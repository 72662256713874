<template>
  <sfs-base-table
    :data-provider="$locationsAPI.getLocations"
    provider-key="locations"
    :columns="translatedColumns(modelName, columns)"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(store_number)="data">
      <b-link :to="{ name: 'location', params: { id: data.item.id } }">
        {{ data.item.store_number }}
      </b-link>
      <div
        v-if="data.item.name"
        class="font-size-sm text-secondary"
      >
        {{ data.item.name }}
      </div>
    </template>
    <template #cell(clients)="data">
      <span
        v-for="(client, index) in data.item.clients"
        :key="index"
      >
        <span v-if="index != 0">,&nbsp;</span>
        <b-link :to="{ name: 'client', params: { id: client.id } }">
          {{ client.name }}
          <span class="font-size-sm">({{ client.code }})</span>
        </b-link>
      </span>
    </template>
    <template #cell(manager_id)="data">
      <b-link
        v-if="data.item.manager"
        :to="{ name: 'user', params: { id: data.item.manager_id } }"
      >
        {{ data.item.manager.name }}
      </b-link>
    </template>

    <template #cell(city)="data">
      {{ data.item.city }}
      <div class="font-size-sm text-secondary">
        {{ data.item.county }}
      </div>
    </template>

    <template #cell(floor_type)="data">
      <b-badge
        v-if="data.item.floor_type"
        variant="secondary"
      >
        {{ floorTypeFormatOptions[data.item.floor_type] }}
      </b-badge>
    </template>

    <template #cell(active)="data">
      {{ !data.item.deleted_at ? "Yes" : "No" }}
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import translationMixin from '@/mixins/translationMixin';
import { LOCATION_TABLE_DEFAULT_COLUMNS, LOCATION_FLOOR_TYPE_FORMAT_OPTIONS, LOCATION_MODEL_NAME } from '@/constants/locations';

export default {
  name: 'LocationsTable',
  mixins: [tablePropsMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: () => LOCATION_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      floorTypeFormatOptions: LOCATION_FLOOR_TYPE_FORMAT_OPTIONS,
      modelName: LOCATION_MODEL_NAME,
    };
  },
  methods: {
    formatClients(clients) {
      return clients.map((client) => client.name).join(', ');
    },
  },
};
</script>
