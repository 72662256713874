<template>
  <div v-if="insuranceCertificate">
    <base-page-heading
      show-back-button
      :title="`Insurance Certificate ${insuranceCertificate.policy_number}`"
    >
      <template #extra>
        <b-button
          v-if="$can('update', 'InsuranceCertificate')"
          variant="primary"
          v-b-modal.insurance-certificate-edit-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Edit the Insurance Certificate"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item
            v-if="$can('update', 'InsuranceCertificate')"
            @click="toggleActive"
          >
            <i
              v-if="!insuranceCertificate.deleted_at"
              class="fa fa-trash mr-1"
            />
            <i
              v-else
              class="fa fa-undo mr-1"
            />
            {{ toggleActiveTooltipText }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: insuranceCertificate.id, model: 'InsuranceCertificate' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>

    <div class="content pt-5">
      <b-row class="mb-4">
        <b-col lg="6">
          <b-card>
            <b-row>
              <b-col
                cols="auto"
                class="mr-auto mb-3"
              >
                Policy #
              </b-col>
              <b-col class="text-right">
                {{ insuranceCertificate.policy_number }}
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto mb-3"
              >
                Category
              </b-col>
              <b-col class="text-right">
                {{ insuranceCertificate.category | humanizedFormat | capitalizeFormat }}
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto mb-3"
              >
                User
              </b-col>
              <b-col class="text-right">
                <b-link :to="{ name: 'user', params: { id: user.id } }">
                  {{ user.name }}
                </b-link>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                State
              </b-col>
              <b-col class="text-right">
                {{ user.state }}
              </b-col>
            </b-row>
            <h4 class="my-3">
              Insurance Contact
            </h4>
            <b-row>
              <b-col
                cols="auto"
                class="mr-auto mb-3"
              >
                Email
              </b-col>
              <b-col class="text-right">
                <b-link
                  v-if="insuranceCertificate.email"
                  :href="`mailto:${insuranceCertificate.email}`"
                >
                  {{ insuranceCertificate.email }}
                </b-link>
                <span v-else>Not provided</span>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Phone
              </b-col>
              <b-col class="text-right">
                <b-link
                  v-if="insuranceCertificate.phone"
                  :href="`mailto:${insuranceCertificate.phone}`"
                >
                  {{ insuranceCertificate.phone }}
                </b-link>
                <span v-else>Not provided</span>
              </b-col>
            </b-row>

            <h4 class="mt-3 mb-2">
              Reminders
            </h4>
            <b-row>
              <b-col>
                Reminders will be sent
                <b-badge
                  v-if="insuranceCertificate.reminder_frequency"
                  variant="secondary"
                >
                  {{ insuranceCertificate.reminder_frequency_parts | frequencyFormat }}
                </b-badge>
                <b-badge
                  v-else
                  variant="secondary"
                >
                  Only on the day of expiry
                </b-badge>
                starting
                <b-badge variant="secondary">
                  {{ insuranceCertificate.reminder_days || 0 }} days
                </b-badge>
                before coverage expires.
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          lg="6"
        >
          <b-card class="h-100">
            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                <h4>
                  Current Coverage
                </h4>
              </b-col>
              <b-col class="text-right">
                <b-button
                  v-if="$can('create', 'InsuranceCertificateCoverage')"
                  variant="outline-secondary"
                  v-b-modal.insurance-certificate-coverage-add-modal
                  class="mr-2"
                  v-b-tooltip.hover
                  title="Renew the insurance coverage"
                >
                  Renew Coverage
                </b-button>
                <b-button
                  v-if="$can('read', 'InsuranceCertificate')"
                  variant="outline-secondary"
                  v-b-modal.insurance-certificate-coverage-email-modal
                  v-b-tooltip.hover
                  title="Send coverage expiration reminder email"
                >
                  <i class="fa fa-envelope" />
                </b-button>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Status
              </b-col>

              <b-col class="text-right">
                <status-badge
                  :status="insuranceCertificate.status"
                  :status-variants="statusVariants"
                />
              </b-col>
            </b-row>

            <b-row
              v-if="currentCoverage.start_date"
              class="mb-3"
            >
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Coverage Starts
              </b-col>

              <b-col class="text-right">
                {{ currentCoverage.start_date | dateFormat }}
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Coverage Ends
              </b-col>

              <b-col class="text-right">
                {{ currentCoverage.end_date | dateFormat }}
              </b-col>
            </b-row>

            <h4 class="my-3">
              Verification
            </h4>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Status
              </b-col>

              <b-col class="text-right">
                <div v-if="currentCoverage.verified">
                  <b-badge variant="success">
                    Verified
                  </b-badge>
                </div>
                <div v-else>
                  <b-badge variant="secondary">
                    Not Verified
                  </b-badge>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Verification Required
              </b-col>

              <b-col class="text-right">
                <b-badge variant="secondary">
                  {{ insuranceCertificate.requires_coverage_verification ? "Yes" : "No" }}
                </b-badge>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-tabs
            content-class="pt-4"
            v-model="activeTab"
          >
            <template #tabs-end>
              <div
                v-show="activeTab === 0"
                class="ml-auto"
              >
                <b-button
                  v-if="$can('create', 'InsuranceCertificateVerification')"
                  variant="outline-secondary"
                  class="mr-2"
                  v-b-modal.insurance-certificate-verification-add-modal
                  v-b-tooltip.hover
                  title="Verify the insurance coverage"
                >
                  Verify Coverage
                </b-button>

                <b-button
                  v-if="$can('read', 'InsuranceCertificate')"
                  variant="outline-secondary"
                  v-b-modal.insurance-certificate-verification-email-modal
                  v-b-tooltip.hover
                  title="Send coverage verification email"
                >
                  <i class="fa fa-envelope" />
                </b-button>
              </div>
              <div
                v-show="activeTab === 1"
                class="ml-auto"
              >
                <b-dropdown
                  class="ml-2"
                  variant="outline-secondary"
                  right
                  v-b-tooltip.hover
                  title="Download spreadsheets"
                >
                  <template #button-content>
                    <b-spinner
                      v-if="downloading"
                      small
                      label="Downloading..."
                    />
                    <i
                      v-else
                      class="fa fa-download mr-1"
                    />
                    Downloads
                  </template>
                  <b-dropdown-item
                    @click="downloadCoveragesSpreadsheet"
                    v-if="$can('read', 'InsuranceCertificateCoverage')"
                  >
                    Previous Coverage
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="downloadVerificationsSpreadsheet"
                    v-if="$can('read', 'InsuranceCertificateVerification')"
                  >
                    Verification History
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <b-tab
              active
              title="Coverage Verification"
            >
              <verifications-table
                :key="verificationsTableKey"
                :columns="verificationsColumns"
                :insurance-certificate-coverage-id="currentCoverage.id"
                :filters="{ paginate: false }"
                :per-page="1"
                :show-per-page="false"
              />
            </b-tab>
            <b-tab title="Previous Coverage">
              <coverages-table
                :columns="coveragesColumns"
                :key="coveragesTableKey"
                :insurance-certificate-id="insuranceCertificate.id"
              />
            </b-tab>

            <b-tab title="Reminder History">
              <communications-table
                :columns="communicationsColumns"
                :list-provider="$communicationsAPI.getInsuranceCertificatesCommunications"
                :reference-id="insuranceCertificate.id"
              />
            </b-tab>

            <b-tab>
              <template #title>
                <notes-badge :total="notesTotal" />
              </template>
              <notes-timeline
                :list-func="$notesAPI.getInsuranceCertificatesNotes"
                :create-func="$notesAPI.createInsuranceCertificatesNote"
                :reference-id="insuranceCertificate.id"
                reference-type="InsuranceCertificate"
                @total-rows-changed="updateNotesTotal"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>

    <!-- Modals -->
    <edit-modal
      v-if="$can('update', 'InsuranceCertificate')"
      v-model="insuranceCertificate"
    />
    <new-coverage-modal
      :insurance-certificate-id="insuranceCertificate.id"
      @on-save="getInsuranceCertificate"
    />
    <new-verification-modal
      :insurance-certificate-coverage-id="currentCoverage.id"
      @on-save="getInsuranceCertificate"
    />
    <verifications-email-modal
      v-model="insuranceCertificate"
      :email="user.email"
    />
    <coverages-email-modal
      v-model="insuranceCertificate"
      :email="user.email"
    />
  </div>
</template>

<script>
import EditModal from '@/components/admin/insuranceCertificates/EditModal.vue';
import NewCoverageModal from '@/components/admin/insuranceCertificateCoverages/NewModal.vue';
import NewVerificationModal from '@/components/admin/insuranceCertificateVerifications/NewModal.vue';
import StatusBadge from '@/components/shared/StatusBadge.vue';
import VerificationsTable from '@/components/admin/insuranceCertificateVerifications/Table.vue';
import VerificationsEmailModal from '@/components/admin/insuranceCertificateVerifications/EmailModal.vue';
import CoveragesTable from '@/components/admin/insuranceCertificateCoverages/Table.vue';
import CoveragesEmailModal from '@/components/admin/insuranceCertificateCoverages/EmailModal.vue';
import CommunicationsTable from '@/components/admin/communications/Table.vue';
import NotesTimeline from '@/components/admin/notes/Timeline.vue';
import NotesBadge from '@/components/admin/notes/TimelineBadge.vue';
import downloadMixin from '@/mixins/downloadMixin';
import { INSURANCE_CERTIFICATE_STATUS_VARIANTS } from '@/constants/insuranceCertificates';
import { INSURANCE_CERTIFICATE_COMMUNICATION_TABLE_NAME } from '@/constants/communications';
import { INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_NAME } from '@/constants/insuranceCertificateVerifications';
import { INSURANCE_CERTIFICATE_COVERAGES_TABLE_NAME } from '@/constants/insuranceCertificateCoverages';
import { DateTime } from 'luxon';

export default {
  name: 'InsuranceCertificateShow',
  mixins: [downloadMixin],
  components: {
    EditModal,
    StatusBadge,
    NewCoverageModal,
    NewVerificationModal,
    VerificationsTable,
    CoveragesTable,
    CommunicationsTable,
    CoveragesEmailModal,
    VerificationsEmailModal,
    NotesTimeline,
    NotesBadge,
  },
  data() {
    return {
      communicationsTableName: INSURANCE_CERTIFICATE_COMMUNICATION_TABLE_NAME,
      verificationsTableName: INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_NAME,
      coveragesTableName: INSURANCE_CERTIFICATE_COVERAGES_TABLE_NAME,
      statusVariants: INSURANCE_CERTIFICATE_STATUS_VARIANTS,
      insuranceCertificate: null,
      verificationsTableKey: Date.now(),
      coveragesTableKey: Date.now(),
      reRender: false,
      notesTotal: 0,
      activeTab: 0,
    };
  },
  mounted() {
    this.getInsuranceCertificate();
  },
  methods: {
    getInsuranceCertificate() {
      this.$insuranceCertificatesAPI
        .get(this.$route.params.id)
        .then((insuranceCertificate) => (this.insuranceCertificate = insuranceCertificate))
        .then(() => this.refreshTables())
        .catch(() => {
          this.$router.push('/404');
        });
    },
    getReminderSchedule() {
      this.$insuranceCertificatesAPI
        .getReminderSchedule(this.insuranceCertificate?.id)
        .then((response) => {
          const { schedule } = response;
          this.schedule = schedule;
        })
        .catch(() => {
          this.schedule = [];
        });
    },
    refreshTables() {
      if (this.reRender) {
        this.refreshVerificationTable();
        this.refreshCoveragesTable();
      } else {
        this.reRender = true;
      }
    },
    refreshVerificationTable() {
      this.verificationsTableKey++;
    },
    refreshCoveragesTable() {
      this.coveragesTableKey++;
    },
    updateNotesTotal(newTotal) {
      this.notesTotal = newTotal;
    },
    toggleActive() {
      this.$swal({
        text: this.toggleActiveConfirmText,
        preConfirm: () => this.$insuranceCertificatesAPI.update(this.$route.params.id,  { deleted_at: (!this.insuranceCertificate.deleted_at ? DateTime.now().toISODate() : null) }),
      }).then((result) => {
        if (result.value) {
          this.insuranceCertificate = result.value;
        }
        result.timer;
      });
    },
    downloadCoveragesSpreadsheet() {
      this.download(
        this.$insuranceCertificateCoveragesAPI.getInsuranceCertificateCoveragesSpreadsheet,
        { insuranceCertificateId: this.insuranceCertificate.id },
        'Insurance Certificate Coverages.csv'
      );
    },
    downloadVerificationsSpreadsheet() {
      this.download(
        this.$insuranceCertificateVerificationsAPI.getInsuranceCertificateVerificationsSpreadsheet,
        { insuranceCertificateCoverageId: this.insuranceCertificate.current_coverage_id },
        'Insurance Certificate Verifications.csv'
      );
    },
  },
  computed: {
    user() {
      return this.insuranceCertificate.user;
    },
    currentCoverage() {
      return this.insuranceCertificate.current_coverage;
    },
    communicationsColumns() {
      return this.$store.getters.columnsForTable(this.communicationsTableName);
    },
    coveragesColumns() {
      return this.$store.getters.columnsForTable(this.coveragesTableName);
    },
    verificationsColumns() {
      return this.$store.getters.columnsForTable(this.verificationsTableName);
    },
    toggleActiveTooltipText() {
      return !this.insuranceCertificate.deleted_at ? 'Deactivate' : 'Reactivate';
    },
    toggleActiveConfirmText() {
      return !this.insuranceCertificate.deleted_at
        ? 'Are you sure you want to deactivate the Insurance Certificate?'
        : 'Are you sure you want to reactivate the Insurance Certificate?';
    },
  },
};
</script>
<style scoped>
.notes-badge {
  font-size: 0.75rem;
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  vertical-align: text-top;
}
</style>