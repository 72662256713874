<template>
  <b-button
    variant="outline-secondary"
    @click="copyContent"
    size="sm"
    :disabled="copied"
  >
    {{ label }}
    <i
      v-if="copied"
      class="fa fa-check ml-1"
    />
    <i
      v-else
      class="far fa-copy ml-1"
    />
  </b-button>
</template>

<script>
export default {
  name: 'CopyButton',
  props: {
    label: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    /**
     * Triggered when the user clicks the copy button. This function copies the content
     * to the users clipboard.
     */
    copyContent() {
      const el = document.createElement('textarea');
      el.value = this.content;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
  },
};
</script>
