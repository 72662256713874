import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '../helpers/apiPath';

export default {
  getWalkthroughs(params) {
    return axios.get(adminPath('/walkthroughs?') + prepareQueryString(params)).then((response) => {
      return {
        walkthroughs: response.data.map((walkthrough) => an('Walkthrough', walkthrough)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(adminPath(`/walkthroughs/${id}`)).then((response) => an('Walkthrough', response.data));
  },
  getPDF(params) {
    return axios.get(adminPath(`/walkthroughs/${params.id}.pdf`), { responseType: 'blob' });
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/walkthroughs/${id}`), {
        ...data,
      })
      .then((response) => an('Walkthrough', response.data));
  },
  create(data) {
    return axios
      .post(adminPath('/walkthroughs'), {
        ...data,
      })
      .then((response) => an('Walkthrough', response.data));
  },
  getPhotos(id, params) {
    return axios.get(adminPath(`/walkthroughs/${id}/photos?`) + prepareQueryString(params)).then((response) => {
      return {
        photos: response.data.map((item) => an('Photo', item)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getItems(id, params) {
    return axios.get(adminPath(`/walkthroughs/${id}/items?`) + prepareQueryString(params)).then((response) => {
      return {
        walkthroughItems: response.data.map((item) => an('WalkthroughItem', item)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  createItem(id, data) {
    return axios
      .post(adminPath(`/walkthroughs/${id}/items`), {
        ...data,
      })
      .then((response) => an('WalkthroughItem', response.data));
  },
  updateItem(itemId, data) {
    return axios
      .patch(adminPath(`/walkthrough_items/${itemId}`), {
        ...data,
      })
      .then((response) => an('WalkthroughItem', response.data));
  },
  destroyItemPhoto(itemId, photoId) {
    return axios.delete(adminPath(`/walkthrough_items/${itemId}/photos/${photoId}`));
  },
  destroyItem(itemId) {
    return axios.delete(adminPath(`/walkthrough_items/${itemId}`));
  },
};
