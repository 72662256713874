<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$glCodesAPI.getGlCodes"
    provider-key="glCodes"
    :columns="translatedColumns(modelName, columns)"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(client_id)="data">
      <b-link
        v-if="data.item.client"
        :to="{ name: 'client', params: { id: data.item.client.id } }"
      >
        {{ data.item.client.name }}
      </b-link>
    </template>
    <template #cell(code)="data">
      <code>{{ data.item.code }}</code>
    </template>
    <template #cell(category)="data">
      <b-badge variant="secondary">
        {{ data.item.category | humanizedFormat | titlecaseFormat }}
      </b-badge>
    </template>
    <template #cell(grouped)="data">
      <div
        v-if="data.item.grouped"
        class="text-secondary"
      >
        Grouped
      </div>
      <div
        v-else
        class="text-secondary"
      >
        Not Grouped
      </div>
    </template>
    <template #cell(active)="data">
      <b-badge
        v-if="!data.item.deleted_at"
        variant="success"
      >
        Active
      </b-badge>
      <b-badge
        v-else
        variant="danger"
      >
        Inactive
      </b-badge>
    </template>
    <template #cell(actions)="data">
      <b-button-group>
        <b-button
          v-if="$can('update', 'GLCode')"
          v-b-modal="`gl-code-${data.item.id}-edit-modal`"
          size="sm"
          variant="link"
          v-b-tooltip.hover
          title="Edit the GL Code"
        >
          <i class="fa fa-fw fa-pencil-alt" />
        </b-button>

        <b-button
          v-if="$can('update', 'GLCode')"
          v-b-tooltip.hover
          size="sm"
          variant="link"
          :title="toggleActiveTooltipText(!data.item.deleted_at)"
          @click="toggleActive(data.item.id, !data.item.deleted_at)"
        >
          <i
            v-if="!data.item.deleted_at"
            class="fa fa-fw fa-times"
          />
          <i
            v-else
            class="fa fa-fw fa-undo"
          />
        </b-button>

        <net-suite-form-popover
          v-if="$can('read', 'IntegrationReference')"
          :reference-id="data.item.id"
          reference-type="GLCode"
          :title="null"
          icon="fa fa-fw fa-code-branch"
          variant="link"
          :current-integration-reference-id="data.item.netsuite_reference_id"
        />

        <edit-modal
          v-if="$can('update', 'GLCode')"
          :key="`gl-codes-edit-modal-${data.item.id}`"
          :value="data.item"
          @input="refreshTable"
        />
      </b-button-group>
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import translationMixin from '@/mixins/translationMixin';
import { GL_CODE_TABLE_DEFAULT_COLUMNS, GL_CODE_MODEL_NAME } from '@/constants/glCodes';
import EditModal from '@/components/admin/glCodes/EditModal.vue';
import NetSuiteFormPopover from '@/components/admin/integrationReferences/NetSuiteFormPopover.vue';
import { DateTime } from 'luxon';

export default {
  name: 'GlCodesTable',
  mixins: [tablePropsMixin, translationMixin],
  components: {
    EditModal,
    NetSuiteFormPopover,
  },
  props: {
    columns: {
      type: Array,
      default: () => GL_CODE_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      modelName: GL_CODE_MODEL_NAME,
    };
  },
  methods: {
    toggleActiveTooltipText(deleted_at) {
      return deleted_at ? 'Deactivate' : 'Reactivate';
    },
    toggleActiveConfirmText(deleted_at) {
        return deleted_at
          ? 'Are you sure you want to deactivate the GL Code?'
          : 'Are you sure you want to reactivate the GL Code?';
    },
    toggleActive(id, deleted_at) {
      this.$swal({
        text: this.toggleActiveConfirmText(deleted_at),
        preConfirm: () => this.$glCodesAPI.update(id, { deleted_at: (deleted_at ? DateTime.now().toISODate() : null ) }),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
  },
};
</script>
