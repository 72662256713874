<template>
  <div>
    <base-page-heading title="Dashboard">
      <template #extra>
        <b-button
          v-b-tooltip.hover
          variant="link"
          title="Refresh the Dashboard"
          @click="refreshData"
        >
          <i class="fa fa-sync" />
        </b-button>
      </template>
    </base-page-heading>
    <div
      class="content pt-3"
    >
      <b-row class="mb-2">
        <b-col
          cols="auto"
          class="mr-auto"
        >
          <client-select
            name="dashboard-filter-client_id"
            class="ml-2"
            style="min-width: 300px; width: auto"
            placeholder="All Clients"
            :value="client"
            @input="onClientChanged"
            :persist-state="true"
            :disabled="isLoadingData"
          />
        </b-col>
        <b-col
          cols="auto"
        >
          <sfs-date-range-picker
            id="dashboard-filter-dates"
            v-model="currentDates"
            style="width: 300px"
            placeholder="All Dates"
            @on-change="onDatesChanged"
          />
        </b-col>
      </b-row>
      <b-row class="mt-0 font-size-sm">
        <b-col
          cols="auto"
          class="ml-auto"
        >
          <b-link
            :disabled="isLoadingData"
            @click="() => dateShortcutChanged(currentWeekDates)"
          >
            Current Week
          </b-link>
          <span class="pl-1 pr-1">|</span>
          <b-link
            :disabled="isLoadingData"
            @click="() => dateShortcutChanged(currentMonthDates)"
          >
            Current Month
          </b-link>
          <span class="pl-1 pr-1">|</span>
          <b-link
            :disabled="isLoadingData"
            @click="() => dateShortcutChanged(lastWeekDates)"
          >
            Previous Week
          </b-link>
          <span class="pl-1 pr-1">|</span>
          <b-link
            :disabled="isLoadingData"
            @click="() => dateShortcutChanged(lastMonthDates)"
          >
            Previous Month
          </b-link>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col
          cols="12"
          md="6"
          lg="4"
          class="mb-4"
        >
          <sfs-dashboard-card
            title="Current Work Orders"
            icon-class="far fa-folder-open fa-2x"
            :content="currentWorkOrdersCurrent.toString()"
            :href="{ name: 'work-orders', query: { statuses: [workOrderAssignedStatus], date_bucket_start_from: datePeriod[0], date_bucket_end_to: datePeriod[1] } }"
            href-content="View current"
          />
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
          class="mb-4"
        >
          <sfs-dashboard-card
            title="In Progress Work Orders"
            icon-class="far fa-clock fa-2x"
            :content="currentWorkOrdersInProgress.toString()"
            :href="{ name: 'work-orders', query: { statuses: [workOrderInProgressStatus], date_bucket_start_from: datePeriod[0], date_bucket_end_to: datePeriod[1] } }"
            href-content="View in progress"
          />
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
          class="mb-4"
        >
          <sfs-dashboard-card
            title="Completed Work Orders"
            icon-content="100%"
            icon-class="far fa-check-circle fa-2x"
            :href="{ name: 'work-orders', query: { statuses: [workOrderCompletedStatus, workOrderInvoicedStatus], date_bucket_start_from: datePeriod[0], date_bucket_end_to: datePeriod[1], checked_out_from: datePeriod[0], checked_out_to: datePeriod[1] } }"
            href-content="View completed"
          >
            <template #content>
              <h2 class="dashboard-content mb-2 font-w400 d-inline">
                {{ currentWorkOrdersCompleted }}
              </h2>
              <h4 class="dashboard-content mb-2 font-w400 d-inline">
                / {{ currentWorkOrdersTotal }}
              </h4>
            </template>
            <template #secondary>
              <div>
                <h2 class="font-w400 d-inline">
                  {{ currentCompletionPercent.toFixed(2) }}
                </h2>
                <h4 class="font-w400 d-inline">
                  %
                </h4>
              </div>
              <div class="dashboard-title text-muted">
                &nbsp;
              </div>
            </template>
          </sfs-dashboard-card>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col cols="12">
          <b-card class="mb-0 h-100">
            <skeleton-chart
              v-show="isLoadingData"
              :height="'400px'"
            />
            <work-order-revenue-chart
              v-show="!isLoadingData"
              height="400px"
              :group-period="groupPeriod"
              :date-period="datePeriod"
              :client-id="client?.id"
            />
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col cols="12">
          <b-card class="mb-0 h-100">
            <skeleton-chart
              v-show="isLoadingData"
              :height="'400px'"
            />
            <work-order-completion-source-chart
              v-show="!isLoadingData"
              height="400px"
              :group-period="groupPeriod"
              :date-period="datePeriod"
              :client-id="client?.id"
            />
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import ClientSelect from '@/components/admin/clients/Select.vue';
import WorkOrderRevenueChart from '@/components/admin/charts/WorkOrderRevenueChart.vue';
import WorkOrderCompletionSourceChart from '@/components/admin/charts/WorkOrderCompletionSourceChart.vue';
import calculateGroupPeriod from '@/utils/calculateGroupPeriod';
import SkeletonChart from '@/components/shared/SkeletonChart.vue';

import {
  WORK_ORDER_STATUS_ASSIGNED,
  WORK_ORDER_STATUS_IN_PROGRESS,
  WORK_ORDER_STATUS_COMPLETED,
  WORK_ORDER_STATUS_INVOICED,
} from '@/constants/workOrders';

export default {
  name: 'Dashboard',
  components: {
    ClientSelect,
    WorkOrderRevenueChart,
    WorkOrderCompletionSourceChart,
    SkeletonChart
  },
  data() {
    const start = DateTime.now().startOf('week').minus({ week: 1, day: 1 }).toISODate();
    const end = DateTime.now().startOf('week').minus({ day: 2 }).toISODate();
    return {
      workOrdersDashboardData: null,
      workOrderAssignedStatus: WORK_ORDER_STATUS_ASSIGNED,
      workOrderInProgressStatus: WORK_ORDER_STATUS_IN_PROGRESS,
      workOrderCompletedStatus: WORK_ORDER_STATUS_COMPLETED,
      workOrderInvoicedStatus: WORK_ORDER_STATUS_INVOICED,
      lastWeekDates: [start, end],
      lastMonthDates: [DateTime.now().startOf('month').minus({ month: 1 }).toISODate(), DateTime.now().endOf('month').minus({ month: 1 }).toISODate()],
      currentMonthDates: [DateTime.now().startOf('month').toISODate(), DateTime.now().endOf('month').toISODate()],
      currentWeekDates: [DateTime.now().startOf('week').minus({ day: 1 }).toISODate(), DateTime.now().endOf('week').toISODate()],
      currentDates: [this.$options.filters.dateFormat(start), this.$options.filters.dateFormat(end)],
      datePeriod: [start, end],
      client: this.$store.getters?.client,
      isLoadingData: false
    };
  },
  methods: {
    onDatesChanged(dates) {
      const start = dates[0] ? DateTime.fromJSDate(dates[0]).toISODate() : '';
      const end = dates[1] ? DateTime.fromJSDate(dates[1]).toISODate() : '';
      if (start && end) {
        this.datePeriod = [start, end];
        this.refreshData();
      }
    },
    dateShortcutChanged(dates) {
      this.datePeriod = dates;
      this.currentDates = [this.$options.filters.dateFormat(dates[0]), this.$options.filters.dateFormat(dates[1])];
      this.refreshData();
    },
    onClientChanged(client) {
      this.client = client;
      this.refreshData();
    },
    fetchWorkOrdersDashboardData() {
      this.isLoadingData = true;
      this.$analyticsAPI
        .getWorkOrdersDashboard({
          client_id: this.client?.id,
          start_date: this.datePeriod[0],
          end_date:  this.datePeriod[1],
        })
        .then((response) => {
          const { data } = response;
          this.workOrdersDashboardData = data;
          this.isLoadingData = false;
        })
        .catch(() => {
          this.workOrdersDashboardData = null;
        });
    },
    refreshData() {
      this.fetchWorkOrdersDashboardData();
    },
  },
  computed: {
    currentWorkOrdersCurrent() {
      return this.workOrdersDashboardData?.current?.current || 0;
    },
    currentWorkOrdersInProgress() {
      return this.workOrdersDashboardData?.in_progress?.current || 0;
    },
    currentWorkOrdersCompleted() {
      return this.workOrdersDashboardData?.completed?.current || 0;
    },
    currentWorkOrdersTotal() {
      return this.workOrdersDashboardData?.total?.current || 0;
    },
    currentCompletionPercent() {
      if (this.currentWorkOrdersTotal == 0) {
        return 0;
      } else {
        return (this.currentWorkOrdersCompleted / this.currentWorkOrdersTotal) * 100.0;
      }
    },
    groupPeriod() {
      return calculateGroupPeriod(this.datePeriod[0], this.datePeriod[1]);
    },
  },
  mounted() {
    this.refreshData();
  },
};
</script>
