<template>
  <div>
    <b-dropdown
      class="mr-2"
      variant="outline-secondary"
      v-b-tooltip.hover
      title="Manage Officetrax data"
    >
      <template #button-content>
        <span>Fexa</span>
        <span class="font-size-xs bg-gray p-1">
          3rd Party
        </span>
      </template>
      <b-dropdown-item
        v-b-modal="scheduleModalId"
      >
        <i class="fa fa-calendar-alt mr-1" />
        Manage Visit Schedule
      </b-dropdown-item>

      <b-dropdown-item
        v-b-modal="statusModalId"
      >
        <i class="fa fa-wrench mr-1" />
        Update Assignment Status
      </b-dropdown-item>
    </b-dropdown>
    <dollar-general-fexa-assignment-status-form-modal
      v-if="$can('update', 'WorkOrder') && integrationReference"
      :key="statusFormModalKey"
      :form-modal-id="statusModalId"
      :value="integrationReference"
      @on-save="onIntegrationReferenceChange"
    />

    <dollar-general-fexa-visit-schedule-form-modal
      v-if="$can('update', 'WorkOrder') && integrationReference"
      :key="scheduleFormModalKey"
      :form-modal-id="scheduleModalId"
      :recommended-visit-schedule="integrationReference?.dollar_general_fexa_assignment_record?.initial_arrival_deadline"
      :value="value"
      @on-save="onWorkOrderChange"
    />
  </div>
</template>

<script>
import DollarGeneralFexaAssignmentStatusFormModal from '@/components/admin/integrationReferences/DollarGeneralFexa/AssignmentStatusFormModal.vue';
import DollarGeneralFexaVisitScheduleFormModal from '@/components/admin/integrationReferences/DollarGeneralFexa/VisitScheduleFormModal.vue';

export default {
  name: 'DollarGeneralFexaAssignmentManager',
  components: {
    DollarGeneralFexaAssignmentStatusFormModal,
    DollarGeneralFexaVisitScheduleFormModal,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      integrationReference: null,
      statusFormModalKey: Date.now(),
      scheduleFormModalKey: Date.now(),
    };
  },
  mounted() {
    this.getIntegrationReference();
  },

  methods: {
    onIntegrationReferenceChange(reference) {
      this.$emit('status-changed', reference.dollar_general_fexa_assignment_record?.status);
      this.integrationReference = reference;
      this.statusFormModalKey = Date.now();
    },
    onWorkOrderChange(workOrder) {
      this.$emit('input', workOrder);
      this.scheduleFormModalKey = Date.now();
    },
    getIntegrationReference() {
      this.$integrationReferencesAPI.get(this.value.dollar_general_fexa_assignment_reference_id).then((currentReference) => {
        this.onIntegrationReferenceChange(currentReference);
    });
    },
  },
  computed: {
    statusModalId() {
      return `dollar-general-fexa-assignment-${this.value.dollar_general_fexa_assignment_reference_id}-status-modal`;
    },
    scheduleModalId() {
      return `dollar-general-fexa-visit-${this.value.dollar_general_fexa_assignment_reference_id}-schedule-modal`;
    },
  },
};
</script>

