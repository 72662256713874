<template>
  <b-form
    @submit.stop.prevent="handleSubmit"
    class="text-left"
  >
    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.cost"
          label-for="recurring-payment-cost"
        >
          <b-input-group prepend="$">
            <b-form-input
              name="recurring-payment-cost"
              v-model="$v.form.cost.$model"
              :state="$v.form.cost.$dirty ? !$v.form.cost.$error : null"
              type="text"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.cost.$dirty"
            :state="$v.form.cost.$dirty ? !$v.form.cost.$error : null"
            class="recurring-payment-cost-feedback"
          >
            <span v-if="!$v.form.cost.required">Please enter a cost.</span>
            <span v-if="!$v.form.cost.decimal">Please enter a number.</span>
            <span v-if="!$v.form.cost.serverFailed">
              {{ this.serverErrors.cost_cents }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.description"
          label-for="recurring-payment-description"
        >
          <b-form-input
            name="recurring-payment-description"
            v-model="$v.form.description.$model"
            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.description.$dirty"
            class="recurring-payment-description-feedback"
          >
            <span v-if="!$v.form.description.required">Please enter a description.</span>
            <span v-if="!$v.form.description.maxLength">
              The description must be less than {{ $v.form.description.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.description.serverFailed">{{ this.serverErrors.description }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.gl_code_id"
          label-for="recurring-payment-gl_code_id"
        >
          <gl-code-select
            name="recurring-payment-gl_code_id"
            :select-class="{ 'is-invalid': $v.form.gl_code_id.$dirty && $v.form.gl_code_id.$error }"
            :value="form.gl_code"
            @input="onGlCodeChange"
            :filter-params="glCodeFilters"
          />
          <b-form-invalid-feedback
            v-if="$v.form.gl_code_id.$dirty"
            class="recurring-payment-gl_code_id-feedback"
          >
            <span v-if="!$v.form.gl_code_id.serverFailed">
              {{ serverErrors.gl_code }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.netsuite_expense_class_id"
          label-for="recurring-payment-netsuite_expense_class_id"
        >
          <net-suite-record-select
            name="gl-code-netsuite_expense_class_id"
            :value="form.netsuite_expense_class_id"
            @input="onExpenseClassificationChange"
            reference-type="Service"
            :select-class="{ 'is-invalid': $v.form.netsuite_expense_class_id.$dirty && $v.form.netsuite_expense_class_id.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.netsuite_expense_class_id.$dirty"
            class="recurring-payment-netsuite_expense_class_id-feedback"
          >
            <span v-if="!$v.form.netsuite_expense_class_id.maxLength">
              The expense class must be less than {{ $v.form.netsuite_expense_class_id.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.netsuite_expense_class_id.serverFailed">{{ serverErrors.netsuite_expense_class_id }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.start_date"
          label-for="recurring-payment-start_date"
        >
          <sfs-date-picker
            name="recurring-payment-start_date"
            v-model="$v.form.start_date.$model"
            :class="{ 'is-invalid': $v.form.start_date.$dirty && $v.form.start_date.$error }"
            :with-class="{ 'is-invalid': $v.form.start_date.$dirty && $v.form.start_date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.start_date.$dirty"
            :state="$v.form.start_date.$dirty ? !$v.form.start_date.$error : null"
            class="recurring-payment-start_date-feedback"
          >
            <span v-if="!$v.form.start_date.required">Please enter a start date.</span>
            <span v-if="!$v.form.start_date.serverFailed">
              {{ this.serverErrors.start_date }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.end_date"
          label-for="recurring-payment-end_date"
        >
          <sfs-date-picker
            name="recurring-payment-end_date"
            v-model="$v.form.end_date.$model"
            :class="{ 'is-invalid': $v.form.end_date.$dirty && $v.form.end_date.$error }"
            :with-class="{ 'is-invalid': $v.form.end_date.$dirty && $v.form.end_date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.end_date.$dirty"
            :state="$v.form.end_date.$dirty ? !$v.form.end_date.$error : null"
            class="recurring-payment-end_date-feedback"
          >
            <span v-if="!$v.form.end_date.serverFailed">
              {{ this.serverErrors.end_date }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="recurring-payment-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="recurring-payment-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength, decimal } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import GlCodeSelect from '@/components/admin/glCodes/Select.vue';
import NetSuiteRecordSelect from '@/components/admin/integrationReferences/NetSuiteRecordSelect.vue';
import { GL_CODE_TECHNICIAN_PAYMENT_CATEGORY } from '@/constants/glCodes';

export default {
  name: 'RecurringPaymentsForm',
  components: {
    GlCodeSelect,
    NetSuiteRecordSelect,
  },
  mixins: [formMixin],
  props: {
    description: String,
    cost: Object,
    user_id: Number,
    gl_code_id: Number,
    netsuite_expense_class_id: [String, Number],
    user: Object,
    gl_code: Object,
    start_date: String,
    end_date: String,
  },
  data() {
    return {
      form: {
        description: this.description,
        cost: this.cost && this.cost.amount ? this.cost.amount / 100 : null,
        cost_cents: this.cost && this.cost.amount ? this.cost.amount : null,
        user_id: this.user_id,
        gl_code_id: this.gl_code_id,
        netsuite_expense_class_id: this.netsuite_expense_class_id,
        user: this.user,
        gl_code: this.gl_code,
        start_date: this.start_date,
        end_date: this.end_date,
      },
      fieldLabels: {
        description: 'Description *',
        cost: 'Cost *',
        user_id: 'Technician *',
        gl_code_id: 'GL Code',
        netsuite_expense_class_id: 'Expense Class',
        start_date: 'Start Date *',
        end_date: 'End Date',
      },
      glCodeFilters: { category: GL_CODE_TECHNICIAN_PAYMENT_CATEGORY, active: true },
    };
  },
  validations: {
    form: {
      description: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('description');
        },
      },
      cost: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('cost_cents');
        },
      },
      user_id: {
        serverFailed() {
          return !this.hasServerErrors('user_id');
        },
      },
      gl_code_id: {
        serverFailed() {
          return !this.hasServerErrors('gl_code');
        },
      },
      netsuite_expense_class_id: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('netsuite_expense_class_id');
        },
      },
      start_date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('start_date');
        },
      },
      end_date: {
        serverFailed() {
          return !this.hasServerErrors('end_date');
        },
      },
    },
  },
  watch: {
    'form.cost': function (newAmount) {
      this.form.cost_cents = isNaN(newAmount) ? null : Math.round(newAmount * 100);
    },
  },
  methods: {
    onGlCodeChange(glCode) {
      this.form.gl_code_id = glCode ? glCode.id : '';
      this.form.gl_code = glCode;
    },
    onExpenseClassificationChange(netsuiteClass) {
      this.form.netsuite_expense_class_id = netsuiteClass.id;
    },
  },
};
</script>
