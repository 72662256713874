<template>
  <sfs-form-modal
    :id="modalId"
    title="Add a Service"
    size="lg"
    @on-hide="resetFormData"
  >
    <services-form
      :minimum_days_between_service="0"
      :organization="organization"
      :organization_id="organization_id"
      @on-submit="createService"
      @on-save="redirectToService"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import ServicesForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'ServicesAddModal',
  mixins: [formModalMixin],
  components: {
    ServicesForm,
  },
  data() {
    return {
      modalId: 'service-add-modal',
    };
  },
  methods: {
    createService(data, handleCreate) {
      handleCreate(this.$servicesAPI.create(data));
    },
    redirectToService(service) {
      this.$router.push({ name: 'service', params: { id: service.id } });
    },
  },
  computed: {
    organization() {
      return this.$store.getters.currentOrganization;
    },
    organization_id() {
      return this.organization?.id;
    },
  },
};
</script>
