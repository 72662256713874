import { isUndefined as _isUndefined } from 'lodash';
import handlePaginationRouteProps from '@/utils/handlePaginationRouteProps';
/**
 * This function returns a set of props containing all of the query params from a route.
 * If the active query param is not present it sets it to be true by default. Calls the handlePaginationRouteProps
 * function after setting the active prop.
 * @param {object} route the vue-router route to check the active prop for.
 */
export default function handleActivePaginationRouteProps(route) {
  if (_isUndefined(route.query.active)) {
    route.query.active = 'true';
  }
  return handlePaginationRouteProps(route);
}
