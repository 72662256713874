import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const RECURRING_CHARGE_MODEL_NAME = 'recurring_charges';
export const RECURRING_CHARGE_TABLE_NAME = 'recurringCharges';

// The columns available to be shown in a recurringCharges table.
export const RECURRING_CHARGE_TABLE_COLUMNS = {
  handle: {label: '', key: 'handle' },
  id: { label: 'ID', key: 'id' },
  description: { label: 'Description', key: 'description' },
  price: { label: 'Price', key: 'price' },
  taxes: { label: 'Estimated Tax', key: 'taxes' },
  location_id: { label: 'Location', key: 'location_id' },
  client_id: { label: 'Client', key: 'client_id' },
  start_date: { label: 'Start Date', key: 'start_date' },
  end_date: { label: 'End Date', key: 'end_date' },
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const RECURRING_CHARGE_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(RECURRING_CHARGE_TABLE_COLUMNS);
// The default columns to be shown when the recurringCharges table loads.
export const RECURRING_CHARGE_TABLE_DEFAULT_COLUMNS = [
  RECURRING_CHARGE_TABLE_COLUMNS.handle,
  RECURRING_CHARGE_TABLE_COLUMNS.description,
  RECURRING_CHARGE_TABLE_COLUMNS.price,
  RECURRING_CHARGE_TABLE_COLUMNS.taxes,
  RECURRING_CHARGE_TABLE_COLUMNS.client_id,
  RECURRING_CHARGE_TABLE_COLUMNS.start_date,
  RECURRING_CHARGE_TABLE_COLUMNS.end_date,
  RECURRING_CHARGE_TABLE_COLUMNS.actions,
];
