<template>
  <span>
    <i
      :id="target"
      class="si si-info font-size-sm"
    />
    <b-popover
      :target="target"
      triggers="hover"
      placement="top"
      boundary="window"
    >
      <div v-if="taxRates">
        <div v-if="taxApplied">The following tax rates were applied at time of invoicing:</div>
        <div v-else>The following tax rates will be applied at time of invoicing:</div>
        <div
          v-for="taxRate in taxRates"
          :key="taxRate.id"
          class="font-size-sm mb-1"
        >
          <div>
            <b-link :to="{ name: 'tax-rates', query: { city: taxRate.city, county: taxRate.county, state: taxRate.state } }">
              {{ taxRate.name }}({{ { city: taxRate.city, county: taxRate.county, state: taxRate.state } | locationFormat }}):
            </b-link>
            <span class="font-w700">{{ taxRate.rate | percentFormatFilter }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div>No tax rates found</div>
      </div>
    </b-popover>
  </span>
</template>
<script>

export default {
  name: 'TaxRatePopover',
  props: {
    target: {
      type: String,
    },
    taxApplied: {
      type: Boolean,
      default: false,
    },
    taxRates: {
      type: Array,
      default: () => [],
    }
  },
};
</script>
