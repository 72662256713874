<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit Walkthrough"
    @on-hide="resetFormData"
  >
    <walkthroughs-form
      v-bind="value"
      @on-submit="updateWalkthrough"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import WalkthroughsForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'WalkthroughsEditModal',
  mixins: [formModalMixin],
  components: {
    WalkthroughsForm,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: 'walkthrough-edit-modal',
    };
  },
  methods: {
    updateWalkthrough(data, handleUpdate) {
      handleUpdate(this.$walkthroughsAPI.update(this.value.id, data));
    },
    onSave(walkthrough) {
      this.closeModal();
      this.$emit('input', walkthrough);
    },
  },
};
</script>
