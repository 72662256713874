<template>
  <div>
    <base-page-heading :title="clientHeader">
      <template #extra>
        <b-button
          v-if="$can('create', 'Client')"
          variant="primary"
          class="mr-2"
          v-b-modal.client-add-modal
          v-b-tooltip.hover
          title="Create a new Client"
        >
          <i class="fa fa-plus mr-1" />
          New {{ clientTranslation }}
        </b-button>

        <b-button
          v-if="$can('create', 'Import')"
          variant="outline-secondary"
          class="mr-2"
          v-b-modal.clients-imports-add-modal
          v-b-tooltip.hover
          title="Import new Clients"
        >
          <i class="fa fa-file-excel mr-1" />
          Import
        </b-button>

        <sfs-download-button
          v-b-tooltip.hover
          :provider-params="filters"
          filename="Clients.csv"
          :file-provider="$clientsAPI.getSpreadsheet"
          title="Download spreadsheet"
          label="Download"
        />
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <clients-list-filter
            :table-name="tableName"
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>

      <clients-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
    <!-- Page Modals -->
    <clients-new-modal v-if="$can('create', 'Client')" />
    <clients-import-new-modal v-if="$can('create', 'Import')" />
  </div>
</template>

<script>
import ClientsNewModal from '@/components/admin/clients/NewModal.vue';
import ClientsTable from '@/components/admin/clients/Table.vue';
import ClientsImportNewModal from '@/components/admin/imports/ClientsNewModal.vue';
import ClientsListFilter from '@/components/admin/clients/ListFilter.vue';
import listMixin from '@/mixins/listMixin';
import translationMixin from '@/mixins/translationMixin';
import { CLIENT_TABLE_NAME } from '@/constants/clients';
import pluralize from 'pluralize';

export default {
  name: 'ClientsList',
  components: {
    ClientsNewModal,
    ClientsImportNewModal,
    ClientsListFilter,
    ClientsTable,
  },
  mixins: [listMixin, translationMixin],
  props: {
    text: String,
    code: String,
    name: String,
    invoice_delivery_method: String,
    manager_id: String,
    active: String,
  },
  data() {
    return {
      tableName: CLIENT_TABLE_NAME,
      filters: {
        text: this.text,
        code: this.code,
        name: this.name,
        invoice_delivery_method: this.invoice_delivery_method,
        active: this.active,
        manager_id: this.manager_id,
      },
    };
  },
  computed: {
    clientHeader() {
      return pluralize(this.$store.getters.translate('client', 'Clients'));
    },
  }
};
</script>
