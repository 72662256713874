<template>
  <div>
    <base-page-heading :title="greeting" />
    <div class="content">
      <b-row class="mb-3">
        <b-col>
          <h4 class="font-w300">
            Here's your overview
          </h4>
        </b-col>

        <b-col
          cols="auto"
          class="ml-auto form-inline"
        >
          <b-button
            id="dropdown-filter-button"
            variant="outline-secondary"
            title="Select a different date period"
            v-b-tooltip.hover
          >
            {{ currentDateRange }}<i class="ml-2 far fa-calendar" />
          </b-button>
          <b-popover
            triggers="click"
            :show.sync="showDateFilters"
            custom-class="popover-filters p-3"
            target="dropdown-filter-button"
            placement="bottomleft"
            container="base-list-filter"
          >
            <b-row>
              <b-col>
                <b-form-group
                  label="Select a Date Period"
                  label-for="dashboard-filter-dates"
                  class="mb-2"
                >
                  <sfs-date-range-picker
                    id="dashboard-filter-dates"
                    placeholder="Select Dates"
                    v-model="currentDates"
                    @on-change="onDatesChanged"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mb-4">
              <b-col
                cols="auto"
                class="ml-auto"
              >
                <b-link @click="() => dateShortcutChanged(last30DaysDates)">
                  Previous 30 Days
                </b-link>
                <span class="pl-1 pr-1">|</span>
                <b-link @click="() => dateShortcutChanged(Last90DaysDates)">
                  Previous 90 Days
                </b-link>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-button
                  id="popover-filter-apply-button"
                  block
                  variant="primary"
                  @click="applyDates"
                >
                  Apply
                </b-button>
              </b-col>
            </b-row>
          </b-popover>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col
          cols="12"
          md="12"
          lg="4"
          class="mb-3"
        >
          <sfs-dashboard-card
            title="Next Payment"
            icon-content="100%"
            icon-class="far fa-credit-card fa-2x"
            :content="nextPayment ? $options.filters.dateFormat(nextPayment.due_date) : '-'"
            :href="nextPayment ? { name: 'technician-user-payment', params: { id: nextPayment?.id } }: { name: 'technician-user-payments' }"
          >
            <template #hrefContent>
              <span v-if="nextPayment">
                View
                <money-format
                  class="d-inline"
                  :value="nextPayment.balance.amount"
                  :currency-code="nextPayment.balance.currency"
                  subunits-value
                />
                payment
              </span>
              <span v-else>
                View payments
              </span>
            </template>
          </sfs-dashboard-card>
        </b-col>
        <b-col
          cols="12"
          md="12"
          lg="4"
          class="mb-3"
        >
          <sfs-dashboard-card
            title="Current Work Orders"
            icon-class="far fa-clipboard fa-2x"
            :content="currentWorkOrdersCurrent.toString()"
            :href="{ name: 'technician-work-orders', query: { status: workOrderCurrentStatus } }"
            href-content="View current"
          />
        </b-col>

        <b-col
          cols="12"
          md="12"
          lg="4"
          class="mb-3"
        >
          <sfs-dashboard-card
            title="Work Orders In Progress "
            icon-class="far fa-clock fa-2x"
            :content="currentWorkOrdersInProgress.toString()"
            :href="{ name: 'technician-work-orders', query: { status: workOrderInProgressStatus } }"
            href-content="View in progress"
          />
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col
          cols="12"
          md="12"
          lg="12"
          xl="7"
        >
          <b-card
            class="h-100"
          >
            <area-chart
              title="Completed Work Orders"
              y-axis-title="Total"
              :y-axis-label-formatter="(val) => (val || 0).toFixed(0)"
              :y-axis-min="0"
              chart-height="550px"
              skeleton-height="550px"
              :series="[completedWorkOrdersData]"
            />
          </b-card>
        </b-col>

        <b-col
          cols="12"
          md="12"
          lg="12"
          xl="5"
        >
          <div class="h-100">
            <b-row class="mb-2">
              <b-col>
                <h4>
                  Latest Work Orders
                </h4>
              </b-col>
              <b-col
                cols="auto"
                class="ml-auto"
              >
                <b-link
                  class="dashboard-link"
                  :to="{ name: 'technician-work-orders', query: { status: workOrderCurrentStatus } }"
                >
                  View More
                </b-link>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-table
                  :items="workableWorkOrders"
                  :fields="[{ key: 'number', label: 'Number' }, { key: 'description', label: 'Description' }]"
                  class="mb-0"
                  outlined
                  show-empty
                  responsive
                  table
                >
                  <template #cell(number)="data">
                    <b-link :to="{ name: 'technician-work-order', params: { id: data.item.id } }">
                      {{ data.item.number }}
                    </b-link>

                    <div class="text-nowrap mt-1">
                      {{ data.item.location.street }}
                    </div>
                  </template>
                  <template #cell(description)="data">
                    <div class="mb-1">
                      {{ data.item.description }}
                    </div>
                    <money-format
                      class="font-w600"
                      :value="data.item.cost.amount"
                      :currency-code="data.item.cost.currency"
                      subunits-value
                    />
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import { mapTimeSeriesData } from '@/utils/apexchartsSeriesMapping';
import AreaChart from '@/components/shared/apexcharts/AreaChart.vue';
import { TECHNICIAN_WORK_ORDER_STATUS_CURRENT,  TECHNICIAN_WORK_ORDER_STATUS_IN_PROGRESS } from '@/constants/workOrders';
import calculateGroupPeriod from '@/utils/calculateGroupPeriod';

export default {
  name: 'Dashboard',
  components: {
    AreaChart,
  },
  data() {
    const start = DateTime.now().minus({ month: 1 }).toISODate();
    const end = DateTime.now().toISODate();
    return {
      workOrdersDashboardData: null,
      workableWorkOrders: [],
      completedWorkOrdersData: { data: [] },
      workOrderCurrentStatus: TECHNICIAN_WORK_ORDER_STATUS_CURRENT,
      workOrderInProgressStatus: TECHNICIAN_WORK_ORDER_STATUS_IN_PROGRESS,
      last30DaysDates: [DateTime.now().minus({ day: 30 }).toISODate(), end],
      Last90DaysDates: [DateTime.now().minus({ day: 90 }).toISODate(), end],
      currentDates: [this.$options.filters.dateFormat(start), this.$options.filters.dateFormat(end)],
      startDate: start,
      endDate: end,
      unappliedStartDate: start,
      unappliedEndDate: end,
      nextPayment: null,
      showDateFilters: false,
    };
  },
  methods: {
    onDatesChanged(dates) {
      this.unappliedStartDate = dates[0] ? DateTime.fromJSDate(dates[0]).toISODate() : '';
      this.unappliedEndDate = dates[1] ? DateTime.fromJSDate(dates[1]).toISODate() : '';
    },
    dateShortcutChanged(dates) {
      this.unappliedStartDate = dates[0];
      this.unappliedEndDate = dates[1];
      this.currentDates = [this.$options.filters.dateFormat(dates[0]), this.$options.filters.dateFormat(dates[1])];
    },
    applyDates() {
      this.startDate = this.unappliedStartDate;
      this.endDate = this.unappliedEndDate;
      this.showDateFilters = false;
      this.refreshData();
    },
    refreshData() {
      this.fetchWorkOrdersDashboardData();
      this.getRecentWorkableWorkOrders();
      this.fetchCompletedWorkOrders();
    },
    fetchWorkOrdersDashboardData() {
      this.$technicianAnalyticsAPI
        .getWorkOrdersDashboard({
          start_date: this.startDate,
          end_date: this.endDate,
        })
        .then((response) => {
          const { data } = response;
          this.workOrdersDashboardData = data;
        })
        .catch(() => {
          this.workOrdersDashboardData = null;
        });
    },
    fetchCompletedWorkOrders() {
      this.$technicianAnalyticsAPI
        .getWorkOrdersCount({
          checked_out_at_from: this.startDate,
          checked_out_at_to: this.endDate,
          group_period: this.groupPeriod,
          group_by: 'checked_out_at',
          count_by: 'work_order_id',
        })
        .then((response) => {
          this.completedWorkOrdersData = {
            name: 'Completed',
            data: mapTimeSeriesData(response.series),
          };
        })
        .catch(() => {
          this.completedWorkOrdersData = { data: [] };
        });
    },
    getNextPayment() {
      this.$technicianUserPaymentsAPI
        .getTechnicianPayments({ due_date_from: DateTime.now().toISODate(), per_page: 1, page: 1, sort_by: 'due_date', sort_desc: false })
        .then((response) => {
          const { technicianPayments } = response;
          this.nextPayment = technicianPayments[0];
        })
        .catch(() => {
          this.nextPayment = null;
        });
    },
    getRecentWorkableWorkOrders() {
      this.$technicianWorkOrdersAPI
        .getWorkOrders({ status: TECHNICIAN_WORK_ORDER_STATUS_CURRENT, per_page: 6, page: 1, sort_by: 'id' })
        .then((response) => {
          const { workOrders } = response;
          this.workableWorkOrders = workOrders;
        })
        .catch(() => {
          this.workableWorkOrders = [];
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    greeting() {
      let timeOfDay = '';
      const currentHour = new Date().getHours();
      if (currentHour >= 5 && currentHour < 12) {
        timeOfDay = 'Morning';
      } else if (currentHour >= 12 && currentHour < 17) {
        timeOfDay = 'Afternoon';
      } else {
        timeOfDay = 'Evening';
      }

      return `Good ${timeOfDay}, ${this.$store.getters.user.first_name}`;
    },
    currentDateRange() {
      if (this.startDate && this.endDate) {
        return this.$options.filters.dateRangeFormat({ startDate: this.startDate, endDate: this.endDate }, 'startDate', 'endDate');
      } else {
        return '';
      }
    },
    currentWorkOrdersCurrent() {
      return this.workOrdersDashboardData?.current?.current || 0;
    },
    currentWorkOrdersInProgress() {
      return this.workOrdersDashboardData?.in_progress?.current || 0;
    },
    groupPeriod() {
      return calculateGroupPeriod(this.startDate, this.endDate);
    },
  },
  mounted() {
    this.getNextPayment();
    this.getRecentWorkableWorkOrders();
    this.refreshData();
  },
};
</script>
<style scoped>
.popover-filters {
  font-size: 1rem;
  min-width: 430px;
}
</style>
