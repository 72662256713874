<template>
  <sfs-form-modal
    :id="modalId"
    title="Add Supplies"
    @on-hide="resetFormData"
  >
    <supplies-form
      @on-submit="createSupply"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
      :vendor="vendor"
      :vendor_id="vendorId"
      :client="client"
      :client_id="clientId"
    />
  </sfs-form-modal>
</template>

<script>
import SuppliesForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'SuppliesAddModal',
  mixins: [formModalMixin],
  components: {
    SuppliesForm,
  },
  props: {
    vendor: Object,
    client: Object,
  },
  data() {
    return {
      modalId: 'supply-add-modal',
    };
  },
  methods: {
    createSupply(data, handleCreate) {
      handleCreate(this.$suppliesAPI.create(data));
    },
    onSave() {
      this.closeModal();
      this.resetFormData();
      this.$emit('on-save');
    },
  },
  computed: {
    vendorId() {
      return this.vendor?.id;
    },
    clientId() {
      return this.client?.id;
    },
  },
};
</script>
