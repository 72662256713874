<template>
  <sfs-base-list-filter
    title="Filter Work Orders"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
    :column-picker="false"
  >
    <template #quick-filters>
      <b-form-group
        placeholder="Filter by Work Order #, Description or Location"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Work Order #, Description or Location"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col>
          <b-form-group
            label="Status"
            label-for="popover-filter-status"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <v-select
              id="popover-filter-status"
              v-model="filters.status"
              :options="statusOptions"
              placeholder="All"
              :reduce="(status) => status.value"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-4">

      <b-row>
        <b-col>
          <b-form-group
            label="Scheduled Between"
            label-for="popover-filter-scheduled_between"
            label-cols-lg="5"
            content-cols-lg="7"
            class="mb-0"
          >
            <sfs-date-range-picker
              id="popover-filter-scheduled_between"
              v-model="filters.scheduled_between"
              placeholder="All"
              @on-change="onScheduledBetweenChanged"
            />
          </b-form-group>

          <small class="form-text text-muted mb-3">Filter on dates the work order is scheduled to be completed</small>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-4">

      <b-row>
        <b-col>
          <b-form-group
            label="Completed Between"
            label-for="popover-filter-completed_between"
            label-cols-lg="5"
            content-cols-lg="7"
            class="mb-0"
          >
            <sfs-date-range-picker
              id="popover-filter-completed_between"
              v-model="filters.completed_between"
              placeholder="All"
              @on-change="onCompletedBetweenChange"
            />
          </b-form-group>

          <small class="form-text text-muted mb-3">Filter on dates the work order was completed</small>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-4">

      <b-row>
        <b-col>
          <b-form-group
            :label="fieldLabels.client_id"
            label-for="popover-filter-client_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <client-select
              id="popover-filter-client_id"
              :client-id="filters.client_id"
              placeholder="All"
              :value="client"
              @input="onClientChange"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-4">

      <b-row>
        <b-col>
          <b-form-group
            :label="fieldLabels.location_id"
            label-for="popover-filter-location_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <location-select
              id="popover-filter-location_id"
              placeholder="All"
              :value="location"
              @input="onLocationChange"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-4">

      <b-row>
        <b-col>
          <b-form-group
            label="Completed By"
            label-for="popover-filter-done_by_id"
            label-cols-lg="5"
            content-cols-lg="7"
            class="mb-0"
          >
            <user-select
              id="work-order-reassign-done_by_id"
              select-label="name"
              placeholder="All"
              :primary-option="$store.getters.user"
              :value="doneBy"
              @input="onDoneByChange"
            />
          </b-form-group>

          <small class="form-text text-muted mb-3">Filter by who the work is to be completed by</small>
        </b-col>
      </b-row>
    </template>
    <template #right-tray>
      <slot name="right-tray" />
    </template>
  </sfs-base-list-filter>
</template>
<script>
import LocationSelect from '@/components/technician/locations/Select.vue';
import UserSelect from '@/components/technician/users/Select.vue';
import ClientSelect from '@/components/technician/clients/Select.vue';

import listFilterMixin from '@/mixins/listFilterMixin';
import {
  TECHNICIAN_WORK_ORDER_TABLE_COLUMN_OPTIONS,
  TECHNICIAN_WORK_ORDER_TABLE_DEFAULT_COLUMNS,
  TECHNICIAN_WORK_ORDER_TABLE_NAME,
  WORK_ORDER_FORM_LABELS,
} from '@/constants/workOrders';

export default {
  name: 'TechnicianWorkOrderListFilter',
  mixins: [listFilterMixin],
  components: {
    LocationSelect,
    UserSelect,
    ClientSelect,
  },
  data() {
    return {
      availableColumns: TECHNICIAN_WORK_ORDER_TABLE_COLUMN_OPTIONS,
      tableName: TECHNICIAN_WORK_ORDER_TABLE_NAME,
      fieldLabels: WORK_ORDER_FORM_LABELS,
      statusOptions: [],
      scheduled_between: [
        this.date_bucket_start_from && this.$options.filters.dateFormat(this.date_bucket_start_from),
        this.date_bucket_end_to && this.$options.filters.dateFormat(this.date_bucket_end_to),
      ],
      completed_between: [
        this.checked_out_at_from && this.$options.filters.dateFormat(this.checked_out_at_from),
        this.checked_out_at_to && this.$options.filters.dateFormat(this.checked_out_at_to),
      ],
    };
  },
  mounted() {
    this.getStatusOptions();
    this.loadInitialFilters();
  },
  methods: {
    applyPresetFilter(filters, columns) {
      this.filters = filters;
      this.displayedColumns = columns;
      this.applyFilters();
    },
    onDefaultPresetApplied() {
      this.applyPresetFilter({}, TECHNICIAN_WORK_ORDER_TABLE_DEFAULT_COLUMNS);
    },
    onClientChange(client) {
      this.filters.client_id = client ? client.id.toString() : '';
      this.client = client;
    },
    onLocationChange(location) {
      this.filters.location_id = location ? location.id.toString() : '';
      this.location = location;
    },
    onDoneByChange(user) {
      this.filters.done_by_id = user ? user.id.toString() : '';
      this.doneBy = user;
    },
    onScheduledBetweenChanged(dates) {
      if (dates[0]) {
        this.filters.date_bucket_start_from = this.$options.filters.dateFormat(dates[0].toISOString());
      } else {
        this.filters.date_bucket_start_from = '';
      }

      if (dates[1]) {
        this.filters.date_bucket_end_to = this.$options.filters.dateFormat(dates[1].toISOString());
      } else {
        this.filters.date_bucket_end_to = '';
      }
    },
    onCompletedBetweenChange(dates) {
      if (dates[0]) {
        this.filters.checked_out_at_from = this.$options.filters.dateFormat(dates[0].toISOString());
      } else {
        this.filters.checked_out_at_from = '';
      }

      if (dates[1]) {
        this.filters.checked_out_at_to = this.$options.filters.dateFormat(dates[1].toISOString());
      } else {
        this.filters.checked_out_at_to = '';
      }
    },
    getStatusOptions() {
      this.$technicianWorkOrdersAPI.getStatusOptions().then((response) => {
        const options = response.data.status_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
        this.statusOptions = [...options];
      });
    },
    loadInitialFilters() {
      const loadClient = new Promise((resolve) => {
        if (this.initialFilters.client_id) {
          this.$technicianClientsAPI.get(this.initialFilters.client_id).then((client) => {
            resolve(client);
          });
        } else {
          resolve(null);
        }
      });

      const loadLocation = new Promise((resolve) => {
        if (this.initialFilters.location_id) {
          this.$technicianLocationsAPI.get(this.initialFilters.location_id).then((location) => {
            resolve(location);
          });
        } else {
          resolve(null);
        }
      });

      const loadDoneBy = new Promise((resolve) => {
        if (this.initialFilters.done_by_id) {
          this.$technicianUsersAPI.get(this.initialFilters.done_by_id).then((doneBy) => {
            resolve(doneBy);
          });
        } else {
          resolve(null);
        }
      });

      Promise.all([loadClient, loadLocation, loadDoneBy]).then((values) => {
        this.client = values[0];
        this.location = values[1];
        this.doneBy = values[2];
        this.applyFilters();
      });
    },
  },
};
</script>
