<template>
  <b-form
    @submit.stop.prevent="handleSubmit"
    autocomplete="off"
  >
    <b-row>
      <b-col>
        <b-alert
          :show="hasBaseServerErrors()"
          variant="danger"
          dismissible
        >
          <p
            class="mb-0"
            v-for="(error, index) in baseServerErrors"
            :key="index"
          >
            {{ error }}
          </p>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.first_name"
          label-for="user-first_name"
        >
          <b-form-input
            id="user-first_name"
            v-model="$v.form.first_name.$model"
            :state="$v.form.first_name.$dirty ? !$v.form.first_name.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.first_name.$dirty"
            id="user-first_name-feedback"
          >
            <span v-if="!$v.form.first_name.required">Please enter a first name.</span>
            <span v-if="!$v.form.first_name.maxLength">
              The first name must be less than {{ $v.form.first_name.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.first_name.serverFailed">{{ serverErrors.first_name }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.last_name"
          label-for="user-last_name"
        >
          <b-form-input
            id="user-last_name"
            v-model="$v.form.last_name.$model"
            :state="$v.form.last_name.$dirty ? !$v.form.last_name.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.last_name.$dirty"
            id="user-last_name-feedback"
          >
            <span v-if="!$v.form.last_name.required">Please enter a last name.</span>
            <span v-if="!$v.form.last_name.maxLength">
              The last name must be less than {{ $v.form.last_name.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.last_name.serverFailed">{{ serverErrors.last_name }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.email"
          label-for="user-email"
        >
          <b-form-input
            id="user-email"
            v-model="$v.form.email.$model"
            autocomplete="new-password"
            :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
            type="text"
            placeholder="example@email.com"
          />
          <b-form-invalid-feedback
            v-if="$v.form.email.$dirty"
            id="user-email-feedback"
          >
            <span v-if="!$v.form.email.required">Please enter a email.</span>
            <span v-if="!$v.form.email.email">Please enter a valid email.</span>
            <span v-if="!$v.form.email.maxLength">
              The email must be less than {{ $v.form.email.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.email.serverFailed">{{ serverErrors.email }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.phone"
          label-for="user-phone"
        >
          <b-form-input
            id="user-phone"
            v-model="$v.form.phone.$model"
            :state="$v.form.phone.$dirty ? !$v.form.phone.$error : null"
            type="text"
            placeholder="(201) 555-5555"
          />
          <b-form-invalid-feedback
            v-if="$v.form.phone.$dirty"
            id="user-phone-feedback"
          >
            <span v-if="!$v.form.phone.maxLength">
              The phone must be less than {{ $v.form.phone.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.phone.serverFailed">{{ serverErrors.phone }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.category"
          label-for="user-category"
        >
          <category-select
            id="user-category"
            :select-class="{ 'is-invalid': $v.form.category.$dirty && $v.form.category.$error }"
            v-model="$v.form.category.$model"
            assignable-only
          />
          <b-form-invalid-feedback
            v-if="$v.form.category.$dirty"
            id="user-category-feedback"
          >
            <span v-if="!$v.form.category.required">Please enter a category.</span>
            <span v-if="!$v.form.category.serverFailed">{{ serverErrors.category }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          label-for="user-role"
          :label="fieldLabels.roles"
        >
          <role-select
            id="user-role"
            :select-class="{ 'is-invalid': $v.form.role_ids.$dirty && $v.form.role_ids.$error }"
            :value="form.roles"
            @input="onRolesChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.role_ids.$dirty"
            id="user-role-feedback"
          >
            <span v-if="!$v.form.role_ids.serverFailed">{{ serverErrors.role_ids }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="newForm">
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.password"
          label-for="user-password"
        >
          <b-input-group>
            <b-form-input
              id="user-password"
              v-model="$v.form.password.$model"
              autocomplete="new-password"
              :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
              :type="togglePassword1 ? 'text' : 'password'"
            />
            <b-input-group-append>
              <b-button
                class="rounded-right"
                variant="gray"
                @click="() => (togglePassword1 = !togglePassword1)"
              >
                <i
                  v-if="togglePassword1"
                  class="fa-regular fa-eye"
                />
                <i
                  v-else
                  class="fa-regular fa-eye-slash"
                />
              </b-button>
            </b-input-group-append>

            <b-form-invalid-feedback
              v-if="$v.form.password.$dirty"
              id="user-password-feedback"
            >
              <span v-if="!$v.form.password.required">Please enter a password.</span>
              <span v-if="!$v.form.password.minLength">
                The password must be at least {{ $v.form.password.$params.minLength.min }} characters long.
              </span>
              <span v-if="!$v.form.password.serverFailed">{{ serverErrors.password }}</span>
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.password_confirmation"
          label-for="user-password_confirmation"
        >
          <b-input-group>
            <b-form-input
              id="user-password_confirmation"
              autocomplete="new-password"
              v-model="$v.form.password_confirmation.$model"
              :state="$v.form.password_confirmation.$dirty ? !$v.form.password_confirmation.$error : null"
              :type="togglePassword2 ? 'text' : 'password'"
            />
            <b-input-group-append>
              <b-button
                class="rounded-right"
                variant="gray"
                @click="() => (togglePassword2 = !togglePassword2)"
              >
                <i
                  v-if="togglePassword2"
                  class="fa-regular fa-eye"
                />
                <i
                  v-else
                  class="fa-regular fa-eye-slash"
                />
              </b-button>
            </b-input-group-append>

            <b-form-invalid-feedback
              v-if="$v.form.password_confirmation.$dirty"
              id="user-password_confirmation-feedback"
            >
              <span v-if="!$v.form.password_confirmation.sameAs">Must match the password.</span>
              <span v-if="!$v.form.password_confirmation.serverFailed">
                {{ serverErrors.password_confirmation }}
              </span>
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mb-3">

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.manager_id"
          label-for="user-manager_id"
        >
          <employee-select
            id="user-manager_id"
            :select-class="{ 'is-invalid': $v.form.manager_id.$dirty && $v.form.manager_id.$error }"
            :value="form.manager"
            @input="onManagerChange"
            placeholder="Optional"
          />
          <b-form-invalid-feedback
            v-if="$v.form.manager_id.$dirty"
            id="user-manager_id-feedback"
          >
            <span v-if="!$v.form.manager_id.serverFailed">
              {{ serverErrors.manager_id }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          label-for="user-services_provided"
          :label="fieldLabels.services_provided"
        >
          <services-provided-select
            id="user-services_provided"
            :select-class="{ 'is-invalid': $v.form.services_provided.$dirty && $v.form.services_provided.$error }"
            placeholder="Optional"
            v-model="form.services_provided"
          />
          <small class="form-text text-secondary">These are the services that the User can provide</small>
          <b-form-invalid-feedback
            v-if="$v.form.services_provided.$dirty"
            id="user-services_provided-feedback"
          >
            <span v-if="!$v.form.services_provided.serverFailed">{{ serverErrors.services_provided }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>




    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.address"
          label-for="user-address"
        >
          <b-form-input
            id="user-address"
            v-model="$v.form.address.$model"
            :state="$v.form.address.$dirty ? !$v.form.address.$error : null"
            type="text"
            placeholder="Optional"
          />
          <b-form-invalid-feedback
            v-if="$v.form.address.$dirty"
            id="user-address-feedback"
          >
            <span v-if="!$v.form.address.maxLength">
              The address must be less than {{ $v.form.address.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.address.serverFailed">{{ serverErrors.address }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.city"
          label-for="user-city"
        >
          <b-form-input
            id="user-city"
            v-model="$v.form.city.$model"
            :state="$v.form.city.$dirty ? !$v.form.city.$error : null"
            type="text"
            placeholder="Optional"
          />
          <b-form-invalid-feedback
            v-if="$v.form.city.$dirty"
            id="user-city-feedback"
          >
            <span v-if="!$v.form.city.maxLength">
              The city must be less than {{ $v.form.city.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.city.serverFailed">{{ serverErrors.city }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.state"
          label-for="user-state"
        >
          <b-form-input
            id="user-state"
            v-model="$v.form.state.$model"
            :state="$v.form.state.$dirty ? !$v.form.state.$error : null"
            type="text"
            placeholder="Optional"
          />
          <b-form-invalid-feedback
            v-if="$v.form.state.$dirty"
            id="user-state-feedback"
          >
            <span v-if="!$v.form.state.maxLength">
              The state must be less than {{ $v.form.state.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.state.serverFailed">{{ serverErrors.state }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.zip_code"
          label-for="user-zip_code"
        >
          <b-form-input
            id="user-zip_code"
            v-model="$v.form.zip_code.$model"
            :state="$v.form.zip_code.$dirty ? !$v.form.zip_code.$error : null"
            type="text"
            placeholder="Optional"
          />
          <b-form-invalid-feedback
            v-if="$v.form.zip_code.$dirty"
            id="user-zip_code-feedback"
          >
            <span v-if="!$v.form.zip_code.maxLength">
              The zip code must be less than {{ $v.form.zip_code.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.zip_code.serverFailed">{{ serverErrors.zip_code }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          id="user-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          id="user-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, requiredIf, maxLength, email, minLength, sameAs } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import EmployeeSelect from '@/components/admin/users/EmployeeSelect.vue';
import RoleSelect from '@/components/admin/users/RoleSelect.vue';
import CategorySelect from '@/components/admin/users/CategorySelect.vue';
import ServicesProvidedSelect from '@/components/admin/users/ServicesProvidedSelect.vue';

import { USER_FORM_LABELS, USER_TECHNICIAN_CATEGORY, USER_SUB_TECHNICIAN_CATEGORY } from '@/constants/users';

export default {
  name: 'UsersForm',
  components: {
    EmployeeSelect,
    RoleSelect,
    CategorySelect,
    ServicesProvidedSelect,
  },
  mixins: [formMixin],
  props: {
    newForm: Boolean,
    category: String,
    role_ids: Array,
    roles: Array,
    first_name: String,
    last_name: String,
    phone: String,
    email: String,
    address: String,
    city: String,
    state: String,
    zip_code: String,
    password: String,
    password_confirmation: String,
    manager_id: [String, Number],
    manager: Object,
    services_provided: Array,
  },
  data() {
    return {
      form: {
        newForm: this.newForm,
        title: this.title,
        category: this.category,
        role_ids: this.role_ids,
        roles: this.roles,
        first_name: this.first_name,
        last_name: this.last_name,
        phone: this.phone,
        email: this.email,
        address: this.address,
        city: this.city,
        state: this.state,
        zip_code: this.zip_code,
        password: this.password,
        password_confirmation: this.password_confirmation,
        manager_id: this.manager_id,
        manager: this.manager,
        services_provided: this.services_provided,
        organization_id: this.$store.getters.currentOrganization?.id,
      },
      fieldLabels: {
        ...USER_FORM_LABELS,
      },
      togglePassword1: false,
      togglePassword2: false,
    };
  },
  validations: {
    form: {
      first_name: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('first_name');
        },
      },
      last_name: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('last_name');
        },
      },
      email: {
        required,
        email,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('email');
        },
      },
      phone: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('phone');
        },
      },
      address: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('address');
        },
      },
      city: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('city');
        },
      },
      state: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('state');
        },
      },
      zip_code: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('zip_code');
        },
      },
      category: {
        required,
        serverFailed() {
          return !this.hasServerErrors('category');
        },
      },
      role_ids: {
        serverFailed() {
          return !this.hasServerErrors('role_ids');
        },
      },
      password: {
        required: requiredIf(function () {
          return this.newForm;
        }),
        minLength: minLength(8),
        serverFailed() {
          return !this.hasServerErrors('password');
        },
      },
      password_confirmation: {
        sameAs: sameAs('password'),
        serverFailed() {
          return !this.hasServerErrors('password_confirmation');
        },
      },
      services_provided: {
        serverFailed() {
          return !this.hasServerErrors('services_provided');
        },
      },
      manager_id: {
        serverFailed() {
          return !this.hasServerErrors('manager_id');
        },
      },
    },
  },
  methods: {
    isTechnician(category) {
      return [USER_TECHNICIAN_CATEGORY, USER_SUB_TECHNICIAN_CATEGORY].includes(category);
    },
    onManagerChange(user) {
      this.form.manager_id = user ? user.id : '';
      this.form.manager = user;
    },
    onRolesChange(roles) {
      this.form.role_ids = roles ? roles.map((role) => role.id) : '';
      this.form.roles = roles;
    },
  },
};
</script>
