<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit Technician Service"
    @on-hide="resetFormData"
  >
    <technician-services-form
      v-bind="value"
      @on-submit="updateTechnicianService"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import TechnicianServicesForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'TechnicianServicesEditModal',
  mixins: [formModalMixin],
  components: {
    TechnicianServicesForm,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: `technician-service-${this.value.id}-edit-modal`,
    };
  },
  methods: {
    updateTechnicianService(data, handleUpdate) {
      handleUpdate(this.$technicianServicesAPI.update(this.value.id, data));
    },
    onSave(technicianService) {
      this.closeModal();
      this.$emit('input', technicianService);
    },
  },
};
</script>
