import { ACTIVE_TABLE_FILTER_OPTIONS } from '@/constants';
import { LOCATION_FLOOR_TYPE_FORMAT_OPTIONS } from '@/constants/locations';
import { SET_CLIENT_MUTATION } from '@/constants/mutations.js';
import { isEqual as _isEqual, isUndefined as _isUndefined } from 'lodash';

export default {
  props: {
    totalRows: {
      type: Number,
      default: 0,
    },
    initialFilters: {
      type: Object,
      default: () => new Object(),
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      displayedColumns: this.columns,
      availableColumns: [],
      appliedFilters: {},
      filters: { ...this.initialFilters },
      activeOptions: ACTIVE_TABLE_FILTER_OPTIONS,
      user: null,
      client: null,
      manager: null,
      technician: null,
      subTechnician: null,
      location: null,
      service: null,
      assignedTo: null,
      doneBy: null,
      vendor: null,
      roles: null,
      parentWorkOrder: null,
    };
  },
  methods: {
    applyFilters() {
      this.appliedFilters = Object.assign({}, this.filters);
      if (this.filters.active != null) {
        this.appliedFilters.active = ['true', true].includes(this.filters.active) ? 'Yes' : 'No';
      }
      this.appliedFilters.user_id = this.user ? this.user.name : '';
      this.appliedFilters.client_id = this.client ? this.client.code : '';
      this.appliedFilters.manager_id = this.manager ? this.manager.name : '';
      this.appliedFilters.sub_technician_id = this.subTechnician ? this.subTechnician.name : '';
      this.appliedFilters.technician_id = this.technician ? this.technician.name : '';
      this.appliedFilters.location_id = this.location ? this.location.store_number : '';
      this.appliedFilters.location_id = this.location && this.location.name ? `${this.appliedFilters.location_id} - ${this.location.name}` : this.appliedFilters.location_id;
      this.appliedFilters.service_id = this.service ? this.service.name : '';
      this.appliedFilters.assigned_to_id = this.assignedTo ? this.assignedTo.name : '';
      this.appliedFilters.done_by_id = this.doneBy ? this.doneBy.name : '';
      this.appliedFilters.vendor_id = this.vendor ? this.vendor.name : '';
      this.appliedFilters.parent_id = this.parentWorkOrder ? this.parentWorkOrder.number : '';
      this.appliedFilters.role_ids = this.roles ? this.roles.map((role) => role.name).join(', ') : '';
      this.appliedFilters.category = this.humanize(this.filters.category || '');
      this.appliedFilters.service_category = this.humanize(this.filters.service_category || '');
      this.appliedFilters.charge_category = this.humanize(this.filters.charge_category || '');
      this.appliedFilters.invoice_delivery_method = this.humanize(this.filters.invoice_delivery_method || '');
      this.appliedFilters.floor_type = LOCATION_FLOOR_TYPE_FORMAT_OPTIONS[this.filters.floor_type];
      this.appliedFilters.status = this.humanize(this.filters.status || '');
      this.appliedFilters.completion_source = this.humanize(this.filters.completion_source || '');
      this.appliedFilters.start_date = this.filters.start_date ? `> ${this.filters.start_date}` : '';
      this.appliedFilters.end_date = this.filters.end_date ? `< ${this.filters.end_date}` : '';
      if (this.filters.emailed != null) {
        this.appliedFilters.emailed = this.filters.emailed === 'true' ? 'Yes' : 'No';
      }
      if (this.filters.approved != null) {
        this.appliedFilters.approved = this.filters.approved === 'true' ? 'Yes' : 'No';
      }
      if (this.filters.verified != null) {
        this.appliedFilters.verified = this.filters.verified === 'true' ? 'Yes' : 'No';
      }
      if (this.filters.send_physical_invoice != null) {
        this.appliedFilters.send_physical_invoice = this.filters.send_physical_invoice == 'true' ? 'Yes' : 'No';
      }
      if (this.filters.backordered != null) {
        this.appliedFilters.backordered = this.filters.backordered === 'true' ? 'Yes' : 'No';
      }
      if (this.filters.technician_paid != null) {
        this.appliedFilters.technician_paid = this.filters.technician_paid === 'true' ? 'Yes' : 'No';
      }
      if (this.filters.qr_code_access != null) {
        this.appliedFilters.qr_code_access = this.filters.qr_code_access === 'true' ? 'Yes' : 'No';
      }
      if (this.filters.technician_portal_access != null) {
        this.appliedFilters.technician_portal_access = this.filters.technician_portal_access === 'true' ? 'Yes' : 'No';
      }
      if (this.filters.reviewed != null) {
        this.appliedFilters.reviewed = this.filters.reviewed === 'true' ? 'Yes' : 'No';
      }
      if (this.filters.finalized != null) {
        this.appliedFilters.finalized = this.filters.finalized === 'true' ? 'Yes' : 'No';
      }
      if (this.filters.invoiced != null) {
        this.appliedFilters.invoiced = this.filters.invoiced === 'true' ? 'Yes' : 'No';
      }
      if (this.filters.emailed != null) {
        this.appliedFilters.emailed = this.filters.emailed === 'true' ? 'Yes' : 'No';
      }
      if (this.filters.emailable != null) {
        this.appliedFilters.emailable = this.filters.emailable === 'true' ? 'Yes' : 'No';
      }
      if (this.filters.check_in_distance != null) {
        this.appliedFilters.check_in_distance > `${this.filters.check_in_distance} mi`;
      }
      this.appliedFilters.statuses = this.filters.statuses
        ? this.filters.statuses.map((status) => this.humanize(status)).join(', ')
        : '';
      this.appliedFilters.cancellation_reasons = this.filters.cancellation_reasons
        ? this.filters.cancellation_reasons.map((reason) => this.humanize(reason)).join(', ')
        : '';
      this.appliedFilters.completion_sources = this.filters.completion_sources
        ? this.filters.completion_sources.map((reason) => this.humanize(reason)).join(', ')
        : '';
      this.appliedFilters.services_provided = this.filters.services_provided
        ? this.filters.services_provided.map((reason) => this.humanize(reason)).join(', ')
        : '';


      this.$emit('filters-applied', {
        columns: this.displayedColumns,
        filters: this.filters,
      });
    },
    humanize(words) {
      return this.$options.filters.capitalizeFormat(this.$options.filters.humanizedFormat(words));
    },
    onFilterCleared(key) {
      if (key == 'user_id') {
        this.user = null;
      }
      if (key == 'client_id') {
        this.$store.commit(SET_CLIENT_MUTATION, { client: null });
        this.client = null;
      }
      if (key == 'manager_id') {
        this.manager = null;
      }
      if (key == 'technician_id') {
        this.technician = null;
      }
      if (key == 'sub_technician_id') {
        this.subTechnician = null;
      }
      if (key == 'location_id') {
        this.location = null;
      }
      if (key == 'service_id') {
        this.service = null;
      }
      if (key == 'assigned_to_id') {
        this.assignedTo = null;
      }
      if (key == 'done_by_id') {
        this.doneBy = null;
      }
      if (key == 'vendor_id') {
        this.vendor = null;
      }
      if (key == 'parent_id') {
        this.parentWorkOrder = null;
      }
      if (key == 'role_ids') {
        this.roles = null;
      }
      if (key == 'scheduled_between') {
        this.filters.date_bucket_start_from = null;
        this.filters.date_bucket_end_to = null;
      }
      if (key == 'completed_between') {
        this.filters.checked_out_at_from = null;
        this.filters.checked_out_at_to = null;
      }
      if (key == 'date_between') {
        this.filters.date_to = null;
        this.filters.date_from = null;
      }
      if (key == 'due_date_between') {
        this.filters.due_date_to = null;
        this.filters.due_date_from = null;
      }
      this.filters[key] = null;
      this.applyFilters();
    },
    onFiltersApplied(displayedColumns) {
      this.displayedColumns = displayedColumns;
      this.applyFilters();
    },
  },
  watch: {
    initialFilters: {
      deep: true,
      handler(newFilters, oldFilters) {
        if (!_isEqual(oldFilters, newFilters) && !_isUndefined(newFilters)) {
          this.filters = Object.assign(this.filters, newFilters);
          this.applyFilters();
        }
      }
    },
  },
};
