<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit Supplies"
    @on-hide="resetFormData"
    class="text-left"
  >
    <supplies-form
      v-bind="value"
      :new-form="false"
      @on-submit="updateSupply"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import SuppliesForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'SuppliesEditModal',
  mixins: [formModalMixin],
  components: {
    SuppliesForm,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: `supply-${this.value.id}-edit-modal`,
    };
  },
  methods: {
    updateSupply(data, handleUpdate) {
      handleUpdate(this.$suppliesAPI.update(this.value.id, data));
    },
    onSave(supply) {
      this.closeModal();
      this.$emit('on-save', supply);
    },
  },
};
</script>
