<template>
  <base-layout>
    <!-- Sidebar Content -->
    <template #sidebar>
      <base-sidebar-menu :items="items" />
      <!-- Modals -->
      <edit-profile-modal
        v-if="$can('profile', 'User')"
        lazy-load
      />
      <edit-password-modal
        v-if="$can('update_password', 'User')"
        lazy-load
      />
    </template>
    <!-- END Sidebar Content -->
    <template #footer>
      <div class="content py-3">
        <b-row>
          <b-col
            sm="12"
            order-sm="1"
            class="text-center"
          >
            <a
              class="font-w600"
              href="http://scopesfs.com/"
              target="_blank"
            >
              {{ $store.getters.appName }}
            </a>
            &copy; {{ $store.getters.appCopyright }}
          </b-col>
        </b-row>
      </div>
    </template>
    <!-- END Footer Content -->
  </base-layout>
</template>

<script>
import { compact as _compact } from 'lodash';
import BaseLayout from '@/components/shared/layouts/Base';
import BaseSidebarMenu from '@/components/shared/BaseSidebarMenu';
import EditProfileModal from '@/components/shared/users/EditProfileModal.vue';
import EditPasswordModal from '@/components/shared/users/EditPasswordModal.vue';
import showAppOutOfDatePopup from '@/utils/showAppOutOfDatePopup.js';
import { SET_OUT_OF_DATE_MUTATION } from '@/constants/mutations.js';

import store from '@/store';

export default {
  name: 'TechnicianLayout',
  components: {
    BaseLayout,
    BaseSidebarMenu,
    EditProfileModal,
    EditPasswordModal,
  },
  data() {
    return {
      items: _compact([
        {
          title: 'Dashboard',
          href: { path: '/technician/dashboard' },
          icon: 'si si-speedometer',
          exactPath: true,
        },
        {
          title: 'Work Orders',
          href: '/technician/work_orders',
          icon: 'si si-tag',
          exactPath: true,
        },
        {
          title: 'Users',
          href: '/technician/users',
          icon: 'si si-people',
          exactPath: true,
        },
        {
          title: 'Payments',
          href: '/technician/payments',
          icon: 'si si-wallet',
          exactPath: true,
        },
        {
          title: 'My Account',
          icon: 'si si-user',
          child: _compact([
            {
              title: 'Edit My Profile',
              href: '',
              icon: '',
              modalId: 'edit-profile-modal',
            },
            {
              title: 'Update My Password',
              href: '',
              icon: '',
              modalId: 'update-password-modal',
            },
            !!this.$store.getters.impersonatingToken && {
              title: 'Stop Impersonating',
              href: '',
              icon: '',
              action: () => {
                this.$store.dispatch('unimpersonateUser').then(() => {
                  this.$router.push({ name: 'admin-dashboard' });
                });
              }
            },
            {
              title: 'Logout',
              href: '/logout',
              icon: '',
            },
          ]),
        }
      ]),
    };
  },
  created() {
    // Set default elements for this layout
    this.$store.commit('setLayout', {
      footer: true,
      sidebar: true,
    });

    // Set various template options
    this.$store.commit('mainContent', { mode: 'boxed' });
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_OUT_OF_DATE_MUTATION) {
        if (!state.app.upToDate && !this.$store.getters.hasUpToDatePopupRendered) {
          showAppOutOfDatePopup();
        }
      }
    });
  },
  beforeRouteEnter(to, from, next) {
    store
      .dispatch('getProfile')
      .then(() => next())
      .catch((error) => {
        if (error?.response?.status == 412) {
          showAppOutOfDatePopup();
        }
      });
  },
};
</script>
