<template>
  <sfs-form-modal
    :id="modalId"
    title="Work Order Schedule"
  >
    <recurring-work-order-frequencies-form
      :month-options="monthOptions"
      :frequency-options="frequencyOptions"
      v-bind="value"
      @on-submit="update"
      @on-save="onSave"
      @on-cancel="closeModal"
    />
  </sfs-form-modal>
</template>

<script>
import RecurringWorkOrderFrequenciesForm from './FrequenciesForm.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'RecurringWorkOrderFrequenciesModal',
  mixins: [formModalMixin],
  components: {
    RecurringWorkOrderFrequenciesForm,
  },
  props: {
    value: Object,
    monthOptions: {
      type: Array,
      default: () => [],
    },
    frequencyOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        frequencies: this.value.frequencies,
      },
      modalId: `recurring-work-order-${this.value.id}-frequencies-modal`,
    };
  },
  methods: {
    update(data, handleUpdate) {
      handleUpdate(this.$recurringWorkOrdersAPI.update(this.value.id, data));
    },
    onSave(recurringWorkOrder) {
      this.closeModal();
      this.$emit('on-save', recurringWorkOrder);
    },
  },
};
</script>
