<template>
  <div>
    <b-row
      v-if="internalSections.length === 0 && !parentId"
      class="my-2 ml-2"
    >
      <b-col>
        Click "Add Section" to get started.
      </b-col>
    </b-row>
    <draggable
      group="children"
      :animation="250"
      :sort="false"
      tag="div"
      handle=".drag-handle"
      :list="internalSections"
      @change="(changes) => $emit('input', { parent: undefined, changes })"
    >
      <div
        :key="section.id"
        v-for="section in internalSections"
      >
        <b-form-group
          label="Section Name"
          :label-for="`section-${section.id}-name`"
          label-sr-only
          class="section-group mb-0"
        >
          <b-row class="mr-2">
            <b-col
              cols="10"
              class="mr-auto"
            >
              <b-input-group>
                <b-form-input
                  :id="`section-${section.id}-name`"
                  class="section-name-input my-1 d-inline-block"
                  :value="section.name"
                  type="text"
                  :autofocus="!section.id"
                  @change="(newName) => handleNameChanged(section, newName)"
                />
                <template #prepend>
                  <i class="section-drag-handle far fa-solid fa-grip-vertical drag-handle text-secondary ml-4 mr-2" />
                </template>
              </b-input-group>
            </b-col>
            <b-col cols="auto">
              <b-button
                class="section-trash-button"
                variant="link"
                @click="destroySection(section)"
              >
                <i class="far fa-trash-can" />
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>

        <sections-tree
          v-if="section.children"
          :parent-id="section.id"
          :location-id="locationId"
          class="nav-depth-1"
          :sections="section.children"
          @input="({ parent: parentSection, changes }) => $emit('input', { parent: parentSection || section, changes: changes })"
        />
      </div>
    </draggable>
    <b-button
      v-if="!parentId"
      variant="outline-secondary"
      class="sections-add-button my-3 ml-2"
      @click="addEmptySection"
    >
      <i class="fa fa-fw fa-plus" /> Add Section
    </b-button>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
export default {
  name: 'SectionsTree',
  components: {
    draggable,
  },
  props: {
    sections: {
      type: Array,
      required: true,
    },
    parentId: {
      type: Number,
    },
    locationId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      internalSections: this.sections,
    };
  },
  methods: {
    handleNameChanged(section, newName) {
      if (newName.length > 0) {
        if (section.id) {
          this.$sectionsAPI
            .update(section.id, { name: newName })
            .then((updatedSection) => {
              this.$emit('section-updated', updatedSection);
            });
        } else {
          this.$sectionsAPI
            .create({ location_id: this.locationId, parent_id: this.parentId, name: newName })
            .then((newSection) => {
              this.internalSections = this.internalSections.filter((s) => s.id !== null);
              this.internalSections.push({ id: newSection.id, name: newSection.name, parent_id: this.parentId, children: [], depth: 0 });
            });
        }

      }
    },
    addEmptySection() {
      this.internalSections.push({ id: null, name: '', parent_id: this.parentId });
    },
    destroySection(section) {
      this.$swal({
        text: 'Once it\'s deleted, its gone forever!',
        preConfirm: () => this.$sectionsAPI.destroy(section.id),
      }).then((result) => {
        if (result.value) {
          this.internalSections = this.internalSections.filter((s) => s.id !== section.id);
        }
        result.timer;
      });
    },
  }
};
</script>

<style scoped>
.section-name-input.form-control {
    border: none;
    background-color: #FFFFFF;
    min-height: 42px;
}
.section-group {
  background: #FFFFFF;
}
.section-group .input-group-prepend {
  align-self: center;
}
.section-group:hover, .section-group:hover .section-name-input {
  background-color: #F8F9FA;
  border-radius: 6px;
}
.section-trash-button:hover {
  background-color: #F8F9FA;
}
</style>
