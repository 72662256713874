<template>
  <sfs-form-modal
    :id="modalId"
    title="Remove Work Orders from Invoice Import"
    @on-hide="resetFormData"
  >
    <imports-form
      @on-submit="createImport"
      @on-save="handleSave"
      @on-cancel="closeModal"
      :key="formKey"
    >
      <template #downloads>
        <b-row>
          <b-col cols="12">
            <sfs-download-button
              label="Download Template"
              filename="Remove Work Orders From Invoice Template.xlsx"
              :file-provider="$importsAPI.getRemoveWorkOrdersFromInvoiceTemplate"
            />
          </b-col>
        </b-row>
      </template>
    </imports-form>
  </sfs-form-modal>
</template>

<script>
import ImportsForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'RemoveWorkOrdersFromInvoiceImportsAddModal',
  mixins: [formModalMixin],
  components: {
    ImportsForm,
  },
  data() {
    return {
      modalId: 'remove-work-orders-from-invoice-imports-add-modal',
    };
  },
  methods: {
    createImport(data, handleCreate) {
      handleCreate(this.$importsAPI.removeWorkOrdersFromInvoiceCreate(data));
    },
    handleSave(data) {
      if (this.$route.name != 'imports') {
        this.$router.push({ name: 'imports' });
      } else {
        this.closeModal();
      }
      this.$emit('on-save', data);
    },
  },
};
</script>
