<template>
  <div>
    <base-page-heading
      title="Supply Orders"
      show-back-button
    >
      <template #extra>
        <b-button
          v-if="$can('create', 'SupplyOrder')"
          variant="primary"
          class="mr-2"
          @click="() => $router.push({ name: 'new-supply-order' })"
        >
          <i class="fa fa-plus mr-1" />
          New Order
        </b-button>

        <sfs-download-button
          v-b-tooltip.hover
          :provider-params="filters"
          filename="Supply Orders.csv"
          :file-provider="$supplyOrdersAPI.getSpreadsheet"
          title="Download spreadsheet"
          label="Download"
        />
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <supply-orders-list-filter
            :table-name="tableName"
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>

      <supply-orders-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
  </div>
</template>

<script>
import SupplyOrdersTable from '@/components/admin/supplyOrders/Table.vue';
import SupplyOrdersListFilter from '@/components/admin/supplyOrders/ListFilter.vue';
import listMixin from '@/mixins/listMixin';
import { SUPPLY_ORDER_TABLE_NAME } from '@/constants/supplyOrders';

export default {
  name: 'SupplyOrdersList',
  components: {
    SupplyOrdersListFilter,
    SupplyOrdersTable,
  },
  mixins: [listMixin],
  props: {
    text: String,
    client_id: String,
    vendor_id: String,
    status: String,
    backordered: String,
  },
  data() {
    return {
      tableName: SUPPLY_ORDER_TABLE_NAME,
      filters: {
        text: this.text,
        client_id: this.client_id,
        vendor_id: this.vendor_id,
        status: this.status,
        backordered: this.backordered,
      },
    };
  },
};
</script>
