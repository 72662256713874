import List from '@/pages/admin/invoices/List.vue';
import Show from '@/pages/admin/invoices/Show.vue';
import handlePaginationRouteProps from '@/utils/handlePaginationRouteProps.js';

export default [
  {
    path: 'invoices',
    name: 'invoices',
    component: List,
    props: handlePaginationRouteProps,
  },
  {
    path: 'invoices/:id(\\d+)',
    name: 'invoice',
    component: Show,
  },
];
