<template>
  <router-view />
</template>

<style lang="scss">
// Main Stylesheet
@import "./src/assets/scss/main";

// Bootstrap Vue Stylesheet
@import "~bootstrap-vue/src/index";

// Custom overrides
@import "~vue-select/src/scss/vue-select";
@import "./src/assets/scss/custom/vendor/vue-select";
@import "~flatpickr/dist/flatpickr.css";
@import "~sweetalert2/dist/sweetalert2.min.css";
@import "~vue2-dropzone/dist/vue2Dropzone.min.css";
@import "./src/assets/scss/custom/vendor/dropzone";
</style>

<script>
export default {
  name: 'App',
};
</script>
