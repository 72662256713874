<template>
  <sfs-form-modal
    :id="modalId"
    title="Add a Technician Payment"
    @on-hide="resetFormData"
  >
    <technician-payments-form
      @on-submit="createTechnicianPayment"
      @on-save="redirectToTechnicianPayment"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import TechnicianPaymentsForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'TechnicianPaymentsAddModal',
  mixins: [formModalMixin],
  components: {
    TechnicianPaymentsForm,
  },
  data() {
    return {
      modalId: 'technician-payment-add-modal',
    };
  },
  methods: {
    createTechnicianPayment(data, handleCreate) {
      handleCreate(this.$technicianPaymentsAPI.create(data));
    },
    redirectToTechnicianPayment(technician_payment) {
      this.$router.push({ name: 'technician-payment', params: { id: technician_payment.id } });
    },
  },
};
</script>
