<template>
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :value="value"
    @input="(category) => $emit('input', category)"
    :reduce="(category) => category.value"
  />
</template>
<script>
import { GL_CODE_TECHNICIAN_PAYMENT_CATEGORY, GL_CODE_INVOICE_CATEGORY } from '@/constants/glCodes';

export default {
  name: 'CategorySelect',
  props: {
    value: String,
    id: {
      type: String,
      default: 'category-select',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: {
      type: String,
      default: 'Select a Category',
    },
  },
  data() {
    return {
      options: [{label: 'Technician Payment', value: GL_CODE_TECHNICIAN_PAYMENT_CATEGORY }, { label: 'Invoice', value: GL_CODE_INVOICE_CATEGORY }],
    };
  },
};
</script>
