import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '../helpers/apiPath';

export default {
  getVersions(params) {
    return axios.get(adminPath('/versions?') + prepareQueryString(params)).then((response) => {
      return {
        versions: response.data.map((version) => an('Version', version)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/versions.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
};
