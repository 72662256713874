<template>
  <div>
    <div class="options-container rounded">
      <img
        v-if="hasImages"
        class="img-fluid rounded options-item w-100"
        style="height: 375px;"
        :src="images[0].url"
      >
      <div
        v-else
        class="options-item py-6 mb-0 d-flex justify-content-center align-items-center"
        style="height: 375px;"
      >
        <div class="text-center push mt-50">
          <h5 class="mb-0">
            No Photos
          </h5>
        </div>
      </div>

      <div class="options-overlay bg-black-50 mb-2">
        <div class="options-overlay-content">
          <b-button
            v-if="$can('upload_photo', workOrder)"
            variant="light"
            size="sm"
            @click="$bvModal.show(newPhotoModalId)"
          >
            <i class="fa fa-plus mr-1" />
            Add
          </b-button>

          <b-button
            v-if="hasImages"
            class="ml-2"
            variant="light"
            size="sm"
            @click="$bvModal.show(modalId)"
          >
            <i class="fa fa-eye mr-1" />
            View
          </b-button>
        </div>
      </div>
    </div>

    <b-modal
      :id="modalId"
      :title="title"
      size="md"
      no-close-on-backdrop
      hide-footer
      static
    >
      <template #modal-header-close>
        <i class="fa fa-close" />
      </template>
      <b-carousel
        fade
        controls
        indicators
        :interval="0"
      >
        <b-carousel-slide
          v-for="(image, index) in images"
          :key="index"
          :img-src="image.url"
        >
          <template #default>
            <b-button
              v-if="$can('delete_photo', workOrder)"
              variant="light"
              @click="removePhoto(image)"
              v-b-tooltip.hover
              title="Remove the Photo"
              class="btn-remove"
            >
              <i class="fa fa-trash-alt" />
            </b-button>
            <b-button
              v-b-tooltip.hover
              class="btn-download"
              title="Download Photo"
              variant="light"
              :href="image.url"
              download
            >
              <i class="fa fa-download" />
            </b-button>
            <p class="lead">
              {{ caption(image) }}
            </p>
          </template>
        </b-carousel-slide>
      </b-carousel>

      <hr class="mt-4">

      <b-row>
        <b-col
          cols="3"
          offset="9"
        >
          <b-button
            variant="primary"
            block
            @click="closeModal"
          >
            Close
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Modals -->
    <new-photo-modal
      :title="newPhotoModalTitle"
      :modal-id="newPhotoModalId"
      :work-order-id="workOrder.id"
      :before-photo="beforePhotos"
      :after-photo="afterPhotos"
      @on-save="onSave"
      :key="newPhotoKey"
    />
  </div>
</template>

<script>
import NewPhotoModal from '@/components/technician/workOrders/NewPhotoModal';

export default {
  name: 'TechnicianPhotoCarouselModal',
  components: {
    NewPhotoModal,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
    },
    workOrder: {
      type: Object,
      required: true,
    },
    beforePhotos: {
      type: Boolean,
      default: false,
    },
    afterPhotos: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newPhotoKey: Date.now(),
    };
  },
  watch: {
    images() {
      if (!this.hasImages) {
        this.closeModal();
      }
    },
  },
  computed: {
    hasImages() {
      return this.images?.length > 0;
    },
    title() {
      if (this.beforePhotos) {
        return 'Before Photos';
      } else if (this.afterPhotos) {
        return 'After Photos';
      } else {
        return 'No Show Photos';
      }
    },
    newPhotoModalTitle() {
      if (this.beforePhotos) {
        return 'New Before Photos';
      } else if (this.afterPhotos) {
        return 'New After Photos';
      } else {
        return 'New No Show Photos';
      }
    },
    newPhotoModalId() {
      if (this.beforePhotos) {
        return 'technician-work-order-new-before-photo-modal';
      } else if (this.afterPhotos) {
        return 'technician-work-order-new-after-photo-modal';
      } else {
        return 'technician-work-order-new-no-show-photo-modal';
      }
    },
    deleteProvider() {
      if (this.beforePhotos) {
        return this.$technicianWorkOrdersAPI.destroyBeforePhoto;
      } else if (this.afterPhotos) {
        return this.$technicianWorkOrdersAPI.destroyAfterPhoto;
      } else {
        return this.$technicianWorkOrdersAPI.destroyNoShowPhoto;
      }
    },
  },
  methods: {
    removePhoto(photo) {
      this.$swal({
        text: 'Once it\'s deleted, it cannot be undone!',
        preConfirm: () => {
          this.deleteProvider(this.workOrder.id, photo.id);
        },
      }).then((result) => {
        if (result.value) {
          this.$emit('on-destroy', photo);
        }
        result.timer;
      });
    },
    onSave() {
      this.$emit('on-save');
      this.refreshNewPhotoKey();
    },
    refreshNewPhotoKey() {
      this.newPhotoKey = Date.now();
    },
    caption(image) {
      return `${this.$options.filters.dateTimeFormat(image.created_at, this.workOrder.location.time_zone)}`;
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>

<style #scoped>
.carousel-caption {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.carousel-caption .btn-remove {
  position: absolute;
  right: 65px;
}
.carousel-caption .btn-download {
  position: absolute;
  right: 20px;
}

.carousel-caption .fa {
  font-size: 1.2em;
}

.carousel-caption .lead {
  background: rgba(7, 7, 7, 0.5) !important;
  position: absolute;
  bottom: 30px;
  width: 100%;
}

.carousel-control-prev,
.carousel-control-next {
  height: 50%;
  top: unset;
  bottom: 25%;
}
</style>
