<template>
  <sfs-base-list-filter
    title="Filter Insurance Certificates"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
  >
    <template #quick-filters>
      <b-form-group
        label="Search fields for Data"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Policy Number, User Name, or Email"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #advanced-filters>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            class="mb-0"
            label="Status"
            label-for="advanced-filter-status"
            label-sr-only
          >
            <status-select
              id="advanced-filter-status"
              v-model="filters.status"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="User"
            label-for="advanced-filter-user_id"
            label-sr-only
          >
            <user-select
              id="advanced-filter-user_id"
              placeholder="User"
              :value="user"
              :filter-params="userSelectChange"
              @input="onUserChanged"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            class="mb-0"
            label="Category"
            label-for="advanced-filter-category"
            label-sr-only
          >
            <category-select
              id="advanced-filter-category"
              v-model="filters.category"
              :options="categoryOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Phone"
            label-for="advanced-filter-phone"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-phone"
              v-model="filters.phone"
              type="text"
              placeholder="Inurance Phone"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Insurance Email"
            label-for="advanced-filter-email"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-email"
              v-model="filters.email"
              type="text"
              placeholder="Insurance Email"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Phone"
            label-for="advanced-filter-state"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-state"
              v-model="filters.state"
              type="text"
              placeholder="State"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            class="mb-0"
            label="Policy Number"
            label-for="advanced-filter-policy_number"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-policy_number"
              v-model="filters.policy_number"
              type="text"
              placeholder="Policy Number"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            class="mb-0"
            label="Coverage Ending From"
            label-for="advanced-filter-end_date_from"
            label-sr-only
            content-cols-lg="12"
          >
            <sfs-date-picker
              id="advanced-filter-end_date_from"
              v-model="filters.end_date_from"
              placeholder="Coverage Ending From"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            class="mb-0"
            label="Coverage Ending To"
            label-for="advanced-filter-end_date_to"
            label-sr-only
            content-cols-lg="12"
          >
            <sfs-date-picker
              id="advanced-filter-end_date_to"
              v-model="filters.end_date_to"
              placeholder="Coverage Ending To"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Manager"
            label-for="advanced-filter-manager_id"
            label-sr-only
          >
            <employee-select
              id="advanced-filter-manager_id"
              placeholder="Manager"
              :value="manager"
              @input="onManagerChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Coverage Verified or Not Verified"
            label-for="advanced-filter-verified"
            label-sr-only
          >
            <b-form-radio-group
              id="advanced-filter-verified"
              v-model="filters.verified"
              :options="verifiedOptions"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Active or Inactive"
            label-for="advanced-filter-active"
            label-sr-only
          >
            <b-form-radio-group
              id="advanced-filter-active"
              v-model="filters.active"
              :options="activeOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import EmployeeSelect from '@/components/admin/users/EmployeeSelect.vue';
import UserSelect from '@/components/admin/users/Select.vue';
import CategorySelect from '@/components/admin/insuranceCertificates/CategorySelect.vue';
import StatusSelect from '@/components/admin/insuranceCertificates/StatusSelect.vue';
import listFilterMixin from '@/mixins/listFilterMixin';
import {
  INSURANCE_CERTIFICATE_TABLE_COLUMN_OPTIONS,
  INSURANCE_CERTIFICATE_TABLE_DEFAULT_COLUMNS,
  INSURANCE_CERTIFICATE_TABLE_VERIFIED_FILTER_OPTIONS,
  INSURANCE_CERTIFICATE_TABLE_NAME,
} from '@/constants/insuranceCertificates';

export default {
  name: 'InsuranceCertificatesListFilter',
  mixins: [listFilterMixin],
  components: {
    EmployeeSelect,
    UserSelect,
    CategorySelect,
    StatusSelect,
  },
  data() {
    return {
      availableColumns: INSURANCE_CERTIFICATE_TABLE_COLUMN_OPTIONS,
      appliedFilters: {},
      categoryOptions: [],
      verifiedOptions: INSURANCE_CERTIFICATE_TABLE_VERIFIED_FILTER_OPTIONS,
      tableName: INSURANCE_CERTIFICATE_TABLE_NAME,
      userSelectChange: { active: true },
    };
  },
  mounted() {
    this.loadInitialFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = INSURANCE_CERTIFICATE_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    onManagerChange(user) {
      this.filters.manager_id = user ? user.id.toString() : '';
      this.manager = user;
    },
    onUserChanged(changedUser) {
      this.filters.user_id = changedUser ? changedUser.id.toString() : '';
      this.user = changedUser;
    },
    loadInitialFilters() {
      const loadCategoryOptions = new Promise((resolve) => {
        this.$store.cache.dispatch('getInsuranceCertificateCategories').then((categoryOptions) => {
          const options = categoryOptions.map((option) => {
            return { label: option[0], value: option[1] };
          });
          resolve(options);
        });
      });
      const loadManager = new Promise((resolve) => {
        if (this.initialFilters.manager_id) {
          this.$usersAPI.get(this.initialFilters.manager_id).then((manager) => {
            resolve(manager);
          });
        } else {
          resolve(null);
        }
      });

      const loadUser = new Promise((resolve) => {
        if (this.initialFilters.user_id) {
          this.$usersAPI.get(this.initialFilters.user_id).then((user) => {
            resolve(user);
          });
        } else {
          resolve(null);
        }
      });

      Promise.all([loadCategoryOptions, loadManager, loadUser]).then((values) => {
        this.categoryOptions = values[0];
        this.manager = values[1];
        this.user = values[2];
        this.applyFilters();
      });
    },
  },
};
</script>
