export const DEFAULT_GENERAL_SECTION = { id: null, name: 'General', parent_id: null, children: [], depth: 0 };

export const createFlattenedSectionTree = (sections) => {
  const tree = createSectionTree([{ ...DEFAULT_GENERAL_SECTION }, ...sections.map((section) => ({...section, children: [], depth: 0 }))]);

  return flattenSectionsTree(tree);
};

/**
 * Processes a flat array of sections, and creates an array of trees.
 * Their descendents are based on the `parent_id` property of the section.
 * @param items The items to structure into trees.
 * @returns an array of trees, with descenants inside the `children` prop of each node.
 */
export const createSectionTree = (items) => {
  const treeMap = {};
  items.forEach(item => treeMap[item.id] = {...item, children: [], depth: 0 });
  const tree = [];

  items.forEach(item => {
    if (item.parent_id) {
      treeMap[item.parent_id].children.push(treeMap[item.id]);
    } else {
      tree.push(treeMap[item.id]);
    }
  });

  return tree;
};

export const flattenSectionsTree = (items, depth = 0) => {
  let flattenedList = [];
  items.forEach((item) => {
    flattenedList.push({ ...item, depth: depth });

    if (item.children.length > 0) {
      flattenedList = flattenedList.concat(flattenSectionsTree(item.children, depth + 1));
    }
  });
  return flattenedList;
};
