<template>
  <sfs-form-modal
    :id="modalId"
    title="Add Contact Info"
    size="sm"
    @on-hide="resetFormData"
  >
    <contact-info-form
      @on-submit="create"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import ContactInfoForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'ContactInfoAddModal',
  mixins: [formModalMixin],
  components: {
    ContactInfoForm,
  },
  props: {
    referenceId: {
      type: Number,
      required: true,
    },
    createProvider: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modalId: 'contact-info-add-modal',
    };
  },
  methods: {
    create(data, handleCreate) {
      handleCreate(this.createProvider(this.referenceId, data));
    },
    onSave(contactInfo) {
      this.closeModal();
      this.resetFormData();
      this.$emit('on-save', contactInfo);
    },
  },
};
</script>
