<template>
  <sfs-form-modal
    :id="modalId"
    title="Add a Technician Service"
    @on-hide="resetFormData"
  >
    <technician-services-form
      :location_id="location_id"
      @on-submit="createTechnicianService"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import TechnicianServicesForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'TechnicianServicesAddModal',
  mixins: [formModalMixin],
  components: {
    TechnicianServicesForm,
  },
  props: {
    location_id: Number,
  },
  data() {
    return {
      modalId: 'technician-service-add-modal',
    };
  },
  methods: {
    createTechnicianService(data, handleCreate) {
      handleCreate(this.$technicianServicesAPI.create(data));
    },
    onSave() {
      this.closeModal();
      this.$emit('save');
    },
  },
};
</script>
