<template>
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :value="value"
    @input="onChange"
    :reduce="(option) => option.value"
  >
    <template #option="option">
      <div>{{ option.label }}</div>
      <div class="font-size-sm text-secondary">
        {{ option.value }}
      </div>
    </template>
  </v-select>
</template>
<script>
export default {
  name: 'TimeZoneSelect',
  props: {
    value: String,
    id: {
      type: String,
      default: 'time-zone-select',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: {
      type: String,
      default: 'Select a Time Zone',
    },
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getTimeZoneOptions();
  },
  methods: {
    getTimeZoneOptions() {
      this.$authAPI.getTimeZoneOptions().then((response) => {
        this.options = response.data.time_zone_options?.map((option) => {
          return { label: option.name, value: option.identifier };
        });
      });
    },
    onChange(timeZone) {
      this.$emit('input', timeZone);
    },
  },
};
</script>
