<template>
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :value="value"
    @input="(category) => $emit('input', category)"
    :reduce="(category) => category.value"
  >
    <template #option="option">
      <div>{{ option.label }}</div>
      <div class="font-size-sm text-secondary">
        {{ categoryText[option.value] }}
      </div>
    </template>
  </v-select>
</template>
<script>
import { USER_CATEGORIES } from '@/constants/users';

export default {
  name: 'CategorySelect',
  props: {
    value: String,
    id: {
      type: String,
      default: 'category-select',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    assignableOnly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select a Category',
    },
  },
  data() {
    return {
      options: [],
      categoryText: USER_CATEGORIES,
    };
  },
  mounted() {
    this.getCategoryOptions();
  },
  methods: {
    getCategoryOptions() {
      this.$store.cache.dispatch(this.assignableOnly ? 'getAssignableUserCategories' : 'getUserCategories').then((categoryOptions) => {
        this.options = categoryOptions.map((option) => {
          return { label: option[0], value: option[1] };
        });
      });
    },
  },
};
</script>
