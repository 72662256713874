<template>
  <div>
    <base-page-heading title="Locations">
      <template #extra>
        <b-button
          v-if="$can('create', 'Location')"
          class="mr-2"
          v-b-modal.location-add-modal
          variant="primary"
          v-b-tooltip.hover
          title="Create a new Location"
        >
          <i class="fa fa-plus mr-1" />
          New Location
        </b-button>

        <b-dropdown
          v-if="$can('create', 'Import')"
          text="Import"
          class="mr-2"
          variant="outline-secondary"
          v-b-tooltip.hover
          title="Manage Locations using imports"
          right
        >
          <template #button-content>
            <i class="fa fa-file-excel mr-1" />
            Import
          </template>
          <b-dropdown-item v-b-modal.locations-imports-add-modal>
            Create New / Update
          </b-dropdown-item>
          <b-dropdown-item v-b-modal.technician-services-imports-add-modal>
            Technician Services
          </b-dropdown-item>
          <b-dropdown-item v-b-modal.recurring-charges-imports-add-modal>
            Recurring Charges
          </b-dropdown-item>
          <b-dropdown-item v-b-modal.recurring-work-orders-imports-add-modal>
            Recurring Work Orders
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          variant="outline-secondary"
          right
          v-b-tooltip.hover
          title="Download spreadsheet"
        >
          <template #button-content>
            <b-spinner
              v-if="downloading"
              small
              label="Downloading..."
            />
            <i
              v-else
              class="fa fa-download mr-1"
            />
            Downloads
          </template>
          <b-dropdown-item @click="downloadSpreadsheet">
            Locations
          </b-dropdown-item>
          <b-dropdown-item
            @click="downloadTechnicianServicesSpreadsheet"
            v-if="$can('read', 'TechnicianService')"
          >
            Technician Services
          </b-dropdown-item>
          <b-dropdown-item
            @click="downloadRecurringChargesSpreadsheet"
            v-if="$can('read', 'RecurringCharge')"
          >
            Recurring Charges
          </b-dropdown-item>
          <b-dropdown-item
            @click="downloadRecurringWorkOrdersSpreadsheet"
            v-if="$can('read', 'RecurringWorkOrder')"
          >
            Recurring Work Orders
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <locations-list-filter
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>
      <locations-table
        id="locations-table"
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>

    <!-- Page Modals -->
    <locations-new-modal v-if="$can('create', 'Location')" />
    <technician-services-import-new-modal v-if="$can('create', 'Import')" />
    <locations-import-new-modal v-if="$can('create', 'Import')" />
    <recurring-charges-import-new-modal v-if="$can('create', 'Import')" />
    <recurring-work-orders-import-new-modal v-if="$can('create', 'Import')" />
  </div>
</template>

<script>
import LocationsNewModal from '@/components/admin/locations/NewModal.vue';
import LocationsTable from '@/components/admin/locations/Table.vue';
import TechnicianServicesImportNewModal from '@/components/admin/imports/TechnicianServicesNewModal.vue';
import LocationsImportNewModal from '@/components/admin/imports/LocationsNewModal.vue';
import RecurringChargesImportNewModal from '@/components/admin/imports/RecurringChargesNewModal.vue';
import RecurringWorkOrdersImportNewModal from '@/components/admin/imports/RecurringWorkOrdersNewModal.vue';
import LocationsListFilter from '@/components/admin/locations/ListFilter.vue';
import listMixin from '@/mixins/listMixin';
import downloadMixin from '@/mixins/downloadMixin';

import { LOCATION_TABLE_NAME } from '@/constants/locations';

export default {
  name: 'LocationsList',
  components: {
    LocationsNewModal,
    TechnicianServicesImportNewModal,
    LocationsImportNewModal,
    RecurringChargesImportNewModal,
    RecurringWorkOrdersImportNewModal,
    LocationsListFilter,
    LocationsTable,
  },
  mixins: [downloadMixin, listMixin],
  props: {
    store_number: String,
    manager_id: String,
    technician_id: String,
    client_id: String,
    service_id: String,
    floor_type: String,
    street: String,
    city: String,
    county: String,
    state: String,
    zip_code: String,
    active: String,
    text: String,
  },
  data() {
    return {
      tableName: LOCATION_TABLE_NAME,
      filters: {
        store_number: this.store_number,
        manager_id: this.manager_id,
        technician_id: this.technician_id,
        client_id: this.client_id || this.$store.getters?.client?.id,
        service_id: this.service_id,
        floor_type: this.floor_type,
        street: this.street,
        city: this.city,
        state: this.state,
        county: this.county,
        zip_code: this.zip_code,
        active: this.active,
        text: this.text,
      },
    };
  },
  methods: {
    downloadSpreadsheet() {
      this.download(this.$locationsAPI.getSpreadsheet, this.filters, 'Locations.csv');
    },
    downloadTechnicianServicesSpreadsheet() {
      this.download(this.$technicianServicesAPI.getSpreadsheet, this.filters, 'Technician Services.csv');
    },
    downloadRecurringChargesSpreadsheet() {
      this.download(this.$recurringChargesAPI.getSpreadsheet, this.filters, 'Recurring Charges.csv');
    },
    downloadRecurringWorkOrdersSpreadsheet() {
      this.download(this.$recurringWorkOrdersAPI.getSpreadsheet, this.filters, 'Recurring Work Orders.csv');
    },
  },
};
</script>
