<template>
  <!-- using autocomplete value "new-password" instead of "off" seems to disable autocomplete better in some browsers. -->
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :value="value"
    @search="onSearch"
    :filterable="false"
    @input="(recurringCharge) => $emit('input', recurringCharge)"
    autocomplete="new-password"
  >
    <template #option="option">
      <div>
        {{ option.location.store_number }}<span v-if="option.location.name">- {{ option.location.name }}</span>
        {{ { city: option.location.city, state: option.location.state } | locationFormat }}
      </div>
      <div class="font-size-sm">
        <div class="text-nowrap font-size-sm text-secondary">
          {{ option.description }} -
          <money-format
            class="d-inline"
            :value="option.price.amount"
            :currency-code="option.price.currency"
            subunits-value
          />
          {{ option.frequency_parts | frequencyFormat | titlecaseFormat }}
        </div>
      </div>
    </template>
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        No results found for
        <em>{{ search }}</em>
      </template>
      <em
        style="opacity: 0.7"
        v-else
      >Type to search for charges</em>
    </template>
  </v-select>
</template>
<script>
import { debounce as _debounce } from 'lodash';

export default {
  name: 'RecurringChargesSelect',
  props: {
    value: Object,
    id: {
      type: String,
      default: 'recurring-charge',
    },
    selectLabel: {
      type: String,
      default: 'store_number',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    filterParams: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: String,
  },
  data() {
    return {
      options: [],
    };
  },
  methods: {
    onSearch(store_number, loading) {
      if (store_number.length) {
        loading(true);
        this.filterCharges(store_number, loading, this);
      }
    },
    filterCharges: _debounce((store_number, loading, vm) => {
      const params = {
        ...vm.filterParams,
        store_number: store_number,
      };
      vm.$recurringChargesAPI.getCharges(params).then((response) => {
        vm.options = response.recurringCharges.map((charge) => {
          return { store_number: charge.location.store_number, ...charge };
        });
        loading(false);
      });
    }, 500),
    onChange(recurringCharge) {
      this.$emit('input', recurringCharge);
    },
  },
};
</script>
