import tablePropsMixin from '@/mixins/tablePropsMixin';
import { isEqual as _isEqual, isUndefined as _isUndefined } from 'lodash';

export default {
  mixins: [tablePropsMixin],
  data() {
    return {
      loaded: false,
      isLoadingData: false,
      records: [],
      totalRows: 0,
    };
  },
  methods: {
    onPerPageChanged(perPage) {
      this.pagination = Object.assign({}, { ...this.pagination, perPage: perPage });
      this.$store.dispatch('updateProfile', { ui_config: { perPage: perPage } });
      this.getRecords();
    },
    getRecords() {
      this.records = [];
    },
    handleSortingChanged(ctx) {
      this.sorting.sortBy = ctx?.sortBy;
    },
  },
  computed: {
    isLoaded() {
      return this.loaded && !this.isLoadingData;
    },
    showPagination() {
      return this.loaded && this.totalRows > this.pagination.perPage;
    },
    params() {
      return {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        sort_by: this.sorting.sortBy,
        sort_desc: this.sorting.sortDesc,
        ...this.filters,
      };
    },
  },
  watch: {
    'pagination.currentPage': function (newCurrent, oldCurrent) {
      if (!_isEqual(newCurrent, oldCurrent) && !_isUndefined(oldCurrent)) {
        this.getRecords();
      }
    },
    'sorting.sortBy': function (newCurrent, oldCurrent) {
      if (!_isEqual(newCurrent, oldCurrent) && !_isUndefined(oldCurrent)) {
        this.getRecords();
      }
    },
    'sorting.sortDesc': function (newCurrent, oldCurrent) {
      if (!_isEqual(newCurrent, oldCurrent) && !_isUndefined(oldCurrent)) {
        this.getRecords();
      }
    },
    filters: {
      deep: true,
      handler(newFilters, oldFilters) {
        if (!_isEqual(oldFilters, newFilters) && !_isUndefined(newFilters)) {
          this.pagination.currentPage = 1;
          this.getRecords();
        }
      },
    },
    totalRows: {
      immediate: true,
      handler(newTotal, oldTotal) {
        if (!_isEqual(oldTotal, newTotal) && !_isUndefined(oldTotal)) {
          this.resetCurrentPage(newTotal);
        }
        this.$emit('total-rows-changed', newTotal);
      },
    },
  },
  mounted() {
    this.getRecords();
  },
};
