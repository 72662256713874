import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const DELAYED_JOB_TABLE_NAME = 'delayedJobs';

// The columns available to be shown in a delayedJobs table.
export const DELAYED_JOB_TABLE_COLUMNS = {
  queue: { label: 'Queue', key: 'queue' },
  priority: { label: 'Priority', key: 'priority' },
  run_at: { label: 'Run At', key: 'run_at', sortable: true },
  failed_at: { label: 'Failed At', key: 'failed_at', sortable: true },
  failed_from: { label: 'Failed Delayed Jobs From', key: 'failed_from', filterOnly: true },
  failed_to: { label: 'Failed Delayed Jobs To', key: 'failed_to', filterOnly: true },
  date_from: { label: 'Delayed Jobs From', key: 'date_from', filterOnly: true },
  date_to: { label: 'Delayed Jobs To', key: 'date_to', filterOnly: true },
  last_error: { label: 'Last Error', key: 'last_error' },
  job_class: { label: 'Job Class', key: 'job_class' },
  arguments: { label: 'Arguments', key: 'arguments' },
  status: { label: 'Status', key: 'status' },
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const DELAYED_JOB_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(DELAYED_JOB_TABLE_COLUMNS);

// The default columns to be shown when the delayedJobs table loads.
export const DELAYED_JOB_TABLE_DEFAULT_COLUMNS = [
  DELAYED_JOB_TABLE_COLUMNS.job_class,
  DELAYED_JOB_TABLE_COLUMNS.status,
  DELAYED_JOB_TABLE_COLUMNS.arguments,
  DELAYED_JOB_TABLE_COLUMNS.run_at,
  DELAYED_JOB_TABLE_COLUMNS.last_error,
  DELAYED_JOB_TABLE_COLUMNS.actions,
];