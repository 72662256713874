<template>
  <sfs-form-modal
    :id="modalId"
    title="Add a Location"
    size="lg"
    @on-hide="resetFormData"
  >
    <locations-form
      :client_ids="client_ids"
      @on-submit="createLocation"
      @on-save="redirectToLocation"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import LocationsForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'LocationsAddModal',
  mixins: [formModalMixin],
  props: {
    client_ids: {
      type: Array,
    },
  },
  components: {
    LocationsForm,
  },
  data() {
    return {
      modalId: 'location-add-modal',
    };
  },
  methods: {
    createLocation(data, handleCreate) {
      handleCreate(this.$locationsAPI.create(data));
    },
    redirectToLocation(location) {
      this.$router.push({ name: 'location', params: { id: location.id } });
    },
  },
};
</script>
