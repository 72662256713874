<template>
  <sfs-form-modal
    :id="modalId"
    :title="`Email Work Order ${value.number}`"
    @on-hide="resetForm"
  >
    <b-form
      novalidate
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            :label="fieldLabels.email"
            label-for="work-order-email-email"
          >
            <b-form-input
              id="work-order-email-email"
              v-model="$v.form.email.$model"
              autocomplete="new-password"
              :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
              type="text"
              placeholder="example@email.com"
            />
            <b-form-invalid-feedback
              v-if="$v.form.email.$dirty"
              id="work-order-email-email-feedback"
            >
              <span v-if="!$v.form.email.required">Please enter a email.</span>
              <span v-if="!$v.form.email.email">Please enter a valid email.</span>
              <span v-if="!$v.form.email.serverFailed">{{ serverErrors.email }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="work-order-email-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="work-order-email-submit-button"
            block
            type="submit"
            variant="primary"
          >
            Email
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'WorkOrdersEmailModal',
  mixins: [formMixin, formModalMixin],
  props: {
    value: Object,
    email: String,
  },
  data() {
    return {
      form: {
        email: this.email,
      },
      fieldLabels: {
        email: 'Email',
      },
      modalId: 'work-order-email-modal',
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
        serverFailed() {
          return !this.hasServerErrors('email');
        },
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.$workOrdersAPI
        .email(this.value.id, this.form)
        .then((workOrder) => {
          this.resetServerErrors();
          this.$v.$reset();
          this.$emit('input', workOrder);
          this.closeModal();
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
        });
    },
  },
};
</script>
