<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.number"
          label-for="technician-payment-number"
        >
          <b-form-input
            id="technician-payment-number"
            v-model="$v.form.number.$model"
            placeholder="Optional"
            :state="$v.form.number.$dirty ? !$v.form.number.$error : null"
            type="text"
          />
          <small
            v-if="newForm"
            class="form-text text-muted"
          >
            If left blank the number will be automatically generated
          </small>
          <b-form-invalid-feedback
            v-if="$v.form.number.$dirty"
            id="technician-payment-number-feedback"
          >
            <span v-if="!$v.form.number.maxLength">
              The number must be less than {{ $v.form.number.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.number.serverFailed">{{ serverErrors.number }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.user_id"
          label-for="technician-payment-user_id"
        >
          <user-select
            id="technician-payment-user_id"
            :select-class="{ 'is-invalid': $v.form.user_id.$dirty && $v.form.user_id.$error }"
            select-label="name"
            :disabled="!newForm"
            :value="form.user"
            :filter-params="technicianSelectParams"
            @input="onUserChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.user_id.$dirty"
            id="technician-payment-user_id-feedback"
          >
            <span v-if="!$v.form.user_id.required">Please enter a technician.</span>
            <span v-if="!$v.form.user_id.serverFailed">{{ serverErrors.user_id }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.date_period"
          label-for="technician-payment-date_period"
        >
          <sfs-date-range-picker
            id="technician-payment-date_period"
            v-model="$v.form.date_period.$model"
            :with-class="{ 'is-invalid': ($v.form.start_date.$dirty && $v.form.start_date.$error) || ($v.form.end_date.$dirty && $v.form.end_date.$error) }"
            :class="{ 'is-invalid': ($v.form.start_date.$dirty && $v.form.start_date.$error) || ($v.form.end_date.$dirty && $v.form.end_date.$error) }"
            placeholder="Select Dates"
            @on-change="onDatePeriodChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.start_date.$dirty"
            id="technician-payment-start_date-feedback"
          >
            <span v-if="!$v.form.start_date.required">Please enter a start date.</span>
            <span v-if="!$v.form.start_date.serverFailed">{{ serverErrors.start_date }}</span>
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-if="$v.form.end_date.$dirty"
            id="technician-payment-end_date-feedback"
          >
            <span v-if="!$v.form.end_date.required">Please enter an end date.</span>
            <span v-if="!$v.form.end_date.serverFailed">{{ serverErrors.end_date }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.due_date"
          label-for="technician-payment-due_date"
        >
          <sfs-date-picker
            id="technician-payment-due_date"
            v-model="form.due_date"
            :with-class="{ 'is-invalid': $v.form.due_date.$dirty && $v.form.due_date.$error }"
            :class="{ 'is-invalid': $v.form.due_date.$dirty && $v.form.due_date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.due_date.$dirty"
            id="technician-payment-due_date-feedback"
          >
            <span v-if="!$v.form.due_date.required">Please enter a due date.</span>
            <span v-if="!$v.form.due_date.serverFailed">{{ serverErrors.due_date }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          id="technician-payment-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col cols="3">
        <b-button
          id="technician-payment-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import UserSelect from '@/components/admin/users/Select.vue';

export default {
  name: 'TechnicianPaymentsForm',
  components: {
    UserSelect,
  },
  mixins: [formMixin],
  props: {
    newForm: {
      type: Boolean,
      default: true,
    },
    number: String,
    start_date: String,
    end_date: String,
    due_date: String,
    user_id: Number,
    user: Object,
  },
  data() {
    return {
      form: {
        number: this.number,
        start_date: this.start_date,
        end_date: this.end_date,
        due_date: this.due_date,
        user_id: this.user_id,
        user: this.user,
        date_period: [
          this.start_date && this.$options.filters.dateFormat(this.start_date),
          this.end_date && this.$options.filters.dateFormat(this.end_date),
        ],
      },
      fieldLabels: {
        number: 'Number',
        date_period: 'Date Period',
        due_date: 'Due Date',
        user_id: 'Technician',
      },
      technicianSelectParams: { active: true },
    };
  },
  validations: {
    form: {
      number: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('number');
        },
      },
      start_date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('start_date');
        },
      },
      end_date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('end_date');
        },
      },
      due_date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('due_date');
        },
      },
      date_period: {},
      user_id: {
        required: requiredIf(function () {
          return this.newForm;
        }),
        serverFailed() {
          return !this.hasServerErrors('user_id');
        },
      },
    },
  },
  methods: {
    onDatePeriodChange(dates) {
      this.form.start_date = dates?.[0] || '';
      this.form.end_date = dates?.[1] || '';
    },
    onUserChange(user) {
      this.form.user_id = user ? user.id : '';
      this.form.user = user;
    },
  },
};
</script>
