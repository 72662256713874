<template>
  <div v-if="user">
    <base-page-heading
      :title="`${user.name} KPI Report`"
      show-back-button
    >
      <template #extra>
        <b-link @click="() => changeDates(currentMonthDates)">
          Current Month
        </b-link>
        <span class="pl-1 pr-1">|</span>
        <b-link @click="() => changeDates(lastMonthDates)">
          Previous Month
        </b-link>
        <span class="pl-1 pr-1">|</span>
        <b-link @click="() => changeDates(lastSixMonthsDates)">
          Last 6 Months
        </b-link>
      </template>
    </base-page-heading>
    <div class="content">
      <technician-dashboard-filter
        :columns="columns"
        :column-picker="false"
        :initial-filters="filters"
        @filters-applied="applyFilters"
      />

      <b-row>
        <b-col cols="12">
          <b-nav
            tabs
            class="nav-tabs-alt"
          >
            <b-nav-item
              @click="navIndex = 0"
              :active="navIndex == 0"
            >
              Work Orders
            </b-nav-item>
            <b-nav-item
              @click="navIndex = 1"
              :active="navIndex == 1"
            >
              WO Revenue
            </b-nav-item>
            <b-nav-item
              @click="navIndex = 2"
              :active="navIndex == 2"
            >
              Compliance
            </b-nav-item>
          </b-nav>

          <div
            v-if="navIndex == 0"
            class="mt-4"
          >
            <b-card class="py-4">
              <b-row>
                <b-col class="text-center border-right">
                  <h1 class="font-w400">
                    {{ totalWorkOrders }}
                  </h1>
                  <span class="text-secondary">Total Work Orders</span>
                </b-col>
                <b-col class="text-center border-right">
                  <h1 class="font-w400">
                    {{ workOrdersCompletedPerPeriod }}
                  </h1>
                  <span class="text-secondary">Completed per {{ groupPeriod | titlecaseFormat }}</span>
                </b-col>
                <b-col class="text-center border-right">
                  <h1 class="font-w400">
                    {{ locationsVisitedPerPeriod }}
                  </h1>
                  <span class="text-secondary">Locations Visited per {{ groupPeriod | titlecaseFormat }}</span>
                </b-col>
                <b-col class="text-center border-right">
                  <h1 class="font-w400">
                    {{ avgRating }} <i class="w-2 h-2 si si-star" />
                  </h1>
                  <span class="text-secondary">Avg Rating</span>
                </b-col>
                <b-col class="text-center">
                  <h1 class="font-w400">
                    {{ avgTimeSpent }} hrs
                  </h1>
                  <span class="text-secondary">Spent per Service</span>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="mt-4 py-4">
              <line-chart
                title="Completed Work Orders"
                :colors="workOrderChartColours"
                :share-tooltip="true"
                :stroke-width="4"
                :fill-opacity="1"
                :chart-height="defaultChartHeight"
                :skeleton-height="defaultChartHeight"
                :y-axis-label-formatter="(val) => (val || 0).toFixed(0)"
                :series="[completedWorkOrdersSeries, locationsServicedSeries]"
                :data-function="[getCompletedWorkOrdersSeries, getLocationsServicedSeries]"
                :data-parameters="{
                  filters,
                  groupPeriod,
                }"
              />
            </b-card>
          </div>

          <div
            v-if="navIndex == 1"
            class="mt-4"
          >
            <b-card class="py-4">
              <b-row>
                <b-col class="text-center border-right">
                  <h1 class="font-w400">
                    {{ costPerWorkOrder }}
                  </h1>
                  <span class="text-secondary">Cost per Work Order</span>
                </b-col>
                <b-col class="text-center border-right">
                  <h1 class="font-w400">
                    {{ grossProfitPerWorkOrder }}
                  </h1>
                  <span class="text-secondary">Margin per Work Order</span>
                </b-col>
                <b-col class="text-center border-right">
                  <h1 class="font-w400">
                    {{ marginPercentage }}
                  </h1>
                  <span class="text-secondary">Margin</span>
                </b-col>
                <b-col class="text-center border-right">
                  <h1 class="font-w400">
                    {{ totalRevenue }}
                  </h1>
                  <span class="text-secondary">Total WO Revenue</span>
                </b-col>
                <b-col class="text-center">
                  <h1 class="font-w400">
                    {{ totalRecurringRevenue }}
                  </h1>
                  <span class="text-secondary">Total Recurring Revenue</span>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="mt-4 py-4">
              <area-chart
                title="WO Revenue"
                :stacked="true"
                :share-tooltip="true"
                :colors="revenueChartColours"
                :fill-opacity="1"
                :chart-height="defaultChartHeight"
                :skeleton-height="defaultChartHeight"
                :y-axis-label-formatter="(val) => $options.filters.moneyFormatFilter(val, 'compact')"
                :series="$can('read_price', 'WorkOrderCharge') ? [costSeries, profitSeries] : [costSeries]"
                :data-function="$can('read_price', 'WorkOrderCharge') ? [getCostSeries, getProfitSeries] : [getCostSeries]"
                :data-parameters="{
                  filters,
                  groupPeriod,
                }"
              />
            </b-card>
          </div>

          <div
            v-if="navIndex == 2"
            class="mt-4"
          >
            <b-card class="py-4">
              <b-row>
                <b-col class="text-center border-right">
                  <h1 class="font-w400">
                    {{ user.created_at | dateFormat }}
                  </h1>
                  <span class="text-secondary">Technician Since</span>
                </b-col>
                <b-col class="text-center border-right">
                  <h1 class="font-w400">
                    {{ mobileUsage }}
                  </h1>
                  <span class="text-secondary">Mobile Usage</span>
                </b-col>
                <b-col class="text-center border-right">
                  <h1 class="font-w400">
                    <span v-if="latestAppVersion && appUpToDate">
                      <i class="fa fa-fw fa-check text-success" />
                    </span>
                    <span v-if="latestAppVersion && !appUpToDate">
                      <i class="fa fa-fw fa-times text-danger" />
                    </span>
                    {{ latestAppVersion }}
                  </h1>
                  <span class="text-secondary">App Up To Date</span>
                </b-col>
                <b-col class="text-center border-right">
                  <h1 class="font-w400">
                    <i
                      v-if="devicesPerAccount <= 1"
                      class="fa fa-fw fa-check text-success"
                    />
                    <i
                      v-else
                      class="fa fa-fw fa-times text-danger"
                    />
                    {{ devicesPerAccount }}
                  </h1>
                  <span class="text-secondary">Devices Used Last 30 Days</span>
                </b-col>
              </b-row>
            </b-card>

            <b-card class="mt-4 py-4">
              <work-order-completion-source-chart
                title="App Usage"
                :height="defaultChartHeight"
                :group-period="groupPeriod"
                :date-period="datePeriod"
                :client-id="filters.client_id"
                :assigned-to-id="user.id"
              />
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { isEqual as _isEqual, isUndefined as _isUndefined  } from 'lodash';

import listMixin from '@/mixins/listMixin';
import TechnicianDashboardFilter from '@/components/admin/reports/users/TechnicianDashboardFilter.vue';
import WorkOrderCompletionSourceChart from '@/components/admin/charts/WorkOrderCompletionSourceChart.vue';
import LineChart from '@/components/shared/apexcharts/LineChart';
import AreaChart from '@/components/shared/apexcharts/AreaChart';

import { mapTimeSeriesData } from '@/utils/apexchartsSeriesMapping';
import calculateDateIntervals from '@/utils/calculateDateIntervals';
import calculateGroupPeriod from '@/utils/calculateGroupPeriod';

import { CHART_COLOUR_ORANGE, CHART_COLOUR_PRIMARY_DARK } from '@/constants';

export default {
  name: 'TechnicianDashboard',
  mixins: [listMixin],
  components: {
    TechnicianDashboardFilter,
    WorkOrderCompletionSourceChart,
    LineChart,
    AreaChart,
  },
  props: {
    client_id: String,
    service_id: String,
    state: String,
    start_date: String,
    end_date: String,
  },
  data() {
    return {
      user: null,
      filters: {
        client_id: this.client_id,
        service_id: this.service_id,
        state: this.state,
        start_date: this.start_date,
        end_date: this.end_date,
      },
      defaultChartHeight: '400px',
      completedWorkOrdersSeries: { data: [] },
      locationsServicedSeries: { data: [] },
      costSeries: { data: [] },
      profitSeries: { data: [] },
      dashboardData: {},
      workOrderChartColours: [CHART_COLOUR_PRIMARY_DARK, CHART_COLOUR_ORANGE],
      revenueChartColours: [CHART_COLOUR_PRIMARY_DARK, '#00AB9C'],
      dateFormat: 'LLL d, yyyy',
      lastSixMonthsDates: [DateTime.now().minus({ month: 6 }).startOf('month').toISODate(), DateTime.now().toISODate()],
      lastMonthDates: [DateTime.now().startOf('month').minus({ month: 1 }).toISODate(), DateTime.now().endOf('month').minus({ month: 1 }).toISODate()],
      currentMonthDates: [DateTime.now().startOf('month').toISODate(), DateTime.now().endOf('month').toISODate()],
      navIndex: 0
    };
  },
  mounted() {
    this.getUser().then(() => this.getDashboard());
  },
  methods: {
    getUser() {
      return this.$usersAPI
        .get(this.$route.params.id)
        .then((user) => (this.user = user))
        .catch(() => {
          this.$router.push('/404');
        });
    },
    refreshData() {
      this.getDashboard();
    },
    getDashboard() {
      return this.$analyticsAPI
        .getTechnicianDashboard(this.user.id, {
          client_id: this.filters.client_id,
          service_id: this.filters.service_id,
          checked_out_from: this.filters.start_date,
          checked_out_to: this.filters.end_date,
          group_period: this.groupPeriod,
        }).then((response) => {
          this.dashboardData = response.data;
        })
        .catch(() => {
          this.dashboardData = {};
        });
    },
    getProfitSeries() {
      return this.$analyticsAPI
        .getWorkOrdersSum({
          client_id: this.filters.client_id,
          service_id: this.filters.service_id,
          assigned_to_id: this.user.id,
          checked_out_from: this.filters.start_date,
          checked_out_to: this.filters.end_date,
          group_period: this.groupPeriod,
          group_by: 'checked_out_at',
          sum_by: 'profit',
        })
        .then((response) => {
          this.profitSeries = {
            name: 'Gross Profit',
            data: mapTimeSeriesData(response.series, this.intervals),
          };
        })
        .catch(() => {
          this.profitSeries = { data: [] };
        });
    },
    getCostSeries() {
      return this.$analyticsAPI
        .getWorkOrdersSum({
          client_id: this.filters.client_id,
          service_id: this.filters.service_id,
          assigned_to_id: this.user.id,
          checked_out_from: this.filters.start_date,
          checked_out_to: this.filters.end_date,
          group_period: this.groupPeriod,
          group_by: 'checked_out_at',
          sum_by: 'cost',
        })
        .then((response) => {
          this.costSeries = {
            name: 'Cost',
            data: mapTimeSeriesData(response.series, this.intervals),
          };
        })
        .catch(() => {
          this.costSeries = { data: [] };
        });
    },
    getLocationsServicedSeries() {
      return this.$analyticsAPI
        .getWorkOrdersCount({
          client_id: this.filters.client_id,
          service_id: this.filters.service_id,
          assigned_to_id: this.user.id,
          checked_out_from: this.filters.start_date,
          checked_out_to: this.filters.end_date,
          state: this.filters.state,
          group_period: this.groupPeriod,
          group_by: 'checked_out_at',
          count_by: 'location_id',
        })
        .then((response) => {
          this.locationsServicedSeries = {
            name: 'Locations Serviced',
            type: 'line',
            data: mapTimeSeriesData(response.series, this.intervals),
          };
        })
        .catch(() => {
          this.locationsServicedSeries = { data: [] };
        });
    },
    getCompletedWorkOrdersSeries() {
      return this.$analyticsAPI
        .getWorkOrdersCount({
          client_id: this.filters.client_id,
          service_id: this.filters.service_id,
          assigned_to_id: this.user.id,
          checked_out_from: this.filters.start_date,
          checked_out_to: this.filters.end_date,
          state: this.filters.state,
          group_period: this.groupPeriod,
          group_by: 'checked_out_at',
          count_by: 'work_order_id',
        })
        .then((response) => {
          this.completedWorkOrdersSeries = {
            name: 'Work Orders',
            type: 'column',
            data: mapTimeSeriesData(response.series, this.intervals),
          };
        })
        .catch(() => {
          this.completedWorkOrdersSeries = { data: [] };
        });
    },
    changeDates(dates) {
      this.applyFilters({ filters: { ...this.filters, start_date: this.$options.filters.dateFormat(dates[0]), end_date: this.$options.filters.dateFormat(dates[1]) }});
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(newFilters, oldFilters) {
        if (!_isEqual(oldFilters, newFilters) && !_isUndefined(newFilters)) {
          this.refreshData();
        }
      }
    },
  },
  computed: {
    groupPeriod() {
      return calculateGroupPeriod(this.datePeriod[0], this.datePeriod[1]);
    },
    intervals() {
      if (this.datePeriod[0] && this.datePeriod[1]) {
        return calculateDateIntervals(DateTime.fromISO(this.datePeriod[0]), DateTime.fromISO(this.datePeriod[1]), this.groupPeriod);
      } else {
        return undefined;
      }
    },
    datePeriod() {
      if (this.filters.start_date && this.filters.end_date) {
        return [DateTime.fromFormat(this.filters.start_date, this.dateFormat).toISODate(), DateTime.fromFormat(this.filters.end_date, this.dateFormat).toISODate()];
      } else {
          return [];
      }
    },
    workOrdersCompletedPerPeriod() {
      return this.$options.filters.numberFormatFilter(this.dashboardData.work_orders_completed_per_period || 0, 0);
    },
    locationsVisitedPerPeriod() {
      return this.$options.filters.numberFormatFilter(this.dashboardData.locations_visited_per_period || 0, 0);
    },
    totalWorkOrders() {
      return this.$options.filters.numberFormatFilter(this.dashboardData.total_work_orders || 0, 0);
    },
    mobileUsage() {
      return this.$options.filters.percentFormatFilter(this.dashboardData.mobile_app_usage || 0);
    },
    latestAppVersion() {
      return this.dashboardData.latest_app_version || 'N/A';
    },
    devicesPerAccount() {
      return this.dashboardData.mobile_devices_used || 0;
    },
    appUpToDate() {
      return this.dashboardData.app_up_to_date || false;
    },
    avgRating() {
      return this.$options.filters.numberFormatFilter(this.dashboardData.rating || 0);
    },
    avgTimeSpent() {
      return this.$options.filters.numberFormatFilter(this.dashboardData.time_spent || 0);
    },
    costPerWorkOrder() {
      return this.$options.filters.moneyFormatFilter(this.dashboardData.cost_per_work_order || 0);
    },
    grossProfitPerWorkOrder() {
      return this.$options.filters.moneyFormatFilter(this.grossProfit);
    },
    totalRevenue() {
      return this.$options.filters.moneyFormatFilter(this.dashboardData.total_work_order_revenue || 0);
    },
    totalRecurringRevenue() {
      return this.$options.filters.moneyFormatFilter(this.dashboardData.total_recurring_revenue || 0);
    },
    recurringRevenuePerWorkOrder() {
      return (this.dashboardData.total_recurring_revenue || 0) / (this.dashboardData.total_work_orders || 0);
    },
    grossProfit() {
      return (this.dashboardData.price_per_work_order || 0) + this.recurringRevenuePerWorkOrder - (this.dashboardData.cost_per_work_order || 0);
    },
    marginPercentage() {
      if (this.grossProfit === 0) {
        return this.$options.filters.percentFormatFilter(0);
      }
      return this.$options.filters.percentFormatFilter(this.grossProfit / ((this.dashboardData.price_per_work_order || 0) + this.recurringRevenuePerWorkOrder));
    },
  }
};
</script>
