import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '../helpers/apiPath';

export default {
  get(id) {
    return axios
      .get(adminPath(`/insurance_certificate_coverages/${id}`))
      .then((response) => an('InsuranceCertificateCoverage', response.data));
  },
  getCoverages(params) {
    return axios.get(adminPath('/insurance_certificates/coverages?') + prepareQueryString(params)).then((response) => {
      return {
        coverages: response.data.map((insuranceCertificateCoverage) =>
          an('InsuranceCertificateCoverage', insuranceCertificateCoverage)
        ),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getInsuranceCertificateCoverages(insuranceCertificateId, params) {
    return axios
      .get(adminPath(`/insurance_certificates/${insuranceCertificateId}/coverages?`) + prepareQueryString(params))
      .then((response) => {
        return {
          coverages: response.data.map((insuranceCertificateCoverage) =>
            an('InsuranceCertificateCoverage', insuranceCertificateCoverage)
          ),
          total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
        };
      });
  },
  create(insuranceCertificateId, data) {
    return axios
      .post(adminPath(`/insurance_certificates/${insuranceCertificateId}/coverages`), {
        ...data,
      })
      .then((response) => an('InsuranceCertificateCoverage', response.data));
  },
  email(id, data) {
    return axios
      .post(adminPath(`/insurance_certificates/${id}/current_coverage/email`), {
        ...data,
      })
      .then((response) => an('InsuranceCertificate', response.data));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/insurance_certificates/coverages.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  getInsuranceCertificateCoveragesSpreadsheet(params) {
    return axios.get(
      adminPath(`/insurance_certificates/${params.insuranceCertificateId}/coverages.csv?`) + prepareQueryString(params),
      {
        responseType: 'blob',
      }
    );
  },
};
