<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col>
        <b-alert
          :show="hasBaseServerErrors()"
          variant="danger"
          dismissible
        >
          <p
            class="mb-0"
            v-for="(error, index) in baseServerErrors"
            :key="index"
          >
            {{ error }}
          </p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.date"
          label-for="walkthrough-date"
        >
          <sfs-date-picker
            id="walkthrough-date"
            v-model="form.date"
            :class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
            :with-class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.date.$dirty"
            id="walkthrough-date-feedback"
          >
            <span v-if="!$v.form.date.required">Please enter a date.</span>
            <span v-if="!$v.form.date.serverFailed">{{ serverErrors.date }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.user_id"
          label-for="walkthrough-user_id"
        >
          <user-select
            id="walkthrough-user_id"
            :select-class="{ 'is-invalid': $v.form.user_id.$dirty && $v.form.user_id.$error }"
            select-label="name"
            :value="form.user"
            @input="onUserChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.user_id.$dirty"
            id="walkthrough-user_id-feedback"
          >
            <span v-if="!$v.form.user_id.required">Please enter a user.</span>
            <span v-if="!$v.form.user_id.serverFailed">{{ serverErrors.user }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.client_id"
          label-for="walkthrough-client_id"
        >
          <client-select
            id="walkthrough-client_id"
            :select-class="{ 'is-invalid': $v.form.client_id.$dirty && $v.form.client_id.$error }"
            :value="form.client"
            @input="onClientChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.client_id.$dirty"
            id="walkthrough-client_id-feedback"
          >
            <span v-if="!$v.form.client_id.required">Please enter a client.</span>
            <span v-if="!$v.form.client_id.serverFailed">
              {{ serverErrors.client }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.location_id"
          label-for="walkthrough-location_id"
        >
          <location-select
            id="walkthrough-location_id"
            :client-id="form.client_id"
            :select-class="{ 'is-invalid': $v.form.location_id.$dirty && $v.form.location_id.$error }"
            :value="form.location"
            @input="onLocationChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.location_id.$dirty"
            id="walkthrough-location_id-feedback"
          >
            <span v-if="!$v.form.location_id.required">Please enter a location.</span>
            <span v-if="!$v.form.location_id.serverFailed">
              {{ serverErrors.location }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          id="walkthrough-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          id="walkthrough-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import LocationSelect from '@/components/admin/locations/Select.vue';
import ClientSelect from '@/components/admin/clients/Select.vue';
import UserSelect from '@/components/admin/users/Select.vue';

import { WALKTHROUGHS_FORM_LABELS } from '@/constants/walkthroughs.js';
export default {
  name: 'WalkthroughsForm',
  mixins: [formMixin],
  components: {
    LocationSelect,
    ClientSelect,
    UserSelect,
  },
  props: {
    location_id: [String, Number],
    client_id: [String, Number],
    user_id: [String, Number],
    date: String,
    client: Object,
    location: Object,
    user: Object,
  },
  data() {
    return {
      form: {
        location_id: this.location_id,
        client_id: this.client_id,
        user_id: this.user_id,
        date: this.date,
        location: this.location,
        client: this.client,
        user: this.user,
      },
      fieldLabels: {
        ...WALKTHROUGHS_FORM_LABELS,
        client_id: `${this.$store.getters.translate('client', 'Client')}`,
      },
    };
  },
  validations: {
    form: {
      date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('date');
        },
      },
      location_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('location');
        },
      },
      client_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('client');
        },
      },
      user_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('user');
        },
      },
    },
  },
  methods: {
    onLocationChange(location) {
      this.form.location_id = location ? location.id : '';
      this.form.location = location;
    },
    onClientChange(client) {
      this.form.client_id = client ? client.id : '';
      this.form.client = client;
    },
    onUserChange(user) {
      this.form.user_id = user ? user.id : '';
      this.form.user = user;
    },
  },
};
</script>
