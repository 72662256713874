<template>
  <sfs-form-modal
    :id="modalId"
    title="Impersonate a Technician"
    @on-hide="() => user = null"
  >
    <b-form
      novalidate
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            :label="fieldLabels.user_id"
            label-for="user-impersonation-user_id"
          >
            <users-select
              id="user-impersonation-user_id"
              :value="user"
              :select-class="{ 'is-invalid': $v.form.user_id.$dirty && $v.form.user_id.$error }"
              select-label="name"
              placeholder="John Doe"
              :filter-params="userSelectParams"
              @input="onUserChange"
            />
            <b-form-invalid-feedback
              v-if="$v.form.user_id.$dirty"
              id="user-impersonation-user_id-feedback"
            >
              <span v-if="!$v.form.user_id.required">Please enter a technician to impersonate.</span>
              <span v-if="!$v.form.user_id.serverFailed">{{ serverErrors.user_id }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="user-impersonation-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="user-impersonation-submit-button"
            block
            type="submit"
            variant="primary"
            :disabled="processing"
          >
            Impersonate
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import UsersSelect from '@/components/admin/users/Select';
import { USER_TECHNICIAN_CATEGORY } from '@/constants/users';

export default {
  name: 'ImpersonationModal',
  mixins: [formMixin, formModalMixin],
  components: {
    UsersSelect,
  },
  data() {
    return {
      form: {
        user_id: null,
      },
      user: null,
      fieldLabels: {
        user_id: 'Select a Technician to Impersonate',
      },
      userSelectParams: { category: USER_TECHNICIAN_CATEGORY, active: true },
      modalId: 'user-impersonation-modal',
    };
  },
  validations: {
    form: {
      user_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('user_id');
        },
      },
    },
  },
  methods: {
    onUserChange(user) {
      this.form.user_id = user ? user.id : '';
      this.user = user;
    },
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.processing = true;
      this.$technicianUsersAPI
        .impersonate(this.form.user_id)
        .then((response) => {
          this.$store.dispatch('impersonateUser', response.data).then(() => {
            this.resetServerErrors();
            this.$v.$reset();
            this.closeModal();
            this.processing = false;
            this.$router.push({ name: 'technician-dashboard' });
          });
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
          this.processing = false;
        });
    },
  },
};
</script>
