<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-form-group
      v-if="isSubTechnician"
      :label="fieldLabels.technician_id"
      label-for="user-ownership-technician_id"
    >
      <user-select
        :select-class="{ 'is-invalid': $v.form.technician_id.$dirty && $v.form.technician_id.$error }"
        select-label="name"
        name="user-ownership-technician_id"
        :value="form.technician"
        :filter-params="technicianSelectParams"
        @input="onTechnicianChange"
      />
      <b-form-invalid-feedback
        v-if="$v.form.technician_id.$dirty"
        class="user-ownership-technician_id-feedback"
      >
        <span v-if="!$v.form.technician_id.required">Please enter a technician.</span>
        <span v-if="!$v.form.technician_id.serverFailed">{{ serverErrors.technician_id }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      v-else
      :label="fieldLabels.sub_technician_id"
      label-for="user-ownership-sub_technician_id"
    >
      <user-select
        :select-class="{ 'is-invalid': $v.form.sub_technician_id.$dirty && $v.form.sub_technician_id.$error }"
        select-label="name"
        name="user-ownership-sub_technician_id"
        :value="form.sub_technician"
        :filter-params="subTechnicianSelectParams"
        @input="onSubTechnicianChange"
      />
      <b-form-invalid-feedback
        v-if="$v.form.sub_technician_id.$dirty"
        class="user-ownership-sub_technician_id-feedback"
      >
        <span v-if="!$v.form.sub_technician_id.required">Please enter a sub technician.</span>
        <span v-if="!$v.form.sub_technician_id.serverFailed">{{ serverErrors.sub_technician_id }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="user-ownerships-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="user-ownerships-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import UserSelect from '@/components/admin/users/Select.vue';
import { USER_SUB_TECHNICIAN_CATEGORY, USER_TECHNICIAN_CATEGORY } from '@/constants/users';

export default {
  name: 'UserOwnershipsForm',
  components: {
    UserSelect,
  },
  mixins: [formMixin],
  props: {
    isSubTechnician: {
      type: Boolean,
      default: false,
    },
    technician_id: Number,
    sub_technician_id: Number,
    technician: Object,
    sub_technician: Object,
  },
  data() {
    return {
      form: {
        technician_id: this.technician_id,
        technician: this.technician,
        sub_technician_id: this.sub_technician_id,
        sub_technician: this.sub_technician,
      },
      fieldLabels: {
        technician_id: 'Managing Technician',
        sub_technician_id: 'Sub Technician',
      },
      subTechnicianSelectParams: { category: USER_SUB_TECHNICIAN_CATEGORY, active: true },
      technicianSelectParams: { category: USER_TECHNICIAN_CATEGORY, active: true },
    };
  },
  validations: {
    form: {
      technician_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('technician_id');
        },
      },
      sub_technician_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('sub_technician_id');
        },
      },
    },
  },
  methods: {
    onSubTechnicianChange(user) {
      this.form.sub_technician_id = user ? user.id : '';
      this.form.sub_technician = user;
    },
    onTechnicianChange(user) {
      this.form.technician_id = user ? user.id : '';
      this.form.technician = user;
    },
  },
};
</script>
