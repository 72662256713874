import qs from 'qs';
import store from '@/store';

/**
 * This function returns a string query params in a format that is accepted by our API.
 * The API handles things in a certain way, such as Arrays are sent as `foo[]=bar&foo[]=bar2,
 * which must be told to qs in the options param.
 * @param {object} params the params to turn into a query string.
 */
export default function prepareQueryString(params = {}) {
  params.organization_id = params.organization_id || store?.getters?.organization?.id;
  Object.keys(params).forEach((key) => (params[key] = trimWhitespace(params[key])));
  return qs.stringify(params, { arrayFormat: 'brackets' });
}

function trimWhitespace(element) {
  if (typeof element === 'string') {
    return element.trim();
  }

  return element;
}
