import { Ability } from '@casl/ability';
import { isEmpty as _isEmpty } from 'lodash';
import { DEFAULT_TABLE_COLUMNS } from '@/constants';
import { USER_ADMIN_CATEGORY, USER_EMPLOYEE_CATEGORY } from '@/constants/users';
import { I18n } from 'i18n-js';
import { compact as _compact } from 'lodash';

export default {
  token(state) {
    return state.token;
  },
  impersonatingToken(state) {
    return state.impersonatingToken;
  },
  user(state) {
    return state.user;
  },
  impersonatingUser(state) {
    return state.impersonatingUser;
  },
  category(state) {
    return state.category;
  },
  ability() {
    return new Ability();
  },
  isAuthenticated(state) {
    return !!state.token;
  },
  isEmployee(state) {
    return [USER_ADMIN_CATEGORY, USER_EMPLOYEE_CATEGORY].includes(state.category);
  },
  indexPage(state) {
    return [USER_ADMIN_CATEGORY, USER_EMPLOYEE_CATEGORY].includes(state.category) ? 'admin-dashboard' : 'technician-dashboard';
  },
  organization(state) {
    return state.organization;
  },
  organizationTerminology(state) {
    return state.organizationTerminology;
  },
  client(state) {
    return state.client;
  },
  userListColumns(state) {
    return state.userListColumns;
  },
  userPerPage(state) {
    return state.userPerPage;
  },
  organizationListColumns(state) {
    return state.organizationListColumns;
  },
  columnsForTable(state, getters) {
    return (tableName) => {
      const customColumns = (getters.userListColumns || {})[tableName] || (getters.organizationListColumns || {})[tableName] || {};
      if (_isEmpty(customColumns)) {
        return DEFAULT_TABLE_COLUMNS[tableName]?.default;
      }

      return _compact(customColumns.map((key) => DEFAULT_TABLE_COLUMNS[tableName]?.columns[key]));
    };
  },
  currentOrganization(state) {
    return state.organization || state.user?.organization;
  },
  translate(state) {
    return (key, defaultValue) => {
      const translations = state.organizationTerminology;
      const i18n = new I18n(translations);

      if(i18n.t(key).charAt(0) === '[') { return defaultValue; }
      return i18n.t(key);
    };
  }
};
