<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit Additional Info"
    :size="form.customFieldValues.length > 3 ? 'lg' : 'sm'"
  >
    <b-form @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-if="form.customFieldValues.length === 0">
        <b-col class="mt-3">
          <p class="text-muted">
            There are no additional fields available for this Work Order.
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-for="(v, index) in $v.form.customFieldValues.$each.$iter"
          :key="index"
          :cols="form.customFieldValues.length > 3 ? 6 : 12"
        >
          <form-field
            :custom-field="v.custom_field.$model"
            v-model="v.value.$model"
            :validation-class="{ 'is-invalid': v.value.$dirty && v.value.$error }"
            :time-zone="value.location && value.location.time_zone"
          >
            <template #feedback>
              <b-form-invalid-feedback
                v-if="v.value.$dirty"
                id="work-order-custom-field-form-scheduled_date-feedback"
              >
                <span v-if="!v.value.serverFailed">{{ serverErrors.value }}</span>
              </b-form-invalid-feedback>
            </template>
          </form-field>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="work-order-custom-field-form-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="work-order-custom-field-form-submit-button"
            block
            type="submit"
            variant="primary"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { isNil as _isNil } from 'lodash';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import FormField from '@/components/admin/customFields/FormField';

export default {
  name: 'WorkOrdersCustomFieldsFormModal',
  mixins: [formMixin, formModalMixin],
  components: {
    FormField,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    customFieldValues: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        customFieldValues: [],
      },
      modalId: 'work-order-custom-fields-form-modal',
    };
  },
  validations: {
    form: {
      customFieldValues: {
        $each: {
          value: {
            serverFailed() {
              return !this.hasServerErrors('value');
            },
          },
          custom_field: {},
          custom_field_id: {},
          field_type: {},
        },
      },
    },
  },
  mounted() {
    this.getCustomFields();
  },
  methods: {
    getCustomFields() {
      this.$customFieldsAPI.getWorkOrderOptions(this.value.id).then(({ customFields }) => {
        this.form.customFieldValues = customFields.map((customField) => ({
          value: this.getPropValue(customField.id),
          custom_field: customField,
          custom_field_id: customField.id,
          field_type: customField.field_type,
        }));
        this.$emit('data-changed', customFields);
      });
    },
    getPropValue(customFieldId) {
      return this.customFieldValues.find((customFieldValue) => customFieldValue.custom_field_id === customFieldId)?.value;
    },
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      const customFieldValues = this.form.customFieldValues.filter((fieldValue) => {
        return !(_isNil(fieldValue.value) || fieldValue.value === '');
      });
      this.$customFieldsAPI
        .manageWorkOrderValues(this.value.id, { custom_field_values_attributes: customFieldValues })
        .then(({ customFieldValues }) => {
          this.resetServerErrors();
          this.$v.$reset();
          this.$emit('input', { customFieldValues });
          this.closeModal();
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
        });
    },
  },
};
</script>
