<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col>
        <b-alert
          :show="hasBaseServerErrors()"
          variant="danger"
          dismissible
        >
          <p
            class="mb-0"
            v-for="(error, index) in baseServerErrors"
            :key="index"
          >
            {{ error }}
          </p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.name"
          label-for="service-name"
        >
          <b-form-input
            id="service-name"
            v-model="$v.form.name.$model"
            :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.name.$dirty"
            id="service-name-feedback"
          >
            <span v-if="!$v.form.name.required">Please enter a name.</span>
            <span v-if="!$v.form.name.maxLength">
              The name must be less than {{ $v.form.name.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.name.serverFailed">{{ serverErrors.name }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.category"
          label-for="service-category"
        >
          <category-select
            id="service-category"
            v-model="form.category"
            :select-class="{ 'is-invalid': $v.form.category.$dirty && $v.form.category.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.category.$dirty"
            id="service-category-feedback"
          >
            <span v-if="!$v.form.category.required">Please enter a category.</span>
            <span v-if="!$v.form.category.maxLength">
              The category must be less than {{ $v.form.category.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.category.serverFailed">{{ serverErrors.category }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.organization_id"
          label-for="service-organization_id"
        >
          <organization-select
            id="service-organization_id"
            :select-class="{ 'is-invalid': $v.form.organization_id.$dirty && $v.form.organization_id.$error }"
            :value="form.organization"
            @input="onOrganizationChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.organization_id.$dirty"
            id="service-organization_id-feedback"
          >
            <span v-if="!$v.form.organization_id.required">Please enter an organization.</span>
            <span v-if="!$v.form.organization_id.serverFailed">
              {{ serverErrors.organization }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.client_id"
          label-for="service-client_id"
        >
          <client-select
            id="service-client_id"
            :select-class="{ 'is-invalid': $v.form.client_id.$dirty && $v.form.client_id.$error }"
            :value="form.client"
            @input="onClientChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.client_id.$dirty"
            id="service-client_id-feedback"
          >
            <span v-if="!$v.form.client_id.serverFailed">
              {{ serverErrors.client }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          :label="fieldLabels.description"
          label-for="service-description"
        >
          <b-form-textarea
            id="service-description"
            v-model="$v.form.description.$model"
            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
            rows="4"
          />
          <b-form-invalid-feedback
            v-if="$v.form.description.$dirty"
            id="service-description-feedback"
          >
            <span v-if="!$v.form.description.serverFailed">{{ serverErrors.description }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.minimum_days_between_service"
          label-for="service-minimum_days_between_service"
        >
          <b-input-group
            append="Days"
            :class="{ 'is-invalid': $v.form.estimated_hours.$dirty && $v.form.estimated_hours.$error }"
          >
            <b-form-input
              id="service-minimum_days_between_service"
              v-model="$v.form.minimum_days_between_service.$model"
              :state="$v.form.minimum_days_between_service.$dirty ? !$v.form.minimum_days_between_service.$error : null"
              type="number"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.minimum_days_between_service.$dirty"
            id="service-minimum_days_between_service-feedback"
          >
            <span v-if="!$v.form.minimum_days_between_service.integer">Please enter a number.</span>
            <span v-if="!$v.form.minimum_days_between_service.serverFailed">
              {{ serverErrors.minimum_days_between_service }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.estimated_hours"
          label-for="service-estimated_hours"
        >
          <b-input-group
            append="Hours"
            :class="{ 'is-invalid': $v.form.minimum_days_between_service.$dirty && $v.form.minimum_days_between_service.$error }"
          >
            <b-form-input
              id="service-estimated_hours"
              v-model="$v.form.estimated_hours.$model"
              :state="$v.form.estimated_hours.$dirty ? !$v.form.estimated_hours.$error : null"
              type="text"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.estimated_hours.$dirty"
            id="service-estimated_hours-feedback"
          >
            <span v-if="!$v.form.estimated_hours.decimal">Please enter a number.</span>
            <span v-if="!$v.form.estimated_hours.serverFailed">
              {{ serverErrors.estimated_hours }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <h3 class="mb-2">
      Billing
    </h3>

    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.invoice_gl_code_id"
          label-for="service-invoice_gl_code_id"
        >
          <gl-code-select
            id="service-invoice_gl_code_id"
            :select-class="{ 'is-invalid': $v.form.invoice_gl_code_id.$dirty && $v.form.invoice_gl_code_id.$error }"
            client-unassigned
            :value="form.invoice_gl_code"
            @input="onInvoiceGlCodeChange"
            :filter-params="invoiceGlCodeFilters"
          />
          <b-form-invalid-feedback
            v-if="$v.form.invoice_gl_code_id.$dirty"
            id="service-invoice_gl_code_id-feedback"
          >
            <span v-if="!$v.form.invoice_gl_code_id.serverFailed">
              {{ serverErrors.invoice_gl_code }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.technician_payment_gl_code_id"
          label-for="service-technician_payment_gl_code_id"
        >
          <gl-code-select
            id="service-technician_payment_gl_code_id"
            :select-class="{ 'is-invalid': $v.form.technician_payment_gl_code_id.$dirty && $v.form.technician_payment_gl_code_id.$error }"
            client-unassigned
            :value="form.technician_payment_gl_code"
            @input="onTechnicianPaymentGlCodeChange"
            :filter-params="technicianPaymentGlCodeFilters"
          />
          <b-form-invalid-feedback
            v-if="$v.form.technician_payment_gl_code_id.$dirty"
            id="service-technician_payment_gl_code_id-feedback"
          >
            <span v-if="!$v.form.technician_payment_gl_code_id.serverFailed">
              {{ serverErrors.technician_payment_gl_code }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.customer_identifier"
          label-for="service-customer_identifier"
        >
          <b-form-input
            id="service-customer_identifier"
            v-model="$v.form.customer_identifier.$model"
            :state="$v.form.customer_identifier.$dirty ? !$v.form.customer_identifier.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.customer_identifier.$dirty"
            id="service-customer_identifier-feedback"
          >
            <!-- prettier-ignore -->
            <span v-if="!$v.form.customer_identifier.maxLength">
              The customer identifier must be less than {{ $v.form.customer_identifier.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.customer_identifier.serverFailed">{{ this.serverErrors.customer_identifier }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group label-for="service-tax_categorized_via_work_order">
              &nbsp;
          <b-form-checkbox
            switch
            id="service-tax_categorized_via_work_order"
            v-model="$v.form.tax_categorized_via_work_order.$model"
          >
            Tax is categorized through the Work Order instead of the Charge?
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <h3 class="mb-2">
      Settings
    </h3>

    <b-row>
      <b-col lg="6">
        <b-form-group label-for="service-add_on">
          <b-form-checkbox
            switch
            id="service-add_on"
            v-model="$v.form.add_on.$model"
          >
            Is this an add-on Service?
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group label-for="service-work_order_charges_require_user">
          <b-form-checkbox
            switch
            id="service-work_order_charges_require_user"
            v-model="$v.form.work_order_charges_require_user.$model"
          >
            All Work Order Charges require a Technician
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          id="service-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          id="service-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength, integer, decimal } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import CategorySelect from '@/components/admin/services/CategorySelect.vue';
import ClientSelect from '@/components/admin/clients/Select.vue';
import GlCodeSelect from '@/components/admin/glCodes/Select.vue';
import OrganizationSelect from '@/components/admin/organizations/Select.vue';
import { GL_CODE_TECHNICIAN_PAYMENT_CATEGORY, GL_CODE_INVOICE_CATEGORY } from '@/constants/glCodes';

export default {
  name: 'ServicesForm',
  components: {
    CategorySelect,
    OrganizationSelect,
    ClientSelect,
    GlCodeSelect,
  },
  mixins: [formMixin],
  props: {
    organization_id: [String, Number],
    client_id: [String, Number],
    technician_payment_gl_code_id: [String, Number],
    invoice_gl_code_id: [String, Number],
    name: String,
    description: String,
    category: String,
    minimum_days_between_service: [String, Number],
    estimated_hours: [String, Number],
    customer_identifier: String,
    add_on: Boolean,
    work_order_charges_require_user: Boolean,
    tax_categorized_via_work_order: Boolean,
    organization: Object,
    client: Object,
    technician_payment_gl_code: Object,
    invoice_gl_code: Object,
  },
  data() {
    return {
      form: {
        organization_id: this.organization_id,
        client_id: this.client_id,
        technician_payment_gl_code_id: this.technician_payment_gl_code_id,
        invoice_gl_code_id: this.invoice_gl_code_id,
        name: this.name,
        description: this.description,
        category: this.category,
        minimum_days_between_service: this.minimum_days_between_service,
        estimated_hours: this.estimated_hours,
        customer_identifier: this.customer_identifier,
        add_on: this.add_on,
        work_order_charges_require_user: this.work_order_charges_require_user,
        tax_categorized_via_work_order: this.tax_categorized_via_work_order,
        organization: this.organization,
        client: this.client,
        technician_payment_gl_code: this.technician_payment_gl_code,
        invoice_gl_code: this.invoice_gl_code,
      },
      fieldLabels: {
        name: 'Name *',
        description: 'Description',
        category: this.$store.getters.translate('services.category', 'Category') + ' *',
        minimum_days_between_service: 'Minimum Days Between Service',
        estimated_hours: 'Estimated Hours to Complete',
        customer_identifier: 'Customer Identifier',
        add_on: 'Add-on',
        work_order_charges_require_user: 'All Work Order Charges require a Technician',
        tax_categorized_via_work_order: 'Tax is categorized through the Work Order instead of the Charge?',
        organization_id: 'Organization *',
        client_id: this.$store.getters.translate('client', 'Client'),
        technician_payment_gl_code_id: 'Technician Payment GL Code',
        invoice_gl_code_id: 'Invoice GL Code',
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('name');
        },
      },
      description: {
        serverFailed() {
          return !this.hasServerErrors('description');
        },
      },
      category: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('category');
        },
      },
      minimum_days_between_service: {
        integer,
        serverFailed() {
          return !this.hasServerErrors('minimum_days_between_service');
        },
      },
      estimated_hours: {
        decimal,
        serverFailed() {
          return !this.hasServerErrors('estimated_hours');
        },
      },
      customer_identifier: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('customer_identifier');
        },
      },
      add_on: {},
      work_order_charges_require_user: {},
      tax_categorized_via_work_order: {},
      organization_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('organization');
        },
      },
      client_id: {
        serverFailed() {
          return !this.hasServerErrors('client');
        },
      },
      technician_payment_gl_code_id: {
        serverFailed() {
          return !this.hasServerErrors('technician_payment_gl_code');
        },
      },
      invoice_gl_code_id: {
        serverFailed() {
          return !this.hasServerErrors('invoice_gl_code');
        },
      },
    },
  },
  computed: {
    technicianPaymentGlCodeFilters() {
      return { client_id: this.form.client_id, category: GL_CODE_TECHNICIAN_PAYMENT_CATEGORY, active: true };
    },
    invoiceGlCodeFilters() {
      return { client_id: this.form.client_id, category: GL_CODE_INVOICE_CATEGORY, active: true };
    },
  },
  methods: {
    onOrganizationChange(organization) {
      this.form.organization_id = organization ? organization.id : '';
      this.form.organization = organization;
    },
    onClientChange(client) {
      this.form.client_id = client ? client.id : null;
      this.form.client = client;
    },
    onTechnicianPaymentGlCodeChange(glCode) {
      this.form.technician_payment_gl_code_id = glCode ? glCode.id : '';
      this.form.technician_payment_gl_code = glCode;
    },
    onInvoiceGlCodeChange(glCode) {
      this.form.invoice_gl_code_id = glCode ? glCode.id : '';
      this.form.invoice_gl_code = glCode;
    },
  },
};
</script>
