<template>
  <sfs-form-modal
    :id="modalId"
    title="Add an Invoice"
    @on-hide="resetFormData"
  >
    <invoices-form
      @on-submit="createInvoice"
      @on-save="redirectToInvoice"
      @on-cancel="closeModal"
      :key="formKey"
      :date="new Date().toISOString()"
    />
  </sfs-form-modal>
</template>

<script>
import InvoicesForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'InvoicesAddModal',
  mixins: [formModalMixin],
  components: {
    InvoicesForm,
  },
  data() {
    return {
      modalId: 'invoice-add-modal',
    };
  },
  methods: {
    createInvoice(data, handleCreate) {
      handleCreate(this.$invoicesAPI.create(data));
    },
    redirectToInvoice(invoice) {
      this.$router.push({ name: 'invoice', params: { id: invoice.id } });
    },
  },
};
</script>
