<template>
  <div>
    <base-page-heading title="Payments" />
    <div class="content">
      <b-row>
        <b-col>
          <technician-payments-list-filter
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>
      <technician-payments-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
  </div>
</template>

<script>
import TechnicianPaymentsListFilter from '@/components/technician/technicianPayments/ListFilter.vue';
import TechnicianPaymentsTable from '@/components/technician/technicianPayments/Table.vue';
import listMixin from '@/mixins/listMixin';

import { TECHNICIAN_USER_PAYMENT_TABLE_NAME } from '@/constants/technicianPayments';

export default {
  name: 'TechnicianPaymentsList',
  components: {
    TechnicianPaymentsListFilter,
    TechnicianPaymentsTable,
  },
  mixins: [listMixin],
  props: {
    text: String,
    date_from: String,
    date_to: String,
    due_date_from: String,
    due_date_to: String,
    date_between: String,
    due_date_between: String,
    status: String,
  },
  data() {
    return {
      tableName: TECHNICIAN_USER_PAYMENT_TABLE_NAME,
      filters: {
        text: this.text,
        date_from: this.date_from,
        date_to: this.date_to,
        due_date_from: this.due_date_from,
        due_date_to: this.due_date_to,
        date_between: this.date_between,
        due_date_between: this.due_date_between,
        status: this.status,
      },
    };
  },
};
</script>
