<template>
  <b-modal
    :id="modalId"
    title="Verification History"
    no-close-on-backdrop
    hide-footer
    static
    lazy
  >
    <template #modal-header-close>
      <i class="fa fa-close" />
    </template>
    <b-row>
      <b-col>
        <verifications-table
          :insurance-certificate-coverage-id="value.id"
          :filters="{ paginate: false }"
          :per-page="1"
          :show-per-page="false"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="3"
        offset="9"
      >
        <b-button
          variant="primary"

          block
          id="version-changes-close-button"
          @click="closeModal"
        >
          Close
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import VerificationsTable from '@/components/admin/insuranceCertificateVerifications/Table.vue';

export default {
  name: 'InsuranceCertificateVerificationsTableModal',
  components: {
    VerificationsTable,
  },
  props: {
    value: Object,
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
  computed: {
    modalId() {
      return `insurance-certificate-verification-table-${this.value.id}-modal`;
    },
  },
};
</script>
