<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col>
        <b-alert
          :show="hasBaseServerErrors()"
          variant="danger"
          dismissible
        >
          <p
            class="mb-0"
            v-for="(error, index) in baseServerErrors"
            :key="index"
          >
            {{ error }}
          </p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
      >
        <b-form-group
          :label="fieldLabels.item_number"
          label-for="supply-item_number"
        >
          <b-form-input
            name="supply-item_number"
            v-model="$v.form.item_number.$model"
            :state="$v.form.item_number.$dirty ? !$v.form.item_number.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.item_number.$dirty"
            class="supply-item_number-feedback"
          >
            <span v-if="!$v.form.item_number.maxLength">
              The item number must be less than {{ $v.form.item_number.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.item_number.serverFailed">{{ serverErrors.item_number }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.category"
          label-for="supply-client-category"
        >
          <category-select
            id="supply-client-category"
            v-model="form.category"
            :select-class="{ 'is-invalid': $v.form.category.$dirty && $v.form.category.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.category.$dirty"
            id="supply-client-category-feedback"
          >
            <span v-if="!$v.form.category.required">Please enter a category.</span>
            <span v-if="!$v.form.category.maxLength">
              The category must be less than {{ $v.form.category.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.category.serverFailed">{{ serverErrors.category }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.units"
          label-for="supply-units"
        >
          <b-form-input
            name="supply-units"
            v-model="$v.form.units.$model"
            :state="$v.form.units.$dirty ? !$v.form.units.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.units.$dirty"
            class="supply-units-feedback"
          >
            <span v-if="!$v.form.units.maxLength">
              The units must be less than {{ $v.form.units.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.units.serverFailed">{{ serverErrors.units }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
      >
        <b-form-group
          :label="fieldLabels.description"
          label-for="supply-description"
        >
          <b-form-input
            name="supply-description"
            v-model="$v.form.description.$model"
            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.description.$dirty"
            class="supply-description-feedback"
          >
            <span v-if="!$v.form.description.required">Please enter a description.</span>
            <span v-if="!$v.form.description.maxLength">
              The description must be less than {{ $v.form.description.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.description.serverFailed">{{ serverErrors.description }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
      >
        <b-form-group
          :label="fieldLabels.client_description"
          label-for="supply-client_description"
        >
          <b-form-input
            name="supply-client_description"
            v-model="$v.form.client_description.$model"
            :state="$v.form.client_description.$dirty ? !$v.form.client_description.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.client_description.$dirty"
            class="supply-client_description-feedback"
          >
            <span v-if="!$v.form.client_description.required">Please enter a client_description.</span>
            <span v-if="!$v.form.client_description.maxLength">
              The client_description must be less than {{ $v.form.client_description.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.client_description.serverFailed">{{ serverErrors.client_description }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.cost"
          label-for="supply-cost"
        >
          <b-input-group
            prepend="$"
            :class="{ 'is-invalid': $v.form.cost.$dirty && $v.form.cost.$error, 'has-warning': negativeProfitability }"
          >
            <b-form-input
              name="supply-cost"
              v-model="$v.form.cost.$model"
              :state="$v.form.cost.$dirty ? !$v.form.cost.$error : null"
              type="text"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.cost.$dirty"
            :state="$v.form.cost.$dirty ? !$v.form.cost.$error : null"
            class="supply-cost-feedback"
          >
            <span v-if="!$v.form.cost.required">Please enter a cost.</span>
            <span v-if="!$v.form.cost.decimal">Please enter a number.</span>
            <span v-if="!$v.form.cost.serverFailed">
              {{ serverErrors.cost_cents }}
            </span>
          </b-form-invalid-feedback>
          <div class="warning-feedback">
            <span v-if="negativeProfitability">Warning: cost is more than the price.</span>
          </div>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          label="Markup"
          label-for="supply-markup"
        >
          <b-input-group
            append="%"
            :class="{ 'is-invalid': $v.form.markup.$dirty && $v.form.markup.$error }"
          >
            <b-form-input
              name="supply-markup"
              v-model="$v.form.markup.$model"
              :state="$v.form.markup.$dirty ? !$v.form.markup.$error : null"
              type="text"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.markup.$dirty"
            :state="$v.form.markup.$dirty ? !$v.form.markup.$error : null"
            class="supply-markup-feedback"
          >
            <span v-if="!$v.form.markup.decimal">Please enter a number.</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
      >
        <b-form-group
          :label="fieldLabels.price"
          label-for="supply-price"
        >
          <b-input-group
            prepend="$"
            :class="{ 'is-invalid': $v.form.price.$dirty && $v.form.price.$error }"
          >
            <b-form-input
              name="supply-price"
              v-model="$v.form.price.$model"
              :state="$v.form.price.$dirty ? !$v.form.price.$error : null"
              type="text"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.price.$dirty"
            :state="$v.form.price.$dirty ? !$v.form.price.$error : null"
            class="supply-price-feedback"
          >
            <span v-if="!$v.form.price.required">Please enter a price.</span>
            <span v-if="!$v.form.price.decimal">Please enter a number.</span>
            <span v-if="!$v.form.price.serverFailed">
              {{ serverErrors.price_cents }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        v-if="newForm"
        cols="12"
      >
        <b-form-group
          :label="fieldLabels.vendor_id"
          label-for="supply-vendor_id"
        >
          <vendor-select
            name="supply-vendor_id"
            :select-class="{ 'is-invalid': $v.form.vendor_id.$dirty && $v.form.vendor_id.$error }"
            :value="form.vendor"
            @input="onVendorChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.vendor_id.$dirty"
            class="supply-vendor_id-feedback"
          >
            <span v-if="!$v.form.vendor_id.required">Please enter an vendor.</span>
            <span v-if="!$v.form.vendor_id.serverFailed">
              {{ serverErrors.vendor_id }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
      >
        <b-form-group
          :label="fieldLabels.client_id"
          label-for="supply-client_id"
        >
          <client-select
            name="supply-client_id"
            :select-class="{ 'is-invalid': $v.form.client_id.$dirty && $v.form.client_id.$error }"
            :value="form.client"
            @input="onClientChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.client_id.$dirty"
            class="supply-client_id-feedback"
          >
            <span v-if="!$v.form.client_id.serverFailed">
              {{ serverErrors.client_id }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          class="supply-close-button"
          block
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          block
          class="supply-submit-button"
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength, decimal } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import VendorSelect from '@/components/admin/vendors/Select.vue';
import ClientSelect from '@/components/admin/clients/Select.vue';
import CategorySelect from '@/components/admin/supplies/CategorySelect.vue';
import { SUPPLIES_FORM_LABELS } from '@/constants/supplies.js';
import { fromCents, toCents, roundedMoney } from '@/utils/money';

export default {
  name: 'SuppliesForm',
  mixins: [formMixin],
  components: {
    VendorSelect,
    ClientSelect,
    CategorySelect,
  },
  props: {
    newForm: {
      type: Boolean,
      default: true,
    },
    vendor_id: [String, Number],
    client_id: [String, Number],
    item_number: String,
    description: String,
    client_description: String,
    category: String,
    units: String,
    cost: Object,
    price: Object,
    vendor: Object,
    client: Object,
  },
  data() {
    const markup = this.price && this.cost ? (((this.price.amount / this.cost.amount) - 1) * 100).toFixed(2) : 25;
    return {
      form: {
        vendor_id: this.vendor_id,
        client_id: this.client_id,
        item_number: this.item_number,
        description: this.description,
        client_description: this.client_description,
        category: this.category,
        units: this.units,
        cost: this.cost && this.cost.amount ? this.cost.amount / 100 : 0,
        cost_cents: this.cost && this.cost.amount ? this.cost.amount : 0,
        price: this.price && this.price.amount ? this.price.amount / 100 : 0,
        price_cents: this.price && this.price.amount ? this.price.amount : 0,
        markup: markup,
        vendor: this.vendor,
        client: this.client,
      },
      fieldLabels: {
        ...SUPPLIES_FORM_LABELS,
        description: 'Description *',
        category: 'Category *',
        cost: 'Unit Cost *',
        price: 'Unit Price *',
        vendor_id: 'Vendor *',
      },
    };
  },
  validations: {
    form: {
      item_number: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('item_number');
        },
      },
      description: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('description');
        },
      },
      client_description: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('client_description');
        },
      },
      category: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('category');
        },
      },
      units: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('units');
        },
      },
      cost: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('cost_cents');
        },
      },
      markup: {
        decimal,
      },
      price: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('price_cents');
        },
      },
      vendor_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('vendor');
        },
      },
      client_id: {
        serverFailed() {
          return !this.hasServerErrors('client');
        },
      },
    },
  },
  computed: {
    negativeProfitability() {
      return this.form.cost_cents > this.form.price_cents;
    },
  },
  watch: {
    'form.cost': function (newCost) {
      this.form.cost_cents = toCents(newCost);
      if (!isNaN(newCost)) {
        const newPriceCents = this.form.cost_cents + (this.form.cost_cents * (this.form.markup / 100));
        this.form.price_cents = newPriceCents;
        this.form.price = roundedMoney(fromCents(newPriceCents));
      }
    },
    'form.price': function (newPrice) {
      this.form.price_cents = toCents(newPrice);
    },
    'form.markup': function (newMarkup) {
      const markup = isNaN(newMarkup) ? 0 : newMarkup / 100;
      const newPriceCents = this.form.cost_cents + (this.form.cost_cents * markup);
      this.form.price_cents = newPriceCents;
      this.form.price = roundedMoney(fromCents(newPriceCents));
    },
  },
  methods: {
    onVendorChange(vendor) {
      this.form.vendor_id = vendor ? vendor.id : '';
      this.form.vendor = vendor;
    },
    onClientChange(client) {
      this.form.client_id = client ? client.id : '';
      this.form.client = client;
    },
  },
};
</script>
