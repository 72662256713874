import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const INVOICE_ITEM_TABLE_NAME = 'invoiceItems';

// The columns available to be shown in a clients table.
export const INVOICE_ITEM_TABLE_COLUMNS = {
  id: { label: 'ID', key: 'id' },
  invoice_id: { label: 'Invoice', key: 'invoice_id' },
  reference_id: { label: 'Reference', key: 'reference_id' },
  location_id: { label: 'Location', key: 'location_id' },
  gl_code_id: { label: 'GL Code', key: 'gl_code_id' },
  details: { label: 'Details', key: 'details' },
  description: { label: 'Description', key: 'description' },
  amount: { label: 'Amount', key: 'amount' },
  tax_amount: { label: 'Tax', key: 'tax_amount' },
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const INVOICE_ITEM_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(INVOICE_ITEM_TABLE_COLUMNS);

// The default columns to be shown when the clients table loads.
export const INVOICE_ITEM_TABLE_DEFAULT_COLUMNS = [
  INVOICE_ITEM_TABLE_COLUMNS.reference_id,
  INVOICE_ITEM_TABLE_COLUMNS.details,
  INVOICE_ITEM_TABLE_COLUMNS.location_id,
  INVOICE_ITEM_TABLE_COLUMNS.description,
  INVOICE_ITEM_TABLE_COLUMNS.amount,
  INVOICE_ITEM_TABLE_COLUMNS.tax_amount,
  INVOICE_ITEM_TABLE_COLUMNS.actions,
];
