<template>
  <sfs-base-table
    id="delayed-jobs-table"
    :key="tableKey"
    :data-provider="$delayedJobsAPI.getDelayedJobs"
    provider-key="delayedJobs"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(job_class)="data">
      <code>{{ data.item.job_data.job_class }}</code>
      <div class="font-size-sm text-secondary">
        {{ data.item.queue }}
      </div>
    </template>
    <template #cell(status)="data">
      <div>{{ data.item.status | capitalizeFormat }}</div>
      <div
        v-if="data.item.failed_at"
        class="font-size-sm text-secondary"
      >
        {{ data.item.failed_at | dateTimeFormat }}
      </div>
    </template>
    <template #cell(arguments)="data">
      <span>{{ data.item.job_data.arguments[0]._aj_globalid }}</span>
    </template>
    <template #cell(run_at)="data">
      <span v-if="data.item.run_at">{{ data.item.run_at | dateTimeFormat }}</span>
    </template>
    <template #cell(last_error)="data">
      <b-button
        v-if="data.item.last_error"
        v-b-modal="`delayed-job-${data.item.id}-error-info`"
        v-b-tooltip.hover="{ boundary: 'window' }"
        :title="data.item.last_error.slice(0, 255)"
        variant="outline-secondary"
        size="sm"
      >
        View
      </b-button>
      <error-details-modal
        v-if="data.item.last_error"
        :modal-id="`delayed-job-${data.item.id}-error-info`"
        :value="data.item"
      />
    </template>
    <template #cell(actions)="data">
      <b-button-group>
        <b-button
          size="sm"
          variant="link"
          @click="rerunDelayedJob(data.item.id)"
        >
          <i class="fa fa-refresh" />
        </b-button>
        <b-button
          v-if="$can('destroy', data.item)"
          size="sm"
          variant="link"
          @click="destroyDelayedJobDialog(data.item.id)"
        >
          <i class="fa fa-trash" />
        </b-button>
      </b-button-group>
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import { DELAYED_JOB_TABLE_DEFAULT_COLUMNS } from '@/constants/delayedJobs';
import ErrorDetailsModal from '@/components/admin/delayedJobs/ErrorDetailsModal.vue';

export default {
  name: 'DelayedJobsTable',
  mixins: [tablePropsMixin],
  components: {
    ErrorDetailsModal,
  },
  props: {
    columns: {
      type: Array,
      default: () => DELAYED_JOB_TABLE_DEFAULT_COLUMNS,
    },
  },
  methods: {
    destroyDelayedJobDialog(id) {
      this.$swal({
        text: 'Once it\'s deleted, its gone forever!',
        preConfirm: () => this.$delayedJobsAPI.destroy(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
    rerunDelayedJob(id) {
      this.$delayedJobsAPI.rerunDelayedJob(id).then(() => this.refreshTable());
    }
  },
};
</script>
