<template>
  <div>
    <base-page-heading title="Services">
      <template #extra>
        <b-button
          v-if="$can('create', 'Service')"
          variant="primary"
          class="mr-2"
          v-b-modal.service-add-modal
          v-b-tooltip.hover
          title="Create a new Service"
        >
          <i class="fa fa-plus mr-1" />
          New Service
        </b-button>
        <sfs-download-button
          v-b-tooltip.hover
          :provider-params="filters"
          filename="Services.csv"
          :file-provider="$servicesAPI.getSpreadsheet"
          title="Download spreadsheet"
          label="Download"
        />
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <services-list-filter
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>
      <services-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>

    <!-- Page Modals -->
    <services-new-modal v-if="$can('create', 'Service')" />
  </div>
</template>

<script>
import ServicesNewModal from '@/components/admin/services/NewModal.vue';
import ServicesListFilter from '@/components/admin/services/ListFilter.vue';
import ServicesTable from '@/components/admin/services/Table.vue';
import listMixin from '@/mixins/listMixin';
import { SERVICE_TABLE_NAME } from '@/constants/services';

export default {
  name: 'ServicesList',
  components: {
    ServicesNewModal,
    ServicesListFilter,
    ServicesTable,
  },
  mixins: [listMixin],
  props: {
    name: String,
    description: String,
    category: String,
    minimum_days_between_service: String,
    text: String,
    client_id: String,
    active: String,
  },
  data() {
    return {
      tableName: SERVICE_TABLE_NAME,
      filters: {
        name: this.name,
        description: this.description,
        category: this.category,
        minimum_days_between_service: this.minimum_days_between_service,
        text: this.text,
        client_id: this.client_id || this.$store.getters?.client?.id,
        active: this.active,
      },
    };
  },
};
</script>
