<template>
  <sfs-form-modal
    :id="modalId"
    title="Add a Tax Rate"
    @on-hide="resetFormData"
  >
    <tax-rates-form
      :organization="organization"
      :organization_id="organization_id"
      @on-submit="createTaxRate"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import TaxRatesForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'TaxRatesAddModal',
  mixins: [formModalMixin],
  components: {
    TaxRatesForm,
  },
  data() {
    return {
      modalId: 'tax-rates-add-modal',
    };
  },
  methods: {
    createTaxRate(data, handleCreate) {
      handleCreate(this.$taxRatesAPI.create(data));
    },
    onSave() {
      this.closeModal();
      this.resetFormData();
      this.$emit('on-save');
    },
  },
  computed: {
    organization() {
      return this.$store.getters.currentOrganization;
    },
    organization_id() {
      return this.organization?.id;
    },
  },
};
</script>
