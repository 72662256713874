<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit Technician Payment"
    @on-hide="resetFormData"
  >
    <technician-payment-form
      v-bind="value"
      @on-submit="updateTechnicianPayment"
      @on-save="onSave"
      @on-cancel="closeModal"
      :new-form="false"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import TechnicianPaymentForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'TechnicianPaymentEditModal',
  mixins: [formModalMixin],
  components: {
    TechnicianPaymentForm,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: 'technician-payment-edit-modal',
    };
  },
  methods: {
    updateTechnicianPayment(data, handleUpdate) {
      handleUpdate(this.$technicianPaymentsAPI.update(this.value.id, data));
    },
    onSave(technicianPayment) {
      this.closeModal();
      this.resetFormData();
      this.$emit('input', technicianPayment);
    },
  },
};
</script>
