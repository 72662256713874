<template>
  <sfs-base-table
    :data-provider="$invoicesAPI.getInvoices"
    provider-key="invoices"
    :columns="translatedColumns(modelName, columns)"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(number)="data">
      <b-link :to="{ name: 'invoice', params: { id: data.item.id } }">
        {{ data.item.number }}
      </b-link>
      <div class="font-size-sm text-secondary">
        <strong>ID: {{ data.item.id }}</strong>
      </div>
    </template>
    <template #cell(client_id)="data">
      <b-link :to="{ name: 'client', params: { id: data.item.client.id } }">
        {{ data.item.client.name }}
      </b-link>
    </template>
    <template #cell(date)="data">
      {{ data.item.date | dateFormat }}
    </template>
    <template #cell(due_date)="data">
      {{ data.item.due_date | dateFormat }}
    </template>
    <template #cell(subtotal)="data">
      <money-format
        :value="data.item.subtotal.amount"
        :currency-code="data.item.subtotal.currency"
        subunits-value
      />
    </template>
    <template #cell(taxes)="data">
      <money-format
        :value="data.item.taxes.amount"
        :currency-code="data.item.taxes.currency"
        subunits-value
      />
    </template>
    <template #cell(balance)="data">
      <money-format
        :value="data.item.balance.amount"
        :currency-code="data.item.balance.currency"
        subunits-value
      />
    </template>
    <template #cell(status)="data">
      <b-badge
        v-if="data.item.finalized_at"
        class="mr-2"
        variant="success"
      >
        Finalized
      </b-badge>
      <b-badge
        v-else
        class="mr-2"
        variant="secondary"
      >
        Open
      </b-badge>
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import translationMixin from '@/mixins/translationMixin';
import { INVOICE_TABLE_DEFAULT_COLUMNS, INVOICE_MODEL_NAME } from '@/constants/invoices';

export default {
  name: 'InvoicesTable',
  mixins: [tablePropsMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: () => INVOICE_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      modelName: INVOICE_MODEL_NAME,
    };
  },
};
</script>
