<template>
  <sfs-base-table
    :data-provider="$servicesAPI.getServices"
    provider-key="services"
    :columns="translatedColumns(modelName, columns)"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(name)="data">
      <b-link :to="{ name: 'service', params: { id: data.item.id } }">
        {{ data.item.name }}
      </b-link>
    </template>
    <template #cell(category)="data">
      <b-badge variant="secondary">
        {{ data.item.category | humanizedFormat | capitalizeFormat }}
      </b-badge>
    </template>
    <template #cell(client_id)="data">
      <b-link
        v-if="data.item.client"
        :to="{ name: 'client', params: { id: data.item.client.id } }"
      >
        {{ data.item.client.name }}
      </b-link>
    </template>
    <template #cell(minimum_days_between_service)="data">
      <span v-if="data.item.minimum_days_between_service">{{ minimumDaysBetweenService(data.item.minimum_days_between_service) }}</span>
    </template>
    <template #cell(technician_payment_gl_code_id)="data">
      <span v-if="data.item.technician_payment_gl_code_id">{{ data.item.technician_payment_gl_code.name }}</span>
    </template>
    <template #cell(invoice_gl_code_id)="data">
      <span v-if="data.item.invoice_gl_code_id">{{ data.item.invoice_gl_code.name }}</span>
    </template>
    <template #cell(add_on)="data">
      {{ data.item.add_on ? "Yes" : "No" }}
    </template>
  </sfs-base-table>
</template>

<script>
import pluralize from 'pluralize';

import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';
import translationMixin from '@/mixins/translationMixin';

import { SERVICE_TABLE_DEFAULT_COLUMNS, SERVICE_MODEL_NAME } from '@/constants/services';

export default {
  name: 'ServicesTable',
  mixins: [urlMixin, tableMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: () => SERVICE_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      modelName: SERVICE_MODEL_NAME,
    };
  },
  methods: {
    minimumDaysBetweenService(minimum) {
      return `${minimum} ${pluralize('Day', minimum)}`;
    },
  },
};
</script>
