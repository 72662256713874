import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const VENDOR_SUPPLY_TABLE_NAME = 'vendorSupplies';
export const CLIENT_SUPPLY_TABLE_NAME = 'clientSupplies';


// Vendor label constants
export const SUPPLY_ITEM_NUMBER_LABEL = 'Item Number';
export const SUPPLY_DESCRIPTION_LABEL = 'Description';
export const SUPPLY_CLIENT_DESCRIPTION_LABEL = 'Client Description';
export const SUPPLY_CATEGORY_LABEL = 'Category';
export const SUPPLY_UNITS_LABEL = 'Units';
export const SUPPLY_COST_LABEL = 'Unit Cost';
export const SUPPLY_PRICE_LABEL = 'Unit Price';
export const SUPPLY_CLIENT_ID_LABEL = 'Client';
export const SUPPLY_VENDOR_ID_LABEL = 'Vendor';

export const SUPPLIES_FORM_LABELS = {
  item_number: SUPPLY_ITEM_NUMBER_LABEL,
  description: SUPPLY_DESCRIPTION_LABEL,
  client_description: SUPPLY_CLIENT_DESCRIPTION_LABEL,
  category: SUPPLY_CATEGORY_LABEL,
  units: SUPPLY_UNITS_LABEL,
  cost: SUPPLY_COST_LABEL,
  price: SUPPLY_PRICE_LABEL,
  client_id: SUPPLY_CLIENT_ID_LABEL,
  vendor_id: SUPPLY_VENDOR_ID_LABEL,
};

// The columns available to be shown in a supplies table.
export const SUPPLY_TABLE_COLUMNS = {
  item_number: { label: SUPPLY_ITEM_NUMBER_LABEL, key: 'item_number', sortable: true },
  description: { label: SUPPLY_DESCRIPTION_LABEL, key: 'description' },
  client_description: { label: SUPPLY_CLIENT_DESCRIPTION_LABEL, key: 'client_description' },
  units: { label: SUPPLY_UNITS_LABEL, key: 'units', sortable: true },
  category: { label: SUPPLY_CATEGORY_LABEL, key: 'category' },
  cost: { label: SUPPLY_COST_LABEL, key: 'cost', sortable: true },
  price: { label: SUPPLY_PRICE_LABEL, key: 'price' },
  client_id: { label: SUPPLY_CLIENT_ID_LABEL, key: 'client_id' },
  vendor_id: { label: SUPPLY_VENDOR_ID_LABEL, key: 'vendor_id' },
  deleted_at: { label: 'Active', key: 'active' },
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const SUPPLY_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(SUPPLY_TABLE_COLUMNS);

// The default columns to be shown when the supplies table loads.
export const VENDOR_SUPPLY_TABLE_DEFAULT_COLUMNS = [
  SUPPLY_TABLE_COLUMNS.item_number,
  SUPPLY_TABLE_COLUMNS.client_description,
  SUPPLY_TABLE_COLUMNS.category,
  SUPPLY_TABLE_COLUMNS.cost,
  SUPPLY_TABLE_COLUMNS.price,
  SUPPLY_TABLE_COLUMNS.actions,
];

export const CLIENT_SUPPLY_TABLE_DEFAULT_COLUMNS = [
  SUPPLY_TABLE_COLUMNS.item_number,
  SUPPLY_TABLE_COLUMNS.client_description,
  SUPPLY_TABLE_COLUMNS.category,
  SUPPLY_TABLE_COLUMNS.cost,
  SUPPLY_TABLE_COLUMNS.price,
  SUPPLY_TABLE_COLUMNS.vendor_id,
  SUPPLY_TABLE_COLUMNS.actions,
];
