import List from '@/pages/technician/technicianPayments/List.vue';
import Show from '@/pages/technician/technicianPayments/Show.vue';
import handlePaginationRouteProps from '@/utils/handlePaginationRouteProps.js';

export default [
  {
    path: 'payments',
    name: 'technician-user-payments',
    component: List,
    meta: { requiresAuth: true },
    props: handlePaginationRouteProps,
  },
  {
    path: 'payments/:id(\\d+)',
    name: 'technician-user-payment',
    component: Show,
    meta: { requiresAuth: true },
  },
];
