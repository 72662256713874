<template>
  <sfs-form-modal
    :id="modalId"
    title="Add an Insurance Certificate"
    size="lg"
    @on-hide="resetFormData"
  >
    <insurance-certificate-form
      @on-submit="create"
      @on-save="redirectToCertificate"
      @on-cancel="closeModal"
      :key="formKey"
      :reminder_days="0"
      :reminder-frequency-options="reminderFrequencyOptions"
      :category-options="categoryOptions"
    />
  </sfs-form-modal>
</template>

<script>
import InsuranceCertificateForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'InsuranceCertificateAddModal',
  mixins: [formModalMixin],
  components: {
    InsuranceCertificateForm,
  },
  props: {
    locationId: Number,
  },
  data() {
    return {
      reminderFrequencyOptions: [],
      categoryOptions: [],
      modalId: 'insurance-certificate-add-modal',
    };
  },
  mounted() {
    this.getReminderFrequencyOptions();
    this.getCategoryOptions();
  },
  methods: {
    getReminderFrequencyOptions() {
      this.$insuranceCertificatesAPI.getReminderFrequencyOptions().then((response) => {
        this.reminderFrequencyOptions = response.data.reminder_frequency_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
      });
    },
    getCategoryOptions() {
      this.$store.cache.dispatch('getInsuranceCertificateCategories').then((options) => {
        this.categoryOptions = options.map((option) => {
          return { label: option[0], value: option[1] };
        });
      });
    },
    create(data, handleCreate) {
      handleCreate(this.$insuranceCertificatesAPI.create(data.user_id, data));
    },
    redirectToCertificate(insuranceCertificate) {
      this.$router.push({ name: 'insurance-certificate', params: { id: insuranceCertificate.id } });
    },
  },
};
</script>
