/**
 * Returns a capitalized version of the provided words String.
 * @param {words} String The text to capitalize.
 */
export default function (words) {
  return words
    .split(' ')
    .map((part, i) => {
      if (i == 0) {
        return part.charAt(0).toUpperCase() + part.slice(1);
      } else {
        return part;
      }
    })
    .join(' ');
}
