import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '../helpers/apiPath';

export default {
  getOrganizations(params) {
    return axios.get(adminPath('/organizations?') + prepareQueryString(params)).then((response) => {
      return {
        organizations: response.data.map((user) => an('Organization', user)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getOrganizationTerminology(id) {
    return axios.get(adminPath(`/organizations/${id}/translations`));
  }
};
