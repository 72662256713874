<template>
  <bar-chart
    :title="title"
    :stacked="true"
    :share-tooltip="true"
    y-axis-title="Total"
    :chart-height="height"
    :skeleton-height="height"
    :series="chartData"
    :fill-opacity="1"
    :colors="chartColours"
    :y-axis-label-formatter="(val) => $options.filters.moneyFormatFilter(val, 'compact')"
    :data-function="loadChart"
    :data-parameters="{
      filters: {
        clientId,
        datePeriod,
      },
      groupPeriod: groupPeriod,
    }"
  />
</template>
<script>
import { DateTime } from 'luxon';
import calculateDateIntervals from '@/utils/calculateDateIntervals';
import { mapTimeSeriesData } from '@/utils/apexchartsSeriesMapping';
import BarChart from '@/components/shared/apexcharts/BarChart.vue';
import { CHART_COLOUR_PRIMARY_LIGHT, CHART_COLOUR_PRIMARY } from '@/constants';
import {
  WORK_ORDER_STATUS_COMPLETED,
  WORK_ORDER_STATUS_INVOICED,
} from '@/constants/workOrders';

export default {
  name: 'WorkOrderRevenueChart',
  components: {
    BarChart,
  },
  props: {
    clientId: {
      type: Number,
    },
    height: {
      type: String,
    },
    datePeriod: {
      type: Array,
    },
    groupPeriod: {
      type: String,
    },
  },
  data() {
    return {
      profitSeries: { data: [] },
      costSeries: { data: [] },
      chartColours: [CHART_COLOUR_PRIMARY_LIGHT, CHART_COLOUR_PRIMARY],
    };
  },
  methods: {
    getProfitSeries() {
      return this.$analyticsAPI
        .getWorkOrdersSum({
          client_id: this.clientId,
          statuses: [WORK_ORDER_STATUS_COMPLETED, WORK_ORDER_STATUS_INVOICED],
          checked_out_from: this.datePeriod[0],
          checked_out_to: this.datePeriod[1],
          group_period: this.groupPeriod,
          group_by: 'checked_out_at',
          sum_by: 'profit',
        })
        .then((response) => {
          this.profitSeries = {
            name: 'Gross Profit',
            data: mapTimeSeriesData(response.series, this.intervals),
          };
        })
        .catch(() => {
          this.profitSeries = { data: [] };
        });
    },
    getCostSeries() {
      return this.$analyticsAPI
        .getWorkOrdersSum({
          client_id: this.clientId,
          statuses: [WORK_ORDER_STATUS_COMPLETED, WORK_ORDER_STATUS_INVOICED],
          checked_out_from: this.datePeriod[0],
          checked_out_to: this.datePeriod[1],
          group_period: this.groupPeriod,
          group_by: 'checked_out_at',
          sum_by: 'cost',
        })
        .then((response) => {
          this.costSeries = {
            name: 'Cost',
            data: mapTimeSeriesData(response.series, this.intervals),
          };
        })
        .catch(() => {
          this.costSeries = { data: [] };
        });
    },
  },
  computed: {
    chartData() {
      if (this.$can('read_price', 'WorkOrderCharge')) {
        return [this.costSeries, this.profitSeries];
      } else {
        return [this.costSeries];
      }
    },
    loadChart() {
      return this.$can('read_price', 'WorkOrderCharge') ? [this.getCostSeries, this.getProfitSeries] : [this.getCostSeries];
    },
    title() {
      return this.$can('read_price', 'WorkOrderCharge') ? 'Work Order Revenue' : 'Work Order Cost';
    },
    intervals() {
      if (this.datePeriod[0] && this.datePeriod[1]) {
        return calculateDateIntervals(DateTime.fromISO(this.datePeriod[0]), DateTime.fromISO(this.datePeriod[1]), this.groupPeriod);
      } else {
        return undefined;
      }
    }
  },
};
</script>
