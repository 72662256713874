import { PAGINATION_PER_PAGE, PAGINATION_START_PAGE } from '@/constants';

/**
 * This function returns a set of props containing all of the query params from a route.
 * If the page and per param are not included in the query, it will use a set of defaults,
 * as defined in `@/constants`.
 * @param {object} route the vue-router route to fetch the pagination query props from.
 */
export default function handlePaginationRouteProps(route) {
  if (!Array.isArray(route.query.statuses) && route.query.statuses && route.query.statuses.length > 0) {
    route.query.statuses = [route.query.statuses];
  }
  if (!Array.isArray(route.query.completion_sources) && route.query.completion_sources && route.query.completion_sources.length > 0) {
    route.query.completion_sources = [route.query.completion_sources];
  }
  if (!Array.isArray(route.query.services_provided) && route.query.services_provided && route.query.services_provided.length > 0) {
    route.query.services_provided = [route.query.services_provided];
  }
  if (!Array.isArray(route.query.cancellation_reasons) && route.query.cancellation_reasons && route.query.cancellation_reasons.length > 0) {
    route.query.cancellation_reasons = [route.query.cancellation_reasons];
  }
  if (!Array.isArray(route.query.role_ids) && route.query.role_ids && route.query.role_ids.length > 0) {
    route.query.role_ids = [Number.parseInt(route.query.role_ids)];
  }
  if (Array.isArray(route.query.role_ids)) {
    route.query.role_ids = route.query.role_ids.map((roleId) => Number.parseInt(roleId));
  }
  return {
    ...route.query,
    sortDesc: route.query.sortDesc ? route.query.sortDesc === 'true' : true,
    currentPage: Number.parseInt(route.query.currentPage, 10) || PAGINATION_START_PAGE,
    perPage: Number.parseInt(route.query.perPage, 10) || PAGINATION_PER_PAGE,
  };
}
