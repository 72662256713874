export const TECHNICIAN_SERVICES_TABLE_NAME = 'technicianServices';

// The columns available to be shown in a technician services table.
export const TECHNICIAN_SERVICES_TABLE_COLUMNS = {
  cost: { label: 'Cost', key: 'cost' },
  location_id: { label: 'Location', key: 'location_id' },
  service_id: { label: 'Service', key: 'service_id' },
  assigned_to_id: { label: 'Assigned Technician', key: 'assigned_to_id' },
  done_by_id: { label: 'Completed By Technician', key: 'done_by_id' },
  actions: { label: 'Actions', key: 'actions' },
};

// The default columns to be shown when the technician services table loads.
export const TECHNICIAN_SERVICES_TABLE_DEFAULT_COLUMNS = [
  TECHNICIAN_SERVICES_TABLE_COLUMNS.service_id,
  TECHNICIAN_SERVICES_TABLE_COLUMNS.assigned_to_id,
  TECHNICIAN_SERVICES_TABLE_COLUMNS.done_by_id,
  TECHNICIAN_SERVICES_TABLE_COLUMNS.cost,
  TECHNICIAN_SERVICES_TABLE_COLUMNS.actions,
];
