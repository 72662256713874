<template>
  <sfs-form-modal
    :id="modalId"
    title="Import Recurring Work Orders"
    @on-hide="resetFormData"
  >
    <imports-form
      @on-submit="createRecurringWorkOrdersImport"
      @on-save="handleSave"
      @on-cancel="closeModal"
      :key="formKey"
    >
      <template #downloads>
        <b-row>
          <b-col cols="12">
            <sfs-download-button
              label="Download Template"
              filename="Recurring Work Orders Template.xlsx"
              :file-provider="$importsAPI.getRecurringWorkOrdersTemplate"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <h3 class="mt-3 mb-2">
              Schedule Code Mappings
            </h3>
            <b-table
              class="mb-0"
              :items="frequencyOptions"
              :fields="['code', 'description']"
              responsive
              table
            >
              <template #cell(code)="data">
                <code>{{ data.item.code }}</code>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </template>
    </imports-form>
  </sfs-form-modal>
</template>

<script>
import ImportsForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'RecurringWorkOrdersImportsAddModal',
  mixins: [formModalMixin],
  components: {
    ImportsForm,
  },
  data() {
    return {
      frequencyOptions: [],
      modalId: 'recurring-work-orders-imports-add-modal',
    };
  },
  mounted() {
    this.getFrequencyOptions();
  },
  methods: {
    getFrequencyOptions() {
      this.$recurringWorkOrdersAPI.getFrequencyOptions().then((response) => {
        this.frequencyOptions = response.data.frequency_options.map((option) => {
          return { description: option[0], code: option[1] };
        });
      });
    },
    createRecurringWorkOrdersImport(data, handleCreate) {
      handleCreate(this.$importsAPI.recurringWorkOrdersCreate(data));
    },
    handleSave(data) {
      if (this.$route.name != 'imports') {
        this.$router.push({ name: 'imports' });
      } else {
        this.closeModal();
      }
      this.$emit('on-save', data);
    },
  },
};
</script>
