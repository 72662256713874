import { keyBy as _keyBy, values as _values } from 'lodash';

export default {
  computed: {
    translatedColumns() {
      return (model, availableColumns) => { 
        const columns = _keyBy(availableColumns, 'key');

        Object.keys(columns).forEach((key) => {
          const column = columns[key];
          columns[key].label = this.$store.getters.translate(`${model}.${column.key}`, `${column.label}`);
        });

        return _values(columns);
      };
    },
    clientTranslation() {
      return this.$store.getters.translate('client', 'Client');
    }
  },
};
