<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.name"
          label-for="vendor-name"
        >
          <b-form-input
            id="vendor-name"
            v-model="$v.form.name.$model"
            :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.name.$dirty"
            id="vendor-name-feedback"
          >
            <span v-if="!$v.form.name.required">Please enter a name.</span>
            <span v-if="!$v.form.name.maxLength">
              The name must be less than {{ $v.form.name.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.name.serverFailed">{{ serverErrors.name }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.organization_id"
          label-for="vendor-organization_id"
        >
          <organization-select
            id="vendor-organization_id"
            :select-class="{ 'is-invalid': $v.form.organization_id.$dirty && $v.form.organization_id.$error }"
            :value="form.organization"
            @input="onOrganizationChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.organization_id.$dirty"
            id="vendor-organization_id-feedback"
          >
            <span v-if="!$v.form.organization_id.required">Please enter an organization.</span>
            <span v-if="!$v.form.organization_id.serverFailed">
              {{ serverErrors.organization }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.address"
          label-for="vendor-address"
        >
          <b-form-input
            id="vendor-address"
            v-model="$v.form.address.$model"
            :state="$v.form.address.$dirty ? !$v.form.address.$error : null"
            type="text"
            placeholder="123 Fake address"
          />
          <b-form-invalid-feedback
            v-if="$v.form.address.$dirty"
            id="vendor-address-feedback"
          >
            <span v-if="!$v.form.address.required">Please enter an address.</span>
            <span v-if="!$v.form.address.maxLength">
              The address must be less than {{ $v.form.address.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.address.serverFailed">{{ serverErrors.address }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.city"
          label-for="vendor-city"
        >
          <b-form-input
            id="vendor-city"
            v-model="$v.form.city.$model"
            :state="$v.form.city.$dirty ? !$v.form.city.$error : null"
            type="text"
            placeholder="New York"
          />
          <b-form-invalid-feedback
            v-if="$v.form.city.$dirty"
            id="vendor-city-feedback"
          >
            <span v-if="!$v.form.city.required">Please enter a city.</span>
            <span v-if="!$v.form.city.maxLength">
              The city must be less than {{ $v.form.city.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.city.serverFailed">{{ serverErrors.city }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.state"
          label-for="vendor-state"
        >
          <b-form-input
            id="vendor-state"
            v-model="$v.form.state.$model"
            :state="$v.form.state.$dirty ? !$v.form.state.$error : null"
            type="text"
            placeholder="NY"
          />
          <b-form-invalid-feedback
            v-if="$v.form.state.$dirty"
            id="vendor-state-feedback"
          >
            <span v-if="!$v.form.state.required">Please enter a state.</span>
            <span v-if="!$v.form.state.maxLength">
              The state must be less than {{ $v.form.state.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.state.serverFailed">{{ serverErrors.state }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.zip_code"
          label-for="vendor-zip_code"
        >
          <b-form-input
            id="vendor-zip_code"
            v-model="$v.form.zip_code.$model"
            :state="$v.form.zip_code.$dirty ? !$v.form.zip_code.$error : null"
            type="text"
            placeholder="55555"
          />
          <b-form-invalid-feedback
            v-if="$v.form.zip_code.$dirty"
            id="vendor-zip_code-feedback"
          >
            <span v-if="!$v.form.zip_code.required">Please enter a zip code.</span>
            <span v-if="!$v.form.zip_code.maxLength">
              The zip code must be less than {{ $v.form.zip_code.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.zip_code.serverFailed">{{ serverErrors.zip_code }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.account_number"
          label-for="vendor-account_number"
        >
          <b-form-input
            id="vendor-account_number"
            v-model="$v.form.account_number.$model"
            :state="$v.form.account_number.$dirty ? !$v.form.account_number.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.account_number.$dirty"
            id="vendor-account_number-feedback"
          >
            <span v-if="!$v.form.account_number.maxLength">
              The account number must be less than {{ $v.form.account_number.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.account_number.serverFailed">{{ serverErrors.account_number }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.attention_to"
          label-for="vendor-attention_to"
        >
          <b-form-input
            id="vendor-attention_to"
            v-model="$v.form.attention_to.$model"
            :state="$v.form.attention_to.$dirty ? !$v.form.attention_to.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.attention_to.$dirty"
            id="vendor-attention_to-feedback"
          >
            <span v-if="!$v.form.attention_to.maxLength">
              The attention to must be less than {{ $v.form.attention_to.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.attention_to.serverFailed">{{ serverErrors.attention_to }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label-for="vendor-tax_exempt">
          <b-form-checkbox
            switch
            id="vendor-tax_exempt"
            v-model="$v.form.tax_exempt.$model"
          >
            {{ fieldLabels.tax_exempt }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.freight_invoice_gl_code_id"
          label-for="vendor-freight_invoice_gl_code_id"
        >
          <gl-code-select
            id="vendor-freight_invoice_gl_code_id"
            :select-class="{ 'is-invalid': $v.form.freight_invoice_gl_code_id.$dirty && $v.form.freight_invoice_gl_code_id.$error }"
            client-unassigned
            :value="form.freight_invoice_gl_code"
            @input="onFreightInvoiceGlCodeChange"
            :filter-params="invoiceGlCodeFilters"
          />
          <b-form-invalid-feedback
            v-if="$v.form.freight_invoice_gl_code_id.$dirty"
            id="vendor-freight_invoice_gl_code_id-feedback"
          >
            <span v-if="!$v.form.freight_invoice_gl_code_id.serverFailed">
              {{ serverErrors.invoice_gl_code }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.labor_invoice_gl_code_id"
          label-for="vendor-labor_invoice_gl_code_id"
        >
          <gl-code-select
            id="vendor-labor_invoice_gl_code_id"
            :select-class="{ 'is-invalid': $v.form.labor_invoice_gl_code_id.$dirty && $v.form.labor_invoice_gl_code_id.$error }"
            client-unassigned
            :value="form.labor_invoice_gl_code"
            @input="onLaborInvoiceGlCodeChange"
            :filter-params="invoiceGlCodeFilters"
          />
          <b-form-invalid-feedback
            v-if="$v.form.labor_invoice_gl_code_id.$dirty"
            id="vendor-labor_invoice_gl_code_id-feedback"
          >
            <span v-if="!$v.form.labor_invoice_gl_code_id.serverFailed">
              {{ serverErrors.invoice_gl_code }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          id="vendor-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          id="vendor-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import OrganizationSelect from '@/components/admin/organizations/Select.vue';
import GlCodeSelect from '@/components/admin/glCodes/Select.vue';
import { GL_CODE_INVOICE_CATEGORY } from '@/constants/glCodes';
import { VENDORS_FORM_LABELS } from '@/constants/vendors.js';

export default {
  name: 'VendorsForm',
  mixins: [formMixin],
  components: {
    OrganizationSelect,
    GlCodeSelect,
  },
  props: {
    organization_id: [String, Number],
    name: String,
    address: String,
    city: String,
    state: String,
    zip_code: String,
    account_number: String,
    attention_to: String,
    organization: Object,
    tax_exempt: Boolean,
    freight_invoice_gl_code_id: [String, Number],
    freight_invoice_gl_code: Object,
    labor_invoice_gl_code_id: [String, Number],
    labor_invoice_gl_code: Object,
  },
  data() {
    return {
      form: {
        organization_id: this.organization_id,
        name: this.name,
        address: this.address,
        city: this.city,
        state: this.state,
        zip_code: this.zip_code,
        account_number: this.account_number,
        attention_to: this.attention_to,
        organization: this.organization,
        tax_exempt: this.tax_exempt,
        freight_invoice_gl_code_id: this.freight_invoice_gl_code_id,
        freight_invoice_gl_code: this.freight_invoice_gl_code,
        labor_invoice_gl_code_id: this.labor_invoice_gl_code_id,
        labor_invoice_gl_code: this.labor_invoice_gl_code,
      },
      invoiceGlCodeFilters: { client_id: this.id, category: GL_CODE_INVOICE_CATEGORY, active: true },
      fieldLabels: {
        ...VENDORS_FORM_LABELS,
        name: 'Name *',
        address: 'Address *',
        city: 'City *',
        state: 'State *',
        zip_code: 'Zip Code *',
        tax_exempt: 'Vendor is exempt from charging taxes',
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('name');
        },
      },

      address: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('address');
        },
      },
      city: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('city');
        },
      },
      state: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('state');
        },
      },
      zip_code: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('zip_code');
        },
      },
      account_number: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('account_number');
        },
      },
      attention_to: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('attention_to');
        },
      },
      organization_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('organization');
        },
      },
      freight_invoice_gl_code_id: {
        serverFailed() {
          return !this.hasServerErrors('freight_invoice_gl_code');
        },
      },
      labor_invoice_gl_code_id: {
        serverFailed() {
          return !this.hasServerErrors('labor_invoice_gl_code');
        },
      },
      tax_exempt: {},
    },
  },
  methods: {
    onOrganizationChange(organization) {
      this.form.organization_id = organization ? organization.id : '';
      this.form.organization = organization;
    },
    onFreightInvoiceGlCodeChange(glCode) {
      this.form.freight_invoice_gl_code_id = glCode ? glCode.id : '';
      this.form.freight_invoice_gl_code = glCode;
    },
    onLaborInvoiceGlCodeChange(glCode) {
      this.form.labor_invoice_gl_code_id = glCode ? glCode.id : '';
      this.form.labor_invoice_gl_code = glCode;
    },
  },
};
</script>
