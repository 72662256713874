<template>
  <header id="page-header">
    <div
      v-if="title || $slots.title || $slots.extra"
      class="content content-header py-3"
    >
      <div
        v-if="title || $slots.title"
        class="d-flex align-items-center"
      >
        <h3
          v-if="showBackButton"
          class="flex-sm-fill my-0"
        >
          <b-button
            @click="() => $router.go(-1)"
            class="font-w700 font-size-lg"
            variant="link"
          >
            <i class="fa fa-arrow-left" />
          </b-button>
        </h3>
        <h1
          v-if="title"
          class="flex-sm-fill h3 my-0 text-dark font-w600"
        >
          {{ title }}
        </h1>
        <slot name="title" />
        <div
          v-if="$slots['secondary-title']"
          class="ml-3 d-flex align-items-center"
        >
          <slot name="secondary-title" />
        </div>
      </div>
      <div
        v-if="$slots.extra"
        class="d-flex align-items-center"
      >
        <slot name="extra" />
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: 'BasePageHeading',
  props: {
    title: {
      type: String,
      description: 'The title of page heading section',
    },
    subtitle: {
      type: String,
      description: 'The subtitle of page heading section',
    },
    showBackButton: {
      type: Boolean,
      description: 'Whether or not to show a back button before the page title',
      default: false,
    }
  },
};
</script>
