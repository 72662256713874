import { DateTime } from 'luxon';
import dateFormatFilter from  './dateFormatFilter';

export default function (isoDate) {
  const date = DateTime.fromISO(isoDate);
  const today = DateTime.local();
  if (date.toISODate() === today.toISODate()) {
    return DateTime.fromISO(isoDate).toLocaleString(DateTime.TIME_SIMPLE);
  } else if (today.year === date.year) {
    return date.toFormat('LLL d');
  } else {
    return dateFormatFilter(isoDate);
  }
}
