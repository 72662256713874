<template>
  <b-form
    @submit.stop.prevent="handleSubmit"
    class="text-left"
  >
    <b-form-group
      :label="fieldLabels.description"
      label-for="technician-payment-item-description"
    >
      <b-form-textarea
        name="technician-payment-item-description"
        v-model="$v.form.description.$model"
        :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
        rows="4"
      />
      <b-form-invalid-feedback
        v-if="$v.form.description.$dirty"
        class="technician-payment-item-description-feedback"
      >
        <span v-if="!$v.form.description.required">Please enter a description.</span>
        <span v-if="!$v.form.description.serverFailed">{{ serverErrors.description }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.amount"
      label-for="technician-payment-item-amount"
    >
      <b-input-group prepend="$">
        <b-form-input
          name="technician-payment-item-amount"
          v-model="$v.form.amount.$model"
          :state="$v.form.amount.$dirty ? !$v.form.amount.$error : null"
          type="text"
        />
      </b-input-group>
      <b-form-invalid-feedback
        v-if="$v.form.amount.$dirty"
        :state="$v.form.amount.$dirty ? !$v.form.amount.$error : null"
        class="technician-payment-item-amount-feedback"
      >
        <span v-if="!$v.form.amount.required">Please enter an amount.</span>
        <span v-if="!$v.form.amount.decimal">Please enter a number.</span>
        <span v-if="!$v.form.amount.serverFailed">
          {{ serverErrors.amount_cents }}
        </span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.gl_code_id"
      label-for="technician-payment-item-gl_code_id"
    >
      <gl-code-select
        name="technician-payment-item-gl_code_id"
        :select-class="{ 'is-invalid': $v.form.gl_code_id.$dirty && $v.form.gl_code_id.$error }"
        :value="form.gl_code"
        @input="onGlCodeChange"
        :filter-params="technicianPaymentGlCodeFilters"
      />
      <b-form-invalid-feedback
        v-if="$v.form.gl_code_id.$dirty"
        class="technician-payment-item-gl_code_id-feedback"
      >
        <span v-if="!$v.form.gl_code_id.required">Please enter a GL Code.</span>
        <span v-if="!$v.form.gl_code_id.serverFailed">
          {{ serverErrors.gl_code }}
        </span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.netsuite_expense_class_id"
      label-for="technician-payment-item-netsuite_expense_class_id"
    >
      <net-suite-record-select
        name="gl-code-netsuite_expense_class_id"
        :value="form.netsuite_expense_class_id"
        @input="onExpenseClassificationChange"
        reference-type="Service"
        :select-class="{ 'is-invalid': $v.form.netsuite_expense_class_id.$dirty && $v.form.netsuite_expense_class_id.$error }"
      />
      <b-form-invalid-feedback
        v-if="$v.form.netsuite_expense_class_id.$dirty"
        class="technician-payment-item-netsuite_expense_class_id-feedback"
      >
        <span v-if="!$v.form.netsuite_expense_class_id.required">Please enter an expense class.</span>
        <span v-if="!$v.form.netsuite_expense_class_id.serverFailed">{{ serverErrors.netsuite_expense_class_id }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="technician-payment-item-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="technician-payment-item-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, decimal, requiredIf } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import GlCodeSelect from '@/components/admin/glCodes/Select.vue';
import { GL_CODE_TECHNICIAN_PAYMENT_CATEGORY } from '@/constants/glCodes';
import NetSuiteRecordSelect from '@/components/admin/integrationReferences/NetSuiteRecordSelect.vue';

export default {
  name: 'TechnicianPaymentItemsForm',
  components: {
    GlCodeSelect,
    NetSuiteRecordSelect,
  },
  mixins: [formMixin],
  props: {
    description: String,
    netsuite_expense_class_id: [String, Number],
    amount: Object,
    gl_code_id: [String, Number],
    gl_code: Object,
    newForm: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        description: this.description,
        netsuite_expense_class_id: this.netsuite_expense_class_id,
        amount: this.amount && this.amount.amount ? this.amount.amount / 100 : null,
        amount_cents: this.amount && this.amount.amount ? this.amount.amount : null,
        gl_code_id: this.gl_code_id,
        gl_code: this.gl_code,
      },
      fieldLabels: {
        description: 'Description',
        netsuite_expense_class_id: 'Expense Class',
        amount: 'Amount',
        gl_code_id: 'GL Code',
      },
      technicianPaymentGlCodeFilters: { category: GL_CODE_TECHNICIAN_PAYMENT_CATEGORY, active: true },
    };
  },
  validations: {
    form: {
      description: {
        required,
        serverFailed() {
          return !this.hasServerErrors('description');
        },
      },
      netsuite_expense_class_id: {
        required: requiredIf(function () {
          return this.newForm;
        }),
        serverFailed() {
          return !this.hasServerErrors('netsuite_expense_class_id');
        },
      },
      amount: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('amount_cents');
        },
      },
      gl_code_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('gl_code');
        },
      },
    },
  },
  watch: {
    'form.amount': function (newAmount) {
      this.form.amount_cents = isNaN(newAmount) ? null : Math.round(newAmount * 100);
    },
  },
  methods: {
    onGlCodeChange(glCode) {
      this.form.gl_code_id = glCode ? glCode.id : '';
      this.form.gl_code = glCode;
      this.form.netsuite_expense_class_id = glCode?.netsuite_expense_class_id;
    },
    onExpenseClassificationChange(netsuiteClass) {
      this.form.netsuite_expense_class_id = netsuiteClass.id;
    },
  },
};
</script>
