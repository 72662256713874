import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '../helpers/apiPath';

export default {
  getClientsContactInfos(clientId, params) {
    return axios.get(adminPath(`/clients/${clientId}/contact_infos?`) + prepareQueryString(params)).then((response) => {
      return {
        contactInfos: response.data.map((contactInfo) => an('ContactInfo', contactInfo)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  createClientsContactInfos(clientId, data) {
    return axios
      .post(adminPath(`/clients/${clientId}/contact_infos`), {
        ...data,
      })
      .then((response) => an('ContactInfo', response.data));
  },
  getLocationsContactInfos(locationId, params) {
    return axios.get(adminPath(`/locations/${locationId}/contact_infos?`) + prepareQueryString(params)).then((response) => {
      return {
        contactInfos: response.data.map((contactInfo) => an('ContactInfo', contactInfo)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  createLocationsContactInfos(locationId, data) {
    return axios
      .post(adminPath(`/locations/${locationId}/contact_infos`), {
        ...data,
      })
      .then((response) => an('ContactInfo', response.data));
  },
  getVendorsContactInfos(vendorId, params) {
    return axios.get(adminPath(`/vendors/${vendorId}/contact_infos?`) + prepareQueryString(params)).then((response) => {
      return {
        contactInfos: response.data.map((contactInfo) => an('ContactInfo', contactInfo)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  createVendorsContactInfos(vendorId, data) {
    return axios
      .post(adminPath(`/vendors/${vendorId}/contact_infos`), {
        ...data,
      })
      .then((response) => an('ContactInfo', response.data));
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/contact_infos/${id}`), {
        ...data,
      })
      .then((response) => an('ContactInfo', response.data));
  },
  getCategoryOptions() {
    return axios.get(adminPath('/contact_infos/category_options'));
  },
};
