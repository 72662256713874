<template>
  <div>
    <b-row>
      <b-col
        cols="auto"
        class="mr-auto text-prewrap"
      >
        {{ value.content }}
      </b-col>
      <b-col cols="auto">
        <b-dropdown
          variant="link"
          size="sm"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>
          <b-dropdown-item
            v-if="$can('update', referenceType)"
            v-b-modal="`contact-info-${value.id}-edit-modal`"
          >
            <i class="fa fa-fw fa-pencil-alt" />
            Edit
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$can('update', referenceType)"
            @click="destroyContactInfoDialog(value.id)"
          >
            <i class="fa fa-fw fa-times" /> Delete
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-badge
          class="font-size-sm"
          variant="secondary"
        >
          {{ value.category | humanizedFormat | capitalizeFormat }}
        </b-badge>
      </b-col>
    </b-row>
    <edit-modal
      v-if="$can('update', referenceType)"
      :value="value"
      @on-save="(updatedContactInfo) => $emit('on-save', updatedContactInfo)"
    />
  </div>
</template>

<script>
import EditModal from '@/components/admin/contactInfos/EditModal.vue';
import { DateTime } from 'luxon';

export default {
  name: 'ContactInfosCardItem',
  components: {
    EditModal,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    referenceType: {
      type: String,
      required: true,
    },
  },
  methods: {
    destroyContactInfoDialog(id) {
      this.$swal({
        text: 'Once it\'s deleted, its gone forever!',
        preConfirm: () => this.$contactInfosAPI.update(id, { deleted_at: DateTime.now().toISODate() }),
      }).then((result) => {
        if (result.value) {
          this.$emit('on-destroy');
        }
        result.timer;
      });
    },
  },
};
</script>
