import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const USER_OWNERSHIPS_TABLE_NAME = 'userOwnerships';

// The columns available to be shown in a user ownerships table.
export const USER_OWNERSHIPS_TABLE_COLUMNS = {
  technician_id: { label: 'Technician', key: 'technician_id' },
  sub_technician_id: { label: 'Sub Technician', key: 'sub_technician_id' },
  sub_technician_name: { label: 'Name', key: 'sub_technician_name' },
  sub_technician_phone: { label: 'Phone', key: 'sub_technician_phone' },
  sub_technician_email: { label: 'Email', key: 'sub_technician_email' },
  sub_technician_active: { label: 'Status', key: 'sub_technician_active' },
  technician_name: { label: 'Manager', key: 'technician_name' },
  technician_phone: { label: 'Phone', key: 'technician_phone' },
  technician_email: { label: 'Email', key: 'technician_email' },
  technician_active: { label: 'Status', key: 'technician_active' },
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const USER_OWNERSHIPS_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(USER_OWNERSHIPS_TABLE_COLUMNS);

// The default columns to be shown when the user ownerships table loads.
export const USER_OWNERSHIPS_TABLE_DEFAULT_COLUMNS = [
  USER_OWNERSHIPS_TABLE_COLUMNS.sub_technician_name,
  USER_OWNERSHIPS_TABLE_COLUMNS.sub_technician_email,
  USER_OWNERSHIPS_TABLE_COLUMNS.sub_technician_phone,
  USER_OWNERSHIPS_TABLE_COLUMNS.sub_technician_active,
  USER_OWNERSHIPS_TABLE_COLUMNS.actions,
];

// The default columns to be shown when the user ownerships table loads.
export const USER_OWNERSHIPS_TABLE_DEFAULT_SUB_TECHNICIAN_COLUMNS = [
  USER_OWNERSHIPS_TABLE_COLUMNS.technician_name,
  USER_OWNERSHIPS_TABLE_COLUMNS.technician_email,
  USER_OWNERSHIPS_TABLE_COLUMNS.technician_phone,
  USER_OWNERSHIPS_TABLE_COLUMNS.technician_active,
  USER_OWNERSHIPS_TABLE_COLUMNS.actions,
];
