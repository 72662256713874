<template>
  <sfs-form-modal
    :id="modalId"
    title="Add Additional Charge"
    @on-hide="resetFormData"
  >
    <additional-charges-form
      @on-submit="createAdditionalCharge"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
      :client="client"
      :client_id="clientId"
      category="labor"
    />
  </sfs-form-modal>
</template>

<script>
import AdditionalChargesForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'AdditionalChargesAddModal',
  mixins: [formModalMixin],
  components: {
    AdditionalChargesForm,
  },
  props: {
    client: Object,
  },
  data() {
    return {
      modalId: 'additional-charges-add-modal',
    };
  },
  methods: {
    createAdditionalCharge(data, handleCreate) {
      handleCreate(this.$additionalChargesAPI.create(this.clientId, data));
    },
    onSave() {
      this.closeModal();
      this.resetFormData();
      this.$emit('on-save');
    },
  },
  computed: {
    clientId() {
      return this.client?.id;
    },
  }
};
</script>
