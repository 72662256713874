import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const TECHNICIAN_PAYMENT_ITEM_TABLE_NAME = 'technicianPaymentItems';

// The columns available to be shown in a technicianPaymentItems table.
export const TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS = {
  id: { label: 'ID', key: 'id' },
  technician_payment_id: { label: 'Technician Payment', key: 'technician_payment_id' },
  work_order_id: { label: 'Work Order', key: 'work_order_id' },
  done_by: { label: 'Completed By', key: 'done_by' },
  gl_code_id: { label: 'GL Code', key: 'gl_code_id' },
  description: { label: 'Description', key: 'description' },
  location_id: { label: 'Location', key: 'location_id' },
  checked_in_at: { label: 'Service Date', key: 'checked_in_at' },
  amount: { label: 'Amount', key: 'amount' },
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS);

// The default columns to be shown when the technicianPaymentItems table loads.
export const TECHNICIAN_PAYMENT_ITEM_TABLE_DEFAULT_COLUMNS = [
  TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS.work_order_id,
  TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS.description,
  TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS.checked_in_at,
  TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS.gl_code_id,
  TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS.amount,
  TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS.actions,
];

// TECHNICIAN PROFILE CONSTANTS

// The default columns to be shown when the technician user payment items table loads.
export const TECHNICIAN_USER_PAYMENT_ITEM_TABLE_DEFAULT_COLUMNS = [
  TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS.work_order_id,
  TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS.description,
  TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS.location_id,
  TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS.checked_in_at,
  TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS.done_by,
  TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMNS.amount,
];
