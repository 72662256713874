import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const CLIENT_TABLE_NAME = 'clients';

// The columns available to be shown in a clients table.
export const CLIENT_TABLE_COLUMNS = {
  name: { label: 'Name', key: 'name', sortable: true },
  code: { label: 'Code', key: 'code', sortable: true },
  customer_identifier: { label: 'Customer ID', key: 'customer_identifier', sortable: true },
  contact_name: { label: 'Billing Contact', key: 'contact_name' },
  purchase_order_number: { label: 'Billing PO #', key: 'purchase_order_number' },
  attention_to: { label: 'Attention To', key: 'attention_to' },
  invoice_delivery_method: { label: 'Invoice Delivery Method', key: 'invoice_delivery_method' },
  address: { label: 'Address', key: 'address' },
  city: { label: 'City', key: 'city', sortable: true },
  state: { label: 'State', key: 'state', sortable: true },
  zip_code: { label: 'Zip Code', key: 'zip_code' },
  payment_term_days: { label: 'Payment Terms', key: 'payment_term_days' },
  deleted_at: { label: 'Active', key: 'active' },
  manager_id: { label: 'Managers', key: 'manager_id' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const CLIENT_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(CLIENT_TABLE_COLUMNS);

// The default columns to be shown when the clients table loads.
export const CLIENT_TABLE_DEFAULT_COLUMNS = [
  CLIENT_TABLE_COLUMNS.name,
  CLIENT_TABLE_COLUMNS.customer_identifier,
  CLIENT_TABLE_COLUMNS.city,
  CLIENT_TABLE_COLUMNS.state,
  CLIENT_TABLE_COLUMNS.zip_code,
  CLIENT_TABLE_COLUMNS.deleted_at,
];
