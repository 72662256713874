<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit Recurring Payment"
    @on-hide="resetFormData"
  >
    <recurring-payment-form
      v-bind="value"
      @on-submit="update"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import RecurringPaymentForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'RecurringPaymentEditModal',
  mixins: [formModalMixin],
  components: {
    RecurringPaymentForm,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: `recurring-payment-${this.value.id}-edit-modal`,
    };
  },
  methods: {
    update(data, handleUpdate) {
      handleUpdate(this.$recurringPaymentsAPI.update(this.value.id, data));
    },
    onSave(recurringPayment) {
      this.closeModal();
      this.$emit('on-save', recurringPayment);
    },
  },
};
</script>
