<template>
  <div>
    <b-row>
      <b-col>
        <b-table-simple
          v-if="isLoaded"
          :busy="isLoadingData"
          no-local-sorting
          show-empty
          responsive
          table
        >
          <b-thead>
            <b-tr>
              <b-th
                v-for="field in translatedColumns(modelName, columns)"
                :key="field.key"
                aria-colindex="1"
              >
                {{ field.label }}
              </b-th>
            </b-tr>
          </b-thead>
          <tr
            v-if="sortedRecords.length == 0"
            role="row"
            class="b-table-empty-row"
          >
            <td
              :colspan="columns.length"
              role="cell"
            >
              <div
                role="alert"
                aria-live="polite"
              >
                <div class="text-center my-2">
                  There are no records to show
                </div>
              </div>
            </td>
          </tr>

          <draggable
            v-else
            v-model="sortedRecords"
            handle=".drag-handle"
            draggable=".drag-target"
            group="records"
            tag="tbody"
            role="rowgroup"
          >
            <b-tr
              v-for="item in sortedRecords"
              :key="item.id"
              class="drag-target"
            >
              <b-td>
                <i class="fa fa-fw fa-solid fa-grip-vertical drag-handle text-secondary" />
              </b-td>
              <b-td>
                {{ item.description }}
                <br>
                <span
                  v-if="item.service_id"
                  class="font-size-sm text-secondary"
                >
                  <b-link :to="{ name: 'service', params: { id: item.service_id } }">
                    {{ item.service.name }}
                  </b-link>
                </span>
              </b-td>
              <b-td>
                <money-format
                  :value="item.price.amount"
                  :currency-code="item.price.currency"
                  subunits-value
                />
                <div class="font-size-sm text-secondary">
                  {{ item.frequency_parts | frequencyFormat | titlecaseFormat }}
                </div>
                <div class="font-size-sm text-secondary">
                  {{ item.category | humanizedFormat | titlecaseFormat }}
                </div>
              </b-td>
              <b-td>
                <div
                  v-if="$can('read_price', 'WorkOrderCharge')"
                  class="text-nowrap tax-rate-info"
                >
                  <div v-if="taxAmounts[item.id] > 0">
                    <money-format
                      :id="`tax-rate-info-${item.id}`"
                      class="d-inline"
                      :value="taxAmounts[item.id]"
                      :currency-code="item.price.currency"
                      subunits-value
                    />
                    <tax-rate-popover
                      :target="`tax-rate-info-${item.id}`"
                      :tax-rates="[taxRates[item.id]]"
                    />
                  </div>
                  <div v-else-if="taxAmounts[item.id] === 0" />
                  <div v-else>
                    <b-skeleton />
                  </div>
                </div>
              </b-td>
              <b-td>
                <b-link :to="{ name: 'client', params: { id: item.client_id } }">
                  {{ item.client.name }} ({{ item.client.code }})
                </b-link>
              </b-td>
              <b-td>
                {{ item.start_date | dateFormat }}
              </b-td>
              <b-td>
                <span v-if="item.end_date">{{ item.end_date | dateFormat }}</span>
              </b-td>
              <b-td>
                <div class="text-nowrap">
                  <b-button
                    v-if="$can('update', 'RecurringCharge')"
                    v-b-modal="`recurring-charge-${item.id}-edit-modal`"
                    size="sm"
                    variant="link"
                  >
                    <i class="fa fa-fw fa-pencil-alt" />
                  </b-button>
                  <b-button
                    v-if="$can('update', 'RecurringCharge') && !item.end_date"
                    v-b-modal="`recurring-charge-cancel-${item.id}-modal`"
                    size="sm"
                    variant="link"
                  >
                    <i class="fa fa-fw fa-times" />
                  </b-button>
                  <b-button
                    v-if="$can('update', 'RecurringCharge') && item.end_date"
                    v-b-tooltip.hover
                    title="Reactivate the charge"
                    @click="toggleActive(item)"
                    size="sm"
                    variant="link"
                  >
                    <i class="fa fa-fw fa-undo" />
                  </b-button>
                </div>
                <edit-modal
                  v-if="$can('update', 'RecurringCharge')"
                  :value="item"
                  @on-save="onSave"
                  :frequency-options="frequencyOptions"
                />
                <cancel-modal
                  v-if="$can('update', 'RecurringCharge')"
                  :value="item"
                  :end-date="$store.getters.currentOrganization?.id == 1 ? '2023-12-31' : null"
                  @on-save="onSave"
                />
              </b-td>
            </b-tr>
          </draggable>
        </b-table-simple>
        <sfs-skeleton-table
          v-else
          :rows="pagination.perPage"
          :columns="columns.length"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import EditModal from '@/components/admin/recurringCharges/EditModal.vue';
import CancelModal from '@/components/admin/recurringCharges/CancelModal.vue';
import TaxRatePopover from '@/components/admin/taxRates/TaxRatePopover.vue';
import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';
import translationMixin from '@/mixins/translationMixin';
import { RECURRING_CHARGE_TABLE_DEFAULT_COLUMNS, RECURRING_CHARGE_MODEL_NAME } from '@/constants/recurringCharges';

export default {
  name: 'RecurringChargesTable',
  components: {
    EditModal,
    CancelModal,
    TaxRatePopover,
    draggable
  },
  mixins: [urlMixin, tableMixin, translationMixin],
  props: {
    locationId: {
      type: Number,
      required: true,
    },
    columns: {
      type: Array,
      default: () => RECURRING_CHARGE_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      frequencyOptions: [],
      taxAmounts: {},
      taxRates: {},
      modelName: RECURRING_CHARGE_MODEL_NAME,
    };
  },
  mounted() {
    this.getFrequencyOptions();
  },
  methods: {
    onSave() {
      this.getRecords();
    },
    getFrequencyOptions() {
      this.$recurringChargesAPI.getFrequencyOptions().then((response) => {
        this.frequencyOptions = response.data.frequency_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
      });
    },
    getRecords() {
      this.isLoadingData = true;
      this.$recurringChargesAPI
        .getLocationRecurringCharges(this.locationId, { paginate: false })
        .then((response) => {
          const { recurringCharges } = response;
          this.records = recurringCharges;
          recurringCharges.forEach((charge) => this.calculateEstimatedTaxes(charge));
        })
        .catch(() => {
          this.records = [];
        })
        .finally(() => {
          this.loaded = true;
          this.isLoadingData = false;
        });
    },
    updateRecords(data) {
      this.$recurringChargesAPI.updateLocationRecurringChargesSortOrder(this.locationId, { recurring_charges: data });
    },
    calculateEstimatedTaxes(charge) {
      const id = charge.id;
      this.$taxRatesAPI.getRecurringChargeTaxRates(id, {})
        .then((response) => {
          const { total, taxRates } = response;
          if (total == 0) {
            this.taxAmounts[id] = 0;
          } else {
            const taxRate = taxRates[0];
            this.taxAmounts[id] = charge.price.amount * taxRate.rate;
            this.taxRates[id] = taxRate;
          }
          this.taxAmounts = Object.assign({}, this.taxAmounts);
          this.taxRates = Object.assign({}, this.taxRates);
        });
    },
    toggleActive(charge) {
      this.$swal({
        text: 'Once the charge is reactivated it will begin to be billed again.',
        preConfirm: () => this.$recurringChargesAPI.update(charge.id, { end_date: null }),
      }).then((result) => {
        if (result.value) {
          this.getRecords();
        }
        result.timer;
      });
    },
  },
  computed: {
    sortedRecords: {
      get() {
        return this.records;
      },
      set(value) {
        const sortedCharges = value.map((charge, index) => ({ ...charge, sort_order: index }));
        this.records = sortedCharges;
        this.updateRecords(sortedCharges);
      }
    }
  }
};
</script>
