<template>
  <div v-if="walkthrough">
    <base-page-heading
      title="Walkthrough"
      show-back-button
    >
      <template #secondary-title>
        <sfs-active-badge :active="!walkthrough.deleted_at" />
      </template>
      <template #extra>
        <b-button
          v-if="$can('update', 'Walkthrough')"
          variant="primary"
          v-b-modal.walkthrough-edit-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Edit the Walkthrough"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <sfs-download-button
          :filename="`Walkthrough ${walkthrough.date} - ${walkthrough.client.name}.pdf`"
          label="Summary"
          :provider-params="{ id: walkthrough.id }"
          :file-provider="$walkthroughsAPI.getPDF"
          v-b-tooltip.hover
          title="Download the Summary PDF"
          class="mr-2"
        />

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item
            v-if="$can('destroy', 'Walkthrough')"
            @click="toggleActive"
          >
            <i
              v-if="!walkthrough.deleted_at"
              class="fa fa-trash mr-1"
            />
            <i
              v-else
              class="fa fa-undo mr-1"
            />
            {{ toggleActiveTooltipText }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: walkthrough.id, model: 'Walkthrough' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content pt-5">
      <b-row>
        <b-col md="8">
          <b-row class="text-muted font-w500 mb-1">
            <b-col>
              Client
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col>
              <b-link :to="{ name: 'client', params: { id: walkthrough.client.id } }">
                {{ walkthrough.client.name }}
              </b-link>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-row class="text-muted font-w500 mb-1">
                <b-col>
                  Location
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-link :to="{ name: 'location', params: { id: location.id } }">
                    <span>{{ location.store_number }}</span>
                    <span v-if="location.name"> - {{ location.name }}</span>
                  </b-link>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  {{ { address: location.street, city: location.city, state: location.state } | locationFormat }} {{ location.zip_code }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="4">
          <b-row class="text-muted font-w500 mb-1">
            <b-col>
              Date of Walkthrough
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col>
              {{ walkthrough.date | dateFormat }}
            </b-col>
          </b-row>

          <b-row class="text-muted font-w500 mb-1">
            <b-col>
              Completed By
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-link :to="{ name: 'user', params: { id: walkthrough.user.id } }">
                {{ walkthrough.user.name }}
              </b-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <hr class="my-3">

      <b-row class="mb-4">
        <b-col>
          <div class="text-muted">
            Last modified {{ walkthrough.updated_at | dateOrTimeFormat }}
          </div>
        </b-col>
      </b-row>

      <items-manager
        :walkthrough-id="Number($route.params.id)"
        :sections="sections"
      />
    </div>

    <!-- Modals -->
    <walkthroughs-edit-modal
      v-if="$can('update', 'Walkthrough')"
      v-model="walkthrough"
    />
  </div>
</template>

<script>
import WalkthroughsEditModal from '@/components/admin/walkthroughs/EditModal.vue';
import ItemsManager from '@/components/admin/walkthroughItems/ItemsManager.vue';
import { DateTime } from 'luxon';

export default {
  name: 'WalkthroughsShow',
  components: {
    WalkthroughsEditModal,
    ItemsManager,
  },
  data() {
    return {
      walkthrough: null,
      sections: [],
    };
  },
  mounted() {
    this.getWalkthrough().then(() => this.getSections());
  },
  computed: {
    location() {
      return this.walkthrough.location;
    },
    toggleActiveTooltipText() {
      return !this.walkthrough.deleted_at ? 'Delete' : 'Reactivate';
    },
    toggleActiveConfirmText() {
      return !this.walkthrough.deleted_at
        ? 'Are you sure you want to delete the Walkthrough?'
        : 'Are you sure you want to reactivate the Walkthrough?';
    },
    toggleActiveButtonText() {
      return !this.walkthrough.deleted_at ? 'Yes, delete it!' : 'Yes, reactivate it!';
    },
  },
  methods: {
    getWalkthrough() {
      return this.$walkthroughsAPI
        .get(this.$route.params.id)
        .then((walkthrough) => {
          this.walkthrough = walkthrough;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    getSections() {
      return this.$sectionsAPI
        .getSections({ location_id: this.walkthrough.location_id, paginate: false })
        .then(({ sections }) => {
          this.sections = sections;
        });
    },
    toggleActive() {
      this.$swal({
        text: this.toggleActiveConfirmText,
        preConfirm: () => this.$walkthroughsAPI.update(this.$route.params.id, { deleted_at: !this.walkthrough.deleted_at ?  DateTime.now().toISODate() : null }),
      }).then((result) => {
        if (result.value) {
          this.walkthrough = result.value;
          this.$router.push({ name: 'walkthroughs' });
        }
        result.timer;
      });
    },
  },
};
</script>
