<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit User"
    size="lg"
    @on-hide="resetFormData"
  >
    <users-form
      @on-submit="updateUser"
      v-bind="value"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import UsersForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'UsersEditModal',
  mixins: [formModalMixin],
  components: {
    UsersForm,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: 'user-edit-modal',
    };
  },
  methods: {
    updateUser(data, handleUpdate) {
      handleUpdate(this.$usersAPI.update(this.$route.params.id, data));
    },
    onSave(user) {
      this.closeModal();
      this.$emit('input', user);
    },
  },
};
</script>
