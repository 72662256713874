<template>
  <sfs-form-modal
    :id="modalId"
    title="Add a Vendor"
    size="lg"
    @on-hide="resetFormData"
  >
    <vendors-form
      :organization="organization"
      :organization_id="organization_id"
      @on-submit="createVendor"
      @on-save="redirectToVendor"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import VendorsForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'VendorsAddModal',
  mixins: [formModalMixin],
  components: {
    VendorsForm,
  },
  data() {
    return {
      modalId: 'vendor-add-modal',
    };
  },
  methods: {
    createVendor(data, handleCreate) {
      handleCreate(this.$vendorsAPI.create(data));
    },
    redirectToVendor(vendor) {
      this.$router.push({ name: 'vendor', params: { id: vendor.id } });
    },
  },
  computed: {
    organization() {
      return this.$store.getters.currentOrganization;
    },
    organization_id() {
      return this.organization?.id;
    },
  },
};
</script>
