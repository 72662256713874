<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$recurringPaymentsAPI.getUserRecurringPayments"
    :data-id="userId"
    provider-key="recurringPayments"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
    :append-to-query="false"
  >
    <template #cell(user_id)="data">
      <b-link :to="{ name: 'user', params: { id: data.item.user_id } }">
        {{ data.item.user.name }}
      </b-link>
    </template>
    <template #cell(description)="data">
      {{ data.item.description }}
      <br>
      <span
        v-if="data.item.gl_code_id"
        class="font-size-sm text-secondary"
      >
        {{ data.item.gl_code.name }} - {{ data.item.gl_code.code }}
      </span>
    </template>
    <template #cell(start_date)="data">
      {{ data.item.start_date | dateFormat }}
    </template>

    <template #cell(end_date)="data">
      <span v-if="data.item.end_date">{{ data.item.end_date | dateFormat }}</span>
    </template>

    <template #cell(cost)="data">
      <money-format
        :value="data.item.cost.amount"
        :currency-code="data.item.cost.currency"
        :subunits-value="true"
      />
    </template>
    <template #cell(actions)="data">
      <b-button
        v-if="$can('update', 'RecurringPayment')"
        v-b-modal="`recurring-payment-${data.item.id}-edit-modal`"
        size="sm"
        variant="link"
      >
        <i class="fa fa-fw fa-pencil-alt" />
      </b-button>
      <edit-modal
        v-if="$can('update', 'RecurringPayment')"
        :key="`recurring-payments-edit-modal-${data.item.id}`"
        :value="data.item"
        @on-save="refreshTable"
      />
    </template>
  </sfs-base-table>
</template>

<script>
import EditModal from '@/components/admin/recurringPayments/EditModal.vue';
import tablePropsMixin from '@/mixins/tablePropsMixin';

import { RECURRING_PAYMENT_TABLE_DEFAULT_COLUMNS } from '@/constants/recurringPayments';

export default {
  name: 'RecurringPaymentsTable',
  components: {
    EditModal,
  },
  mixins: [tablePropsMixin],
  props: {
    userId: {
      type: Number,
      required: true,
    },
    columns: {
      type: Array,
      default: () => RECURRING_PAYMENT_TABLE_DEFAULT_COLUMNS,
    },
  },
};
</script>
