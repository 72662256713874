<template>
  <sfs-base-list-filter
    title="Filter Payment"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
    :column-picker="false"
  >
    <template #quick-filters>
      <b-form-group
        label-for="quick-filter-status"
        label-sr-only
      >
        <b-input-group>
          <v-select
            id="quick-filter-status"
            v-model="filters.status"
            :options="statusOptions"
            placeholder="Filter by Status"
            :reduce="(status) => status.value"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col>
          <b-form-group
            label="Work Period Between"
            label-for="popover-filter-date_between"
            label-cols-lg="5"
            content-cols-lg="7"
            class="mb-0"
          >
            <sfs-date-range-picker
              id="popover-filter-date_between"
              v-model="filters.date_between"
              placeholder="All"
              @on-change="onDateBetweenChanged"
            />
          </b-form-group>

          <small class="form-text text-muted mb-3">Filter on dates the work was completed</small>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-4">

      <b-row>
        <b-col>
          <b-form-group
            label="Payable Between"
            label-for="popover-filter-due_date_between"
            label-cols-lg="5"
            content-cols-lg="7"
            class="mb-0"
          >
            <sfs-date-range-picker
              id="popover-filter-due_date_between"
              v-model="filters.due_date_between"
              placeholder="All"
              @on-change="onDueDateBetweenChanged"
            />
          </b-form-group>

          <small class="form-text text-muted mb-3">Filter on dates the payment is payable</small>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import {
  TECHNICIAN_USER_PAYMENT_TABLE_COLUMN_OPTIONS,
  TECHNICIAN_USER_PAYMENT_TABLE_DEFAULT_COLUMNS,
  TECHNICIAN_PAYMENT_TABLE_EMAILED_FILTER_OPTIONS,
  TECHNICIAN_PAYMENT_TABLE_APPROVED_FILTER_OPTIONS,
  TECHNICIAN_PAYMENT_TABLE_FINALIZED_FILTER_OPTIONS,
  TECHNICIAN_USER_PAYMENT_TABLE_NAME,
} from '@/constants/technicianPayments';
import listFilterMixin from '@/mixins/listFilterMixin';

export default {
  name: 'TechnicianUserPaymentsListFilter',
  mixins: [listFilterMixin],
  components: {},
  data() {
    return {
      availableColumns: TECHNICIAN_USER_PAYMENT_TABLE_COLUMN_OPTIONS,
      emailedOptions: TECHNICIAN_PAYMENT_TABLE_EMAILED_FILTER_OPTIONS,
      approvedOptions: TECHNICIAN_PAYMENT_TABLE_APPROVED_FILTER_OPTIONS,
      finalizedOptions: TECHNICIAN_PAYMENT_TABLE_FINALIZED_FILTER_OPTIONS,
      tableName: TECHNICIAN_USER_PAYMENT_TABLE_NAME,
      statusOptions: [],
      date_between: [
        this.date_from && this.$options.filters.dateFormat(this.date_from),
        this.date_to && this.$options.filters.dateFormat(this.date_to),
      ],
      due_date_between: [
        this.due_date_from && this.$options.filters.dateFormat(this.due_date_from),
        this.due_date_to && this.$options.filters.dateFormat(this.due_date_to),
      ],
    };
  },
  mounted() {
    this.getStatusOptions();
    this.applyFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = TECHNICIAN_USER_PAYMENT_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    getStatusOptions() {
      this.$technicianUserPaymentsAPI.getStatusOptions().then((response) => {
        const options = response.data.status_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
        this.statusOptions = [...options];
      });
    },
    onDateBetweenChanged(dates) {
      if (dates[0]) {
        this.filters.date_from = this.$options.filters.dateFormat(dates[0].toISOString());
      } else {
        this.filters.date_from = '';
      }

      if (dates[1]) {
        this.filters.date_to = this.$options.filters.dateFormat(dates[1].toISOString());
      } else {
        this.filters.date_to = '';
      }
    },
    onDueDateBetweenChanged(dates) {
      if (dates[0]) {
        this.filters.due_date_from = this.$options.filters.dateFormat(dates[0].toISOString());
      } else {
        this.filters.due_date_from = '';
      }

      if (dates[1]) {
        this.filters.due_date_to = this.$options.filters.dateFormat(dates[1].toISOString());
      } else {
        this.filters.due_date_to = '';
      }
    },
  },
};
</script>
