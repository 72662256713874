<template>
  <b-modal
    :id="modalId"
    :title="title"
    size="lg"
    no-close-on-backdrop
    hide-footer
    static
  >
    <template #modal-header-close>
      <i class="fa fa-close" />
    </template>
    <b-row>
      <b-col>
        <b-alert
          v-model="showErrors"
          variant="danger"
          dismissible
        >
          <p
            class="mb-0"
            v-for="(error, index) in baseServerErrors"
            :key="index"
          >
            {{ error }}
          </p>
          <p
            class="mb-0"
            v-for="(error, index) in serverErrors"
            :key="index"
          >
            {{ error }}
          </p>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <vue-dropzone
          ref="myVueDropzone"
          :id="`dropzone-${modalId}`"
          :options="dropzoneOptions"
          @vdropzone-error="handleError"
        />
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="work-order-new-photo-close-button"
          @click="closeModal()"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          variant="primary"
          block
          class="work-order-new-photo-close-button"
          @click="finishUpload()"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import axios from 'axios';
import formMixin from '@/mixins/formMixin';
import { technicianPath } from '@/api/helpers/apiPath';
import dropzoneDefaults from '@/utils/dropzoneDefaults';

export default {
  name: 'TechnicianWorkOrdersNewPhotoModal',
  mixins: [formMixin],
  props: {
    title: {
      type: String,
      default: 'New Photo',
    },
    modalId: {
      type: String,
      default: 'work-order-new-photo-modal',
    },
    workOrderId: {
      type: Number,
    },
    beforePhoto: {
      type: Boolean,
      default: false,
    },
    afterPhoto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showErrors: false,
      fieldLabels: {
        before_photos: 'Photo',
        after_photos: 'Photo',
        no_show_photos: 'Photo',
      },
    };
  },
  computed: {
    dropzoneOptions() {
      return {
        ...dropzoneDefaults,
        url: this.uploadUrl,
        headers: { Authorization: this.$store.getters.token },
        dictDefaultMessage: 'Choose photos or drop them here to upload',
        thumbnailWidth: 125,
        thumbnailHeight: 125,

      };
    },
    uploadUrl() {
      let photoPath;
      if (this.beforePhoto) {
        photoPath = 'before_photos';
      } else if (this.afterPhoto) {
        photoPath = 'after_photos';
      } else {
        photoPath = 'no_show_photos';
      }
      return `${axios.defaults.baseURL}/${technicianPath(`/work_orders/${this.workOrderId}/${photoPath}`)}`;
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
    finishUpload() {
      this.$emit('on-save');
      this.closeModal();
    },
    handleError(_file, error) {
      this.processServerErrors(error?.errors);
      this.showErrors = this.baseServerErrors.length > 0 || Object.keys(this.serverErrors).length > 0;
    },
  },
};
</script>
