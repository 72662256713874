<template>
  <b-button
    :variant="variant"
    :size="size"
    @click="onFileDownload"
    :disabled="downloading"
  >
    <b-spinner
      v-if="downloading"
      small
      variant="secondary"
      label="Downloading..."
    />
    <i
      v-else
      :class="icon"
    />
    <span :class="{ 'ml-1': this.label.length > 0 }"> {{ label }}</span>
  </b-button>
</template>

<script>
import downloadMixin from '@/mixins/downloadMixin';

export default {
  name: 'DownloadButton',
  mixins: [downloadMixin],
  props: {
    label: {
      type: String,
      default: '',
    },
    providerParams: {
      type: Object,
      default: () => new Object(),
    },
    fileProvider: {
      type: Function,
      required: true,
    },
    filename: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'fa fa-download',
    },
    variant: {
      type: String,
      default: 'outline-secondary',
    },
    size: {
      type: String,
      default: '',
    },
  },
  methods: {
    /**
     * Triggered when the user clicks the download button. This button calls the provider function, which is expected
     * to return a promise, and processes the resulting data.
     */
    onFileDownload() {
      this.download(this.fileProvider, this.providerParams, this.filename);
    },
  },
};
</script>
