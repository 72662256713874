<template>
  <div>
    <base-page-heading title="GL Codes">
      <template #extra>
        <b-button
          v-if="$can('create', 'GLCode')"
          variant="primary"
          class="mr-2"
          v-b-modal.gl-code-add-modal
          v-b-tooltip.hover
          title="Create a new GL Code"
        >
          <i class="fa fa-plus mr-1" />
          New GL Code
        </b-button>
        <sfs-download-button
          v-b-tooltip.hover
          :provider-params="filters"
          filename="GL Codes.csv"
          :file-provider="$glCodesAPI.getSpreadsheet"
          title="Download spreadsheet"
          label="Download"
        />
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <gl-codes-list-filter
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>
      <gl-codes-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :key="tableKey"
      />
    </div>

    <!-- Page Modals -->
    <gl-codes-new-modal
      v-if="$can('create', 'GLCode')"
      @on-save="refreshTable"
    />
  </div>
</template>

<script>
import GlCodesNewModal from '@/components/admin/glCodes/NewModal.vue';
import GlCodesListFilter from '@/components/admin/glCodes/ListFilter.vue';
import GlCodesTable from '@/components/admin/glCodes/Table.vue';
import listMixin from '@/mixins/listMixin';
import { GL_CODE_TABLE_NAME } from '@/constants/glCodes';

export default {
  name: 'GlCodesList',
  components: {
    GlCodesNewModal,
    GlCodesListFilter,
    GlCodesTable,
  },
  mixins: [listMixin],
  props: {
    text: String,
    category: String,
    active: String,
    client_id: String,

  },
  data() {
    return {
      tableName: GL_CODE_TABLE_NAME,
      filters: {
        text: this.text,
        category: this.category,
        active: this.active,
        client_id: this.client_id || this.$store.getters?.client?.id,
      },
      tableKey: Date.now(),
    };
  },
  methods: {
    refreshTable() {
      this.tableKey = Date.now();
    }
  },
};
</script>
