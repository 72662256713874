<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          v-if="isLoaded"
          :items="records"
          :fields="columns"
          :busy="isLoadingData"
          :sort-by.sync="sorting.sortBy"
          :sort-desc.sync="sorting.sortDesc"
          @sort-changed="handleSortingChanged"
          no-local-sorting
          show-empty
          responsive
          table
        >
          <template #cell(name)="data">
            <b-link :to="{ name: 'technician-user', params: { id: data.item.id } }">
              {{ data.item.name }}
            </b-link>
          </template>
          <template #cell(email)="data">
            <b-link :href="`mailto:${data.item.email}`">
              {{ data.item.email }}
            </b-link>
          </template>
          <template #cell(address)="data">
            {{ data.item | locationFormat }}
          </template>
          <template #cell(parent_work_order_access)="data">
            <b-badge :variant="data.item.parent_work_order_access ? 'success' : 'secondary'">
              {{ data.item.parent_work_order_access ? 'Full Access' : 'Only Assigned' }}
            </b-badge>
          </template>
          <template #cell(active)="data">
            {{ !data.item.deleted_at ? "Yes" : "No" }}
          </template>
        </b-table>
        <sfs-skeleton-table
          v-else
          :rows="pagination.perPage"
          :columns="columns.length"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="auto"
        :class="{ 'mr-auto': true, 'ml-auto': !showPerPage }"
      >
        <b-pagination
          v-if="showPagination"
          v-model="pagination.currentPage"
          :total-rows="pagination.maxResults > totalRows ? totalRows : pagination.maxResults"
          :per-page="pagination.perPage"
          last-class="d-none"
        />
      </b-col>
      <sfs-pagination-dropdown
        v-if="showPerPage"
        container-class="col-auto"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="totalRows"
        @per-page-changed="onPerPageChanged"
      />
    </b-row>
  </div>
</template>

<script>
import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';
import { TECHNICIAN_USER_TABLE_DEFAULT_COLUMNS } from '@/constants/users';

export default {
  name: 'TechnicianUsersTable',
  mixins: [urlMixin, tableMixin],
  components: {
  },
  props: {
    columns: {
      type: Array,
      default: () => TECHNICIAN_USER_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.getRecords();
  },
  methods: {
    getRecords() {
      this.isLoadingData = true;
      this.$technicianUsersAPI
        .getUsers(this.params)
        .then((response) => {
          const { total, users } = response;
          this.totalRows = total;
          this.records = users;
        })
        .catch(() => {
          this.records = [];
        })
        .finally(() => {
          this.loaded = true;
          this.isLoadingData = false;
        });
    },
  },
};
</script>