<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$taxRatesAPI.getTaxRates"
    provider-key="taxRates"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(name)="data">
      <div>{{ data.item.name }}</div>
      <div><code>{{ data.item.rate | percentFormatFilter }}</code></div>
    </template>
    <template #cell(charge_categories)="data">
      <div
        v-if="data.item.charge_categories"
        class="font-size-sm text-secondary"
      >
        {{ formatCategories(data.item.charge_categories) }}
      </div>
    </template>
    <template #cell(service_categories)="data">
      <div
        v-if="data.item.service_categories"
        class="font-size-sm text-secondary"
      >
        {{ formatCategories(data.item.service_categories) }}
      </div>
    </template>
    <template #cell(active)="data">
      <b-badge
        v-if="!data.item.deleted_at"
        variant="success"
      >
        Active
      </b-badge>
      <b-badge
        v-else
        variant="danger"
      >
        Inactive
      </b-badge>
    </template>
    <template #cell(actions)="data">
      <div class="text-nowrap">
        <b-button
          v-if="$can('update', 'TaxRate')"
          v-b-modal="`tax-rates-${data.item.id}-edit-modal`"
          size="sm"
          variant="link"
          class="mr-1"
        >
          <i class="fa fa-fw fa-pencil-alt" />
        </b-button>
        <b-button
          v-if="$can('destroy', 'TaxRate')"
          v-b-tooltip.hover
          size="sm"
          variant="link"
          :title="toggleActiveTooltipText(!data.item.deleted_at)"
          @click="toggleActive(data.item.id, !data.item.deleted_at)"
        >
          <i
            v-if="!data.item.deleted_at"
            class="fa fa-fw fa-times"
          />
          <i
            v-else
            class="fa fa-undo"
          />
        </b-button>
      </div>
      <edit-modal
        v-if="$can('update', 'TaxRate')"
        :key="`tax-rates-edit-modal-${data.item.id}`"
        :value="data.item"
        @on-save="refreshTable"
      />
    </template>
  </sfs-base-table>
</template>

<script>
import EditModal from '@/components/admin/taxRates/EditModal.vue';
import tablePropsMixin from '@/mixins/tablePropsMixin';
import { TAX_RATE_TABLE_DEFAULT_COLUMNS } from '@/constants/taxRates';
import { DateTime } from 'luxon';

export default {
  name: 'TaxRatesTable',
  mixins: [tablePropsMixin],
  components: {
    EditModal,
  },
  props: {
    columns: {
      type: Array,
      default: () => TAX_RATE_TABLE_DEFAULT_COLUMNS,
    },
  },
  methods: {
    toggleActiveTooltipText(deleted_at) {
      return deleted_at ? 'Deactivate' : 'Reactivate';
    },
    toggleActiveConfirmText(deleted_at) {
        return deleted_at
          ? 'Are you sure you want to deactivate the Tax Rate?'
          : 'Are you sure you want to reactivate the Tax Rate?';
    },
    toggleActive(id, deleted_at) {
      this.$swal({
        text: this.toggleActiveConfirmText(deleted_at),
        preConfirm: () => this.$taxRatesAPI.update(id, { deleted_at: (deleted_at ? DateTime.now().toISODate() : null ) }),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
    formatCategories(categories) {
      return categories.map((category) => this.$options.filters.capitalizeFormat(this.$options.filters.humanizedFormat(category))).join(', ');
    },
  },
};
</script>
