<template>
  <sfs-form-modal
    :id="modalId"
    title="Generate an Invoice"
  >
    <b-form @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.number"
            label-for="supply-order-invoice-number"
          >
            <b-form-input
              id="supply-order-invoice-number"
              v-model="$v.form.number.$model"
              :state="$v.form.number.$dirty ? !$v.form.number.$error : null"
              type="text"
            />
            <b-form-invalid-feedback
              v-if="$v.form.number.$dirty"
              id="supply-order-invoice-number-feedback"
            >
              <span v-if="!$v.form.number.maxLength">
                The number must be less than {{ $v.form.number.$params.maxLength.max + 1 }} characters.
              </span>
              <span v-if="!$v.form.number.serverFailed">{{ serverErrors.number }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            :label="fieldLabels.invoice_format_id"
            label-for="supply-order-invoice-invoice_format_id"
          >
            <invoice-formats-select
              id="supply-order-invoice-invoice_format_id"
              :select-class="{ 'is-invalid': $v.form.invoice_format_id.$dirty && $v.form.invoice_format_id.$error }"
              :value="form.invoice_format"
              @input="onInvoiceFormatChange"
            />
            <b-form-invalid-feedback
              v-if="$v.form.invoice_format_id.$dirty"
              id="supply-order-invoice-invoice_format_id-feedback"
            >
              <span v-if="!$v.form.invoice_format_id.serverFailed">{{ serverErrors.invoice_format_id }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.date"
            label-for="supply-order-invoice-date"
          >
            <sfs-date-picker
              id="supply-order-invoice-date"
              v-model="form.date"
              :class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
              :with-class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
            />
            <b-form-invalid-feedback
              v-if="$v.form.date.$dirty"
              id="supply-order-invoice-date-feedback"
            >
              <span v-if="!$v.form.date.serverFailed">{{ serverErrors.date }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.due_date"
            label-for="supply-order-invoice-due_date"
          >
            <sfs-date-picker
              id="supply-order-invoice-due_date"
              v-model="form.due_date"
              :class="{ 'is-invalid': $v.form.due_date.$dirty && $v.form.due_date.$error }"
              :with-class="{ 'is-invalid': $v.form.due_date.$dirty && $v.form.due_date.$error }"
            />
            <b-form-invalid-feedback
              v-if="$v.form.due_date.$dirty"
              id="supply-order-invoice-due_date-feedback"
            >
              <span v-if="!$v.form.due_date.serverFailed">{{ serverErrors.due_date }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            :label="fieldLabels.delivery_fee"
            label-for="supply-order-invoice-delivery_fee"
          >
            <b-input-group
              prepend="$"
              :class="{ 'is-invalid': $v.form.delivery_fee.$dirty && $v.form.delivery_fee.$error }"
            >
              <b-form-input
                name="supply-order-invoice-delivery_fee"
                v-model="$v.form.delivery_fee.$model"
                :state="$v.form.delivery_fee.$dirty ? !$v.form.delivery_fee.$error : null"
                type="text"
              />
            </b-input-group>
            <b-form-invalid-feedback
              v-if="$v.form.delivery_fee.$dirty"
              :state="$v.form.delivery_fee.$dirty ? !$v.form.delivery_fee.$error : null"
              class="supply-order-invoice-delivery_fee-feedback"
            >
              <span v-if="!$v.form.delivery_fee.decimal">Please enter a number.</span>
              <span v-if="!$v.form.delivery_fee.serverFailed">
                {{ serverErrors.delivery_fee_cents }}
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            :label="fieldLabels.labor_fee"
            label-for="supply-order-invoice-labor_fee"
          >
            <b-input-group
              prepend="$"
              :class="{ 'is-invalid': $v.form.labor_fee.$dirty && $v.form.labor_fee.$error }"
            >
              <b-form-input
                name="supply-order-invoice-labor_fee"
                v-model="$v.form.labor_fee.$model"
                :state="$v.form.labor_fee.$dirty ? !$v.form.labor_fee.$error : null"
                type="text"
              />
            </b-input-group>
            <b-form-invalid-feedback
              v-if="$v.form.labor_fee.$dirty"
              :state="$v.form.labor_fee.$dirty ? !$v.form.labor_fee.$error : null"
              class="supply-order-invoice-labor_fee-feedback"
            >
              <span v-if="!$v.form.labor_fee.decimal">Please enter a number.</span>
              <span v-if="!$v.form.labor_fee.serverFailed">
                {{ serverErrors.labor_fee_cents }}
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="supply-order-invoice-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="supply-order-invoice-submit-button"
            block
            type="submit"
            variant="primary"
          >
            Generate
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { maxLength, decimal } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import InvoiceFormatsSelect from '@/components/admin/invoiceFormats/Select.vue';

export default {
  name: 'SupplyOrdersInvoiceModal',
  components: {
    InvoiceFormatsSelect,
  },
  mixins: [formMixin, formModalMixin],
  props: {
    supplyOrder: Object,
    number: String,
    date: String,
    due_date: String,
    delivery_fee: Object,
    labor_fee: Object,
    invoice_format_id: [String, Number],
    invoice_format: Object,
  },
  data() {
    return {
      form: {
        number: this.number,
        date: this.date,
        due_date: this.due_date,
        invoice_format_id: this.invoice_format_id,
        invoice_format: this.invoice_format,
        delivery_fee: this.delivery_fee && this.delivery_fee.amount ? this.delivery_fee.amount / 100 : 0,
        delivery_fee_cents: this.delivery_fee && this.delivery_fee.amount ? this.delivery_fee.amount : 0,
        labor_fee: this.labor_fee && this.labor_fee.amount ? this.labor_fee.amount / 100 : 0,
        labor_fee_cents: this.labor_fee && this.labor_fee.amount ? this.labor_fee.amount : 0,
      },
      fieldLabels: {
        number: 'Number',
        date: 'Invoice Date',
        due_date: 'Due Date',
        invoice_format_id: 'Invoice Format',
        delivery_fee: 'Delivery Fee',
        labor_fee: 'Labor Fee',
       },
       modalId: 'supply-order-invoice-modal',
    };
  },
  validations: {
    form: {
      number: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('number');
        },
      },
      purchase_order_number: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('purchase_order_number');
        },
      },
      date: {
        serverFailed() {
          return !this.hasServerErrors('date');
        },
      },
      due_date: {
        serverFailed() {
          return !this.hasServerErrors('due_date');
        },
      },
      invoice_format_id: {
        serverFailed() {
          return !this.hasServerErrors('invoice_format_id');
        },
      },
      delivery_fee: {
        decimal,
        serverFailed() {
          return !this.hasServerErrors('delivery_fee_cents');
        },
      },
      labor_fee: {
        decimal,
        serverFailed() {
          return !this.hasServerErrors('labor_fee_cents');
        },
      },
    },
  },
  watch: {
    'form.delivery_fee': function (newDeliveryFee) {
      this.form.delivery_fee_cents = isNaN(newDeliveryFee) ? 0 : Math.round(newDeliveryFee * 100);
    },
    'form.labor_fee': function (newLaborFee) {
      this.form.labor_fee_cents = isNaN(newLaborFee) ? 0 : Math.round(newLaborFee * 100);
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.$supplyOrdersAPI
        .createInvoice(this.supplyOrder.id, this.form)
        .then((invoice) => {
          this.resetServerErrors();
          this.$v.$reset();
          this.closeModal();
          this.$router.push({ name: 'invoice', params: { id: invoice.id }});
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
        });
    },
    onInvoiceFormatChange(invoiceFormat) {
      this.form.invoice_format_id = invoiceFormat ? invoiceFormat.id : '';
      this.form.invoice_format = invoiceFormat;
    },
  },
};
</script>
