<template>
  <div>
    <base-page-heading
      :title="title"
      show-back-button
    />
    <div
      v-if="loaded"
      class="content"
    >
      <b-form
        class="pt-3"
        novalidate
        @submit.stop.prevent="handleSubmit"
      >
        <b-row>
          <b-col>
            <b-alert
              :show="hasBaseServerErrors()"
              variant="danger"
              dismissible
            >
              <p
                class="mb-0"
                v-for="(error, index) in baseServerErrors"
                :key="index"
              >
                {{ error }}
              </p>
            </b-alert>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              :label="fieldLabels.date_bucket"
              label-for="work-order-date_bucket"
            >
              <sfs-date-range-picker
                id="work-order-date_bucket"
                v-model="$v.formData.date_bucket.$model"
                :with-class="{
                  'is-invalid':
                    ($v.formData.date_bucket_start.$dirty && $v.formData.date_bucket_start.$error) ||
                    ($v.formData.date_bucket_end.$dirty && $v.formData.date_bucket_end.$error)
                }"
                :class="{
                  'is-invalid':
                    ($v.formData.date_bucket_start.$dirty && $v.formData.date_bucket_start.$error) ||
                    ($v.formData.date_bucket_end.$dirty && $v.formData.date_bucket_end.$error)
                }"
                placeholder="Select Dates"
                @on-change="onDateBucketChange"
              />
              <b-form-invalid-feedback
                v-if="$v.formData.date_bucket_start.$dirty"
                id="work-order-date_bucket_start-feedback"
              >
                <span v-if="!$v.formData.date_bucket_start.required">Please enter a start date.</span>
                <span v-if="!$v.formData.date_bucket_start.serverFailed">{{ serverErrors.date_bucket_start }}</span>
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="$v.formData.date_bucket_end.$dirty"
                id="work-order-date_bucket_end-feedback"
              >
                <span v-if="!$v.formData.date_bucket_end.required">Please enter a end date.</span>
                <span v-if="!$v.formData.date_bucket_end.serverFailed">{{ serverErrors.date_bucket_end }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              :label="fieldLabels.number"
              label-for="work-order-number"
            >
              <b-form-input
                id="work-order-number"
                v-model="$v.formData.number.$model"
                :state="$v.formData.number.$dirty ? !$v.formData.number.$error : null"
                placeholder="Optional"
                type="text"
              />
              <b-form-invalid-feedback
                v-if="$v.formData.number.$dirty"
                id="work-order-number-feedback"
              >
                <span v-if="!$v.formData.number.maxLength">
                  The number must be less than {{ $v.formData.number.$params.maxLength.max + 1 }} characters.
                </span>
                <span v-if="!$v.formData.number.serverFailed">{{ serverErrors.number }}</span>
              </b-form-invalid-feedback>
              <small class="form-text text-muted">If left blank the number will be automatically generated</small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="fieldLabels.client_id"
                  label-for="work-order-client_id"
                >
                  <client-select
                    id="work-order-client_id"
                    :select-class="{ 'is-invalid': $v.formData.client_id.$dirty && $v.formData.client_id.$error, 'mb-1': true }"
                    :value="formData.client"
                    @input="onClientChange"
                    placeholder="Select a Client"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.formData.client_id.$dirty"
                    id="work-order-client_id-feedback"
                  >
                    <span v-if="!$v.formData.client_id.required">Please enter a client.</span>
                    <span v-if="!$v.formData.client_id.serverFailed">
                      {{ serverErrors.client }}
                    </span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                v-if="formData.client"
                cols="12"
              >
                <b-form-group
                  :label="fieldLabels.location_id"
                  label-for="work-order-location_id"
                >
                  <location-select
                    id="work-order-location_id"
                    :client-id="formData.client_id"
                    :select-class="{ 'is-invalid': $v.formData.location_id.$dirty && $v.formData.location_id.$error }"
                    :value="formData.location"
                    @input="onLocationChange"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.formData.location_id.$dirty"
                    id="work-order-location_id-feedback"
                  >
                    <span v-if="!$v.formData.location_id.required">Please enter a location.</span>
                    <span v-if="!$v.formData.location_id.serverFailed">{{ serverErrors.location }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="fieldLabels.assigned_to_id"
                  label-for="work-order-assigned_to_id"
                >
                  <user-select
                    id="work-order-assigned_to_id"
                    :select-class="{ 'is-invalid': $v.formData.assigned_to_id.$dirty && $v.formData.assigned_to_id.$error }"
                    select-label="name"
                    placeholder="Optional"
                    :value="formData.assigned_to"
                    :filter-params="technicianSelectParams"
                    @input="onAssignedToChange"
                  />
                  <small
                    v-if="!formData.service?.work_order_charges_require_user && !formData.assigned_to"
                    class="form-text text-muted"
                  >
                    The technician can be assigned later on
                  </small>
                  <b-form-invalid-feedback
                    v-if="$v.formData.assigned_to_id.$dirty"
                    id="work-order-assigned_to_id-feedback"
                  >
                    <span v-if="!$v.formData.assigned_to_id.required">Please enter an assigned technician.</span>
                    <span v-if="!$v.formData.assigned_to_id.serverFailed">{{ serverErrors.assigned_to_id }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                v-if="formData.assigned_to_id"
                cols="12"
              >
                <b-form-group
                  :label="fieldLabels.done_by_id"
                  label-for="work-order-done_by_id"
                >
                  <user-select
                    id="work-order-done_by_id"
                    :select-class="{ 'is-invalid': $v.formData.done_by_id.$dirty && $v.formData.done_by_id.$error }"
                    select-label="name"
                    :manager-id="formData.assigned_to_id"
                    :primary-option="formData.assigned_to"
                    :value="formData.done_by"
                    @input="onDoneByChange"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.formData.done_by_id.$dirty"
                    id="work-order-done_by_id-feedback"
                  >
                    <span v-if="!$v.formData.done_by_id.required">Please enter the technician the work is completed by.</span>
                    <span v-if="!$v.formData.done_by_id.serverFailed">{{ serverErrors.done_by_id }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>


        <b-row>
          <b-col>
            <h3 class="mb-3">
              Service
            </h3>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label="Details"
              label-for="work-order-description"
            >
              <service-select
                :select-class="{ 'is-invalid': $v.formData.service_id.$dirty && $v.formData.service_id.$error, 'mb-1': true }"
                id="work-order-service_id"
                placeholder="Select a Service"
                :client-id="formData.client_id"
                client-unassigned
                :value="formData.service"
                @input="onServiceChange"
              />
              <b-form-invalid-feedback
                v-if="$v.formData.service_id.$dirty"
                id="work-order-service_id-feedback"
              >
                <span v-if="!$v.formData.service_id.required">Please enter a service.</span>
                <span v-if="!$v.formData.service_id.serverFailed">{{ serverErrors.service }}</span>
              </b-form-invalid-feedback>
              <b-form-textarea
                id="work-order-description"
                placeholder="Enter a description"
                v-model="$v.formData.description.$model"
                :state="$v.formData.description.$dirty ? !$v.formData.description.$error : null"
                rows="4"
              />
              <b-form-invalid-feedback
                v-if="$v.formData.description.$dirty"
                id="work-order-description-feedback"
              >
                <span v-if="!$v.formData.description.required">Please enter a description.</span>
                <span v-if="!$v.formData.description.serverFailed">{{ serverErrors.description }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="fieldLabels.priority"
                  label-for="work-order-priority"
                >
                  <b-form-input
                    id="work-order-priority"
                    v-model="$v.formData.priority.$model"
                    placeholder="Optional"
                    :state="$v.formData.priority.$dirty ? !$v.formData.priority.$error : null"
                    type="text"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.formData.priority.$dirty"
                    id="work-order-priority-feedback"
                  >
                    <span v-if="!$v.formData.priority.maxLength">
                      The priority must be less than {{ $v.formData.priority.$params.maxLength.max + 1 }} characters.
                    </span>
                    <span v-if="!$v.formData.priority.serverFailed">{{ serverErrors.priority }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="fieldLabels.maintainance_type"
                  label-for="work-order-maintainance_type"
                >
                  <b-form-input
                    id="work-order-maintainance_type"
                    v-model="$v.formData.maintainance_type.$model"
                    :state="$v.formData.maintainance_type.$dirty ? !$v.formData.maintainance_type.$error : null"
                    placeholder="Optional"
                    type="text"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.formData.maintainance_type.$dirty"
                    id="work-order-maintainance_type-feedback"
                  >
                    <!-- prettier-ignore -->
                    <span v-if="!$v.formData.maintainance_type.maxLength">
                      The maintainance type must be less than {{ $v.formData.maintainance_type.$params.maxLength.max + 1 }} characters.
                    </span>
                    <span v-if="!$v.formData.maintainance_type.serverFailed">
                      {{ serverErrors.maintainance_type }}
                    </span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>


        <b-collapse id="collapse-more-filters">
          <b-row class="mb-2">
            <b-col
              cols="12"
              lg="6"
            >
              <b-row>
                <b-col lg="6">
                  <b-form-group
                    :label="fieldLabels.cost_limit"
                    label-for="work-order-cost_limit"
                    class="mb-0"
                  >
                    <b-input-group
                      prepend="$"
                      :class="{ 'is-invalid': $v.formData.cost_limit.$dirty && $v.formData.cost_limit.$error }"
                    >
                      <b-form-input
                        id="work-order-cost_limit"
                        v-model="$v.formData.cost_limit.$model"
                        placeholder="Optional"
                        :state="$v.formData.cost_limit.$dirty ? !$v.formData.cost_limit.$error : null"
                        type="text"
                      />
                    </b-input-group>
                    <b-form-invalid-feedback
                      v-if="$v.formData.cost_limit.$dirty"
                      id="work-order-cost_limit-feedback"
                    >
                      <span v-if="!$v.formData.cost_limit.decimal">Please enter a number.</span>
                      <span v-if="!$v.formData.cost_limit.serverFailed">
                        {{ serverErrors.cost_limit_cents }}
                      </span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col lg="6">
                  <b-form-group
                    :label="fieldLabels.price_limit"
                    label-for="work-order-price_limit"
                    class="mb-0"
                  >
                    <b-input-group
                      prepend="$"
                      :class="{ 'is-invalid': $v.formData.price_limit.$dirty && $v.formData.price_limit.$error }"
                    >
                      <b-form-input
                        id="work-order-price_limit"
                        v-model="$v.formData.price_limit.$model"
                        placeholder="Optional"
                        :state="$v.formData.price_limit.$dirty ? !$v.formData.price_limit.$error : null"
                        type="text"
                      />
                    </b-input-group>
                    <b-form-invalid-feedback
                      v-if="$v.formData.price_limit.$dirty"
                      id="work-order-price_limit-feedback"
                    >
                      <span v-if="!$v.formData.price_limit.decimal">Please enter a number.</span>
                      <span v-if="!$v.formData.price_limit.serverFailed">
                        {{ serverErrors.price_limit_cents }}
                      </span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <small class="form-text">
                    These limits are the maximum allowed to be billed and paid
                  </small>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                :label="fieldLabels.customer_identifier"
                label-for="work-order-customer_identifier"
              >
                <b-form-input
                  id="work-order-customer_identifier"
                  v-model="$v.formData.customer_identifier.$model"
                  :state="$v.formData.customer_identifier.$dirty ? !$v.formData.customer_identifier.$error : null"
                  placeholder="Optional"
                  type="text"
                />
                <b-form-invalid-feedback
                  v-if="$v.formData.customer_identifier.$dirty"
                  id="work-order-customer_identifier-feedback"
                >
                  <!-- prettier-ignore -->
                  <span v-if="!$v.formData.customer_identifier.maxLength"> The customer identifier must be less than {{ $v.formData.customer_identifier.$params.maxLength.max + 1 }} characters.</span>
                  <span v-if="!$v.formData.customer_identifier.serverFailed">
                    {{ serverErrors.customer_identifier }}
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-collapse>

        <b-row class="mb-3">
          <b-col>
            <b-link v-b-toggle:collapse-more-filters>
              <span class="when-open">Show Less</span><span class="when-closed">Show More</span>
            </b-link>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <h3 class="mt-4 mb-3">
              Charges
            </h3>
          </b-col>
        </b-row>

        <div
          v-for="(v, index) in $v.formData.charges.$each.$iter"
          class="mt-3"
          :key="index"
        >
          <b-row>
            <b-col
              lg="6"
            >
              <b-row>
                <b-col>
                  <b-form-group
                    :label="fieldLabels.service_id"
                    label-for="work-order-charge-service_id"
                    class="mb-0"
                  >
                    <service-select
                      :select-class="{ 'is-invalid': v.service_id.$dirty && v.service_id.$error }"
                      class="work-order-charge-service_id mb-1"
                      :placeholder="formData.client?.work_order_charges_require_service ? '' : 'Optional'"
                      :client-id="formData.client_id"
                      client-unassigned
                      v-model="v.service.$model"
                      @input="(service) => onChargeServiceChange(index, service)"
                    />
                    <b-form-invalid-feedback
                      v-if="v.service_id.$dirty"
                      class="work-order-charge-service_id-feedback"
                    >
                      <span v-if="!v.service_id.required">Please enter a service.</span>
                      <span v-if="!v.service_id.serverFailed">{{ serverErrors.service }}</span>
                    </b-form-invalid-feedback>
                    <b-form-textarea
                      class="work-order-charge-description"
                      placeholder="Enter a description"
                      v-model="v.description.$model"
                      :state="v.description.$dirty ? !v.description.$error : null"
                      rows="4"
                    />
                    <b-form-invalid-feedback
                      v-if="v.description.$dirty"
                      class="work-order-charge-description-feedback"
                    >
                      <span v-if="!v.description.required">Please enter a description.</span>
                      <span v-if="!v.description.serverFailed">{{ serverErrors.description }}</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col lg="6">
              <b-row>
                <b-col>
                  <b-form-group
                    :label="fieldLabels.category"
                    label-for="work-order-category"
                  >
                    <category-select
                      name="work-order-category"
                      v-model="v.category.$model"
                      :select-class="{ 'is-invalid': v.category.$dirty && v.category.$error }"
                    />
                    <b-form-invalid-feedback
                      v-if="v.category.$dirty"
                      class="work-order-category-feedback"
                    >
                      <span v-if="!v.category.required">Please enter a category.</span>
                      <span v-if="!v.category.maxLength">
                        The category must be less than {{ v.category.$params.maxLength.max + 1 }} characters.
                      </span>
                      <span v-if="!v.category.serverFailed">{{ serverErrors.category }}</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col class="text-right">
                  <b-button
                    class="remove-charge-button font-size-lg"
                    v-b-tooltip.hover
                    title="Remove the Charge"
                    variant="link"
                    @click="() => removeCharge(index)"
                  >
                    <i class="fa fa-close" />
                  </b-button>
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="4">
                  <b-form-group
                    :label="fieldLabels.quantity"
                    label-for="work-order-quantity"
                    class="mb-0"
                  >
                    <b-form-input
                      name="work-order-quantity"
                      v-model="v.quantity.$model"
                      :state="v.quantity.$dirty ? !v.quantity.$error : null"
                      type="text"
                    />
                    <b-form-invalid-feedback
                      v-if="v.quantity.$dirty"
                      class="work-order-quantity-feedback"
                    >
                      <span v-if="!v.quantity.required">Please enter a quantity.</span>
                      <span v-if="!v.quantity.decimal">Please enter a number.</span>
                      <span v-if="!v.quantity.serverFailed">{{ serverErrors.quantity }}</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col lg="4">
                  <b-form-group
                    :label="fieldLabels.cost"
                    label-for="work-order-cost"
                    class="mb-0"
                  >
                    <b-input-group
                      prepend="$"
                      :class="{ 'is-invalid': v.cost.$dirty && v.cost.$error, 'has-warning': !v.cost.$error && negativeProfitability(v) }"
                    >
                      <b-form-input
                        id="work-order-cost"
                        v-model="v.cost.$model"
                        :state="v.cost.$dirty ? !v.cost.$error : null"
                        type="text"
                      />
                    </b-input-group>
                    <small
                      v-if="!v.cost.$error"
                      class="form-text text-secondary"
                    >
                      <money-format
                        class="d-inline"
                        :value="toCents(v.cost.$model) * v.quantity.$model"
                        subunits-value
                      />
                      total
                    </small>
                    <b-form-invalid-feedback
                      v-if="v.cost.$dirty"
                      id="work-order-cost-feedback"
                    >
                      <span v-if="!v.cost.required">Please enter a cost.</span>
                      <span v-if="!v.cost.decimal">Please enter a number.</span>
                      <span v-if="!v.cost.serverFailed">
                        {{ serverErrors.cost_cents }}
                      </span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col lg="4">
                  <b-form-group
                    :label="fieldLabels.price"
                    label-for="work-order-price"
                    class="mb-0"
                  >
                    <b-input-group
                      prepend="$"
                      :class="{ 'is-invalid': v.price.$dirty && v.price.$error }"
                    >
                      <b-form-input
                        id="work-order-price"
                        v-model="v.price.$model"
                        :state="v.price.$dirty ? !v.price.$error : null"
                        type="text"
                      />
                    </b-input-group>
                    <small
                      v-if="!v.price.$error"
                      class="form-text text-secondary"
                    >
                      <money-format
                        class="d-inline"
                        :value="toCents(v.price.$model) * v.quantity.$model"
                        subunits-value
                      />
                      total
                    </small>
                    <b-form-invalid-feedback
                      v-if="v.price.$dirty"
                      id="work-order-price-feedback"
                    >
                      <span v-if="!v.price.required">Please enter a price.</span>
                      <span v-if="!v.price.decimal">Please enter a number.</span>
                      <span v-if="!v.price.serverFailed">
                        {{ serverErrors.price_cents }}
                      </span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="negativeProfitability(v)">
                <b-col>
                  <small
                    class="warning-feedback"
                  >
                    Warning: the {{ $store.getters.translate('work_orders.cost', 'Cost') }} of this charge is more than the {{ $store.getters.translate('work_orders.price', 'Price') }}.
                  </small>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col>
            <b-button
              id="add-charge-button"
              class="mt-1"
              variant="outline-secondary"
              @click="addCharge"
            >
              <i class="fa fa-plus mr-1" />Add Charge
            </b-button>
          </b-col>
        </b-row>

        <b-row
          v-if="duplicate_id"
          class="mt-4"
        >
          <b-col lg="6">
            <b-form-group label-for="work-order-link_to_parent">
              <b-form-checkbox
                id="work-order-link_to_parent"
                v-model="$v.formData.link_to_parent.$model"
              >
                Link to Work Order {{ duplicateNumber }} <i class="fa fa-link" />
              </b-form-checkbox>
              <small class="form-text text-secondary">
                Select this if these Work Orders are related to each other
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <hr class="mt-4">

        <b-row class="mb-3">
          <b-col
            offset-lg="8"
            lg="2"
          >
            <b-button
              variant="link-dark"
              block
              id="work-order-close-button"
              @click="() => $router.go(-1)"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col lg="2">
            <b-button
              id="work-order-submit-button"
              block
              type="submit"
              variant="primary"
              :disabled="processing"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import { required, maxLength, decimal, requiredIf, minLength } from 'vuelidate/lib/validators';
import LocationSelect from '@/components/admin/locations/Select.vue';
import ClientSelect from '@/components/admin/clients/Select.vue';
import ServiceSelect from '@/components/admin/services/Select.vue';
import UserSelect from '@/components/admin/users/Select.vue';
import CategorySelect from '@/components/admin/workOrderCharges/CategoryButtonSelect.vue';
import { WORK_ORDER_FORM_LABELS } from '@/constants/workOrders.js';
import { USER_TECHNICIAN_CATEGORY } from '@/constants/users';
import { toCents } from '@/utils/money.js';

export default {
  name: 'NewWorkOrder',
  components: {
    LocationSelect,
    ClientSelect,
    ServiceSelect,
    UserSelect,
    CategorySelect,
  },
  mixins: [formMixin],
  props: {
    duplicate_id: {
      type: [String, Number]
    },
  },
  data() {
    const costLabel = this.$store.getters.translate('work_orders.cost', 'Cost');
    const priceLabel = this.$store.getters.translate('work_orders.price', 'Price');
    return {
      duplicateNumber: '',
      formData: {
        link_to_parent: false,
        client_id: null,
        location_id: null,
        service_id: null,
        number: null,
        customer_identifier: null,
        description: null,
        priority: null,
        maintainance_type: null,
        cost_limit: null,
        price_limit: null,
        date_bucket_start: null,
        date_bucket_end: null,
        date_bucket: [],
        assigned_to_id: null,
        done_by_id: null,
        client: null,
        location: null,
        service: null,
        assigned_to: null,
        done_by: null,
        charges: [{
          description: null,
          service_id: null,
          service: null,
          quantity: 1,
          price: 0,
          cost: 0,
          category: 'labor',
        }],
      },
      fieldLabels: {
        ...WORK_ORDER_FORM_LABELS,
        client_id: this.$store.getters.translate('client', 'Client'),
        category: 'Category',
        quantity: 'Quantity',
        cost: costLabel,
        price: priceLabel,
        cost_cents: costLabel,
        price_cents: priceLabel,
        cost_limit: `${costLabel} Limit`,
        price_limit: `${priceLabel} Limit`,
        cost_limit_cents: `${costLabel} Limit`,
        price_limit_cents: `${priceLabel} Limit`,
      },
      loaded: false,
      technicianSelectParams: { category: USER_TECHNICIAN_CATEGORY, active: true },
    };
  },
  mounted() {
    if (this.duplicate_id) {
      this.loadDuplicateData();
    } else {
      this.loaded = true;
    }
  },
  computed: {
    form() {
      return {
        parent_id: this.formData.link_to_parent ? this.duplicate_id : null,
        client_id: this.formData.client_id,
        location_id: this.formData.location_id,
        service_id: this.formData.service_id,
        number: this.formData.number,
        customer_identifier: this.formData.customer_identifier,
        description: this.formData.description,
        priority: this.formData.priority,
        maintainance_type: this.formData.maintainance_type,
        date_bucket_start: this.formData.date_bucket_start,
        date_bucket_end: this.formData.date_bucket_end,
        assigned_to_id: this.formData.assigned_to_id,
        done_by_id: this.formData.done_by_id,
        cost_limit_cents: this.formData.cost_limit || this.formData.cost_limit === 0 ? this.toCents(this.formData.cost_limit) : null,
        price_limit_cents: this.formData.price_limit || this.formData.price_limit === 0 ? this.toCents(this.formData.price_limit) : null,
        charges_attributes: this.formData.charges.map((charge) => (
          {
            description: charge.description,
            service_id: charge.service_id,
            user_id: this.formData.assigned_to_id,
            category: charge.category,
            quantity: charge.quantity,
            cost_cents: this.toCents(charge.cost),
            price_cents: this.toCents(charge.price),
          }
        )),
      };
    },
    title() {
      return this.duplicate_id ? `Duplicate Work Order ${this.duplicateNumber}` : 'New Work Order';
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.processing = true;
      this.$workOrdersAPI.create(this.form).then((data) => {
        this.resetServerErrors();
        this.$v.$reset();
        this.processing = false;
        this.$router.push({ name: 'work-order', params: { id: data.id } });
      }).catch((error) => {
          this.processServerErrors(error.response.data.errors);
          this.processing = false;
      });
    },
    addCharge() {
      const service = this.formData.charges.length === 0 ? this.formData.service : null;
      const description = this.formData.charges.length === 0 ? this.formData.description : '';
      this.formData.charges.push({
        description: description,
        service_id: service?.id,
        service: service,
        quantity: 1,
        price: 0,
        cost: 0,
        category: 'labor',
      });
    },
    removeCharge(index) {
      this.formData.charges.splice(index, 1);
    },
    loadDuplicateData() {
      const loadWorkOrder = new Promise((resolve) => {
        this.$workOrdersAPI.get(this.duplicate_id).then((workOrder) => {
          resolve(workOrder);
        });
      });

      const loadCharges = new Promise((resolve) => {
        this.$workOrderChargesAPI.getWorkOrderCharges(this.duplicate_id).then(({ workOrderCharges }) => {
          resolve(workOrderCharges);
        });
      });

      Promise.all([loadWorkOrder, loadCharges]).then((values) => {
        const workOrder = values[0];
        this.duplicateNumber = workOrder.number;
        this.formData.client_id = workOrder.client_id;
        this.formData.location_id = workOrder.location_id;
        this.formData.service_id = workOrder.service_id;
        this.formData.description = workOrder.description;
        this.formData.priority = workOrder.priority;
        this.formData.customer_identifier = workOrder.customer_identifier;
        this.formData.cost_limit =  workOrder.cost_limit && (workOrder.cost_limit.amount || workOrder.cost_limit.amount === 0) ? workOrder.cost_limit?.amount / 100 : null;
        this.formData.price_limit = workOrder.price_limit && (workOrder.price.amount || workOrder.price.amount === 0) ? workOrder.price_limit?.amount / 100 : null;
        this.formData.maintainance_type = workOrder.maintainance_type;
        this.formData.date_bucket_start = workOrder.date_bucket_start;
        this.formData.date_bucket_end = workOrder.date_bucket_end;
        this.formData.date_bucket = [
          this.$options.filters.dateFormat(workOrder.date_bucket_start),
          this.$options.filters.dateFormat(workOrder.date_bucket_end),
        ];
        this.formData.assigned_to_id = workOrder.assigned_to_id;
        this.formData.done_by_id = workOrder.done_by_id;
        this.formData.client = workOrder.client;
        this.formData.location = workOrder.location;
        this.formData.service = workOrder.service;
        this.formData.assigned_to = workOrder.assigned_to;
        this.formData.done_by = workOrder.done_by;

        this.formData.charges = values[1].map((charge) => ({
          description: charge.description,
          service_id: charge.service_id,
          service: charge.service,
          quantity: charge.quantity,
          price: charge.price.amount / 100,
          cost: charge.cost.amount / 100,
          category: charge.category,
        }));
      })
      .finally(() => {
        this.loaded = true;
      });
    },
    negativeProfitability(v) {
      return this.toCents(v.cost.$model) > this.toCents(v.price.$model);
    },
    onDateBucketChange(dates) {
      this.formData.date_bucket_start = dates[0] || '';
      this.formData.date_bucket_end = dates[1] || '';
    },
    onLocationChange(location) {
      this.formData.location_id = location ? location.id : null;
      this.formData.location = location;
    },
    onClientChange(client) {
      this.formData.client_id = client ? client.id : null;
      this.formData.client = client;
      this.formData.location_id = null;
      this.formData.location = null;
    },
    onChargeServiceChange(index, service) {
      this.formData.charges[index].service_id = service ? service.id : null;
      this.formData.charges[index].service = service ;

      if (service) {
        this.formData.charges[index].description = service.description;
      }
    },
    toCents(amount) {
      return toCents(amount);
    },
    onServiceChange(service) {
      this.formData.service_id = service ? service.id : null;
      this.formData.service = service;
      if (service) {
        this.formData.description = service.description;
      }
    },
    onAssignedToChange(user) {
      this.formData.assigned_to_id = user ? user.id : null;
      this.formData.assigned_to = user;
      this.formData.done_by_id = user ? user.id : null;
      this.formData.done_by = user;
    },
    onDoneByChange(user) {
      this.formData.done_by_id = user ? user.id : null;
      this.formData.done_by = user;
    },
  },
  validations: {
    formData: {
      client_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('client');
        },
      },
      location_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('location');
        },
      },
      service_id: {
        required: requiredIf(function () {
          return this.formData.client?.work_order_charges_require_service;
        }),
        serverFailed() {
          return !this.hasServerErrors('service');
        },
      },
      number: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('number');
        },
      },
      customer_identifier: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('customer_identifier');
        },
      },
      description: {
        required,
        serverFailed() {
          return !this.hasServerErrors('description');
        },
      },
      priority: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('priority');
        },
      },
      maintainance_type: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('maintainance_type');
        },
      },
      date_bucket_start: {
        required,
        serverFailed() {
          return !this.hasServerErrors('date_bucket_start');
        },
      },
      date_bucket_end: {
        required,
        serverFailed() {
          return !this.hasServerErrors('date_bucket_end');
        },
      },
      date_bucket: {},
      assigned_to_id: {
        required: requiredIf(function () {
          return this.formData.service?.work_order_charges_require_user;
        }),
        serverFailed() {
          return !this.hasServerErrors('assigned_to_id');
        },
      },
      done_by_id: {
        required: requiredIf(function () {
          return this.formData.service?.work_order_charges_require_user;
        }),
        serverFailed() {
          return !this.hasServerErrors('done_by_id');
        },
      },
      cost_limit: {
        decimal,
        serverFailed() {
          return !this.hasServerErrors('cost_limit_cents');
        },
      },
      price_limit: {
        decimal,
        serverFailed() {
          return !this.hasServerErrors('price_limit_cents');
        },
      },
      link_to_parent: {},
      charges: {
        required,
        minLength: minLength(1),
        $each: {
          description: {
            required,
            serverFailed() {
              return !this.hasServerErrors('description');
            },
          },
          service: {},
          service_id: {
            required: requiredIf(function () {
              return this.formData.client?.work_order_charges_require_service;
            }),
            serverFailed() {
              return !this.hasServerErrors('service_id');
            },
          },
          category: {
            required,
            maxLength: maxLength(255),
            serverFailed() {
              return !this.hasServerErrors('category');
            },
          },
          quantity: {
            required,
            decimal,
            serverFailed() {
              return !this.hasServerErrors('quantity');
            },
          },
          cost: {
            required,
            decimal,
            serverFailed() {
              return !this.hasServerErrors('cost');
            },
          },
          price: {
            required,
            decimal,
            serverFailed() {
              return !this.hasServerErrors('price');
            },
          },
        },
      },
    },
  },
};
</script>

