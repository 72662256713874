<template>
  <div v-if="workOrder">
    <div class="content">
      <b-row class="justify-content-center">
        <b-col
          md="8"
          lg="6"
          xl="4"
        >
          <div class="p-sm-3 px-lg-4 py-lg-5">
            <div class="push text-center mb-0">
              <b-img
                :src="logo"
                height="320"
                :alt="$store.getters.appName"
              />
              <div class="font-size-sm mb-5">
                v{{ $appVersion }}
              </div>
            </div>
            <div
              v-if="submitted"
              class="animated bounceIn text-center"
            >
              <h2 class="mb-3">
                Thank you!
              </h2>
              <h5 class="mb-4">
                Your upload has been submitted for approval
              </h5>
              <div class="mb-3">
                <i class="far fa-4x fa-check-circle" />
              </div>
            </div>
            <b-form
              v-else
              @submit.stop.prevent="onSubmit"
            >
              <h2 class="text-center mb-5">
                Work Order {{ workOrder.number }}
              </h2>
              <b-alert
                :show="hasBaseServerErrors()"
                variant="danger"
                dismissible
              >
                <p
                  class="mb-0"
                  v-for="(error, index) in baseServerErrors"
                  :key="index"
                >
                  {{ error }}
                </p>
              </b-alert>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="fieldLabels.file"
                    label-for="work-order-qr-code-file"
                  >
                    <b-form-file
                      v-model="$v.form.file.$model"
                      name="work-order-qr-code-file"
                      :state="$v.form.file.$dirty ? !$v.form.file.$error : null"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                    <b-form-invalid-feedback
                      v-if="$v.form.file.$dirty"
                      class="work-order-qr-code-file-feedback"
                    >
                      <span v-if="!$v.form.file.fileSize">
                        The file you are attempting to upload is too large, the file must be smaller than 5mb.
                      </span>
                      <span v-if="!$v.form.file.required">Please enter a file.</span>
                      <span v-if="!$v.form.file.serverFailed">{{ serverErrors.file }}</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group
                    :label="fieldLabels.checked_in_at"
                    label-for="work-order-qr-code-checked_in_at"
                  >
                    <sfs-date-time-picker
                      id="work-order-checked_in_at"
                      v-model="form.checked_in_at"
                      :class="{ 'is-invalid': $v.form.checked_in_at.$dirty && $v.form.checked_in_at.$error }"
                      :with-class="{ 'is-invalid': $v.form.checked_in_at.$dirty && $v.form.checked_in_at.$error }"
                      :time-zone="workOrder.location && workOrder.location.time_zone"
                    />
                    <b-form-invalid-feedback
                      v-if="$v.form.checked_in_at.$dirty"
                      id="work-order-qr-code-checked_in_at-feedback"
                    >
                      <p
                        v-if="!$v.form.checked_in_at.required"
                        class="mb-0"
                      >
                        Please enter a check-in time.
                      </p>
                      <!-- prettier-ignore -->
                      <span v-if="!$v.form.checked_in_at.minDate">Check-in date must be on or after {{ workOrder.date_bucket_start | dateFormat }}.</span>
                      <!-- prettier-ignore -->
                      <span v-if="!$v.form.checked_in_at.maxDate">Check-in date must be on or before {{ workOrder.date_bucket_end | dateFormat }}.</span>
                      <span v-if="!$v.form.checked_in_at.serverFailed">{{ serverErrors.checked_in_at }}</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  lg="6"
                >
                  <b-form-group
                    :label="fieldLabels.checked_out_at"
                    label-for="work-order-qr-code-checked_out_at"
                  >
                    <sfs-date-time-picker
                      id="work-order-checked_out_at"
                      v-model="form.checked_out_at"
                      :class="{ 'is-invalid': $v.form.checked_out_at.$dirty && $v.form.checked_out_at.$error }"
                      :with-class="{ 'is-invalid': $v.form.checked_out_at.$dirty && $v.form.checked_out_at.$error }"
                      :time-zone="workOrder.location && workOrder.location.time_zone"
                    />
                    <b-form-invalid-feedback
                      v-if="$v.form.checked_out_at.$dirty"
                      id="work-order-qr-code-checked_out_at-feedback"
                    >
                      <p
                        v-if="!$v.form.checked_out_at.required"
                        class="mb-0"
                      >
                        Please enter a check-out time.
                      </p>
                      <!-- prettier-ignore -->
                      <span v-if="!$v.form.checked_out_at.minDate">Check-out date must be on or after {{ workOrder.date_bucket_start | dateFormat }}.</span>
                      <!-- prettier-ignore -->
                      <span v-if="!$v.form.checked_out_at.maxDate">Check-out date must be on or before {{ workOrder.date_bucket_end | dateFormat }}.</span>
                      <!-- prettier-ignore -->
                      <span v-if="!$v.form.checked_out_at.afterCheckInDate">Check-out date must be after the check-in.</span>
                      <span v-if="!$v.form.checked_out_at.serverFailed">{{ serverErrors.checked_out_at }}</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col cols="12">
                  <b-button
                    block
                    class="work-order-qr-code-submit-button"
                    type="submit"
                    variant="primary"
                  >
                    <b-spinner
                      v-if="submitting"
                      small
                      class="mr-2"
                      label="Downloading..."
                    />
                    <i
                      v-else
                      class="fa fa-faw fa-download mr-2"
                    />
                    Upload Work Order
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import minDate from '@/utils/validators/minDate';
import maxDate from '@/utils/validators/maxDate';
import fileSize from '@/utils/validators/fileSize';
import minDateTime from '@/utils/validators/minDateTime';
import formMixin from '@/mixins/formMixin';
import scopesLogo from '@/assets/images/logo.png';
import { WORK_ORDER_FORM_LABELS } from '@/constants/workOrders.js';
import { MAX_FILE_SIZE_BYTES } from '@/constants';

export default {
  name: 'WorkOrdersUploadForm',
  mixins: [formMixin],
  data() {
    return {
      workOrder: null,
      form: {
        file: null,
        checked_in_at: null,
        checked_out_at: null,
      },
      submitted: false,
      submitting: false,
      fieldLabels: {
        file: 'Upload the Work Order File',
        ...WORK_ORDER_FORM_LABELS,
      },
      logo: scopesLogo,
    };
  },
  mounted() {
    this.getWorkOrder();
  },
  methods: {
    getWorkOrder() {
      this.$workOrdersAPI
        .getQRCodeWorkOrder(this.$route.params.id)
        .then((workOrder) => (this.workOrder = workOrder))
        .catch((error) => this.processQRCodeErrorResponse(error));
    },
    onSubmit() {
      this.resetServerErrors();
      this.$v.$touch();
      this.submitting = true;

      if (this.$v.$anyError) {
        this.submitting = false;
        return;
      }
      this.$workOrdersAPI
        .uploadQRCodeWorkOrder(this.$route.params.id, this.form)
        .then(() => (this.submitted = true))
        .catch((error) => this.processQRCodeErrorResponse(error));
    },
    processQRCodeErrorResponse(error) {
      this.submitting = false;
      if (error?.response?.status == 422) {
        this.processServerErrors(error?.response?.data?.errors);
      } else if (error?.response?.status == 404) {
        this.$router.push('/work_orders/invalid');
      } else {
        this.$router.push('/work_orders/unprocessable');
      }
    },
  },
  validations: {
    form: {
      file: {
        required,
        fileSize(value) {
          return fileSize(value, MAX_FILE_SIZE_BYTES);
        },
        serverFailed() {
          return !this.hasServerErrors('file');
        },
      },
      checked_in_at: {
        required,
        minDate(value) {
          return minDate(value, this.workOrder.date_bucket_start);
        },
        maxDate(value) {
          return maxDate(value, this.workOrder.date_bucket_end);
        },
        serverFailed() {
          return !this.hasServerErrors('checked_in_at');
        },
      },
      checked_out_at: {
        required,
        minDate(value) {
          return minDate(value, this.workOrder.date_bucket_start);
        },
        maxDate(value) {
          return maxDate(value, this.workOrder.date_bucket_end);
        },
        afterCheckInDate(value) {
          return minDateTime(value, this.form.checked_in_at);
        },
        serverFailed() {
          return !this.hasServerErrors('checked_out_at');
        },
      },
    },
  },
};
</script>
