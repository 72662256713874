<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$recurringWorkOrdersAPI.getLocationRecurringWorkOrders"
    :data-id="locationId"
    provider-key="recurringWorkOrders"
    :columns="translatedColumns(modelName, columns)"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
    :append-to-query="false"
  >
    <template #cell(client_id)="data">
      <b-link :to="{ name: 'client', params: { id: data.item.client_id } }">
        {{ data.item.client.name }} ({{ data.item.client.code }})
      </b-link>
    </template>
    <template #cell(description)="data">
      <div class="text-prewrap">
        {{ data.item.description }}
      </div>
      <div
        v-if="data.item.service_id"
        class="font-size-sm"
      >
        <b-link :to="{ name: 'service', params: { id: data.item.service_id } }">
          {{ data.item.service.name }}
        </b-link>
      </div>
    </template>

    <template #cell(price)="data">
      <money-format
        :value="data.item.price.amount"
        :currency-code="data.item.price.currency"
        subunits-value
      />
    </template>
    <template #cell(actions)="data">
      <div class="text-nowrap">
        <b-button
          v-if="$can('update', 'RecurringWorkOrder')"
          v-b-modal="`recurring-work-order-${data.item.id}-edit-modal`"
          size="sm"
          variant="link"
          class="mr-1"
        >
          <i class="fa fa-fw fa-pencil-alt" />
        </b-button>
        <b-button
          v-b-tooltip.hover.top.window="'Schedule the Work Orders'"
          v-if="$can('update', 'RecurringWorkOrder')"
          v-b-modal="`recurring-work-order-${data.item.id}-frequencies-modal`"
          size="sm"
          variant="link"
          class="mr-1"
        >
          <i class="fa fa-fw fa-calendar-alt" />
        </b-button>
        <b-button
          v-if="$can('destroy', 'RecurringWorkOrder')"
          size="sm"
          variant="link"
          @click="destroyItemDialog(data.item.id)"
        >
          <i class="fa fa-fw fa-times" />
        </b-button>
        <edit-modal
          v-if="$can('update', 'RecurringWorkOrder')"
          :key="`recurring-work-orders-edit-modal-${data.item.id}`"
          :value="data.item"
          @on-save="refreshTable"
        />
        <frequencies-modal
          v-if="$can('update', 'RecurringWorkOrder')"
          :key="`recurring-work-orders-frequencies-modal-${data.item.id}`"
          :value="data.item"
          @on-save="refreshTable"
          :month-options="monthOptions"
          :frequency-options="frequencyOptions"
        />
      </div>
    </template>
  </sfs-base-table>
</template>

<script>
import EditModal from '@/components/admin/recurringWorkOrders/EditModal.vue';
import FrequenciesModal from '@/components/admin/recurringWorkOrders/FrequenciesModal.vue';
import tablePropsMixin from '@/mixins/tablePropsMixin';
import translationMixin from '@/mixins/translationMixin';
import { RECURRING_WORK_ORDER_TABLE_DEFAULT_COLUMNS, RECURRING_WORK_ORDER_MODEL_NAME } from '@/constants/recurringWorkOrders';

export default {
  name: 'RecurringWorkOrdersTable',
  components: {
    EditModal,
    FrequenciesModal,
  },
  mixins: [tablePropsMixin, translationMixin],
  props: {
    locationId: {
      type: Number,
      required: true,
    },
    columns: {
      type: Array,
      default: () => RECURRING_WORK_ORDER_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      monthOptions: [],
      frequencyOptions: [],
      modelName: RECURRING_WORK_ORDER_MODEL_NAME,
    };
  },
  mounted() {
    this.getMonthOptions();
    this.getFrequencyOptions();
  },
  methods: {
    getMonthOptions() {
      this.$recurringWorkOrdersAPI.getMonthOptions().then((response) => {
        this.monthOptions = response.data.month_options;
      });
    },
    getFrequencyOptions() {
      this.$recurringWorkOrdersAPI.getFrequencyOptions().then((response) => {
        this.frequencyOptions = response.data.frequency_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
      });
    },
    destroyItemDialog(id) {
      this.$swal({
        text: 'Once it\'s deleted, its gone forever!',
        preConfirm: () => this.$recurringWorkOrdersAPI.destroy(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
  },
};
</script>
