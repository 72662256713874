<template>
  <sfs-form-modal
    :id="modalId"
    title="Import Work Orders to Update"
    @on-hide="resetFormData"
  >
    <imports-form
      @on-submit="createUpdateWorkOrdersImport"
      @on-save="handleSave"
      @on-cancel="closeModal"
      :key="formKey"
    >
      <template #downloads>
        <b-row>
          <b-col cols="12">
            <sfs-download-button
              label="Download Template"
              filename="Work Orders to Update Template.xlsx"
              :file-provider="$importsAPI.getUpdateWorkOrdersTemplate"
            />
          </b-col>
        </b-row>
      </template>
    </imports-form>
  </sfs-form-modal>
</template>

<script>
import ImportsForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'UpdateWorkOrdersImportsAddModal',
  mixins: [formModalMixin],
  components: {
    ImportsForm,
  },
  data() {
    return {
      modalId: 'update-work-orders-imports-add-modal',
    };
  },
  methods: {
    createUpdateWorkOrdersImport(data, handleCreate) {
      handleCreate(this.$importsAPI.updateWorkOrdersCreate(data));
    },
    handleSave(data) {
      if (this.$route.name != 'imports') {
        this.$router.push({ name: 'imports' });
      } else {
        this.closeModal();
      }
      this.$emit('on-save', data);
    },
  },
};
</script>
