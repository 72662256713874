import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const VENDOR_TABLE_NAME = 'vendors';

// Vendor label constants
export const VENDOR_NAME_LABEL = 'Name';
export const VENDOR_ADDRESS_LABEL = 'Street';
export const VENDOR_CITY_LABEL = 'City';
export const VENDOR_STATE_LABEL = 'State';
export const VENDOR_ZIP_CODE_LABEL = 'Zip Code';
export const VENDOR_ACCOUNT_NUMBER_LABEL = 'Account Number';
export const VENDOR_ATTENTION_TO_LABEL = 'PO Attention To';
export const VENDOR_ORGANIZATION_LABEL = 'Organization';
export const VENDOR_FREIGHT_INVOICE_GL_CODE_LABEL = 'Freight GL Code';
export const VENDOR_LABOR_INVOICE_GL_CODE_LABEL = 'Labor GL Code';

export const VENDORS_FORM_LABELS = {
  name: VENDOR_NAME_LABEL,
  address: VENDOR_ADDRESS_LABEL,
  city: VENDOR_CITY_LABEL,
  state: VENDOR_STATE_LABEL,
  zip_code: VENDOR_ZIP_CODE_LABEL,
  account_number: VENDOR_ACCOUNT_NUMBER_LABEL,
  attention_to: VENDOR_ATTENTION_TO_LABEL,
  organization_id: VENDOR_ORGANIZATION_LABEL,
  freight_invoice_gl_code_id: VENDOR_FREIGHT_INVOICE_GL_CODE_LABEL,
  labor_invoice_gl_code_id: VENDOR_LABOR_INVOICE_GL_CODE_LABEL,
};

// The columns available to be shown in a vendors table.
export const VENDOR_TABLE_COLUMNS = {
  name: { label: VENDOR_NAME_LABEL, key: 'name', sortable: true },
  address: { label: VENDOR_ADDRESS_LABEL, key: 'address' },
  city: { label: VENDOR_CITY_LABEL, key: 'city', sortable: true },
  state: { label: VENDOR_STATE_LABEL, key: 'state', sortable: true },
  zip_code: { label: VENDOR_ZIP_CODE_LABEL, key: 'zip_code' },
  account_number: { label: VENDOR_ACCOUNT_NUMBER_LABEL, key: 'account_number' },
  attention_to: { label: VENDOR_ATTENTION_TO_LABEL, key: 'attention_to' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const VENDOR_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(VENDOR_TABLE_COLUMNS);

// The default columns to be shown when the vendors table loads.
export const VENDOR_TABLE_DEFAULT_COLUMNS = [
  VENDOR_TABLE_COLUMNS.name,
  VENDOR_TABLE_COLUMNS.address,
  VENDOR_TABLE_COLUMNS.city,
  VENDOR_TABLE_COLUMNS.state,
  VENDOR_TABLE_COLUMNS.account_number,
];
