<template>
  <!-- using autocomplete value "new-password" instead of "off" seems to disable autocomplete better in some browsers. -->
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :value="value"
    @search="onSearch"
    :filterable="false"
    @input="(workOrderCharge) => $emit('input', workOrderCharge)"
    autocomplete="new-password"
  >
    <template #option="option">
      <div>
        {{ option.work_order.number }}
      </div>
      <div class="font-size-sm text-secondary">
        {{ option.description }}
      </div>
    </template>
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        No results found for
        <em>{{ search }}</em>
      </template>
      <em
        style="opacity: 0.7"
        v-else
      >Type to search for charges</em>
    </template>
  </v-select>
</template>
<script>
import { debounce as _debounce } from 'lodash';

export default {
  name: 'WorkOrderChargesSelect',
  props: {
    value: Object,
    id: {
      type: String,
      default: 'work-order-charge',
    },
    selectLabel: {
      type: String,
      default: 'number',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    filterParams: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: String,
  },
  data() {
    return {
      options: [],
    };
  },
  methods: {
    onSearch(number, loading) {
      if (number.length) {
        loading(true);
        this.filterCharges(number, loading, this);
      }
    },
    filterCharges: _debounce((number, loading, vm) => {
      const params = {
        ...vm.filterParams,
        number: number,
      };
      vm.$workOrderChargesAPI.getCharges(params).then((response) => {
        vm.options = response.workOrderCharges.map((charge) => {
          return { number: charge.work_order.number, ...charge };
        });
        loading(false);
      });
    }, 500),
  },
};
</script>
