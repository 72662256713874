<template>
  <sfs-form-modal
    :id="modalId"
    :title="`Cancel Work Order ${workOrder.number}`"
    @on-hide="() => selectedOption = null"
  >
    <b-form
      novalidate
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col>
          <b-form-group label="Choose a reason for cancelling">
            <v-select
              id="advanced-filter-statuses"
              v-model="selectedOption"
              :options="cancellationOptions"
              :reduce="(reason) => reason.value"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="work-order-email-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="work-order-email-submit-button"
            block
            type="submit"
            variant="primary"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
export default {
  name: 'WorkOrdersCancelModal',
  props: {
    workOrder: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      cancellationOptions: [],
      selectedOption: null,
      modalId: 'work-order-cancel-modal',
    };
  },
  mounted() {
    this.getCancellationOptions();
  },
  methods: {
    async getCancellationOptions() {
        const response = await this.$workOrdersAPI.getCancellationOptions();
        const options = response.data.cancellation_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
        this.cancellationOptions = [...options];
    },
    async handleSubmit() {
      try {
        const updateResponse = await this.$workOrdersAPI.destroy(this.workOrder.id, { data: { cancellation_reason: this.selectedOption } });
        this.$emit('cancellation-reason-changed', updateResponse);
        this.closeModal();

      } catch (error) {
          throw new Error(error);
      }
    },
    closeModal() {
      this.selectedOption = null;
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
