<template>
  <sfs-form-modal
    :id="modalId"
    title="Email Work Order Profitability Report"
  >
    <b-form
      novalidate
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col cols="12">
          <h5>{{ fieldLabels.email }}</h5>
        </b-col>
        <b-col cols="12">
          <b-row
            v-for="(v, index) in $v.emails.$each.$iter"
            :key="index"
          >
            <b-col
              cols="10"
              class="pr-0"
            >
              <b-form-input
                id="work-order-profitability-email-email"
                class="mb-2"
                v-model.trim="v.email.$model"
                :state="!v.email.$error"
                autocomplete="new-password"
                type="text"
                placeholder="example@email.com"
              />
              <b-form-invalid-feedback
                v-if="v.email.$dirty"
                id="work-order-profitability-email-email-feedback"
                class="mb-3"
              >
                <span v-if="!v.email.required">Please enter an email. </span>
                <span v-if="!v.email.email">Please enter a valid email.</span>
                <span v-if="!v.email.serverFailed">{{ serverErrors.email }}</span>
              </b-form-invalid-feedback>
            </b-col>
            <b-col cols="2">
              <b-button
                class="w-75"
                @click="removeEmail(index)"
                variant="outline-danger"
              >
                <i class="fa fa-trash-can" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          offset="7"
          class="d-flex justify-content-end"
        >
          <b-button
            id="work-order-profitability-email-add-button"
            class="w-75 mt-1 mb-2"
            variant="link"
            @click="addEmail"
          >
            <span> <i class="fa fa-plus" /> Add Email</span>
          </b-button>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="work-order-profitability-email-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="work-order-profitability-email-submit-button"
            block
            type="submit"
            variant="primary"
            :disabled="!emails.length"
          >
            Send
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'WorkOrdersProfitabilityEmailModal',
  mixins: [formMixin, formModalMixin],
  props: {
    params: Object,
    email: String,
  },
  data() {
    return {
      emails: [{
        email: this.email
      }],
      fieldLabels: {
        email: 'Emails',
      },
      modalId: 'work-order-profitability-email-modal',
    };
  },
  validations: {
    emails: {
      required,
      minLength: minLength(1),
      $each: {
        email: {
          required,
          email,
          serverFailed() {
            return !this.hasServerErrors('email');
          }
        }
      }
    }
  },
  methods: {
    handleSubmit() {
      if (!this.emails.length) {
        return;
      }
      const emails = this.emails.map(item => item.email).filter(item => item !== '');
      this.$analyticsAPI
        .emailProfitabilityReport(emails, this.params)
        .then(() => {
          this.resetServerErrors();
          this.resetEmailModal();
        })
        .catch((error) => {
          if(error.response != undefined){
            this.processServerErrors(error.response.data.errors);
          } else {
            this.resetEmailModal();
          }
          //
        });
    },
    addEmail() {
      this.emails.push({ email: ''});
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    resetEmailModal() {
      this.$v.$reset();
      this.$emit('input');
      this.closeModal();
      this.emails = [];
    }
  },
};
</script>
