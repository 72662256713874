<template>
  <div class="content">
    <b-row class="justify-content-center">
      <b-col
        md="8"
        lg="6"
        xl="4"
      >
        <div class="p-sm-3 px-lg-4 py-lg-5">
          <div class="push text-center mb-0">
            <b-img
              :src="logo"
              height="320"
              :alt="$store.getters.appName"
            />

            <div class="font-size-sm mb-5">
              v{{ $appVersion }}
            </div>

            <p v-if="emailSent">
              If you have an account on our system, we have emailed you a link to reset your password. If it
              doesn’t appear within a few minutes, check your spam folder.
            </p>
            <p v-else>
              Enter your email address.
              <br>
              If your account exists, we will send you a link to reset your password.
            </p>
          </div>

          <b-alert
            class="mb-0"
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>

          <b-form
            v-if="!emailSent"
            @submit.stop.prevent="resetPassword"
          >
            <div>
              <b-form-group
                :label="fieldLabels.email"
                label-for="user-email"
              >
                <b-form-input
                  id="user-email"
                  class="form-control-dark"
                  v-model="$v.form.email.$model"
                  :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
                  type="text"
                  placeholder="name@company.com"
                />
                <b-form-invalid-feedback
                  v-if="$v.form.email.$dirty"
                  id="user-email-feedback"
                >
                  <span v-if="!$v.form.email.required">Please enter a email.</span>
                  <span v-if="!$v.form.email.email">Please enter a valid email.</span>
                  <span v-if="!$v.form.email.serverFailed">
                    {{ serverErrors.email }}
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <b-row class="form-group">
              <b-col>
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  <b-spinner
                    v-if="processing"
                    small
                    label="Logging In..."
                    class="mr-1"
                  />
                  <i
                    v-else
                    class="fa fa-envelope mr-1"
                  />
                  Send Email
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <b-button
            v-if="emailSent"
            class="push text-center"
            variant="primary"
            block
            :to="{ name: 'login' }"
          >
            Return to Login
          </b-button>
          <b-link
            v-else
            :to="{ name: 'login' }"
          >
            Back to Login
          </b-link>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import scopesLogo from '@/assets/images/logo.png';

export default {
  name: 'LoginForm',
  mixins: [formMixin],
  data() {
    return {
      form: {
        email: null,
      },
      fieldLabels: {
        email: 'Email',
      },
      emailSent: false,
      processing: false,
      logo: scopesLogo,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
        serverFailed() {
          return !this.hasServerErrors('email');
        },
      },
    },
  },
  methods: {
    resetPassword() {
      this.resetServerErrors();
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }

      this.processing = true;

      this.$authAPI
        .resetPassword(this.form)
        .then(() => {
          this.resetServerErrors();
          this.$v.$reset();
          this.emailSent = true;
          this.processing = false;
        })
        .catch((error) => {
          this.processing = false;
          this.processServerErrors(error?.response?.data?.errors);
        });
    },
  },
};
</script>
