<template>
  <sfs-base-table
    :data-provider="$insuranceCertificatesAPI.getInsuranceCertificates"
    provider-key="insuranceCertificates"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(policy_number)="data">
      <b-link :to="{ name: 'insurance-certificate', params: { id: data.item.id } }">
        {{ data.item.policy_number }}
      </b-link>
      <div class="font-size-sm text-secondary">
        {{ data.item.category | humanizedFormat | capitalizeFormat }}
      </div>
    </template>
    <template #cell(status)="data">
      <status-badge
        :status="data.item.status"
        :status-variants="statusVariants"
      />
    </template>
    <template #cell(state)="data">
      {{ data.item.user.state }}
    </template>
    <template #cell(user_id)="data">
      <b-link :to="{ name: 'user', params: { id: data.item.user_id } }">
        {{ data.item.user ? data.item.user.name : "" }}
      </b-link>
      <div
        v-if="data.item.user"
        class="font-size-sm text-secondary"
      >
        <b-link :href="`mailto:${data.item.user.email}`">
          {{ data.item.user.email }}
        </b-link>
      </div>
    </template>
    <template #cell(end_date)="data">
      <div class="mb-1">
        {{ data.item.current_coverage.end_date | dateFormat }}
      </div>
      <b-badge
        variant="success"
        class="font-size-sm"
        v-if="data.item.current_coverage.verified"
      >
        Verified
      </b-badge>
      <b-badge
        variant="secondary"
        class="font-size-sm"
        v-else
      >
        Not Verified
      </b-badge>
    </template>
    <template #cell(insurance_contact)="data">
      <div v-if="data.item.email">
        <b-link :href="`mailto:${data.item.email}`">
          {{ data.item.email }}
        </b-link>
      </div>
      <div v-if="data.item.phone">
        <b-link
          class="font-size-sm text-secondary"
          :href="`tel:${data.item.phone}`"
        >
          {{ data.item.phone }}
        </b-link>
      </div>
      <span />
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import { INSURANCE_CERTIFICATE_STATUS_VARIANTS, INSURANCE_CERTIFICATE_TABLE_DEFAULT_COLUMNS } from '@/constants/insuranceCertificates';
import StatusBadge from '@/components/shared/StatusBadge.vue';

export default {
  name: 'InsuranceCertificatesTable',
  mixins: [tablePropsMixin],
  components: {
    StatusBadge,
  },
  props: {
    columns: {
      type: Array,
      default: () => INSURANCE_CERTIFICATE_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      statusVariants: INSURANCE_CERTIFICATE_STATUS_VARIANTS,
    };
  },
};
</script>
