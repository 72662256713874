import List from '@/pages/admin/glCodes/List.vue';
import handleActivePaginationRouteProps from '@/utils/handleActivePaginationRouteProps.js';

export default [
  {
    path: 'gl_codes',
    name: 'gl-codes',
    component: List,
    props: handleActivePaginationRouteProps,
  },
];
