<template>
  <v-select
    :id="id"
    :options="filteredOptions"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :value="value"
    @search="onSearch"
    :filterable="false"
    @input="(status) => $emit('input', status)"
  >
    <template #option="status">
      <div class="font-w500">
        <span>{{ status.name }}</span>
      </div>
    </template>
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        No results found for
        <em>{{ search }}</em>
      </template>
      <em
        style="opacity: 0.7"
        v-else
      >Type to search for statuses</em>
    </template>
    <template #selected-option="status">
      {{ status.name }}
    </template>
  </v-select>
</template>
<script>
import { debounce as _debounce, filter as _filter } from 'lodash';

export default {
  name: 'DollarGeneralFexaAssignmentStatusSelect',
  props: {
    value: Object,
    integrationReferenceId: {
      type: Number,
      required: true,
    },
    initialStatus: {
      type: Object,
    },
    id: {
      type: String,
      default: 'dollar-general-fexa-assignment-status-select',
    },
    selectLabel: {
      type: String,
      default: 'name',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: String,
  },
  data() {
    return {
      options: [],
      filteredOptions: [],
    };
  },
  mounted() {
    this.getRecordOptions();
  },
  methods: {
    getRecordOptions() {
      this.$integrationReferencesAPI.getAssignmentStatusOptions(this.integrationReferenceId).then((response) => {
        const statuses = response.statuses;
        if (this.initialStatus) {
          statuses.unshift(this.initialStatus);
        }
        this.options = statuses;
        this.filteredOptions = statuses;
      });
    },
    onSearch(text, loading) {
      if (text.length) {
        loading(true);
        this.filterRecords(text, loading, this);
      }
    },
    filterRecords: _debounce((text, loading, vm) => {
      const regex = new RegExp(`${text}`, 'ig');
      vm.filteredOptions = _filter(vm.options, (option) => (option.name.match(regex)));
      loading(false);
    }, 500),
  },
};
</script>