<template>
  <sfs-base-list-filter
    title="Filter Technician Payments"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
  >
    <template #quick-filters>
      <b-form-group
        label="Search fields for Data"
        label-for="quick-filter-user_id"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Number, Technician Name or Email"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #advanced-filters>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="User"
            label-for="advanced-filter-user_id"
            label-sr-only
          >
            <user-select
              id="advanced-filter-user_id"
              placeholder="Select a User"
              :value="user"
              @input="onUserChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            class="mb-0"
            label="Paid From"
            label-for="advanced-filter-date_from"
            label-sr-only
            content-cols-lg="12"
          >
            <sfs-date-picker
              id="advanced-filter-date_from"
              v-model="filters.date_from"
              placeholder="Pay Period From"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            class="mb-0"
            label="Paid To"
            label-for="advanced-filter-date_to"
            label-sr-only
            content-cols-lg="12"
          >
            <sfs-date-picker
              id="advanced-filter-date_to"
              v-model="filters.date_to"
              placeholder="Pay Period To"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Manager"
            label-for="advanced-filter-manager_id"
            label-sr-only
          >
            <employee-select
              id="advanced-filter-manager_id"
              placeholder="Manager"
              :value="manager"
              @input="onManagerChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            class="mb-0"
            label="Due Date"
            label-for="advanced-filter-due_date"
            label-sr-only
          >
            <sfs-date-picker
              id="advanced-filter-due_date"
              v-model="filters.due_date"
              placeholder="Due Date"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Technician Paid or Unpaid"
            label-for="advanced-filter-emailed"
            label-sr-only
          >
            <b-form-radio-group
              id="advanced-filter-emailed"
              v-model="filters.emailed"
              :options="emailedOptions"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Approved or Not Approved"
            label-for="advanced-filter-approved"
            label-sr-only
          >
            <b-form-radio-group
              id="advanced-filter-approved"
              v-model="filters.approved"
              :options="approvedOptions"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Payment Finalized or Not"
            label-for="advanced-filter-finalized"
            label-sr-only
          >
            <b-form-radio-group
              id="advanced-filter-finalized"
              v-model="filters.finalized"
              :options="finalizedOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import EmployeeSelect from '@/components/admin/users/EmployeeSelect.vue';
import UserSelect from '@/components/admin/users/Select.vue';
import {
  TECHNICIAN_PAYMENT_TABLE_COLUMN_OPTIONS,
  TECHNICIAN_PAYMENT_TABLE_DEFAULT_COLUMNS,
  TECHNICIAN_PAYMENT_TABLE_EMAILED_FILTER_OPTIONS,
  TECHNICIAN_PAYMENT_TABLE_APPROVED_FILTER_OPTIONS,
  TECHNICIAN_PAYMENT_TABLE_FINALIZED_FILTER_OPTIONS,
  TECHNICIAN_PAYMENT_TABLE_NAME,
} from '@/constants/technicianPayments';
import listFilterMixin from '@/mixins/listFilterMixin';

export default {
  name: 'TechnicianPaymentsListFilter',
  mixins: [listFilterMixin],
  components: {
    UserSelect,
    EmployeeSelect,
  },
  data() {
    return {
      availableColumns: TECHNICIAN_PAYMENT_TABLE_COLUMN_OPTIONS,
      emailedOptions: TECHNICIAN_PAYMENT_TABLE_EMAILED_FILTER_OPTIONS,
      approvedOptions: TECHNICIAN_PAYMENT_TABLE_APPROVED_FILTER_OPTIONS,
      finalizedOptions: TECHNICIAN_PAYMENT_TABLE_FINALIZED_FILTER_OPTIONS,
      tableName: TECHNICIAN_PAYMENT_TABLE_NAME,
    };
  },
  mounted() {
    this.loadInitialFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.user = null;
      this.displayedColumns = TECHNICIAN_PAYMENT_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    onUserChange(user) {
      this.filters.user_id = user ? user.id.toString() : '';
      this.user = user;
    },
    onManagerChange(manager) {
      this.filters.manager_id = manager ? manager.id.toString() : '';
      this.manager = manager;
    },
    loadInitialFilters() {
      const loadUser = new Promise((resolve) => {
        if (this.initialFilters.user_id) {
          this.$usersAPI.get(this.initialFilters.user_id).then((user) => {
            resolve(user);
          });
        } else {
          resolve(null);
        }
      });

      const loadManager = new Promise((resolve) => {
        if (this.initialFilters.manager_id) {
          this.$usersAPI.get(this.initialFilters.manager_id).then((user) => {
            resolve(user);
          });
        } else {
          resolve(null);
        }
      });

      Promise.all([loadUser, loadManager]).then((values) => {
        this.user = values[0];
        this.manager = values[1];
        this.applyFilters();
      });
    },
  },
};
</script>