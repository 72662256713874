<template>
  <!-- using autocomplete value "new-password" instead of "off" seems to disable autocomplete better in some browsers. -->
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :disabled="disabled"
    :value="value"
    :filterable="false"
    @input="onChange"
    autocomplete="new-password"
  >
    <template #option="option">
      <div>{{ option.name }}</div>
      <div class="font-size-sm text-secondary">
        {{ option.email }}
      </div>
    </template>
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        No results found for
        <em>{{ search }}</em>
      </template>
      <em
        style="opacity: 0.7"
        v-else
      >Type to search for users</em>
    </template>
  </v-select>
</template>
<script>

export default {
  name: 'TechnicianUsersSelect',
  props: {
    value: Object,
    id: {
      type: String,
      default: () => 'user',
    },
    primaryOption: Object,
    managerId: {
      type: Number,
    },
    selectLabel: {
      type: String,
      default: () => 'name',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    filterParams: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: String,
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getOptions();
  },
  methods: {
    getOptions() {
      this.$technicianUsersAPI.getUsers({ per_page: 100, active: true }).then((response) => {
          this.options = response.users;
          if (this.primaryOption) {
            this.options.unshift(this.primaryOption);
          }
        });
    },
    onChange(user) {
      this.$emit('input', user);
    },
  },
};
</script>
