import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const TAX_RATE_TABLE_NAME = 'taxRates';

// The columns available to be shown in a taxRates table.
export const TAX_RATE_TABLE_COLUMNS = {
  name: { label: 'Name', key: 'name', sortable: true },
  code: { label: 'Code', key: 'code', sortable: true },
  rate: { label: 'Rate', key: 'rate' },
  city: { label: 'City', key: 'city', sortable: true },
  county: { label: 'County', key: 'county', sortable: true },
  state: { label: 'State', key: 'state', sortable: true },
  charge_categories: { label: 'Charge Categories', key: 'charge_categories' },
  service_categories: { label: 'Service Categories', key: 'service_categories' },
  deleted_at: { label: 'Active', key: 'active' },
  actions: { label: '', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const TAX_RATE_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(TAX_RATE_TABLE_COLUMNS);
// The default columns to be shown when the taxRates table loads.
export const TAX_RATE_TABLE_DEFAULT_COLUMNS = [
  TAX_RATE_TABLE_COLUMNS.name,
  TAX_RATE_TABLE_COLUMNS.code,
  TAX_RATE_TABLE_COLUMNS.city,
  TAX_RATE_TABLE_COLUMNS.county,
  TAX_RATE_TABLE_COLUMNS.state,
  TAX_RATE_TABLE_COLUMNS.charge_categories,
  TAX_RATE_TABLE_COLUMNS.service_categories,
  TAX_RATE_TABLE_COLUMNS.deleted_at,
  TAX_RATE_TABLE_COLUMNS.actions,
];
