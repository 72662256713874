export const INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_NAME = 'insuranceCertificateVerifications';

// Document table constants

// The columns available to be shown in a documents table.
export const INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_COLUMNS = {
  audited_at: { label: 'Audited At', key: 'audited_at' },
  user_id: { label: 'Audited By', key: 'user_id' },
};

export const INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_DEFAULT_COLUMNS = [
  INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_COLUMNS.user_id,
  INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_COLUMNS.audited_at,
];
