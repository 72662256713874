// Document table constants
export const DOCUMENTS_TABLE_NAME = 'documents';

// The columns available to be shown in a documents table.
export const DOCUMENTS_TABLE_COLUMNS = {
  file: { label: 'File', key: 'file' },
  notes: { label: 'Notes', key: 'notes' },
  internal: { label: 'Visibility', key: 'internal' },
  created_at: { label: 'Created', key: 'created_at' },
  actions: { label: 'Actions', key: 'actions' },
};

export const DOCUMENTS_TABLE_DEFAULT_COLUMNS = [
  DOCUMENTS_TABLE_COLUMNS.file,
  DOCUMENTS_TABLE_COLUMNS.notes,
  DOCUMENTS_TABLE_COLUMNS.internal,
  DOCUMENTS_TABLE_COLUMNS.created_at,
  DOCUMENTS_TABLE_COLUMNS.actions,
];
