<template>
  <sfs-base-list-filter
    title="Filter Services"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="translatedColumns(modelName, availableColumns)"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
  >
    <template #quick-filters>
      <b-form-group
        label="Search fields for Data"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Name or Description"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Client"
            label-for="advanced-filter-client_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <client-select
              id="advanced-filter-client_id"
              placeholder="All"
              :value="client"
              @input="onClientChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Service Name"
            label-for="advanced-filter-name"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-input
              id="advanced-filter-name"
              v-model="filters.name"
              type="text"
              placeholder="All"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Description"
            label-for="advanced-filter-description"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-input
              id="advanced-filter-description"
              v-model="filters.description"
              type="text"
              placeholder="All"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Category"
            label-for="advanced-filter-category"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <category-select
              id="advanced-filter-category"
              placeholder="All"
              v-model="filters.category"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Days Between Service"
            label-for="advanced-filter-minimum_days_between_service"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-input
              id="advanced-filter-minimum_days_between_service"
              v-model="filters.minimum_days_between_service"
              type="number"
              placeholder="All"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Active or Inactive"
            label-for="advanced-filter-active"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-radio-group
              id="advanced-filter-active"
              v-model="filters.active"
              :options="activeOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import ClientSelect from '@/components/admin/clients/Select.vue';
import CategorySelect from '@/components/admin/services/CategorySelect.vue';
import { SERVICE_TABLE_COLUMN_OPTIONS, SERVICE_TABLE_DEFAULT_COLUMNS, SERVICE_TABLE_NAME, SERVICE_MODEL_NAME } from '@/constants/services';
import listFilterMixin from '@/mixins/listFilterMixin';
import translationMixin from '@/mixins/translationMixin';

export default {
  name: 'ServicesListFilter',
  mixins: [listFilterMixin, translationMixin],
  components: {
    ClientSelect,
    CategorySelect,
  },
  data() {
    return {
      availableColumns: SERVICE_TABLE_COLUMN_OPTIONS,
      tableName: SERVICE_TABLE_NAME,
      modelName: SERVICE_MODEL_NAME,
    };
  },
  mounted() {
    this.loadInitialFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = SERVICE_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    onClientChange(client) {
      this.filters.client_id = client ? client.id.toString() : '';
      this.client = client;
    },
    loadInitialFilters() {
      const loadClient = new Promise((resolve) => {
        if (this.initialFilters.client_id) {
          this.$clientsAPI.get(this.initialFilters.client_id).then((client) => {
            resolve(client);
          });
        } else {
          resolve(null);
        }
      });

      // The order of the resolved promises is preserved so we are able to use
      // indexes to fetch the data.
      Promise.all([loadClient]).then((values) => {
        this.client = values[0];
        this.applyFilters();
      });
    },
  },
};
</script>
