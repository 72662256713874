export default {
  data() {
    return {
      formKey: Date.now(),
      modalId: `default-modal-${Date.now()}`,
    };
  },
  methods: {
    resetFormData() {
      this.formKey = Date.now();
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
      this.resetFormData();
    },
  },
};
