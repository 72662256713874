<template>
  <sfs-base-list-filter
    title="Filter Order Items"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :column-picker="false"
    :advanced-filter="false"
  >
    <template #quick-filters>
      <b-form-group
        label="Search fields for Data"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Description or Item Number"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #right-tray>
      <b-button
        v-if="$can('create', 'SupplyOrderItem')"
        v-b-modal="`supply-order-item-add-modal`"
        class="ml-2"
        variant="primary"
      >
        <i class="fa fa-plus mr-1" />
        New Item
      </b-button>
      <sfs-download-button
        v-b-tooltip.hover
        class="ml-2"
        filename="Supply Order Items.csv"
        :file-provider="getItemsSpreadsheet"
        title="Download Spreadsheet"
      />
      <!-- Modals -->
      <new-modal
        v-if="$can('create', 'SupplyOrderItem')"
        :supply_order_id="supplyOrder.id"
        :vendor_id="supplyOrder.vendor_id"
        @on-save="refreshTable"
      />
    </template>
  </sfs-base-list-filter>
</template>
<script>
import NewModal from '@/components/admin/supplyOrderItems/NewModal.vue';
import { SUPPLY_ORDER_ITEM_TABLE_COLUMN_OPTIONS, SUPPLY_ORDER_ITEM_TABLE_DEFAULT_COLUMNS, SUPPLY_ORDER_ITEM_TABLE_NAME, } from '@/constants/supplyOrderItems';
import listFilterMixin from '@/mixins/listFilterMixin';

export default {
  name: 'SupplyOrderItemsListFilter',
  mixins: [listFilterMixin],
  components: {
    NewModal,
  },
  props: {
    supplyOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      availableColumns: SUPPLY_ORDER_ITEM_TABLE_COLUMN_OPTIONS,
      tableName: SUPPLY_ORDER_ITEM_TABLE_NAME,
    };
  },
  mounted() {
    this.applyFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = SUPPLY_ORDER_ITEM_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    getItemsSpreadsheet() {
      return this.$supplyOrdersAPI.getItemsSpreadsheet(this.supplyOrder.id);
    },
    refreshTable() {
      this.$emit('data-changed');
    },
  },
};
</script>
