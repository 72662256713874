import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const SUPPLY_ORDER_MODEL_NAME = 'supply_orders';
export const SUPPLY_ORDER_TABLE_NAME = 'supplyOrders';

// Vendor label constants
export const SUPPLY_ORDER_NUMBER_LABEL = 'Supply PO';
export const SUPPLY_ORDER_DATE_LABEL = 'Date';
export const SUPPLY_ORDER_VENDOR_NUMBER_LABEL = 'Vendor Invoice Number';
export const SUPPLY_ORDER_CONFIRMED_AT_LABEL = 'Confirmed At';
export const SUPPLY_ORDER_VENDOR_ID_LABEL = 'Vendor';
export const SUPPLY_ORDER_CLIENT_ID_LABEL = 'Client';
export const SUPPLY_ORDER_LOCATION_ID_LABEL = 'Location';
export const SUPPLY_ORDER_DELIVERY_NOTES_LABEL = 'Delivery Notes / Special Instructions';

export const SUPPLY_ORDERS_FORM_LABELS = {
  number: SUPPLY_ORDER_NUMBER_LABEL,
  vendor_number: SUPPLY_ORDER_VENDOR_NUMBER_LABEL,
  date: SUPPLY_ORDER_DATE_LABEL,
  confirmed_at: SUPPLY_ORDER_NUMBER_LABEL,
  client_id: SUPPLY_ORDER_CLIENT_ID_LABEL,
  vendor_id: SUPPLY_ORDER_VENDOR_ID_LABEL,
  delivery_notes: SUPPLY_ORDER_DELIVERY_NOTES_LABEL,
};

// The columns available to be shown in a supplyOrders table.
export const SUPPLY_ORDER_TABLE_COLUMNS = {
  number: { label: SUPPLY_ORDER_NUMBER_LABEL, key: 'number', sortable: true },
  date: { label: SUPPLY_ORDER_DATE_LABEL, key: 'date', sortable: true },
  vendor_number: { label: SUPPLY_ORDER_VENDOR_NUMBER_LABEL, key: 'vendor_number' },
  confirmed_at: { label: SUPPLY_ORDER_CONFIRMED_AT_LABEL, key: 'confirmed_at' },
  vendor_id: { label: SUPPLY_ORDER_VENDOR_ID_LABEL, key: 'vendor_id' },
  client_id: { label: SUPPLY_ORDER_CLIENT_ID_LABEL, key: 'client_id' },
  location_id: { label: SUPPLY_ORDER_LOCATION_ID_LABEL, key: 'location_id' },
  delivery_notes: { label: SUPPLY_ORDER_DELIVERY_NOTES_LABEL, key: 'delivery_notes' },
  cost: { label: 'Total Cost', key: 'cost' },
  price: { label: 'Total', key: 'price' },
  status: { label: 'Status', key: 'status' },
  backordered: { label: 'Backordered', key: 'backordered' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const SUPPLY_ORDER_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(SUPPLY_ORDER_TABLE_COLUMNS);

// The default columns to be shown when the supplyOrders table loads.
export const SUPPLY_ORDER_TABLE_DEFAULT_COLUMNS = [
  SUPPLY_ORDER_TABLE_COLUMNS.number,
  SUPPLY_ORDER_TABLE_COLUMNS.status,
  SUPPLY_ORDER_TABLE_COLUMNS.date,
  SUPPLY_ORDER_TABLE_COLUMNS.price,
  SUPPLY_ORDER_TABLE_COLUMNS.location_id,
  SUPPLY_ORDER_TABLE_COLUMNS.vendor_id,
];

// The available work order statuses.
export const SUPPLY_ORDER_STATUS_PENDING = 'pending';
export const SUPPLY_ORDER_STATUS_CONFIRMED = 'confirmed';
export const SUPPLY_ORDER_STATUS_INVOICED = 'invoiced';

export const SUPPLY_ORDER_STATUS_VARIANTS = {
  [SUPPLY_ORDER_STATUS_PENDING]: 'primary',
  [SUPPLY_ORDER_STATUS_CONFIRMED]: 'success',
  [SUPPLY_ORDER_STATUS_INVOICED]: 'purple',
};

export const SUPPLY_ORDER_TABLE_BACKORDERED_FILTER_OPTIONS = [
  { text: 'Backordered', value: 'true' },
  { text: 'Not Backordered', value: 'false' },
  { text: 'Both', value: null },
];
