<template>
  <sfs-form-modal
    :id="modalId"
    :title="title"
    @on-hide="resetFormData"
  >
    <user-ownerships-form
      :is-sub-technician="isSubTechnician"
      :technician_id="technician_id"
      :technician="technician"
      :sub_technician_id="sub_technician_id"
      :sub_technician="sub_technician"
      @on-submit="createUserOwnership"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import UserOwnershipsForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'UserOwnershipsAddModal',
  mixins: [formModalMixin],
  components: {
    UserOwnershipsForm,
  },
  props: {
    isSubTechnician: {
      type: Boolean,
      default: false,
    },
    technician_id: Number,
    technician: Object,
    sub_technician_id: Number,
    sub_technician: Object,
  },
  data() {
    return {
      modalId: 'user-ownerships-add-modal',
    };
  },
  methods: {
    createUserOwnership(data, handleCreate) {
      handleCreate(this.$userOwnershipsAPI.create(data));
    },
    onSave() {
      this.closeModal();
      this.$emit('save');
    },
  },
  computed: {
    title() {
      return this.isSubTechnician ? 'Add a Managing Technician' : 'Add a Sub Technician';
    },
  },
};
</script>
