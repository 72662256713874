<template>
  <sfs-form-modal
    :id="modalId"
    title="Start a Walkthrough"
    @on-hide="resetFormData"
  >
    <walkthroughs-form
      @on-submit="createWalkthrough"
      @on-save="redirectToWalkthrough"
      @on-cancel="closeModal"
      :key="formKey"
      :date="new Date().toISOString()"
      :user="user"
      :user_id="userId"
      :client="client"
      :client_id="clientId"
    />
  </sfs-form-modal>
</template>

<script>
import WalkthroughsForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'WalkthroughsAddModal',
  mixins: [formModalMixin],
  components: {
    WalkthroughsForm,
  },
  props: {
    user: Object,
    client: Object,
  },
  data() {
    return {
      modalId: 'walkthrough-add-modal',
    };
  },
  methods: {
    createWalkthrough(data, handleCreate) {
      handleCreate(this.$walkthroughsAPI.create(data));
    },
    redirectToWalkthrough(walkthrough) {
      this.$router.push({ name: 'walkthrough', params: { id: walkthrough.id } });
    },
  },
  computed: {
    userId() {
      return this.user?.id;
    },
     clientId() {
      return this.client?.id;
    },
  },
};
</script>
