<template>
  <div>
    Notes
    <b-badge
      v-if="total > 0"
      class="ml-2 notes-badge rounded-pill"
      variant="danger"
    >
      {{ total > 10 ? '10+' : total }}
    </b-badge>
  </div>
</template>

<script>

export default {
  name: 'NotesTimelineBadge',
  props: {
    total: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style scoped>
.notes-badge {
  font-size: 0.75rem;
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  vertical-align: text-top;
}
</style>
