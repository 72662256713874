<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          v-if="isLoaded"
          ref="work-orders-profitability-table"
          :items="records"
          :fields="translatedColumns(modelName, columns)"
          :busy="isLoadingData"
          :sort-by.sync="sorting.sortBy"
          :sort-desc.sync="sorting.sortDesc"
          @sort-changed="handleSortingChanged"
          no-local-sorting
          show-empty
          responsive
          table
        >
          <template #cell(number)="data">
            <div>
              <b-link :to="{ name: 'work-order', params: { id: data.item.id } }">
                {{ data.item.number }}
              </b-link>
            </div>
            <b-link
              v-if="data.item.service"
              class="font-size-sm"
              :to="{ name: 'service', params: { id: data.item.service.id } }"
            >
              {{ data.item.service.name }}
            </b-link>
            <div class="font-size-sm">
              {{ data.item.status | humanizedFormat | capitalizeFormat }}
            </div>
          </template>
          <template #cell(location_id)="data">
            <b-link :to="{ name: 'location', params: { id: data.item.location_id } }">
              {{ data.item.location.store_number }}
            </b-link>
            <div class="text-nowrap font-size-sm">
              {{ { city: data.item.location.city, state: data.item.location.state } | locationFormat }}
            </div>
            <div class="text-nowrap font-size-sm">
              <b-link :to="{ name: 'client', params: { id: data.item.client.id } }">
                {{ data.item.client.name }}
              </b-link>
            </div>
          </template>
          <template #cell(square_footage)="data">
            <div v-if="data.item.location.stock_room_footage">
              Stock Room: {{ data.item.location.stock_room_footage }} sq. ft
            </div>
            <div v-if="data.item.location.sales_floor_footage">
              Sales Floor: {{ data.item.location.sales_floor_footage }} sq. ft
            </div>
          </template>
          <template #cell(assigned_to_id)="data">
            <b-link
              v-if="data.item.assigned_to"
              :to="{ name: 'user', params: { id: data.item.assigned_to.id } }"
            >
              {{ data.item.assigned_to.name }}
            </b-link>
            <div
              v-if="data.item.done_by && data.item.done_by_id != data.item.assigned_to_id"
              class="font-size-sm"
            >
              <b-link :to="{ name: 'user', params: { id: data.item.done_by.id } }">
                {{ data.item.done_by.name }}
              </b-link>
            </div>
            <div class="text-nowrap font-size-sm">
              {{ data.item | dateRangeFormat("date_bucket_start", "date_bucket_end") }}
            </div>
          </template>
          <template #cell(manager_id)="data">
            <b-link
              v-if="data.item.manager"
              :to="{ name: 'user', params: { id: data.item.manager.id } }"
            >
              {{ data.item.manager.name }}
            </b-link>
          </template>
          <template #cell(margin_percent)="data">
            <span>{{ marginPercenatage(data.item) }}%</span>
          </template>
          <template #cell(margin)="data">
            <div class="d-flex justify-content-center">
              <money-format
                :id="`work-order-margin-${data.item.id}`"
                :class="marginClass(data.item)"
                style="width: fit-content;"
                :value="data.item.price.amount - data.item.cost.amount"
                :currency-code="data.item.cost.currency"
                subunits-value
              />
              <b-popover
                boundary="window"
                :target="`work-order-margin-${data.item.id}`"
                triggers="hover"
                placement="top"
              >
                <div class="text-left">
                  <b-row>
                    <b-col class="font-w600 mr-2">
                      Price
                    </b-col>
                    <b-col>
                      <money-format
                        class="text-right"
                        :value="data.item.price.amount"
                        :currency-code="data.item.price.currency"
                        subunits-value
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="font-w600 mr-2">
                      Cost
                    </b-col>
                    <b-col>
                      <money-format
                        class="text-right"
                        :value="data.item.cost.amount"
                        :currency-code="data.item.cost.currency"
                        subunits-value
                      />
                    </b-col>
                  </b-row>
                  <hr class="mt-1 mb-1">
                  <b-row>
                    <b-col class="font-w600 mr-2">
                      Profit
                    </b-col>
                    <b-col>
                      <money-format
                        :class="marginAmountClass(data.item)"
                        :value="data.item.price.amount - data.item.cost.amount"
                        :currency-code="data.item.cost.currency"
                        subunits-value
                      />
                    </b-col>
                  </b-row>
                </div>
              </b-popover>
            </div>
          </template>
        </b-table>
        <sfs-skeleton-table
          v-else
          :rows="pagination.perPage"
          :columns="columns.length"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="auto"
        :class="{ 'mr-auto': true, 'ml-auto': !showPerPage }"
      >
        <b-pagination
          v-if="showPagination"
          v-model="pagination.currentPage"
          :total-rows="pagination.maxResults > totalRows ? totalRows : pagination.maxResults"
          :per-page="pagination.perPage"
          last-class="d-none"
        />
      </b-col>
      <sfs-pagination-dropdown
        v-if="showPerPage"
        container-class="col-auto"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="totalRows"
        @per-page-changed="onPerPageChanged"
      />
    </b-row>
  </div>
</template>

<script>
import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';
import translationMixin from '@/mixins/translationMixin';
import { WORK_ORDER_PROFITABILITY_REPORT_TABLE_DEFAULT_COLUMNS } from '@/constants/reports';
import { WORK_ORDER_MODEL_NAME } from '@/constants/workOrders';

export default {
  name: 'WorkOrdersTable',
  mixins: [urlMixin, tableMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: WORK_ORDER_PROFITABILITY_REPORT_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      modelName: WORK_ORDER_MODEL_NAME,
    };
  },
  methods: {
    getRecords() {
      this.isLoadingData = true;
      this.$workOrdersAPI
        .getWorkOrders(this.params)
        .then((response) => {
          const { total, workOrders } = response;
          this.totalRows = total;
          this.records = workOrders;
        })
        .catch(() => {
          this.records = [];
        })
        .finally(() => {
          this.loaded = true;
          this.isLoadingData = false;
          this.$emit('data-changed', this.records);
        });
    },
    positiveMargin(workOrder) {
      return (workOrder.price.amount - workOrder.cost.amount) > 0;
    },
    negativeMargin(workOrder) {
      return (workOrder.price.amount - workOrder.cost.amount) <= 0;
    },
    marginClass(workOrder) {
      return {
        'px-2': true,
        'py-1': true,
        'font-size-sm': true,
        'font-w600': true,
        'text-danger': this.negativeMargin(workOrder),
        'bg-danger-light': this.negativeMargin(workOrder),
        'text-success': this.positiveMargin(workOrder),
        'bg-success-light': this.positiveMargin(workOrder),
      };
    },
    marginAmountClass(workOrder) {
      return {
        'font-w600': true,
        'd-inline': true,
        'text-danger': this.negativeMargin(workOrder),
        'bg-danger-light': this.negativeMargin(workOrder),
        'text-success': this.positiveMargin(workOrder),
        'bg-success-light': this.positiveMargin(workOrder),
      };
    },
  },
  computed: {
    marginPercenatage() {
      return obj => {
        let margin = obj.price.amount - obj.cost.amount;
        const priceAmount = obj.price.amount;

        if (margin <= 0) {
          margin =  0;
        } else {
          margin =  ((margin / priceAmount) * 100);
        }

        if (Number.isInteger(margin)) {
          return margin.toFixed(0);
        } else {
          return margin.toFixed(2);
        }
      };
    }
  }

};
</script>
