<template>
  <v-select
    :id="id"
    :options="filteredOptions"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :value="value"
    @search="onSearch"
    :filterable="false"
    @input="onChange"
    :disabled="disabled"
  >
    <template #option="client">
      <div>{{ client.name }}</div>
      <div class="font-size-sm text-secondary">
        {{ client.code }}
      </div>
    </template>
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        No results found for
        <em>{{ search }}</em>
      </template>
      <em
        style="opacity: 0.7"
        v-else
      >Type to search for clients</em>
    </template>
    <template #selected-option="client">
      {{ client.name }} <span
        v-if="showClientCode"
      >&nbsp;({{ client.code }})</span>
    </template>
  </v-select>
</template>
<script>
import { SET_CLIENT_MUTATION } from '@/constants/mutations.js';
import { debounce as _debounce, filter as _filter } from 'lodash';

export default {
  name: 'ClientsSelect',
  props: {
    value: Object,
    id: {
      type: String,
      default: 'client',
    },
    selectLabel: {
      type: String,
      default: 'name',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    locationId: {
      type: [Number, String],
    },
    persistState: {
      type: Boolean,
      default: false,
    },
    showClientCode: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: String,
  },
  data() {
    return {
      options: [],
      filteredOptions: [],
    };
  },
  mounted() {
    this.getClientOptions();
  },
  methods: {
    getClientOptions() {
      this.$store.cache.dispatch('getClients', { active: true, paginate: false, organization_id: this.organizationId, location_id: this.locationId }).then((clients) => {
        this.options = clients;
        this.filteredOptions = clients;
      });
    },
    onChange(client) {
      if (this.persistState) {
        this.$store.commit(SET_CLIENT_MUTATION, { client: client });
      }
      this.$emit('input', client);
    },
    onSearch(text, loading) {
      if (text.length) {
        loading(true);
        this.filterClients(text, loading, this);
      }
    },
    filterClients: _debounce((text, loading, vm) => {
      const regex = new RegExp(`${text}`, 'ig');
      vm.filteredOptions = _filter(vm.options, (option) => (option.code.match(regex) || option.name.match(regex)));
      loading(false);
    }, 500),
  },
  computed: {
    organizationId() {
      return this.$store.getters.currentOrganization?.id;
    },
  }
};
</script>
