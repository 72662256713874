<template>
  <v-select
    :id="id"
    :options="options"
    :multiple="multiple"
    :class="selectClass"
    :placeholder="placeholder"
    :value="value"
    @input="onChange"
    :reduce="(category) => category.value"
  />
</template>
<script>
export default {
  name: 'CategorySelect',
  props: {
    value: [String, Array],
    id: {
      type: String,
      default: 'charge-category-select',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: {
      type: String,
      default: 'Select a Category',
    },
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getCategoryOptions();
  },
  methods: {
    getCategoryOptions() {
      this.$store.cache.dispatch('getWorkOrderChargeCategories').then((categoryOptions) => {
        this.options = categoryOptions.map((option) => {
          return { label: option[0], value: option[1] };
        });
      });
    },
    onChange(category) {
      this.$emit('input', category);
    },
  },
};
</script>
