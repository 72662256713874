<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col
        v-if="newForm"
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.name"
          label-for="tax-rate-name"
        >
          <b-form-input
            name="tax-rate-name"
            v-model="$v.form.name.$model"
            :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.name.$dirty"
            class="tax-rate-name-feedback"
          >
            <span v-if="!$v.form.name.required">Please enter a name.</span>
            <span v-if="!$v.form.name.maxLength">
              The name must be less than {{ $v.form.name.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.name.serverFailed">{{ serverErrors.name }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        v-if="newForm"
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.rate_percent"
          label-for="tax-rate-rate_percent"
        >
          <b-input-group
            append="%"
            :class="{ 'is-invalid': $v.form.rate_percent.$dirty && $v.form.rate_percent.$error }"
          >
            <b-form-input
              name="tax-rate-rate_percent"
              v-model="$v.form.rate_percent.$model"
              :state="$v.form.rate_percent.$dirty ? !$v.form.rate_percent.$error : null"
              type="text"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.rate_percent.$dirty"
            class="tax-rate-rate_percent-feedback"
          >
            <span v-if="!$v.form.rate_percent.integer">Please enter a number.</span>
            <span v-if="!$v.form.rate_percent.between">Must be between {{ $v.form.rate_percent.$params.between.min }} and {{ $v.form.rate_percent.$params.between.max }}</span>
            <span v-if="!$v.form.rate_percent.serverFailed">{{ serverErrors.rate }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        :lg="newForm ? 6 : 12"
      >
        <b-form-group
          :label="fieldLabels.code"
          label-for="tax-rate-code"
        >
          <b-form-input
            name="tax-rate-code"
            v-model="$v.form.code.$model"
            :state="$v.form.code.$dirty ? !$v.form.code.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.code.$dirty"
            class="tax-rate-code-feedback"
          >
            <span v-if="!$v.form.code.maxLength">
              The code must be less than {{ $v.form.code.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.code.serverFailed">{{ serverErrors.code }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>


      <b-col
        v-if="newForm"
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.city"
          label-for="tax-rate-city"
        >
          <b-form-input
            name="tax-rate-city"
            v-model="$v.form.city.$model"
            :state="$v.form.city.$dirty ? !$v.form.city.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.city.$dirty"
            class="tax-rate-city-feedback"
          >
            <span v-if="!$v.form.city.maxLength">
              The city must be less than {{ $v.form.city.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.city.serverFailed">{{ serverErrors.city }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        v-if="newForm"
        cols="12"
      >
        <b-form-group
          :label="fieldLabels.county"
          label-for="tax-rate-county"
        >
          <b-form-input
            name="tax-rate-county"
            v-model="$v.form.county.$model"
            :state="$v.form.county.$dirty ? !$v.form.county.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.county.$dirty"
            class="tax-rate-county-feedback"
          >
            <span v-if="!$v.form.county.maxLength">
              The county must be less than {{ $v.form.county.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.county.serverFailed">{{ serverErrors.county }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        v-if="newForm"
        cols="12"
      >
        <b-form-group
          :label="fieldLabels.state"
          label-for="tax-rate-state"
        >
          <b-form-input
            name="tax-rate-state"
            v-model="$v.form.state.$model"
            :state="$v.form.state.$dirty ? !$v.form.state.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.state.$dirty"
            class="tax-rate-state-feedback"
          >
            <span v-if="!$v.form.state.required">Please enter a state.</span>
            <span v-if="!$v.form.state.maxLength">
              The state must be less than {{ $v.form.state.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.state.serverFailed">{{ serverErrors.state }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.charge_categories"
          label-for="tax-rate-charge_categories"
        >
          <charge-category-select
            multiple
            name="tax-rate-charge_categories"
            placeholder="All"
            v-model="form.charge_categories"
            :select-class="{ 'is-invalid': $v.form.charge_categories.$dirty && $v.form.charge_categories.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.charge_categories.$dirty"
            class="tax-rate-charge_categories-feedback"
          >
            <span v-if="!$v.form.charge_categories.serverFailed">{{ serverErrors.charge_categories }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.service_categories"
          label-for="tax-rate-service_categories"
        >
          <service-category-select
            multiple
            name="tax-rate-service_categories"
            placeholder="All"
            v-model="form.service_categories"
            :select-class="{ 'is-invalid': $v.form.service_categories.$dirty && $v.form.service_categories.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.service_categories.$dirty"
            class="tax-rate-service_categories-feedback"
          >
            <span v-if="!$v.form.service_categories.serverFailed">{{ serverErrors.service_categories }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        v-if="newForm"
        cols="12"
      >
        <b-form-group
          :label="fieldLabels.organization_id"
          label-for="tax-rate-organization_id"
        >
          <organization-select
            name="tax-rate-organization_id"
            :select-class="{ 'is-invalid': $v.form.organization_id.$dirty && $v.form.organization_id.$error }"
            :value="form.organization"
            @input="onOrganizationChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.organization_id.$dirty"
            class="tax-rate-organization_id-feedback"
          >
            <span v-if="!$v.form.organization_id.required">Please enter an organization.</span>
            <span v-if="!$v.form.organization_id.serverFailed">
              {{ serverErrors.organization_id }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="tax-rate-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="tax-rate-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength, requiredIf, decimal, between } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import OrganizationSelect from '@/components/admin/organizations/Select.vue';
import ChargeCategorySelect from '@/components/admin/workOrderCharges/CategorySelect.vue';
import ServiceCategorySelect from '@/components/admin/services/CategorySelect.vue';

export default {
  name: 'TaxRatesForm',
  components: {
    OrganizationSelect,
    ChargeCategorySelect,
    ServiceCategorySelect,
  },
  mixins: [formMixin],
  props: {
    name: String,
    code: String,
    rate: Number,
    city: String,
    county: String,
    state: String,
    service_categories: Array,
    charge_categories: Array,
    organization_id: Number,
    organization: Object,
    newForm: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        name: this.name,
        code: this.code,
        rate: this.rate,
        rate_percent: this.rate ? this.rate * 100 : null,
        city: this.city,
        county: this.county,
        state: this.state,
        service_categories: this.service_categories,
        charge_categories: this.charge_categories,
        organization_id: this.organization_id,
        organization: this.organization,
      },
      fieldLabels: {
        name: 'Name *',
        code: 'Code',
        rate_percent: 'Rate *',
        city: 'City',
        county: 'County',
        state: 'State *',
        service_categories: 'Service Categories',
        charge_categories: 'Charge Categories',
        organization_id: 'Organization *',
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('name');
        },
      },
      code: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('code');
        },
      },
      rate_percent: {
        required: requiredIf(function () {
          return this.newForm;
        }),
        decimal,
        between: between(0, 100),
        serverFailed() {
          return !this.hasServerErrors('rate');
        },
      },
      city: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('city');
        },
      },
      county: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('county');
        },
      },
      state: {
        required: requiredIf(function () {
          return this.newForm;
        }),
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('state');
        },
      },
      service_categories: {
        serverFailed() {
          return !this.hasServerErrors('service_categories');
        },
      },
      charge_categories: {
        serverFailed() {
          return !this.hasServerErrors('charge_categories');
        },
      },
      organization_id: {
        required: requiredIf(function () {
          return this.newForm;
        }),
        serverFailed() {
          return !this.hasServerErrors('organization_id');
        },
      },
    },
  },
  watch: {
    'form.rate_percent': function (newRatePercent) {
      this.form.rate = isNaN(newRatePercent) ? null : newRatePercent / 100.0;
    },
  },
  methods: {
    onOrganizationChange(organization) {
      this.form.organization_id = organization ? organization.id : null;
      this.form.organization = organization;
    },
  },
};
</script>
