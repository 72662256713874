export default {
  data() {
    return {
      downloading: false,
      warningCount: 50000,
      totalRows: 0,
    };
  },
  methods: {
    /**
     * Triggered when the user clicks the download button. This button calls the provider function, which is expected
     * to return a promise, and processes the resulting data.
     * @param {Function} fileDownloader The function that returns the file data.
     * @param {Object} params The params to call fileDownloader with.
     * @param {String} filename The name of the file to download.
     */
    download(fileDownloader, params, filename) {
      if(this.totalRows >= this.warningCount) {
        this.$swal({
          text: 'Your download has many rows, it might take a while. Are you sure you want to continue?',
        }).then((result) => {
          if (result.value) {
            this.runDownload(fileDownloader, params, filename);
          }
          result.timer;
        });
      } else {
        this.runDownload(fileDownloader, params, filename);
      }
    },
    runDownload(fileDownloader, params, filename) {
      this.downloading = true;
      fileDownloader(params)
      .then((response) => this.processDownload(response.data, filename))
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          error.response.data.text().then((text) => {
            this.$bvToast.toast(JSON.parse(text).message, {
              title: 'Oops!',
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false,
            });
          });
        } else {
          this.$bvToast.toast('We\'ve experienced an issue while trying to download the file, Please try again.', {
            title: 'Oops!',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false,
          });
        }

        this.downloading = false;
      });
    },
    /**
     * Processes the data returned by the fileProvider function and transformers into into a blob object.
     * The blob data is attached to an object URL and a click is simulated.
     * This results in the blob being downloaded by the browser.
     * @param {Object} data The data provided by the fileProvider prop.
     * @param {String} filename The name of the file to download.
     */
    processDownload(data, filename) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([data]));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      this.downloading = false;
    },
  },
};
