export const roundedMoney = (number) => {
  if (isNaN(number)) {
    return 0;
  }

  return Number.parseFloat(number.toFixed(2));
};

export const fromCents = (number) => {
  if (isNaN(number)) {
    return 0;
  }

  return (number / 100);
};

export const toCents = (number) => {
  if (isNaN(number)) {
    return 0;
  }

  return Math.round(number * 100);
};
