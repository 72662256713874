import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '../helpers/apiPath';

export default {
  getInvoices(params) {
    return axios.get(adminPath('/invoices?') + prepareQueryString(params)).then((response) => {
      return {
        invoices: response.data.map((invoice) => an('Invoice', invoice)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getItems(id, params) {
    return axios.get(adminPath(`/invoices/${id}/items?`) + prepareQueryString(params)).then((response) => {
      return {
        invoiceItems: response.data.map((invoiceItem) => an('InvoiceItem', invoiceItem)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(adminPath(`/invoices/${id}`)).then((response) => an('Invoice', response.data));
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/invoices/${id}`), {
        ...data,
      })
      .then((response) => an('Invoice', response.data));
  },
  create(data) {
    return axios
      .post(adminPath('/invoices'), {
        ...data,
      })
      .then((response) => an('Invoice', response.data));
  },
  destroy(id) {
    return axios.delete(adminPath(`/invoices/${id}`));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/invoices.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  emailInvoices(params) {
    return axios.post(adminPath('/invoices/email?') + prepareQueryString(params), { responseType: 'blob' });
  },
  getAvalaraImport(params) {
    return axios.get(adminPath('/invoices/avalara_import.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  getItemsSpreadsheet(id, params) {
    return axios.get(adminPath(`/invoices/${id}/items.csv?`) + prepareQueryString(params), { responseType: 'blob' });
  },
  getSageImport(params) {
    return axios.get(adminPath('/invoices/sage_import.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  getTemplate(id, file_type) {
    return axios.get(adminPath(`/invoices/${id}/template.${file_type}`), { responseType: 'blob' });
  },
  email(id, data) {
    return axios
      .post(adminPath(`/invoices/${id}/email`), {
        ...data,
      })
      .then((response) => an('Invoice', response.data));
  },
  createItem(id, data) {
    return axios
      .post(adminPath(`/invoices/${id}/items`), {
        ...data,
      })
      .then((response) => an('InvoiceItem', response.data));
  },
  updateItem(invoiceItemId, data) {
    return axios
      .patch(adminPath(`/invoice_items/${invoiceItemId}`), {
        ...data,
      })
      .then((response) => an('InvoiceItem', response.data));
  },
  createWorkOrderChargeItem(id, data) {
    return axios
      .post(adminPath(`/invoices/${id}/items/work_order_charges`), {
        ...data,
      })
      .then((response) => an('InvoiceItem', response.data));
  },
  createRecurringChargeItem(id, data) {
    return axios
      .post(adminPath(`/invoices/${id}/items/recurring_charges`), {
        ...data,
      })
      .then((response) => an('InvoiceItem', response.data));
  },
  destroyItem(invoiceItemId) {
    return axios.delete(adminPath(`/invoice_items/${invoiceItemId}`));
  },
  destroyItemTaxes(invoiceItemId) {
    return axios.delete(adminPath(`/invoice_items/${invoiceItemId}/tax_items`));
  },
  finalizeInvoice(id) {
    return axios
      .patch(adminPath(`/invoices/${id}/finalize`))
      .then((response) => an('Invoice', response.data));
  },
  finalize(data) {
    return axios
      .post(adminPath('/invoices/finalize'), {
        ...data,
      })
      .then((response) => response.data);
  },
};
