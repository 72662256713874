import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const INVOICE_MODEL_NAME = 'invoices';
export const INVOICE_TABLE_NAME = 'invoices';

// The columns available to be shown in a invoices table.
export const INVOICE_TABLE_COLUMNS = {
  number: { label: 'Number', key: 'number', sortable: true },
  client_id: { label: 'Client', key: 'client_id', sortable: true },
  date: { label: 'Date', key: 'date', sortable: true },
  due_date: { label: 'Due Date', key: 'due_date', sortable: true },
  subtotal: { label: 'Subtotal', key: 'subtotal' },
  taxes: { label: 'Taxes', key: 'taxes' },
  balance: { label: 'Balance', key: 'balance' },
  status: { label: 'Status', key: 'status' },
  date_from: { label: 'Invoiced From', key: 'date_from', filterOnly: true },
  date_to: { label: 'Invoiced To', key: 'date_to', filterOnly: true },
  send_physical_invoice: { label: 'Requires Physical Invoice', key: 'send_physical_invoice', filterOnly: true },
  finalized: { label: 'Finalized', key: 'finalized', filterOnly: true },
  emailed: { label: 'Emailed', key: 'emailed', filterOnly: true },
  emailable: { label: 'Will Automatically Email', key: 'emailable', filterOnly: true },
  invoice_delivery_method: { label: 'Delivery Method', key: 'invoice_delivery_method', filterOnly: true },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const INVOICE_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(INVOICE_TABLE_COLUMNS);

// The default columns to be shown when the invoices table loads.
export const INVOICE_TABLE_DEFAULT_COLUMNS = [
  INVOICE_TABLE_COLUMNS.number,
  INVOICE_TABLE_COLUMNS.client_id,
  INVOICE_TABLE_COLUMNS.date,
  INVOICE_TABLE_COLUMNS.due_date,
  INVOICE_TABLE_COLUMNS.subtotal,
  INVOICE_TABLE_COLUMNS.taxes,
  INVOICE_TABLE_COLUMNS.balance,
  INVOICE_TABLE_COLUMNS.status,
];

export const INVOICE_TABLE_FILTER_OPTIONS = [
  { text: 'Invoiced Only', value: 'true' },
  { text: 'Uninvoiced Only', value: 'false' },
  { text: 'Both', value: null },
];

export const INVOICE_TABLE_FINALIZED_FILTER_OPTIONS = [
  { text: 'Yes', value: 'true' },
  { text: 'No', value: 'false' },
  { text: 'Both', value: null },
];

export const INVOICE_TABLE_PHYSICAL_INVOICE_FILTER_OPTIONS = [
  { text: 'Yes', value: 'true' },
  { text: 'No', value: 'false' },
  { text: 'Both', value: null },
];

export const INVOICE_TABLE_EMAILED_FILTER_OPTIONS = [
  { text: 'Yes', value: 'true' },
  { text: 'No', value: 'false' },
  { text: 'Both', value: null },
];

export const INVOICE_TABLE_EMAILABLE_FILTER_OPTIONS = [
  { text: 'Yes', value: 'true' },
  { text: 'No', value: 'false' },
  { text: 'Both', value: null },
];
