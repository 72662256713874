<template>
  <div v-if="workOrder">
    <base-page-heading
      show-back-button
    >
      <template #title>
        <h1 class="flex-sm-fill h3 my-0 text-dark font-w600">
          {{ workOrder.number }}
          <b-button
            v-if="parent || workOrder.parent_id"
            id="parent-work-order-popover"
            class="px-0 font-size-lg text-secondary"
            variant="link"
            @click="() => $router.push({ name: 'work-order', params: { id: parent.id } })"
          >
            <i class="fa fa-link" />
          </b-button>
          <b-popover
            v-if="parent"
            target="parent-work-order-popover"
            triggers="hover"
            placement="top"
          >
            This Work Order is linked to {{ parent.number }}
          </b-popover>
        </h1>
      </template>
      <template #secondary-title>
        <status-badge
          class="mr-1"
          :status="workOrder.status"
          :status-variants="statusVariants"
        />
        <div
          v-if="workOrder.cancellation_reason"
        >
          {{ workOrder.cancellation_reason | humanizedFormat | capitalizeFormat }}
        </div>
        <div
          v-else-if="workOrder.completion_source"
        >
          Completed by {{ workOrder.completion_source | humanizedFormat | capitalizeFormat }}
        </div>
      </template>
      <template #extra>
        <b-button
          v-if="!showCustomFieldsForm && $can('update', 'WorkOrder')"
          variant="primary"
          v-b-modal.work-order-edit-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Edit the Work Order"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <b-dropdown
          v-if="showCustomFieldsForm && $can('update', 'WorkOrder')"
          variant="primary"
          class="mr-2"
          split
          @click="$bvModal.show('work-order-edit-modal')"
          no-flip
          v-b-tooltip.hover
          title="Edit the Work Order"
        >
          <template #button-content>
            <i class="fa fa-pencil-alt mr-1" /> Edit
          </template>
          <b-dropdown-item
            v-b-modal.work-order-custom-fields-form-modal
          >
            <i class="fa fa-pencil-alt mr-1" />
            Edit Additional Info
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          v-if="$can('update', 'WorkOrder') && workOrder.officetrax_reference_id"
          class="mr-2"
          variant="outline-secondary"
          v-b-tooltip.hover
          title="Manage Officetrax data"
        >
          <template #button-content>
            <span>Officetrax</span>
            <span class="font-size-xs bg-gray p-1">
              3rd Party
            </span>
          </template>
          <b-dropdown-item
            v-b-modal.work-order-officetrax-modal
          >
            <i class="fa fa-pencil-alt mr-1" />
            Edit Data
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('upload_photo', 'WorkOrder')"
            @click="uploadOfficetraxPhotos"
          >
            <i class="fa fa-upload mr-1" />
            Upload Photos
          </b-dropdown-item>
        </b-dropdown>

        <dollar-general-fexa-assignment-manager
          v-if="$can('update', 'WorkOrder') && workOrder.dollar_general_fexa_assignment_reference_id"
          :value="workOrder"
          @input="onWorkOrderChanged"
          @status-changed="(status) => dollarGeneralFexaAssignmentStatus = status"
        />

        <b-button
          v-if="$can('assign', workOrder) && isUnassigned"
          variant="outline-secondary"
          v-b-modal.work-order-assign-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Assign the Work Order"
        >
          <i class="fa fa-user-plus mr-1" /> Assign
        </b-button>

        <b-button
          v-if="$can('complete', workOrder) && !isUnassigned"
          :key="`${workOrderKey}-complete-button`"
          variant="outline-secondary"
          v-b-modal.work-order-complete-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Complete the Work Order"
        >
          <i class="fa fa-calendar-plus mr-1" /> Complete
        </b-button>

        <sfs-download-button
          :filename="`${workOrder.number}.pdf`"
          :provider-params="{ id: workOrder.id }"
          :file-provider="$workOrdersAPI.getPDF"
          v-b-tooltip.hover
          title="Download the Work Order"
          class="mr-2"
        />

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item
            v-if="$can('assign', workOrder) && !isUnassigned"
            v-b-modal.work-order-assign-modal
            :key="`${workOrderKey}-assign-dropdown`"
          >
            <i class="fa fa-user-edit mr-1" />
            Assign
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('reassign', workOrder)"
            v-b-modal.work-order-reassign-modal
            :key="`${workOrderKey}-reassign-dropdown`"
          >
            <i class="fa fa-user-edit mr-1" />
            Reassign
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('complete', workOrder) && isUnassigned"
            v-b-modal.work-order-complete-modal
            :key="`${workOrderKey}-complete-dropdown`"
          >
            <i class="fa fa-calendar-plus mr-1" />
            Complete
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('undo_completion', workOrder)"
            :key="`${workOrderKey}-undo-check-out-dropdown`"
            @click="undoCompletion"
          >
            <i class="fa fa-undo mr-1" />
            Undo Check-In / Out
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('cancel', workOrder) && technicianPayments.length === 0"
            :key="`${workOrderKey}-cancel-dropdown`"
            v-b-modal.work-order-cancel-modal
          >
            <i class="fa fa-trash mr-1" />
            Cancel
          </b-dropdown-item>

          <b-dropdown-item v-b-modal.work-order-email-modal>
            <i class="fa fa-envelope mr-1" />
            Email
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('uncancel', workOrder)"
            :key="`${workOrderKey}-uncancel-dropdown`"
            @click="reopen"
          >
            <i class="fa fa-trash mr-1" />
            Reopen
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item
            v-if="$can('update', 'WorkOrder') && !parent"
            :key="`${workOrderKey}-link-dropdown`"
            v-b-modal.work-order-link-modal
          >
            <i class="fa fa-link mr-1" />
            Link to Work Order
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('update', 'WorkOrder') && parent"
            :key="`${workOrderKey}-unlink-dropdown`"
            @click="unlinkParent"
          >
            <i class="fa fa-link mr-1" />
            Unlink Work Order
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('create', 'WorkOrder')"
            @click="() => $router.push({ name: 'new-work-order', query: { duplicate_id: workOrder.id } })"
          >
            <i class="fa fa-clone mr-1" />
            Duplicate
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item
            :to="{ name: 'work-orders', query: { parent_id: workOrder.id.toString() } }"
          >
            <i class="fas fa-external-link-alt mr-1" />
            View linked WOs
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: workOrder.id, model: 'WorkOrder' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content pt-5">
      <b-row class="mb-3">
        <b-col
          md="12"
          lg="7"
          xl="7"
        >
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Date Bucket
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  {{ workOrder | dateRangeFormat("date_bucket_start", "date_bucket_end") }}
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Work Completed
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="isCompleted">
                  <div>
                    {{ workOrder | dateRangeFormat("checked_in_at", "checked_out_at", workOrder.location.time_zone) }}
                  </div>
                  <div>
                    {{ workOrder | timeRangeFormat("checked_in_at", "checked_out_at", workOrder.location.time_zone) }}
                  </div>
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Assigned Technician
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="workOrder.assigned_to">
                  <b-link :to="{ name: 'user', params: { id: workOrder.assigned_to.id } }">
                    {{ workOrder.assigned_to.name }}
                  </b-link>
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Completed By
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="(workOrder.done_by && workOrder.done_by_id != workOrder.assigned_to_id) || (workOrder.done_by && isCompleted)">
                  <b-link :to="{ name: 'user', params: { id: workOrder.done_by.id } }">
                    {{ workOrder.done_by.name }}
                  </b-link>
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <hr class="mt-0 mb-3">

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Location
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  <b-link :to="{ name: 'location', params: { id: workOrder.location_id } }">
                    {{ workOrder.location.store_number }}
                    <span v-if="workOrder.location.name">- {{ workOrder.location.name }}</span>
                  </b-link>
                  <b-link
                    class="d-block"
                    :to="{ name: 'client', params: { id: workOrder.client.id } }"
                  >
                    {{ workOrder.client.name }}
                  </b-link>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Address
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  <div>{{ workOrder.location.street }}</div>
                  <div>
                    {{
                      {
                        city: workOrder.location.city,
                        state: workOrder.location.state,
                        zip_code: workOrder.location.zip_code,
                      } | locationFormat
                    }}
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <hr class="mt-0 mb-3">

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col class="text-prewrap">
                  Service
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col v-if="workOrder.service">
                  <b-link :to="{ name: 'service', params: { id: workOrder.service.id } }">
                    {{ workOrder.service.name }}
                  </b-link>
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col class="text-prewrap">
                  Customer ID
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col v-if="workOrder.customer_identifier">
                  {{ workOrder.customer_identifier }}
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>


          <b-row>
            <b-col>
              <b-row class="mb-3">
                <b-col class="text-prewrap">
                  <div>{{ workOrder.description }}</div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Priority
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="workOrder.priority">
                  {{ workOrder.priority }}
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Maintainance Type
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="workOrder.maintainance_type">
                  {{ workOrder.maintainance_type }}
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>


          <b-row v-if="customFieldData.length > 0">
            <b-col>
              <b-collapse id="collapse-custom-fields">
                <hr class="mt-0 mb-3">

                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    v-for="(customData, index) in customFieldData"
                    :key="index"
                  >
                    <b-row class="text-muted font-w500 font-size-sm mb-1">
                      <b-col class="text-prewrap">
                        {{ customData.custom_field?.label }}
                      </b-col>
                    </b-row>
                    <b-row class="mb-3">
                      <b-col>
                        {{ customData.value }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>

          <b-row
            v-if="customFieldData.length > 0"
            class="mb-3"
          >
            <b-col>
              <b-link v-b-toggle:collapse-custom-fields>
                <span class="when-open">Show Less</span><span class="when-closed">Show More</span>
              </b-link>
            </b-col>
          </b-row>

          <hr class="mt-0 mb-3">

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Invoices
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="invoices.length > 0">
                  <div
                    v-for="(invoice, index) in invoices"
                    :key="index"
                  >
                    <b-link :to="{ name: 'invoice', params: { id: invoice.id } }">
                      {{ invoice.number }}
                    </b-link>
                  </div>
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Payments
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="technicianPayments.length > 0">
                  <div
                    v-for="(technicianPayment, index) in technicianPayments"
                    :key="index"
                  >
                    <b-link :to="{ name: 'technician-payment', params: { id: technicianPayment.id } }">
                      {{ technicianPayment.number }}
                    </b-link>
                  </div>
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          md="12"
          lg="5"
          xl="5"
        >
          <b-row v-if="workOrder.dollar_general_fexa_assignment_reference_id">
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Fexa Assignment ID
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col v-if="workOrder.dollar_general_fexa_assignment_reference">
                  {{ workOrder.dollar_general_fexa_assignment_reference.source_id }}
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Visit ID
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col v-if="workOrder.dollar_general_fexa_visit_reference">
                  {{ workOrder.dollar_general_fexa_visit_reference.source_id }}
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="workOrder.dollar_general_fexa_assignment_reference_id">
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Assignment Status
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="dollarGeneralFexaAssignmentStatus">
                  <b-badge
                    variant="primary"
                    class="font-size-sm"
                  >
                    {{ dollarGeneralFexaAssignmentStatus }}
                  </b-badge>
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Visit Scheduled At
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="workOrder.integration_metadata.scheduled_date">
                  {{ workOrder.integration_metadata.scheduled_date | dateTimeFormat(workOrder.location.time_zone) }}
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="workOrder.officetrax_reference_id">
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Officetrax Scheduled Date
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="workOrder.integration_metadata.scheduled_date">
                  {{ workOrder.integration_metadata.scheduled_date | dateTimeFormat(workOrder.location.time_zone) }}
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Close Out #
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="workOrder.integration_metadata.close_out_number">
                  {{ workOrder.integration_metadata.close_out_number }}
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-tabs
                v-model="activeLocationPhotoTab"
                content-class="pt-3"
              >
                <b-tab title="Photos">
                  <b-nav
                    pills
                    class="mx-4 mb-3"
                  >
                    <b-nav-item
                      @click="activePhotoTab = 0"
                      :active="activePhotoTab === 0"
                    >
                      Before Photos
                    </b-nav-item>
                    <b-nav-item
                      @click="activePhotoTab = 1"
                      :active="activePhotoTab === 1"
                    >
                      After Photos
                    </b-nav-item>
                    <b-nav-item
                      @click="activePhotoTab = 2"
                      :active="activePhotoTab === 2"
                    >
                      No Show Photos
                    </b-nav-item>
                  </b-nav>

                  <div v-show="activePhotoTab === 0">
                    <photo-carousel-modal
                      modal-id="before-photos-modal"
                      :images="workOrder.before_photos"
                      :work-order="workOrder"
                      before-photos
                      @on-save="getWorkOrder"
                      @on-destroy="getWorkOrder"
                    />
                  </div>

                  <div v-show="activePhotoTab === 1">
                    <photo-carousel-modal
                      modal-id="after-photos-modal"
                      :images="workOrder.after_photos"
                      :work-order="workOrder"
                      after-photos
                      @on-save="getWorkOrder"
                      @on-destroy="getWorkOrder"
                    />
                  </div>

                  <div v-show="activePhotoTab === 2">
                    <photo-carousel-modal
                      modal-id="no-show-photos-modal"
                      :images="workOrder.no_show_photos"
                      :work-order="workOrder"
                      :before-photos="false"
                      :after-photos="false"
                      @on-save="getWorkOrder"
                      @on-destroy="getWorkOrder"
                    />
                  </div>
                </b-tab>

                <b-tab title="Map">
                  <map-with-markers
                    :marker-distance="workOrder.check_in_distance"
                    :markers="[
                      { coord: { lat: parseFloat(workOrder.location.latitude), lng: parseFloat(workOrder.location.longitude) }, label: 'Work Order Location' },
                      { coord: { lat: parseFloat(workOrder.latitude), lng: parseFloat(workOrder.longitude) }, label: 'Check in Location' }
                    ]"
                  />
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-tabs
        content-class="pt-4 mb-3"
        v-model="activeTab"
      >
        <template #tabs-end>
          <div
            v-show="activeTab === 0"
            class="ml-auto"
          >
            <b-button
              v-if="$can('create', 'WorkOrderCharge')"
              v-b-modal.work-order-charge-add-modal
              variant="outline-secondary"
            >
              <i class="fa fa-plus mr-1" /> Add Charge
            </b-button>
            <sfs-download-button
              v-b-tooltip.hover
              class="ml-2"
              filename="Work Order Charges.csv"
              :file-provider="getChargesSpreadsheet"
              title="Download spreadsheet"
            />
          </div>

          <div
            v-show="activeTab === 1"
            class="ml-auto"
          >
            <b-button
              v-if="$can('create', 'Document')"
              v-b-modal.new-document-modal
              variant="outline-secondary"
            >
              <i class="fa fa-plus mr-1" /> Add Document
            </b-button>
          </div>
        </template>
        <b-tab
          active
          title="Charges"
        >
          <work-order-charges-table
            :work-order-id="workOrder.id"
            :append-to-query="false"
            :show-per-page="false"
            :key="chargesKey"
          />
        </b-tab>

        <b-tab>
          <template #title>
            <documents-badge :total="documentsTotal" />
          </template>

          <documents-table
            :columns="$store.getters.columnsForTable(this.documentsTableName)"
            :append-to-query="false"
            :show-per-page="false"
            :key="documentsKey"
            :list-provider="$documentsAPI.getWorkOrderDocuments"
            :reference-id="workOrder.id"
            @total-rows-changed="(newTotal) => documentsTotal = newTotal"
          />
        </b-tab>

        <b-tab>
          <template #title>
            <notes-badge :total="notesTotal" />
          </template>

          <notes-timeline
            :list-func="$notesAPI.getWorkOrdersNotes"
            :create-func="$notesAPI.createWorkOrdersNote"
            :reference-id="workOrder.id"
            reference-type="WorkOrder"
            @total-rows-changed="(newTotal) => notesTotal = newTotal"
          />
        </b-tab>

        <b-tab
          v-if="hasSignature"
        >
          <template #title>
            Manager Review
          </template>
          <b-row class="pb-3">
            <b-col
              md="7"
              class="border-right"
            >
              <div class="pb-3 pt-0">
                <b-row>
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Manager Name
                  </b-col>
                  <b-col
                    cols="auto"
                  >
                    {{ workOrder.manager_review.name }}
                  </b-col>
                </b-row>
              </div>

              <div class="pb-3 border-top">
                <b-row class="mb-2">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Manager Comment
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    cols="auto"
                    class="mr-auto "
                  >
                    {{ workOrder.manager_review.comments }}
                  </b-col>
                </b-row>
              </div>

              <b-row>
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  Rating
                </b-col>

                <b-col
                  cols="auto"
                >
                  <star-rating
                    :rating="workOrder.manager_review.rating"
                    :star-size="36"
                    :show-rating="false"
                    read-only
                    active-color="#084298"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col md="5">
              <b-row class="h-100">
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  <img
                    v-if="signatureUrl"
                    class="img-fluid img-signature"
                    :src="signatureUrl"
                  >
                  <p
                    v-else
                  >
                    Signature not found.
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>

    <!-- Modals -->
    <edit-modal
      v-if="$can('update', 'WorkOrder')"
      :value="workOrder"
      @input="onWorkOrderChanged"
    />
    <assign-modal
      v-if="workOrder && $can('assign', workOrder)"
      :value="workOrder"
      @input="onWorkOrderChanged"
    />
    <reassign-modal
      v-if="workOrder && $can('reassign', workOrder)"
      :value="workOrder"
      @input="onWorkOrderChanged"
    />
    <complete-modal
      v-if="workOrder && $can('complete', workOrder)"
      :value="workOrder"
      @input="onWorkOrderChanged"
    />
    <link-modal
      v-if="$can('update', 'WorkOrder') && !parent"
      :value="workOrder"
      @input="(newParent) => parent = newParent"
    />
    <email-modal
      v-model="workOrder"
      :email="workOrder.assigned_to ? workOrder.assigned_to.email : ''"
    />
    <edit-officetrax-modal
      v-if="$can('update', 'WorkOrder')"
      :value="workOrder"
      @input="onWorkOrderChanged"
    />
    <custom-fields-form-modal
      v-if="$can('update', 'WorkOrder')"
      :value="workOrder"
      :custom-field-values="customFieldData"
      @data-changed="(customFields) => showCustomFieldsForm = customFields.length > 0"
    />
    <cancel-modal
      :work-order="workOrder"
      @cancellation-reason-changed="onWorkOrderChanged"
    />
    <new-document-modal
      v-if="$can('update', 'WorkOrder')"
      :reference-id="workOrder.id"
      :create-provider="$documentsAPI.createWorkOrderDocument"
      @on-save="refreshDocuments()"
      :key="documentsKey"
    />
    <new-charge-modal
      v-if="$can('create', 'WorkOrderCharge')"
      :work-order="workOrder"
      @on-save="refreshCharges"
    />
  </div>
</template>

<script>
import CancelModal from '@/components/admin/workOrders/CancelModal.vue';
import StarRating from 'vue-star-rating';
import WorkOrderChargesTable from '@/components/admin/workOrderCharges/Table.vue';
import EditModal from '@/components/admin/workOrders/EditModal.vue';
import LinkModal from '@/components/admin/workOrders/LinkModal.vue';
import AssignModal from '@/components/admin/workOrders/AssignModal.vue';
import ReassignModal from '@/components/admin/workOrders/ReassignModal.vue';
import CompleteModal from '@/components/admin/workOrders/CompleteModal.vue';
import MapWithMarkers from '@/components/shared/MapWithMarkers.vue';
import NewDocumentModal from '@/components/admin/documents/NewModal.vue';
import DocumentsTable from '@/components/admin/documents/Table.vue';
import DocumentsBadge from '@/components/admin/documents/TimelineBadge.vue';
import StatusBadge from '@/components/shared/StatusBadge.vue';
import PhotoCarouselModal from '@/components/admin/workOrders/PhotoCarouselModal.vue';
import EmailModal from '@/components/admin/workOrders/EmailModal.vue';
import NewChargeModal from '@/components/admin/workOrderCharges/NewModal.vue';
import EditOfficetraxModal from '@/components/admin/workOrders/EditOfficetraxModal.vue';
import DollarGeneralFexaAssignmentManager from '@/components/admin/integrationReferences/DollarGeneralFexa/AssignmentManager.vue';
import CustomFieldsFormModal from '@/components/admin/workOrders/CustomFieldsFormModal.vue';
import NotesTimeline from '@/components/admin/notes/Timeline.vue';
import NotesBadge from '@/components/admin/notes/TimelineBadge.vue';
import { isNil as _isNil } from 'lodash';
import { STATUS_VARIANTS, WORK_ORDER_STATUS_UNASSIGNED, WORK_ORDER_STATUS_COMPLETED, WORK_ORDER_STATUS_INVOICED } from '@/constants/workOrders';
import { DOCUMENTS_TABLE_NAME } from '@/constants/documents';

export default {
  name: 'WorkOrdersShow',
  components: {
    StarRating,
    CancelModal,
    WorkOrderChargesTable,
    EditModal,
    LinkModal,
    AssignModal,
    ReassignModal,
    StatusBadge,
    CompleteModal,
    NewDocumentModal,
    DocumentsTable,
    DocumentsBadge,
    PhotoCarouselModal,
    EmailModal,
    NewChargeModal,
    EditOfficetraxModal,
    CustomFieldsFormModal,
    DollarGeneralFexaAssignmentManager,
    NotesTimeline,
    NotesBadge,
    MapWithMarkers,
  },
  data() {
    return {
      documentsTableName: DOCUMENTS_TABLE_NAME,
      workOrder: null,
      parent: null,
      statusVariants: STATUS_VARIANTS,
      invoices: [],
      technicianPayments: [],
      customFieldData: [],
      showCustomFieldsForm: false,
      dollarGeneralFexaAssignmentStatus: null,
      documentsTotal: 0,
      notesTotal: 0,
      workOrderKey: `${Date.now()}`,
      documentsKey: `documents-${Date.now()}`,
      chargesKey: `charges-${Date.now()}`,
      activeTab: 0,
      activeLocationPhotoTab: 0,
      activePhotoTab: 0,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.parent = null;
      this.getWorkOrder();
      this.getTechnicianPayments();
      this.getCustomFieldData();
      if (this.$can('read', 'Invoice')) {
        this.getInvoices();
      }
    },
    getWorkOrder() {
      this.$workOrdersAPI
        .get(this.$route.params.id)
        .then((workOrder) => {
          this.workOrder = workOrder;
          if (this.workOrder.parent_id) {
           this.$workOrdersAPI
            .get(this.workOrder.parent_id)
            .then((workOrder) => {
              this.parent = workOrder;
            });
          } else {
            this.parent = null;
          }
          if (!workOrder.before_photos.length && !workOrder.after_photos.length && !workOrder.no_show_photos.length) {
            this.activeLocationPhotoTab = 1;
          }
          if (workOrder.before_photos.length) {
            this.activePhotoTab =  0;
          }
          else if (workOrder.after_photos.length) {
            this.activePhotoTab =  1;
          }
          else if (workOrder.no_show_photos.length) {
            this.activePhotoTab =  2;
          }
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    getInvoices() {
      this.$invoicesAPI.getInvoices({ work_order_id: this.$route.params.id }).then((response) => {
        this.invoices = response.invoices;
      });
    },
    getTechnicianPayments() {
      this.$technicianPaymentsAPI.getTechnicianPayments({ work_order_id: this.$route.params.id }).then((response) => {
        this.technicianPayments = response.technicianPayments;
      });
    },
    getCustomFieldData() {
      this.$customFieldsAPI.getWorkOrderValues(this.$route.params.id).then(({ customFieldValues }) => {
        this.customFieldData = customFieldValues;
      });
    },
    unlinkParent() {
      this.$swal({
        text: `Are you sure you want to unlink this Work order from ${this.parent?.number}?`,
        preConfirm: () => this.$workOrdersAPI.update(this.workOrder.id, { parent_id: null }),
      }).then((result) => {
        if (result.value) {
          this.workOrder = result.value;
          this.parent = null;
        }
        result.timer;
      });
    },
    reopen() {
      this.$swal({
        text: 'Are you sure you want to reopen this Work Order?',
        preConfirm: () => this.$workOrdersAPI.uncancel(this.workOrder.id),
      }).then((result) => {
        if (result.value) {
          this.workOrder = result.value;
        }
        result.timer;
      });
    },
    undoCompletion() {
      this.$swal({
        text: 'Do you want to undo the Check-In / Out?',
        preConfirm: () => this.$workOrdersAPI.undoCompletion(this.workOrder.id),
      }).then((result) => {
        if (result.value) {
          this.workOrder = result.value;
        }
        result.timer;
      });
    },
    uploadOfficetraxPhotos() {
      this.$swal({
        text: 'Do you want to upload the Photos to Officetrax?',
        preConfirm: () => this.$workOrdersAPI.uploadOfficetraxPhotos(this.workOrder.id),
      }).then((result) => {
        if (result.value) {
          this.workOrder = result.value;
        }
        result.timer;
      });
    },
    refreshDocuments() {
      this.documentsKey = `documents-${Date.now()}`;
    },
    refreshCharges() {
      this.chargesKey = `charges-${Date.now()}`;
    },
    getChargesSpreadsheet() {
      return this.$workOrderChargesAPI.getWorkOrderSpreadsheet(this.workOrder.id);
    },
    onWorkOrderChanged(workOrder) {
      this.workOrder = workOrder;
      this.workOrderKey = `${Date.now()}`;
      this.refreshCharges();
    },
  },
  watch: {
    '$route': 'fetchData',
  },
  computed: {
    isUnassigned() {
      return this.workOrder.status === WORK_ORDER_STATUS_UNASSIGNED;
    },
    isCompleted() {
      return [WORK_ORDER_STATUS_COMPLETED, WORK_ORDER_STATUS_INVOICED].includes(this.workOrder.status);
    },
    hasSignature() {
      return !_isNil(this.workOrder.manager_review);
    },
    signatureUrl() {
      return this?.workOrder?.manager_review?.signature?.url;
    },
  },
};
</script>
<style scoped>
.img-signature {
  max-height: 100px;
}
.notes-badge {
  font-size: 0.75rem;
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  vertical-align: text-top;
}
</style>
