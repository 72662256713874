import { DateTime } from 'luxon';
/**
 *  Returns a string in a format like 'Oct 13, 1994 from 8:55 - 9:55 PM'.
 * @param {string} dateTimeObj An object with a start and end date properties
 *                         in the form of ISO 8601 strings.
 * @param {string} startDateTimeProp the key of the start date for the dateTimeObj.
 * @param {string} endDateTimeProp the key of the end date for the dateTimeObj.
 */
export default function(dateTimeObj, startDateTimeProp, endDateTimeProp, timeZone = null) {
  const dt1 = DateTime.fromISO(dateTimeObj[startDateTimeProp], { zone: timeZone });
  const dt2 = DateTime.fromISO(dateTimeObj[endDateTimeProp], { zone: timeZone });
  const timeFormat = 'h:mm';
  const periodFormat = 'a';

  const formattedTime1 = dt1.toFormat(timeFormat);
  const formattedTime2 = dt2.toFormat(timeFormat);

  // Check if both times are in the same AM/PM period
  const samePeriod = dt1.toFormat(periodFormat) === dt2.toFormat(periodFormat);
  const sameDay = dt1.month == dt2.month && dt1.year == dt2.year && dt1.day == dt2.day;

  // If they are in the same period, we don't need to repeat the AM/PM marker
  return samePeriod && sameDay
      ? `${formattedTime1} - ${formattedTime2} ${dt2.toFormat(periodFormat)} ${dt2.toFormat('ZZZZ')}`
      : `${formattedTime1} ${dt1.toFormat(periodFormat)} - ${formattedTime2} ${dt2.toFormat(periodFormat)} ${dt2.toFormat('ZZZZ')}`;
}
