<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$supplyOrdersAPI.getSupplyOrders"
    provider-key="supplyOrders"
    :columns="translatedColumns(modelName, columns)"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(number)="data">
      <b-link :to="{ name: 'supply-order', params: { id: data.item.id } }">
        {{ data.item.number }}
      </b-link>
    </template>
    <template #cell(status)="data">
      <div class="text-nowrap">
        <status-badge
          :status="data.item.status"
          :status-variants="statusVariants"
        />
        <b-badge
          v-if="data.item.backordered"
          class="font-size-sm mt-2"
          variant="warning"
        >
          Backordered
        </b-badge>
      </div>
    </template>
    <template #cell(date)="data">
      {{ data.item.date | dateFormat }}
    </template>
    <template #cell(location_id)="data">
      <b-link :to="{ name: 'location', params: { id: data.item.location_id } }">
        {{ data.item.location.store_number }}
        <span v-if="data.item.location.name">- {{ data.item.location.name }}</span>
      </b-link>
      <div class="text-nowrap font-size-sm text-secondary">
        {{ { city: data.item.location.city, state: data.item.location.state } | locationFormat }}
      </div>
      <div>
        <b-link
          v-if="data.item.client"
          :to="{ name: 'client', params: { id: data.item.client_id } }"
        >
          {{ data.item.client.name }}
        </b-link>
      </div>
    </template>
    <template #cell(client_id)="data">
      <b-link
        v-if="data.item.client"
        :to="{ name: 'client', params: { id: data.item.client_id } }"
      >
        {{ data.item.client.name }}
      </b-link>
    </template>
    <template #cell(cost)="data">
      <money-format
        :value="data.item.cost.amount"
        :currency-code="data.item.cost.currency"
        subunits-value
      />
    </template>
    <template #cell(price)="data">
      <money-format
        :value="data.item.price.amount"
        :currency-code="data.item.price.currency"
        subunits-value
      />
    </template>
    <template #cell(vendor_id)="data">
      <b-link :to="{ name: 'vendor', params: { id: data.item.vendor_id } }">
        {{ data.item.vendor.name }}
      </b-link>
    </template>
  </sfs-base-table>
</template>

<script>
import StatusBadge from '@/components/shared/StatusBadge.vue';
import tablePropsMixin from '@/mixins/tablePropsMixin';
import translationMixin from '@/mixins/translationMixin';
import { SUPPLY_ORDER_TABLE_DEFAULT_COLUMNS, SUPPLY_ORDER_STATUS_VARIANTS, SUPPLY_ORDER_MODEL_NAME } from '@/constants/supplyOrders';
export default {
  name: 'SupplyOrdersTable',
  components: {
    StatusBadge,
  },
  mixins: [tablePropsMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: () => SUPPLY_ORDER_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      statusVariants: SUPPLY_ORDER_STATUS_VARIANTS,
      modelName: SUPPLY_ORDER_MODEL_NAME,
    };
  },
};
</script>
