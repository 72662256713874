<template>
  <div>
    <base-page-heading title="Insurance Certificates">
      <template #extra>
        <b-button
          v-if="$can('create', 'InsuranceCertificate')"
          class="mr-2"
          v-b-modal.insurance-certificate-add-modal
          variant="primary"
          v-b-tooltip.hover
          title="Create a new Insurance Certificate"
        >
          <i class="fa fa-plus mr-1" />
          New Certificate
        </b-button>

        <b-dropdown
          variant="outline-secondary"
          right
          v-b-tooltip.hover
          title="Download spreadsheets"
        >
          <template #button-content>
            <b-spinner
              v-if="downloading"
              small
              label="Downloading..."
            />
            <i
              v-else
              class="fa fa-download mr-1"
            />
            Downloads
          </template>
          <b-dropdown-item @click="downloadSpreadsheet">
            Insurance Certificates
          </b-dropdown-item>
          <b-dropdown-item
            @click="downloadCoveragesSpreadsheet"
            v-if="$can('read', 'InsuranceCertificateCoverage')"
          >
            Coverages
          </b-dropdown-item>
          <b-dropdown-item
            @click="downloadVerificationsSpreadsheet"
            v-if="$can('read', 'InsuranceCertificateVerification')"
          >
            Verification History
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <insurance-certificates-list-filter
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>

      <insurance-certificates-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>

    <!-- Page Modals -->
    <insurance-certificate-new-modal v-if="$can('create', 'InsuranceCertificate')" />
  </div>
</template>

<script>
import InsuranceCertificateNewModal from '@/components/admin/insuranceCertificates/NewModal.vue';
import InsuranceCertificatesTable from '@/components/admin/insuranceCertificates/Table.vue';
import InsuranceCertificatesListFilter from '@/components/admin/insuranceCertificates/ListFilter.vue';
import listMixin from '@/mixins/listMixin';
import downloadMixin from '@/mixins/downloadMixin';
import { INSURANCE_CERTIFICATE_TABLE_NAME } from '@/constants/insuranceCertificates';

export default {
  name: 'InsuranceCertificatesList',
  components: {
    InsuranceCertificateNewModal,
    InsuranceCertificatesTable,
    InsuranceCertificatesListFilter,
  },
  mixins: [downloadMixin, listMixin],
  props: {
    text: String,
    policy_number: String,
    status: String,
    category: String,
    manager_id: String,
    phone: String,
    email: String,
    state: String,
    user_id: String,
    end_date_from: String,
    end_date_to: String,
    verified: String,
    active: String,
  },
  data() {
    return {
      tableName: INSURANCE_CERTIFICATE_TABLE_NAME,
      filters: {
        text: this.text,
        policy_number: this.policy_number,
        status: this.status,
        category: this.category,
        manager_id: this.manager_id,
        phone: this.phone,
        email: this.email,
        state: this.state,
        user_id: this.user_id,
        end_date_from: this.end_date_from,
        end_date_to: this.end_date_to,
        verified: this.verified,
        active: this.active,
      },
    };
  },
  methods: {
    downloadSpreadsheet() {
      this.download(this.$insuranceCertificatesAPI.getSpreadsheet, this.filters, 'Insurance Certificates.csv');
    },
    downloadCoveragesSpreadsheet() {
      this.download(
        this.$insuranceCertificateCoveragesAPI.getSpreadsheet,
        this.filters,
        'Insurance Certificate Coverages.csv'
      );
    },
    downloadVerificationsSpreadsheet() {
      this.download(
        this.$insuranceCertificateVerificationsAPI.getSpreadsheet,
        this.filters,
        'Insurance Certificate Verifications.csv'
      );
    },
  },
};
</script>
