export default {
  setUser(state, payload) {
    state.user = payload.user;
  },
  setImpersonatingUser(state, payload) {
    state.impersonatingUser = payload.impersonatingUser;
  },
  setToken(state, payload) {
    state.token = payload.token;
  },
  setImpersonatingToken(state, payload) {
    state.impersonatingToken = payload.impersonatingToken;
  },
  setCategory(state, payload) {
    state.category = payload.category;
  },
  setRules(state, payload) {
    state.rules = payload.rules;
  },
  setPermissions(state, payload) {
    state.permissions = payload;
  },
  setOrganization(state, payload) {
    state.organization = payload.organization;
  },
  setOrganizationTerminology(state, payload) {
    state.organizationTerminology = payload.organizationTerminology;
  },
  setClient(state, payload) {
    state.client = payload.client;
  },
  setUserListColumns(state, payload) {
    state.userListColumns = payload.userListColumns;
  },
  setUserPerPage(state, payload) {
    state.userPerPage = payload.userPerPage;
  },
  setOrganizationListColumns(state, payload) {
    state.organizationListColumns = payload.organizationListColumns;
  },
};
