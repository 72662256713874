import axios from 'axios';
import { authPath } from './helpers/apiPath';

export default {
  login(data) {
    return axios.post(authPath('/auth'), { ...data });
  },
  resetPassword(data) {
    return axios.post(authPath('/auth/reset_password'), { ...data });
  },
  getProfile() {
    return axios.get(authPath('/users/profile'));
  },
  getLocaleOptions() {
    return axios.get(authPath('/users/locale_options'));
  },
  getTimeZoneOptions() {
    return axios.get(authPath('/users/time_zone_options'));
  },
  updateProfile(data) {
    return axios.patch(authPath('/users/profile'), {
      ...data,
    });
  },
  updatePassword(data) {
    return axios.patch(authPath('/users/update_password'), {
      ...data,
    });
  },
  newPassword(data) {
    return axios.patch(authPath('/auth/update_password'), {
      ...data,
    });
  },
  registerMfaAuth(data) {
    return axios.post(authPath('/auth/two_factor/register'), { ...data });
  },
  registerLoggedInUserMfaAuth() {
    return axios.get(authPath('/users/two_factor/register'));
  },
  disableMfaAuth(data) {
    return axios.post(authPath('/auth/two_factor/disable'), { ...data });
  },
};
