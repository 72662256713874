export const INSURANCE_CERTIFICATE_COVERAGES_TABLE_NAME = 'insuranceCertificateCoverages';

// Document table constants

// The columns available to be shown in a documents table.
export const INSURANCE_CERTIFICATE_COVERAGES_TABLE_COLUMNS = {
  start_date: { label: 'Coverage Start', key: 'start_date' },
  end_date: { label: 'Coverage End', key: 'end_date' },
  verified: { label: 'Verification', key: 'verified' },
  actions: { label: 'Verification History', key: 'actions' },
};

export const INSURANCE_CERTIFICATE_COVERAGES_TABLE_DEFAULT_COLUMNS = [
  INSURANCE_CERTIFICATE_COVERAGES_TABLE_COLUMNS.start_date,
  INSURANCE_CERTIFICATE_COVERAGES_TABLE_COLUMNS.end_date,
  INSURANCE_CERTIFICATE_COVERAGES_TABLE_COLUMNS.verified,
  INSURANCE_CERTIFICATE_COVERAGES_TABLE_COLUMNS.actions,
];
