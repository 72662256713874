import NotFound from '@/pages/errors/404.vue';

export default [
  {
    path: '/404',
    name: 'not-found',
    component: NotFound,
  },
  {
    path: '*',
    redirect: '/404',
  },
];
