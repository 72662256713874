<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row
      v-for="month in monthOptions"
      :key="month[1]"
    >
      <b-col>
        <b-form-group
          :label="`${month[0]}:`"
          label-cols="3"
          label-for="recurring-work-order-frequency"
        >
          <frequency-select
            name="recurring-work-order-frequency"
            :value="form.frequencies[month[1]]"
            @input="(frequency) => updateFrequency(month[1], frequency)"
            :options="frequencyOptions"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="recurring-work-order-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="recurring-work-order-submit-button"
          block
          type="submit"
          variant="primary"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { omitBy as _omitBy, isNil as _isNil } from 'lodash';
import formMixin from '@/mixins/formMixin';
import FrequencySelect from '@/components/shared/FrequencySelect.vue';

export default {
  name: 'RecurringWorkOrderFrequenciesForm',
  components: {
    FrequencySelect,
  },
  mixins: [formMixin],
  props: {
    frequencies: {
      type: Object,
      default: () => new Object(),
    },
    monthOptions: {
      type: Array,
      default: () => [],
    },
    frequencyOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        frequencies: this.frequencies,
      },
    };
  },
  validations: {},
  methods: {
    updateFrequency(monthNum, frequency) {
      this.form.frequencies[monthNum] = frequency;
      this.form.frequencies = _omitBy(this.form.frequencies, _isNil);
    },
  },
};
</script>
