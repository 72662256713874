<template>
  <sfs-base-list-filter
    title="Filter Payment Items"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :column-picker="false"
    :advanced-filter="false"
  >
    <template #quick-filters>
      <b-form-group
        label="Search fields for Data"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Work Order Number or Description"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #right-tray>
      <b-dropdown
        v-if="$can('create_item', technicianPayment)"
        class="ml-2"
        variant="primary"
        v-b-tooltip.hover
        title="Create a new Payment Item"
      >
        <template #button-content>
          <i class="fa fa-plus mr-1" />
          New Item
        </template>
        <b-dropdown-item v-b-modal.technician-payment-item-add-modal>
          Add Item
        </b-dropdown-item>
        <b-dropdown-item v-b-modal.technician-payment-item-add-work-order-charge-modal>
          Add Charge
        </b-dropdown-item>
      </b-dropdown>
      <sfs-download-button
        v-b-tooltip.hover
        class="ml-2"
        filename="Technician Payment Items.csv"
        :file-provider="getItemsSpreadsheet"
        title="Download spreadsheet"
      />
      <!-- Modals -->
      <new-modal
        v-if="$can('create_item', technicianPayment)"
        :technician_payment_id="technicianPayment.id"
        @on-save="refreshTable"
      />
      <add-work-order-charge-modal
        v-if="$can('create_item', technicianPayment)"
        :technician_payment_id="technicianPayment.id"
        :user_id="technicianPayment.user_id"
        @on-save="refreshTable"
      />
    </template>
  </sfs-base-list-filter>
</template>
<script>
import NewModal from '@/components/admin/technicianPaymentItems/NewModal.vue';
import AddWorkOrderChargeModal from '@/components/admin/technicianPaymentItems/AddWorkOrderChargeModal.vue';
import {
  TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMN_OPTIONS,
  TECHNICIAN_PAYMENT_ITEM_TABLE_DEFAULT_COLUMNS,
  TECHNICIAN_PAYMENT_ITEM_TABLE_NAME,
} from '@/constants/technicianPaymentItems';
import listFilterMixin from '@/mixins/listFilterMixin';

export default {
  name: 'InvoiceItemsListFilter',
  mixins: [listFilterMixin],
  components: {
    NewModal,
    AddWorkOrderChargeModal,
  },
  props: {
    technicianPayment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      availableColumns: TECHNICIAN_PAYMENT_ITEM_TABLE_COLUMN_OPTIONS,
      tableName: TECHNICIAN_PAYMENT_ITEM_TABLE_NAME,
    };
  },
  mounted() {
    this.applyFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.client = null;
      this.displayedColumns = TECHNICIAN_PAYMENT_ITEM_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    getItemsSpreadsheet() {
      return this.$technicianPaymentsAPI.getItemsSpreadsheet(this.technicianPayment.id);
    },
    refreshTable() {
      this.$emit('data-changed');
    },
  },
};
</script>
