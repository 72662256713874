<template>
  <b-form
    @submit.stop.prevent="handleSubmit"
    class="text-left"
  >
    <b-row>
      <b-col>
        <b-alert
          :show="hasBaseServerErrors()"
          variant="danger"
          dismissible
        >
          <p
            class="mb-0"
            v-for="(error, index) in baseServerErrors"
            :key="index"
          >
            {{ error }}
          </p>
        </b-alert>
      </b-col>
    </b-row>

    <b-form-group
      :label="fieldLabels.description"
      label-for="invoice-item-description"
    >
      <b-form-textarea
        name="invoice-item-description"
        placeholder="Enter a Description"
        v-model="$v.form.description.$model"
        :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
        rows="4"
      />
      <b-form-invalid-feedback
        v-if="$v.form.description.$dirty"
        class="invoice-item-description-feedback"
      >
        <span v-if="!$v.form.description.required">Please enter a description.</span>
        <span v-if="!$v.form.description.serverFailed">{{ serverErrors.description }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.category"
      label-for="invoice-item-category"
    >
      <category-select
        name="invoice-item-category"
        v-model="form.category"
        :select-class="{ 'is-invalid': $v.form.category.$dirty && $v.form.category.$error }"
      />
      <b-form-invalid-feedback
        v-if="$v.form.category.$dirty"
        class="invoice-item-category-feedback"
      >
        <span v-if="!$v.form.category.required">Please enter a category.</span>
        <span v-if="!$v.form.category.maxLength">
          The category must be less than {{ $v.form.category.$params.maxLength.max + 1 }} characters.
        </span>
        <span v-if="!$v.form.category.serverFailed">{{ serverErrors.category }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.amount"
      label-for="invoice-item-amount"
    >
      <b-input-group prepend="$">
        <b-form-input
          name="invoice-item-amount"
          v-model="$v.form.amount.$model"
          :state="$v.form.amount.$dirty ? !$v.form.amount.$error : null"
          type="text"
        />
      </b-input-group>
      <b-form-invalid-feedback
        v-if="$v.form.amount.$dirty"
        :state="$v.form.amount.$dirty ? !$v.form.amount.$error : null"
        class="invoice-item-amount-feedback"
      >
        <span v-if="!$v.form.amount.required">Please enter an amount.</span>
        <span v-if="!$v.form.amount.decimal">Please enter a number.</span>
        <span v-if="!$v.form.amount.serverFailed">
          {{ serverErrors.amount_cents }}
        </span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.gl_code_id"
      label-for="invoice-item-gl_code_id"
    >
      <gl-code-select
        name="invoice-item-gl_code_id"
        :select-class="{ 'is-invalid': $v.form.gl_code_id.$dirty && $v.form.gl_code_id.$error }"
        :value="form.gl_code"
        @input="onGlCodeChange"
        :filter-params="invoiceGlCodeFilters"
      />
      <b-form-invalid-feedback
        v-if="$v.form.gl_code_id.$dirty"
        class="invoice-item-gl_code_id-feedback"
      >
        <span v-if="!$v.form.gl_code_id.serverFailed">
          {{ serverErrors.gl_code }}
        </span>
      </b-form-invalid-feedback>
    </b-form-group>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="invoice-item-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="invoice-item-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength, decimal } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import CategorySelect from '@/components/admin/workOrderCharges/CategoryButtonSelect.vue';
import GlCodeSelect from '@/components/admin/glCodes/Select.vue';
import { GL_CODE_INVOICE_CATEGORY } from '@/constants/glCodes';

export default {
  name: 'ServicesForm',
  components: {
    CategorySelect,
    GlCodeSelect,
  },
  mixins: [formMixin],
  props: {
    description: String,
    category: String,
    amount: Object,
    gl_code_id: [String, Number],
    gl_code: Object,
  },
  data() {
    return {
      form: {
        description: this.description,
        category: this.category,
        amount: this.amount && this.amount.amount ? this.amount.amount / 100 : null,
        amount_cents: this.amount && this.amount.amount ? this.amount.amount : null,
        gl_code_id: this.gl_code_id,
        gl_code: this.gl_code,
      },
      fieldLabels: {
        description: 'Description',
        category: 'Category *',
        amount: 'Amount',
        gl_code_id: 'GL Code',
      },
      invoiceGlCodeFilters: { category: GL_CODE_INVOICE_CATEGORY, active: true },
    };
  },
  validations: {
    form: {
      description: {
        required,
        serverFailed() {
          return !this.hasServerErrors('description');
        },
      },
      category: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('category');
        },
      },
      amount: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('amount_cents');
        },
      },
      gl_code_id: {
        serverFailed() {
          return !this.hasServerErrors('gl_code');
        },
      },
    },
  },
  watch: {
    'form.amount': function (newAmount) {
      this.form.amount_cents = isNaN(newAmount) ? null : Math.round(newAmount * 100);
    },
  },
  methods: {
    onGlCodeChange(glCode) {
      this.form.gl_code_id = glCode ? glCode.id : '';
      this.form.gl_code = glCode;
    },
  },
};
</script>
