<template>
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :value="value"
    @input="(data) => $emit('input', data)"
    :multiple="multiple"
  >
    <template #option="option">
      <div>
        {{ option.name }}
      </div>
      <div class="font-size-sm text-secondary">
        {{ option.email }}
      </div>
    </template>
  </v-select>
</template>
<script>
export default {
  name: 'EmployeeSelect',
  props: {
    value: [Object, Array],
    id: {
      type: String,
      default: 'client',
    },
    selectLabel: {
      type: String,
      default: 'name',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getEmployeeOptions();
  },
  methods: {
    getEmployeeOptions() {
      this.$usersAPI.getUsers({ employees_only: true, paginate: false, active: true }).then((response) => {
          this.options = response.users;
        });
    },
  },
};
</script>
