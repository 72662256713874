<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          v-if="isLoaded"
          :items="records"
          :fields="columns"
          :busy="isLoadingData"
          :sort-by.sync="sorting.sortBy"
          :sort-desc.sync="sorting.sortDesc"
          @sort-changed="handleSortingChanged"
          no-local-sorting
          show-empty
          responsive
          table
        >
          <template #cell(number)="data">
            {{ data.item.number }}
          </template>
          <template #cell(status)="data">
            <status-badge
              :status="data.item.status"
              :status-variants="statusVariants"
            />
          </template>
          <template #cell(start_date)="data">
            {{ data.item | dateRangeFormat("start_date", "end_date") }}
          </template>
          <template #cell(due_date)="data">
            <b-link :to="{ name: 'technician-user-payment', params: { id: data.item.id } }">
              {{ data.item.due_date | dateFormat }}
            </b-link>
          </template>
          <template #cell(balance)="data">
            <money-format
              :value="data.item.balance.amount"
              :currency-code="data.item.balance.currency"
              subunits-value
            />
          </template>
        </b-table>
        <sfs-skeleton-table
          v-else
          :rows="pagination.perPage"
          :columns="columns.length"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="auto"
        :class="{ 'mr-auto': true, 'ml-auto': !showPerPage }"
      >
        <b-pagination
          v-if="showPagination"
          v-model="pagination.currentPage"
          :total-rows="pagination.maxResults > totalRows ? totalRows : pagination.maxResults"
          :per-page="pagination.perPage"
          last-class="d-none"
        />
      </b-col>
      <sfs-pagination-dropdown
        v-if="showPerPage"
        container-class="col-auto"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="totalRows"
        @per-page-changed="onPerPageChanged"
      />
    </b-row>
  </div>
</template>

<script>
import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';
import technicianPaymentsMixin from '@/mixins/technicianPaymentsMixin';
import { TECHNICIAN_USER_PAYMENT_TABLE_DEFAULT_COLUMNS, STATUS_VARIANTS } from '@/constants/technicianPayments';
import StatusBadge from '@/components/shared/StatusBadge.vue';

export default {
  name: 'TechnicianUserPaymentsTable',
  mixins: [urlMixin, tableMixin, technicianPaymentsMixin],
  components: {
    StatusBadge,
  },
  props: {
    columns: {
      type: Array,
      default: () => TECHNICIAN_USER_PAYMENT_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      statusVariants: STATUS_VARIANTS,
    };
  },
  methods: {
    getRecords() {
      this.isLoadingData = true;
      this.$technicianUserPaymentsAPI
        .getTechnicianPayments(this.params)
        .then((response) => {
          const { total, technicianPayments } = response;
          this.totalRows = total;
          this.records = technicianPayments;
        })
        .catch(() => {
          this.records = [];
        })
        .finally(() => {
          this.loaded = true;
          this.isLoadingData = false;
        });
    },
  },
};
</script>
