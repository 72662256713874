import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '../helpers/apiPath';

export default {
  getServices(params) {
    return axios.get(adminPath('/services?') + prepareQueryString(params)).then((response) => {
      return {
        services: response.data.map((service) => an('Service', service)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(adminPath(`/services/${id}`)).then((response) => an('Service', response.data));
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/services/${id}`), {
        ...data,
      })
      .then((response) => an('Service', response.data));
  },
  create(data) {
    return axios
      .post(adminPath('/services'), {
        ...data,
      })
      .then((response) => an('Service', response.data));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/services.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  getCategoryOptions() {
    return axios.get(adminPath('/services/category_options'));
  },
};
