import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '../helpers/apiPath';

export default {
  getInsuranceCertificatesNotes(id, params) {
    return axios.get(adminPath(`/insurance_certificates/${id}/notes?`) + prepareQueryString(params)).then((response) => {
      return {
        notes: response.data.map((note) => an('Note', note)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  createInsuranceCertificatesNote(insuranceCertificateId, data) {
    return axios
      .post(adminPath(`/insurance_certificates/${insuranceCertificateId}/notes`), {
        ...data,
      })
      .then((response) => an('Note', response.data));
  },
  getWorkOrdersNotes(id, params) {
    return axios.get(adminPath(`/work_orders/${id}/notes?`) + prepareQueryString(params)).then((response) => {
      return {
        notes: response.data.map((note) => an('Note', note)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  createWorkOrdersNote(workOrderId, data) {
    return axios
      .post(adminPath(`/work_orders/${workOrderId}/notes`), {
        ...data,
      })
      .then((response) => an('Note', response.data));
  },
  getLocationsNotes(id, params) {
    return axios.get(adminPath(`/locations/${id}/notes?`) + prepareQueryString(params)).then((response) => {
      return {
        notes: response.data.map((note) => an('Note', note)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  createLocationsNote(locationId, data) {
    return axios
      .post(adminPath(`/locations/${locationId}/notes`), {
        ...data,
      })
      .then((response) => an('Note', response.data));
  },
  updateNote(id, data) {
    return axios
    .patch(adminPath(`/notes/${id}`), {
      ...data,
    })
    .then((response) => an('Note', response.data));
  },
};
