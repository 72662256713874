<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="endpoint"
    :data-id="vendorId"
    provider-key="supplies"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
    :append-to-query="false"
  >
    <template #cell(item_number)="data">
      <div>{{ data.item.item_number }}</div>
      <div class="font-size-sm">
        {{ data.item.description }}
      </div>
    </template>
    <template #cell(client_description)="data">
      {{ data.item.client_description }}
    </template>
    <template #cell(category)="data">
      <b-badge variant="secondary">
        {{ data.item.category | humanizedFormat | capitalizeFormat }}
      </b-badge>
    </template>
    <template #cell(vendor_id)="data">
      <b-link :to="{ name: 'vendor', params: { id: data.item.vendor_id } }">
        {{ data.item.vendor.name }}
      </b-link>
    </template>
    <template #cell(client_id)="data">
      <b-link
        v-if="data.item.client"
        :to="{ name: 'client', params: { id: data.item.client_id } }"
      >
        {{ data.item.client.name }}
      </b-link>
    </template>

    <template #cell(cost)="data">
      <money-format
        :value="data.item.cost.amount"
        :currency-code="data.item.cost.currency"
        subunits-value
      />
    </template>

    <template #cell(price)="data">
      <money-format
        :value="data.item.price.amount"
        :currency-code="data.item.price.currency"
        subunits-value
      />
    </template>

    <template #cell(active)="data">
      <b-badge
        v-if="!data.item.deleted_at"
        class="font-size-sm"
        variant="success"
      >
        Active
      </b-badge>
      <b-badge
        v-else
        class="font-size-sm"
        variant="danger"
      >
        Inactive
      </b-badge>
    </template>

    <template #cell(actions)="data">
      <div class="text-nowrap">
        <b-button
          v-if="$can('update', 'Supply')"
          v-b-modal="`supply-${data.item.id}-edit-modal`"
          size="sm"
          variant="link"
          class="mr-1"
        >
          <i class="fa fa-fw fa-pencil-alt" />
        </b-button>
        <b-button
          v-if="$can('update', 'Supply')"
          v-b-tooltip.hover
          size="sm"
          variant="link"
          :title="toggleActiveTooltipText(!data.item.deleted_at)"
          @click="toggleActive(data.item.id, !data.item.deleted_at)"
        >
          <i
            v-if="!data.item.deleted_at"
            class="fa fa-fw fa-times"
          />
          <i
            v-else
            class="fa fa-undo"
          />
        </b-button>
      </div>
      <edit-modal
        v-if="$can('update', 'Supply')"
        :key="`supplies-edit-modal-${data.item.id}`"
        :value="data.item"
        @on-save="refreshTable"
      />
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import { VENDOR_TABLE_DEFAULT_COLUMNS } from '@/constants/vendors';
import EditModal from '@/components/admin/supplies/EditModal.vue';
import { DateTime } from 'luxon';

export default {
  name: 'VendorsTable',
  mixins: [tablePropsMixin],
  components: {
    EditModal,
  },
  props: {
    columns: {
      type: Array,
      default: () => VENDOR_TABLE_DEFAULT_COLUMNS,
    },
    vendorId: {
      type: Number,
    },
  },
  methods: {
    toggleActiveTooltipText(deleted_at) {
      return deleted_at ? 'Deactivate' : 'Reactivate';
    },
    toggleActiveConfirmText(deleted_at) {
        return deleted_at
          ? 'Are you sure you want to deactivate the Supply?'
          : 'Are you sure you want to reactivate the Supply?';
    },
    toggleActive(id, deleted_at) {
      this.$swal({
        text: this.toggleActiveConfirmText(deleted_at),
        preConfirm: () => this.$suppliesAPI.update(id, { deleted_at: (!deleted_at ? DateTime.now().toISODate() : null ) }),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
  },
  computed: {
    endpoint() {
      if (this.vendorId) {
        return this.$suppliesAPI.getVendorSupplies;
      } else {
        return this.$suppliesAPI.getSupplies;
      }
    },
  }
};
</script>
