<template>
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :value="value"
    @input="onChange"
    :searchable="false"
  />
</template>
<script>
export default {
  name: 'OrganizationsSelect',
  props: {
    value: Object,
    id: {
      type: String,
      default: 'organization',
    },
    selectLabel: {
      type: String,
      default: 'name',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: String,
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getOrganizations();
  },
  methods: {
    getOrganizations() {
      this.$store.cache.dispatch('getOrganizations').then((organizationOptions) => {
        this.options = organizationOptions;
      });
    },
    onChange(organization) {
      this.$emit('input', organization);
    },
  },
};
</script>
