import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '../helpers/apiPath';

export default {
  getSupplies(params) {
    return axios.get(adminPath('/supplies?') + prepareQueryString(params)).then((response) => {
      return {
        supplies: response.data.map((supply) => an('Supply', supply)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getVendorSupplies(vendorId, params) {
    return axios.get(adminPath(`/vendors/${vendorId}/supplies?`) + prepareQueryString(params)).then((response) => {
      return {
        supplies: response.data.map((supply) => an('Supply', supply)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getClientSupplies(clientId, params) {
    return axios.get(adminPath(`/clients/${clientId}/supplies?`) + prepareQueryString(params)).then((response) => {
      return {
        supplies: response.data.map((supply) => an('Supply', supply)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/supplies.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/supplies/${id}`), {
        ...data,
      })
      .then((response) => an('Supply', response.data));
  },
  create(data) {
    return axios
      .post(adminPath('/supplies'), {
        ...data,
      })
      .then((response) => an('Supply', response.data));
  },
  getCategoryOptions() {
    return axios.get(adminPath('/supplies/category_options'));
  },
};
