<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="listProvider"
    :data-id="referenceId"
    provider-key="documents"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
    :append-to-query="false"
    @total-rows="handleTotalRows"
  >
    <template #cell(file)="data">
      <b-link :href="data.item.file.url">
        {{ data.item.file.filename }}<i class="ml-2 fa fa-external-link-alt" />
      </b-link>
    </template>
    <template #cell(internal)="data">
      <b-badge :variant="data.item.internal ? 'success' : 'warning'">
        {{ data.item.internal ? "Internal" : "External" }}
      </b-badge>
    </template>
    <template #cell(created_at)="data">
      <span class="text-secondary">{{ data.item.created_at | dateTimeFormat }}</span>
    </template>
    <template #cell(actions)="data">
      <b-button-group>
        <b-button
          v-if="$can('update', 'Document')"
          v-b-modal="`document-${data.item.id}-edit-modal`"
          size="sm"
          variant="link"
        >
          <i class="fa fa-pencil-alt" />
        </b-button>
        <b-button
          v-if="$can('update', 'Document')"
          size="sm"
          variant="link"
          @click="destroyDocument(data.item.id)"
        >
          <i class="fa fa-trash" />
        </b-button>
        <document-edit-modal
          v-if="$can('update', 'Document')"
          :key="`documents-edit-modal-${data.item.id}`"
          :value="data.item"
          @on-save="refreshTable"
        />
      </b-button-group>
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import { DOCUMENTS_TABLE_DEFAULT_COLUMNS } from '@/constants/documents';
import DocumentEditModal from '@/components/admin/documents/EditModal.vue';

export default {
  name: 'DocumentsTable',
  mixins: [tablePropsMixin],
  components: {
    DocumentEditModal,
  },
  props: {
    referenceId: {
      type: Number,
      required: true,
    },
    listProvider: {
      type: Function,
      required: true,
    },
    columns: {
      type: Array,
      default: () => DOCUMENTS_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      totalRows: 0,
    };
  },
  methods: {
    handleTotalRows(totalRows) {
      this.$emit('total-rows-changed', totalRows);
    },
    destroyDocument(id) {
      this.$swal({
        text: 'Once it\'s deleted, its gone forever!',
        preConfirm: () => this.$documentsAPI.destroy(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
          this.$emit('total-rows-changed', this.totalRows - 1);
        }
        result.timer;
      });
    },
  },
};
</script>
