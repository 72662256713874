import List from '@/pages/admin/versions/List.vue';
import handleActivePaginationRouteProps from '@/utils/handleActivePaginationRouteProps.js';

export default [
  {
    path: 'versions',
    name: 'versions',
    component: List,
    props: handleActivePaginationRouteProps,
  },
];
