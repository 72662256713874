<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col cols="12">
        <b-form-group
          v-if="newForm"
          :label="fieldLabels.user_id"
          label-for="insurance-certificate-user_id"
        >
          <user-select
            name="insurance-certificate-user_id"
            :select-class="{ 'is-invalid': $v.formData.user_id.$dirty && $v.formData.user_id.$error }"
            :value="formData.user"
            @input="onUserChange"
            :filter-params="userSelectParams"
          />
          <b-form-invalid-feedback
            v-if="$v.formData.user_id.$dirty"
            class="insurance-certificate-user_id-feedback"
          >
            <span v-if="!$v.formData.user_id.required">Please enter a user.</span>
            <span v-if="!$v.formData.user_id.serverFailed">
              {{ serverErrors.user_id }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.policy_number"
          label-for="insurance-certificate-policy_number"
        >
          <b-form-input
            name="insurance-certificate-policy_number"
            v-model="$v.formData.policy_number.$model"
            :state="$v.formData.policy_number.$dirty ? !$v.formData.policy_number.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.formData.policy_number.$dirty"
            class="insurance-certificate-policy_number-feedback"
          >
            <span v-if="!$v.formData.policy_number.required">Please enter a policy number.</span>
            <!-- prettier-ignore -->
            <span v-if="!$v.formData.policy_number.maxLength">
              The policy number must be less than {{ $v.formData.policy_number.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.formData.policy_number.serverFailed">{{ serverErrors.policy_number }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.category"
          label-for="insurance-certificate-category"
        >
          <category-select
            name="insurance-certificate-category"
            v-model="$v.formData.category.$model"
            :select-class="{ 'is-invalid': $v.formData.category.$dirty && $v.formData.category.$error }"
            :options="categoryOptions"
          />
          <b-form-invalid-feedback
            v-if="$v.formData.category.$dirty"
            class="insurance-certificate-category-feedback"
          >
            <span v-if="!$v.formData.category.required">Please enter a category.</span>
            <span v-if="!$v.formData.category.maxLength">
              The category must be less than {{ $v.formData.category.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.formData.category.serverFailed">{{ serverErrors.category }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          v-if="newForm"
          :label="fieldLabels.start_date"
          label-for="insurance-certificate-start_date"
        >
          <sfs-date-picker
            name="insurance-certificate-start_date"
            v-model="$v.formData.start_date.$model"
            :class="{ 'is-invalid': $v.formData.start_date.$dirty && $v.formData.start_date.$error }"
            :with-class="{ 'is-invalid': $v.formData.start_date.$dirty && $v.formData.start_date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.formData.start_date.$dirty"
            :state="$v.formData.start_date.$dirty ? !$v.formData.start_date.$error : null"
            class="insurance-certificate-start_date-feedback"
          >
            <span v-if="!$v.formData.start_date.serverFailed">
              {{ serverErrors.start_date }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          v-if="newForm"
          :label="fieldLabels.end_date"
          label-for="insurance-certificate-end_date"
        >
          <sfs-date-picker
            name="insurance-certificate-end_date"
            v-model="$v.formData.end_date.$model"
            :class="{ 'is-invalid': $v.formData.end_date.$dirty && $v.formData.end_date.$error }"
            :with-class="{ 'is-invalid': $v.formData.end_date.$dirty && $v.formData.end_date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.formData.end_date.$dirty"
            :state="$v.formData.end_date.$dirty ? !$v.formData.end_date.$error : null"
            class="insurance-certificate-end_date-feedback"
          >
            <span v-if="!$v.formData.end_date.required">Please enter a coverage end date.</span>
            <span v-if="!$v.formData.end_date.serverFailed">
              {{ serverErrors.end_date }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.email"
          label-for="insurance-certificate-email"
        >
          <b-form-input
            name="insurance-certificate-email"
            v-model="$v.formData.email.$model"
            autocomplete="new-password"
            :state="$v.formData.email.$dirty ? !$v.formData.email.$error : null"
            type="text"
            placeholder="example@email.com"
          />
          <b-form-invalid-feedback
            v-if="$v.formData.email.$dirty"
            class="insurance-certificate-email-feedback"
          >
            <span v-if="!$v.formData.email.email">Please enter a valid email.</span>
            <span v-if="!$v.formData.email.maxLength">
              The email must be less than {{ $v.formData.email.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.formData.email.serverFailed">{{ serverErrors.email }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.phone"
          label-for="insurance-certificate-phone"
        >
          <b-form-input
            name="insurance-certificate-phone"
            v-model="$v.formData.phone.$model"
            :state="$v.formData.phone.$dirty ? !$v.formData.phone.$error : null"
            type="text"
            placeholder="(201) 555-5555"
          />
          <b-form-invalid-feedback
            v-if="$v.formData.phone.$dirty"
            class="insurance-certificate-phone-feedback"
          >
            <span v-if="!$v.formData.phone.maxLength">
              The phone must be less than {{ $v.formData.phone.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.formData.phone.serverFailed">{{ serverErrors.phone }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group label-for="insurance-certificate-reminder_days">
          <template #label>
            <span>{{ fieldLabels.reminder_days }}</span>
            <i
              id="reminder-days-popover"
              class="far fa-question-circle ml-2"
            />
            <b-popover
              target="reminder-days-popover"
              triggers="hover"
              placement="top"
            >
              This determines the number of days before the certificate expires to send reminder emails.
            </b-popover>
          </template>
          <b-input-group append="Days">
            <b-form-input
              name="insurance-certificate-reminder_days"
              v-model="$v.formData.reminder_days.$model"
              :state="$v.formData.reminder_days.$dirty ? !$v.formData.reminder_days.$error : null"
              type="text"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.formData.reminder_days.$dirty"
            :state="$v.formData.reminder_days.$dirty ? !$v.formData.reminder_days.$error : null"
            class="insurance-certificate-reminder_days-feedback"
          >
            <span v-if="!$v.formData.reminder_days.required">Please enter a number.</span>
            <span v-if="!$v.formData.reminder_days.integer">Please enter a number.</span>
            <span v-if="!$v.formData.reminder_days.serverFailed">
              {{ serverErrors.reminder_days }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="fieldLabels.reminder_frequency"
          label-for="insurance-certificate-reminder_frequency"
        >
          <frequency-select
            name="insurance-certificate-reminder_frequency"
            v-model="$v.formData.reminder_frequency.$model"
            :select-class="{ 'is-invalid': $v.formData.reminder_frequency.$dirty && $v.formData.reminder_frequency.$error }"
            :options="reminderFrequencyOptions"
            placeholder="Only on the day of expiry"
          />
          <b-form-invalid-feedback
            v-if="$v.formData.reminder_frequency.$dirty"
            class="insurance-certificate-reminder_frequency-feedback"
          >
            <span v-if="!$v.formData.reminder_frequency.serverFailed">{{ serverErrors.reminder_frequency }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group label-for="insurance-certificate-requires_coverage_verification">
          <b-form-checkbox
            switch
            class="insurance-certificate-requires_coverage_verification"
            v-model="$v.formData.requires_coverage_verification.$model"
          >
            {{ fieldLabels.requires_coverage_verification }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="insurance-certificate-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="insurance-certificate-submit-button"
          block
          type="submit"
          variant="primary"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength, integer, email, requiredIf } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import UserSelect from '@/components/admin/users/Select.vue';
import FrequencySelect from '@/components/shared/FrequencySelect.vue';
import CategorySelect from '@/components/admin/insuranceCertificates/CategorySelect.vue';
import { USER_TECHNICIAN_CATEGORY } from '@/constants/users';

export default {
  name: 'InsuranceCertificatesForm',
  components: {
    UserSelect,
    FrequencySelect,
    CategorySelect,
  },
  mixins: [formMixin],
  props: {
    policy_number: String,
    category: String,
    email: String,
    phone: String,
    reminder_frequency: String,
    reminder_days: Number,
    user_id: Number,
    user: Object,
    requires_coverage_verification: Boolean,
    start_date: String,
    end_date: String,
    newForm: {
      type: Boolean,
      default: true,
    },
    reminderFrequencyOptions: {
      type: Array,
      default: () => [],
    },
    categoryOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formData: {
        policy_number: this.policy_number,
        category: this.category,
        email: this.email,
        phone: this.phone,
        reminder_frequency: this.reminder_frequency,
        reminder_days: this.reminder_days,
        user_id: this.user_id,
        user: this.user,
        requires_coverage_verification: this.requires_coverage_verification,
        start_date: this.start_date,
        end_date: this.end_date,
      },
      fieldLabels: {
        policy_number: 'Policy # *',
        category: 'Category *',
        email: 'Insurance Email',
        phone: 'Insurance Phone',
        reminder_frequency: 'Reminder Frequency',
        reminder_days: 'Reminder Days *',
        user_id: 'User *',
        requires_coverage_verification: 'Does this insurance certificate require coverage verification?',
        start_date: 'Coverage Start',
        end_date: 'Coverage End *',
      },
      userSelectParams: { category: USER_TECHNICIAN_CATEGORY, active: true },
    };
  },
  validations: {
    formData: {
      policy_number: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('policy_number');
        },
      },
      category: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('category');
        },
      },
      email: {
        email,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('email');
        },
      },
      phone: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('phone');
        },
      },
      reminder_frequency: {
        serverFailed() {
          return !this.hasServerErrors('reminder_frequency');
        },
      },
      reminder_days: {
        integer,
        required,
        serverFailed() {
          return !this.hasServerErrors('reminder_days');
        },
      },
      user_id: {
        required: requiredIf(function () {
          return this.newForm;
        }),
        serverFailed() {
          return !this.hasServerErrors('user_id');
        },
      },
      requires_coverage_verification: {},
      start_date: {
        serverFailed() {
          return !this.hasServerErrors('start_date');
        },
      },
      end_date: {
        required: requiredIf(function () {
          return this.newForm;
        }),
        serverFailed() {
          return !this.hasServerErrors('end_date');
        },
      },
    },
  },
  computed: {
    form() {
      return {
        policy_number: this.formData.policy_number,
        category: this.formData.category,
        email: this.formData.email,
        phone: this.formData.phone,
        reminder_frequency: this.formData.reminder_frequency,
        reminder_days: this.formData.reminder_days,
        user_id: this.formData.user_id,
        requires_coverage_verification: this.formData.requires_coverage_verification,
        current_coverage_attributes: {
          start_date: this.formData.start_date,
          end_date: this.formData.end_date,
        },
      };
    },
  },
  methods: {
    onUserChange(user) {
      this.formData.user_id = user ? user.id : null;
      this.formData.user = user;
    },
  },
};
</script>
