
export default {
  paramName: 'photo',
  maxFilesize: 5,
  resizeHeight: 768,
  resizeWidth: 1366,
  resizeQuality: 1,
  acceptedFiles: 'image/png,image/jpg,image/jpeg',
  resizeMimeType: 'image/jpeg',
  parallelUploads: 1,
};
