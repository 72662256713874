<template>
  <sfs-base-list-filter
    title="Filter Technician"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
    :column-picker="false"
  >
    <template #quick-filters>
      <b-form-group
        label="Technician"
        label-for="quick-filter-user_id"
        label-sr-only
      >
        <div class="d-flex">
          <user-select
            id="quick-filter-user_id"
            placeholder="Select Technician"
            style="min-width: fit-content;"
            :value="user"
            @input="onUserChange"
            :persist-state="true"
          />
          <b-input-group-append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </b-input-group-append>
        </div>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col>
          <b-form-group
            label="Date Bucket"
            label-for="quick-filter-dates"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <sfs-date-range-picker
              id="quick-filter-dates"
              :value="datesRange"
              placeholder="Select Dates"
              @on-change="onDatesChange"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Client"
            label-for="quick-filter-client_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <client-select
              id="quick-filter-client_id"
              placeholder="All"
              :value="client"
              @input="onClientChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Manager"
            label-for="quick-filter-manager_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <employee-select
              id="quick-filter-manager_id"
              placeholder="All"
              :value="manager"
              @input="onManagerChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            class="mb-4"
            label="State"
            label-for="quick-filter-state"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-input
              id="quick-filter-state"
              v-model="filters.state"
              placeholder="All"
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Service Performed"
            label-for="quick-filter-service_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <service-select
              id="quick-filter-service_id"
              placeholder="All"
              :value="service"
              @input="onServiceChange"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template #right-tray>
      <sfs-download-button
        v-b-tooltip.hover
        :provider-params="filters"
        filename="Technician Forecast.csv"
        :file-provider="$analyticsAPI.getTechnicianForecastReportSpreadsheet"
      />
    </template>
  </sfs-base-list-filter>
</template>
<script>
import ServiceSelect from '@/components/admin/services/Select.vue';
import ClientSelect from '@/components/admin/clients/Select.vue';
import EmployeeSelect from '@/components/admin/users/EmployeeSelect.vue';
import UserSelect from '@/components/admin/users/Select.vue';

import listFilterMixin from '@/mixins/listFilterMixin';
import {
  WORK_ORDER_TECHNICIAN_FORECAST_REPORT_TABLE_NAME,
  TECHNICIAN_FORECAST_REPORT_TABLE_COLUMN_OPTIONS,
  TECHNICIAN_FORECAST_REPORT_TABLE_DEFAULT_COLUMNS,
}
from '@/constants/reports';

export default {
  name: 'TechnicianListFilter',
  mixins: [listFilterMixin],
  components: {
    ServiceSelect,
    ClientSelect,
    EmployeeSelect,
    UserSelect,
  },
  data() {
    return {
      tableName: WORK_ORDER_TECHNICIAN_FORECAST_REPORT_TABLE_NAME,
      availableColumns: TECHNICIAN_FORECAST_REPORT_TABLE_COLUMN_OPTIONS,
    };
  },
  mounted() {
    this.loadInitialFilters();
  },
  methods: {
    applyPresetFilter(filters, columns) {
      this.filters = filters;
      this.displayedColumns = columns;
      this.applyFilters();
    },
    onDefaultPresetApplied() {
      this.applyPresetFilter({}, TECHNICIAN_FORECAST_REPORT_TABLE_DEFAULT_COLUMNS);
    },
    onServiceChange(service) {
      this.filters.service_id = service ? service.id.toString() : '';
      this.service = service;
    },
    onClientChange(client) {
      this.filters.client_id = client ? client.id.toString() : '';
      this.client = client;
    },
    onManagerChange(manager) {
      this.filters.manager_id = manager ? manager.id.toString() : '';
      this.manager = manager;
    },
    onUserChange(user) {
      this.filters.user_id = user ? user.id.toString() : '';
      this.user = user;
    },
    onDatesChange(dates) {
      if (dates[0] && dates[1]) {
        this.filters.start_date = dates[0] ? this.$options.filters.dateFormat(dates[0].toISOString()) : '';
        this.filters.end_date = dates[1] ? this.$options.filters.dateFormat(dates[1].toISOString()) : '';
      }
    },
    loadInitialFilters() {
      const loadClient = new Promise((resolve) => {
        if (this.initialFilters.client_id) {
          this.$clientsAPI.get(this.initialFilters.client_id).then((client) => {
            resolve(client);
          });
        } else {
          resolve(null);
        }
      });

      const loadService = new Promise((resolve) => {
        if (this.initialFilters.service_id) {
          this.$servicesAPI.get(this.initialFilters.service_id).then((service) => {
            resolve(service);
          });
        } else {
          resolve(null);
        }
      });
      // The order of the resolved promises is preserved so we are able to use
      // indexes to fetch the data.
      Promise.all([loadClient, loadService]).then((values) => {
        this.client = values[0];
        this.service = values[1];
        this.applyFilters();
      });
    },
  },
  computed: {
    datesRange() {
      return [this.filters.start_date, this.filters.end_date];
    }
  },
};
</script>
