<template>
  <div :class="containerClass">
    <div class="d-inline mr-1">
      <b-dropdown
        id="pagination-per-page-dropdown"
        size="sm"
        variant="outline-secondary"
      >
        <template #button-content>
          <span class="font-weight-bold">{{ firstItem }}-{{ lastItem }}</span>
        </template>
        <b-dropdown-group>
          <b-dropdown-item
            :active="isActive(10)"
            @click="$emit('per-page-changed', 10)"
          >
            10 per page
          </b-dropdown-item>
          <b-dropdown-item
            :active="isActive(20)"
            @click="$emit('per-page-changed', 20)"
          >
            20 per page
          </b-dropdown-item>
          <b-dropdown-item
            :active="isActive(30)"
            @click="$emit('per-page-changed', 30)"
          >
            30 per page
          </b-dropdown-item>
        </b-dropdown-group>
      </b-dropdown>
    </div>
    <div class="d-inline total-section">
      of {{ totalRows }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaginationDropdown',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
    containerClass: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    firstItem() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    lastItem() {
      const count = this.perPage * this.currentPage;
      return count < this.totalRows ? count : this.totalRows;
    },
  },
  methods: {
    isActive(num) {
      return num == this.perPage;
    },
  },
};
</script>
