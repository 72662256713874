<template>
  <b-form @submit.stop.prevent="onSubmit">
    <b-row v-if="qrCode">
      <b-col
        class="text-center"
        cols="12"
      >
        <h3 class="mb-1">
          Register Multi-Factor Authenticator
        </h3>
        <p class="mb-0">
          In order to login, Please setup multi-factor authentication.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <div class="push text-center mb-0">
          <b-img :src="qrCode" />
        </div>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        class="pt-4"
      >
        <p>Scan the QR code with your preferred authenticator app.</p>
        <p>
          Supported authenticator apps include: Google Authenticator, Microsoft Authenticator, LastPass
          Authenticator and Authy.
        </p>
      </b-col>
    </b-row>

    <b-alert
      v-model="incorrectCode"
      variant="danger"
      dismissible
    >
      The one-time code you entered is incorrect.
    </b-alert>
    <b-alert
      :show="hasBaseServerErrors()"
      variant="danger"
      dismissible
    >
      <p
        class="mb-0"
        v-for="(error, index) in baseServerErrors"
        :key="index"
      >
        {{ error }}
      </p>
    </b-alert>

    <div>
      <b-form-group
        :label="fieldLabels.otp_attempt"
        label-for="user-otp_attempt"
      >
        <b-form-input
          id="user-otp_attempt"
          v-model="$v.form.otp_attempt.$model"
          :state="$v.form.otp_attempt.$dirty ? !$v.form.otp_attempt.$error : null"
          type="text"
        />
        <b-form-invalid-feedback
          v-if="$v.form.otp_attempt.$dirty"
          id="user-otp_attempt-feedback"
        >
          <span v-if="!$v.form.otp_attempt.required">Please enter a one-time code.</span>
          <span v-if="!$v.form.otp_attempt.serverFailed">
            {{ serverErrors.otp_attempt }}
          </span>
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
    <b-row class="form-group">
      <b-col>
        <b-button
          type="submit"
          variant="primary"
          block
        >
          <b-spinner
            v-if="processing"
            small
            label="Logging In..."
            class="mr-1"
          />
          <i
            v-else
            class="fa fa-fw fa-sign-in-alt mr-1"
          />
          Register Multi-Factor App
        </b-button>
      </b-col>
    </b-row>
    <b-link @click="backToLogin">
      Back to Login
    </b-link>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';

export default {
  name: 'RegisterForm',
  mixins: [formMixin],
  props: {
    email: {
      type: String,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        email: this.email,
        password: this.password,
        otp_attempt: null,
      },
      fieldLabels: {
        otp_attempt: 'One-time Code',
      },
      incorrectCode: false,
      processing: false,
      qrCode: null,
    };
  },
  validations: {
    form: {
      otp_attempt: {
        required,
        serverFailed() {
          return !this.hasServerErrors('otp_attempt');
        },
      },
    },
  },
  methods: {
    onSubmit() {
      this.resetServerErrors();
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }
      this.processing = true;
      this.$store
        .dispatch('login', this.form)
        .then(() => {
          this.$emit('on-login');
        })
        .catch((error) => {
          this.processing = false;
          this.processServerErrors(error?.response?.data?.errors);
          this.incorrectCode = !this.hasBaseServerErrors();
        });
    },
    registerMfaAuth() {
      this.$authAPI
        .registerMfaAuth({ email: this.form.email, password: this.form.password })
        .then((response) => {
          this.qrCode = response.data.otp_qr_code;
        });
    },
    backToLogin() {
      this.$emit('on-reset-login');
    },
  },
  mounted() {
    this.registerMfaAuth();
  },
};
</script>
