<template>
  <b-input-group>
    <flat-pickr
      :id="pickerId"
      :name="name"
      :value="convertToZoneTime(value, { fromZone: timeZone })"
      :config="dateTimeConfig"
      :class="{ 'flat-pickr': true, 'form-control': true, 'border-right-0': true, ... withClass }"
      @input="(date) => $emit('input', convertToZoneTime(date, { toZone: timeZone }))"
    />
    <b-input-group-append
      class="flatpickr-icon-container"
      @click="openFlatpickr"
    >
      <i class="si si-calendar" />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { DateTime } from 'luxon';
import flatpickr from 'flatpickr';
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import { uniqueId as _uniqueId } from 'lodash';

export default {
  name: 'DateTimePicker',
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    value: {
      type: String,
    },
    static: {
      type: Boolean,
      default: true,
    },
    timeZone: {
      type: String,
      default: null,
    },
    withClass: {
      type: Object,
    },
  },
  data() {
    return {
      dateTimeConfig: {
        wrap: false,
        allowInput: true,
        altInput: true,
        altFormat: 'M j, Y, h:i K',
        enableTime: true,
        static: this.static,
        dateFormat: 'Z',
        plugins: [new ConfirmDatePlugin({
          confirmIcon: '<i class=\'fa fa-check ml-2\'></i>', // your icon's html, if you wish to override
          confirmText: 'OK',
          showAlways: false,
          theme: 'light'
        })],
      },
      pickerId: this.id || `sfs-date-time-picker-${_uniqueId()}`,
    };
  },
  methods: {
    convertToZoneTime(isoDateTime, { fromZone = null, toZone = null }) {
      if (isoDateTime) {
        const oldDateTime = DateTime.fromISO(isoDateTime, { zone: fromZone });
        const newDateTime = DateTime.fromObject({
          year: oldDateTime.year,
          month: oldDateTime.month,
          day: oldDateTime.day,
          hour: oldDateTime.hour,
          minute: oldDateTime.minute,
          second: oldDateTime.second,
          millisecond: oldDateTime.millisecond,
        }, { zone: toZone });
        return newDateTime.toUTC().toISO();
      } else {
        return isoDateTime;
      }
    },
    openFlatpickr() {
      const fp = flatpickr(`#${this.pickerId}`, this.dateTimeConfig);
      fp.open();
    }
  },
};
</script>
