<template>
  <b-card class="h-100">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <div>
        <div v-if="$slots.content">
          <slot name="content" />
        </div>
        <div v-else>
          <h2 class="dashboard-content mb-2 font-w400">
            {{ content }}
          </h2>
        </div>
        <div class="dashboard-title text-muted">
          {{ title }}
        </div>
      </div>
      <div v-if="$slots.secondary">
        <slot name="secondary" />
      </div>
      <div
        v-else
        class="item item-rounded-lg bg-body text-alt-secondary opacity-75"
      >
        <i :class="iconClass" />
      </div>
    </div>
    <b-row v-if="href">
      <b-col
        cols="auto"
        class="mr-auto ml-auto"
      >
        <b-button
          class="dashboard-link mt-2"
          variant="outline-secondary"
          :to="href"
        >
          <span v-if="$slots.hrefContent">
            <slot name="hrefContent" />
          </span>
          <span v-else>
            {{ hrefContent }}
          </span>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'DashboardCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    href: {
      type: Object,
    },
    iconClass: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    hrefContent: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.item-rounded-lg {
  border-radius: 1.5rem;
}
</style>
