<template>
  <!-- using autocomplete value "new-password" instead of "off" seems to disable autocomplete better in some browsers. -->
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :value="value"
    @search="onSearch"
    :filterable="false"
    @input="onChange"
    autocomplete="new-password"
  >
    <template #option="location">
      <div>
        <span>{{ location.store_number }}</span>
        <span v-if="location.name"> - {{ location.name }}</span>
      </div>
      <div class="font-size-sm text-secondary">
        {{ location.street }}
      </div>
      <div class="font-size-sm text-secondary">
        {{ { city: location.city, state: location.state } | locationFormat }}
      </div>
    </template>
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        No results found for
        <em>{{ search }}</em>
      </template>
      <em
        style="opacity: 0.7"
        v-else
      >Search by store number or address</em>
    </template>
  </v-select>
</template>
<script>
import { debounce as _debounce } from 'lodash';

export default {
  name: 'TechnicianLocationsSelect',
  props: {
    value: Object,
    id: {
      type: String,
      default: 'location',
    },
    clientId: {
      type: [String, Number],
    },
    selectLabel: {
      type: String,
      default: 'store_number',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: String,
  },
  data() {
    return {
      options: [],
      perPage: 20,
    };
  },
  methods: {
    onSearch(text, loading) {
      if (text.length) {
        loading(true);
        this.filterLocations(text, loading, this);
      }
    },
    filterLocations: _debounce((text, loading, vm) => {
      const params = {
        text: text,
        client_id: vm.clientId,
        active: true,
        sort_by: 'store_number',
        sort_desc: false,
        per_page: vm.perPage,
      };
      vm.$technicianLocationsAPI.getLocations(params).then((response) => {
        vm.options = response.locations;
        loading(false);
      });
    }, 500),
    onChange(location) {
      this.$emit('input', location);
    },
  },
  watch: {
    clientId(newVal, oldVal) {
      if (newVal != oldVal) {
        this.options = [];
      }
    },
  },
};
</script>
