<template>
  <sfs-form-modal
    :id="modalId"
    title="Renew Coverage"
    @on-hide="resetFormData"
  >
    <insurance-certificate-coverages-form
      @on-submit="create"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import InsuranceCertificateCoveragesForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'InsuranceCertificateAddModal',
  mixins: [formModalMixin],
  components: {
    InsuranceCertificateCoveragesForm,
  },
  props: {
    insuranceCertificateId: { type: Number, required: true },
  },
  data() {
    return {
      modalId: 'insurance-certificate-coverage-add-modal',
    };
  },
  methods: {
    create(data, handleCreate) {
      handleCreate(this.$insuranceCertificateCoveragesAPI.create(this.insuranceCertificateId, data));
    },
    onSave() {
      this.closeModal();
      this.resetFormData();
      this.$emit('on-save');
    },
  },
};
</script>
