<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          v-if="isLoaded"
          ref="work-orders-revenue-table"
          :items="records"
          :fields="columns"
          :busy="isLoadingData"
          :sort-by.sync="sorting.sortBy"
          :sort-desc.sync="sorting.sortDesc"
          @sort-changed="handleSortingChanged"
          no-local-sorting
          show-empty
          responsive
          table
        >
          <template #cell(period)="data">
            <div>
              {{ data.item.group }}
            </div>
          </template>
          <template #cell(revenue)="data">
            <money-format
              :value="data.item.total_revenue"
              subunits-value
            />
          </template>
          <template #cell(work_orders)="data">
            <div>
              {{ data.item.total_work_orders | numberWithDelimeter }}
            </div>
          </template>
          <template #cell(total_cost)="data">
            <div>
              <money-format
                :value="data.item.total_cost"
                subunits-value
              />
            </div>
          </template>
          <template #cell(profit)="data">
            <div>
              <money-format
                :value="data.item.profit"
                subunits-value
              />
            </div>
          </template>
          <template #cell(margin)="data">
            <div>
              {{ `${Math.floor(data.item.margin)}%` }}
            </div>
          </template>
        </b-table>
        <sfs-skeleton-table
          v-else
          :rows="pagination.perPage"
          :columns="columns.length"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="auto"
        :class="{ 'mr-auto': true, 'ml-auto': !showPerPage }"
      >
        <b-pagination
          v-if="showPagination"
          v-model="pagination.currentPage"
          :total-rows="pagination.maxResults > totalRows ? totalRows : pagination.maxResults"
          :per-page="pagination.perPage"
          last-class="d-none"
        />
      </b-col>
      <sfs-pagination-dropdown
        v-if="showPerPage"
        container-class="col-auto"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="totalRows"
        @per-page-changed="onPerPageChanged"
      />
    </b-row>
  </div>
</template>

<script>
import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';
import translationMixin from '@/mixins/translationMixin';
import { WORK_ORDER_REVENUE_REPORT_TABLE_COLUMNS } from '@/constants/reports';

export default {
  name: 'RevenueReportTable',
  mixins: [urlMixin, tableMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: WORK_ORDER_REVENUE_REPORT_TABLE_COLUMNS,
    },
    revenueTableParams: {
      type: Object
    },
  },
  methods: {
    getRecords() {
      this.isLoadingData = true;
      this.$analyticsAPI
        .getWorkOrdersRevenueReport({ ...this.params, ...this.revenueTableParams })
        .then((response) => {
          const { total, records } = response;
          this.totalRows = total;
          this.records = records;
        })
        .catch(() => {
          this.records = [];
        })
        .finally(() => {
          this.loaded = true;
          this.isLoadingData = false;
          this.$emit('data-changed', this.records);
        });
    },
  }
};
</script>
