<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col>
        <b-alert
          :show="hasBaseServerErrors()"
          variant="danger"
          dismissible
        >
          <p
            class="mb-0"
            v-for="(error, index) in baseServerErrors"
            :key="index"
          >
            {{ error }}
          </p>
        </b-alert>
      </b-col>
    </b-row>

    <b-form-group
      :label="fieldLabels.name"
      label-for="gl-code-name"
    >
      <b-form-input
        name="gl-code-name"
        v-model="$v.form.name.$model"
        :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
        type="text"
      />
      <b-form-invalid-feedback
        v-if="$v.form.name.$dirty"
        class="gl-code-name-feedback"
      >
        <span v-if="!$v.form.name.required">Please enter a name.</span>
        <span v-if="!$v.form.name.maxLength">
          The name must be less than {{ $v.form.name.$params.maxLength.max + 1 }} characters.
        </span>
        <span v-if="!$v.form.name.serverFailed">{{ serverErrors.name }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.code"
      label-for="gl-code-code"
    >
      <b-form-input
        name="gl-code-code"
        v-model="$v.form.code.$model"
        :state="$v.form.code.$dirty ? !$v.form.code.$error : null"
        type="text"
      />
      <b-form-invalid-feedback
        v-if="$v.form.code.$dirty"
        class="gl-code-code-feedback"
      >
        <span v-if="!$v.form.code.required">Please enter a code.</span>
        <span v-if="!$v.form.code.maxLength">
          The code must be less than {{ $v.form.code.$params.maxLength.max + 1 }} characters.
        </span>
        <span v-if="!$v.form.code.serverFailed">{{ serverErrors.code }}</span>
      </b-form-invalid-feedback>
    </b-form-group>


    <b-form-group
      :label="fieldLabels.category"
      label-for="gl-code-category"
    >
      <category-select
        name="gl-code-category"
        v-model="form.category"
        :select-class="{ 'is-invalid': $v.form.category.$dirty && $v.form.category.$error }"
      />
      <b-form-invalid-feedback
        v-if="$v.form.category.$dirty"
        class="gl-code-category-feedback"
      >
        <span v-if="!$v.form.category.required">Please enter a category.</span>
        <span v-if="!$v.form.category.maxLength">
          The category must be less than {{ $v.form.category.$params.maxLength.max + 1 }} characters.
        </span>
        <span v-if="!$v.form.category.serverFailed">{{ serverErrors.category }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.netsuite_expense_class_id"
      label-for="gl-code-netsuite_expense_class_id"
    >
      <net-suite-record-select
        name="gl-code-netsuite_expense_class_id"
        :value="form.netsuite_expense_class_id"
        @input="onExpenseClassificationChange"
        reference-type="Service"
        :select-class="{ 'is-invalid': $v.form.netsuite_expense_class_id.$dirty && $v.form.netsuite_expense_class_id.$error }"
      />
      <b-form-invalid-feedback
        v-if="$v.form.netsuite_expense_class_id.$dirty"
        class="gl-code-netsuite_expense_class_id-feedback"
      >
        <span v-if="!$v.form.netsuite_expense_class_id.maxLength">
          The expense class must be less than {{ $v.form.netsuite_expense_class_id.$params.maxLength.max + 1 }} characters.
        </span>
        <span v-if="!$v.form.netsuite_expense_class_id.serverFailed">{{ serverErrors.netsuite_expense_class_id }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.organization_id"
      label-for="gl-code-organization_id"
    >
      <organization-select
        name="gl-code-organization_id"
        :select-class="{ 'is-invalid': $v.form.organization_id.$dirty && $v.form.organization_id.$error }"
        :value="form.organization"
        @input="onOrganizationChange"
      />
      <b-form-invalid-feedback
        v-if="$v.form.organization_id.$dirty"
        class="gl-code-organization_id-feedback"
      >
        <span v-if="!$v.form.organization_id.required">Please enter an organization.</span>
        <span v-if="!$v.form.organization_id.serverFailed">
          {{ serverErrors.organization }}
        </span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.client_id"
      label-for="gl-code-client_id"
    >
      <client-select
        name="gl-code-client_id"
        :select-class="{ 'is-invalid': $v.form.client_id.$dirty && $v.form.client_id.$error }"
        :value="form.client"
        @input="onClientChange"
      />
      <b-form-invalid-feedback
        v-if="$v.form.client_id.$dirty"
        class="gl-code-client_id-feedback"
      >
        <span v-if="!$v.form.client_id.serverFailed">
          {{ serverErrors.client }}
        </span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label-for="gl-code-grouped">
      <b-form-checkbox
        switch
        name="gl-code-grouped"
        v-model="$v.form.grouped.$model"
      >
        Is this GL Code grouped?
      </b-form-checkbox>
    </b-form-group>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="gl-code-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="gl-code-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import ClientSelect from '@/components/admin/clients/Select.vue';
import OrganizationSelect from '@/components/admin/organizations/Select.vue';
import CategorySelect from '@/components/admin/glCodes/CategorySelect.vue';
import NetSuiteRecordSelect from '@/components/admin/integrationReferences/NetSuiteRecordSelect.vue';

export default {
  name: 'GlCodesForm',
  components: {
    OrganizationSelect,
    ClientSelect,
    CategorySelect,
    NetSuiteRecordSelect,
  },
  mixins: [formMixin],
  props: {
    organization_id: [String, Number],
    client_id: [String, Number],
    name: String,
    code: String,
    category: String,
    netsuite_expense_class_id: [String, Number],
    organization: Object,
    client: Object,
    grouped: Boolean,
  },
  data() {
    return {
      form: {
        organization_id: this.organization_id,
        client_id: this.client_id,
        name: this.name,
        code: this.code,
        category: this.category,
        netsuite_expense_class_id: this.netsuite_expense_class_id,
        organization: this.organization,
        client: this.client,
        grouped: this.grouped,
      },
      fieldLabels: {
        organization_id: 'Organization *',
        client_id: this.$store.getters.translate('client', 'Client'),
        name: 'Name *',
        code: 'Code *',
        netsuite_expense_class_id: 'NetSuite Expense Class',
        category: `${this.$store.getters.translate('gl_codes.category', 'Category')} *`,
        grouped: 'Grouped',
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('name');
        },
      },
      code: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('code');
        },
      },
      category: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('category');
        },
      },
      netsuite_expense_class_id: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('netsuite_expense_class_id');
        },
      },
      organization_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('organization');
        },
      },
      client_id: {
        serverFailed() {
          return !this.hasServerErrors('client');
        },
      },
      grouped: {},
    },
  },
  methods: {
    onOrganizationChange(organization) {
      this.form.organization_id = organization ? organization.id : '';
      this.form.organization = organization;
    },
    onClientChange(client) {
      this.form.client_id = client ? client.id : null;
      this.form.client = client;
    },
    onExpenseClassificationChange(netsuiteClass) {
      this.form.netsuite_expense_class_id = netsuiteClass.id;
    },
  },
};
</script>
