<template>
  <sfs-form-modal
    :id="modalId"
    title="Add a Recurring Work Order"
    @on-hide="resetFormData"
  >
    <recurring-work-order-form
      @on-submit="create"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
      :location_id="locationId"
    />
  </sfs-form-modal>
</template>

<script>
import RecurringWorkOrderForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'RecurringWorkOrderAddModal',
  mixins: [formModalMixin],
  components: {
    RecurringWorkOrderForm,
  },
  props: {
    locationId: Number,
  },
  data() {
    return {
      modalId: 'recurring-work-order-add-modal',
    };
  },
  methods: {
    create(data, handleCreate) {
      handleCreate(this.$recurringWorkOrdersAPI.create(this.locationId, data));
    },
    onSave(recurringWorkOrder) {
      this.closeModal();
      this.resetFormData();
      this.$emit('on-save', recurringWorkOrder);
    },
  },
};
</script>
