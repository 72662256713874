import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const COMMUNICATION_TABLE_NAME = 'communications';
export const INSURANCE_CERTIFICATE_COMMUNICATION_TABLE_NAME = 'insuranceCertificateCommunications';
export const LIST_MODAL_COMMUNICATION_TABLE_NAME = 'listModalCommunications';

// The columns available to be shown in a clients table.
export const COMMUNICATION_TABLE_COLUMNS = {
  title: { label: 'Title', key: 'title' },
  category: { label: 'Category', key: 'category' },
  sent_at: { label: 'Sent On', key: 'sent_at' },
  read_at: { label: 'Read On', key: 'read_at' },
  details: { label: 'Details', key: 'details' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const COMMUNICATION_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(COMMUNICATION_TABLE_COLUMNS);

// The default columns to be shown when the clients table loads.
export const COMMUNICATION_TABLE_DEFAULT_COLUMNS = [
  COMMUNICATION_TABLE_COLUMNS.title,
  COMMUNICATION_TABLE_COLUMNS.category,
  COMMUNICATION_TABLE_COLUMNS.sent_at,
  COMMUNICATION_TABLE_COLUMNS.details,
];
