<template>
  <div>
    <base-page-heading title="Users">
      <template #extra>
        <b-button
          v-if="$can('create', 'User')"
          class="mr-2"
          v-b-modal.user-add-modal
          variant="primary"
          v-b-tooltip.hover
          title="Create a new User"
        >
          <i class="fa fa-plus mr-1" />
          Create User
        </b-button>

        <b-button
          v-if="$can('create', 'Import')"
          variant="outline-secondary"
          class="mr-2"
          v-b-modal.users-imports-add-modal
          v-b-tooltip.hover
          title="Import new Users"
        >
          <i class="fa fa-file-excel mr-1" />
          Import
        </b-button>

        <b-dropdown
          variant="outline-secondary"
          right
          v-b-tooltip.hover
          title="Download Spreadsheets"
        >
          <template #button-content>
            <b-spinner
              v-if="downloading"
              small
              label="Downloading..."
            />
            <i
              v-else
              class="fa fa-download mr-1"
            />
            Downloads
          </template>
          <b-dropdown-item @click="downloadSpreadsheet">
            Users
          </b-dropdown-item>
          <b-dropdown-item
            @click="downloadRecurringPaymentsSpreadsheet"
            v-if="$can('read', 'RecurringPayment')"
          >
            Recurring Payments
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <users-list-filter
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>

      <users-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>

    <!-- Page Modals -->
    <users-new-modal v-if="$can('create', 'User')" />
    <users-import-new-modal v-if="$can('create', 'Import')" />
  </div>
</template>

<script>
import UsersNewModal from '@/components/admin/users/NewModal.vue';
import UsersTable from '@/components/admin/users/Table.vue';
import UsersImportNewModal from '@/components/admin/imports/UsersNewModal.vue';
import UsersListFilter from '@/components/admin/users/ListFilter.vue';
import listMixin from '@/mixins/listMixin';
import downloadMixin from '@/mixins/downloadMixin';
import { USER_TABLE_NAME } from '@/constants/users';

export default {
  name: 'UsersList',
  components: {
    UsersNewModal,
    UsersImportNewModal,
    UsersTable,
    UsersListFilter,
  },
  mixins: [downloadMixin, listMixin],
  props: {
    text: String,
    category: String,
    services_provided: Array,
    manager_id: String,
    role_ids: Array,
    state: String,
    active: String,
    client_id: String,
    qr_code_access: String,
    technician_portal_access: String,
  },
  data() {
    return {
      tableName: USER_TABLE_NAME,
      filters: {
        text: this.text,
        category: this.category,
        services_provided: this.services_provided,
        manager_id: this.manager_id,
        role_ids: this.role_ids,
        active: this.active,
        state: this.state,
        client_id: this.client_id || this.$store.getters?.client?.id,
        qr_code_access: this.qr_code_access,
        technician_portal_access: this.technician_portal_access,
      },
    };
  },
  methods: {
    downloadSpreadsheet() {
      this.download(this.$usersAPI.getSpreadsheet, this.filters, 'Users.csv');
    },
    downloadRecurringPaymentsSpreadsheet() {
      this.download(this.$recurringPaymentsAPI.getSpreadsheet, this.filters, 'Recurring Payments.csv');
    },
  },
};
</script>
