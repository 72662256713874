<template>
  <sfs-base-list-filter
    title="Filter Additional Charges"
    :table-name="additionalChargesTableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :column-picker="false"
  >
    <template #quick-filters>
      <b-form-group
        :label="fieldLabels.search"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Description or Service name"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #advanced-filters>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.description"
            label-for="advanced-filter-description"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-description"
              v-model="filters.description"
              type="text"
              :placeholder="fieldLabels.description"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.category"
            label-for="advanced-filter-category"
            label-sr-only
          >
            <category-select
              id="advanced-filter-category"
              v-model="filters.category"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            class="mb-0"
            :label="fieldLabels.service"
            label-for="additional-charges-advanced-filter-service_id"
            label-sr-only
          >
            <service-select
              id="additional-charges-advanced-filter-service_id"
              placeholder="Select a Service"
              :value="service"
              @input="onServiceChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="fieldLabels.active"
            label-for="additional-charges-advanced-filter-active"
            label-sr-only
          >
            <b-form-radio-group
              id="additional-charges-advanced-filter-active"
              v-model="filters.active"
              :options="activeOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template #right-tray>
      <sfs-download-button
        v-b-tooltip.hover
        class="ml-2"
        filename="Additional Charges.csv"
        :file-provider="getAdditionalChargeSpreadsheet"
        title="Download spreadsheet"
      />
      <slot name="right-tray" />
    </template>
  </sfs-base-list-filter>
</template>
<script>
import listFilterMixin from '@/mixins/listFilterMixin';
import CategorySelect from '@/components/admin/additionalCharges/CategorySelect.vue';
import ServiceSelect from '@/components/admin/services/Select.vue';

import { ADDITIONAL_CHARGES_TABLE_DEFAULT_COLUMNS, ADDITIONAL_CHARGES_TABLE_COLUMN_OPTIONS, ADDITIONAL_CHARGES_TABLE_NAME } from '@/constants/additionalCharges';

export default {
  name: 'AdditionalChargesListFilter',
  mixins: [listFilterMixin],
  components: {
    CategorySelect,
    ServiceSelect,
  },
  data() {
    return {
      availableColumns: ADDITIONAL_CHARGES_TABLE_COLUMN_OPTIONS,
      additionalChargesTableName: ADDITIONAL_CHARGES_TABLE_NAME,
      additionalChargesDefaultColumns: ADDITIONAL_CHARGES_TABLE_DEFAULT_COLUMNS,
      fieldLabels: {
        search: 'Filter fields for Data',
        description: 'Description',
        category: 'Category',
        service: 'Service',
        active: 'Active or Inactive',
      },
    };
  },
  mounted() {
    this.applyFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = this.additionalChargesDefaultColumns;
      this.applyFilters();
    },
    getAdditionalChargeSpreadsheet() {
      return this.$additionalChargesAPI.getAdditionalChargeSpreadsheet(this.$route.params.id);
    },
    onServiceChange(service) {
      this.filters.service_id = service ? service.id.toString() : '';
      this.service = service;
    },
  },
};
</script>
