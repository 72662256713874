<template>
  <sfs-base-list-filter
    title="Filter Users"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
    :column-picker="false"
  >
    <template #quick-filters>
      <b-form-group
        placeholder="Filter by Name, Email, Phone, City or State"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Name, Email, Phone, City or State"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col>
          <b-form-group
            label="Name"
            label-for="popover-filter-name"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-input
              id="popover-filter-name"
              v-model="filters.name"
              type="text"
              placeholder="All"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-4">

      <b-row>
        <b-col>
          <b-form-group
            label="Email"
            label-for="popover-filter-email"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-input
              id="popover-filter-email"
              v-model="filters.email"
              type="text"
              placeholder="All"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-4">

      <b-row>
        <b-col>
          <b-form-group
            label="Phone Number"
            label-for="popover-filter-phone"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-input
              id="popover-filter-phone"
              v-model="filters.phone"
              type="text"
              placeholder="All"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-4">

      <b-row>
        <b-col>
          <b-form-group
            label="Active?"
            label-for="popover-filter-active"
            label-class="pt-0"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-radio-group
              id="popover-filter-active"
              v-model="filters.active"
              :options="activeOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import listFilterMixin from '@/mixins/listFilterMixin';
import { TECHNICIAN_USER_TABLE_COLUMN_OPTIONS, TECHNICIAN_USER_TABLE_DEFAULT_COLUMNS, TECHNICIAN_USER_TABLE_NAME } from '@/constants/users';

export default {
  name: 'TechnicianUsersListFilter',
  mixins: [listFilterMixin],
  data() {
    return {
      availableColumns: TECHNICIAN_USER_TABLE_COLUMN_OPTIONS,
      tableName: TECHNICIAN_USER_TABLE_NAME,
    };
  },
  mounted() {
    this.applyFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = TECHNICIAN_USER_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
  },
};
</script>
