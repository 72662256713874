<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$technicianPaymentsAPI.getTechnicianPayments"
    provider-key="technicianPayments"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(number)="data">
      <b-link :to="{ name: 'technician-payment', params: { id: data.item.id } }">
        {{ data.item.number }}
      </b-link>
    </template>
    <template #cell(user_id)="data">
      <b-link :to="{ name: 'user', params: { id: data.item.user.id } }">
        {{ data.item.user.name }}
      </b-link>
    </template>
    <template #cell(approved_at)="data">
      <b-badge :variant="statusVariant(data.item)">
        {{ status(data.item) }}
      </b-badge>
    </template>
    <template #cell(start_date)="data">
      {{ data.item | dateRangeFormat("start_date", "end_date") }}
    </template>
    <template #cell(due_date)="data">
      {{ data.item.due_date | dateFormat }}
    </template>
    <template #cell(balance)="data">
      <money-format
        :value="data.item.balance.amount"
        :currency-code="data.item.balance.currency"
        subunits-value
      />
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';

import technicianPaymentsMixin from '@/mixins/technicianPaymentsMixin';
import { TECHNICIAN_PAYMENT_TABLE_DEFAULT_COLUMNS } from '@/constants/technicianPayments';

export default {
  name: 'TechnicianPaymentsTable',
  mixins: [tablePropsMixin, technicianPaymentsMixin],
  props: {
    columns: {
      type: Array,
      default: () => TECHNICIAN_PAYMENT_TABLE_DEFAULT_COLUMNS,
    },
  },
};
</script>
