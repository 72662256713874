<template>
  <sfs-form-modal
    :id="modalId"
    title="Building Structure"
    @on-hide="resetFormData"
    size="lg"
  >
    <sections-tree
      v-if="!loading"
      :sections="sections"
      :location-id="location.id"
      @input="handleChange"
    />

    <b-row>
      <b-col
        cols="3"
        offset="9"
      >
        <b-button
          variant="primary"
          block
          class="sections-manage-modal-close-button"
          @click="closeModal"
        >
          Finish
        </b-button>
      </b-col>
    </b-row>
  </sfs-form-modal>
</template>
<script>
import formModalMixin from '@/mixins/formModalMixin';
import { createSectionTree } from '@/utils/sections';
import SectionsTree from '@/components/admin/sections/Tree.vue';
export default {
  name: 'SectionsManageModal',
  mixins: [formModalMixin],
  components: {
    SectionsTree,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalId: `location-${this.location.id}-section-manage-modal`,
      sections: [],
      loading: false,
    };
  },
  mounted() {
    this.getSections();
  },
  methods: {
    getSections() {
      this.loading = true;
      this.$sectionsAPI
        .getLocationSections(this.location.id, { sort_by: 'name', paginate: false })
        .then((response) => {
          this.sections = createSectionTree(response.sections.map( section => ({ id: section.id, name: section.name, parent_id: section.parent_id })));
        })
        .catch(() => {
          this.sections = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateSection(section, updates) {
      this.$sectionsAPI.update(section.id, updates);
    },
    handleChange({ parent, changes }) {
      const movedSection = changes.added?.element;
      if (movedSection && movedSection.id) {
        this.updateSection(movedSection, { parent_id: parent?.id === undefined ? null : parent.id });
      }
    }
  },
};
</script>
