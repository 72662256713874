<template>
  <div
    :style="`width: ${collapsed ? collapsedWidth : fullWidth}`"
    class="base-sidebar-menu-container"
    @mouseover="() => delayCollapse(false, this)"
    @mouseleave="() => delayCollapse(true, this)"
  >
    <sidebar-menu
      :menu="items"
      :collapsed="collapsed"
      @item-click="(_event, item) => handleItemClicked(item)"
      disable-hover
      hide-toggle
      :width="fullWidth"
      :width-collapsed="collapsedWidth"
    >
      <template #header>
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="collapsed"
            key="collapsed"
            class="push text-center mb-2 mt-3 px-2"
          >
            <b-img
              width="32"
              height="32"
              class="base-sidebar-logo"
              :src="logoIcon"
            />
            <hr class="mt-3 mb-0">
          </div>
          <div
            v-if="!collapsed"
            key="uncollapsed"
            class="mb-2 mt-3 px-2"
          >
            <b-img
              class="base-sidebar-logo"
              height="32"
              :src="logo"
            />
            <hr class="mt-3 mb-0">
          </div>
        </transition>
      </template>
    </sidebar-menu>
  </div>
</template>

<script>
import { debounce as _debounce } from 'lodash';
import logo from '@/assets/images/sidebar-logo.png';
import logoIcon from '@/assets/images/sidebar-logo-icon.png';

export default {
  name: 'BaseSidebarMenu',
  props: {
    items: {
      type: Array,
      required: true,
      description: 'The items for the menu',
    },
  },
  data() {
    return {
      collapsedWidth: '58px',
      fullWidth: '240px',
      collapsed: true,
      logo: logo,
      logoIcon: logoIcon,
    };
  },
  methods: {
    delayCollapse: _debounce((isCollapsed, vm) => {
      vm.collapsed = isCollapsed;
    }, 250),
    handleItemClicked(menuItem) {
      if (menuItem.action) {
        menuItem.action();
      }

      if (menuItem?.modalId) {
        this.$bvModal.show(menuItem.modalId);
      }
    },
  }
};
</script>

<style scoped>
.base-sidebar-menu-container {
    position: fixed;
    box-sizing: border-box;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    transition: 0.3s max-width ease;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.15s ease, visibility 0.15s ease;
  /* transition: opacity .15s ease; */

}
.fade-enter, .fade-leave-to {
  opacity: 0;
  visibility: hidden !important;
}
</style>
