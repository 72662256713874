<template>
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :value="value"
    @input="(locale) => $emit('input', locale)"
    :reduce="(locale) => locale.value"
  />
</template>
<script>
export default {
  name: 'LocaleSelect',
  props: {
    value: String,
    id: {
      type: String,
      default: 'locale-select',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: {
      type: String,
      default: 'Select a Locale',
    },
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getLocaleOptions();
  },
  methods: {
    getLocaleOptions() {
      this.$authAPI.getLocaleOptions().then((response) => {
        this.options = response.data.locale_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
      });
    },
  },
};
</script>
