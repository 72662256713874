<template>
  <b-tabs
    :key="sectionTabsKey"
    :value="sectionIndex"
    class="section-tabs mr-0 ml-0"
    vertical
    nav-wrapper-class="secton-tabs-nav pl-0 pr-5"
    content-class="pt-0 pb-3 px-0"
    @activate-tab="handleSectionTabChanged"
  >
    <b-tab
      v-for="section in sectionTree"
      :key="section.id"
      :title-link-class="`nav-depth-${section.depth} mr-3`"
    >
      <template #title>
        <b-row>
          <b-col
            cols="10"
            class="mr-auto"
          >
            <div class="section-nav-title">
              {{ section.name }}
            </div>
          </b-col>
          <b-col
            cols="2"
            v-if="itemsForSection(section.id).length > 0"
            class="text-right"
          >
            <b-badge
              class="font-size-sm"
              variant="primary"
            >
              {{ itemsForSection(section.id).length }}
            </b-badge>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-3">
        <b-col>
          <h3 class="flex-sm-fill">
            {{ section.name }}
          </h3>
        </b-col>
        <b-col
          cols="auto"
          class="mr-auto"
        >
          <b-button
            v-if="$can('create', 'WalkthroughItem')"
            variant="outline-secondary"
            class="mr-1"
            @click="() => handleNewItemAdded(section.id)"
          >
            Add Note
          </b-button>
        </b-col>
      </b-row>

      <b-row
        v-if="itemsForSection(section.id).length === 0 && !newItemVisible"
        class="px-5"
      >
        <b-col>
          You haven't added any notes yet
        </b-col>
      </b-row>

      <edit-item
        v-for="item in itemsForSection(section.id)"
        :key="item.id"
        :value="item"
        :sections="sectionTree"
        @input="(updatedItem) => updateItem(updatedItem)"
        @on-moved="(updatedItem) => moveItem(updatedItem)"
        @on-destroy="() => destroyItem(item)"
        @on-photo-uploaded="(photo) => addItemPhoto(item.id, photo)"
        @on-photo-destroyed="(photo) => destroyItemPhoto(item.id, photo)"
      />

      <edit-item
        :value="newItem"
        :sections="sectionTree"
        :input-ref="newItemInputRef"
        @on-content-changed="(changedContent) => newItem.content = changedContent"
        @input="(updatedItem) => updateNewItem(updatedItem)"
        @on-moved="(updatedItem) => moveNewItem(updatedItem)"
        @on-destroy="resetNewItem"
        @on-photo-uploaded="(photo) => addNewItemPhoto(photo)"
        @on-photo-destroyed="(photo) => destroyNewItemPhoto(photo)"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import EditItem from '@/components/admin/walkthroughItems/EditItem.vue';
import { isEqual as _isEqual, isUndefined as _isUndefined } from 'lodash';
import { DEFAULT_GENERAL_SECTION, createFlattenedSectionTree } from '@/utils/sections';

export default {
  name: 'ItemsManager',
  components: {
    EditItem,
  },
  props: {
    walkthroughId: {
      type: Number,
      required: true,
    },
    sections: {
      type: Array,
      default: () => [{ ...DEFAULT_GENERAL_SECTION }],
    }
  },
  data() {
    return {
      sectionTabsKey: Date.now(),
      sectionIndex: 0,
      sectionTree: [],
      items: [],
      newItem: {},
    };
  },
  methods: {
    resetSectionTabs() {
      this.sectionTabsKey = Date.now();
    },
    buildSectionTrees() {
      this.sectionTree = createFlattenedSectionTree(this.sections);
    },
    getWalkthroughItems() {
      return this.$walkthroughsAPI
        .getItems(this.walkthroughId, { paginate: false })
        .then(({ walkthroughItems }) => {
          this.items = walkthroughItems;
        });
    },
    /********************************
    *** Methods for editing items ***
    ********************************/
    updateItem(updatedItem) {
      const itemIndex = this.items.findIndex((item) => item.id === updatedItem.id);
      if (itemIndex !== -1) {
        this.items[itemIndex] = updatedItem;
      }
    },
    moveItem(updatedItem) {
      const itemIndex = this.items.findIndex((item) => item.id === updatedItem.id);
      const sectionIndex = this.sectionTree.findIndex((section) => section.id === updatedItem.section_id);
      if (itemIndex !== -1) {
        this.items[itemIndex] = updatedItem;
        this.sectionIndex = sectionIndex;
        this.resetSectionTabs();
      }
    },
    destroyItem(item) {
      const itemIndex = this.items.findIndex((currentItem) => currentItem.id === item.id);
      if (itemIndex !== -1) {
        this.items.splice(itemIndex, 1);
      }
    },
    addItemPhoto(itemId, photo) {
      const itemIndex = this.items.findIndex((item) => item.id === itemId);
      if (itemIndex !== -1) {
        this.items[itemIndex].photos.push(photo);
      }
    },
    destroyItemPhoto(itemId, photo) {
      const itemIndex = this.items.findIndex((item) => item.id === itemId);
      const photoIndex = this.items[itemIndex].photos.findIndex((currPhoto) => currPhoto.id === photo.id);
      if (photoIndex !== -1) {
        this.items[itemIndex].photos.splice(photoIndex, 1);
      }
    },
    /**************************************
    *** Methods for managing a new item ***
    **************************************/
    createNewItem(sectionId) {
      return this.$walkthroughsAPI
      .createItem(this.walkthroughId, { section_id: sectionId, content: 'No notes' })
      .then((walkthroughItem) => {
        this.newItem = {...walkthroughItem, content: '' };
      });
    },
    updateNewItem(updatedItem) {
      if (updatedItem.id === this.newItem.id) {
        this.newItem = updatedItem;
      }
    },
    moveNewItem(updatedItem) {
      this.updateNewItem(updatedItem);
      const sectionIndex = this.sectionTree.findIndex((section) => section.id === updatedItem.section_id);
      this.sectionIndex = sectionIndex;
      this.resetSectionTabs();
    },
    destroyNewItem() {
      return this.$walkthroughsAPI.destroyItem(this.newItem.id).then(() => this.resetNewItem());
    },
    addNewItemPhoto(photo) {
      this.newItem.photos.push(photo);
    },
    destroyNewItemPhoto(photo) {
      const photoIndex = this.newItem.photos.findIndex((currPhoto) => currPhoto.id === photo.id);
      if (photoIndex !== -1) {
        this.newItem.photos.splice(photoIndex, 1);
      }
    },
    resetNewItem() {
      this.newItem = new Object();
    },
    /**************************************
    *** Misc methods for managing items ***
    **************************************/
    handleNewItemAdded(sectionId) {
      if (!this.newItemVisible) {
        this.createNewItem(sectionId);
      } else if (this.newItemEdited) {
        const oldNewItem = { ...this.newItem };
        this.createNewItem(sectionId).then(() => this.items.push(oldNewItem));
      }
    },
    handleSectionTabChanged() {
      if (this.newItemVisible) {
        if (this.newItemEdited) {
          this.items.push(this.newItem);
          this.resetNewItem();
        } else {
          this.destroyNewItem();
        }
      }
    }
  },
  mounted() {
    this.buildSectionTrees();
    this.getWalkthroughItems();
  },
  destroyed() {
    // When the user is done with updating the items, remove the new note if it
    // wasn't actually edited (note added or photo uploaded).
    if (this.newItemVisible && !this.newItemEdited) {
      this.destroyNewItem();
    }
  },
  computed: {
    itemsForSection() {
      return (sectionId) => {
        return this.items.filter((item) => item.section_id === sectionId);
      };
    },
    showEmptyState() {
      return this.items.length === 0 && !this.newItemVisible;
    },
    newItemVisible() {
      return Object.keys(this.newItem).length > 0;
    },
    newItemEdited() {
      return this.newItemVisible && (this.newItem.content.length > 0 || this.newItem.photos.length > 0);
    },
    newItemInputRef() {
      return `add-item-${this.newItem?.id}`;
    },
  },
  watch: {
    sections: {
      deep: true,
      handler(newSections, oldSections) {
        if (!_isEqual(oldSections, newSections) && !_isUndefined(newSections)) {
          this.buildSectionTrees();
        }
      },
    }
  },
};
</script>

<style #scoped>
  .section-tabs .nav-tabs, .section-tabs .tab-content {
    flex-wrap: nowrap;
    overflow-y: auto;
  }
  .section-tabs .tab-content {
    height: 100%;
    overflow-x: hidden;
  }
  .section-tabs .nav-item {
    margin-bottom: 0.25rem;
  }
  .section-tabs, .section-tabs .nav-tabs {
    height: 762px;
    max-height: 762px;
  }
  .secton-tabs-nav {
    width: 35%;
  }
</style>
