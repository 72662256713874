import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const ADDITIONAL_CHARGES_MODEL_NAME = 'additional_charges';
export const ADDITIONAL_CHARGES_TABLE_NAME = 'additionalCharges';

// The columns available to be shown in a additionalCharges table.
export const ADDITIONAL_CHARGES_TABLE_COLUMNS = {
  id: { label: 'ID', key: 'id' },
  description: { label: 'Description', key: 'description' },
  category: { label: 'Category', key: 'category' },
  cost: { label: 'Cost (Tech Price)', key: 'cost' },
  price: { label: 'Price (DNE)', key: 'price' },
  service_id: { label: 'Service', key: 'service_id' },
  client_id: { label: 'Client', key: 'client_id'},
  deleted_at: { label: 'Active', key: 'deleted_At'},
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const ADDITIONAL_CHARGES_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(ADDITIONAL_CHARGES_TABLE_COLUMNS);

// The default columns to be shown when the additionalCharges table loads.
export const ADDITIONAL_CHARGES_TABLE_DEFAULT_COLUMNS = [
  ADDITIONAL_CHARGES_TABLE_COLUMNS.description,
  ADDITIONAL_CHARGES_TABLE_COLUMNS.category,
  ADDITIONAL_CHARGES_TABLE_COLUMNS.cost,
  ADDITIONAL_CHARGES_TABLE_COLUMNS.price,
  ADDITIONAL_CHARGES_TABLE_COLUMNS.actions,
];
