import { isEqual as _isEqual, isUndefined as _isUndefined } from 'lodash';
import { FONT_FAMILY, CHART_COLOUR_PALETTE } from '@/constants';

export default {
  props: {
    seriesType: {
      type: String,
      default: 'datetime',
    },
    series: {
      Type: Array,
      default: () => new Array(),
    },
    colors: {
      type: Array,
      default: () => CHART_COLOUR_PALETTE,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    legendEnabled: {
      type: Boolean,
      default: true,
    },
    skeletonAnimation: {
      type: String,
      default: 'wave'
    },
    skeletonHeight: {
      type: String,
      default: '400px'
    },
    skeletonWidth: {
      type: String,
      default: '100%'
    },
    chartHeight: {
      type: String,
      default: '100%',
    },
    chartWidth: {
      type: String,
      default: '100%',
    },
    categories: {
      Type: Array,
      default: () => new Array(),
    },
    xAxisTitle: {
      type: String,
      default: '',
    },
    xAxisLabelsEnabled: {
      type: Boolean,
      default: true,
    },
    xAxisCrosshair: {
      type: Boolean,
      default: true,
    },
    yAxisTitle: {
      type: String,
      default: '',
    },
    yAxisLabelsEnabled: {
      type: Boolean,
      default: true,
    },
    yAxisMin: {
      type: Number,
      default: undefined,
    },
    yAxisLabelFormatter: {
      type: Function,
      default: (val) => val,
    },
    gridLineColor: {
      type: String,
      default: '#e7ecfe',
    },
    fontColor: {
      type: String,
      default: '#343A40',
    },
    fontSize: {
      type: String,
      default: '0.85rem',
    },
    titleFontWeight: {
      type: String,
      default: '500',
    },
    titleFontSize: {
      type: String,
      default: '1.25rem',
    },
    titleAlign: {
      type: String,
      default: 'center',
    },
    subtitleFontSize: {
      type: String,
      default: '1rem',
    },
    fillOpacity: {
      type: Number,
      default: 0.85,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    toolbarEnabled: {
      type: Boolean,
      default: false,
    },
    gridEnabled: {
      type: Boolean,
      default: false,
    },
    zoomEnabled: {
      type: Boolean,
      default: false,
    },
    tooltipDateFormat: {
      type: String,
      default: 'MMM d, yyyy',
    },
    shareTooltip: {
      type: Boolean,
      default: false,
    },
    intersectTooltip: {
      type: Boolean,
      default: false,
    },
    strokeWidth: {
      type: Number,
      default: 2,
    },
    barBorderRadius: {
      type: Number,
      default: 3,
    },
    borderRadiusWhenStacked: {
      type: String,
      default: 'last',
    },
    markerSize: {
      type: Number,
      default: 0,
    },
    dataFunction: {
      type: [Array, Function],
    },
    dataParameters: {
      type: Object,
      default: () => new Object(),
    },
  },
  data() {
    return {
      chartType: 'line',
      isLoading: true,
    };
  },
  mounted() {
    this.loadChart();
  },
  methods: {
    loadChart() {
      this.isLoading = true;
      if (!this.dataFunction) {
        this.isLoading = false;
      }
      else if (Array.isArray(this.dataFunction)) {
        this.dataFunction.forEach((dataFunction) => dataFunction().finally(() => this.isLoading = false));
      }
      else {
        this.dataFunction().finally(() => this.isLoading = false);
      }
    },
  },
  watch: {
    'dataParameters.filters': {
      deep: true,
      handler(newFilters, oldFilters) {
        if (!_isEqual(oldFilters, newFilters) && !_isUndefined(newFilters)) {
          this.loadChart();
        }
      }
    },
    'dataParameters.groupPeriod': {
      handler(newPeriod, oldPeriod) {
        if (!_isEqual(oldPeriod, newPeriod) && !_isUndefined(newPeriod)) {
          this.loadChart();
        }
      }
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          fontFamily: FONT_FAMILY,
          stacked: this.stacked,
          height: this.chartHeight,
          width: this.chartWidth,
          toolbar: {
            show: this.toolbarEnabled,
          },
          zoom: { enabled: this.zoomEnabled }
        },
        colors: this.colors,
        title: {
          text: this.title,
          align: this.titleAlign,
          offsetY: 20,
          style: {
            color: this.fontColor,
            fontSize: this.titleFontSize,
            fontWeight: this.titleFontWeight,
            fontFamily: FONT_FAMILY,
          },
        },
        subtitle: {
          text: this.subtitle,
          style: {
            color: this.fontColor,
            fontSize: this.subtitleFontSize,
            fontWeight: this.titleFontWeight,
          },
        },
        legend: {
          show: this.legendEnabled,
        },
        tooltip: {
          shared: this.shareTooltip,
          intersect: this.intersectTooltip,
          style: {
            color: this.fontColor,
            fontSize: this.fontSize,
          },
          x: {
            format: this.tooltipDateFormat,
          },
        },
        dataLabels: { enabled: false },
        plotOptions: {
          bar: {
            borderRadius: this.barBorderRadius,
            borderRadiusWhenStacked: this.borderRadiusWhenStacked,
          },
        },
        markers: { size: this.markerSize, },
        grid: { show: this.gridEnabled },
        stroke: {
          width: this.strokeWidth,
        },
        fill: {
          opacity: this.fillOpacity,
        },
        xaxis: {
          type: this.seriesType,
          categories: this.categories,
          title: {
            text: this.xAxisTitle,
            style: {
              color: this.fontColor,
              fontSize: this.fontSize,
              fontWeight: this.titleFontWeight,
              fontFamily: FONT_FAMILY,
            },
          },
          crosshair: {
            show: this.xAxisCrosshair,
          },
          labels: {
            show: this.xAxisLabelsEnabled,
            style: {
              color: this.fontColor,
              fontSize: this.fontSize,
              fontFamily: FONT_FAMILY,
            },
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM \'yy',
              day: 'MMM d',
              hour: 'HH:mm'
            }
          },
          axisTicks: { show: this.gridEnabled },
          axisBorder: { show: this.gridEnabled }
        },
        yaxis: {
          min: this.yAxisMin,
          showForNullSeries: false,
          title: {
            text: this.yAxisTitle,
            style: {
              color: this.fontColor,
              fontSize: this.fontSize,
              fontWeight: this.titleFontWeight,
              fontFamily: FONT_FAMILY,
            },
          },
          gridLineColor: this.gridLineColor,
          labels: {
            show: this.yAxisLabelsEnabled,
            formatter: this.yAxisLabelFormatter,
            style: {
              colors: [this.fontColor],
              fontSize: this.fontSize,
              fontFamily: FONT_FAMILY,
            },
          },
        },
      };
    },
  },
};
