<template>
  <sfs-base-list-filter
    title="Filter Work Orders"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    :column-picker="false"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
  >
    <template #quick-filters>
      <b-form-group
        :label="fieldLabels.date_bucket"
        label-for="quick-filter-date_bucket"
        label-sr-only
      >
        <div class="d-flex">
          <sfs-date-range-picker
            id="quick-filter-date_bucket"
            :value="dateBucket"
            placeholder="Select Date Buckets"
            @on-change="onDateBucketChange"
          />
          <b-input-group-append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </b-input-group-append>
        </div>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Manager"
            label-for="advanced-filter-manager_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <employee-select
              id="advanced-filter-manager_id"
              placeholder="All"
              :value="manager"
              @input="onManagerChange"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Client"
            label-for="advanced-filter-client_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <client-select
              id="advanced-filter-client_id"
              placeholder="All"
              :value="client"
              @input="onClientChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Select a Status"
            label-for="advanced-filter-statuses"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <v-select
              id="advanced-filter-statuses"
              v-model="filters.statuses"
              :options="statusOptions"
              placeholder="All"
              :reduce="(status) => status.value"
              multiple
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.assigned_to_id"
            label-for="advanced-filter-assigned_to_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <user-select
              id="advanced-filter-assigned_to_id"
              placeholder="All"
              :value="assignedTo"
              @input="onAssignedToChange"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Checked Out Between"
            label-for="advanced-filter-checked_out_from"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <sfs-date-range-picker
              id="quick-filter-checked_out"
              v-model="checkedOutRange"
              placeholder="All"
              @on-change="onCheckedOutRangeChanged"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.location_id"
            label-for="advanced-filter-location_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <location-select
              id="advanced-filter-location_id"
              :client-id="filters.client_id"
              placeholder="All"
              :value="location"
              @input="onLocationChange"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="State"
            label-for="advanced-filter-state"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-input
              id="advanced-filter-state"
              v-model="filters.state"
              type="text"
              placeholder="All"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <template #right-tray>
      <sfs-download-button
        v-b-tooltip.hover
        :provider-params="filters"
        filename="Work Orders Profitability.csv"
        :file-provider="$analyticsAPI.getWorkOrderProfitabilitySpreadsheet"
        title="Download spreadsheet"
      />
      <b-button
        variant="outline-secondary"
        v-b-modal.work-order-profitability-email-modal
        v-b-tooltip.hover
        title="Email Profitability Report"
        class="ml-2"
      >
        <i class="fa fa-envelope" />
      </b-button>
      <profitability-email-modal
        :email="$store.getters.user.email"
        :params="filters"
      />
    </template>
  </sfs-base-list-filter>
</template>
<script>
import LocationSelect from '@/components/admin/locations/Select.vue';
import ClientSelect from '@/components/admin/clients/Select.vue';
import UserSelect from '@/components/admin/users/Select.vue';
import EmployeeSelect from '@/components/admin/users/EmployeeSelect.vue';
import ProfitabilityEmailModal from '@/components/admin/reports/workOrders/ProfitabilityEmailModal.vue';
import listFilterMixin from '@/mixins/listFilterMixin';

import {
  WORK_ORDER_TABLE_COLUMN_OPTIONS,
  WORK_ORDER_TABLE_DEFAULT_COLUMNS,
  WORK_ORDER_FORM_LABELS,
} from '@/constants/workOrders';
import { WORK_ORDER_PROFITABILITY_REPORT_TABLE_NAME } from '@/constants/reports';
export default {
  name: 'WorkOrderListFilter',
  mixins: [listFilterMixin],
  components: {
    LocationSelect,
    UserSelect,
    ClientSelect,
    EmployeeSelect,
    ProfitabilityEmailModal,
  },
  data() {
    return {
      availableColumns: WORK_ORDER_TABLE_COLUMN_OPTIONS,
      fieldLabels: WORK_ORDER_FORM_LABELS,
      tableName: WORK_ORDER_PROFITABILITY_REPORT_TABLE_NAME,
      statusOptions: [],
      checkedOutRange: [
        this.initialFilters.checked_out_from,
        this.initialFilters.checked_out_to,
      ],
    };
  },
  mounted() {
    this.getStatusOptions();
    this.loadInitialFilters();
  },
  methods: {
    applyPresetFilter(filters, columns) {
      this.filters = filters;
      this.displayedColumns = columns;
      this.applyFilters();
    },
    onDefaultPresetApplied() {
      this.applyPresetFilter({}, WORK_ORDER_TABLE_DEFAULT_COLUMNS);
    },
    onLocationChange(location) {
      this.filters.location_id = location ? location.id.toString() : '';
      this.location = location;
    },
    onClientChange(client) {
      this.filters.client_id = client ? client.id.toString() : '';
      this.client = client;
    },
    onAssignedToChange(user) {
      this.filters.assigned_to_id = user ? user.id.toString() : '';
      this.assignedTo = user;
    },
    onManagerChange(user) {
      this.filters.manager_id = user ? user.id.toString() : '';
      this.manager = user;
    },
    onDateBucketChange(dates) {
      if (dates[0] && dates[1]) {
        this.filters.date_bucket_start_from = dates[0] ? this.$options.filters.dateFormat(dates[0].toISOString()) : '';
        this.filters.date_bucket_end_to = dates[1] ? this.$options.filters.dateFormat(dates[1].toISOString()) : '';
        this.applyFilters();
      }
    },
    onCheckedOutRangeChanged(dates) {
      if (dates[0]) {
        this.filters.checked_out_from = this.$options.filters.dateFormat(dates[0].toISOString());
      } else {
        this.filters.checked_out_from = '';
      }
      if (dates[1]) {
        this.filters.checked_out_to = this.$options.filters.dateFormat(dates[1].toISOString());
      } else {
        this.filters.checked_out_to = '';
      }
    },
    getStatusOptions() {
      this.$workOrdersAPI.getStatusOptions().then((response) => {
        const options = response.data.status_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
        this.statusOptions = [...options];
      });
    },
    humanize(words) {
      return this.$options.filters.capitalizeFormat(this.$options.filters.humanizedFormat(words));
    },
    downloadSpreadsheet() {
      this.download(this.$workOrdersAPI.getSpreadsheet, this.filters, 'Work Orders.csv');
    },
    loadInitialFilters() {
      const loadLocation = new Promise((resolve) => {
        if (this.initialFilters.location_id) {
          this.$locationsAPI.get(this.initialFilters.location_id).then((location) => {
            resolve(location);
          });
        } else {
          resolve(null);
        }
      });

      const loadClient = new Promise((resolve) => {
        if (this.initialFilters.client_id) {
          this.$clientsAPI.get(this.initialFilters.client_id).then((client) => {
            resolve(client);
          });
        } else {
          resolve(null);
        }
      });

      const loadAssignedTo = new Promise((resolve) => {
        if (this.initialFilters.assigned_to_id) {
          this.$usersAPI.get(this.initialFilters.assigned_to_id).then((assignedTo) => {
            resolve(assignedTo);
          });
        } else {
          resolve(null);
        }
      });

      const loadManager = new Promise((resolve) => {
        if (this.initialFilters.manager_id) {
          this.$usersAPI.get(this.initialFilters.manager_id).then((manager) => {
            resolve(manager);
          });
        } else {
          resolve(null);
        }
      });
      // The order of the resolved promises is preserved so we are able to use
      // indexes to fetch the data.
      Promise.all([loadLocation, loadClient, loadAssignedTo, loadManager]).then((values) => {
        this.location = values[0];
        this.client = values[1];
        this.assignedTo = values[2];
        this.manager = values[3];
        this.applyFilters();
      });
    },
  },
  computed: {
    dateBucket() {
      return [this.filters.date_bucket_start_from, this.filters.date_bucket_end_to];
    },
    dateCheckedOut() {
      return [this.filters.checked_out_from, this.filters.checked_out_to];
    }
  },
};
</script>
