import List from '@/pages/admin/technicianPayments/List.vue';
import Show from '@/pages/admin/technicianPayments/Show.vue';
import handlePaginationRouteProps from '@/utils/handlePaginationRouteProps.js';

export default [
  {
    path: 'technician_payments',
    name: 'technician-payments',
    component: List,
    props: handlePaginationRouteProps,
  },
  {
    path: 'technician_payments/:id(\\d+)',
    name: 'technician-payment',
    component: Show,
  },
];
