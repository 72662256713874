import List from '@/pages/technician/users/List';
import Show from '@/pages/technician/users/Show';
import handleActivePaginationRouteProps from '@/utils/handleActivePaginationRouteProps.js';

export default [
  {
    path: 'users',
    name: 'technician-users',
    component: List,
    props: handleActivePaginationRouteProps,
  },
  {
    path: 'users/:id(\\d+)',
    name: 'technician-user',
    component: Show,
    meta: { requiresAuth: true },
  },
];
