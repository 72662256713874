<template>
  <b-form-radio-group
    :id="id"
    :options="options"
    :multiple="multiple"
    :class="{ ...selectClass, 'radio-btn-group': true }"
    :checked="value"
    @input="onChange"
    :disabled="disabled"
    button-variant="gray"
    buttons
  />
</template>
<script>
export default {
  name: 'CategoryButtonSelect',
  props: {
    value: [String, Array],
    id: {
      type: String,
      default: 'charge-category-select',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getCategoryOptions();
  },
  methods: {
    getCategoryOptions() {
      this.$store.cache.dispatch('getWorkOrderChargeCategories').then((categoryOptions) => {
        this.options = categoryOptions.map((option) => {
          return { text: option[0], value: option[1] };
        });
      });
    },
    onChange(category) {
      this.$emit('input', category);
    },
  },
};
</script>
