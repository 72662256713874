<template>
  <!-- using autocomplete value "new-password" instead of "off" seems to disable autocomplete better in some browsers. -->
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :value="value"
    @search="onSearch"
    @input="(supply) => $emit('input', supply)"
    :filterable="false"
    autocomplete="new-password"
  >
    <template #option="supply">
      <div>
        {{ supply.item_number }} - {{ supply.units }}
      </div>
      <div class="font-size-sm text-secondary">
        {{ supply.description }}
      </div>
    </template>
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        No results found for
        <em>{{ search }}</em>
      </template>
      <em
        style="opacity: 0.7"
        v-else
      >Type to search for supplies</em>
    </template>
  </v-select>
</template>
<script>
import { debounce as _debounce } from 'lodash';

export default {
  name: 'SuppliesSelect',
  props: {
    value: Object,
    id: {
      type: String,
      default: 'supplies',
    },
    selectLabel: {
      type: String,
      default: 'item_number',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: String,
    vendorId:  Number,
    clientId: Number,
  },
  data() {
    return {
      options: [],
    };
  },
  methods: {
    onSearch(text, loading) {
      if (text.length) {
        loading(true);
        this.filterSupplies(text, loading, this);
      }
    },
    filterSupplies: _debounce((text, loading, vm) => {
      vm.endpoint({
        text: text,
        sort_by: 'item_number',
        paginate: false,
      }).then((response) => {
        vm.options = response.supplies;
        loading(false);
      });
    }, 500),
    endpoint(params) {
      if (this.vendorId) {
        return this.$suppliesAPI.getVendorSupplies(this.vendorId, params);
      } else if (this.clientId) {
        return this.$suppliesAPI.getClientSupplies(this.clientId, params);
      } else {
        return this.$suppliesAPI.getSupplies(params);
      }
    },
  },
};
</script>
