<template>
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :value="value"
    @input="(service) => $emit('input', service)"
    :disabled="disabled"
  >
    <template #option="service">
      <div>{{ service.name }}</div>
      <div
        v-if="service.client"
        class="font-size-sm text-secondary"
      >
        {{ service.client.code }}
      </div>
    </template>
  </v-select>
</template>
<script>
import { uniqBy as _uniqBy } from 'lodash';
export default {
  name: 'ServicesSelect',
  props: {
    value: Object,
    id: {
      type: String,
      default: 'service',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectLabel: {
      type: String,
      default: 'name',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    clientId: {
      type: Number,
    },
    clientUnassigned: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getServices();
  },
  methods: {
    getServices() {
      const params = {
        client_id: this.clientId,
        active: true,
        paginate: false,
      };
      this.$servicesAPI
        .getServices(params)
        .then((response) => {
          return response.services;
        })
        .then((services) => {
          if (this.clientUnassigned) {
            this.$servicesAPI.getServices({ client_unassigned: true, active: true, paginate: false }).then((response) => {
              this.options = _uniqBy(response.services.concat(services), 'id');
            });
          } else {
            this.options = services;
          }
        });
    },
  },
  watch: {
    clientId() {
      this.getServices();
    },
  },
};
</script>
