<template>
  <sfs-base-list-filter
    title="Filter Delayed Jobs"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
  >
    <template #quick-filters>
      <b-form-group
        label-for="quick-filter-status"
        label-sr-only
      >
        <b-input-group>
          <v-select
            id="quick-filter-status"
            v-model="filters.status"
            :options="statusOptions"
            placeholder="Filter by Status"
            :reduce="(status) => status.value"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #advanced-filters>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Arguments"
            label-for="advanced-filter-arguments"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-arguments"
              v-model="filters.arguments"
              type="text"
              placeholder="Arguments"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Last Error"
            label-for="advanced-filter-last_error"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-last_error"
              v-model="filters.last_error"
              type="text"
              placeholder="Last Error"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            class="mb-0"
            label="Delayed Jobs From"
            label-for="advanced-filter-date_from"
            label-sr-only
          >
            <sfs-date-picker
              id="advanced-filter-date_from"
              v-model="filters.date_from"
              placeholder="Delayed Jobs From"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            class="mb-4"
            label="Delayed Jobs To"
            label-for="advanced-filter-date_to"
            label-sr-only
          >
            <sfs-date-picker
              id="advanced-filter-date_to"
              v-model="filters.date_to"
              placeholder="Delayed Jobs To"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            class="mb-0"
            label="Failed Delayed Jobs From"
            label-for="advanced-filter-failed_from"
            label-sr-only
          >
            <sfs-date-picker
              id="advanced-filter-failed_from"
              v-model="filters.failed_from"
              placeholder="Failed Delayed Jobs From"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            class="mb-4"
            label="Failed Delayed Jobs To"
            label-for="advanced-filter-failed_to"
            label-sr-only
          >
            <sfs-date-picker
              id="advanced-filter-failed_to"
              v-model="filters.failed_to"
              placeholder="Failed Delayed Jobs To"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            class="mb-0"
            label="Queue"
            label-for="advanced-filter-queue"
            label-sr-only
          >
            <v-select
              id="advanced-filter-queue"
              v-model="filters.queue"
              :options="queueOptions"
              placeholder="Queue"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import { DELAYED_JOB_TABLE_COLUMN_OPTIONS, DELAYED_JOB_TABLE_DEFAULT_COLUMNS, DELAYED_JOB_TABLE_NAME } from '@/constants/delayedJobs';
import listFilterMixin from '@/mixins/listFilterMixin';

export default {
  name: 'DelayedJobsListFilter',
  mixins: [listFilterMixin],
  data() {
    return {
      availableColumns: DELAYED_JOB_TABLE_COLUMN_OPTIONS,
      tableName: DELAYED_JOB_TABLE_NAME,
      statusOptions: [],
      queueOptions: [],
    };
  },
  mounted() {
    this.getStatusOptions();
    this.getQueueOptions();
    this.applyFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = DELAYED_JOB_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    getStatusOptions() {
      this.$delayedJobsAPI.getStatusOptions().then((response) => {
        const options = response.data.status_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
        this.statusOptions = [...options];
      });
    },
    getQueueOptions() {
      this.$delayedJobsAPI.getQueueOptions().then((response) => {
        this.queueOptions = response.data.queue_options;
      });
    },
  },
};
</script>
