import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { technicianPath } from '../helpers/apiPath';

export default {
  getTechnicianPayments(params) {
    return axios.get(technicianPath('/technician_payments?') + prepareQueryString(params)).then((response) => {
      return {
        technicianPayments: response.data.map((technicianPayment) => an('TechnicianPayment', technicianPayment)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(technicianPath(`/technician_payments/${id}`)).then((response) => an('TechnicianPayment', response.data));
  },
  getItems(id, params) {
    return axios.get(technicianPath(`/technician_payments/${id}/items?`) + prepareQueryString(params)).then((response) => {
      return {
        technicianPaymentItems: response.data.map((technicianPaymentItem) =>
          an('TechnicianPaymentItem', technicianPaymentItem)
        ),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getStatusOptions() {
    return axios.get(technicianPath('/technician_payments/status_options'));
  },
  getCompletedBySummary(id) {
    return axios.get(technicianPath(`/technician_payments/${id}/completed_by_summary`));
  },
  getItemsSpreadsheet(id, params) {
    return axios.get(technicianPath(`/technician_payments/${id}/items.csv?`) + prepareQueryString(params), { responseType: 'blob' });
  },
};
