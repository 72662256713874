<template>
  <sfs-form-modal
    :id="modalId"
    class="text-left"
    title="Upload New Document"
    @on-hide="resetForm"
  >
    <b-form @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.file"
            label-for="document-file"
          >
            <b-form-file
              v-model="$v.form.file.$model"
              id="document-file"
              :state="$v.form.file.$dirty ? !$v.form.file.$error : null"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            />
            <b-form-invalid-feedback
              v-if="$v.form.file.$dirty"
              id="document-file-feedback"
            >
              <span v-if="!$v.form.file.fileSize">
                The file you are attempting to upload is too large, the file must be smaller than 5mb.
              </span>
              <span v-if="!$v.form.file.required">Please enter a file.</span>
              <span v-if="!$v.form.file.serverFailed">{{ serverErrors.file }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.notes"
            label-for="document-notes"
          >
            <b-form-textarea
              id="document-notes"
              v-model="$v.form.notes.$model"
              :state="$v.form.notes.$dirty ? !$v.form.notes.$error : null"
              rows="4"
            />
            <b-form-invalid-feedback
              v-if="$v.form.notes.$dirty"
              id="document-notes-feedback"
            >
              <span v-if="!$v.form.notes.serverFailed">{{ serverErrors.notes }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label-for="document-internal">
            <b-form-checkbox
              id="document-internal"
              v-model="$v.form.internal.$model"
            >
              {{ fieldLabels.internal }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="document-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="document-submit-button"
            block
            type="submit"
            variant="primary"
            :disabled="processing"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import fileSize from '@/utils/validators/fileSize';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import { MAX_FILE_SIZE_BYTES } from '@/constants';
export default {
  name: 'NewDocumentModal',
  mixins: [formMixin, formModalMixin],
  props: {
    referenceId: {
      type: Number,
      required: true,
    },
    createProvider: {
      type: Function,
      required: true,
    },
    file: {
      type: File,
    },
    internal: {
      type: Boolean,
    },
    notes: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        file: this.file,
        internal: this.internal,
        notes: this.notes,
      },
      fieldLabels: {
        file: 'File *',
        internal: 'Is this document for SCOPES employees ONLY?',
        notes: 'Notes',
      },
      modalId: 'new-document-modal',
    };
  },
  validations: {
    form: {
      file: {
        required,
        fileSize(value) {
          return fileSize(value, MAX_FILE_SIZE_BYTES);
        },
        serverFailed() {
          return !this.hasServerErrors('file');
        },
      },
      internal: {},
      notes: {
        serverFailed() {
          return !this.hasServerErrors('notes');
        },
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.processing = true;
      this.createProvider(this.referenceId, this.form)
        .then((document) => {
          this.$emit('on-save', document);
          this.closeModal();
          this.processing = false;
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
          this.processing = false;
        });
    },
  },
};
</script>
