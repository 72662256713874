<template>
  <!-- using autocomplete value "new-password" instead of "off" seems to disable autocomplete better in some browsers. -->
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :disabled="disabled"
    :value="value"
    @search="onSearch"
    :filterable="false"
    @input="(user) => $emit('input', user)"
    autocomplete="new-password"
  >
    <template #option="option">
      <div>{{ option.name }}</div>
      <div class="font-size-sm text-secondary">
        {{ option.email }}
      </div>
    </template>
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        No results found for
        <em>{{ search }}</em>
      </template>
      <em
        style="opacity: 0.7"
        v-else
      >Type to search for users</em>
    </template>
  </v-select>
</template>
<script>
import { debounce as _debounce } from 'lodash';

export default {
  name: 'UsersSelect',
  props: {
    value: Object,
    id: {
      type: String,
      default: () => 'user',
    },
    primaryOption: Object,
    managerId: {
      type: Number,
    },
    selectLabel: {
      type: String,
      default: () => 'name',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    filterParams: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: String,
  },
  data() {
    return {
      options: [],
    };
  },
  methods: {
    onSearch(name, loading) {
      if (name.length) {
        loading(true);
        this.filterUsers(name, loading, this);
      }
    },
    filterUsers: _debounce((name, loading, vm) => {
      const params = {
        ...vm.filterParams,
        name: name,
        technician_manager_id: vm.managerId || '',
      };
      vm.$usersAPI.getUsers(params).then((response) => {
        const users = response.users;
        if (vm.primaryOption) {
          users.unshift(vm.primaryOption);
        }
        vm.options = response.users;
        loading(false);
      });
    }, 500),
  },
  watch: {
    managerId(newVal, oldVal) {
      if (newVal != oldVal) {
        this.options = [];
      }
    },
  },
};
</script>
