<template>
  <div>
    <base-page-heading title="Technician Forecast Report" />
    <div class="content">
      <technician-forecast-list-filter
        :columns="columns"
        :initial-filters="filters"
        @filters-applied="applyFilters"
      />
      <technician-forecast-table
        :filters="filters"
        :columns="columns"
        sort-by="revenue_cents"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin';
import TechnicianForecastListFilter from '@/components/admin/reports/users/TechnicianForecastListFilter.vue';
import TechnicianForecastTable from '@/components/admin/reports/users/TechnicianForecastTable.vue';
import { WORK_ORDER_TECHNICIAN_FORECAST_REPORT_TABLE_NAME } from '@/constants/reports';

export default {
  name: 'TechniciansForecastReport',
  components: {
    TechnicianForecastTable,
    TechnicianForecastListFilter,
  },
  mixins: [listMixin],
  props: {
    manager_id: String,
    client_id: String,
    service_id: String,
    state: String,
    start_date: String,
    end_date: String,
    sortBy: {
      type: String,
      default: 'revenue_cents',
    },
  },
  data() {
    return {
      tableName: WORK_ORDER_TECHNICIAN_FORECAST_REPORT_TABLE_NAME,
      filters: {
        manager_id: this.manager_id,
        client_id: this.client_id,
        service_id: this.service_id,
        state: this.state,
        start_date: this.start_date,
        end_date: this.end_date,
      },
    };
  },
};
</script>
