import usersAPI from '@/api/admin/users.js';
import authAPI from '@/api/auth.js';
import clientsAPI from '@/api/admin/clients.js';
import workOrderChargesAPI from '@/api/admin/workOrderCharges.js';
import organizationsAPI from '@/api/admin/organizations.js';
import servicesAPI from '@/api/admin/services.js';
import additionalChargesAPI from '@/api/admin/additionalCharges.js';
import contactInfosAPI from '@/api/admin/contactInfos.js';
import importsAPI from '@/api/admin/imports.js';
import insuranceCertificatesAPI from '@/api/admin/insuranceCertificates.js';
import integrationReferencesAPI from '@/api/admin/integrationReferences.js';
import suppliesAPI from '@/api/admin/supplies.js';

import { defineRulesFor } from '@/abilities/defineAbility.js';
import {
  SET_USER_MUTATION,
  SET_IMPERSONATING_USER_MUTATION,
  SET_CLIENT_MUTATION,
  SET_RULES_MUTATION,
  SET_TOKEN_MUTATION,
  SET_IMPERSONATING_TOKEN_MUTATION,
  SET_CATEGORY_MUTATION,
  SET_ORGANIZATION_MUTATION,
  SET_PERMISSIONS_MUTATION,
  SET_USER_LIST_COLUMNS_MUTATION,
  SET_USER_PER_PAGE_MUTATION,
  SET_ORGANIZATION_LIST_COLUMNS_MUTATION,
  SET_ORGANIZATION_TERMINOLOGY_MUTATION,
} from '@/constants/mutations.js';

export default {
  login(context, payload) {
    return authAPI.login(payload).then((response) => {
      const user = response.data.user;
      context.commit(SET_USER_MUTATION, { user: { ...user } });
      context.commit(SET_TOKEN_MUTATION, { token: response.data.jwt });
      context.commit(SET_RULES_MUTATION, { rules: defineRulesFor(user) });
      context.commit(SET_CATEGORY_MUTATION, { category: user.category });
      context.commit(SET_USER_LIST_COLUMNS_MUTATION, { userListColumns: user.ui_config?.listColumns });
      context.commit(SET_USER_PER_PAGE_MUTATION, { userPerPage: user.ui_config?.perPage });
      context.commit(SET_ORGANIZATION_LIST_COLUMNS_MUTATION, { organizationListColumns: user.organization.ui_config?.listColumns });
      return response;
    });
  },
  getProfile(context) {
    return authAPI.getProfile().then((response) => {
      const user = response.data;
      const permissions = response.data.roles.map((role) => role.permissions );
      context.commit(SET_USER_MUTATION, { user: { ...user } });
      context.commit(SET_RULES_MUTATION, { rules: defineRulesFor(user) });
      context.commit(SET_USER_LIST_COLUMNS_MUTATION, { userListColumns: user.ui_config?.listColumns });
      context.commit(SET_USER_PER_PAGE_MUTATION, { userPerPage: user.ui_config?.perPage });
      context.commit(SET_PERMISSIONS_MUTATION, permissions);
      context.commit(SET_CATEGORY_MUTATION, { category: user.category });
      if (user.organization_id == context.getters.currentOrganization?.id) {
        context.commit(SET_ORGANIZATION_LIST_COLUMNS_MUTATION, { organizationListColumns: user.organization.ui_config?.listColumns });
      }
    });
  },
  getTerminology(context) {
    return organizationsAPI.getOrganizationTerminology(context.getters.currentOrganization?.id).then((response) => {
      const terminologies = response.data;
      context.commit(SET_ORGANIZATION_TERMINOLOGY_MUTATION, { organizationTerminology: { ...terminologies } });
    });
  },
  logout(context) {
    context.commit(SET_USER_MUTATION, { user: null });
    context.commit(SET_IMPERSONATING_USER_MUTATION, { impersonatingUser: null });
    context.commit(SET_CLIENT_MUTATION, { client: null });
    context.commit(SET_TOKEN_MUTATION, { token: null });
    context.commit(SET_IMPERSONATING_TOKEN_MUTATION, { impersonatingToken: null });
    context.commit(SET_RULES_MUTATION, { rules: [] });
    context.commit(SET_ORGANIZATION_MUTATION, { organization: null });
    context.commit(SET_USER_LIST_COLUMNS_MUTATION, { userListColumns: null });
    context.commit(SET_USER_PER_PAGE_MUTATION, { userPerPage: null });
    context.commit(SET_CATEGORY_MUTATION, { category: null });
    context.commit(SET_ORGANIZATION_LIST_COLUMNS_MUTATION, { organizationListColumns: null });
  },
  impersonateUser(context, payload) {
    context.commit(SET_IMPERSONATING_USER_MUTATION, { impersonatingUser: context.getters.user });
    context.commit(SET_IMPERSONATING_TOKEN_MUTATION, { impersonatingToken: context.getters.token });
    context.commit(SET_USER_MUTATION, { user: payload.user });
    context.commit(SET_TOKEN_MUTATION, { token: payload.jwt });
    context.commit(SET_CATEGORY_MUTATION, { category: payload.user.category });
    context.commit(SET_RULES_MUTATION, { rules: defineRulesFor(payload.user) });
    return Promise.resolve;
  },
  unimpersonateUser(context) {
    const user = context.getters.impersonatingUser;
    context.commit(SET_USER_MUTATION, { user: user });
    context.commit(SET_TOKEN_MUTATION, { token: context.getters.impersonatingToken });
    context.commit(SET_RULES_MUTATION, { rules: defineRulesFor(user) });
    context.commit(SET_CATEGORY_MUTATION, { category: user.category });
    context.commit(SET_IMPERSONATING_USER_MUTATION, { impersonatingUser: null });
    context.commit(SET_IMPERSONATING_TOKEN_MUTATION, { impersonatingToken: null });
    return Promise.resolve;
  },
  updateProfile(context, payload) {
    return authAPI.updateProfile(payload).then((response) => {
      const user = response.data;
      context.commit(SET_USER_MUTATION, { user: { ...user } });
      context.commit(SET_RULES_MUTATION, { rules: defineRulesFor(user) });
      context.commit(SET_USER_LIST_COLUMNS_MUTATION, { userListColumns: user.ui_config?.listColumns });
      context.commit(SET_USER_PER_PAGE_MUTATION, { userPerPage: user.ui_config?.perPage });
      return response;
    });
  },
  getOrganizations() {
    return organizationsAPI.getOrganizations({ paginate: false }).then((response) => {
      return response.organizations;
    });
  },
  getClients(_, params) {
    return clientsAPI.getClients(params).then((response) => {
      return response.clients;
    });
  },
  getWorkOrderChargeCategories() {
    return workOrderChargesAPI.getCategoryOptions().then((response) => {
      return response.data.category_options;
    });
  },
  getServiceCategories() {
    return servicesAPI.getCategoryOptions().then((response) => {
      return response.data.category_options;
    });
  },
  getAdditionalChargeCategories() {
    return additionalChargesAPI.getCategoryOptions().then((response) => {
      return response.data.category_options;
    });
  },
  getContactInfoCategories() {
    return contactInfosAPI.getCategoryOptions().then((response) => {
      return response.data.category_options;
    });
  },
  getImportCategories() {
    return importsAPI.getCategoryOptions().then((response) => {
      return response.data.category_options;
    });
  },
  getInsuranceCertificateCategories() {
    return insuranceCertificatesAPI.getCategoryOptions().then((response) => {
      return response.data.category_options;
    });
  },
  getSupplyCategories() {
    return suppliesAPI.getCategoryOptions().then((response) => {
      return response.data.category_options;
    });
  },
  getUserCategories() {
    return usersAPI.getCategoryOptions().then((response) => {
      return response.data.category_options;
    });
  },
  getAssignableUserCategories() {
    return usersAPI.getAssignableCategoryOptions().then((response) => {
      return response.data.category_options;
    });
  },
  getUserServicesProvided() {
    return usersAPI.getServicesProvidedOptions().then((response) => {
      return response.data.services_provided_options;
    });
  },
  getNetSuiteRecords(_, params) {
    return integrationReferencesAPI.getNetSuiteRecords(params).then((response) => {
      return response.records;
    });
  },
};
