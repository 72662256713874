<template>
  <sfs-base-list-filter
    title="Filter Vendor"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
  >
    <template #quick-filters>
      <b-form-group
        label="Search fields for Data"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Name, City or State"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #advanced-filters>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.name"
            label-for="advanced-filter-name"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-name"
              v-model="filters.name"
              type="text"
              :placeholder="fieldLabels.name"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.city"
            label-for="advanced-filter-city"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-city"
              v-model="filters.city"
              type="text"
              :placeholder="fieldLabels.city"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.state"
            label-for="advanced-filter-state"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-state"
              v-model="filters.state"
              type="text"
              :placeholder="fieldLabels.state"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.account_number"
            label-for="advanced-filter-account_number"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-account_number"
              v-model="filters.account_number"
              type="text"
              :placeholder="fieldLabels.account_number"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import listFilterMixin from '@/mixins/listFilterMixin';

import { VENDOR_TABLE_COLUMN_OPTIONS, VENDOR_TABLE_DEFAULT_COLUMNS, VENDOR_TABLE_NAME, VENDORS_FORM_LABELS } from '@/constants/vendors';

export default {
  name: 'VendorsListFilter',
  mixins: [listFilterMixin],
  data() {
    return {
      availableColumns: VENDOR_TABLE_COLUMN_OPTIONS,
      fieldLabels: VENDORS_FORM_LABELS,
      tableName: VENDOR_TABLE_NAME,
    };
  },
  mounted() {
    this.applyFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = VENDOR_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
  },
};
</script>
