<template>
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :value="value"
    @input="(glCode) => $emit('input', glCode)"
  >
    <template #option="invoiceFormat">
      {{ invoiceFormat.name }}
    </template>
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        No results found for
        <em>{{ search }}</em>
      </template>
      <em
        style="opacity: 0.7"
        v-else
      >Type to search for Invoice Formats</em>
    </template>
  </v-select>
</template>
<script>
import { isEqual as _isEqual, isUndefined as _isUndefined } from 'lodash';

export default {
  name: 'InvoiceFormatsSelect',
  props: {
    value: Object,
    id: {
      type: String,
      default: 'invoice-format',
    },
    selectLabel: {
      type: String,
      default: 'name',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    filterParams: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: {
      type: String,
      default: 'Select an Invoice Format'
    }
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      const params = {
        ...this.filterParams,
        paginate: false,
      };
      this.$invoiceFormatsAPI.get(params).then((response) => {
        this.options = response.invoiceFormats;
      });
    },
  },
  watch: {
    filterParams: {
      deep: true,
      handler(newFilterParams, oldFilterParams) {
        if (!_isEqual(oldFilterParams, newFilterParams) && !_isUndefined(newFilterParams)) {
          this.get();
        }
      },
    },
  },
};
</script>
