<template>
  <div>
    <base-page-heading title="Total Work Orders Over Time Report" />
    <div class="content">
      <over-time-chart-filter
        class="mb-4"
        :columns="columns"
        :column-picker="false"
        :initial-filters="filters"
        @filters-applied="applyFilters"
      />

      <b-row>
        <b-col cols="12">
          <area-chart
            title="Work Orders Over Time"
            :share-tooltip="true"
            y-axis-title="Total"
            chart-height="400px"
            skeleton-height="400px"
            :series="chartData"
            :fill-opacity="1"
            :colors="chartColours"
            :data-function="loadChart"
            :data-parameters="{
              filters,
              groupPeriod,
            }"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import listMixin from '@/mixins/listMixin';
import OverTimeChartFilter from '@/components/admin/reports/workOrders/OverTimeChartFilter.vue';
import { mapTimeSeriesData } from '@/utils/apexchartsSeriesMapping';
import AreaChart from '@/components/shared/apexcharts/AreaChart.vue';
import calculateGroupPeriod from '@/utils/calculateGroupPeriod';
import { CHART_COLOUR_PRIMARY } from '@/constants';
export default {
  name: 'TotalOverTime',
  components: {
    OverTimeChartFilter,
    AreaChart,
  },
  props: {
    client_id: String,
    location_id: String,
    service_id: String,
    manager_id: String,
    state: String,
    checked_in_from: String,
    checked_in_to: String,
    checked_out_from: String,
    checked_out_to: String,
    assigned_to_id: String,
    statuses: Array,
    date_bucket_start_from: String,
    date_bucket_end_to: String
  },
  mixins: [listMixin],
  data() {
    return {
      filters: {
        client_id: this.client_id || this.$store.getters?.client?.id,
        location_id: this.location_id,
        service_id: this.service_id,
        manager_id: this.manager_id,
        state: this.state,
        date_bucket_start_from: this.date_bucket_start_from || this.$options.filters.dateFormat(DateTime.now().minus({ month: 6 }).toISODate()),
        date_bucket_end_to: this.date_bucket_end_to || this.$options.filters.dateFormat(DateTime.now().toISODate()),
        checked_out_from: this.checked_out_from,
        checked_out_to: this.checked_out_to,
        assigned_to_id: this.assigned_to_id,
        statuses: this.statuses,
      },
      dateFormat: 'LLL d, yyyy',
      countSeries: { data: [] },
      chartColours: [CHART_COLOUR_PRIMARY],
    };
  },
  methods: {
    loadChart() {
      return this.$analyticsAPI
        .getWorkOrdersCount({
          ...this.filters,
          group_period: this.groupPeriod,
          group_by: 'date_bucket_start',
          count_by: 'work_order_id',
        })
        .then((response) => {
          this.countSeries = {
            name: 'Work Orders',
            data: mapTimeSeriesData(response.series),
          };
        })
        .catch(() => {
          this.countSeries = { data: [] };
        });
    },
  },
  computed: {
    chartData() {
      return [this.countSeries];
    },
    groupPeriod() {
      return calculateGroupPeriod(this.datePeriod[0], this.datePeriod[1]);
    },
    datePeriod() {
      if (this.filters.date_bucket_start_from && this.filters.date_bucket_end_to) {
        const dates = [DateTime.fromFormat(this.filters.date_bucket_start_from, this.dateFormat).toISODate(), DateTime.fromFormat(this.filters.date_bucket_end_to, this.dateFormat).toISODate()];
        return dates;
      } else {
          return [];
      }
    },
  },
  mounted() {
    // this.refreshData();
  },
};
</script>
