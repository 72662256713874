<template>
  <sfs-base-list-filter
    title="Filter Locations"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="translatedColumns(modelName, availableColumns)"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
  >
    <template #quick-filters>
      <b-form-group
        label="Search fields for Data"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Number, Name, Address or Client"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #advanced-filters>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.store_number"
            label-for="advanced-filter-store_number"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-store_number"
              v-model="filters.store_number"
              type="text"
              :placeholder="fieldLabels.store_number"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            class="mb-0"
            label="Client"
            label-for="advanced-filter-client_id"
            label-sr-only
          >
            <client-select
              id="advanced-filter-client_id"
              placeholder="All Clients"
              :value="client"
              @input="onClientChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.manager_id"
            label-for="advanced-filter-manager_id"
            label-sr-only
          >
            <employee-select
              id="advanced-filter-manager_id"
              placeholder="All Managers"
              :value="manager"
              @input="onManagerChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            label="Technician"
            label-for="advanced-filter-technician_id"
            label-sr-only
          >
            <user-select
              select-label="name"
              name="advanced-filter-technician_id"
              :value="technician"
              @input="onTechnicianChange"
              placeholder="All Technicians"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.street"
            label-for="advanced-filter-street"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-street"
              v-model="filters.street"
              type="text"
              :placeholder="fieldLabels.street"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.city"
            label-for="advanced-filter-city"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-city"
              v-model="filters.city"
              type="text"
              :placeholder="fieldLabels.city"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.state"
            label-for="advanced-filter-state"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-state"
              v-model="filters.state"
              type="text"
              :placeholder="fieldLabels.state"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.county"
            label-for="advanced-filter-county"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-county"
              v-model="filters.county"
              type="text"
              :placeholder="fieldLabels.county"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.zip_code"
            label-for="advanced-filter-zip_code"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-zip_code"
              v-model="filters.zip_code"
              type="text"
              :placeholder="fieldLabels.zip_code"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            class="mb-0"
            label="Service"
            label-for="advanced-filter-service_id"
            label-sr-only
          >
            <service-select
              id="advanced-filter-service_id"
              placeholder="All Services"
              :value="service"
              @input="onServiceChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.floor_type"
            label-for="advanced-filter-floor_type"
            label-sr-only
          >
            <floor-type-select
              id="advanced-filter-floor_type"
              placeholder="All Floor Types"
              v-model="filters.floor_type"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Active or Inactive"
            label-for="advanced-filter-active"
            label-sr-only
          >
            <b-form-radio-group
              id="advanced-filter-active"
              v-model="filters.active"
              :options="activeOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import EmployeeSelect from '@/components/admin/users/EmployeeSelect.vue';
import UserSelect from '@/components/admin/users/Select.vue';
import ClientSelect from '@/components/admin/clients/Select.vue';
import ServiceSelect from '@/components/admin/services/Select.vue';
import FloorTypeSelect from '@/components/admin/locations/FloorTypeSelect.vue';
import listFilterMixin from '@/mixins/listFilterMixin';
import translationMixin from '@/mixins/translationMixin';

import { LOCATION_TABLE_COLUMN_OPTIONS, LOCATION_TABLE_DEFAULT_COLUMNS, LOCATION_TABLE_NAME, LOCATION_MODEL_NAME } from '@/constants/locations';
import { LOCATIONS_FORM_LABELS } from '@/constants/locations.js';

export default {
  name: 'LocationsListFilter',
  mixins: [listFilterMixin, translationMixin],
  components: {
    EmployeeSelect,
    UserSelect,
    ClientSelect,
    ServiceSelect,
    FloorTypeSelect,
  },
  data() {
    return {
      availableColumns: LOCATION_TABLE_COLUMN_OPTIONS,
      fieldLabels: LOCATIONS_FORM_LABELS,
      tableName: LOCATION_TABLE_NAME,
      modelName: LOCATION_MODEL_NAME,
    };
  },
  mounted() {
    this.loadInitialFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = LOCATION_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    onManagerChange(user) {
      this.filters.manager_id = user ? user.id.toString() : '';
      this.manager = user;
    },
    onTechnicianChange(user) {
      this.filters.technician_id = user ? user.id : '';
      this.technician = user;
    },
    onServiceChange(service) {
      this.filters.service_id = service ? service.id.toString() : '';
      this.service = service;
    },
    onClientChange(client) {
      this.filters.client_id = client ? client.id.toString() : '';
      this.client = client;
    },
    loadInitialFilters() {
      const loadManager = new Promise((resolve) => {
        if (this.initialFilters.manager_id) {
          this.$usersAPI.get(this.initialFilters.manager_id).then((manager) => {
            resolve(manager);
          });
        } else {
          resolve(null);
        }
      });

      const loadTechnician = new Promise((resolve) => {
        if (this.initialFilters.technician_id) {
          this.$usersAPI.get(this.initialFilters.technician_id).then((technician) => {
            resolve(technician);
          });
        } else {
          resolve(null);
        }
      });

      const loadClient = new Promise((resolve) => {
        if (this.initialFilters.client_id) {
          this.$clientsAPI.get(this.initialFilters.client_id).then((client) => {
            resolve(client);
          });
        } else {
          resolve(null);
        }
      });

      const loadService = new Promise((resolve) => {
        if (this.initialFilters.service_id) {
          this.$servicesAPI.get(this.initialFilters.service_id).then((service) => {
            resolve(service);
          });
        } else {
          resolve(null);
        }
      });

      Promise.all([loadManager, loadTechnician, loadClient, loadService]).then((values) => {
        this.manager = values[0];
        this.technician = values[1];
        this.client = values[2];
        this.service = values[3];
        this.applyFilters();
      });
    },
  },
};
</script>
