<template>
  <sfs-base-table
    :data-provider="endpoint"
    :data-id="clientId"
    provider-key="additionalCharges"
    :columns="translatedColumns(modelName, columns)"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
    :append-to-query="false"
  >
    <template #cell(description)="data">
      <text-with-popover
        v-if="data.item.description != data.item.service?.name"
        :popover-id="`work-order-charge-${data.item.id}-description`"
        :text="data.item.description"
      />
      <b-link
        v-if="data.item.service_id"
        class="font-size-sm text-prewrap"
        :to="{ name: 'service', params: { id: data.item.service_id } }"
      >
        {{ data.item.service.name }}
      </b-link>
    </template>
    <template #cell(category)="data">
      <b-badge variant="secondary">
        {{ data.item.category | humanizedFormat | capitalizeFormat }}
      </b-badge>
    </template>
    <template #cell(cost)="data">
      <money-format
        :value="data.item.cost.amount"
        :currency-code="data.item.cost.currency"
        subunits-value
      />
    </template>
    <template #cell(price)="data">
      <money-format
        :value="data.item.price.amount"
        :currency-code="data.item.price.currency"
        subunits-value
      />
    </template>
    <template #cell(actions)="data">
      <b-button-group>
        <b-button
          v-if="$can('update', data.item)"
          v-b-modal="`additional-charges-${data.item.id}-edit-modal`"
          size="sm"
          variant="link"
        >
          <i class="fa fa-fw fa-pencil-alt" />
        </b-button>
        <b-button
          v-if="$can('destroy', data.item)"
          size="sm"
          variant="alt-primary"
          @click="destroyAdditionalChargeDialog(data.item.id)"
        >
          <i class="fa fa-fw fa-times" />
        </b-button>
        <edit-modal
          v-if="$can('update', data.item)"
          :key="`additional-charges-edit-modal-${data.item.id}`"
          :value="data.item"
          @on-save="() => refreshTable"
        />
      </b-button-group>
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import translationMixin from '@/mixins/translationMixin';
import { ADDITIONAL_CHARGES_TABLE_DEFAULT_COLUMNS, ADDITIONAL_CHARGES_MODEL_NAME } from '@/constants/additionalCharges';
import EditModal from '@/components/admin/additionalCharges/EditModal.vue';
import TextWithPopover from '@/components/shared/TextWithPopover.vue';

import { DateTime } from 'luxon';

export default {
  name: 'AdditionalChargesTable',
  mixins: [tablePropsMixin, translationMixin],
  components: {
    EditModal,
    TextWithPopover,
  },
  props: {
    columns: {
      type: Array,
      default: () => ADDITIONAL_CHARGES_TABLE_DEFAULT_COLUMNS,
    },
    clientId: {
      type: Number,
    },
  },
  data() {
    return {
      modelName: ADDITIONAL_CHARGES_MODEL_NAME,
    };
  },
  methods: {
    destroyAdditionalChargeDialog(id) {
      this.$swal({
        text: 'Once it\'s deleted, its gone forever!',
        preConfirm: () => this.$additionalChargesAPI.destroy(id, { deleted_at: DateTime.now().toISODate() }),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
  },
  computed: {
    endpoint() {
      if (this.clientId) {
        return this.$additionalChargesAPI.getClientAdditionalCharges;
      } else {
        return this.$additionalChargesAPI.getAdditionalCharges;
      }
    },
  }
};
</script>
