<template>
  <sfs-base-list-filter
    title="Filter Imports"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
  >
    <template #quick-filters>
      <b-form-group
        label-for="quick-filter-code"
        label-sr-only
      >
        <b-input-group>
          <v-select
            id="quick-filter-status"
            :value="filters.status"
            @input="onStatusChange"
            :options="statusOptions"
            placeholder="Status"
            :reduce="(status) => status.value"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col>
          <b-form-group
            label="Imported By"
            label-for="popover-filter-user_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <user-select
              id="popover-filter-user_id"
              placeholder="All"
              :value="user"
              @input="onUserChange"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Category"
            label-for="popover-filter-category"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <category-select
              id="popover-filter-category"
              placeholder="All"
              v-model="filters.category"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Created From"
            label-for="popover-filter-date_from"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <sfs-date-picker
              id="popover-filter-date_from"
              v-model="filters.date_from"
              placeholder="All"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Created Until"
            label-for="popover-filter-date_to"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <sfs-date-picker
              id="popover-filter-date_to"
              v-model="filters.date_to"
              placeholder="All"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import UserSelect from '@/components/admin/users/Select.vue';
import CategorySelect from '@/components/admin/imports/CategorySelect.vue';
import { IMPORT_TABLE_COLUMN_OPTIONS, IMPORT_TABLE_DEFAULT_COLUMNS, IMPORT_TABLE_NAME } from '@/constants/imports';
import listFilterMixin from '@/mixins/listFilterMixin';

export default {
  name: 'ImportsListFilter',
  mixins: [listFilterMixin],
  components: {
    UserSelect,
    CategorySelect,
  },
  data() {
    return {
      availableColumns: IMPORT_TABLE_COLUMN_OPTIONS,
      tableName: IMPORT_TABLE_NAME,
      statusOptions: [],
    };
  },
  mounted() {
    this.getStatusOptions();
    this.loadInitialFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = IMPORT_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    onUserChange(user) {
      this.filters.user_id = user ? user.id.toString() : '';
      this.user = user;
    },
    onStatusChange(status) {
      this.filters.status = status;
      this.applyFilters();
    },
    getStatusOptions() {
      this.$importsAPI.getStatusOptions().then((response) => {
        const options = response.data.status_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
        this.statusOptions = [...options];
      });
    },
    loadInitialFilters() {
      if (this.initialFilters.user_id) {
        this.$usersAPI.get(this.initialFilters.user_id).then((user) => {
          this.user = user;
          this.applyFilters();
        });
      } else {
        this.applyFilters();
      }
    },
  },
};
</script>

