<template>
  <sfs-form-modal
    :id="modalId"
    title="Change Organizations"
    :static="!lazyLoad"
  >
    <b-form @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.organization"
            label-for="change-organization-organization"
          >
            <organizations-select
              id="change-organization-organization"
              :select-class="{ 'is-invalid': $v.form.organization.$dirty && $v.form.organization.$error }"
              :value="form.organization"
              @input="onOrganizationChange"
            />
            <b-form-invalid-feedback
              v-if="$v.form.organization.$dirty"
              id="change-organization-organization-feedback"
            >
              <span v-if="!$v.form.organization.required">Please enter an organization.</span>
              <span v-if="!$v.form.organization.serverFailed">
                {{ serverErrors.organization }}
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="change-organization-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="change-organization-submit-button"
            block
            type="submit"
            variant="primary"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import { USER_CHANGE_ORGANIZATION_MODAL_ID } from '@/constants';
import { SET_ORGANIZATION_MUTATION, SET_ORGANIZATION_TERMINOLOGY_MUTATION, SET_CLIENT_MUTATION, SET_ORGANIZATION_LIST_COLUMNS_MUTATION } from '@/constants/mutations.js';
import OrganizationsSelect from '@/components/admin/organizations/Select.vue';

export default {
  name: 'ChangeOrganizationModal',
  components: { OrganizationsSelect },
  mixins: [formMixin, formModalMixin],
  props: {
    lazyLoad: {
      type: Boolean,
      default: () => false,
    },
    organization: Object,
  },
  data() {
    return {
      form: {
        organization: this.organization,
      },
      modalId: USER_CHANGE_ORGANIZATION_MODAL_ID,
      fieldLabels: { organization: 'Organization' },
    };
  },
  validations: {
    form: {
      organization: {
        required,
        serverFailed() {
          return !this.hasServerErrors('organization');
        },
      },
    },
  },
  methods: {
    handleSubmit() {
      if (!this.hasFormErrors()) {
        this.$store.commit(SET_ORGANIZATION_MUTATION, { organization: this.form.organization });
        this.$store.commit(SET_ORGANIZATION_LIST_COLUMNS_MUTATION, { organizationListColumns: this.form.organization.ui_config?.listColumns });
        this.$store.commit(SET_CLIENT_MUTATION, { client: null });
        this.$organizationsAPI.getOrganizationTerminology(this.form.organization?.id).then((response) => {
          this.$store.commit(SET_ORGANIZATION_TERMINOLOGY_MUTATION, { organizationTerminology: response.data });
        });
        this.$emit('on-save', this.form.organization);
        this.closeModal();
      }
    },
    onOrganizationChange(organization) {
      this.form.organization = Object.assign({}, organization);
    },
  },
};
</script>
