import List from '@/pages/admin/delayedJobs/List.vue';
import handlePaginationRouteProps from '@/utils/handlePaginationRouteProps.js';

export default [
  {
    path: 'delayed_jobs',
    name: 'Delayed Jobs',
    component: List,
    props: handlePaginationRouteProps,
  },
];
