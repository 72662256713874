import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const IMPORT_TABLE_NAME = 'imports';
// The columns available to be shown in a imports table.
export const IMPORT_TABLE_COLUMNS = {
  id: { label: 'ID', key: 'id' },
  status: { label: 'Status', key: 'status' },
  user_id: { label: 'Imported By', key: 'user_id' },
  category: { label: 'Category', key: 'category' },
  num_imported: { label: 'Total Imported', key: 'num_imported' },
  num_errors: { label: 'Total Errors', key: 'num_errors' },
  started_at: { label: 'Started At', key: 'started_at' },
  completed_at: { label: 'Completed At', key: 'completed_at' },
  created_at: { label: 'Created At', key: 'created_at' },
  date_from: { label: 'Imports From', key: 'date_from' },
  date_to: { label: 'Imports To', key: 'date_to' },
  file: { label: 'Uploaded File', key: 'file' },
  error_file: { label: 'Error File', key: 'error_file' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const IMPORT_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(IMPORT_TABLE_COLUMNS);

// The default columns to be shown when the imports table loads.
export const IMPORT_TABLE_DEFAULT_COLUMNS = [
  IMPORT_TABLE_COLUMNS.category,
  IMPORT_TABLE_COLUMNS.status,
  IMPORT_TABLE_COLUMNS.num_imported,
  IMPORT_TABLE_COLUMNS.num_errors,
  IMPORT_TABLE_COLUMNS.user_id,
  IMPORT_TABLE_COLUMNS.created_at,
  IMPORT_TABLE_COLUMNS.file,
  IMPORT_TABLE_COLUMNS.error_file,
];

export const PENDING_IMPORT_STATUS = 'pending';
export const SUCCEEDED_IMPORT_STATUS = 'succeeded';
export const FAILED_IMPORT_STATUS = 'failed';
