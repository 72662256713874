<template>
  <sfs-form-modal
    :id="modalId"
    class="text-left"
    @on-hide="resetFormData"
    :title="`Edit Document ${value.file.filename}`"
  >
    <b-form @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Notes"
            label-for="document-notes"
          >
            <b-form-textarea
              id="document-notes"
              v-model="$v.form.notes.$model"
              :state="$v.form.notes.$dirty ? !$v.form.notes.$error : null"
              rows="4"
            />
            <b-form-invalid-feedback
              v-if="$v.form.notes.$dirty"
              id="document-notes-feedback"
            >
              <span v-if="!$v.form.notes.serverFailed">{{ serverErrors.notes }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group :label-for="`document-${value.id}-internal`">
            <b-form-checkbox
              :id="`document-${value.id}-internal`"
              v-model="$v.form.internal.$model"
            >
              Is this document for SCOPES employees ONLY?
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="location-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            :id="`document-${value.id}-submit-button`"
            block
            type="submit"
            variant="primary"
            :disabled="processing"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'DocumentEditModal',
  mixins: [formMixin, formModalMixin],
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: `document-${this.value.id}-edit-modal`,
      form: {
        internal: this.value.internal,
        notes: this.value.notes,
      },
    };
  },
  validations: {
    form: {
      internal: {},
      notes: {
        serverFailed() {
          return !this.hasServerErrors('notes');
        },
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }

      this.processing = true;
      this.$documentsAPI.update(this.value.id, this.form).then((document) => {
        this.$emit('on-save', document);
        this.closeModal();
        this.processing = false;
      }).catch((error) => {
          this.processServerErrors(error.response.data.errors);
          this.processing = false;
      });
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
