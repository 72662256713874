export default function prepareTableColumnOptions(columns = {}) {
  const options = Object.keys(columns).map((col) => {
    return {
      label: columns[col].label,
      key: columns[col].key,
      filterOnly: columns[col].filterOnly || false,
    };
  });
  return options;
}
