<template>
  <sfs-form-modal
    :id="modalId"
    title="Add a Charge"
    @on-hide="resetFormData"
  >
    <work-order-charge-form
      :work-order="workOrder"
      :client_id="workOrder.client_id"
      :user="workOrder.assigned_to"
      :user_id="workOrder.assigned_to_id"
      :quantity="1"
      category="labor"
      @on-submit="createCharge"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import WorkOrderChargeForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'InvoiceItemAddModal',
  mixins: [formModalMixin],
  components: {
    WorkOrderChargeForm,
  },
  props: {
    workOrder: Object,
  },
  data() {
    return {
      modalId: 'work-order-charge-add-modal',
    };
  },
  methods: {
    createCharge(data, handleCreate) {
      handleCreate(this.$workOrderChargesAPI.create(this.workOrder.id, data));
    },
    onSave() {
      this.closeModal();
      this.resetFormData();
      this.$emit('on-save');
    },
  },
};
</script>
