<template>
  <b-form
    @submit.stop.prevent="handleSubmit"
    class="text-left"
  >
    <b-form-group
      :label="fieldLabels.service_id"
      label-for="technician-service-service_id"
    >
      <service-select
        :select-class="{ 'is-invalid': $v.form.service_id.$dirty && $v.form.service_id.$error }"
        name="technician-service-service_id"
        :value="form.service"
        @input="onServiceChange"
      />
      <b-form-invalid-feedback
        v-if="$v.form.service_id.$dirty"
        class="technician-service-service_id-feedback"
      >
        <span v-if="!$v.form.service_id.required">Please enter a service.</span>
        <span v-if="!$v.form.service_id.serverFailed">{{ serverErrors.service_id }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.assigned_to_id"
      label-for="technician-service-assigned_to_id"
    >
      <user-select
        :select-class="{ 'is-invalid': $v.form.assigned_to_id.$dirty && $v.form.assigned_to_id.$error }"
        select-label="name"
        name="technician-service-assigned_to_id"
        :value="form.assigned_to"
        :filter-params="technicianSelectParams"
        @input="onAssignedToChange"
      />
      <b-form-invalid-feedback
        v-if="$v.form.assigned_to_id.$dirty"
        class="technician-service-assigned_to_id-feedback"
      >
        <span v-if="!$v.form.assigned_to_id.required">Please enter an assigned technician.</span>
        <span v-if="!$v.form.assigned_to_id.serverFailed">{{ serverErrors.assigned_to_id }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.done_by_id"
      label-for="technician-service-done_by_id"
    >
      <user-select
        :select-class="{ 'is-invalid': $v.form.done_by_id.$dirty && $v.form.done_by_id.$error }"
        select-label="name"
        name="technician-service-done_by_id"
        :disabled="!form.assigned_to_id"
        :manager-id="form.assigned_to_id"
        :value="form.done_by"
        :primary-option="form.assigned_to"
        @input="onDoneByChange"
      />
      <b-form-invalid-feedback
        v-if="$v.form.done_by_id.$dirty"
        class="technician-service-done_by_id-feedback"
      >
        <span v-if="!$v.form.done_by_id.required">Please enter the technician the work is completed by.</span>
        <span v-if="!$v.form.done_by_id.serverFailed">{{ serverErrors.done_by_id }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.cost"
      label-for="technician-service-cost"
    >
      <b-form-input
        v-model="$v.form.cost.$model"
        name="technician-service-cost"
        :state="$v.form.cost.$dirty ? !$v.form.cost.$error : null"
        type="text"
      />
      <b-form-invalid-feedback
        v-if="$v.form.cost.$dirty"
        class="technician-service-cost-feedback"
      >
        <span v-if="!$v.form.cost.required">Please enter a cost.</span>
        <span v-if="!$v.form.cost.decimal">Please enter a number.</span>
        <span v-if="!$v.form.cost.serverFailed">
          {{ serverErrors.cost_cents }}
        </span>
      </b-form-invalid-feedback>
    </b-form-group>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="technician-service-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="technician-service-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import ServiceSelect from '@/components/admin/services/Select.vue';
import UserSelect from '@/components/admin/users/Select.vue';
import { USER_TECHNICIAN_CATEGORY } from '@/constants/users';

export default {
  name: 'TechnicianServicesForm',
  components: {
    ServiceSelect,
    UserSelect,
  },
  mixins: [formMixin],
  props: {
    cost: Object,
    location_id: [String, Number],
    service_id: [String, Number],
    assigned_to_id: [String, Number],
    done_by_id: [String, Number],
    location: Object,
    service: Object,
    assigned_to: Object,
    done_by: Object,
  },
  data() {
    return {
      form: {
        cost: this.cost && this.cost.amount ? this.cost.amount / 100 : null,
        cost_cents: this.cost && this.cost.amount ? this.cost.amount : null,
        location_id: this.location_id,
        service_id: this.service_id,
        assigned_to_id: this.assigned_to_id,
        done_by_id: this.done_by_id,
        location: this.location,
        service: this.service,
        assigned_to: this.assigned_to,
        done_by: this.done_by,
      },
      fieldLabels: {
        cost: 'Cost',
        location_id: 'Location',
        service_id: 'Service',
        assigned_to_id: 'Assigned Technician',
        done_by_id: 'Completed By',
      },
      technicianSelectParams: { category: USER_TECHNICIAN_CATEGORY, active: true },
    };
  },
  validations: {
    form: {
      cost: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('cost_cents');
        },
      },
      location_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('location_id');
        },
      },
      service_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('service_id');
        },
      },
      assigned_to_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('assigned_to_id');
        },
      },
      done_by_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('done_by_id');
        },
      },
    },
  },
  watch: {
    'form.cost': function (newCost) {
      this.form.cost_cents = isNaN(newCost) ? null : Math.round(newCost * 100);
    }
  },
  methods: {
    onServiceChange(service) {
      this.form.service_id = service ? service.id : '';
      this.form.service = service;
    },
    onAssignedToChange(user) {
      this.form.assigned_to_id = user ? user.id : null;
      this.form.assigned_to = user;
      this.form.done_by_id = user ? user.id : null;
      this.form.done_by = user;
    },
    onDoneByChange(user) {
      this.form.done_by_id = user ? user.id : null;
      this.form.done_by = user;
    },
  },
};
</script>
