<template>
  <sfs-base-table
    :data-provider="$insuranceCertificateCoveragesAPI.getInsuranceCertificateCoverages"
    :data-id="insuranceCertificateId"
    provider-key="coverages"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(start_date)="data">
      <span v-if="data.item.start_date">{{ data.item.start_date | dateFormat }}</span>
      <span
        v-else
        class="font-size-sm"
      ><em>-</em></span>
    </template>
    <template #cell(end_date)="data">
      {{ data.item.end_date | dateFormat }}
    </template>
    <template #cell(verified)="data">
      <b-badge
        v-if="data.item.verified"
        variant="success"
      >
        Verified
      </b-badge>
      <b-badge
        v-else
        variant="secondary"
      >
        Not Verified
      </b-badge>
    </template>
    <template #cell(actions)="data">
      <b-button
        v-b-modal="`insurance-certificate-verification-table-${data.item.id}-modal`"
        size="sm"
        variant="link"
      >
        <i class="fa fa-eye" />
      </b-button>
      <verifications-table-modal v-model="data.item" />
    </template>
  </sfs-base-table>
</template>

<script>
import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';
import { INSURANCE_CERTIFICATE_COVERAGES_TABLE_DEFAULT_COLUMNS } from '@/constants/insuranceCertificateCoverages';
import VerificationsTableModal from '@/components/admin/insuranceCertificateVerifications/TableModal.vue';
export default {
  name: 'InsuranceCertificateCoveragesTable',
  mixins: [urlMixin, tableMixin],
  components: {
    VerificationsTableModal,
  },
  props: {
    insuranceCertificateId: {
      type: Number,
      required: true,
    },
    columns: {
      type: Array,
      default: () => INSURANCE_CERTIFICATE_COVERAGES_TABLE_DEFAULT_COLUMNS,
    },
  },
};
</script>
