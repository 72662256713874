<template>
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :value="value"
    @input="(glCode) => $emit('input', glCode)"
  >
    <template #option="option">
      <div>{{ option.name }}</div>
      <div class="font-size-sm text-secondary">
        <span>{{ option.code }}</span>
        <span v-if="option.client">- {{ option.client.name }} {{ option.client.code }}</span>
      </div>
    </template>
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        No results found for
        <em>{{ search }}</em>
      </template>
      <em
        style="opacity: 0.7"
        v-else
      >Type to search for GL Codes</em>
    </template>
  </v-select>
</template>
<script>
import { isEqual as _isEqual, isUndefined as _isUndefined, uniqBy as _uniqBy } from 'lodash';

export default {
  name: 'GlCodesSelect',
  props: {
    value: Object,
    id: {
      type: String,
      default: 'gl-code',
    },
    selectLabel: {
      type: String,
      default: 'name',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    filterParams: {
      type: Object,
      default: () => new Object(),
    },
    clientUnassigned: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getGlCodes();
  },
  methods: {
    getGlCodes() {
      const params = {
        ...this.filterParams,
        paginate: false,
      };
      this.$glCodesAPI.getGlCodes(params).then((response) => {
        return response.glCodes;
      })
      .then((glCodes) => {
        if (this.clientUnassigned) {
          this.$glCodesAPI.getGlCodes({ ...params, client_unassigned: true, client_id: null }).then((response) => {
            this.options = _uniqBy(response.glCodes.concat(glCodes), 'id');
          });
        } else {
          this.options = glCodes;
        }
      });
    },
  },
  watch: {
    filterParams: {
      deep: true,
      handler(newFilterParams, oldFilterParams) {
        if (!_isEqual(oldFilterParams, newFilterParams) && !_isUndefined(newFilterParams)) {
          this.getGlCodes();
        }
      },
    },
  },
};
</script>
