<template>
  <sfs-form-modal
    :id="modalId"
    title="Disable Multi-Factor Authentication"
    @on-hide="resetForm"
    :static="!lazyLoad"
  >
    <b-form @submit.stop.prevent="handleSubmit">
      <b-alert
        v-model="incorrectPassword"
        variant="danger"
        dismissible
      >
        The password that you entered is incorrect.
      </b-alert>
      <b-alert
        :show="hasBaseServerErrors()"
        variant="danger"
        dismissible
      >
        <p
          class="mb-0"
          v-for="(error, index) in baseServerErrors"
          :key="index"
        >
          {{ error }}
        </p>
      </b-alert>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.password"
            label-for="disable-mfa-password"
          >
            <b-form-input
              id="disable-mfa-password"
              autocomplete="new-password"
              v-model="$v.form.password.$model"
              :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
              type="password"
            />
            <b-form-invalid-feedback
              v-if="$v.form.password.$dirty"
              id="disable-mfa-password-feedback"
            >
              <span v-if="!$v.form.password.required">Please enter a password.</span>
              <span v-if="!$v.form.password.serverFailed">
                {{ serverErrors.password }}
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="disable-mfa-auth-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="disable-mfa-auth-submit-button"
            block
            type="submit"
            variant="primary"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import { USER_DISABLE_TWO_FACTOR_AUTH_MODAL_ID } from '@/constants';
import { SET_USER_MUTATION } from '@/constants/mutations.js';

export default {
  name: 'DisableMfaAuthModal',
  mixins: [formMixin, formModalMixin],
  props: {
    lazyLoad: {
      type: Boolean,
      default: () => false,
    },
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        email: this.email,
        password: null,
      },
      incorrectPassword: false,
      modalId: USER_DISABLE_TWO_FACTOR_AUTH_MODAL_ID,
      fieldLabels: { password: 'Current Password' },
    };
  },
  validations: {
    form: {
      password: {
        required,
        serverFailed() {
          return !this.hasServerErrors('password');
        },
      },
    },
  },
  methods: {
    handleSubmit() {
      this.resetServerErrors();
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }
      this.$authAPI
        .disableMfaAuth(this.form)
        .then((response) => {
          this.form.password = null;
          this.resetServerErrors();
          this.$v.$reset();
          this.$store.commit(SET_USER_MUTATION, { user: response.data });
          this.$emit('on-save', response.data);
          this.closeModal();
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
          this.incorrectPassword = !this.hasBaseServerErrors();
        });
    },
  },
};
</script>
