<template>
  <line-chart
    :title="title"
    y-axis-title="Total"
    :share-tooltip="true"
    :chart-height="height"
    :skeleton-height="height"
    :stroke-width="3"
    :series="[mobileWorkOrders, serviceChannelWorkOrders, QRCodeWorkOrders, adminWorkOrders]"
    :y-axis-label-formatter="(val) => val?.toFixed(0)"
    :data-function="[getMobileWorkOrders, getServiceChannelWorkOrders, getQRCodeWorkOrders, getAdminWorkOrders]"
    :data-parameters="{
      filters: {
        clientId,
        datePeriod,
      },
      groupPeriod: groupPeriod,
    }"
  />
</template>
<script>
import { DateTime } from 'luxon';
import calculateDateIntervals from '@/utils/calculateDateIntervals';
import { mapTimeSeriesData } from '@/utils/apexchartsSeriesMapping';
import LineChart from '@/components/shared/apexcharts/LineChart.vue';

export default {
  name: 'WorkOrderCompletionSourceChart',
  components: {
    LineChart,
  },
  props: {
    height: {
      type: String,
    },
    title: {
      type: String,
      default: 'Work Orders Completed By App'
    },
    clientId: {
      type: Number,
    },
    assignedToId: {
      type: Number,
    },
    datePeriod: {
      type: Array,
    },
    groupPeriod: {
      type: String,
    },
  },
  data() {
    return {
      mobileWorkOrders: { data: [] },
      serviceChannelWorkOrders: { data: [] },
      QRCodeWorkOrders: { data: [] },
      adminWorkOrders: { data: [] },
    };
  },
  methods: {
    getMobileWorkOrders() {
      return this.$analyticsAPI
        .getWorkOrdersCount({
          client_id: this.clientId,
          assigned_to_id: this.assignedToId,
          checked_out_from: this.datePeriod[0],
          checked_out_to: this.datePeriod[1],
          group_period: this.groupPeriod,
          completion_source: ['mobile'],
          group_by: 'checked_out_at',
          count_by: 'work_order_id',
        })
        .then((response) => {
          this.mobileWorkOrders = {
            name: 'Mobile',
            data: mapTimeSeriesData(response.series, this.intervals),
          };
        })
        .catch(() => {
          this.mobileWorkOrders = { data: [] };
        });
    },
    getServiceChannelWorkOrders() {
      return this.$analyticsAPI
        .getWorkOrdersCount({
          client_id: this.clientId,
          assigned_to_id: this.assignedToId,
          checked_out_from: this.datePeriod[0],
          checked_out_to: this.datePeriod[1],
          group_period: this.groupPeriod,
          completion_sources: ['service_channel'],
          group_by: 'checked_out_at',
          count_by: 'work_order_id',
        })
        .then((response) => {
          this.serviceChannelWorkOrders = {
            name: 'Service Channel',
            data: mapTimeSeriesData(response.series, this.intervals),
          };
        })
        .catch(() => {
          this.serviceChannelWorkOrders = { data: [] };
        });
    },
    getQRCodeWorkOrders() {
      return this.$analyticsAPI
        .getWorkOrdersCount({
          client_id: this.clientId,
          assigned_to_id: this.assignedToId,
          checked_out_from: this.datePeriod[0],
          checked_out_to: this.datePeriod[1],
          group_period: this.groupPeriod,
          completion_sources: ['qr_code'],
          group_by: 'checked_out_at',
          count_by: 'work_order_id',
        })
        .then((response) => {
          this.QRCodeWorkOrders = {
            name: 'QR Code',
            data: mapTimeSeriesData(response.series, this.intervals),
          };
        })
        .catch(() => {
          this.QRCodeWorkOrders = { data: [] };
        });
    },
    getAdminWorkOrders() {
      return this.$analyticsAPI
        .getWorkOrdersCount({
          client_id: this.clientId,
          assigned_to_id: this.assignedToId,
          checked_out_from: this.datePeriod[0],
          checked_out_to: this.datePeriod[1],
          group_period: this.groupPeriod,
          completion_sources: ['admin'],
          group_by: 'checked_out_at',
          count_by: 'work_order_id',
        })
        .then((response) => {
          this.adminWorkOrders = {
            name: 'Admin',
            data: mapTimeSeriesData(response.series, this.intervals),
          };
        })
        .catch(() => {
          this.adminWorkOrders = { data: [] };
        });
    },
  },
  computed: {
    intervals() {
      if (this.datePeriod[0] && this.datePeriod[1]) {
        return calculateDateIntervals(DateTime.fromISO(this.datePeriod[0]), DateTime.fromISO(this.datePeriod[1]), this.groupPeriod);
      } else {
        return undefined;
      }
    }
  }
};
</script>
