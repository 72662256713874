export default {
  methods: {
    /**
     * Adds the specified key pair to the URL's query params.
     * If the key already exists, the specified value will override the previous.
     * The rest of the query will remain untouched.
     * @param {string} key The key to add to query param.
     * @param {string} value The value to add to the query param.
     */
    appendToQueryParams(key, value) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, { [key]: value }),
      });
    },
    /**
     * Adds the object to the URL's query params, using the object's properties.
     * If the object's property already exists in the URL's query params, the specified value will
     *  override the previous. The rest of the query will remain untouched.
     * @param {object} obj The object to add params from, based on `obj`'s properties.
     */
    appendObjectToQueryParams(obj) {
      this.$router
        .push({
          query: Object.assign({}, this.$route.query, obj),
        })
        .catch(() => {});
    },
  },
};
