<template>
  <b-modal
    :id="modalId"
    title="Permissions"
    body-class="text-left"
    size="lg"
    no-close-on-backdrop
    hide-footer
    static
    lazy
  >
    <template #modal-header-close>
      <i class="fa fa-close" />
    </template>
    <b-row>
      <b-col>
        <b-table
          class="mb-0"
          :items="permissions"
          :fields="columns"
          show-empty
          responsive
          small
          table
        >
          <template #cell(name)="data">
            <div class="text-nowrap">
              {{ data.item.name | humanizedFormat | capitalizeFormat }}
            </div>
          </template>
          <template #cell(rules)="data">
            <code>{{ data.item.rules }}</code>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        cols="3"
        offset="9"
      >
        <b-button
          variant="primary"

          block
          id="version-changes-close-button"
          @click="closeModal"
        >
          Close
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { map as _map } from 'lodash';

export default {
  name: 'RolePermissionsModal',
  props: {
    modalId: {
      type: String,
    },
    role: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
  data() {
    return {
      columns: ['name', 'rules'],
    };
  },
  computed: {
    permissions() {
      return _map(this.role.permissions, (rules, name) => {
        return { name: name, rules: rules.join(', ') };
      });
    },
  },
};
</script>
