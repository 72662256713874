<template>
  <b-card>
    <b-row class="mb-3">
      <b-col
        cols="auto"
        class="mr-auto"
      >
        <h4>
          Contact Info
        </h4>
      </b-col>
      <b-col cols="auto">
        <b-button
          v-if="$can('update', referenceType)"
          variant="outline-secondary"
          v-b-modal.contact-info-add-modal
          v-b-tooltip.hover
          title="Create new Contact Info"
        >
          <i class="fa fa-plus" />
        </b-button>
      </b-col>
    </b-row>

    <b-row v-if="contactInfos.length === 0">
      <b-col class="my-4 text-center">
        There are no contact infos to show
      </b-col>
    </b-row>

    <card-item
      v-else
      v-for="(contactInfo, index) in contactInfos"
      :class="{ 'mb-3': index !== contactInfos.length - 1 }"
      :key="contactInfo.id"
      :reference-type="referenceType"
      :value="contactInfo"
      @on-destroy="() => handleContactInfoDestroyed(contactInfo)"
      @on-save="(updatedContactInfo) => handleContactInfoUpdated(updatedContactInfo)"
    />

    <b-row
      v-if="hasMore"
      class="mt-3"
    >
      <b-col>
        <b-button
          variant="link"
          class="font-w600"
          @click="handleShowMore"
        >
          Show More
        </b-button>
      </b-col>
    </b-row>
    <contact-infos-new-modal
      v-if="$can('update', referenceType)"
      :reference-id="referenceId"
      :create-provider="createFunc"
      @on-save="handleContactInfoAdded"
    />
  </b-card>
</template>

<script>
import { isEqual as _isEqual, isUndefined as _isUndefined } from 'lodash';
import paginationMixin from '@/mixins/paginationMixin';
import CardItem from '@/components/admin/contactInfos/CardItem.vue';
import ContactInfosNewModal from '@/components/admin/contactInfos/NewModal.vue';

export default {
  name: 'ContactInfosCardList',
  mixins: [paginationMixin],
  components: {
    CardItem,
    ContactInfosNewModal,
  },
  props: {
    referenceId: {
      type: Number,
      required: true,
    },
    referenceType: {
      type: String,
      required: true,
    },
    listFunc: {
      type: Function,
      required: true,
    },
    createFunc: {
      type: Function,
      required: true,
    },
    appendToQuery: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      loaded: false,
      isLoadingData: false,
      pagination: {
        currentPage: 1,
        perPage: 3,
      },
      totalRows: 0,
      contactInfos: [],
    };
  },
  methods: {
    updateTotalRows(newTotal) {
      this.totalRows = newTotal;
      this.$emit('total-rows-changed', newTotal);
    },
    handleShowMore() {
      this.pagination = { ...this.pagination, currentPage: this.pagination.currentPage + 1 };
    },
    getContactInfos() {
      this.listFunc(
        this.referenceId, {
          page: this.pagination.currentPage,
          per_page: this.pagination.perPage,
          active: true
        })
        .then((response) => {
          const { total, contactInfos } = response;
          this.updateTotalRows(total);
          this.contactInfos = [...this.contactInfos, ...contactInfos];
        })
        .finally(() => {
          this.loaded = true;
          this.isLoadingData = false;
        });
    },
    handleContactInfoAdded(newContactInfo) {
      this.contactInfos = [newContactInfo, ...this.contactInfos];
      this.updateTotalRows(this.totalRows + 1);
    },
    handleContactInfoDestroyed(contactInfo) {
      const contactInfoIndex = this.contactInfos.findIndex((currentContactInfo) => currentContactInfo.id === contactInfo.id);
      if (contactInfoIndex !== -1) {
        this.contactInfos.splice(contactInfoIndex, 1);
        this.updateTotalRows(this.totalRows - 1);
      }
    },
    handleContactInfoUpdated(updatedContactInfo) {
      const contactInfoIndex = this.contactInfos.findIndex((item) => item.id === updatedContactInfo.id);
      if (contactInfoIndex !== -1) {
        this.contactInfos[contactInfoIndex] = updatedContactInfo;
        this.contactInfos = [...this.contactInfos];
      }
    }
  },
  computed: {
    hasMore() {
      return this.contactInfos.length < this.totalRows;
    },
  },
  watch: {
    'pagination.currentPage': function (newPage, oldPage) {
      if (!_isEqual(newPage, oldPage) && !_isUndefined(oldPage)) {
        this.getContactInfos();
      }
    },
  },
   mounted() {
    this.getContactInfos();
  },
};
</script>
