<template>
  <div>
    <base-page-heading title="Vendors">
      <template #extra>
        <b-button
          v-if="$can('create', 'Vendor')"
          variant="primary"
          class="mr-2"
          v-b-modal.vendor-add-modal
          v-b-tooltip.hover
          title="Create a new Vendor"
        >
          <i class="fa fa-plus" />
          New Vendor
        </b-button>
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <vendors-list-filter
            :table-name="tableName"
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>

      <vendors-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
    <!-- Page Modals -->
    <vendors-new-modal v-if="$can('create', 'Vendor')" />
  </div>
</template>

<script>
import VendorsNewModal from '@/components/admin/vendors/NewModal.vue';
import VendorsTable from '@/components/admin/vendors/Table.vue';
import VendorsListFilter from '@/components/admin/vendors/ListFilter.vue';
import listMixin from '@/mixins/listMixin';
import { VENDOR_TABLE_NAME } from '@/constants/vendors';

export default {
  name: 'VendorsList',
  components: {
    VendorsNewModal,
    VendorsListFilter,
    VendorsTable,
  },
  mixins: [listMixin],
  props: {
    text: String,
    name: String,
    city: String,
    state: String,
    account_number: String,
  },
  data() {
    return {
      tableName: VENDOR_TABLE_NAME,
      filters: {
        text: this.text,
        name: this.name,
        city: this.city,
        state: this.state,
        account_number: this.account_number,
      },
    };
  },
};
</script>
