<template>
  <b-form
    @submit.stop.prevent="handleSubmit"
    class="text-left"
  >
    <b-form-group
      :label="fieldLabels.supply_id"
      label-for="supply-order-item-supply_id"
    >
      <supply-select
        name="supply-order-item-supply_id"
        :select-class="{ 'is-invalid': $v.form.supply_id.$dirty && $v.form.supply_id.$error }"
        :value="form.supply"
        @input="onSupplyChange"
        :vendor-id="vendor_id"
      />
      <b-form-invalid-feedback
        v-if="$v.form.supply_id.$dirty"
        class="supply-order-item-supply_id-feedback"
      >
        <span v-if="!$v.form.supply_id.required">Please enter a supply.</span>
        <span v-if="!$v.form.supply_id.serverFailed">
          {{ serverErrors.supply }}
        </span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.quantity"
      label-for="supply-order-item-quantity"
    >
      <b-form-input
        name="supply-order-item-quantity"
        v-model="$v.form.quantity.$model"
        :state="$v.form.quantity.$dirty ? !$v.form.quantity.$error : null"
        type="text"
      />
      <b-form-invalid-feedback
        v-if="$v.form.quantity.$dirty"
        class="supply-order-item-quantity-feedback"
      >
        <span v-if="!$v.form.quantity.required">Please enter a quantity.</span>
        <span v-if="!$v.form.quantity.decimal">Please enter a number.</span>
        <span v-if="!$v.form.quantity.serverFailed">{{ serverErrors.quantity }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.cost"
      label-for="supply-order-item-cost"
    >
      <b-input-group
        prepend="$"
        :class="{ 'is-invalid': $v.form.cost.$dirty && $v.form.cost.$error, 'has-warning': this.negativeProfitability }"
      >
        <b-form-input
          name="supply-order-item-cost"
          v-model="$v.form.cost.$model"
          :state="$v.form.cost.$dirty ? !$v.form.cost.$error : null"
          type="text"
        />
      </b-input-group>
      <b-form-invalid-feedback
        v-if="$v.form.cost.$dirty"
        :state="$v.form.cost.$dirty ? !$v.form.cost.$error : null"
        class="supply-order-item-cost-feedback"
      >
        <span v-if="!$v.form.cost.required">Please enter a cost.</span>
        <span v-if="!$v.form.cost.decimal">Please enter a number.</span>
        <span v-if="!$v.form.cost.serverFailed">
          {{ serverErrors.cost_cents }}
        </span>
      </b-form-invalid-feedback>
      <div class="warning-feedback">
        <span v-if="negativeProfitability">Warning: cost is more than the price.</span>
      </div>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.price"
      label-for="supply-order-item-price"
    >
      <b-input-group
        prepend="$"
        :class="{ 'is-invalid': $v.form.price.$dirty && $v.form.price.$error }"
      >
        <b-form-input
          name="supply-order-item-price"
          v-model="$v.form.price.$model"
          :state="$v.form.price.$dirty ? !$v.form.price.$error : null"
          type="text"
        />
      </b-input-group>
      <b-form-invalid-feedback
        v-if="$v.form.price.$dirty"
        :state="$v.form.price.$dirty ? !$v.form.price.$error : null"
        class="supply-order-item-price-feedback"
      >
        <span v-if="!$v.form.price.required">Please enter a price.</span>
        <span v-if="!$v.form.price.decimal">Please enter a number.</span>
        <span v-if="!$v.form.price.serverFailed">
          {{ serverErrors.price_cents }}
        </span>
      </b-form-invalid-feedback>
    </b-form-group>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="supply-order-item-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="supply-order-item-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import SupplySelect from '@/components/admin/supplies/Select.vue';

export default {
  name: 'ServicesForm',
  components: {
    SupplySelect,
  },
  mixins: [formMixin],
  props: {
    quantity: Number,
    cost: Object,
    price: Object,
    supply_id: [String, Number],
    supply: Object,
    vendor_id: { type: [String, Number], required: true },
  },
  data() {
    return {
      form: {
        quantity: this.quantity,
        cost: this.cost && this.cost.amount ? this.cost.amount / 100 : null,
        cost_cents: this.cost && this.cost.amount ? this.cost.amount : null,
        price: this.price && this.price.amount ? this.price.amount / 100 : null,
        price_cents: this.price && this.price.amount ? this.price.amount : null,
        supply_id: this.supply_id,
        supply: this.supply,
      },
      fieldLabels: {
        quantity: 'Quantity *',
        cost: 'Cost *',
        price: 'Price *',
        cost_cents: 'Unit Cost',
        price_cents: 'Unit Price',
        supply_id: 'Supply *',
      },
    };
  },
  validations: {
    form: {
      quantity: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('quantity');
        },
      },
      cost: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('cost_cents');
        },
      },
      price: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('price_cents');
        },
      },
      supply_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('supply');
        },
      },
    },
  },
  watch: {
    'form.cost': function (newCost) {
      this.form.cost_cents = isNaN(newCost) ? 0 : Math.round(newCost * 100);
    },
    'form.price': function (newPrice) {
      this.form.price_cents = isNaN(newPrice) ? 0 : Math.round(newPrice * 100);
    },
    'form.supply': function (supply) {
      this.form.cost_cents = supply.cost.amount;
      this.form.price_cents = supply.price.amount;
      this.form.cost = supply.cost.amount / 100;
      this.form.price = supply.price.amount / 100;
    },
  },
  computed: {
    negativeProfitability() {
      return this.form.cost_cents > this.form.price_cents;
    },
  },
  methods: {
    onSupplyChange(supply) {
      this.form.supply_id = supply ? supply.id : '';
      this.form.supply = supply;
    },
  },
};
</script>
