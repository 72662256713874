<template>
  <b-modal
    :id="id"
    :size="size"
    :title="title"
    hide-footer
    no-close-on-backdrop
    :static="isStatic"
    :lazy="isLazy"
    @hide="() => $emit('on-hide')"
  >
    <template #modal-header-close>
      <i class="fa fa-close" />
    </template>
    <slot />
  </b-modal>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    id: String,
    size: String,
    title: String,
    isStatic: {
      type: Boolean,
      default: () => true
    },
    isLazy: {
      type: Boolean,
      default: () => false
    },
  },
};
</script>
