<template>
  <sfs-base-table
    :data-provider="$insuranceCertificateVerificationsAPI.getInsuranceCertificateVerifications"
    :data-id="insuranceCertificateCoverageId"
    provider-key="verifications"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(user_id)="data">
      <b-link :to="{ name: 'user', params: { id: data.item.user.id } }">
        {{ data.item.user.name }}
      </b-link>
    </template>
    <template #cell(audited_at)="data">
      {{ data.item.audited_at | dateTimeFormat }}
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import { INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_DEFAULT_COLUMNS } from '@/constants/insuranceCertificateVerifications';

export default {
  name: 'InsuranceCertificateVerificationsTable',
  mixins: [tablePropsMixin],
  props: {
    insuranceCertificateCoverageId: {
      type: Number,
      required: true,
    },
    columns: {
      type: Array,
      default: () => INSURANCE_CERTIFICATE_VERIFICATIONS_TABLE_DEFAULT_COLUMNS,
    },
  },
};
</script>
