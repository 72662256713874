import List from '@/pages/admin/supplyOrders/List.vue';
import Show from '@/pages/admin/supplyOrders/Show.vue';
import New from '@/pages/admin/supplyOrders/New.vue';
import handlePaginationRouteProps from '@/utils/handlePaginationRouteProps.js';

export default [
  {
    path: 'supply_orders',
    name: 'supply-orders',
    component: List,
    props: handlePaginationRouteProps,
  },
  {
    path: 'supply_orders/new',
    name: 'new-supply-order',
    component: New,
  },
  {
    path: 'supply_orders/:id(\\d+)',
    name: 'supply-order',
    component: Show,
  },
];
