import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '../helpers/apiPath';

export default {
  getTechnicianPayments(params) {
    return axios.get(adminPath('/technician_payments?') + prepareQueryString(params)).then((response) => {
      return {
        technicianPayments: response.data.map((technicianPayment) => an('TechnicianPayment', technicianPayment)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(adminPath(`/technician_payments/${id}`)).then((response) => an('TechnicianPayment', response.data));
  },
  create(data) {
    return axios
      .post(adminPath('/technician_payments'), {
        ...data,
      })
      .then((response) => an('TechnicianPayment', response.data));
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/technician_payments/${id}`), {
        ...data,
      })
      .then((response) => an('TechnicianPayment', response.data));
  },
  destroy(id) {
    return axios.delete(adminPath(`/technician_payments/${id}`));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/technician_payments.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  getPDF(id) {
    return axios.get(adminPath(`/technician_payments/${id}.pdf`), { responseType: 'blob' });
  },
  getBillDotComSpreadsheet(params) {
    return axios.get(adminPath('/technician_payments/bill_dot_com.csv?') + prepareQueryString(params), {
      responseType: 'blob',
    });
  },
  getNewBillDotComSpreadsheet(params) {
    return axios.get(adminPath('/technician_payments/new_bill_dot_com.csv?') + prepareQueryString(params), {
      responseType: 'blob',
    });
  },
  approve(id) {
    return axios.patch(adminPath(`/technician_payments/${id}/approve`)).then((response) => an('TechnicianPayment', response.data));
  },
  email(id, data) {
    return axios
      .post(adminPath(`/technician_payments/${id}/email`), {
        ...data,
      })
      .then((response) => an('TechnicianPayment', response.data));
  },
  getItems(id, params) {
    return axios.get(adminPath(`/technician_payments/${id}/items?`) + prepareQueryString(params)).then((response) => {
      return {
        technicianPaymentItems: response.data.map((technicianPaymentItem) =>
          an('TechnicianPaymentItem', technicianPaymentItem)
        ),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getTechnicianPaymentItemsSpreadsheet(params) {
    return axios.get(adminPath('/technician_payment_items.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  getItemsSpreadsheet(id, params) {
    return axios.get(adminPath(`/technician_payments/${id}/items.csv?`) + prepareQueryString(params), { responseType: 'blob' });
  },
  getItemsProfitabilitySpreadsheet(params) {
    return axios.get('/technician_payment_items/profitability.csv?' + prepareQueryString(params), {
      responseType: 'blob',
    });
  },
  createItem(id, data) {
    return axios
      .post(adminPath(`/technician_payments/${id}/items`), {
        ...data,
      })
      .then((response) => an('TechnicianPaymentItem', response.data));
  },
  updateItem(technicianPaymentItemId, data) {
    return axios
      .patch(adminPath(`/technician_payment_items/${technicianPaymentItemId}`), {
        ...data,
      })
      .then((response) => an('TechnicianPaymentItem', response.data));
  },
  destroyItem(technicianPaymentItemId) {
    return axios.delete(adminPath(`/technician_payment_items/${technicianPaymentItemId}`));
  },
  createWorkOrderChargeItem(id, data) {
    return axios
      .post(adminPath(`/technician_payments/${id}/items/work_order_charges`), {
        ...data,
      })
      .then((response) => an('TechnicianPaymentItem', response.data));
  },
  finalize(data) {
    return axios
      .post(adminPath('/technician_payments/finalize'), {
        ...data,
      })
      .then((response) => response.data);
  },
};
