<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          v-if="isLoaded"
          ref="users-technicians-table"
          :items="records"
          :fields="columns"
          :busy="isLoadingData"
          :sort-by.sync="sorting.sortBy"
          :sort-desc.sync="sorting.sortDesc"
          @sort-changed="handleSortingChanged"
          no-local-sorting
          show-empty
          responsive
          table
        >
          <template #cell(technician_name)="data">
            <b-link :to="{ name: 'user', params: { id: data.item.technician_id } }">
              {{ data.item.technician_name }}
            </b-link>
          </template>
          <template #cell(actions)="data">
            <b-button
              :to="{ name: 'technician-report', params: { id: data.item.technician_id }, query: { client_id: params.client_id, start_date: params.start_date, end_date: params.end_date, service_id: params.service_id, state: params.state } }"
              size="sm"
              variant="link-dark"
              class="pt-0"
              v-b-tooltip.hover
              title="View detailed report"
            >
              <i class="far fa-file-alt" />
            </b-button>
          </template>
          <template #cell(revenue_cents)="data">
            <div>
              <money-format
                :value="Number(data.item.revenue_cents)"
                subunits-value
              />
              <div class="font-size-sm d-flex text-secondary">
                <span>
                  Cost:
                </span>
                &nbsp;
                <money-format
                  :value="Number(data.item.cost_cents)"
                  subunits-value
                />
              </div>
            </div>
          </template>
          <template #cell(recurring_revenue_cents)="data">
            <money-format
              :value="Number(data.item.recurring_revenue_cents)"
              subunits-value
            />
          </template>
          <template #cell(total_work_orders)="data">
            <div>
              {{ data.item.total_work_orders | numberWithDelimeter }}
            </div>
            <div class="font-size-sm text-secondary">
              {{ data.item.total_locations | numberWithDelimeter }} Locations
            </div>
          </template>
          <template #cell(mobile_usage_percentage)="data">
            <div>
              {{ `${Math.floor(data.item.mobile_usage_percentage)}%` }}
            </div>
          </template>
          <template #cell(average_completion_time)="data">
            <div>
              {{ data.item.average_completion_time | numberFormatFilter }} hrs
            </div>
          </template>
          <template #cell(average_rating)="data">
            <div>
              {{ data.item.average_rating }} <i class="w-2 h-2 si si-star" />
            </div>
          </template>
        </b-table>
        <sfs-skeleton-table
          v-else
          :rows="pagination.perPage"
          :columns="columns.length"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="auto"
        :class="{ 'mr-auto': true, 'ml-auto': !showPerPage }"
      >
        <b-pagination
          v-if="showPagination"
          v-model="pagination.currentPage"
          :total-rows="pagination.maxResults > totalRows ? totalRows : pagination.maxResults"
          :per-page="pagination.perPage"
          last-class="d-none"
        />
      </b-col>
      <sfs-pagination-dropdown
        v-if="showPerPage"
        container-class="col-auto"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="totalRows"
        @per-page-changed="onPerPageChanged"
      />
    </b-row>
  </div>
</template>

<script>
import { Duration } from 'luxon';
import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';
import translationMixin from '@/mixins/translationMixin';
import { TECHNICIAN_REPORT_TABLE_DEFAULT_COLUMNS } from '@/constants/reports';

export default {
  name: 'TechnicianReportTable',
  mixins: [urlMixin, tableMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: () => TECHNICIAN_REPORT_TABLE_DEFAULT_COLUMNS,
    },
  },
  methods: {
    formatDuration(seconds) {
      const duration = Duration.fromObject({ seconds: seconds });
      return duration.toFormat('h:mm');
    },
    getRecords() {
      this.isLoadingData = true;
      this.$analyticsAPI
        .getTechnicianReport(this.params)
        .then((response) => {
          const { total, records } = response;
          this.totalRows = total;
          this.records = records;
        })
        .catch(() => {
          this.records = [];
        })
        .finally(() => {
          this.loaded = true;
          this.isLoadingData = false;
          this.$emit('data-changed', this.records);
        });
    },
  },
};
</script>
