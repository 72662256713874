<template>
  <div class="text-nowrap">
    <b-badge
      :variant="variant"
    >
      {{ status | humanizedFormat | capitalizeFormat }}
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'StatusBadge',
  props: {
    status: {
      type: String,
      default: '',
    },
    statusVariants: {
      type: Object,
      required: true,
    }
  },
  computed: {
    variant() {
      return this.statusVariants[this.status];
    },
  },
};
</script>
