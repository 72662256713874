<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col>
        <b-row class="mb-0">
          <b-col>
            <b-form-group
              :label="fieldLabels.content"
              label-for="note-content"
              label-sr-only
            >
              <b-form-textarea
                id="note-content"
                v-model="$v.form.content.$model"
                :state="$v.form.content.$dirty ? !$v.form.content.$error : null"
                :placeholder="fieldLabels.content"
                rows="4"
              />
              <b-form-invalid-feedback
                v-if="$v.form.content.$dirty"
                id="note-content-feedback"
              >
                <span v-if="!$v.form.content.serverFailed">{{ serverErrors.content }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <b-form-group label-for="note-internal">
              <b-form-checkbox
                id="note-internal"
                v-model="$v.form.internal.$model"
              >
                {{ fieldLabels.internal }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
            offset="6"
          >
            <b-button
              id="note-submit-button"
              block
              type="submit"
              variant="primary"
              :disabled="!form.content.length"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import formMixin from '@/mixins/formMixin';

export default {
  name: 'NotesTimelineForm',
  mixins: [formMixin],
  props: {
    referenceId: {
      type: Number,
      required: true,
    },
    createFunc: {
      type: Function,
      required: true,
    },
    content: {
      type: String,
      default: '',
    },
    internal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        content: this.content,
        internal: this.internal,
      },
      fieldLabels: {
        content: 'Add a note here',
        internal: 'Is this note for SCOPES employees ONLY?',
      },
    };
  },
  validations: {
    form: {
      content: {
        serverFailed() {
          return !this.hasServerErrors('content');
        },
      },
      internal: {},
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.createFunc(this.referenceId, this.form)
        .then((note) => {
          this.$emit('on-save', note);
          this.form.content = '';
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
        });
    }
  },
};
</script>
