<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          v-if="isLoaded"
          :items="records"
          :fields="columns"
          :busy="isLoadingData"
          :sort-by.sync="sorting.sortBy"
          :sort-desc.sync="sorting.sortDesc"
          @sort-changed="handleSortingChanged"
          no-local-sorting
          show-empty
          responsive
          table
        >
          <template #cell(work_order_id)="data">
            <b-link
              v-if="data.item.work_order"
              :to="{ name: 'technician-work-order', params: { id: data.item.work_order.id } }"
            >
              {{ data.item.work_order.number }}
            </b-link>
          </template>
          <template #cell(done_by)="data">
            <b-link
              v-if="data.item.done_by && data.item.done_by !== user"
              :to="{ name: 'technician-user', params: { id: data.item.done_by.id } }"
            >
              {{ data.item.done_by.name }}
            </b-link>
          </template>
          <template #cell(description)="data">
            {{ data.item.description }}
          </template>
          <template #cell(location_id)="data">
            <div v-if="data.item.store_number">
              Store # {{ data.item.store_number }}
              <div class="font-size-sm">
                {{ data.item.street }} {{ data.item.city }}, {{ data.item.state }}
              </div>
            </div>
          </template>
          <template #cell(checked_in_at)="data">
            <span v-if="data.item.work_order && data.item.work_order.checked_in_at">
              {{ data.item.work_order.checked_in_at | dateFormat }}
            </span>
          </template>
          <template #cell(amount)="data">
            <money-format
              :value="data.item.amount.amount"
              :currency-code="data.item.amount.currency"
              subunits-value
            />
          </template>
        </b-table>
        <sfs-skeleton-table
          v-else
          :rows="pagination.perPage"
          :columns="columns.length"
        />
      </b-col>
    </b-row>
    <b-row class="mr-2 ml-2">
      <b-col
        cols="auto"
        :class="{ 'mr-auto': true, 'ml-auto': !showPerPage }"
      >
        <b-pagination
          v-if="showPagination"
          v-model="pagination.currentPage"
          :total-rows="pagination.maxResults > totalRows ? totalRows : pagination.maxResults"
          :per-page="pagination.perPage"
          last-class="d-none"
        />
      </b-col>
      <sfs-pagination-dropdown
        v-if="showPerPage"
        container-class="col-auto"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="totalRows"
        @per-page-changed="onPerPageChanged"
      />
    </b-row>
  </div>
</template>

<script>
import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';
import { TECHNICIAN_USER_PAYMENT_ITEM_TABLE_DEFAULT_COLUMNS } from '@/constants/technicianPaymentItems';

export default {
  name: 'TechnicianUserPaymentItemsTable',
  components: {},
  mixins: [urlMixin, tableMixin],
  props: {
    technicianPaymentId: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      default: () => TECHNICIAN_USER_PAYMENT_ITEM_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      subTechnician: null,
    };
  },
  methods: {
    onSave() {
      this.getRecords();
    },
    getRecords() {
      this.isLoadingData = true;
      this.$technicianUserPaymentsAPI
        .getItems(this.technicianPaymentId, this.params)
        .then((response) => {
          const { total, technicianPaymentItems } = response;
          this.totalRows = total;
          this.records = technicianPaymentItems;
        })
        .catch(() => {
          this.records = [];
        })
        .finally(() => {
          this.loaded = true;
          this.isLoadingData = false;
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  }
};
</script>
