import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const TECHNICIAN_PAYMENT_TABLE_NAME = 'technicianPayments';

// The columns available to be shown in a technicianPayments table.
export const TECHNICIAN_PAYMENT_TABLE_COLUMNS = {
  number: { label: 'Number', key: 'number', sortable: true },
  user_id: { label: 'Technician', key: 'user_id', sortable: true },
  manager_id: { label: 'Manager', key: 'manager_id' },
  approved_at: { label: 'Status', key: 'approved_at' },
  start_date: { label: 'Period', key: 'start_date' },
  due_date: { label: 'Due Date', key: 'due_date', sortable: true },
  balance: { label: 'Balance', key: 'balance', sortable: true },
  date_from: { label: 'Paid From', key: 'date_from', filterOnly: true },
  date_to: { label: 'Paid To', key: 'date_to', filterOnly: true },
  emailed: { label: 'Emailed', key: 'emailed', filterOnly: true },
  approved: { label: 'Approved', key: 'approved', filterOnly: true },
  finalized: { label: 'Finalized', key: 'finalized', filterOnly: true },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const TECHNICIAN_PAYMENT_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(TECHNICIAN_PAYMENT_TABLE_COLUMNS);

// The default columns to be shown when the technicianPayments table loads.
export const TECHNICIAN_PAYMENT_TABLE_DEFAULT_COLUMNS = [
  TECHNICIAN_PAYMENT_TABLE_COLUMNS.number,
  TECHNICIAN_PAYMENT_TABLE_COLUMNS.user_id,
  TECHNICIAN_PAYMENT_TABLE_COLUMNS.start_date,
  TECHNICIAN_PAYMENT_TABLE_COLUMNS.due_date,
  TECHNICIAN_PAYMENT_TABLE_COLUMNS.approved_at,
  TECHNICIAN_PAYMENT_TABLE_COLUMNS.balance,
];

export const TECHNICIAN_PAYMENT_TABLE_FILTER_OPTIONS = [
  { text: 'Technician Paid', value: 'true' },
  { text: 'Technician Unpaid', value: 'false' },
  { text: 'Both', value: null },
];

export const TECHNICIAN_PAYMENT_TABLE_EMAILED_FILTER_OPTIONS = [
  { text: 'Emailed', value: 'true' },
  { text: 'Not Emailed', value: 'false' },
  { text: 'Both', value: null },
];

export const TECHNICIAN_PAYMENT_TABLE_APPROVED_FILTER_OPTIONS = [
  { text: 'Approved', value: 'true' },
  { text: 'Not Approved', value: 'false' },
  { text: 'Both', value: null },
];

export const TECHNICIAN_PAYMENT_TABLE_FINALIZED_FILTER_OPTIONS = [
  { text: 'Finalized', value: 'true' },
  { text: 'Not Finalized', value: 'false' },
  { text: 'Both', value: null },
];

export const TECHNICIAN_PAYMENT_STATUS_UPCOMING = 'upcoming';
export const TECHNICIAN_PAYMENT_STATUS_PAID = 'paid';

export const STATUS_VARIANTS = {
  [TECHNICIAN_PAYMENT_STATUS_UPCOMING]: 'warning',
  [TECHNICIAN_PAYMENT_STATUS_PAID]: 'success',
};

// TECHNICIAN PROFILE CONSTANTS

export const TECHNICIAN_USER_PAYMENT_TABLE_NAME = 'technicianUserPayments';

// The columns available to be shown in a technicianPayments table.
export const TECHNICIAN_USER_PAYMENT_TABLE_COLUMNS = {
  number: { label: 'Payment Number', key: 'number', sortable: true },
  status: { label: 'Status', key: 'status' },
  balance: { label: 'Amount', key: 'balance' },
  due_date: { label: 'Payment Date', key: 'due_date', sortable: true },
  start_date: { label: 'Work Period', key: 'start_date' },
  date_between: { label: 'Work Period Between', key: 'date_between', filterOnly: true },
  due_date_between: { label: 'Payment Date Between', key: 'due_date_between', filterOnly: true },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const TECHNICIAN_USER_PAYMENT_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(TECHNICIAN_USER_PAYMENT_TABLE_COLUMNS);

// The default columns to be shown when the technicianPayments table loads for technician users
export const TECHNICIAN_USER_PAYMENT_TABLE_DEFAULT_COLUMNS = [
  TECHNICIAN_USER_PAYMENT_TABLE_COLUMNS.due_date,
  TECHNICIAN_USER_PAYMENT_TABLE_COLUMNS.status,
  TECHNICIAN_USER_PAYMENT_TABLE_COLUMNS.start_date,
  TECHNICIAN_USER_PAYMENT_TABLE_COLUMNS.number,
  TECHNICIAN_USER_PAYMENT_TABLE_COLUMNS.balance,
];
