<template>
  <sfs-form-modal
    :id="modalId"
    title="Finalize Technician Payments"
    @on-hide="resetForm"
  >
    <b-form
      novalidate
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row class="mb-0">
        <b-col>
          <b-form-group
            class="mb-0"
            :label="fieldLabels.due_date"
            label-for="technician-payment-finalize-due_date"
          >
            <sfs-date-picker
              id="technician-payment-finalize-due_date"
              v-model="form.due_date"
              :class="{ 'is-invalid': $v.form.due_date.$dirty && $v.form.due_date.$error }"
              :with-class="{ 'is-invalid': $v.form.due_date.$dirty && $v.form.due_date.$error }"
            />
            <b-form-invalid-feedback
              v-if="$v.form.due_date.$dirty"
              id="technician-payment-finalize-due_date-feedback"
            >
              <span v-if="!$v.form.due_date.required">Please enter a due date.</span>
              <span v-if="!$v.form.due_date.serverFailed">{{ serverErrors.due_date }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="d-flex align-items-center justify-content-between mb-2">
            <div>
              <div>
                <money-format
                  :value="paymentsTotal * 100"
                  currency-code="USD"
                  subunits-value
                />
              </div>
              <div class="dashboard-title text-muted">
                Total Amount to Finalize
              </div>
            </div>
            <div class="item item-rounded-lg bg-body text-alt-secondary opacity-75">
              <i class="fa fa-check-double fa-2x" />
            </div>
          </div>
          <b-row>
            <b-col>
              <sfs-download-button
                v-b-tooltip.hover
                label="Download Payments to Review"
                :provider-params="form"
                filename="Technician Payment Items.csv"
                :file-provider="$technicianPaymentsAPI.getTechnicianPaymentItemsSpreadsheet"
                title="Review Payments"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="technician-payment-finalize-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="technician-payment-finalize-submit-button"
            block
            type="submit"
            variant="primary"
          >
            Finalize
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'TechnicianPaymentsFinalizeModal',
  mixins: [formMixin, formModalMixin],
  props: {
    due_date: String,
  },
  data() {
    return {
      form: {
        due_date: this.due_date,
      },
      fieldLabels: {
        due_date: 'Due Date',
      },
      paymentsTotal: 0,
      modalId: 'technician-payment-finalize-modal',
    };
  },
  validations: {
    form: {
      due_date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('due_date');
        },
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.$technicianPaymentsAPI
        .finalize(this.form)
        .then(() => {
          this.resetServerErrors();
          this.$v.$reset();
          this.$emit('on-save');
          this.closeModal();
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
        });
    },
    fetchTechnicianPaymentsTotal() {
      this.$analyticsAPI
        .getTechnicianPaymentsSum({
          due_date: this.form.due_date,
          group_by: 'due_date',
          sum_by: 'amount',
          finalized: false,
        })
        .then((response) => {
          this.paymentsTotal = Number(response.series[this.form.due_date]) || 0;
        })
        .catch(() => {
          this.paymentsData = 0;
        });
    },
  },
  watch: {
    form: {
      deep: true,
      handler(newFilters) {
        if(newFilters?.due_date) {
          this.fetchTechnicianPaymentsTotal();
        } else {
          this.paymentsData = 0;
        }
      },
    },
  },
};
</script>
<style scoped>
.item-rounded-lg {
  border-radius: 1.5rem;
}
</style>