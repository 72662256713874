/**
 * Returns a number formatted as money.
 * @param {number} val the number to format as money.
 * @param {string} currency the currency of the money.
 * @param {string} notation the notation to apply to the currency such as compact.
 */
export default function (val, notation = 'standard', currency = 'USD') {
  const formatter = new Intl.NumberFormat('en-US', { notation: notation, style: 'currency', currency: currency });
  return formatter.format(val);
}
