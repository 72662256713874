<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$supplyOrdersAPI.getItems"
    :data-id="supplyOrderId"
    provider-key="supplyOrderItems"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
    :append-to-query="false"
  >
    <template #cell(supply_order_id)="data">
      <b-link :to="{ name: 'supply_order', params: { id: data.item.supply_order_id } }">
        {{ data.item.supply_order_id }}
      </b-link>
    </template>
    <template #cell(supply_id)="data">
      {{ data.item.supply.description }} - {{ data.item.supply.units }}
      <div class="font-size-sm text-secondary">
        {{ data.item.supply.item_number }}
      </div>
    </template>
    <template #cell(cost)="data">
      <money-format
        :value="data.item.total_cost.amount"
        :currency-code="data.item.total_cost.currency"
        subunits-value
      />
      <div class="font-size-sm text-secondary">
        <money-format
          :value="data.item.cost.amount"
          :currency-code="data.item.cost.currency"
          subunits-value
        />
      </div>
    </template>
    <template #cell(price)="data">
      <money-format
        :value="data.item.total_price.amount"
        :currency-code="data.item.total_price.currency"
        subunits-value
      />
      <div class="font-size-sm text-secondary">
        <money-format
          :value="data.item.price.amount"
          :currency-code="data.item.price.currency"
          subunits-value
        />
      </div>
    </template>
    <template #cell(margin)="data">
      <money-format
        :value="data.item.total_price.amount - data.item.total_cost.amount"
        :class="marginClass(data.item.total_price.amount - data.item.total_cost.amount)"
        :currency-code="data.item.total_price.currency"
        subunits-value
      />
    </template>
    <template #cell(actions)="data">
      <b-button-group>
        <b-button
          v-if="$can('update', data.item)"
          v-b-modal="`supply-order-item-${data.item.id}-edit-modal`"
          class="mr-1"
          size="sm"
          variant="link"
        >
          <i class="fa fa-pencil-alt" />
        </b-button>
        <b-button
          v-if="$can('destroy', data.item)"
          size="sm"
          variant="link"
          @click="destroySupplyOrderItemDialog(data.item.id)"
        >
          <i class="fa fa-times" />
        </b-button>
        <edit-modal
          v-if="$can('update', data.item)"
          :key="`supply-order-items-edit-modal-${data.item.id}`"
          :value="data.item"
          @on-save="refreshTable"
        />
      </b-button-group>
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import { SUPPLY_ORDER_ITEM_TABLE_DEFAULT_COLUMNS } from '@/constants/supplyOrderItems';
import EditModal from '@/components/admin/supplyOrderItems/EditModal.vue';

export default {
  name: 'SupplyOrderItemsTable',
  mixins: [tablePropsMixin],
  components: {
    EditModal,
  },
  props: {
    supplyOrderId: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      default: () => SUPPLY_ORDER_ITEM_TABLE_DEFAULT_COLUMNS,
    },
  },
  methods: {
    destroySupplyOrderItemDialog(id) {
      this.$swal({
        text: 'Once it\'s deleted, its gone forever!',
        preConfirm: () => this.$supplyOrdersAPI.destroyItem(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
    marginClass(margin) {
      const positiveMargin = margin > 0;
      return {
        'px-2': true,
        'py-1': true,
        'font-size-sm': true,
        'font-w600': true,
        'd-inline': true,
        'text-danger': !positiveMargin,
        'bg-danger-light': !positiveMargin,
        'text-success': positiveMargin,
        'bg-success-light': positiveMargin,
      };
    },
  },
};
</script>
