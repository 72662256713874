<template>
  <b-form
    novalidate
    @submit.stop.prevent="handleSubmit"
  >
    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.audited_at"
          label-for="insurance-certificate-verification-audited_at"
        >
          <sfs-date-time-picker
            name="insurance-certificate-verification-audited_at"
            v-model="formData.audited_at"
            :class="{ 'is-invalid': $v.formData.audited_at.$dirty && $v.formData.audited_at.$error }"
            :with-class="{ 'is-invalid': $v.formData.audited_at.$dirty && $v.formData.audited_at.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.formData.audited_at.$dirty"
            class="insurance-certificate-verification-audited_at-feedback"
          >
            <span
              v-if="!$v.formData.audited_at.required"
              class="mb-0"
            >Please enter a verification time.</span>
            <span v-if="!$v.formData.audited_at.serverFailed">{{ serverErrors.audited_at }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="insurance-certificate-verification-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="insurance-certificate-verification-submit-button"
          block
          type="submit"
          variant="primary"
        >
          Verify
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';

export default {
  name: 'InsuranceCertificateVerificationsForm',
  mixins: [formMixin],
  props: {
    audited_at: String,
  },
  data() {
    return {
      formData: {
        audited_at: this.audited_at,
      },
      fieldLabels: {
        audited_at: 'Audited At',
      },
    };
  },
  validations: {
    formData: {
      audited_at: {
        required,
        serverFailed() {
          return !this.hasServerErrors('audited_at');
        },
      },
    },
  },
  computed: {
    form() {
      return {
        audited_at: this.formData.audited_at,
      };
    },
  },
};
</script>
