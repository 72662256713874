import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const WALKTHROUGH_MODEL_NAME = 'walkthroughs';
export const WALKTHROUGH_TABLE_NAME = 'walkthroughs';

// Vendor label constants
export const WALKTHROUGH_DATE_LABEL = 'Date';
export const WALKTHROUGH_LOCATION_ID_LABEL = 'Location';
export const WALKTHROUGH_CLIENT_ID_LABEL = 'Client';
export const WALKTHROUGH_USER_ID_LABEL = 'Completed By';
export const WALKTHROUGH_ACTIVE_LABEL = 'Active';

export const WALKTHROUGHS_FORM_LABELS = {
  date: WALKTHROUGH_DATE_LABEL,
  location_id: WALKTHROUGH_LOCATION_ID_LABEL,
  client_id: WALKTHROUGH_CLIENT_ID_LABEL,
  user_id: WALKTHROUGH_USER_ID_LABEL,
};

// The columns available to be shown in a walkthroughs table.
export const WALKTHROUGH_TABLE_COLUMNS = {
  date: { label: WALKTHROUGH_DATE_LABEL, key: 'date', sortable: true },
  client_id: { label: WALKTHROUGH_CLIENT_ID_LABEL, key: 'client_id' },
  location_id: { label: WALKTHROUGH_LOCATION_ID_LABEL, key: 'location_id' },
  user_id: { label: WALKTHROUGH_USER_ID_LABEL, key: 'user_id', sortable: true },
  photos: { label: 'Photos', key: 'photos' },
  updated_at: { label: 'Last Modified', key: 'updated_at', sortable: true },
  date_between: { label: 'Completed Between', key: 'date_between', filterOnly: true },
  deleted_at: { label: WALKTHROUGH_ACTIVE_LABEL, key: 'active' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const WALKTHROUGH_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(WALKTHROUGH_TABLE_COLUMNS);

// The default columns to be shown when the walkthroughs table loads.
export const WALKTHROUGH_TABLE_DEFAULT_COLUMNS = [
  WALKTHROUGH_TABLE_COLUMNS.date,
  WALKTHROUGH_TABLE_COLUMNS.location_id,
  WALKTHROUGH_TABLE_COLUMNS.user_id,
  WALKTHROUGH_TABLE_COLUMNS.photos,
];
