<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$versionsAPI.getVersions"
    provider-key="versions"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(user_id)="data">
      <b-link
        v-if="data.item.user_id"
        :to="{ name: 'user', params: { id: data.item.user_id } }"
      >
        {{ data.item.user.name }}
      </b-link>
      <span v-else>System</span>
    </template>
    <template #cell(model)="data">
      <b-link
        v-if="findRoute(data.item.model)"
        :to="{ name: findRoute(data.item.model), params: { id: data.item.model_id } }"
      >
        {{ modelLabel(data.item) }}
      </b-link>
      <span v-else>{{ modelLabel(data.item) }}</span>
    </template>
    <template #cell(event)="data">
      <b-badge variant="secondary">
        {{ data.item.event | capitalizeFormat }}
      </b-badge>
    </template>
    <template #cell(created_at)="data">
      {{ data.item.created_at | dateTimeFormat }}
    </template>

    <template #cell(changes)="data">
      <b-button
        v-b-modal="`version-changes-${data.item.id}-modal`"
        size="sm"
        variant="link"
      >
        <i class="fa fa-eye" />
      </b-button>
      <version-changes-modal v-model="data.item" />
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import { VERSION_TABLE_DEFAULT_COLUMNS, VERSION_MODEL_ROUTES, VERSION_MODEL_NAMES } from '@/constants/versions';

import VersionChangesModal from '@/components/admin/versions/ChangesModal';

export default {
  name: 'VersionsTable',
  components: {
    VersionChangesModal,
  },
  mixins: [tablePropsMixin],
  props: {
    columns: {
      type: Array,
      default: () => VERSION_TABLE_DEFAULT_COLUMNS,
    },
  },
  methods: {
    findRoute(model) {
      return VERSION_MODEL_ROUTES[model];
    },
    modelLabel(version) {
      return `${VERSION_MODEL_NAMES[version.model]} ${version.model_id}`;
    },
  },
};
</script>
