<template>
  <div>
    <base-page-heading title="Technician KPI Report" />
    <div class="content">
      <technician-list-filter
        :columns="columns"
        :initial-filters="filters"
        @filters-applied="applyFilters"
      />
      <technician-table
        :filters="filters"
        :columns="columns"
        sort-by="revenue_cents"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import listMixin from '@/mixins/listMixin';
import TechnicianListFilter from '@/components/admin/reports/users/TechnicianListFilter.vue';
import TechnicianTable from '@/components/admin/reports/users/TechnicianTable.vue';
import { WORK_ORDER_TECHNICIAN_REPORT_TABLE_NAME } from '@/constants/reports';

export default {
  name: 'TechniciansReport',
  components: {
    TechnicianTable,
    TechnicianListFilter,
  },
  mixins: [listMixin],
  props: {
    manager_id: String,
    client_id: String,
    service_id: String,
    state: String,
    start_date: String,
    end_date: String,
    sortBy: {
      type: String,
      default: 'revenue_cents',
    },
  },
  data() {
    return {
      tableName: WORK_ORDER_TECHNICIAN_REPORT_TABLE_NAME,
      filters: {
        manager_id: this.manager_id,
        client_id: this.client_id,
        service_id: this.service_id,
        state: this.state,
        start_date: this.start_date || this.$options.filters.dateFormat(DateTime.now().minus({ month: 6 }).startOf('month').toISODate()),
        end_date: this.end_date || this.$options.filters.dateFormat(DateTime.now().toISODate()),
      },
    };
  },
};
</script>
