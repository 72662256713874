export const SET_USER_MUTATION = 'setUser';
export const SET_IMPERSONATING_USER_MUTATION = 'setImpersonatingUser';
export const SET_IMPERSONATING_TOKEN_MUTATION = 'setImpersonatingToken';
export const SET_TOKEN_MUTATION = 'setToken';
export const SET_RULES_MUTATION = 'setRules';
export const SET_CATEGORY_MUTATION = 'setCategory';
export const SET_ORGANIZATION_MUTATION = 'setOrganization';
export const SET_ORGANIZATION_TERMINOLOGY_MUTATION = 'setOrganizationTerminology';
export const SET_USER_LIST_COLUMNS_MUTATION = 'setUserListColumns';
export const SET_USER_PER_PAGE_MUTATION = 'setUserPerPage';
export const SET_ORGANIZATION_LIST_COLUMNS_MUTATION = 'setOrganizationListColumns';
export const SET_CLIENT_MUTATION = 'setClient';
export const SET_OUT_OF_DATE_MUTATION = 'setAppUpToDate';
export const SET_OUT_OF_DATE_POPUP_RENDERED = 'setUpToDatePopupRendered';
export const SET_PERMISSIONS_MUTATION = 'setPermissions';
