<template>
  <sfs-base-list-filter
    title="Filter Users"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
  >
    <template #quick-filters>
      <b-form-group
        label="Search fields for Data"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Name, Email, Phone or Location"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col>
          <b-form-group
            label="Role"
            label-for="advanced-filter-category"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <category-select
              id="advanced-filter-category"
              placeholder="All"
              v-model="filters.category"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Permissions"
            label-for="advanced-filter-role_ids"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <role-select
              id="advanced-filter-role_ids"
              placeholder="All"
              :value="roles"
              @input="onRolesChange"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Manager"
            label-for="advanced-filter-manager_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <employee-select
              id="advanced-filter-manager_id"
              placeholder="All"
              :value="manager"
              @input="onManagerChange"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-3">

      <b-row>
        <b-col>
          <b-form-group
            label="Services Provided"
            label-for="advanced-filter-services_provided"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <services-provided-select
              id="advanced-filter-services_provided"
              placeholder="Any"
              v-model="filters.services_provided"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            :label="`${$store.getters.translate('client', 'Client')} Serviced`"
            label-for="advanced-filter-client_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <client-select
              id="advanced-filter-client_id"
              placeholder="All"
              :value="client"
              @input="onClientChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="State"
            label-for="advanced-filter-state"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-input
              id="advanced-filter-state"
              v-model="filters.state"
              type="text"
              placeholder="All"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-3">

      <b-row>
        <b-col>
          <b-form-group
            label="Status"
            label-for="advanced-filter-active"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-radio-group
              id="advanced-filter-active"
              v-model="filters.active"
              :options="activeOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="QR Code Access"
            label-for="advanced-filter-user-qr_code_access"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-radio-group
              id="advanced-filter-user-qr_code_access"
              v-model="filters.qr_code_access"
              :options="userQrCodeStatusOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Tech Portal"
            label-for="advanced-filter-user-technician_portal_access"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-radio-group
              id="advanced-filter-user-technician_portal_access"
              v-model="filters.technician_portal_access"
              :options="userTechPortalOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import EmployeeSelect from '@/components/admin/users/EmployeeSelect.vue';
import ClientSelect from '@/components/admin/clients/Select.vue';
import CategorySelect from '@/components/admin/users/CategorySelect.vue';
import RoleSelect from '@/components/admin/users/RoleSelect.vue';
import ServicesProvidedSelect from '@/components/admin/users/ServicesProvidedSelect.vue';
import listFilterMixin from '@/mixins/listFilterMixin';

import { USER_TABLE_COLUMN_OPTIONS, USER_TABLE_DEFAULT_COLUMNS, USER_TABLE_NAME, USER_TABLE_FILTER_OPTIONS, USER_TABLE_PORTAL_OPTIONS } from '@/constants/users';

export default {
  name: 'UsersListFilter',
  components: {
    EmployeeSelect,
    ClientSelect,
    CategorySelect,
    RoleSelect,
    ServicesProvidedSelect,
  },
  mixins: [listFilterMixin],
  data() {
    return {
      availableColumns: USER_TABLE_COLUMN_OPTIONS,
      tableName: USER_TABLE_NAME,
      userQrCodeStatusOptions: USER_TABLE_FILTER_OPTIONS,
      userTechPortalOptions: USER_TABLE_PORTAL_OPTIONS,
    };
  },
  mounted() {
    this.loadInitialFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = USER_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    onManagerChange(user) {
      this.filters.manager_id = user ? user.id.toString() : '';
      this.manager = user;
    },
    onClientChange(client) {
      this.filters.client_id = client ? client.id : '';
      this.client = client;
    },
    onRolesChange(roles) {
      this.filters.role_ids = roles ? roles.map((role) => role.id) : '';
      this.roles = roles;
    },
    loadInitialFilters() {
      const loadManager = new Promise((resolve) => {
        if (this.initialFilters.manager_id) {
          this.$usersAPI.get(this.initialFilters.manager_id).then((manager) => {
            resolve(manager);
          });
        } else {
          resolve(null);
        }
      });

      const loadClient = new Promise((resolve) => {
        if (this.initialFilters.client_id) {
          this.$clientsAPI.get(this.initialFilters.client_id).then((client) => {
            resolve(client);
          });
        } else {
          resolve(null);
        }
      });

      const loadRoles = new Promise((resolve) => {
        if (this.initialFilters.role_ids) {
          this.$usersAPI.getRoles().then((response) => {
            resolve(response.data.filter((role) => this.initialFilters.role_ids.includes(role.id)));
          }).catch(() => {
              resolve(null);
            });
        } else {
          resolve(null);
        }
      });


      Promise.all([loadManager, loadClient, loadRoles]).then((values) => {
        this.manager = values[0];
        this.client = values[1];
        this.roles = values[2];
        this.applyFilters();
      });
    },
  },
};
</script>
