<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.start_date"
          label-for="insurance-certificate-coverage-start_date"
        >
          <sfs-date-picker
            name="insurance-certificate-coverage-start_date"
            v-model="$v.form.start_date.$model"
            :class="{ 'is-invalid': $v.form.start_date.$dirty && $v.form.start_date.$error }"
            :with-class="{ 'is-invalid': $v.form.start_date.$dirty && $v.form.start_date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.start_date.$dirty"
            :state="$v.form.start_date.$dirty ? !$v.form.start_date.$error : null"
            class="insurance-certificate-coverage-start_date-feedback"
          >
            <span v-if="!$v.form.start_date.serverFailed">
              {{ serverErrors.start_date }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.end_date"
          label-for="insurance-certificate-coverage-end_date"
        >
          <sfs-date-picker
            name="insurance-certificate-coverage-end_date"
            v-model="$v.form.end_date.$model"
            :class="{ 'is-invalid': $v.form.end_date.$dirty && $v.form.end_date.$error }"
            :with-class="{ 'is-invalid': $v.form.end_date.$dirty && $v.form.end_date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.end_date.$dirty"
            :state="$v.form.end_date.$dirty ? !$v.form.end_date.$error : null"
            class="insurance-certificate-coverage-end_date-feedback"
          >
            <span v-if="!$v.form.end_date.required">Please enter a coverage end date.</span>
            <span v-if="!$v.form.end_date.serverFailed">
              {{ serverErrors.end_date }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="insurance-certificate-coverage-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="insurance-certificate-coverage-submit-button"
          block
          type="submit"
          variant="primary"
        >
          Renew
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';

export default {
  name: 'InsuranceCertificateCoveragesForm',
  mixins: [formMixin],
  props: {
    start_date: String,
    end_date: String,
  },
  data() {
    return {
      form: {
        start_date: this.start_date,
        end_date: this.end_date,
      },
      fieldLabels: {
        start_date: 'Coverage Start',
        end_date: 'Coverage End *',
      },
    };
  },
  validations: {
    form: {
      start_date: {
        serverFailed() {
          return !this.hasServerErrors('start_date');
        },
      },
      end_date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('end_date');
        },
      },
    },
  },
};
</script>
