<template>
  <div v-if="user">
    <base-page-heading
      :title="user.name"
      show-back-button
    >
      <template #secondary-title>
        <sfs-active-badge :active="!user.deleted_at" />
      </template>
      <template #extra>
        <b-button
          v-if="$can('update', user)"
          variant="primary"
          v-b-modal.user-edit-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Edit the User"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item
            @click="sendWelcomeEmail"
          >
            <i class="fas fa-envelope-circle-check mr-1" />
            Send Welcome Email
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('ios_code', 'User')"
            @click="emailIosCode"
          >
            <i class="fab fa-apple mr-1" />
            Email Technician iOS Invite
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('ios_code', 'User')"
            @click="generateIosCode"
          >
            <i class="fa fa-sync-alt mr-1" />
            {{ user.ios_code ? "Regenerate" : "Generate" }} Technician iOS Code
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('ios_code', 'User') && isEmployee"
            @click="emailEmployeeIosCode"
          >
            <i class="fab fa-apple mr-1" />
            Email Employee App iOS Invite
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('ios_code', 'User') && isEmployee"
            @click="generateEmployeeIosCode"
          >
            <i class="fa fa-sync-alt mr-1" />
            {{ user.employee_ios_code ? "Regenerate" : "Generate" }} Employee App iOS Code
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('update', user)"
            @click="toggleActive"
          >
            <i
              v-if="!user.deleted_at"
              class="fa fa-trash mr-1"
            />
            <i
              v-else
              class="fa fa-undo mr-1"
            />
            {{ toggleActiveTooltipText }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: user.id, model: 'User' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content pt-5">
      <b-row class="mb-3">
        <b-col md="6">
          <b-card>
            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Role
              </b-col>
              <b-col class="text-right">
                <b-badge variant="secondary">
                  {{ user.category | humanizedFormat | capitalizeFormat }}
                </b-badge>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Permissions
              </b-col>
              <b-col class="text-right">
                <div
                  v-for="(role, index) in user.roles"
                  :key="index"
                >
                  <b-badge
                    variant="link"
                    class="mb-1"
                    v-b-modal="`${role.id}-user-role-info`"
                    v-b-tooltip.hover
                    title="View permissions"
                  >
                    {{ role.name | humanizedFormat | capitalizeFormat }}
                  </b-badge>
                  <role-permissions-modal
                    :modal-id="`${role.id}-user-role-info`"
                    :role="role"
                  />
                </div>
              </b-col>
            </b-row>

            <b-row
              v-if="user.manager"
              class="mb-3"
            >
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Manager
              </b-col>
              <b-col class="text-right">
                <b-link :to="{ name: 'user', params: { id: user.manager.id } }">
                  {{ user.manager.name }}
                </b-link>
              </b-col>
            </b-row>

            <b-row
              v-if="servicesProvided"
              class="mb-3"
            >
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Services Provided
              </b-col>
              <b-col class="text-right">
                {{ servicesProvided }}
              </b-col>
            </b-row>

            <h4 class="mb-3">
              Contact
            </h4>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Email
              </b-col>
              <b-col class="text-right">
                {{ user.email }}
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Phone
              </b-col>
              <b-col class="text-right">
                {{ user.phone }}
              </b-col>
            </b-row>

            <div
              v-if="hasAnyAddressFields"
            >
              <h4 class="mb-0">
                Address
              </h4>

              <b-row
                v-if="user.address"
                class="mt-3"
              >
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  Address
                </b-col>
                <b-col class="text-right">
                  {{ user.address }}
                </b-col>
              </b-row>

              <b-row
                v-if="user.city"
                class="mt-3"
              >
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  City
                </b-col>
                <b-col class="text-right">
                  {{ user.city }}
                </b-col>
              </b-row>

              <b-row
                v-if="user.state"
                class="mt-3"
              >
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  State
                </b-col>
                <b-col class="text-right">
                  {{ user.state }}
                </b-col>
              </b-row>

              <b-row
                v-if="user.zip_code"
                class="mt-3"
              >
                <b-col
                  cols="auto"
                  class="mr-auto"
                >
                  Zip Code
                </b-col>
                <b-col class="text-right">
                  {{ user.zip_code }}
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>

        <b-col
          offset-md="1"
          md="5"
        >
          <b-card class="h-100">
            <b-tabs content-class="pt-4">
              <template #tabs-end>
                <b-button
                  v-if="$can('update', user)"
                  class="ml-auto"
                  variant="outline-secondary"
                  v-b-modal.user-settings-modal
                  v-b-tooltip.hover
                  title="Edit the User settings"
                >
                  <i class="fa fa-pencil-alt mr-1" /> Edit
                </b-button>
              </template>
              <b-tab
                active
                title="Settings"
              >
                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Time Zone
                  </b-col>
                  <b-col class="text-right">
                    {{ userTimezoneFormat(user.time_zone) }}
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Language Preference
                  </b-col>
                  <b-col class="text-right">
                    {{ user.locale }}
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Multi-Factor Authentication Required for Login
                  </b-col>

                  <b-col class="text-right">
                    <b-badge
                      v-if="user.otp_required_for_login"
                      variant="success"
                    >
                      Yes
                    </b-badge>
                    <b-badge
                      v-else
                      variant="danger"
                    >
                      No
                    </b-badge>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Technician Portal Access
                  </b-col>

                  <b-col class="text-right">
                    <b-badge variant="secondary">
                      {{ user.technician_portal_access ? 'Yes' : 'No' }}
                    </b-badge>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    QR Code App Access
                  </b-col>

                  <b-col class="text-right">
                    <b-badge
                      v-if="user.qr_code_access"
                      variant="warning"
                    >
                      Yes
                    </b-badge>
                    <b-badge
                      v-else
                      variant="secondary"
                    >
                      No
                    </b-badge>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Can Access Parent Technician's Work Orders
                  </b-col>

                  <b-col class="text-right">
                    <b-badge variant="secondary">
                      {{ user.parent_work_order_access ? 'Yes' : 'No' }}
                    </b-badge>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    Pays Billing Fees
                  </b-col>

                  <b-col class="text-right">
                    <b-badge variant="secondary">
                      {{ user.pays_billing_fees ? 'Yes' : 'No' }}
                    </b-badge>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab title="Mobile App">
                <h4 class="mb-3">
                  Invite Codes
                </h4>

                <b-row class="mb-3">
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    iOS Technician App Code
                  </b-col>
                  <b-col class="text-right">
                    <sfs-copy-button
                      v-if="user.ios_code"
                      :label="user.ios_code.code"
                      :content="user.ios_code.url"
                    />
                    <b-button
                      v-else
                      variant="outline-secondary"
                      size="sm"
                      @click="generateIosCode"
                    >
                      Generate Code
                    </b-button>
                  </b-col>
                </b-row>

                <b-row
                  v-if="isEmployee"
                  class="mb-3"
                >
                  <b-col
                    cols="auto"
                    class="mr-auto"
                  >
                    iOS Employee App Code
                  </b-col>
                  <b-col class="text-right">
                    <sfs-copy-button
                      v-if="user.employee_ios_code"
                      :label="user.employee_ios_code.code"
                      :content="user.employee_ios_code.url"
                    />
                    <b-button
                      v-else
                      variant="outline-secondary"
                      size="sm"
                      @click="generateEmployeeIosCode"
                    >
                      Generate Code
                    </b-button>
                  </b-col>
                </b-row>

                <h4 class="mb-3">
                  Devices
                </h4>

                <div v-if="mobileDevices">
                  <b-row
                    v-for="(data, deviceName) in mobileDevices"
                    :key="deviceName"
                    class="mb-2"
                  >
                    <b-col
                      cols="auto"
                      class="mr-auto font-w600"
                    >
                      {{ deviceName }}
                    </b-col>
                    <b-col class="text-right">
                      {{ data['os'] }} {{ data['os_version'] }}
                      <div
                        v-if="data['last_seen_at']"
                        class="font-size-sm"
                      >
                        <em>Last seen {{ data['last_seen_at'] | dateTimeFormat }}</em>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <b-row>
                    <b-col
                      cols="auto"
                      class="mr-auto"
                    >
                      No device records found
                    </b-col>
                  </b-row>
                </div>

                <h4 class="mb-3 mt-3">
                  Versions
                </h4>

                <div v-if="mobileVersions">
                  <b-row
                    v-for="(lastSeen, version) in mobileVersions"
                    :key="version"
                  >
                    <b-col
                      cols="auto"
                      class="mr-auto"
                    >
                      {{ version }}
                    </b-col>
                    <b-col class="text-right">
                      {{ lastSeen | dateTimeFormat }}
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <b-row>
                    <b-col
                      cols="auto"
                      class="mr-auto"
                    >
                      No versions records found
                    </b-col>
                  </b-row>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="showBottomTabs">
        <b-col cols="12">
          <b-tabs
            content-class="pt-4"
            v-model="activeTab"
          >
            <template #tabs-end>
              <div
                v-show="!isEmployee && activeTab === 0"
                class="ml-auto"
              >
                <b-button
                  v-if="$can('create', 'UserOwnership')"
                  v-b-modal.user-ownerships-add-modal
                  variant="outline-secondary"
                  v-b-tooltip.hover
                  title="Create a new Sub Technician for this User"
                >
                  <i class="fa fa-plus mr-1" /> New
                </b-button>
              </div>

              <div
                v-show="(isEmployee && activeTab === 0) || (!isEmployee && activeTab === 1)"
                class="ml-auto"
              >
                <b-button
                  v-if="$can('create', 'RecurringPayment')"
                  v-b-modal.recurring-payment-add-modal
                  variant="outline-secondary"
                  v-b-tooltip.hover
                  title="Create a new Recurring Payment for this User"
                >
                  <i class="fa fa-plus mr-1" /> New Recurring Payment
                </b-button>
              </div>
            </template>
            <b-tab
              v-if="!isEmployee"
              :active="!isEmployee"
              :title="userOwnershipsHeader"
            >
              <user-ownerships-table
                :user="user"
                :append-to-query="false"
                :key="userOwnershipsTableKey"
              />
            </b-tab>

            <b-tab
              v-if="$can('read', 'RecurringPayment')"
              :active="isEmployee"
              title="Recurring Payments"
            >
              <recurring-payments-table
                :columns="recurringPaymentsColumns"
                :user-id="Number(user.id)"
                :append-to-query="false"
                :key="recurringPaymentsTableKey"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>

    <!-- Modals -->
    <edit-modal
      v-if="$can('update', user)"
      v-model="user"
    />
    <settings-modal
      v-if="$can('update', user)"
      v-model="user"
    />
    <new-recurring-payment-modal
      v-if="$can('create', 'RecurringPayment')"
      :user-id="user.id"
      @on-save="refreshRecurringPayments"
      :key="user.id"
    />

    <!-- Modals -->
    <new-user-ownerships-modal
      v-if="$can('create', 'UserOwnership')"
      :technician_id="technicianId"
      :technician="technician"
      :sub_technician_id="subTechnicianId"
      :sub_technician="subTechnician"
      :is-sub-technician="isSubTechnician"
      @save="refreshUserOwnerships"
    />
  </div>
</template>

<script>
import EditModal from '@/components/admin/users/EditModal.vue';
import SettingsModal from '@/components/admin/users/SettingsModal.vue';
import UserOwnershipsTable from '@/components/admin/userOwnerships/Table.vue';
import RecurringPaymentsTable from '@/components/admin/recurringPayments/Table.vue';
import NewRecurringPaymentModal from '@/components/admin/recurringPayments/NewModal.vue';
import NewUserOwnershipsModal from '@/components/admin/userOwnerships/NewModal.vue';
import RolePermissionsModal from '@/components/shared/RolePermissionsModal.vue';
import { USER_TECHNICIAN_CATEGORY, USER_SUB_TECHNICIAN_CATEGORY } from '@/constants/users';
import { RECURRING_PAYMENT_TABLE_NAME } from '@/constants/recurringPayments';
import formMixin from '@/mixins/formMixin';
import { DateTime } from 'luxon';

export default {
  name: 'UsersShow',
  mixins: [formMixin],
  components: {
    EditModal,
    UserOwnershipsTable,
    NewUserOwnershipsModal,
    RecurringPaymentsTable,
    NewRecurringPaymentModal,
    RolePermissionsModal,
    SettingsModal,
  },
  data() {
    return {
      user: null,
      activeTab: 0,
      recurringPaymentsTableName: RECURRING_PAYMENT_TABLE_NAME,
      userOwnershipsKey: Date.now(),
      recurringPaymentsKey: Date.now() + 1,
      timezoneOptions: [],
      genericIosErrorMessage: 'We\'ve experienced an issue while trying to generate the code, Please try again.',
    };
  },
  computed: {
    hasAnyAddressFields() {
      return (
        (this.user.address || '').length > 0 ||
        (this.user.city || '').length > 0 ||
        (this.user.state || '').length > 0 ||
        (this.user.zip_code || '').length > 0
      );
    },
    toggleActiveTooltipText() {
      return !this.user.deleted_at ? 'Deactivate the User' : 'Reactivate the User';
    },
    toggleActiveConfirmText() {
      return !this.user.deleted_at
        ? 'Are you sure you want to cancel the User?'
        : 'Are you sure you want to reactivate the User?';
    },
    isTechnician() {
      return this.user.category == USER_TECHNICIAN_CATEGORY;
    },
    isSubTechnician() {
      return this.user.category == USER_SUB_TECHNICIAN_CATEGORY;
    },
    isEmployee() {
      return !this.isTechnician && !this.isSubTechnician;
    },
    technician() {
      return this.isSubTechnician ? undefined : this.user;
    },
    technicianId() {
      return this.isSubTechnician ? undefined : this.user.id;
    },
    subTechnician() {
      return this.isSubTechnician ? this.user : undefined;
    },
    subTechnicianId() {
      return this.isSubTechnician ? this.user.id : undefined;
    },
    servicesProvided() {
      if (this.user.services_provided.length === 0) {
        return null;
      }

      return this.user.services_provided.map((service) => this.$options.filters.capitalizeFormat(this.$options.filters.humanizedFormat(service))).join(', ');
    },
    userOwnershipsTableKey() {
      return `${this.user.id}-${this.userOwnershipsKey}`;
    },
    recurringPaymentsTableKey() {
      return `${this.user.id}-${this.recurringPaymentsKey}`;
    },
    recurringPaymentsColumns() {
      return this.$store.getters.columnsForTable(this.recurringPaymentsTableName);
    },
    mobileDevices() {
      return this.user.devices;
    },
    mobileVersions() {
      return this.user.app_versions;
    },
    showBottomTabs() {
      return this.$can('read', 'RecurringPayment') || this.isTechnician || this.isSubTechnician;
    },
    userOwnershipsHeader() {
      return this.isSubTechnician ? 'Managing Technicians' : 'Sub Technicians';
    },
  },
  mounted() {
    this.getUser();
    this.getTimeZoneOptions();
  },
  watch: {
    $route: 'getUser',
  },
  methods: {
    getUser() {
      this.$usersAPI
        .get(this.$route.params.id)
        .then((user) => (this.user = user))
        .catch(() => {
          this.$router.push('/404');
        });
    },
    getTimeZoneOptions() {
      this.$usersAPI.getTimeZoneOptions().then((response) => {
        this.timezoneOptions = response.data.time_zone_options.map((option) => {
          return { name: option.name, value: option.identifier };
        });
      });
    },
    userTimezoneFormat(timezone) {
      for (let i = 0; i < this.timezoneOptions.length; i++) {
        if(timezone === this.timezoneOptions[i].value) {
          timezone = this.timezoneOptions[i].name;
        }
      }
      return timezone;
    },
    refreshUserOwnerships() {
      this.userOwnershipsKey = Date.now();
    },
    refreshRecurringPayments() {
      this.recurringPaymentsKey = Date.now() + 1;
    },
    toggleActive() {
      this.$swal({
        text: this.toggleActiveConfirmText,
        preConfirm: () => this.$usersAPI.update(this.$route.params.id, { deleted_at: !this.user.deleted_at ?  DateTime.now().toISODate() : null }),
      }).then((result) => {
        if (result.value) {
          this.user = result.value;
        }
        result.timer;
      });
    },
    generateIosCode() {
      this.$usersAPI
        .generateIosCode(this.$route.params.id, 'technician')
        .then((user) => (this.user = user))
        .catch((error) => {
          if (error.response && error.response.status === 422 && error.response.data.errors) {
            this.processServerErrors(error.response.data.errors);
            this.$bvToast.toast(this.hasBaseServerErrors() ? this.baseServerErrors : this.genericIosErrorMessage, {
              title: 'Oops!',
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false,
            });
          } else {
            this.$bvToast.toast(this.genericIosErrorMessage, {
              title: 'Oops!',
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false,
            });
          }
        });
    },
    generateEmployeeIosCode() {
      this.$usersAPI
        .generateIosCode(this.$route.params.id, 'employee')
        .then((user) => (this.user = user))
        .catch((error) => {
          if (error.response && error.response.status === 422 && error.response.data.errors) {
            this.processServerErrors(error.response.data.errors);
            this.$bvToast.toast(this.hasBaseServerErrors() ? this.baseServerErrors : this.genericIosErrorMessage, {
              title: 'Oops!',
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false,
            });
          } else {
            this.$bvToast.toast(this.genericIosErrorMessage, {
              title: 'Oops!',
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false,
            });
          }
        });
    },
    emailIosCode() {
      this.$swal({
        text: 'Do you want to email the user an iOS Invite?',
        preConfirm: () => {
          return this.$usersAPI.emailIosCode(this.$route.params.id, 'technician').catch(() => {
            this.$bvToast.toast(this.genericIosErrorMessage, {
              title: 'Oops!',
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false,
            });
            return false;
          });
        },
      }).then((result) => {
        if (result.value) {
          this.user = result.value;
        }
        result.timer;
      });
    },
    emailEmployeeIosCode() {
      this.$swal({
        text: 'Do you want to email the user an Employee App iOS Invite?',
        preConfirm: () => {
          return this.$usersAPI.emailIosCode(this.$route.params.id, 'employee').catch(() => {
            this.$bvToast.toast(this.genericIosErrorMessage, {
              title: 'Oops!',
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false,
            });
            return false;
          });
        },
      }).then((result) => {
        if (result.value) {
          this.user = result.value;
        }
        result.timer;
      });
    },
    sendWelcomeEmail() {
      this.$swal({
        text: 'Do you want to send a welcome email to the User?',
        preConfirm: () => {
          return this.$usersAPI.sendWelcomeEmail(this.$route.params.id).catch(() => {
            this.$bvToast.toast(this.genericIosErrorMessage, {
              title: 'Oops!',
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false,
            });
            return false;
          });
        },
      }).then((result) => {
        if (result.value) {
          this.user = result.value;
        }
        result.timer;
      });
    },
  },
};
</script>
