import { PAGINATION_PER_PAGE, PAGINATION_START_PAGE, PAGINATION_MAX_RESULTS } from '@/constants';
import urlMixin from '@/mixins/urlMixin';

export default {
  mixins: [urlMixin],
  props: {
    currentPage: {
      type: Number,
      default: () => PAGINATION_START_PAGE,
    },
    perPage: {
      type: Number,
      default: () => PAGINATION_PER_PAGE,
    },
    appendToQuery: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      pagination: {
        currentPage: this.currentPage,
        perPage: this.$store.getters.userPerPage || this.perPage,
        maxResults: PAGINATION_MAX_RESULTS,
      },
    };
  },
  watch: {
    'pagination.currentPage': function (newCurrentPage) {
      this.appendToQuery && this.appendToQueryParams('currentPage', newCurrentPage);
    },
  },
  methods: {
    resetCurrentPage(total) {
      if (total < this.pagination.currentPage * this.pagination.perPage - this.pagination.perPage) {
        this.pagination.currentPage = 1;
      }
    },
  },
};
