import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const RECURRING_PAYMENT_TABLE_NAME = 'recurringPayments';

// The columns available to be shown in a recurringPayments table.
export const RECURRING_PAYMENT_TABLE_COLUMNS = {
  id: { label: 'ID', key: 'id' },
  description: { label: 'Description', key: 'description' },
  cost: { label: 'Cost', key: 'cost' },
  user_id: { label: 'User', key: 'user_id' },
  gl_code_id: { label: 'GL Code', key: 'gl_code_id' },
  start_date: { label: 'Start Date', key: 'start_date' },
  end_date: { label: 'End Date', key: 'end_date' },
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const RECURRING_PAYMENT_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(RECURRING_PAYMENT_TABLE_COLUMNS);

// The default columns to be shown when the recurringPayments table loads.
export const RECURRING_PAYMENT_TABLE_DEFAULT_COLUMNS = [
  RECURRING_PAYMENT_TABLE_COLUMNS.description,
  RECURRING_PAYMENT_TABLE_COLUMNS.cost,
  RECURRING_PAYMENT_TABLE_COLUMNS.start_date,
  RECURRING_PAYMENT_TABLE_COLUMNS.end_date,
  RECURRING_PAYMENT_TABLE_COLUMNS.actions,
];
