<template>
  <b-badge
    :variant="variant"
  >
    {{ variantText }}
  </b-badge>
</template>

<script>
export default {
  name: 'ActiveBadge',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    variant() {
      return this.active ? 'success' : 'danger';
    },
    variantText() {
      return this.active ? 'Active' : 'Inactive';
    },
  },
};
</script>
