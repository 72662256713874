<template>
  <b-skeleton
    :animation="animation"
    :height="height"
    :width="width"
  />
</template>

<script>
export default {
  name: 'SkeletonChart',
  props: {
    height: String,
    animation: String,
    width: String,
  },
};
</script>
