<template>
  <sfs-form-modal
    :id="modalId"
    size="lg"
    @on-hide="resetFormData"
    :title="`Edit ${$store.getters.translate('client', 'Client')}`"
  >
    <clients-form
      @on-submit="updateClient"
      v-bind="value"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import ClientsForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'ClientsEditModal',
  mixins: [formModalMixin],
  components: {
    ClientsForm,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: 'client-edit-modal',
    };
  },
  methods: {
    updateClient(data, handleUpdate) {
      handleUpdate(this.$clientsAPI.update(this.$route.params.id, data));
    },
    onSave(client) {
      this.closeModal();
      this.$emit('input', client);
    },
  },
};
</script>
