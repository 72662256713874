import { filter as _filter } from 'lodash';

/**
 *  Returns a string with various location props locationObj from joined together with the specified seperator.
 * @param {string} locationObj An object with various location properties
 *                         in the form of ISO 8601 strings.
 * @option {string} seperator the string to seperate the location elements.
 * @option {object} props the property names of the various location elements.
 *                        Used props are 'address', 'city', 'county', 'state', 'zip_code'.
 */
export default function (locationObj, options = {}) {
  const seperator = options['seperator'] || ', ';
  const props = options['props'] || {};
  const address_field = props['address'] || 'address';
  const city_field = props['city'] || 'city';
  const county_field = props['county'] || 'county';
  const state_field = props['state'] || 'state';
  const zip_code_field = props['zip_code'] || 'zip_code';

  return _filter(
    [locationObj[address_field], locationObj[city_field], locationObj[county_field], locationObj[state_field], locationObj[zip_code_field]],
    (field) => field && field.length > 0
  ).join(seperator);
}
