<template>
  <b-modal
    :id="modalId"
    title="Job Error"
    body-class="text-left"
    size="lg"
    no-close-on-backdrop
    hide-footer
    static
    lazy
  >
    <template #modal-header-close>
      <i class="fa fa-close" />
    </template>
    <b-row>
      <b-col>
        <code>{{ error }}</code>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="3"
        offset="9"
      >
        <b-button
          variant="primary"
          block
          id="version-changes-close-button"
          @click="closeModal"
        >
          Close
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>

export default {
  name: 'DelayedJobsErrorDetailsModal',
  props: {
    modalId: {
      type: String,
    },
    value: Object,
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
  data() {
    return {
      columns: ['name', 'rules'],
    };
  },
  computed: {
    error() {
      return this.value.last_error;
    },
  },
};
</script>
