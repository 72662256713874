<template>
  <div v-if="location">
    <base-page-heading
      :title="title"
      show-back-button
    >
      <template #secondary-title>
        <sfs-active-badge :active="!location.deleted_at" />
      </template>
      <template #extra>
        <b-button
          v-if="$can('update', 'Location')"
          variant="primary"
          v-b-modal.location-edit-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Edit the Location"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <b-button
          v-if="$can('update', 'Location')"
          variant="outline-secondary"
          class="mr-2"
          v-b-modal="`location-${location.id}-section-manage-modal`"
          v-b-tooltip.hover
          title="Manage the structure of a Location"
        >
          Building Structure
        </b-button>

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item
            v-if="$can('destroy', 'Location')"
            @click="toggleActive"
          >
            <i
              v-if="!location.deleted_at"
              class="fa fa-trash mr-1"
            />
            <i
              v-else
              class="fa fa-undo mr-1"
            />
            {{ toggleActiveTooltipText }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: location.id, model: 'Location' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content pt-5">
      <b-row class="mb-3">
        <b-col lg="6">
          <b-card class="mb-3">
            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                {{ clientTranslation }}
              </b-col>
              <b-col class="text-right">
                <div
                  v-for="client in location.clients"
                  :key="client.id"
                >
                  <b-link :to="{ name: 'client', params: { id: client.id } }">
                    {{ client.name }} ({{ client.code }})
                  </b-link>
                </div>
              </b-col>
            </b-row>

            <b-row
              v-if="location.manager"
              class="text-right mt-3"
            >
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Manager
              </b-col>
              <b-col class="text-right">
                <b-link :to="{ name: 'user', params: { id: location.manager.id } }">
                  {{ location.manager.name }}
                </b-link>
              </b-col>
            </b-row>

            <b-row
              v-if="location.customer_identifier"
              class="text-right mt-3"
            >
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Customer ID
              </b-col>
              <b-col class="text-right">
                {{ location.customer_identifier }}
              </b-col>
            </b-row>

            <h4 class="mt-3 mb-3">
              Address
            </h4>
            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Street
              </b-col>
              <b-col class="text-right">
                {{ location.street }}
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                City
              </b-col>
              <b-col class="text-right">
                {{ location.city }}
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                County
              </b-col>
              <b-col class="text-right">
                {{ location.county }}
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                State
              </b-col>
              <b-col class="text-right">
                {{ location.state }}
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Zip Code
              </b-col>
              <b-col class="text-right">
                {{ location.zip_code }}
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Time Zone
              </b-col>
              <b-col class="text-right">
                {{ location.time_zone }}
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                <h4 class="mb-0">
                  Supply Orders
                </h4>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Supplies should be delivered to Organization
              </b-col>

              <b-col class="text-right">
                <b-badge
                  v-if="location.supply_order_deliver_to_organization"
                  variant="warning"
                >
                  Yes
                </b-badge>
                <b-badge
                  v-else
                  variant="secondary"
                >
                  No
                </b-badge>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Delivery Notes
              </b-col>

              <b-col class="text-right">
                <div
                  v-if="location.supply_order_delivery_notes"
                  class="text-prewrap"
                >
                  {{ location.supply_order_delivery_notes }}
                </div>
                <div v-else>
                  None
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Internal Notes
              </b-col>

              <b-col class="text-right">
                <div
                  v-if="location.supply_order_internal_notes"
                  class="text-preline"
                >
                  {{ location.supply_order_internal_notes }}
                </div>
                <div v-else>
                  None
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="6">
          <b-row>
            <b-col
              v-if="location.floor_type"
              cols="6"
              lg="4"
              class="mb-3"
            >
              <sfs-metric-card
                class="h-100"
                title="Floor Type"
                icon-class="si si-layers fa-2x"
                :content="floorTypeFormatOptions[location.floor_type]"
              />
            </b-col>

            <b-col
              cols="6"
              lg="4"
              class="mb-3"
            >
              <sfs-metric-card
                class="h-100"
                title="Sales Floor"
                icon-class="si si-basket fa-2x"
              >
                <template #content>
                  <h3 class="font-w300 mb-0">
                    {{ location.sales_floor_footage || 0 }}
                    <span class="font-size-lg text-muted text-nowrap">sq. ft</span>
                  </h3>
                </template>
              </sfs-metric-card>
            </b-col>

            <b-col
              cols="6"
              lg="4"
              class="mb-3"
            >
              <sfs-metric-card
                class="h-100"
                title="Stock Room"
                icon-class="si si-social-dropbox fa-2x"
              >
                <template #content>
                  <h3 class="font-w300 mb-0">
                    {{ location.stock_room_footage || 0 }}
                    <span class="font-size-lg text-muted text-nowrap">sq. ft</span>
                  </h3>
                </template>
              </sfs-metric-card>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <contact-infos-list
                :list-func="$contactInfosAPI.getLocationsContactInfos"
                :create-func="$contactInfosAPI.createLocationsContactInfos"
                :reference-id="location.id"
                reference-type="Location"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col cols="12">
          <b-tabs
            content-class="pt-4"
            v-model="activeTab"
          >
            <template #tabs-end>
              <div
                v-show="activeTab === 1"
                class="ml-auto"
              >
                <b-button
                  v-if="$can('create', 'TechnicianService')"
                  v-b-modal.technician-service-add-modal
                  variant="outline-secondary"
                  v-b-tooltip.hover
                  title="Create a new Technician Service for this Location"
                >
                  <i class="fa fa-plus mr-1" /> Add Technician Service
                </b-button>
              </div>

              <div
                v-show="activeTab === 2"
                class="ml-auto"
              >
                <b-button
                  v-if="$can('create', 'RecurringCharge')"
                  v-b-modal.recurring-charge-add-modal
                  variant="outline-secondary"
                  v-b-tooltip.hover
                  title="Create a new Recurring Charge for this Location"
                >
                  <i class="fa fa-plus" /> Add Charge
                </b-button>
              </div>

              <div
                v-show="activeTab === 3"
                class="ml-auto"
              >
                <b-button
                  v-if="$can('create', 'RecurringWorkOrder')"
                  v-b-modal.recurring-work-order-add-modal
                  variant="outline-secondary"
                  v-b-tooltip.hover
                  title="Create a new Recurring Work Order for this Location"
                >
                  <i class="fa fa-plus mr-1" /> Add Recurring Work Order
                </b-button>
              </div>
            </template>
            <b-tab active>
              <template #title>
                <notes-badge :total="notesTotal" />
              </template>
              <notes-timeline
                :list-func="$notesAPI.getLocationsNotes"
                :list-params="{ include_work_order_notes: true }"
                :create-func="$notesAPI.createLocationsNote"
                :reference-id="location.id"
                reference-type="Location"
                @total-rows-changed="(newTotal) => notesTotal = newTotal"
              />
            </b-tab>

            <b-tab title="Technician Services">
              <technician-services-table
                :columns="technicianServicesColumns"
                :filters="{ location_id: Number(location.id) }"
                :append-to-query="false"
                :key="technicianServicesKey"
              />
            </b-tab>

            <b-tab
              v-if="$can('read', 'RecurringCharge')"
              title="Recurring Charges"
            >
              <recurring-charges-table
                :columns="recurringChargesColumns"
                :location-id="Number(location.id)"
                :append-to-query="false"
                :key="recurringChargesKey"
              />
            </b-tab>


            <b-tab
              v-if="$can('read', 'RecurringWorkOrder')"
              title="Recurring Work Orders"
            >
              <recurring-work-orders-table
                :columns="recurringWorkOrdersColumns"
                :location-id="Number(location.id)"
                :append-to-query="false"
                :key="recurringWorkOrdersKey"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>

    <!-- Modals -->
    <edit-modal
      v-if="$can('update', 'Location')"
      v-model="location"
    />
    <sections-manage-modal
      v-if="$can('update', 'Location')"
      :location="location"
    />
    <new-recurring-charge-modal
      v-if="$can('create', 'RecurringCharge')"
      :location-id="location.id"
      :location="location"
      @on-save="refreshRecurringCharges()"
      :key="recurringChargesKey"
    />
    <new-recurring-work-order-modal
      v-if="$can('create', 'RecurringWorkOrder')"
      :location-id="location.id"
      @on-save="(recurringWorkOrder) => refreshRecurringWorkOrders(recurringWorkOrder)"
      :key="recurringWorkOrdersKey"
    />
    <new-technician-service-modal
      v-if="$can('create', 'TechnicianService')"
      :location_id="location.id"
      @save="refreshTechnicianServices()"
      :key="technicianServicesKey"
    />
  </div>
</template>

<script>
import EditModal from '@/components/admin/locations/EditModal.vue';
import TechnicianServicesTable from '@/components/admin/technicianServices/Table.vue';
import RecurringChargesTable from '@/components/admin/recurringCharges/Table.vue';
import RecurringWorkOrdersTable from '@/components/admin/recurringWorkOrders/Table.vue';
import ContactInfosList from '@/components/admin/contactInfos/CardList.vue';
import NewRecurringChargeModal from '@/components/admin/recurringCharges/NewModal.vue';
import NewRecurringWorkOrderModal from '@/components/admin/recurringWorkOrders/NewModal.vue';
import NewTechnicianServiceModal from '@/components/admin/technicianServices/NewModal.vue';
import SectionsManageModal from '@/components/admin/sections/ManageModal.vue';
import NotesTimeline from '@/components/admin/notes/Timeline.vue';
import NotesBadge from '@/components/admin/notes/TimelineBadge.vue';
import { LOCATION_FLOOR_TYPE_FORMAT_OPTIONS } from '@/constants/locations';
import { TECHNICIAN_SERVICES_TABLE_NAME } from '@/constants/technicianServices';
import { RECURRING_CHARGE_TABLE_NAME } from '@/constants/recurringCharges';
import { RECURRING_WORK_ORDER_TABLE_NAME } from '@/constants/recurringWorkOrders';
import pluralize from 'pluralize';
import { DateTime } from 'luxon';

export default {
  name: 'LocationsShow',
  components: {
    EditModal,
    TechnicianServicesTable,
    RecurringChargesTable,
    RecurringWorkOrdersTable,
    ContactInfosList,
    NewRecurringChargeModal,
    NewTechnicianServiceModal,
    NewRecurringWorkOrderModal,
    SectionsManageModal,
    NotesTimeline,
    NotesBadge,
  },
  data() {
    return {
      location: null,
      notesTotal: 0,
      activeTab: 0,
      technicianServicesTableName: TECHNICIAN_SERVICES_TABLE_NAME,
      recurringChargesTableName: RECURRING_CHARGE_TABLE_NAME,
      recurringWorkOrdersTableName: RECURRING_WORK_ORDER_TABLE_NAME,
      technicianServicesKey: Date.now(),
      recurringChargesKey: Date.now() + 1,
      recurringWorkOrdersKey: Date.now() + 2,
      contactInfosTableKey: Date.now() + 3,
      floorTypeFormatOptions: LOCATION_FLOOR_TYPE_FORMAT_OPTIONS,
    };
  },
  computed: {
    toggleActiveTooltipText() {
      return !this.location.deleted_at ? 'Deactivate' : 'Reactivate';
    },
    toggleActiveConfirmText() {
      return !this.location.deleted_at
        ? 'Are you sure you want to deactivate the Location?'
        : 'Are you sure you want to reactivate the Location?';
    },
    title() {
      let str = this.location.store_number;
      if (this.location.name) {
        str += ` - ${this.location.name}`;
      }

      return str;
    },
    technicianServicesColumns() {
      return this.$store.getters.columnsForTable(this.technicianServicesTableName);
    },
    recurringChargesColumns() {
      return this.$store.getters.columnsForTable(this.recurringChargesTableName);
    },
    recurringWorkOrdersColumns() {
      return this.$store.getters.columnsForTable(this.recurringWorkOrdersTableName);
    },
    clientTranslation() {
      return pluralize(this.$store.getters.translate('client', 'Client'));
    },
  },
  mounted() {
    this.getLocation();
  },
  methods: {
    getLocation() {
      this.$locationsAPI
        .get(this.$route.params.id)
        .then((location) => (this.location = location))
        .catch(() => {
          this.$router.push('/404');
        });
    },
    toggleActive() {
      this.$swal({
        text: this.toggleActiveConfirmText,
        preConfirm: () => this.$locationsAPI.update(this.$route.params.id, { deleted_at: !this.location.deleted_at ?  DateTime.now().toISODate() : null }),
      }).then((result) => {
        if (result.value) {
          this.location = result.value;
        }
        result.timer;
      });
    },
    refreshRecurringCharges() {
      this.recurringChargesKey = Date.now();
    },
    refreshRecurringWorkOrders(recurringWorkOrder) {
      this.recurringWorkOrdersKey = Date.now();
      setTimeout(() => this.$bvModal.show(`recurring-work-order-${recurringWorkOrder.id}-frequencies-modal`), 1000);
    },
    refreshTechnicianServices() {
      this.technicianServicesKey = Date.now();
    },
  },
};
</script>
