<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit Item"
    @on-hide="resetFormData"
  >
    <supply-order-item-form
      v-bind="value"
      :vendor_id="value.supply_order.vendor_id"
      @on-submit="updateItem"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import SupplyOrderItemForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'SupplyOrderItemEditModal',
  mixins: [formModalMixin],
  components: {
    SupplyOrderItemForm,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: `supply-order-item-${this.value.id}-edit-modal`,
    };
  },
  methods: {
    updateItem(data, handleUpdate) {
      handleUpdate(this.$supplyOrdersAPI.updateItem(this.value.id, data));
    },
    onSave(item) {
      this.closeModal();
      this.resetFormData();
      this.$emit('on-save', item);
    },
  },
};
</script>
