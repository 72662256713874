import { SET_RULES_MUTATION } from '@/constants/mutations';

export default (store) => {
  const ability = store.getters.ability;
  ability.update(store.state.auth.rules);
  return store.subscribe((mutation) => {
    if (mutation.type == SET_RULES_MUTATION) {
      ability.update(mutation.payload.rules);
    }
  });
};
