export default {
  methods: {
    async copyImage(url, contentType) {
      const response = await fetch(url,{ mode: 'cors' });
      const blob = await response.blob();
      if (contentType === 'image/png') {
        this.copyBlobToClipboard(blob);
      } else {
        this.copyJpgToClipyboard(blob);
      }
    },
    copyJpgToClipyboard(blob) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const imageEl = document.createElement('img');
      imageEl.src = window.URL.createObjectURL(blob);
      imageEl.onload = (e) => {
        canvas.width = e.target.width;
        canvas.height = e.target.height;
        ctx.drawImage(e.target, 0, 0, e.target.width, e.target.height);
        canvas.toBlob(this.copyBlobToClipboard, 'image/png', 1);
      };
    },
    copyBlobToClipboard(blob) {
      try {
        navigator.clipboard.write([
          new ClipboardItem({
              'image/png': blob,
          })
        ]);
      } catch (error) {
          console.error(error);
      }
    },
  },
};
