<template>
  <div>
    <base-page-heading title="Work Orders" />
    <div class="content">
      <technician-work-orders-list-filter
        :columns="columns"
        :initial-filters="filters"
        :total-rows="totalRows"
        @filters-applied="applyFilters"
      >
        <template #right-tray>
          <b-button
            v-if="$can('reassign', 'WorkOrder')"
            class="mr-2"
            v-b-modal.technician-work-order-reassign-multiple-modal
            variant="primary"
            :disabled="notSelected"
          >
            Reassign
          </b-button>
        </template>
      </technician-work-orders-list-filter>

      <work-orders-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :key="componentKey"
        :work-orders-with-errors="workOrdersWithErrors"
        @selected-changed="selectedChanged"
      />
    </div>

    <!-- Modals -->
    <reassign-multiple-modal
      :selected-work-orders="selectedWorkOrders"
      @update-table="reset"
      @handle-error="handleReassignError"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin';
import { TECHNICIAN_WORK_ORDER_TABLE_NAME } from '@/constants/workOrders';
import WorkOrdersTable from '@/components/technician/workOrders/Table.vue';
import TechnicianWorkOrdersListFilter from '@/components/technician/workOrders/ListFilter.vue';
import ReassignMultipleModal from '@/components/technician/workOrders/ReassignMultipleModal.vue';

export default {
  name: 'TechnicianWorkOrdersList',
  components: {
    WorkOrdersTable,
    TechnicianWorkOrdersListFilter,
    ReassignMultipleModal,
  },
  mixins: [listMixin],
  props: {
    text: String,
    status: String,
    client_id: String,
    location_id: String,
    done_by_id: String,
    date_bucket_start_from: String,
    date_bucket_end_to: String,
    checked_out_at_from: String,
    checked_out_at_to: String,
    scheduled_between: String,
    completed_between: String,
  },
  data() {
    return {
      tableName: TECHNICIAN_WORK_ORDER_TABLE_NAME,
      selectedWorkOrders: [],
      componentKey: 0,
      workOrdersWithErrors: [],
      filters: {
        text: this.text,
        status: this.status,
        client_id: this.client_id,
        location_id: this.location_id,
        done_by_id: this.done_by_id,
        date_bucket_start_from: this.date_bucket_start_from,
        date_bucket_end_to: this.date_bucket_end_to,
        checked_out_at_from: this.checked_out_at_from,
        checked_out_at_to: this.checked_out_at_to,
        scheduled_between: this.scheduled_between,
        completed_between: this.completed_between,
      },
    };
  },
  methods: {
    selectedChanged(selectedOption) {
      this.selectedWorkOrders = selectedOption;
    },
    handleReassignError(failedWorkOrders) {
      // this.reset();
      this.workOrdersWithErrors = failedWorkOrders;
    },
    reset() {
      this.componentKey++;
      this.selectedWorkOrders = [];
    },
  },
  computed: {
    notSelected() {
      return this.selectedWorkOrders.length === 0;
    },
  }
};
</script>

<style scoped>
.disabled {
  cursor: not-allowed;
}
</style>