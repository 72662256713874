import List from '@/pages/admin/insuranceCertificates/List.vue';
import Show from '@/pages/admin/insuranceCertificates/Show.vue';
import handleActivePaginationRouteProps from '@/utils/handleActivePaginationRouteProps.js';

export default [
  {
    path: 'insurance_certificates',
    name: 'insurance-certificates',
    component: List,
    props: handleActivePaginationRouteProps,
  },
  {
    path: 'insurance_certificates/:id(\\d+)',
    name: 'insurance-certificate',
    component: Show,
  },
];
