import List from '@/pages/admin/taxRates/List.vue';
import handleActivePaginationRouteProps from '@/utils/handleActivePaginationRouteProps.js';

export default [
  {
    path: 'tax_rates',
    name: 'tax-rates',
    component: List,
    props: handleActivePaginationRouteProps,
  },
];
