import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '../helpers/apiPath';

export default {
  get(id) {
    return axios.get(adminPath(`/insurance_certificates/${id}`)).then((response) => an('InsuranceCertificate', response.data));
  },
  getInsuranceCertificates(params) {
    return axios.get(adminPath('/insurance_certificates?') + prepareQueryString(params)).then((response) => {
      return {
        insuranceCertificates: response.data.map((insuranceCertificate) =>
          an('InsuranceCertificate', insuranceCertificate)
        ),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/insurance_certificates/${id}`), {
        ...data,
      })
      .then((response) => an('InsuranceCertificate', response.data));
  },
  create(userId, data) {
    return axios
      .post(adminPath(`/users/${userId}/insurance_certificates`), {
        ...data,
      })
      .then((response) => an('InsuranceCertificate', response.data));
  },
  getReminderFrequencyOptions() {
    return axios.get(adminPath('/insurance_certificates/reminder_frequency_options'));
  },
  getCategoryOptions() {
    return axios.get(adminPath('/insurance_certificates/category_options'));
  },
  getStatusOptions() {
    return axios.get(adminPath('/insurance_certificates/status_options'));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/insurance_certificates.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
};
