const humanize_times = (times) => {
  let times_part = '';
  if (times == 1) {
    times_part = 'once';
  } else {
    times_part = `${times} times`;
  }
  return times_part;
};

const humanize_time_interval = (interval, duration) => {
  if (interval == 1) {
    return `per ${duration}`;
  }

  return `every ${interval} ${duration}s`;
};

/**
 * Returns a humanized version of a frequency object.
 * @param {frequency} Object The frequency object to humanize.
 */
export default function (frequency) {
  return `${humanize_times(frequency.times)} ${humanize_time_interval(frequency.interval, frequency.duration)}`;
}
