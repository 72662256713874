<template>
  <sfs-form-modal
    :id="modalId"
    title="Complete Work Order"
    @on-hide="resetForm"
  >
    <b-form
      novalidate
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.assigned_to_id"
            label-for="work-order-complete-assigned_to_id"
          >
            <user-select
              id="work-order-complete-assigned_to_id"
              :select-class="{ 'is-invalid': $v.form.assigned_to_id.$dirty && $v.form.assigned_to_id.$error }"
              select-label="name"
              :value="form.assigned_to"
              :filter-params="technicianSelectParams"
              @input="onAssignedToChange"
            />
            <b-form-invalid-feedback
              v-if="$v.form.assigned_to_id.$dirty"
              id="work-order-complete-assigned_to_id-feedback"
            >
              <span v-if="!$v.form.assigned_to_id.required">Please enter an assigned technician.</span>
              <span v-if="!$v.form.assigned_to_id.serverFailed">{{ serverErrors.assigned_to }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.done_by_id"
            label-for="work-order-complete-done_by_id"
          >
            <user-select
              id="work-order-complete-done_by_id"
              :select-class="{ 'is-invalid': $v.form.done_by_id.$dirty && $v.form.done_by_id.$error }"
              select-label="name"
              :disabled="!form.assigned_to_id"
              :manager-id="form.assigned_to_id"
              :primary-option="form.assigned_to"
              :value="form.done_by"
              @input="onDoneByChange"
            />
            <b-form-invalid-feedback
              v-if="$v.form.done_by_id.$dirty"
              id="work-order-complete-done_by_id-feedback"
            >
              <span v-if="!$v.form.done_by_id.required">Please enter the technician the work is completed by.</span>
              <span v-if="!$v.form.done_by_id.serverFailed">{{ serverErrors.done_by }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.checked_in_at"
            label-for="work-order-complete-checked_in_at"
          >
            <sfs-date-time-picker
              id="work-order-complete-checked_in_at"
              v-model="form.checked_in_at"
              :class="{ 'is-invalid': $v.form.checked_in_at.$dirty && $v.form.checked_in_at.$error }"
              :with-class="{ 'is-invalid': $v.form.checked_in_at.$dirty && $v.form.checked_in_at.$error }"
              :time-zone="value.location && value.location.time_zone"
            />
            <b-form-invalid-feedback
              v-if="$v.form.checked_in_at.$dirty"
              id="work-order-complete-checked_in_at-feedback"
            >
              <span
                v-if="!$v.form.checked_in_at.required"
                class="mb-0"
              >Please enter a check-in time.</span>
              <span v-if="!$v.form.checked_in_at.serverFailed">{{ serverErrors.checked_in_at }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.checked_out_at"
            label-for="work-order-complete-checked_out_at"
          >
            <sfs-date-time-picker
              id="work-order-complete-checked_out_at"
              v-model="form.checked_out_at"
              :class="{ 'is-invalid': $v.form.checked_out_at.$dirty && $v.form.checked_out_at.$error }"
              :with-class="{ 'is-invalid': $v.form.checked_out_at.$dirty && $v.form.checked_out_at.$error }"
              :time-zone="value.location && value.location.time_zone"
            />
            <b-form-invalid-feedback
              v-if="$v.form.checked_out_at.$dirty"
              id="work-order-complete-checked_out_at-feedback"
            >
              <span
                v-if="!$v.form.checked_out_at.required"
                class="mb-0"
              >Please enter a check-out time.</span>
              <span v-if="!$v.form.checked_out_at.serverFailed">{{ serverErrors.checked_out_at }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="work-order-complete-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="work-order-complete-submit-button"
            block
            type="submit"
            variant="primary"
            :disabled="processing"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import { WORK_ORDER_FORM_LABELS } from '@/constants/workOrders';
import { USER_TECHNICIAN_CATEGORY } from '@/constants/users';

import UserSelect from '@/components/admin/users/Select.vue';

export default {
  name: 'WorkOrdersCompleteModal',
  components: {
    UserSelect,
  },
  mixins: [formMixin, formModalMixin],
  props: {
    value: Object,
  },
  data() {
    return {
      form: {
        assigned_to: this.value.assigned_to,
        assigned_to_id: this.value.assigned_to_id,
        done_by: this.value.done_by || this.value.assigned_to,
        done_by_id: this.value.done_by_id || this.value.assigned_to_id,
        checked_in_at: this.value.checked_in_at,
        checked_out_at: this.value.checked_out_at,
      },
      fieldLabels: { ...WORK_ORDER_FORM_LABELS },
      technicianSelectParams: { category: USER_TECHNICIAN_CATEGORY, active: true },
      modalId: 'work-order-complete-modal',
    };
  },
  validations: {
    form: {
      assigned_to_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('assigned_to');
        },
      },
      done_by_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('done_by');
        },
      },
      checked_in_at: {
        required,
        serverFailed() {
          return !this.hasServerErrors('checked_in_at');
        },
      },
      checked_out_at: {
        required,
        serverFailed() {
          return !this.hasServerErrors('checked_out_at');
        },
      },
    },
  },
  methods: {
    onAssignedToChange(user) {
      this.form.assigned_to_id = user ? user.id : '';
      this.form.assigned_to = user;
      this.form.done_by_id = user ? user.id : '';
      this.form.done_by = user;
    },
    onDoneByChange(user) {
      this.form.done_by_id = user ? user.id : '';
      this.form.done_by = user;
    },
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.processing = true;
      this.$workOrdersAPI
        .complete(this.value.id, this.form)
        .then((workOrder) => {
          this.resetServerErrors();
          this.$v.$reset();
          this.$emit('input', workOrder);
          this.closeModal();
          this.processing = false;
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
          this.processing = false;
        });
    },
  },
};
</script>
