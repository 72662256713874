<template>
  <sfs-form-modal
    :id="modalId"
    title="Register Multi-Factor Authentication"
    @on-hide="resetForm"
  >
    <b-form @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-if="qrCode">
        <b-col
          cols="12"
          lg="6"
        >
          <div class="push text-center mb-0">
            <b-img :src="qrCode" />
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          class="pt-4"
        >
          <p>Scan the QR code with your preferred authenticator app.</p>
          <p>
            Supported authenticator apps include: Google Authenticator, Microsoft Authenticator, LastPass
            Authenticator and Authy.
          </p>
        </b-col>
      </b-row>
      <b-alert
        v-model="incorrectPassword"
        variant="danger"
        dismissible
      >
        The password or one-time code that you entered is incorrect.
      </b-alert>
      <b-alert
        :show="hasBaseServerErrors()"
        variant="danger"
        dismissible
      >
        <p
          class="mb-0"
          v-for="(error, index) in baseServerErrors"
          :key="index"
        >
          {{ error }}
        </p>
      </b-alert>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.password"
            label-for="register-mfa-password"
          >
            <b-input-group>
              <b-form-input
                id="register-mfa-password"
                v-model="$v.form.password.$model"
                autocomplete="new-password"
                :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                :type="passwordVisible ? 'text' : 'password'"
              />
              <b-input-group-append>
                <b-button
                  class="rounded-right"
                  variant="light"
                  @click="() => (passwordVisible = !passwordVisible)"
                >
                  <i
                    v-if="passwordVisible"
                    class="fa-regular fa-eye"
                  />
                  <i
                    v-else
                    class="fa-regular fa-eye-slash"
                  />
                </b-button>
              </b-input-group-append>

              <b-form-invalid-feedback
                v-if="$v.form.password.$dirty"
                id="register-mfa-password-feedback"
              >
                <span v-if="!$v.form.password.required">Please enter a password.</span>
                <span v-if="!$v.form.password.serverFailed">
                  {{ serverErrors.password }}
                </span>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.otp_attempt"
            label-for="register-mfa-otp_attempt"
          >
            <b-form-input
              id="register-mfa-otp_attempt"
              v-model="$v.form.otp_attempt.$model"
              :state="$v.form.otp_attempt.$dirty ? !$v.form.otp_attempt.$error : null"
              type="text"
            />
            <b-form-invalid-feedback
              v-if="$v.form.otp_attempt.$dirty"
              id="register-mfa-otp_attempt-feedback"
            >
              <span v-if="!$v.form.otp_attempt.required">Please enter a one-time code.</span>
              <span v-if="!$v.form.otp_attempt.serverFailed">
                {{ serverErrors.otp_attempt }}
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="register-mfa-auth-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="register-mfa-auth-submit-button"
            block
            type="submit"
            variant="primary"
          >
            Register
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import { USER_REGISTER_TWO_FACTOR_AUTH_MODAL_ID } from '@/constants';

export default {
  name: 'RegisterMfaAuthModal',
  mixins: [formMixin, formModalMixin],
  props: {
    lazyLoad: {
      type: Boolean,
      default: () => false,
    },
    email: {
      type: String,
      required: true,
    },
    qrCode: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        email: this.email,
        password: null,
        otp_attempt: null,
      },
      fieldLabels: { password: 'Current Password', otp_attempt: 'One-time Code' },
      modalId: USER_REGISTER_TWO_FACTOR_AUTH_MODAL_ID,
      incorrectPassword: false,
      passwordVisible: false,
    };
  },
  validations: {
    form: {
      password: {
        required,
        serverFailed() {
          return !this.hasServerErrors('password');
        },
      },
      otp_attempt: {
        required,
        serverFailed() {
          return !this.hasServerErrors('otp_attempt');
        },
      },
    },
  },
  methods: {
    handleSubmit() {
      this.resetServerErrors();
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }
      this.$store
        .dispatch('login', this.form)
        .then((response) => {
          this.form.password = null;
          this.form.otp_attempt = null;
          this.resetServerErrors();
          this.$v.$reset();
          this.$emit('on-save', response.data.user);
          this.closeModal();
        })
        .catch((error) => {
          this.processServerErrors(error?.response?.data?.errors);
          this.incorrectPassword = !this.hasBaseServerErrors();
        });
    },
    onTogglePasswordToggleDown() {
      this.passwordVisible = true;
    },
    onTogglePasswordToggleUp() {
      this.passwordVisible = false;
    },
  },
};
</script>
