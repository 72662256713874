<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col>
        <b-alert
          :show="hasBaseServerErrors()"
          variant="danger"
          dismissible
        >
          <p
            class="mb-0"
            v-for="(error, index) in baseServerErrors"
            :key="index"
          >
            {{ error }}
          </p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.number"
          label-for="supply-order-number"
        >
          <b-form-input
            id="supply-order-number"
            v-model="$v.form.number.$model"
            :state="$v.form.number.$dirty ? !$v.form.number.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.number.$dirty"
            id="supply-order-number-feedback"
          >
            <span v-if="!$v.form.number.required">Please enter a number.</span>
            <span v-if="!$v.form.number.maxLength">
              The number must be less than {{ $v.form.number.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.number.serverFailed">{{ serverErrors.number }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.date"
          label-for="supply-order-date"
        >
          <sfs-date-picker
            id="supply-order-date"
            v-model="form.date"
            :class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
            :with-class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.date.$dirty"
            id="supply-order-date-feedback"
          >
            <span v-if="!$v.form.date.required">Please enter an invoice date.</span>
            <span v-if="!$v.form.date.serverFailed">{{ serverErrors.date }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
      >
        <b-form-group
          :label="fieldLabels.location_id"
          label-for="supply-order-location_id"
        >
          <location-select
            id="supply-order-location_id"
            :client-id="form.client_id"
            :select-class="{ 'is-invalid': $v.form.location_id.$dirty && $v.form.location_id.$error }"
            :value="form.location"
            @input="onLocationChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.location_id.$dirty"
            id="supply-order-location_id-feedback"
          >
            <span v-if="!$v.form.location_id.serverFailed">{{ serverErrors.location }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        v-if="newForm"
        cols="12"
      >
        <b-form-group
          :label="fieldLabels.vendor_id"
          label-for="supply-order-vendor_id"
        >
          <vendor-select
            id="supply-order-vendor_id"
            :select-class="{ 'is-invalid': $v.form.vendor_id.$dirty && $v.form.vendor_id.$error }"
            :value="form.vendor"
            @input="onVendorChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.vendor_id.$dirty"
            id="supply-order-vendor_id-feedback"
          >
            <span v-if="!$v.form.vendor_id.required">Please enter a vendor.</span>
            <span v-if="!$v.form.vendor_id.serverFailed">
              {{ serverErrors.vendor }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        v-if="newForm"
        cols="12"
      >
        <b-form-group
          :label="fieldLabels.client_id"
          label-for="supply-order-client_id"
        >
          <client-select
            id="supply-order-client_id"
            :select-class="{ 'is-invalid': $v.form.client_id.$dirty && $v.form.client_id.$error }"
            :value="form.client"
            @input="onClientChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.client_id.$dirty"
            id="supply-order-client_id-feedback"
          >
            <span v-if="!$v.form.client_id.required">Please enter a client.</span>
            <span v-if="!$v.form.client_id.serverFailed">
              {{ serverErrors.client }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.vendor_number"
          label-for="supply-order-vendor_number"
        >
          <b-form-input
            id="supply-order-vendor_number"
            v-model="$v.form.vendor_number.$model"
            :state="$v.form.vendor_number.$dirty ? !$v.form.vendor_number.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.vendor_number.$dirty"
            id="supply-order-vendor_number-feedback"
          >
            <span v-if="!$v.form.vendor_number.maxLength">
              The vendor_number must be less than {{ $v.form.vendor_number.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.vendor_number.serverFailed">{{ serverErrors.vendor_number }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.delivery_notes"
          label-for="supply-order-delivery_notes"
        >
          <b-form-textarea
            id="supply-order-delivery_notes"
            v-model="$v.form.delivery_notes.$model"
            :state="$v.form.delivery_notes.$dirty ? !$v.form.delivery_notes.$error : null"
          />
          <b-form-invalid-feedback
            v-if="$v.form.delivery_notes.$dirty"
            id="supply-order-delivery_notes-feedback"
          >
            <span v-if="!$v.form.delivery_notes.serverFailed">{{ serverErrors.delivery_notes }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group label-for="supply-order-backordered">
          <b-form-checkbox
            id="supply-order-backordered"
            v-model="form.backordered"
          >
            {{ fieldLabels.backordered }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          id="supply-order-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          id="supply-order-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import VendorSelect from '@/components/admin/vendors/Select.vue';
import ClientSelect from '@/components/admin/clients/Select.vue';
import LocationSelect from '@/components/admin/locations/Select.vue';

import { SUPPLY_ORDERS_FORM_LABELS } from '@/constants/supplyOrders.js';
export default {
  name: 'SupplyOrdersForm',
  mixins: [formMixin],
  components: {
    VendorSelect,
    ClientSelect,
    LocationSelect
  },
  props: {
    newForm: {
      type: Boolean,
      default: false,
    },
    vendor_id: [String, Number],
    client_id: [String, Number],
    location_id: [String, Number],
    number: String,
    date: String,
    vendor_number: String,
    vendor: Object,
    client: Object,
    location: Object,
    backordered: Boolean,
    delivery_notes: String,
  },
  data() {
    return {
      form: {
        vendor_id: this.vendor_id,
        client_id: this.client_id,
        number: this.number,
        date: this.date,
        vendor_number: this.vendor_number,
        vendor: this.vendor,
        client: this.client,
        location_id: this.location_id,
        location: this.location,
        backordered: this.backordered,
        delivery_notes: this.delivery_notes,
      },
      fieldLabels: {
        ...SUPPLY_ORDERS_FORM_LABELS,
        number: 'Supply PO *',
        date: 'Date *',
        client_id: 'Client *',
        vendor_id: 'Vendor *',
        location_id: 'Location *',
        backordered: 'This supply order is backordered',
      },
    };
  },
  validations: {
    form: {
      number: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('number');
        },
      },
      vendor_number: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('vendor_number');
        },
      },
      date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('date');
        },
      },
      delivery_notes: {
        serverFailed() {
          return !this.hasServerErrors('delivery_notes');
        },
      },
      vendor_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('vendor');
        },
      },
      client_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('client');
        },
      },
      location_id: {
        serverFailed() {
          return !this.hasServerErrors('location');
        },
      },
    },
  },
  methods: {
    onVendorChange(vendor) {
      this.form.vendor_id = vendor ? vendor.id : '';
      this.form.vendor = vendor;
    },
    onClientChange(client) {
      this.form.client_id = client ? client.id : '';
      this.form.client = client;
    },
    onLocationChange(location) {
      this.form.location_id = location ? location.id : '';
      this.form.location = location;
    },
  },
};
</script>
