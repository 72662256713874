<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$usersAPI.getUsers"
    provider-key="users"
    :columns="translatedColumns(modelName, columns)"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(name)="data">
      <b-link :to="{ name: 'user', params: { id: data.item.id } }">
        {{ data.item.name }}
      </b-link>
    </template>
    <template #cell(email)="data">
      <b-link :href="`mailto:${data.item.email}`">
        {{ data.item.email }}
      </b-link>
    </template>
    <template #cell(manager_id)="data">
      <b-link :to="{ name: 'user', params: { id: data.item.manager_id } }">
        {{ data.item.manager ? data.item.manager.name : "" }}
      </b-link>
    </template>
    <template #cell(category)="data">
      <b-badge variant="secondary">
        {{ data.item.category | humanizedFormat | capitalizeFormat }}
      </b-badge>
    </template>
    <template #cell(services_provided)="data">
      <span v-if="data.item.services_provided.length">
        {{ formattedServicesProvided(data.item.services_provided) }}
      </span>
    </template>
    <template
      #cell(role)="data"
    >
      <div
        v-for="(role, index) in data.item.roles"
        :key="index"
      >
        <b-badge
          variant="link"
          class="mb-1"
          v-b-modal="`user-${data.item.id}-${role.id}-role-info`"
          v-b-tooltip.hover
          title="View permissions"
        >
          {{ role.name | humanizedFormat | capitalizeFormat }}
        </b-badge>
        <role-permissions-modal
          :modal-id="`user-${data.item.id}-${role.id}-role-info`"
          :role="role"
        />
      </div>
    </template>
    <template #cell(active)="data">
      {{ !data.item.deleted_at ? "Yes" : "No" }}
    </template>
  </sfs-base-table>
</template>

<script>
import RolePermissionsModal from '@/components/shared/RolePermissionsModal.vue';
import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';
import translationMixin from '@/mixins/translationMixin';
import { USER_TABLE_DEFAULT_COLUMNS, USER_MODEL_NAME } from '@/constants/users';

export default {
  name: 'UsersTable',
  mixins: [urlMixin, tableMixin, translationMixin],
  components: {
    RolePermissionsModal,
  },
  props: {
    columns: {
      type: Array,
      default: () => USER_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      modelName: USER_MODEL_NAME,
    };
  },
  methods: {
    formattedServicesProvided(services) {
      return services.map((service) => this.$options.filters.capitalizeFormat(this.$options.filters.humanizedFormat(service))).join(', ');
    },
  }
};
</script>