<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$technicianPaymentsAPI.getItems"
    :data-id="technicianPaymentId"
    provider-key="technicianPaymentItems"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
    :append-to-query="false"
  >
    <template #cell(technician_payment_id)="data">
      <b-link :to="{ name: 'technician-payment', params: { id: data.item.technician_payment_id } }">
        {{ data.item.technician_payment_id }}
      </b-link>
    </template>
    <template #cell(work_order_id)="data">
      <b-link
        v-if="data.item.work_order"
        :to="{ name: 'work-order', params: { id: data.item.work_order.id } }"
      >
        {{ data.item.work_order.number }}
      </b-link>
    </template>
    <template #cell(gl_code_id)="data">
      <span v-if="data.item.gl_code">{{ data.item.gl_code.name }}</span>
    </template>
    <template #cell(description)="data">
      <div class="text-prewrap">
        {{ data.item.description }}
      </div>
    </template>
    <template #cell(checked_in_at)="data">
      <span v-if="data.item.work_order && data.item.work_order.checked_in_at">
        {{ data.item.work_order.checked_in_at | dateFormat }}
      </span>
    </template>
    <template #cell(amount)="data">
      <money-format
        :value="data.item.amount.amount"
        :currency-code="data.item.amount.currency"
        subunits-value
      />
    </template>
    <template #cell(actions)="data">
      <b-dropdown
        v-if="$can('update', data.item) || $can('destroy', data.item) || $can('read', 'Version')"
        variant="link"
        size="sm"
        boundary="window"
        no-caret
      >
        <template #button-content>
          <i class="fas fa-ellipsis-h" />
        </template>
        <b-dropdown-item
          v-if="$can('update', data.item)"
          v-b-modal="`technician-payment-item-${data.item.id}-edit-modal`"
          size="sm"
        >
          <i class="fa fa-fw fa-pencil-alt" />
          Edit
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$can('destroy', data.item)"
          size="sm"
          @click="destroyTechnicianPaymentItemDialog(data.item.id)"
        >
          <i class="fa fa-fw fa-times" />
          Delete
        </b-dropdown-item>

        <b-dropdown-divider
          v-if="$can('update', data.item) || $can('destroy', data.item)"
        />

        <b-dropdown-item
          v-if="$can('read', 'Version')"
          :to="{ name: 'versions', query: { model_id: data.item.id, model: 'TechnicianPaymentItem' } }"
        >
          <i class="fa fa-glasses mr-1" />
          Audit Log
        </b-dropdown-item>
      </b-dropdown>

      <edit-modal
        v-if="$can('update', data.item)"
        :key="`technician-payment-items-edit-modal-${data.item.id}`"
        :value="data.item"
        @on-save="refreshTable"
      />
    </template>
  </sfs-base-table>
</template>

<script>
import EditModal from '@/components/admin/technicianPaymentItems/EditModal.vue';
import tablePropsMixin from '@/mixins/tablePropsMixin';

import { TECHNICIAN_PAYMENT_ITEM_TABLE_DEFAULT_COLUMNS } from '@/constants/technicianPaymentItems';

export default {
  name: 'TechnicianPaymentItemsTable',
  components: {
    EditModal,
  },
  mixins: [tablePropsMixin],
  props: {
    technicianPaymentId: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      default: () => TECHNICIAN_PAYMENT_ITEM_TABLE_DEFAULT_COLUMNS,
    },
  },
  methods: {
    destroyTechnicianPaymentItemDialog(id) {
      this.$swal({
        text: 'Once it\'s deleted, its gone forever!',
        preConfirm: () => this.$technicianPaymentsAPI.destroyItem(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
  },
};
</script>
