import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '../helpers/apiPath';

export default {
  getUsers(params) {
    return axios.get(adminPath('/users?') + prepareQueryString(params)).then((response) => {
      return {
        users: response.data.map((user) => an('User', user)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(adminPath(`/users/${id}`)).then((response) => an('User', response.data));
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/users/${id}`), {
        ...data,
      })
      .then((response) => an('User', response.data));
  },
  create(data) {
    return axios
      .post(adminPath('/users'), {
        ...data,
      })
      .then((response) => an('User', response.data));
  },
  getRoles() {
    return axios.get(adminPath('/roles?') + prepareQueryString());
  },
  getCategoryOptions() {
    return axios.get(adminPath('/users/category_options'));
  },
  getAssignableCategoryOptions() {
    return axios.get(adminPath('/users/assignable_category_options'));
  },
  getLocaleOptions() {
    return axios.get(adminPath('/users/locale_options'));
  },
  getServicesProvidedOptions() {
    return axios.get(adminPath('/users/services_provided_options'));
  },
  getTimeZoneOptions() {
    return axios.get(adminPath('/users/time_zone_options'));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/users.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  generateIosCode(id, category = 'technician') {
    return axios.post(adminPath(`/users/${id}/ios_code`), { category }).then((response) => an('User', response.data));
  },
  emailIosCode(id, category = 'technician') {
    return axios.post(adminPath(`/users/${id}/ios_code/email`), { category }).then((response) => an('User', response.data));
  },
  sendWelcomeEmail(id) {
    return axios.post(adminPath(`/users/${id}/welcome_email`)).then((response) => an('User', response.data));
  },
};
