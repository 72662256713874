<template>
  <sfs-form-modal
    :id="modalId"
    :title="`Edit ${value.policy_number}`"
    size="lg"
    @on-hide="resetFormData"
  >
    <insurance-certificate-form
      v-bind="value"
      @on-submit="update"
      @on-save="onUpdate"
      @on-cancel="closeModal"
      :new-form="false"
      :reminder-frequency-options="reminderFrequencyOptions"
      :category-options="categoryOptions"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import InsuranceCertificateForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'InsuranceCertificateEditModal',
  mixins: [formModalMixin],
  components: {
    InsuranceCertificateForm,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      reminderFrequencyOptions: [],
      categoryOptions: [],
      modalId: 'insurance-certificate-edit-modal',
    };
  },
  mounted() {
    this.getReminderFrequencyOptions();
    this.getCategoryOptions();
  },
  methods: {
    getReminderFrequencyOptions() {
      this.$insuranceCertificatesAPI.getReminderFrequencyOptions().then((response) => {
        this.reminderFrequencyOptions = response.data.reminder_frequency_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
      });
    },
    getCategoryOptions() {
      this.$store.cache.dispatch('getInsuranceCertificateCategories').then((options) => {
        this.categoryOptions = options.map((option) => {
          return { label: option[0], value: option[1] };
        });
      });
    },
    update(data, handleUpdate) {
      handleUpdate(this.$insuranceCertificatesAPI.update(this.value.id, data));
    },
    onUpdate(insuranceCertificate) {
      this.closeModal();
      this.$emit('input', insuranceCertificate);
    },
  },
};
</script>
