import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const WORK_ORDER_CHARGES_TABLE_NAME = 'workOrderCharges';

// The columns available to be shown in a recurringWorkOrders table.
export const WORK_ORDER_CHARGES_TABLE_COLUMNS = {
  id: { label: 'ID', key: 'id' },
  description: { label: 'Description', key: 'description' },
  category: { label: 'Category', key: 'category' },
  quantity: { label: 'Quantity', key: 'quantity' },
  cost: { label: 'Cost (Tech Price)', key: 'cost' },
  price: { label: 'Price (DNE)', key: 'price' },
  taxes: { label: 'Tax', key: 'taxes' },
  service_id: { label: 'Service', key: 'service_id' },
  user_id: { label: 'Technician', key: 'user_id' },
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const WORK_ORDER_CHARGES_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(WORK_ORDER_CHARGES_TABLE_COLUMNS);

// The default columns to be shown when the recurringWorkOrders table loads.
export const WORK_ORDER_CHARGES_TABLE_DEFAULT_COLUMNS = [
  WORK_ORDER_CHARGES_TABLE_COLUMNS.description,
  WORK_ORDER_CHARGES_TABLE_COLUMNS.user_id,
  WORK_ORDER_CHARGES_TABLE_COLUMNS.category,
  WORK_ORDER_CHARGES_TABLE_COLUMNS.quantity,
  WORK_ORDER_CHARGES_TABLE_COLUMNS.cost,
  WORK_ORDER_CHARGES_TABLE_COLUMNS.price,
  WORK_ORDER_CHARGES_TABLE_COLUMNS.taxes,
  WORK_ORDER_CHARGES_TABLE_COLUMNS.actions,
];
