<template>
  <div>
    <div
      :id="popoverId"
      style="max-height: 5rem"
      class="overflow-auto font-size-sm text-secondary text-prewrap"
    >
      {{ shortText }}
    </div>
    <b-popover
      v-if="text !== shortText"
      boundary="window"
      :target="popoverId"
      triggers="hover"
      placement="top"
    >
      <div class="text-prewrap">
        {{ text }}
      </div>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: 'TextWithPopover',
  props: {
    text: {
      type: String,
      required: true,
    },
    cutoffLength: {
      type: Number,
      default: 50,
    },
    popoverId: {
      type: String,
      default: `text-popover-${Date.now()}`
    },
  },
  computed: {
    shortText() {
      let short = this.text;
      if (short.length <= this.cutoffLength) {
        return short;
      }

      if (short.length > this.cutoffLength) {
        short = `${short.slice(0, this.cutoffLength)}...`;
      }

      return short;
    },
  },
};
</script>
