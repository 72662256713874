export default {
  data() {
    return {
      processing: false,
      serverErrors: {},
      baseServerErrors: [],
      fieldLabels: {},
    };
  },
  methods: {
    /**
     * Handles form submission by checking for vuelidate form errors,
     * and then emitting the "on-submit" event.
     */
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.processing = true;
      this.$emit('on-submit', this.form, this.handleServerResponse);
    },
    /**
     * Handles the request response. When succesful it emits the "on-success" event,
     * otherwise it will handle the error response.
     * @param {promise} request The request made to the server to process the form.
     */
    handleServerResponse(request) {
      request
        .then((response) => {
          this.$emit('on-save', response);
          this.resetServerErrors();
          this.$v.$reset();
          this.processing = false;
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
          this.processing = false;
        });
    },
    /**
     * Returns whether or not there are form errors.
     */
    hasFormErrors() {
      this.resetServerErrors();
      this.$v.$touch();
      return this.$v.$anyError ? true : false;
    },
    /**
     * Returns whether or not there are errors for the specified field.
     * @param {string} field The field to check for server errors.
     */
    hasServerErrors(field) {
      return this.serverErrors[field] && this.serverErrors[field].length > 0;
    },
    /**
     * Returns whether or not there are any base errors. Base errors
     * are errors that don't belong to a particular field.
     */
    hasBaseServerErrors() {
      return this.baseServerErrors.length > 0;
    },
    /**
     * Processes the errors that are received from the backend API.
     * The error messages are prepended with the field's label.
     * @param {object} errors The object containing errors.
     */
    processServerErrors(errors) {
      if (errors) {
        errors.forEach((error) => {
          const field = error.field;
          if (field == 'base') {
            this.baseServerErrors = error.errors;
          } else {
            this.serverErrors[field] = error.errors
              .map((message) => {
                return `${this.fieldLabels[field]} ${message}`;
              })
              .join(', ')
              .concat('.');
          }
        });
      } else {
        this.baseServerErrors = ['An unexpected error was encountered, please contact the administrator.'];
      }
    },
    /**
     * Sets each form fields errors to be blank.
     */
    resetServerErrors() {
      const errors = {};
      Object.keys(this.formData || this.form).forEach((field) => {
        errors[field] = '';
      });

      this.serverErrors = Object.assign({}, errors);
      this.baseServerErrors = [];
    },
    resetForm() {
      if (this.formData) {
        this.formData = {};
      } else {
        this.form = {};
      }
      this.resetServerErrors();
      this.$v.$reset();
    },
  },
};
