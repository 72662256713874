<template>
  <div>
    <base-page-heading title="Walkthroughs">
      <template #extra>
        <b-button
          v-if="$can('create', 'Walkthrough')"
          variant="primary"
          class="mr-2"
          v-b-modal.walkthrough-add-modal
          v-b-tooltip.hover
          title="Create a New Walkthrough"
        >
          <i class="fa fa-plus mr-1" />
          Start Walkthrough
        </b-button>
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <walkthroughs-list-filter
            :table-name="tableName"
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>

      <walkthroughs-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
    <walkthroughs-new-modal
      v-if="$can('create', 'Walkthrough')"
      :user="$store.getters.user"
    />
  </div>
</template>

<script>
import WalkthroughsTable from '@/components/admin/walkthroughs/Table.vue';
import WalkthroughsListFilter from '@/components/admin/walkthroughs/ListFilter.vue';
import WalkthroughsNewModal from '@/components/admin/walkthroughs/NewModal.vue';
import listMixin from '@/mixins/listMixin';
import { WALKTHROUGH_TABLE_NAME } from '@/constants/walkthroughs';

export default {
  name: 'WalkthroughsList',
  components: {
    WalkthroughsListFilter,
    WalkthroughsTable,
    WalkthroughsNewModal,
  },
  mixins: [listMixin],
  props: {
    text: String,
    location_id: String,
    client_id: String,
    date_from: String,
    date_to: String,
    date_between: String,
    active: String,
  },
  data() {
    return {
      tableName: WALKTHROUGH_TABLE_NAME,
      filters: {
        text: this.text,
        location_id: this.location_id,
        client_id: this.client_id,
        date_from: this.date_from,
        date_to: this.date_to,
        date_between: this.date_between,
        active: this.active
      },
    };
  },
};
</script>
