<template>
  <sfs-base-list-filter
    title="Filter Clients"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
  >
    <template #quick-filters>
      <b-form-group
        label="Filter fields for Data"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Name or Code"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col>
          <b-form-group
            label="Code"
            label-for="popover-filter-code"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-input
              id="popover-filter-code"
              v-model="filters.code"
              type="text"
              placeholder="All"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Name"
            label-for="popover-filter-name"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-input
              id="popover-filter-name"
              v-model="filters.name"
              type="text"
              placeholder="All"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Manager"
            label-for="popover-filter-manager_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <employee-select
              id="popover-filter-manager_id"
              placeholder="All"
              :value="manager"
              @input="onUserChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Invoice Delivery Method"
            label-for="popover-filter-invoice_delivery_method"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <invoice-delivery-method-select
              id="advanced-filter-invoice_delivery_method"
              placeholder="All Methods"
              v-model="filters.invoice_delivery_method"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-4">

      <b-row>
        <b-col>
          <b-form-group
            label="Status"
            label-for="popover-filter-active"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-radio-group
              id="popover-filter-active"
              v-model="filters.active"
              :options="activeOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import { CLIENT_TABLE_COLUMN_OPTIONS, CLIENT_TABLE_DEFAULT_COLUMNS, CLIENT_TABLE_NAME } from '@/constants/clients';
import listFilterMixin from '@/mixins/listFilterMixin';
import EmployeeSelect from '@/components/admin/users/EmployeeSelect.vue';
import InvoiceDeliveryMethodSelect from '@/components/admin/clients/InvoiceDeliveryMethodSelect.vue';

export default {
  name: 'ClientsListFilter',
  components: {
    EmployeeSelect,
    InvoiceDeliveryMethodSelect,
  },
  mixins: [listFilterMixin],
  data() {
    return {
      availableColumns: CLIENT_TABLE_COLUMN_OPTIONS,
      tableName: CLIENT_TABLE_NAME,
    };
  },
  mounted() {
    this.applyFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = CLIENT_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    onUserChange(manager) {
      this.filters.manager_id = manager ? manager.id.toString() : '';
      this.manager = manager;
    },
  },
};
</script>
