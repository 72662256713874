export function authPath(path) {
  return `/v1${path}`;
}

export function adminPath(path) {
  return `/admin/v1${path}`;
}

export function technicianPath(path) {
  return `/technician/v1${path}`;
}
