<template>
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :label="selectLabel"
    :placeholder="placeholder"
    :value="value"
    @input="onChange"
    :reduce="(category) => category.value"
    multiple
  />
</template>
<script>
export default {
  name: 'ServicesProvidedSelect',
  props: {
    value: Array,
    id: {
      type: String,
      default: 'services-provided-select',
    },
    selectLabel: {
      type: String,
      default: 'label',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getServicesProvided();
  },
  methods: {
    getServicesProvided() {
      this.$store.cache.dispatch('getUserServicesProvided').then((servicesProvidedOptions) => {
        this.options = servicesProvidedOptions.map((option) => {
          return { label: option[0], value: option[1] };
        });
      });
    },
    onChange(services) {
      this.$emit('input', services);
    },
  },
};
</script>
