<template>
  <b-skeleton-table
    :class="{ 'table-skeleton': true, 'table-skeleton-lg': lg, 'table-skeleton-md': md }"
    :rows="rows"
    :columns="columns"
    :table-props="{ responsive: true }"
  />
</template>

<script>
export default {
  name: 'TableSkeleton',
  props: {
    rows: {
      type: Number,
      default: 10,
    },
    columns: {
      type: Number,
      required: 3,
    },
    height: {
      type: String,
      default: '5rem',
    },
    md: {
      type: Boolean,
      default: false,
    },
    lg: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.table-skeleton td .b-skeleton-text {
  height: 1.4rem;
}

.table-skeleton td {
  height: 3rem;
}

.table-skeleton-md td .b-skeleton-text {
  height: 2.4rem;
}

.table-skeleton-md td {
  height: 4rem;
}

.table-skeleton-lg td .b-skeleton-text {
  height: 3.4rem;
}

.table-skeleton-lg td {
  height: 5rem;
}
</style>
