<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          v-if="isLoaded"
          ref="users-technicians-forecast-table"
          class="border-bottom"
          :items="records"
          :fields="columns"
          :busy="isLoadingData"
          :sort-by.sync="sorting.sortBy"
          :sort-desc.sync="sorting.sortDesc"
          @sort-changed="handleSortingChanged"
          no-local-sorting
          show-empty
          responsive
          table
        >
          <template #cell(technician_name)="data">
            <b-link :to="{ name: 'user', params: { id: data.item.technician_id } }">
              {{ data.item.technician_name }}
            </b-link>
          </template>
          <template #cell(revenue_cents)="data">
            <div>
              <money-format
                :value="Number(data.item.revenue_cents)"
                subunits-value
              />
            </div>
          </template>
          <template #cell(cost_cents)="data">
            <div>
              <money-format
                :value="Number(data.item.cost_cents)"
                subunits-value
              />
            </div>
          </template>
          <template #cell(total_work_orders)="data">
            <div>
              {{ data.item.total_work_orders | numberWithDelimeter }}
            </div>
          </template>
          <template #cell(total_locations)="data">
            <div class="font-size-sm">
              {{ data.item.total_locations | numberWithDelimeter }}
            </div>
          </template>
        </b-table>
        <sfs-skeleton-table
          v-else
          :rows="pagination.perPage"
          :columns="columns.length"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="auto"
        :class="{ 'mr-auto': true, 'ml-auto': !showPerPage }"
      >
        <b-pagination
          v-if="showPagination"
          v-model="pagination.currentPage"
          :total-rows="pagination.maxResults > totalRows ? totalRows : pagination.maxResults"
          :per-page="pagination.perPage"
          last-class="d-none"
        />
      </b-col>
      <sfs-pagination-dropdown
        v-if="showPerPage"
        container-class="col-auto"
        parts-class="block-options-item"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="totalRows"
        @per-page-changed="onPerPageChanged"
      />
    </b-row>
  </div>
</template>

<script>
import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';
import translationMixin from '@/mixins/translationMixin';
import { TECHNICIAN_FORECAST_REPORT_TABLE_DEFAULT_COLUMNS } from '@/constants/reports';

export default {
  name: 'TechnicianForecastReportTable',
  mixins: [urlMixin, tableMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: () => TECHNICIAN_FORECAST_REPORT_TABLE_DEFAULT_COLUMNS,
    },
  },
  methods: {
    getRecords() {
      this.isLoadingData = true;
      this.$analyticsAPI
        .getTechnicianForecastReport(this.params)
        .then((response) => {
          const { total, records } = response;
          this.totalRows = total;
          this.records = records;
        })
        .catch(() => {
          this.records = [];
        })
        .finally(() => {
          this.loaded = true;
          this.isLoadingData = false;
          this.$emit('data-changed', this.records);
        });
    },
  },
};
</script>
