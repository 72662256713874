<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$vendorsAPI.getVendors"
    provider-key="vendors"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(name)="data">
      <b-link :to="{ name: 'vendor', params: { id: data.item.id } }">
        {{ data.item.name }}
      </b-link>
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';

import { VENDOR_TABLE_DEFAULT_COLUMNS } from '@/constants/vendors';

export default {
  name: 'VendorsTable',
  mixins: [tablePropsMixin],
  props: {
    columns: {
      type: Array,
      default: () => VENDOR_TABLE_DEFAULT_COLUMNS,
    },
  },
};
</script>
