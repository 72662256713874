import List from '@/pages/admin/clients/List.vue';
import Show from '@/pages/admin/clients/Show.vue';
import handleActivePaginationRouteProps from '@/utils/handleActivePaginationRouteProps.js';

export default [
  {
    path: 'clients',
    name: 'clients',
    component: List,
    props: handleActivePaginationRouteProps,
  },
  {
    path: 'clients/:id(\\d+)',
    name: 'client',
    component: Show,
  },
];
