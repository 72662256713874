<template>
  <sfs-form-modal
    :id="modalId"
    :title="`Add a ${$store.getters.translate('client', 'Client')}`"
    size="lg"
    @on-hide="resetFormData"
  >
    <clients-form
      :payment_term_days="0"
      :organization="$store.getters.currentOrganization"
      :organization_id="$store.getters.currentOrganization?.id"
      invoice_delivery_method="email"
      @on-submit="createClient"
      @on-save="(client) => $router.push({ name: 'client', params: { id: client.id } })"
      @on-cancel="closeModal"
      :new-form="true"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import ClientsForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'ClientsAddModal',
  mixins: [formModalMixin],
  components: {
    ClientsForm,
  },
  data() {
    return {
      modalId: 'client-add-modal',
    };
  },
  methods: {
    createClient(data, handleCreate) {
      handleCreate(this.$clientsAPI.create(data).then((response) => {
        if (data.create_location) {
          return this.$locationsAPI.create({ client_ids: [response.id], name: data.name, store_number: data.code, street: data.address, city: data.city, state: data.state, zip_code: data.zip_code }).then(() => response);
        } else {
          return response;
        }
      }));
    },
  },
};
</script>
