// Helpers
const helpers = {
  getWindowWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  },
  getCurrentYear() {
    return new Date().getFullYear();
  },
};

// Vuex Store
export default {
  state: {
    // App vital details
    app: {
      name: 'Scopes Facility Services, LLC',
      version: process.env.PACKAGE_VERSION,
      copyright: helpers.getCurrentYear(),
      upToDate: true,
      upToDatePopupRendered: false,
    },
    // Default layout options
    layout: {
      sidebar: true,
      footer: true,
    },
    // Default template settings
    // Various of them are also set in each layout variation under layouts/variations/ folder
    settings: {
      mainContent: 'boxed', // 'boxed', 'narrow'
    },
  },
  getters: {
    appName: (state) => {
      return state.app.name;
    },
    appVersion: (state) => {
      return state.app.version;
    },
    appCopyright: (state) => {
      return state.app.copyright;
    },
    isUpToDate(state) {
      return state.app.upToDate;
    },
    hasUpToDatePopupRendered(state) {
      return state.app.upToDatePopupRendered;
    },
  },
  mutations: {
    setAppUpToDate(state, payload) {
      state.app.upToDate = payload.upToDate;
    },
    setUpToDatePopupRendered(state, payload) {
      state.app.upToDatePopupRendered = payload.upToDatePopupRendered;
    },
    // Sets the layout, useful for setting different layouts (under layouts/variations/)
    setLayout(state, payload) {
      state.layout.footer = payload.footer;
      state.layout.sidebar = payload.sidebar;
    },
    mainContent(state, payload) {
      if (payload.mode === 'full') {
        state.settings.mainContent = '';
      } else if (payload.mode === 'boxed') {
        state.settings.mainContent = 'boxed';
      } else if (payload.mode === 'narrow') {
        state.settings.mainContent = 'narrow';
      }
    },
  },
};
