import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { technicianPath } from '../helpers/apiPath';

export default {
  getWorkOrdersCount(params) {
    return axios.get(technicianPath('/analytics/work_orders/count?') + prepareQueryString(params)).then((response) => {
      return {
        series: response.data,
      };
    });
  },
  getWorkOrdersSum(params) {
    return axios.get(technicianPath('/analytics/work_orders/sum?') + prepareQueryString(params)).then((response) => {
      return {
        series: response.data,
      };
    });
  },
  getWorkOrdersDashboard(params) {
    return axios.get(technicianPath('/analytics/work_orders/dashboard?') + prepareQueryString(params)).then((response) => {
      return {
        data: response.data,
      };
    });
  },
};
