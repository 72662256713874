<template>
  <base-layout>
    <template #footer>
      <div class="content py-3">
        <b-row>
          <b-col
            sm="12"
            order-sm="1"
            class="text-center"
          >
            <span class="mr-1">v{{ $appVersion }}</span>
            <a
              class="text-primary"
              href="http://scopesfs.com/"
              target="_blank"
            >
              {{ $store.getters.appName }}
              &copy; {{ $store.getters.appCopyright }}
            </a>
          </b-col>
        </b-row>
      </div>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@/components/shared/layouts/Base';
import showAppOutOfDatePopup from '@/utils/showAppOutOfDatePopup.js';

import { SET_OUT_OF_DATE_MUTATION } from '@/constants/mutations.js';

export default {
  name: 'PublicLayout',
  components: {
    BaseLayout,
  },
  created() {
    // Set default elements for this layout
    this.$store.commit('setLayout', {
      footer: true,
      sidebar: false,
    });
    // Set various template options
    this.$store.commit('mainContent', { mode: 'full' });

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_OUT_OF_DATE_MUTATION) {
        if (!state.app.upToDate && !this.$store.getters.hasUpToDatePopupRendered) {
          showAppOutOfDatePopup();
        }
      }
    });
  },
};
</script>
