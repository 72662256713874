import { isNil as _isNil  } from 'lodash';
import { DateTime } from 'luxon';
import { DAY_TIME_GROUPING, WEEK_TIME_GROUPING, MONTH_TIME_GROUPING } from '@/constants';

export default function calculateGroupPeriod(startDateStr, endDateStr) {
  if (_isNil(startDateStr) || endDateStr == '' || _isNil(startDateStr) || endDateStr == '') {
    return WEEK_TIME_GROUPING;
  }
  const daysBetween = DateTime.fromISO(endDateStr).diff(DateTime.fromISO(startDateStr), 'days').days;

  if (daysBetween >= 90) {
    return MONTH_TIME_GROUPING;
  } else if (daysBetween > 31) {
    return WEEK_TIME_GROUPING;
  } else {
    return DAY_TIME_GROUPING;
  }
}
