<template>
  <!-- Page Content -->
  <div class="content">
    <b-row class="justify-content-center text-center">
      <b-col
        md="8"
        lg="6"
        xl="4"
      >
        <div class="p-sm-3 px-lg-4 py-lg-5">
          <div class="push text-center mb-0">
            <b-img
              :src="logo"
              height="320"
              :alt="$store.getters.appName"
            />

            <div class="font-size-sm mb-5">
              v{{ $appVersion }}
            </div>
          </div>
          <div class="py-4">
            <!-- Error Header -->
            <h1 class="display-3 font-w600 text-modern">
              404
            </h1>
            <h2 class="h4 font-w400 text-muted mb-5">
              We are sorry but the page or resource you are looking for was not found
            </h2>
            <!-- END Error Header -->
          </div>
          <div class="text-muted">
            <!-- Error Footer -->
            <p class="mb-1">
              Would you like to let us know about it?
              <a
                href="https://scopesfs.com/contact/"
                class="link-fx"
              >Report it</a>
              or
              <b-link
                :to="{ name: $store.getters.indexPage }"
                class="link-fx"
              >
                Go back to the Dashboard
              </b-link>
            </p>
            <!-- END Error Footer -->
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
  <!-- END Page Content -->
</template>
<script>
import scopesLogo from '@/assets/images/logo.png';
export default {
  name: 'QRCodesUnprocessable',
  data() {
    return {
      logo: scopesLogo,
    };
  },
};
</script>
