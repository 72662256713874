<template>
  <div>
    <base-page-heading title="Users">
      <template #extra>
        <b-button
          v-if="$can('create', 'User')"
          class="mr-2"
          v-b-modal.technician-user-add-modal
          variant="primary"
        >
          <i class="fa fa-plus mr-1" />
          Create User
        </b-button>
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <users-list-filter
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>

      <users-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
    <!-- Page Modals -->
    <users-new-modal v-if="$can('create', 'User')" />
  </div>
</template>

<script>
import UsersNewModal from '@/components/technician/users/NewModal.vue';
import UsersTable from '@/components/technician/users/Table.vue';
import UsersListFilter from '@/components/technician/users/ListFilter.vue';
import listMixin from '@/mixins/listMixin';
import { TECHNICIAN_USER_TABLE_NAME } from '@/constants/users';

export default {
  name: 'TechnicianUsersList',
  components: {
    UsersNewModal,
    UsersTable,
    UsersListFilter,
  },
  mixins: [listMixin],
  props: {
    text: String,
    name: String,
    phone: String,
    email: String,
    address: String,
    city: String,
    state: String,
    zip_code: String,
    active: String,
  },
  data() {
    return {
      tableName: TECHNICIAN_USER_TABLE_NAME,
      filters: {
        text: this.text,
        name: this.name,
        phone: this.phone,
        email: this.email,
        address: this.address,
        city: this.city,
        state: this.state,
        zip_code: this.zip_code,
        active: this.active,
      },
    };
  },
};
</script>
