<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit My Profile"
    size="lg"
    :static="!lazyLoad"
  >
    <b-form @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-if="isTechnician">
        <b-col
          class="text-center"
          cols="12"
        >
          <h3 class="mb-1">
            Warning
          </h3>
          <p>
            To make changes to your profile please contact your manager.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.first_name"
            label-for="profile-first_name"
          >
            <b-skeleton
              v-if="loading"
              type="input"
            />
            <b-form-input
              v-else
              id="profile-first_name"
              :disabled="isTechnician"
              v-model="$v.form.first_name.$model"
              :state="$v.form.first_name.$dirty ? !$v.form.first_name.$error : null"
              type="text"
            />
            <b-form-invalid-feedback
              v-if="$v.form.first_name.$dirty"
              id="profile-first_name-feedback"
            >
              <span v-if="!$v.form.first_name.required">Please enter a first name.</span>
              <span v-if="!$v.form.first_name.maxLength">
                The first name must be less than {{ $v.form.first_name.$params.maxLength.max + 1 }} characters.
              </span>
              <span v-if="!$v.form.first_name.serverFailed">{{ serverErrors.first_name }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.last_name"
            label-for="profile-last_name"
          >
            <b-skeleton
              v-if="loading"
              type="input"
            />
            <b-form-input
              v-else
              id="profile-last_name"
              :disabled="isTechnician"
              v-model="$v.form.last_name.$model"
              :state="$v.form.last_name.$dirty ? !$v.form.last_name.$error : null"
              type="text"
            />
            <b-form-invalid-feedback
              v-if="$v.form.last_name.$dirty"
              id="profile-last_name-feedback"
            >
              <span v-if="!$v.form.last_name.required">Please enter a last name.</span>
              <span v-if="!$v.form.last_name.maxLength">
                The last name must be less than {{ $v.form.last_name.$params.maxLength.max + 1 }} characters.
              </span>
              <span v-if="!$v.form.last_name.serverFailed">{{ serverErrors.last_name }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.email"
            label-for="profile-email"
          >
            <b-skeleton
              v-if="loading"
              type="input"
            />
            <b-form-input
              v-else
              id="profile-email"
              :disabled="isTechnician"
              v-model="$v.form.email.$model"
              autocomplete="new-password"
              :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
              type="text"
              placeholder="example@email.com"
            />
            <b-form-invalid-feedback
              v-if="$v.form.email.$dirty"
              id="profile-email-feedback"
            >
              <span v-if="!$v.form.email.required">Please enter a email.</span>
              <span v-if="!$v.form.email.email">Please enter a valid email.</span>
              <span v-if="!$v.form.email.maxLength">
                The email must be less than {{ $v.form.email.$params.maxLength.max + 1 }} characters.
              </span>
              <span v-if="!$v.form.email.serverFailed">{{ serverErrors.email }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.phone"
            label-for="profile-phone"
          >
            <b-skeleton
              v-if="loading"
              type="input"
            />
            <b-form-input
              v-else
              id="profile-phone"
              :disabled="isTechnician"
              v-model="$v.form.phone.$model"
              :state="$v.form.phone.$dirty ? !$v.form.phone.$error : null"
              type="text"
              placeholder="(201) 555-5555"
            />
            <b-form-invalid-feedback
              v-if="$v.form.phone.$dirty"
              id="profile-phone-feedback"
            >
              <span v-if="!$v.form.phone.required">Please enter a phone.</span>
              <span v-if="!$v.form.phone.maxLength">
                The phone must be less than {{ $v.form.phone.$params.maxLength.max + 1 }} characters.
              </span>
              <span v-if="!$v.form.phone.serverFailed">{{ serverErrors.phone }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.address"
            label-for="profile-address"
          >
            <b-skeleton
              v-if="loading"
              type="input"
            />
            <b-form-input
              v-else
              id="profile-address"
              :disabled="isTechnician"
              v-model="$v.form.address.$model"
              :state="$v.form.address.$dirty ? !$v.form.address.$error : null"
              type="text"
              placeholder="123 Fake Street"
            />
            <b-form-invalid-feedback
              v-if="$v.form.address.$dirty"
              id="profile-address-feedback"
            >
              <span v-if="!$v.form.address.maxLength">
                The address must be less than {{ $v.form.address.$params.maxLength.max + 1 }} characters.
              </span>
              <span v-if="!$v.form.address.serverFailed">{{ serverErrors.address }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.city"
            label-for="profile-city"
          >
            <b-skeleton
              v-if="loading"
              type="input"
            />
            <b-form-input
              v-else
              id="profile-city"
              :disabled="isTechnician"
              v-model="$v.form.city.$model"
              :state="$v.form.city.$dirty ? !$v.form.city.$error : null"
              type="text"
              placeholder="New York"
            />
            <b-form-invalid-feedback
              v-if="$v.form.city.$dirty"
              id="profile-city-feedback"
            >
              <span v-if="!$v.form.city.maxLength">
                The city must be less than {{ $v.form.city.$params.maxLength.max + 1 }} characters.
              </span>
              <span v-if="!$v.form.city.serverFailed">{{ serverErrors.city }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.state"
            label-for="profile-state"
          >
            <b-skeleton
              v-if="loading"
              type="input"
            />
            <b-form-input
              v-else
              id="profile-state"
              :disabled="isTechnician"
              v-model="$v.form.state.$model"
              :state="$v.form.state.$dirty ? !$v.form.state.$error : null"
              type="text"
              placeholder="NY"
            />
            <b-form-invalid-feedback
              v-if="$v.form.state.$dirty"
              id="profile-state-feedback"
            >
              <span v-if="!$v.form.state.maxLength">
                The state must be less than {{ $v.form.state.$params.maxLength.max + 1 }} characters.
              </span>
              <span v-if="!$v.form.state.serverFailed">{{ serverErrors.state }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.zip_code"
            label-for="profile-zip_code"
          >
            <b-skeleton
              v-if="loading"
              type="input"
            />
            <b-form-input
              v-else
              id="profile-zip_code"
              :disabled="isTechnician"
              v-model="$v.form.zip_code.$model"
              :state="$v.form.zip_code.$dirty ? !$v.form.zip_code.$error : null"
              type="text"
              placeholder="55555"
            />
            <b-form-invalid-feedback
              v-if="$v.form.zip_code.$dirty"
              id="profile-zip_code-feedback"
            >
              <span v-if="!$v.form.zip_code.maxLength">
                The zip code must be less than {{ $v.form.zip_code.$params.maxLength.max + 1 }} characters.
              </span>
              <span v-if="!$v.form.zip_code.serverFailed">{{ serverErrors.zip_code }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.locale"
            label-for="profile-locale"
          >
            <b-skeleton
              v-if="loading"
              type="input"
            />
            <locale-select
              v-else
              id="profile-locale"
              v-model="$v.form.locale.$model"
              :state="$v.form.locale.$dirty ? !$v.form.locale.$error : null"
            />
            <b-form-invalid-feedback
              v-if="$v.form.locale.$dirty"
              id="profile-locale-feedback"
            >
              <span v-if="!$v.form.locale.required">Please enter a locale.</span>
              <span v-if="!$v.form.locale.serverFailed">{{ serverErrors.locale }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.time_zone"
            label-for="profile-time_zone"
          >
            <b-skeleton
              v-if="loading"
              type="input"
            />
            <time-zone-select
              v-else
              id="profile-time_zone"
              v-model="$v.form.time_zone.$model"
              :state="$v.form.time_zone.$dirty ? !$v.form.time_zone.$error : null"
            />
            <b-form-invalid-feedback
              v-if="$v.form.time_zone.$dirty"
              id="profile-time_zone-feedback"
            >
              <span v-if="!$v.form.time_zone.required">Please enter a time zone.</span>
              <span v-if="!$v.form.time_zone.serverFailed">{{ serverErrors.time_zone }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="profile-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="profile-submit-button"
            block
            type="submit"
            variant="primary"
            :disabled="loading"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required, maxLength, email } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import { USER_FORM_LABELS, USER_TECHNICIAN_CATEGORY } from '@/constants/users';
import { SET_USER_MUTATION } from '@/constants/mutations.js';
import { USER_EDIT_PROFILE_MODAL_ID } from '@/constants';
import TimeZoneSelect from '@/components/shared/users/TimeZoneSelect.vue';
import LocaleSelect from '@/components/shared/users/LocaleSelect.vue';

export default {
  name: 'EditProfileModal',
  mixins: [formMixin, formModalMixin],
  components: {
    TimeZoneSelect,
    LocaleSelect,
  },
  props: {
    lazyLoad: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      fieldLabels: { ...USER_FORM_LABELS },
      loading: true,
      isTechnician: false,
      form: {},
      modalId: USER_EDIT_PROFILE_MODAL_ID,
    };
  },
  validations: {
    form: {
      first_name: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('first_name');
        },
      },
      last_name: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('last_name');
        },
      },
      email: {
        required,
        email,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('email');
        },
      },
      phone: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('phone');
        },
      },
      address: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('address');
        },
      },
      city: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('city');
        },
      },
      state: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('state');
        },
      },
      zip_code: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('zip_code');
        },
      },
      locale: {
        required,
        serverFailed() {
          return !this.hasServerErrors('locale');
        },
      },
      time_zone: {
        required,
        serverFailed() {
          return !this.hasServerErrors('time_zone');
        },
      },
    },
  },
  mounted() {
    this.$root.$on('bv::modal::show', (_, modalId) => {
      if (modalId == this.modalId) {
        this.loading = true;
        this.loadProfile();
      }
    });
  },
  methods: {
    loadProfile() {
      this.$authAPI.getProfile().then((response) => {
        this.form = response.data;
        this.isTechnician = response.data.category === USER_TECHNICIAN_CATEGORY;
        this.loading = false;
      });
    },
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }

      this.$authAPI
        .updateProfile(this.form)
        .then((response) => {
          this.$store.commit(SET_USER_MUTATION, { user: response.data });
          this.resetServerErrors();
          this.$v.$reset();
          this.closeModal();
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
        });
    },
  },
};
</script>
