<template>
  <sfs-form-modal
    :id="modalId"
    title="Reassign Work Order"
    @on-hide="resetForm"
  >
    <b-form @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row class="text-center">
        <b-col cols="12">
          The selected Work Orders will be reassigned to the User indicated below.
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.done_by_id"
            label-for="work-order-reassign-done_by_id"
          >
            <user-select
              id="work-order-reassign-done_by_id"
              :select-class="{ 'is-invalid': $v.form.done_by_id.$dirty && $v.form.done_by_id.$error }"
              :primary-option="$store.getters.user"
              select-label="name"
              :value="form.done_by"
              @input="onDoneByChange"
            />
            <b-form-invalid-feedback
              v-if="$v.form.done_by_id.$dirty"
              id="work-order-reassign-done_by_id-feedback"
            >
              <span v-if="!$v.form.done_by_id.required">Please enter the technician the work is completed by.</span>
              <span v-if="!$v.form.done_by_id.serverFailed">{{ serverErrors.done_by }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="work-order-reassign-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="work-order-reassign-submit-button"
            block
            type="submit"
            variant="primary"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import { USER_TECHNICIAN_CATEGORY } from '@/constants/users';

import UserSelect from '@/components/technician/users/Select.vue';

export default {
  name: 'TechnicianWorkOrdersReassignMultipleModal',
  components: {
    UserSelect,
  },
  mixins: [formMixin, formModalMixin],
  props: {
    selectedWorkOrders: Array,
  },
  data() {
    return {
      form: {
        done_by: this.value?.done_by,
        done_by_id: this.value?.done_by_id,
      },
      fieldLabels: {
        done_by_id: 'Completed By'
      },
      technicianSelectParams: { category: USER_TECHNICIAN_CATEGORY, active: true },
      modalId: 'technician-work-order-reassign-multiple-modal',
    };
  },
  validations: {
    form: {
      done_by_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('done_by');
        },
      },
    },
  },
  methods: {
    onDoneByChange(user) {
      this.form.done_by_id = user ? user.id : null;
      this.form.done_by = user;
    },
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }

      const failedWorkOrders = [];

      const workOrders = this.selectedWorkOrders.map(workOrder => {
        return this.$technicianWorkOrdersAPI
        .reassign(workOrder.id, this.form)
        .then(() => {
          this.resetServerErrors();
          this.$v.$reset();
        }).catch((error) => {
          failedWorkOrders.push(workOrder);
          this.processServerErrors(error.response.data.errors);
        });
      });

      Promise.all(workOrders)
      .then(() => {
          if (failedWorkOrders.length) {
            this.$emit('handle-error', failedWorkOrders);
          }
          this.$emit('update-table');
        })
        .finally(() => {
          this.form.done_by = null;
          this.closeModal();
        });
    },
  },
};
</script>
