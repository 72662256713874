import urlMixin from '@/mixins/urlMixin';

export default {
  mixins: [urlMixin],
  props: {
    sortBy: {
      type: String,
      default: '',
    },
    sortDesc: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      sorting: {
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
      },
    };
  },
};
