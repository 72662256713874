<template>
  <v-select
    :id="id"
    :options="filteredOptions"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :value="value"
    @search="onSearch"
    :filterable="false"
    @input="onChange"
  >
    <template #option="client">
      <div>{{ client.name }}</div>
      <div class="font-size-sm text-secondary">
        {{ client.code }}
      </div>
    </template>
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        No results found for
        <em>{{ search }}</em>
      </template>
      <em
        style="opacity: 0.7"
        v-else
      >Type to search for clients</em>
    </template>
    <template #selected-option="client">
      {{ client.name }} ({{ client.code }})
    </template>
  </v-select>
</template>
<script>
import { isEqual as _isEqual, isUndefined as _isUndefined } from 'lodash';
import { debounce as _debounce, filter as _filter } from 'lodash';

export default {
  name: 'ClientsSelect',
  props: {
    value: Object,
    id: {
      type: String,
      default: 'client',
    },
    selectLabel: {
      type: String,
      default: 'name',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    filterParams: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: String,
  },
  data() {
    return {
      options: [],
      filteredOptions: [],
    };
  },
  mounted() {
    this.getClientOptions();
  },
  methods: {
    getClientOptions() {
      const params = {
        ...this.filterParams,
        paginate: false,
      };
      this.$technicianClientsAPI.getClients(params).then((response) => {
        this.options = response.clients;
        this.filteredOptions = response.clients;
      });
    },
    onChange(client) {
      this.$emit('input', client);
    },
    onSearch(text, loading) {
      if (text.length) {
        loading(true);
        this.filterClients(text, loading, this);
      }
    },
    filterClients: _debounce((text, loading, vm) => {
      const regex = new RegExp(`${text}`, 'ig');
      vm.filteredOptions = _filter(vm.options, (option) => (option.code.match(regex) || option.name.match(regex)));
      loading(false);
    }, 500),
  },
  watch: {
    filterParams: {
      deep: true,
      handler(newFilterParams, oldFilterParams) {
        if (!_isEqual(oldFilterParams, newFilterParams) && !_isUndefined(newFilterParams)) {
          this.getClientOptions();
        }
      },
    },
  },
};
</script>
