<template>
  <sfs-base-list-filter
    title="Filter Supply Orders"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
  >
    <template #quick-filters>
      <b-form-group
        label="Search fields for Data"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by PO, Vendor Invoice # or Client"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col>
          <b-form-group
            label="Status"
            label-for="advanced-filter-status"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <v-select
              id="quick-filter-status"
              v-model="filters.status"
              :options="statusOptions"
              placeholder="All"
              :reduce="(status) => status.value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Client"
            label-for="advanced-filter-client_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <client-select
              id="advanced-filter-client_id"
              placeholder="All"
              :value="client"
              @input="onClientChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Vendor"
            label-for="advanced-filter-vendor_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <vendor-select
              id="advanced-filter-vendor_id"
              placeholder="All"
              :value="vendor"
              @input="onVendorChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Backordered"
            label-for="advanced-filter-backordered"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-radio-group
              id="advanced-filter-backordered"
              v-model="filters.backordered"
              :options="backorderedOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import listFilterMixin from '@/mixins/listFilterMixin';
import ClientSelect from '@/components/admin/clients/Select.vue';
import VendorSelect from '@/components/admin/vendors/Select.vue';

import { SUPPLY_ORDER_TABLE_COLUMN_OPTIONS, SUPPLY_ORDER_TABLE_DEFAULT_COLUMNS, SUPPLY_ORDER_TABLE_NAME, SUPPLY_ORDERS_FORM_LABELS, SUPPLY_ORDER_TABLE_BACKORDERED_FILTER_OPTIONS } from '@/constants/supplyOrders';

export default {
  name: 'SupplyOrdersListFilter',
  mixins: [listFilterMixin],
  components: {
    ClientSelect,
    VendorSelect,
  },
  data() {
    return {
      availableColumns: SUPPLY_ORDER_TABLE_COLUMN_OPTIONS,
      fieldLabels: SUPPLY_ORDERS_FORM_LABELS,
      tableName: SUPPLY_ORDER_TABLE_NAME,
      backorderedOptions: SUPPLY_ORDER_TABLE_BACKORDERED_FILTER_OPTIONS,
      statusOptions: [],
    };
  },
  mounted() {
    this.getStatusOptions();
    this.loadInitialFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = SUPPLY_ORDER_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    onClientChange(client) {
      this.filters.client_id = client ? client.id.toString() : '';
      this.client = client;
    },
    onVendorChange(vendor) {
      this.filters.vendor_id = vendor ? vendor.id.toString() : '';
      this.vendor = vendor;
    },
    loadInitialFilters() {
      const loadClient = new Promise((resolve) => {
        if (this.initialFilters.client_id) {
          this.$clientsAPI.get(this.initialFilters.client_id).then((client) => {
            resolve(client);
          });
        } else {
          resolve(null);
        }
      });

      const loadVendor = new Promise((resolve) => {
        if (this.initialFilters.vendor_id) {
          this.$vendorsAPI.get(this.initialFilters.vendor_id).then((vendor) => {
            resolve(vendor);
          });
        } else {
          resolve(null);
        }
      });

      // The order of the resolved promises is preserved so we are able to use
      // indexes to fetch the data.
      Promise.all([loadClient, loadVendor]).then((values) => {
        this.client = values[0];
        this.vendor = values[1];
        this.applyFilters();
      });
    },
    getStatusOptions() {
      this.$supplyOrdersAPI.getStatusOptions().then((response) => {
        const options = response.data.status_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
        this.statusOptions = [...options];
      });
    },
  },
};
</script>
