<template>
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :value="value"
    @input="(frequency) => $emit('input', frequency)"
    :reduce="(frequency) => frequency.value"
  />
</template>
<script>
export default {
  name: 'FrequencySelect',
  props: {
    value: String,
    id: {
      type: String,
      default: 'frequency-select',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
