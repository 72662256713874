<template>
  <div v-if="technicianPayment">
    <base-page-heading
      :title="title"
      show-back-button
    >
      <template #secondary-title>
        <b-badge :variant="statusVariant(technicianPayment)">
          {{ status(technicianPayment) }}
        </b-badge>
      </template>
      <template #extra>
        <b-button
          v-if="$can('approve', technicianPayment) && technicianPayment.approved_at === null"
          v-b-tooltip.hover
          variant="primary"
          class="mr-2"
          title="Approve the Technician Payment"
          @click="approveTechnicianPayment"
        >
          <i class="fa fa-check mr-1" />
          Approve
        </b-button>

        <b-button
          v-if="$can('update', technicianPayment)"
          variant="primary"
          v-b-modal.technician-payment-edit-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Edit the Payment"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <b-button
          variant="outline-secondary"
          v-b-modal.technician-payment-email-modal
          v-b-tooltip.hover
          title="Email the Technician Payment"
          class="mr-2"
        >
          <i class="fa fa-envelope" />
        </b-button>

        <sfs-download-button
          v-b-tooltip.hover
          :filename="filename"
          :file-provider="getPDF"
          title="Download the Technician Payment"
          class="mr-2"
        />

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item
            v-if="$can('destroy', 'TechnicianPayment')"
            @click="destroyTechnicianPayment"
          >
            <i
              class="fa fa-trash mr-1"
            />
            Delete Payment
          </b-dropdown-item>

          <b-dropdown-item v-b-modal.communications-list-modal>
            <i class="fa fa-history" />
            Email History
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: technicianPayment.id, model: 'TechnicianPayment' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content pt-5">
      <b-row class="mb-3">
        <b-col lg="5">
          <b-card class="h-100">
            <b-row>
              <b-col
                cols="auto"
                class="mr-auto mb-3"
              >
                Period
              </b-col>

              <b-col class="text-right">
                {{ technicianPayment | dateRangeFormat("start_date", "end_date") }}
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto mb-3"
              >
                Due Date
              </b-col>

              <b-col class="text-right">
                {{ technicianPayment.due_date | dateFormat }}
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto mb-3"
              >
                Amount
              </b-col>
              <b-col class="text-right">
                <money-format
                  :value="technicianPayment.balance.amount"
                  :currency-code="technicianPayment.balance.currency"
                  subunits-value
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Emailed
              </b-col>

              <b-col class="text-right">
                <span v-if="technicianPayment.emailed_at">{{ technicianPayment.emailed_at | dateTimeFormat }}</span>
                <span v-else>Not Emailed</span>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          lg="5"
          offset-lg="2"
        >
          <b-card class="h-100">
            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Technician
              </b-col>

              <b-col class="text-right">
                <b-link :to="{ name: 'user', params: { id: user.id } }">
                  {{ user.name }}
                </b-link>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Address
              </b-col>

              <b-col class="text-right">
                <div>{{ user.address }}</div>
                <div>
                  {{
                    {
                      city: user.city,
                      state: user.state,
                    } | locationFormat
                  }}
                </div>
                <div>{{ user.zip_code }}</div>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Email
              </b-col>

              <b-col class="text-right">
                <b-link :href="`mailto:${user.email}`">
                  {{ user.email }}
                </b-link>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Phone
              </b-col>

              <b-col class="text-right">
                {{ user.phone }}
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <technician-payment-items-list-filter
            :technician-payment="technicianPayment"
            :initial-filters="filters"
            @filters-applied="applyFilters"
            @data-changed="refreshTable"
          />
          <technician-payment-items-table
            :technician-payment-id="$route.params.id.toString()"
            :append-to-query="false"
            :filters="filters"
            :key="tableKey"
          />
        </b-col>
      </b-row>
    </div>
    <!-- Modals -->
    <technician-payments-edit-modal
      v-if="$can('update', 'TechnicianPayment')"
      v-model="technicianPayment"
    />
    <email-modal
      v-model="technicianPayment"
      :email="technicianPayment.user.email"
      cc_manager
    />
    <communications-list-modal
      :list-provider="$communicationsAPI.getTechnicianPaymentCommunications"
      :reference-id="technicianPayment.id"
      title="Email History"
    />
  </div>
</template>

<script>
import TechnicianPaymentItemsTable from '@/components/admin/technicianPaymentItems/Table.vue';
import TechnicianPaymentItemsListFilter from '@/components/admin/technicianPaymentItems/ListFilter.vue';

import EmailModal from '@/components/admin/technicianPayments/EmailModal.vue';
import TechnicianPaymentsEditModal from '@/components/admin/technicianPayments/EditModal.vue';
import CommunicationsListModal from '@/components/admin/communications/ListModal.vue';

import technicianPaymentsMixin from '@/mixins/technicianPaymentsMixin';

export default {
  name: 'TechnicianPaymentsShow',
  mixins: [technicianPaymentsMixin],
  components: {
    TechnicianPaymentItemsTable,
    TechnicianPaymentItemsListFilter,
    TechnicianPaymentsEditModal,
    EmailModal,
    CommunicationsListModal,
  },
  data() {
    return {
      technicianPayment: null,
      tableKey: Date.now(),
      filters: {
        text: this.text,
      },
    };
  },
  mounted() {
    this.getTechnicianPayment();
  },
  computed: {
    title() {
      return `Technician Payment ${this.technicianPayment.number}`;
    },
    user() {
      return this.technicianPayment.user;
    },
    filename() {
      return `Technician Payment ${this.technicianPayment.number}.pdf`;
    },
  },
  methods: {
    getTechnicianPayment() {
      this.$technicianPaymentsAPI
        .get(this.$route.params.id)
        .then((technicianPayment) => (this.technicianPayment = technicianPayment))
        .catch(() => {
          this.$router.push('/404');
        });
    },
    refreshTable() {
      this.tableKey = Date.now();
    },
    getPDF() {
      return this.$technicianPaymentsAPI.getPDF(this.$route.params.id);
    },
    approveTechnicianPayment() {
      this.$swal({
        text: 'Once approved, this payment will be emailed to the technician',
        preConfirm: () => this.$technicianPaymentsAPI.approve(this.technicianPayment.id),
      }).then((result) => {
        if (result.value) {
          this.technicianPayment = result.value;
        }
        result.timer;
      });
    },
    applyFilters({ filters }) {
      this.filters = Object.assign({}, filters);
    },
    destroyTechnicianPayment() {
      this.$swal({
        text: 'Are you sure you want to delete the Technician Payment? This cannot be undone.',
        preConfirm: () => this.$technicianPaymentsAPI.destroy(this.$route.params.id).catch((error) => {
          let message = 'We\'ve experienced an issue while trying to delete the technician payment, Please try again.';
          if (error.response && error.response.status === 403) {
            message = 'This technician payment cannot be deleted - only payments without items can be deleted.';
          }
          this.$bvToast.toast(message, {
              title: 'Oops!',
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false,
            });
          return false;
        }),
      }).then((result) => {
        if (result.value) {
          this.$router.push({ name: 'technician-payments' });
        }
        result.timer;
      });
    },
  },
};
</script>
