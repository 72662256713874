<template>
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :label="selectLabel"
    :placeholder="placeholder"
    :value="value"
    @input="(role) => $emit('input', role)"
    multiple
  />
</template>
<script>
export default {
  name: 'RoleSelect',
  props: {
    value: Array,
    id: {
      type: String,
      default: 'role-select',
    },
    selectLabel: {
      type: String,
      default: 'name',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: {
      type: String,
      default: 'Select at least one permission',
    },
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    getRoles() {
      this.$usersAPI.getRoles().then((response) => {
        this.options = response.data;
      }).catch((error) => {
          throw new Error(error);
        });
    },
  },
};
</script>
