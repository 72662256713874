<template>
  <div>
    <base-page-heading title="Work Order Profitability Report" />
    <div class="content">
      <profitability-list-filter
        class="mb-0"
        :columns="columns"
        :initial-filters="filters"
        @filters-applied="applyFilters"
      />
      <profitability-table
        class="mt-3"
        :filters="filters"
        :columns="columns"
        v-bind="{ ...sorting, ...pagination }"
      />
    </div>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin';
import { DateTime } from 'luxon';
import {
  WORK_ORDER_PROFITABILITY_REPORT_TABLE_NAME,
} from '@/constants/reports';
import ProfitabilityListFilter from '@/components/admin/reports/workOrders/ProfitabilityListFilter.vue';
import ProfitabilityTable from '@/components/admin/reports/workOrders/ProfitabilityTable.vue';

export default {
  name: 'WorkOrdersProfitabilityReport',
  components: {
    ProfitabilityTable,
    ProfitabilityListFilter,
  },
  mixins: [listMixin],
  props: {
    number: String,
    client_id: String,
    location_id: String,
    service_id: String,
    manager_id: String,
    state: String,
    checked_out_from: String,
    checked_out_to: String,
    assigned_to_id: String,
    statuses: Array,
    group_period: String,
  },
  data() {
    return {
      tableName: WORK_ORDER_PROFITABILITY_REPORT_TABLE_NAME,
      filters: {
        number: this.number,
        client_id: this.client_id || this.$store.getters?.client?.id,
        location_id: this.location_id,
        service_id: this.service_id,
        manager_id: this.manager_id,
        state: this.state,
        date_bucket_start_from: this.$options.filters.dateFormat(DateTime.now().minus({ month: 6 }).toISODate()),
        date_bucket_end_to: this.$options.filters.dateFormat(DateTime.now().toISODate()),
        checked_out_from: this.checked_out_from,
        checked_out_to: this.checked_out_to,
        assigned_to_id: this.assigned_to_id,
        statuses: this.statuses,
      },
    };
  }
};
</script>
