<template>
  <sfs-form-modal
    :id="modalId"
    title="Update My Password"
    @on-hide="resetForm"
    :is-static="!lazyLoad"
  >
    <b-form
      @submit.stop.prevent="handleSubmit"
      :key="formKey"
    >
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.current_password"
            label-for="edit-password-current_password"
          >
            <b-input-group>
              <b-form-input
                id="edit-password-current_password"
                autocomplete="new-password"
                :state="$v.form.current_password.$dirty ? !$v.form.current_password.$error : null"
                :type="togglePassword1 ? 'text' : 'password'"
                :value="form.current_password"
                @input="(password) => form.current_password = password"
              />
              <b-input-group-append>
                <b-button
                  class="rounded-right"
                  variant="light"
                  @click="() => (togglePassword1 = !togglePassword1)"
                >
                  <i
                    v-if="togglePassword1"
                    class="fa-regular fa-eye"
                  />
                  <i
                    v-else
                    class="fa-regular fa-eye-slash"
                  />
                </b-button>
              </b-input-group-append>

              <b-form-invalid-feedback
                v-if="$v.form.current_password.$dirty"
                id="edit-password-current_password-feedback"
              >
                <span v-if="!$v.form.current_password.required">Please enter your current password.</span>
                <span v-if="!$v.form.current_password.serverFailed">{{ serverErrors.current_password }}</span>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.password"
            label-for="edit-password-password"
          >
            <b-input-group>
              <b-form-input
                id="edit-password-password"
                autocomplete="new-password"
                :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                :type="togglePassword2 ? 'text' : 'password'"
                :value="form.password"
                @input="(password) => form.password = password"
              />
              <b-input-group-append>
                <b-button
                  class="rounded-right"
                  variant="light"
                  @click="() => (togglePassword2 = !togglePassword2)"
                >
                  <i
                    v-if="togglePassword2"
                    class="fa-regular fa-eye"
                  />
                  <i
                    v-else
                    class="fa-regular fa-eye-slash"
                  />
                </b-button>
              </b-input-group-append>

              <b-form-invalid-feedback
                v-if="$v.form.password.$dirty"
                id="edit-password-password-feedback"
              >
                <span v-if="!$v.form.password.required">Please enter a password.</span>
                <span v-if="!$v.form.password.minLength">
                  The password must be at least {{ $v.form.password.$params.minLength.min }} characters long.
                </span>
                <span v-if="!$v.form.password.serverFailed">{{ serverErrors.password }}</span>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.password_confirmation"
            label-for="edit-password-password_confirmation"
          >
            <b-input-group>
              <b-form-input
                id="edit-password-password_confirmation"
                :state="$v.form.password_confirmation.$dirty ? !$v.form.password_confirmation.$error : null"
                :type="togglePassword3 ? 'text' : 'password'"
                :value="form.password_confirmation"
                @input="(password) => form.password_confirmation = password"
              />
              <b-input-group-append>
                <b-button
                  class="rounded-right"
                  variant="light"
                  @click="() => (togglePassword3 = !togglePassword3)"
                >
                  <i
                    v-if="togglePassword3"
                    class="fa-regular fa-eye"
                  />
                  <i
                    v-else
                    class="fa-regular fa-eye-slash"
                  />
                </b-button>
              </b-input-group-append>

              <b-form-invalid-feedback
                v-if="$v.form.password_confirmation.$dirty"
                id="edit-password-password_confirmation-feedback"
              >
                <span v-if="!$v.form.password_confirmation.sameAs">Must match the password.</span>
                <span v-if="!$v.form.password_confirmation.serverFailed">
                  {{ serverErrors.password_confirmation }}
                </span>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="edit-password-close-button"
            @click="closeModal()"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="edit-password-submit-button"
            block
            type="submit"
            variant="primary"
            :disabled="processing"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import { USER_FORM_LABELS } from '@/constants/users';
import { USER_UPDATE_PASSWORD_MODAL_ID } from '@/constants';

export default {
  name: 'EditPasswordModal',
  mixins: [formMixin, formModalMixin],
  props: {
    lazyLoad: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      form: {
        current_password: '',
        password: '',
        password_confirmation: '',
      },
      passwordVisible: false,
      modalId: USER_UPDATE_PASSWORD_MODAL_ID,
      fieldLabels: { ...USER_FORM_LABELS },
      togglePassword1: false,
      togglePassword2: false,
      togglePassword3: false,
    };
  },
  validations: {
    form: {
      current_password: {
        required,
        serverFailed() {
          return !this.hasServerErrors('current_password');
        },
      },
      password: {
        required,
        minLength: minLength(8),
        serverFailed() {
          return !this.hasServerErrors('password');
        },
      },
      password_confirmation: {
        sameAs: sameAs('password'),
        serverFailed() {
          return !this.hasServerErrors('password_confirmation');
        },
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.processing = true;
      this.$authAPI
        .updatePassword(this.form)
        .then(() => {
          this.resetServerErrors();
          this.$v.$reset();
          this.closeModal();
          this.processing = false;
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
          this.processing = false;
        });
    },
  },
};
</script>
