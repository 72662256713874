import { DateTime } from 'luxon';
/**
 *  Returns a string in a format like 'Oct 13, 1994 - Dec 25, 1994'.
 * @param {string} dateObj An object with a start and end date properties
 *                         in the form of ISO 8601 strings.
 * @param {string} startDateProp the key of the start date for the dateObj.
 * @param {string} endDateProp the key of the end date for the dateObj.
 */
export default function (dateObj, startDateProp, endDateProp) {
  const start = DateTime.fromISO(dateObj[startDateProp]);
  const end = DateTime.fromISO(dateObj[endDateProp]);
  let dateRangeStr = '';
  if (start.month == end.month && start.year == end.year && start.day == end.day) {
    dateRangeStr = start.toLocaleString(DateTime.DATE_MED);
  } else if (start.month == end.month && start.year == end.year) {
    dateRangeStr = `${start.toFormat('MMM d')} - ${end.toFormat('d, y')}`;
  } else if (start.year == end.year) {
    dateRangeStr = `${start.toFormat('MMM d')} - ${end.toLocaleString(DateTime.DATE_MED)}`;
  } else {
    dateRangeStr = `${start.toLocaleString(DateTime.DATE_MED)} - ${end.toLocaleString(DateTime.DATE_MED)}`;
  }
  return dateRangeStr;
}
