<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-row>
      <b-col>
        <b-alert
          :show="hasBaseServerErrors()"
          variant="danger"
          dismissible
        >
          <p
            class="mb-0"
            v-for="(error, index) in baseServerErrors"
            :key="index"
          >
            {{ error }}
          </p>
        </b-alert>
      </b-col>
    </b-row>
    <b-form-group
      :label="fieldLabels.file"
      label-for="import-file"
    >
      <b-form-file
        v-model="$v.form.file.$model"
        name="import-file"
        :state="$v.form.file.$dirty ? !$v.form.file.$error : null"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      />
      <b-form-invalid-feedback
        v-if="$v.form.file.$dirty"
        class="import-file-feedback"
      >
        <span v-if="!$v.form.file.required">Please enter a file.</span>
        <span v-if="!$v.form.file.serverFailed">{{ serverErrors.file }}</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <slot name="downloads" />

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="import-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="import-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';

export default {
  name: 'ImportsForm',
  mixins: [formMixin],
  props: {
    file: File,
  },
  data() {
    return {
      form: {
        file: this.file,
      },
      fieldLabels: {
        file: 'File',
      },
    };
  },
  validations: {
    form: {
      file: {
        required,
        serverFailed() {
          return !this.hasServerErrors('file');
        },
      },
    },
  },
};
</script>
