import List from '@/pages/technician/workOrders/List';
import Show from '@/pages/technician/workOrders/Show';
import handlePaginationRouteProps from '@/utils/handlePaginationRouteProps.js';

export default [
  {
    path: 'work_orders',
    name: 'technician-work-orders',
    component: List,
    props: handlePaginationRouteProps,
  },
  {
    path: 'work_orders/:id(\\d+)',
    name: 'technician-work-order',
    component: Show,
  },
];
