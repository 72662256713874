<template>
  <b-modal
    :id="modalId"
    title="Walkthrough Photos"
    size="md"
    no-close-on-backdrop
    hide-footer
  >
    <template #modal-header-close>
      <i class="fa fa-close" />
    </template>
    <b-carousel
      fade
      class="walkthrough-carousel"
      controls
      indicators
      :interval="0"
      :ref="modalId"
    >
      <b-carousel-slide
        v-for="(image, index) in photos"
        :key="index"
        :img-src="image.url"
      >
        <template #default>
          <b-button
            variant="light"
            @click="removePhoto(image)"
            v-b-tooltip.hover
            title="Remove the Photo"
            class="btn-remove"
          >
            <i class="fa fa-trash-alt" />
          </b-button>
          <b-button
            v-b-tooltip.hover
            class="btn-download"
            title="Download Photo"
            variant="light"
            :href="image.url"
            download
          >
            <i class="fa fa-download" />
          </b-button>

          <b-button
            v-b-tooltip.hover
            class="btn-copy"
            title="Copy to Clipboard"
            variant="light"
            @click="() => copyImage(image.url, image.content_type)"
          >
            <i class="fa fa-copy" />
          </b-button>
        </template>
      </b-carousel-slide>
    </b-carousel>

    <hr class="mt-4">

    <b-row>
      <b-col
        cols="3"
        offset="9"
      >
        <b-button
          variant="primary"
          block
          @click="closeModal"
        >
          Close
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import copyImageMixin from '@/mixins/copyImageMixin';

export default {
  name: 'PhotoCarouselModal',
  mixins: [copyImageMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    photos: {
      type: Array,
    },
    photoIndex: {
      type: Number,
      default: 0,
    },
    modalId: {
      type: String,
      required: true,
    },
  },
  watch: {
    photos() {
      if (!this.hasPhotos) {
        this.closeModal();
      }
    },
    photoIndex(newPhotoIndex) {
      this.$nextTick(() => {
        this.$refs[this.modalId]?.setSlide(newPhotoIndex);
      });
    },
  },
  computed: {
    hasPhotos() {
      return this.photos?.length > 0;
    },
  },
  methods: {
    removePhoto(photo) {
      this.$swal({
        text: 'Once it\'s deleted, it cannot be undone!',
        preConfirm: () => {
          return this.$walkthroughsAPI.destroyItemPhoto(this.id, photo.id);
        },
      }).then((result) => {
        if (result.value) {
          this.$emit('on-destroy', photo);
        }
        result.timer;
      });
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>

<style #scoped>
.walkthrough-carousel .carousel-caption {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.walkthrough-carousel .carousel-caption .btn-remove {
  position: absolute;
  right: 120px;
}
.walkthrough-carousel .carousel-caption .btn-download {
  position: absolute;
  right: 69px;
}

.walkthrough-carousel .carousel-caption .btn-copy {
  position: absolute;
  right: 20px;
}

.carousel-control-prev,
.carousel-control-next {
  height: 50%;
  top: unset;
  bottom: 25%;
}
</style>
