import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { technicianPath } from '../helpers/apiPath';

export default {
  getWorkOrders(params) {
    return axios.get(technicianPath('/work_orders?') + prepareQueryString(params)).then((response) => {
      return {
        workOrders: response.data.map((workOrder) => an('WorkOrder', workOrder)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(technicianPath(`/work_orders/${id}`)).then((response) => an('WorkOrder', response.data));
  },
  getStatusOptions() {
    return axios.get(technicianPath('/work_orders/status_options'));
  },
  reassign(id, data) {
    return axios
      .patch(technicianPath(`/work_orders/${id}/reassign`), {
        ...data,
      })
      .then((response) => an('WorkOrder', response.data));
  },
  destroyBeforePhoto(id, photoId) {
    return axios.delete(technicianPath(`/work_orders/${id}/before_photos/${photoId}`));
  },
  destroyAfterPhoto(id, photoId) {
    return axios.delete(technicianPath(`/work_orders/${id}/after_photos/${photoId}`));
  },
  destroyNoShowPhoto(id, photoId) {
    return axios.delete(technicianPath(`/work_orders/${id}/no_show_photos/${photoId}`));
  },
  getBeforePhotosZip(params) {
    return axios.get(technicianPath(`/work_orders/${params.id}/before_photos.zip`), { responseType: 'blob' });
  },
  getAfterPhotosZip(params) {
    return axios.get(technicianPath(`/work_orders/${params.id}/after_photos.zip`), { responseType: 'blob' });
  },
  getNoShowPhotosZip(params) {
    return axios.get(technicianPath(`/work_orders/${params.id}/no_show_photos.zip`), { responseType: 'blob' });
  },
};
