<template>
  <sfs-form-modal
    :id="modalId"
    title="Add a User"
    size="lg"
    @on-hide="resetFormData"
  >
    <users-form
      :new-form="true"
      :pays_billing_fees="false"
      :qr_code_access="false"
      :parent_work_order_access="false"
      locale="en"
      time_zone="America/New_York"
      :organization="organization"
      :organization_id="organization_id"
      @on-submit="createUser"
      @on-save="redirectToUser"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import UsersForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'UsersAddModal',
  mixins: [formModalMixin],
  components: {
    UsersForm,
  },
  data() {
    return {
      modalId: 'user-add-modal',
    };
  },
  methods: {
    createUser(data, handleCreate) {
      handleCreate(this.$usersAPI.create(data));
    },
    redirectToUser(user) {
      this.$router.push({ name: 'user', params: { id: user.id } });
    },
  },
  computed: {
    organization() {
      return this.$store.getters.currentOrganization;
    },
    organization_id() {
      return this.organization?.id;
    },
  },
};
</script>
