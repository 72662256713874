<template>
  <div class="content">
    <b-row class="justify-content-center">
      <b-col
        md="8"
        lg="6"
        xl="4"
      >
        <div class="p-sm-3 px-lg-4 py-lg-5">
          <div class="push text-center mb-0">
            <b-img
              :src="logo"
              height="320"
              :alt="$store.getters.appName"
            />
            <div class="font-size-sm mb-5">
              v{{ $appVersion }}
            </div>
          </div>

          <login-form
            v-if="login"
            @on-login="redirectToDashboard"
            @on-verification-required="showVerification"
            @on-registration-required="showRegistration"
          />
          <register-form
            v-if="register"
            @on-login="redirectToDashboard"
            @on-reset-login="showLogin"
            :email="email"
            :password="password"
          />
          <verification-form
            v-if="verify"
            @on-login="redirectToDashboard"
            @on-reset-login="showLogin"
            :email="email"
            :password="password"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import scopesLogo from '@/assets/images/logo.png';
import LoginForm from '@/components/auth/LoginForm.vue';
import RegisterForm from '@/components/auth/mfa/RegisterForm.vue';
import VerificationForm from '@/components/auth/mfa/VerificationForm.vue';
export default {
  name: 'Login',
  components: {
    LoginForm,
    RegisterForm,
    VerificationForm,
  },
  data() {
    return {
      logo: scopesLogo,
      email: null,
      password: null,
      login: true,
      register: false,
      verify: false,
    };
  },
  methods: {
    redirectToDashboard() {
      this.$router.push({ name: this.$store.getters.indexPage });
    },
    showLogin() {
      this.setCredentials(null, null);
      this.renderLogin();
    },
    showVerification({ email, password }) {
      this.setCredentials(email, password);
      this.renderVerification();
    },
    showRegistration({ email, password }) {
      this.setCredentials(email, password);
      this.renderRegistration();
    },
    renderLogin() {
      this.login = true;
      this.register = false;
      this.verify = false;
    },
    renderRegistration() {
      this.login = false;
      this.register = true;
      this.verify = false;
    },
    renderVerification() {
      this.login = false;
      this.register = false;
      this.verify = true;
    },
    setCredentials(email, password) {
      this.email = email;
      this.password = password;
    },
  },
};
</script>
