import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const GL_CODE_MODEL_NAME = 'gl_codes';
export const GL_CODE_TABLE_NAME = 'glCodes';
export const GL_CODE_TECHNICIAN_PAYMENT_CATEGORY = 'technician_payment';
export const GL_CODE_INVOICE_CATEGORY = 'invoice';

// The columns available to be shown in a gl_codes table.
export const GL_CODE_TABLE_COLUMNS = {
  name: { label: 'Name', key: 'name', sortable: true },
  code: { label: 'Code', key: 'code', sortable: true },
  netsuite_expense_class_id: { label: 'Expense Class', key: 'netsuite_expense_class_id' },
  grouped: { label: 'Grouped', key: 'grouped' },
  category: { label: 'Category', key: 'category' },
  client_id: { label: 'Client', key: 'client_id', sortable: true },
  active: { label: 'Active', key: 'active' },
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const GL_CODE_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(GL_CODE_TABLE_COLUMNS);

// The default columns to be shown when the gl_codes table loads.
export const GL_CODE_TABLE_DEFAULT_COLUMNS = [
  GL_CODE_TABLE_COLUMNS.name,
  GL_CODE_TABLE_COLUMNS.code,
  GL_CODE_TABLE_COLUMNS.category,
  GL_CODE_TABLE_COLUMNS.netsuite_expense_class_id,
  GL_CODE_TABLE_COLUMNS.grouped,
  GL_CODE_TABLE_COLUMNS.client_id,
  GL_CODE_TABLE_COLUMNS.actions,
];