<template>
  <sfs-form-modal
    :id="modalId"
    title="Add a Charge"
    @on-hide="resetFormData"
    :is-static="false"
    :is-lazy="true"
  >
    <recurring-charge-form
      @on-submit="create"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
      :location_id="locationId"
      :client="client"
      :client_id="client?.id"
      :service_id="serviceId"
      :service="service"
      :frequency-options="frequencyOptions"
    />
  </sfs-form-modal>
</template>

<script>
import RecurringChargeForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'RecurringChargeAddModal',
  mixins: [formModalMixin],
  components: {
    RecurringChargeForm,
  },
  props: {
    locationId: Number,
    location: Object,
    serviceId: Number,
    service: Object,
  },
  data() {
    return {
      frequencyOptions: [],
      modalId: 'recurring-charge-add-modal',
    };
  },
  computed: {
    client() {
      return (this.location?.clients || [])[0];
    }
  },
  mounted() {
    this.getFrequencyOptions();
  },
  methods: {
    getFrequencyOptions() {
      this.$recurringChargesAPI.getFrequencyOptions().then((response) => {
        this.frequencyOptions = response.data.frequency_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
      });
    },
    create(data, handleCreate) {
      handleCreate(this.$recurringChargesAPI.create(this.locationId, data));
    },
    onSave() {
      this.closeModal();
      this.resetFormData();
      this.$emit('on-save');
    },
  },
};
</script>
