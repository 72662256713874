<template>
  <b-card class="mb-3">
    <i :class="iconClass" />
    <h6 class="font-w300 metric-title text-nowrap">
      {{ title }}
    </h6>
    <div v-if="$slots.content">
      <slot name="content" />
    </div>
    <div v-else>
      <h3 class="font-w300 metric-content mb-0">
        {{ content }}
      </h3>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'MetricCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      default: '',
    },
  },
};
</script>
