import paginationMixin from '@/mixins/paginationMixin';
import sortMixin from '@/mixins/sortMixin';

export default {
  mixins: [paginationMixin, sortMixin],
  data() {
    return {
      tableName: '',
      filters: {},
      totalRows: 0,
    };
  },
  methods: {
    applyFilters({ filters }) {
      this.filters = Object.assign({}, filters);
      this.appendObjectToQueryParams(this.filters);
    },
    groupPeriodChanged(newGroupPeriod) {
      this.applyFilters({  filters: { ...this.filters, group_period: newGroupPeriod } });
    },
    onTotalRowsChanged(newTotal) {
      this.totalRows = newTotal;
    },
  },
  computed: {
    columns() {
      return this.$store.getters.columnsForTable(this.tableName);
    },
  },
};
