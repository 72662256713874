var render = function render(){var _vm=this,_c=_vm._self._c;return _c('line-chart',{attrs:{"title":_vm.title,"y-axis-title":"Total","share-tooltip":true,"chart-height":_vm.height,"skeleton-height":_vm.height,"stroke-width":3,"series":[_vm.mobileWorkOrders, _vm.serviceChannelWorkOrders, _vm.QRCodeWorkOrders, _vm.adminWorkOrders],"y-axis-label-formatter":(val) => val?.toFixed(0),"data-function":[_vm.getMobileWorkOrders, _vm.getServiceChannelWorkOrders, _vm.getQRCodeWorkOrders, _vm.getAdminWorkOrders],"data-parameters":{
    filters: {
      clientId: _vm.clientId,
      datePeriod: _vm.datePeriod,
    },
    groupPeriod: _vm.groupPeriod,
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }