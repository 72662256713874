<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit Charge"
    @on-hide="resetFormData"
  >
    <work-order-charge-form
      v-bind="value"
      :work-order="value.work_order"
      :client_id="value.work_order.client_id"
      @on-submit="updateCharge"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import WorkOrderChargeForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'WorkOrderChargeEditModal',
  mixins: [formModalMixin],
  components: {
    WorkOrderChargeForm,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: `work-order-charge-${this.value.id}-edit-modal`,
    };
  },
  methods: {
    updateCharge(data, handleUpdate) {
      handleUpdate(this.$workOrderChargesAPI.update(this.value.id, data));
    },
    onSave(workOrderCharge) {
      this.closeModal();
      this.resetFormData();
      this.$emit('on-save', workOrderCharge);
    },
  },
};
</script>
