export function prepareAuthenticatedEmployeeRoutes(routes = []) {

  return routes.map((route) => {
    return {
      meta: { requiresAuth: true, employeeOnly: true },
      ...route,
    };
  });
}

export function prepareTechnicianRoutes(routes = []) {
  return routes.map((route) => {
    return {
      ...route,
      meta: { requiresAuth: true },
    };
  });
}
