import List from '@/pages/admin/workOrders/List.vue';
import Show from '@/pages/admin/workOrders/Show.vue';
import New from '@/pages/admin/workOrders/New.vue';
import handlePaginationRouteProps from '@/utils/handlePaginationRouteProps.js';

export default [
  {
    path: 'work_orders',
    name: 'work-orders',
    component: List,
    props: handlePaginationRouteProps,
  },
  {
    path: 'work_orders/new',
    name: 'new-work-order',
    component: New,
    props: handlePaginationRouteProps,
  },
  {
    path: 'work_orders/:id(\\d+)',
    name: 'work-order',
    component: Show,
  },
];
