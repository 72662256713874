<template>
  <div>
    <base-page-heading
      title="New Supply Order"
      show-back-button
    />
    <div class="content">
      <b-row class="pt-3">
        <b-col>
          <b-form
            novalidate
            @submit.stop.prevent
          >
            <b-row>
              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group
                  :label="fieldLabels.vendor_id"
                  label-for="supply-order-vendor_id"
                >
                  <vendor-select
                    id="supply-order-vendor_id"
                    :select-class="{ 'is-invalid': $v.form.vendor_id.$dirty && $v.vendor_id.$error }"
                    :value="form.vendor"
                    @input="onVendorChange"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.form.vendor_id.$dirty"
                    id="supply-order-vendor_id-feedback"
                  >
                    <span v-if="!$v.form.vendor_id.required">Please enter a vendor.</span>
                    <span v-if="!$v.form.vendor_id.serverFailed">
                      {{ serverErrors.client }}
                    </span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group
                  :label="fieldLabels.client_id"
                  label-for="supply-order-client_id"
                >
                  <client-select
                    id="supply-order-client_id"
                    :select-class="{ 'is-invalid': $v.form.client_id.$dirty && $v.form.client_id.$error }"
                    :value="form.client"
                    @input="onClientChange"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.form.client_id.$dirty"
                    id="supply-order-client_id-feedback"
                  >
                    <span v-if="!$v.form.client_id.required">Please enter a {{ translateClient }}.</span>
                    <span v-if="!$v.form.client_id.serverFailed">
                      {{ serverErrors.client }}
                    </span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group
                  :label="fieldLabels.location_id"
                  label-for="supply-order-location_id"
                >
                  <location-select
                    id="supply-order-location_id"
                    :client-id="form.client_id"
                    :select-class="{ 'is-invalid': $v.form.location_id.$dirty && $v.form.location_id.$error }"
                    :value="form.location"
                    @input="onLocationChange"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.form.location_id.$dirty"
                    id="supply-order-location_id-feedback"
                  >
                    <span v-if="!$v.form.location_id.required">Please enter a location.</span>
                    <span v-if="!$v.form.location_id.serverFailed">{{ serverErrors.location }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group
                  :label="fieldLabels.date"
                  label-for="supply-order-date"
                >
                  <sfs-date-picker
                    id="supply-order-date"
                    v-model="form.date"
                    :class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
                    :with-class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.form.date.$dirty"
                    id="supply-order-date-feedback"
                  >
                    <span v-if="!$v.form.date.required">Please enter a date.</span>
                    <span v-if="!$v.form.date.serverFailed">{{ serverErrors.date }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group
                  :label="fieldLabels.vendor_number"
                  label-for="supply-order-vendor_number"
                >
                  <b-form-input
                    id="supply-order-vendor_number"
                    :value="form.vendor_number"
                    @change="(num) => form.vendor_number = num"
                    :state="$v.form.vendor_number.$dirty ? !$v.form.vendor_number.$error : null"
                    type="text"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.form.vendor_number.$dirty"
                    id="supply-order-vendor_number-feedback"
                  >
                    <span v-if="!$v.form.vendor_number.maxLength">
                      The vendor_number must be less than {{ $v.form.vendor_number.$params.maxLength.max + 1 }} characters.
                    </span>
                    <span v-if="!$v.form.vendor_number.serverFailed">{{ serverErrors.vendor_number }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                lg="4"
              >
                <b-form-group
                  :label="fieldLabels.number"
                  label-for="supply-order-number"
                >
                  <b-form-input
                    id="supply-order-number"
                    :value="form.number"
                    @change="(num) => form.number = num"
                    :state="$v.form.number.$dirty ? !$v.form.number.$error : null"
                    type="text"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.form.number.$dirty"
                    id="supply-order-number-feedback"
                  >
                    <span v-if="!$v.form.number.maxLength">
                      The number must be less than {{ $v.form.number.$params.maxLength.max + 1 }} characters.
                    </span>
                    <span v-if="!$v.form.number.serverFailed">{{ serverErrors.number }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  :label="fieldLabels.delivery_notes"
                  label-for="supply-order-delivery_notes"
                >
                  <b-form-textarea
                    id="supply-order-delivery_notes"
                    v-model="$v.form.delivery_notes.$model"
                    :state="$v.form.delivery_notes.$dirty ? !$v.form.delivery_notes.$error : null"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.form.delivery_notes.$dirty"
                    id="supply-order-delivery_notes-feedback"
                  >
                    <span v-if="!$v.form.delivery_notes.serverFailed">{{ serverErrors.delivery_notes }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <h3 class="mb-2">
                  Supplies
                </h3>
              </b-col>
            </b-row>
            <b-row
              v-if="noItems"
              class="mb-3"
            >
              <b-col>
                Select a Vendor and Client to see some items
              </b-col>
            </b-row>
            <b-row
              v-else
              class="mb-3"
            >
              <b-col>
                <b-row v-if="internalNotes">
                  <b-col>
                    <b-alert
                      show
                      variant="warning"
                    >
                      <h5 class="mb-0 alert-heading">
                        Note:
                      </h5>
                      <div class="text-prewrap">
                        {{ internalNotes }}
                      </div>
                    </b-alert>
                  </b-col>
                </b-row>
                <b-row class="mb-0">
                  <b-col
                    cols="12"
                    lg="5"
                  >
                    <b-form-group
                      label="Search for Supplies"
                      label-for="quick-filter-text"
                      label-sr-only
                    >
                      <b-input-group>
                        <b-form-input
                          id="quick-filter-text"
                          :value="textSearch"
                          @input="onSearch"
                          @keydown.native.enter="filterSupplies"
                          type="text"
                          placeholder="Filter by item number or description"
                        />
                        <template #append>
                          <b-button
                            id="quick-filter-apply-button"
                            variant="primary"
                            @click="filterSupplies"
                          >
                            <i class="fa fa-search" />
                          </b-button>
                        </template>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row
                  v-for="(category, index) in categories"
                  :key="index"
                  class="mb-0"
                >
                  <b-col v-if="supplies[category].items.filter((i) => i.visible).length > 0">
                    <b-row class="mb-0">
                      <b-col>
                        <h4 class="mb-2">
                          {{ category | humanizedFormat | capitalizeFormat }}
                        </h4>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1 font-w600">
                      <b-col
                        cols="12"
                        md="2"
                      >
                        Item Number
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                        lg="3"
                        xl="4"
                      >
                        Description
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                        lg="1"
                        class="text-right"
                      >
                        Units
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                        xl="5"
                      >
                        <b-row>
                          <b-col
                            cols="12"
                            md="4"
                            lg="3"
                            offset-lg="1"
                            class="text-center"
                          >
                            Quantity
                          </b-col>
                          <b-col
                            cols="12"
                            md="4"
                            class="text-center"
                          >
                            Unit Cost
                          </b-col>
                          <b-col
                            cols="12"
                            md="4"
                            class="text-center"
                          >
                            Unit Price
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <div
                      v-for="(v, index2) in supplies[category].items"
                      :key="index2"
                    >
                      <b-row v-if="v.visible">
                        <b-col
                          cols="12"
                          md="2"
                        >
                          {{ v.item_number }}
                          <i
                            v-if="v.last_ordered_at"
                            :id="`${v.supply_id}-last-ordered-at`"
                            class="fa fa-clock-rotate-left ml-1 text-primary"
                          />
                          <b-popover
                            v-if="v.last_ordered_at"
                            :target="`${v.supply_id}-last-ordered-at`"
                            triggers="hover"
                            placement="top"
                          >
                            Last ordered on {{ v.last_ordered_at | dateFormat }}
                          </b-popover>
                        </b-col>
                        <b-col
                          cols="12"
                          md="2"
                          lg="3"
                          xl="4"
                        >
                          {{ v.description }}
                        </b-col>
                        <b-col
                          cols="12"
                          md="2"
                          lg="1"
                          class="text-right"
                        >
                          {{ v.units }}
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          xl="5"
                        >
                          <b-row>
                            <b-col
                              cols="12"
                              md="4"
                              lg="3"
                              offset-lg="1"
                            >
                              <b-form-group
                                label-for="supply-order-quantity"
                                class="mb-2"
                              >
                                <b-form-input
                                  id="supply-order-number"
                                  @input="(quantity) => supplies[category].items[index2].quantity = quantity"
                                  :value="v.quantity"
                                  type="text"
                                />
                              </b-form-group>
                            </b-col>

                            <b-col
                              cols="12"
                              md="4"
                            >
                              <b-form-group
                                :label="fieldLabels.cost"
                                label-for="supply-order-cost"
                                class="mb-2"
                              >
                                <b-input-group
                                  prepend="$"
                                >
                                  <b-form-input
                                    name="supply-order-cost"
                                    @input="(cost) => supplies[category].items[index2].cost = cost"
                                    :value="v.cost"
                                    type="number"
                                  />
                                </b-input-group>
                              </b-form-group>
                            </b-col>

                            <b-col
                              cols="12"
                              md="4"
                            >
                              <b-form-group
                                :label="fieldLabels.price"
                                label-for="supply-order-price"
                                class="mb-2"
                              >
                                <b-input-group
                                  prepend="$"
                                >
                                  <b-form-input
                                    name="supply-order-price"
                                    @input="(price) => supplies[category].items[index2].price = price"
                                    :value="v.price"
                                    type="number"
                                  />
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-navbar
              sticky
              fixed="bottom"
              class="save-nav px-0"
            >
              <b-row
                class="mb-3 w-100"
                align-h="end"
                no-gutters
              >
                <b-col
                  offset="8"
                  cols="2"
                >
                  <b-button
                    variant="link-dark"
                    block
                    id="supply-order-close-button"
                    @click="() => $router.push({ name: 'supply-orders' })"
                  >
                    Cancel
                  </b-button>
                </b-col>
                <b-col cols="2">
                  <b-button
                    id="supply-order-submit-button"
                    block
                    type="submit"
                    variant="primary"
                    :disabled="processing"
                    @click="handleSubmit"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </b-navbar>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import { required, maxLength } from 'vuelidate/lib/validators';
import VendorSelect from '@/components/admin/vendors/Select.vue';
import ClientSelect from '@/components/admin/clients/Select.vue';
import LocationSelect from '@/components/admin/locations/Select.vue';
import { SUPPLY_ORDERS_FORM_LABELS } from '@/constants/supplyOrders.js';
import { isNil as _isNil, uniqBy as _uniqBy, debounce as _debounce } from 'lodash';

export default {
  name: 'SupplyOrderNew',
  components: {
    VendorSelect,
    ClientSelect,
    LocationSelect,
  },
  mixins: [formMixin],
  data() {
    return {
      form: {
        number: null,
        vendor_number: null,
        date: new Date(),
        delivery_notes: null,
        vendor_id: null,
        vendor: null,
        client_id: null,
        client: null,
        location_id: null,
        location: null,
      },
      categories: [],
      supplies: {},
      textSearch: '',
      totalSupplies: 0,
      fieldLabels: {
        ...SUPPLY_ORDERS_FORM_LABELS,
        date: 'Date *',
        vendor_id: 'Vendor *',
        client_id: `${this.$store.getters.translate('client', 'Client')} *`,
        location_id: 'Location *',
      },
    };
  },
  validations: {
    form: {
      number: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('number');
        },
      },
      vendor_number: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('vendor_number');
        },
      },
      date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('date');
        },
      },
      delivery_notes: {
        serverFailed() {
          return !this.hasServerErrors('delivery_notes');
        },
      },
      vendor_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('vendor');
        },
      },
      client_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('client');
        },
      },
      location_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('location');
        },
      },
    },
  },
  watch: {
    'form.vendor_id': function () {
      this.getSupplies();
    },
    'form.client_id': function () {
      this.getSupplies();
    },
    'form.location_id': function () {
      this.getSupplies();
    },
  },
  computed: {
    noItems() {
      return this.totalSupplies === 0;
    },
    internalNotes() {
      return this.form.location?.supply_order_internal_notes;
    },
    itemsToCreate() {
      const items = [];
      this.categories.forEach((category) => {
        this.supplies[category].items.forEach((item) => {
          if (!(item.quantity == 0 || _isNil(item.quantity))) {
            items.push({ quantity: item.quantity, supply_id: item.supply_id, cost_cents: item.cost * 100, price_cents: item.price * 100 });
          }
        });
      });

      return items;
    },
    translateClient() {
      return this.$store.getters.translate('client', 'Client');
    },
  },
  mounted() {
    this.resetItems();
  },
  methods: {
    resetItems() {
      this.$store.cache.dispatch('getSupplyCategories').then((categoryOptions) => {
        this.categories = [...categoryOptions.map((option) => {
          this.supplies[option[1]] = { label: option[0], items: [] };
          return option[1];
        })];
      });
    },
    getSupplies() {
      if(this.form.vendor_id) {
        this.$suppliesAPI.getSupplies({ vendor_id: this.form.vendor_id, client_unassigned: true, paginate: false, sort_desc: false, last_ordered_at_for_location_id: this.form.location_id }).then(({ supplies }) => {
          if (this.form.client_id) {
            return this.$suppliesAPI.getSupplies({ client_id: this.form.client_id, vendor_id: this.form.vendor_id, sort_desc: false, paginate: false, last_ordered_at_for_location_id: this.form.location_id }).then((response2) => _uniqBy(response2.supplies.concat(supplies), 'id'));
          } else {
            return supplies;
          }
        }).then((supplies) => {
          const itemsByCategory = {};
          this.totalSupplies = supplies.length;
          supplies.forEach((supply) => {
            let quantity = null;
            let cost = null;
            let price = null;
            this.supplies[supply.category].items.forEach((item) => {
              if (item.supply_id == supply.id) {
                quantity = item.quantity;
                cost = item.cost;
                price = item.price;
              }
            });

            itemsByCategory[supply.category] = itemsByCategory[supply.category] || [];
            itemsByCategory[supply.category].push({ visible: this.itemVisible(supply), item_number: supply.item_number, description: supply.description, units: supply.units, last_ordered_at: supply.last_ordered_at, supply_id: supply.id, quantity: quantity, cost: cost || supply.cost.amount / 100, price: price || supply.price.amount / 100 });
          });
          this.resetItems();
          return itemsByCategory;
        }).then((itemsByCategory) => {
          Object.keys(itemsByCategory).forEach((category) => this.supplies[category].items = itemsByCategory[category]);
        });
      } else {
        this.resetItems();
      }
    },
    filterSupplies() {
      this.categories.forEach((category) => {
        this.supplies[category].items = this.supplies[category].items.map((item) => {
          return { ...item, visible: this.itemVisible(item) };
        });
      });
      this.categories = [ ...this.categories ];
    },
    itemVisible(item) {
      const regex = new RegExp(`${this.textSearch}`, 'ig');
      return item.description.match(regex) || item.item_number.match(regex);
    },
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.processing = true;
      this.$supplyOrdersAPI.create({ ...this.form, items_attributes: this.itemsToCreate }).then((data) => {
        this.resetServerErrors();
        this.$v.$reset();
        this.processing = false;
        this.$router.push({ name: 'supply-order', params: { id: data.id } });
      }).catch((error) => {
          this.processServerErrors(error.response.data.errors);
          this.processing = false;
      });
    },
    onVendorChange(vendor) {
      this.form.vendor_id = vendor ? vendor.id : null;
      this.form.vendor = vendor;
    },
    onSearch(searchKey) {
      this.delaySearch(searchKey, this);
    },
    delaySearch: _debounce((search, vm) => {
      vm.textSearch = search;
      vm.filterSupplies();
    }, 500),
    onClientChange(client) {
      this.form.client_id = client ? client.id : null;
      this.form.client = client;
    },
    onLocationChange(location) {
      this.form.location_id = location ? location.id : null;
      this.form.location = location;
      this.form.delivery_notes = location?.supply_order_delivery_notes;
    },
  },
};
</script>

<style scoped>
  .save-nav {
    background-color: white;
    padding-top: 2.5rem;
    padding-bottom: 1.25rem;
    z-index: 1;
  }
</style>