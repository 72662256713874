<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit Invoice"
    @on-hide="resetFormData"
  >
    <invoice-form
      v-bind="value"
      @on-submit="updateInvoice"
      @on-save="onSave"
      @on-cancel="closeModal"
      :new-form="false"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import InvoiceForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'InvoiceEditModal',
  mixins: [formModalMixin],
  components: {
    InvoiceForm,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: 'invoice-edit-modal',
    };
  },
  methods: {
    updateInvoice(data, handleUpdate) {
      handleUpdate(this.$invoicesAPI.update(this.value.id, data));
    },
    onSave(invoice) {
      this.closeModal();
      this.resetFormData();
      this.$emit('input', invoice);
    },
  },
};
</script>
