/**
 * Returns a humanized version of the provided input String.
 * The input is humanized by removing '-' and '_' characters.
 * The input is stripped of extra spaces between words. Extra spaces are also trimmed.
 * @param {word} String The input to humanize.
 */
export default function (input) {
  return (input || '')
    .toLowerCase()
    .replace(/[_-]+/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim();
}
