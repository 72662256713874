<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit GL Code"
    @on-hide="resetFormData"
    class="text-left"
  >
    <gl-code-form
      v-bind="value"
      @on-submit="updateGlCode"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import GlCodeForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'GlCodesEditModal',
  mixins: [formModalMixin],
  components: {
    GlCodeForm,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: `gl-code-${this.value.id}-edit-modal`,
    };
  },
  methods: {
    updateGlCode(data, handleUpdate) {
      handleUpdate(this.$glCodesAPI.update(this.value.id, data));
    },
    onSave(glCode) {
      this.closeModal();
      this.$emit('input', glCode);
    },
  },
};
</script>
