<template>
  <b-form
    @submit.stop.prevent="handleSubmit"
    class="text-left"
  >
    <b-row>
      <b-col cols="12">
        <b-form-group
          v-if="newForm"
          :label="fieldLabels.client_id"
          label-for="recurring-charge-client_id"
        >
          <client-select
            name="recurring-charge-client_id"
            :select-class="{ 'is-invalid': $v.form.client_id.$dirty && $v.form.client_id.$error }"
            :location-id="location_id"
            :value="form.client"
            @input="onClientChange"
          />
          <b-form-invalid-feedback
            v-if="$v.form.client_id.$dirty"
            class="recurring-charge-client_id-feedback"
          >
            <span v-if="!$v.form.client_id.required">Please enter a client.</span>
            <span v-if="!$v.form.client_id.serverFailed">
              {{ serverErrors.client_id }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          v-if="newForm"
          :label="fieldLabels.service_id"
          label-for="recurring-charge-service_id"
        >
          <service-select
            :select-class="{ 'is-invalid': $v.form.service_id.$dirty && $v.form.service_id.$error }"
            name="recurring-charge-service_id"
            :client-id="form.client_id"
            client-unassigned
            :value="form.service"
            @input="onServiceChange"
            :key="form.client_id"
          />
          <b-form-invalid-feedback
            v-if="$v.form.service_id.$dirty"
            class="recurring-charge-service_id-feedback"
          >
            <span v-if="!$v.form.service_id.serverFailed">{{ serverErrors.service_id }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        :lg="newForm ? 5 : 12"
      >
        <b-form-group
          :label="fieldLabels.price"
          label-for="recurring-charge-price"
        >
          <b-input-group prepend="$">
            <b-form-input
              name="recurring-charge-price"
              v-model="$v.form.price.$model"
              :state="$v.form.price.$dirty ? !$v.form.price.$error : null"
              type="text"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.price.$dirty"
            :state="$v.form.price.$dirty ? !$v.form.price.$error : null"
            class="recurring-charge-price-feedback"
          >
            <span v-if="!$v.form.price.required">Please enter a price.</span>
            <span v-if="!$v.form.price.decimal">Please enter a number.</span>
            <span v-if="!$v.form.price.serverFailed">
              {{ serverErrors.price_cents }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="7"
      >
        <b-form-group
          v-if="newForm"
          :label="fieldLabels.frequency"
          label-for="recurring-charge-frequency"
        >
          <frequency-select
            name="recurring-charge-frequency"
            v-model="$v.form.frequency.$model"
            :options="frequencyOptions"
          />
          <b-form-invalid-feedback
            v-if="$v.form.frequency.$dirty"
            class="recurring-charge-frequency-feedback"
          >
            <span v-if="!$v.form.frequency.required">Please enter a frequency.</span>
            <span v-if="!$v.form.frequency.serverFailed">{{ serverErrors.frequency }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.category"
          label-for="recurring-charge-category"
        >
          <category-select
            name="recurring-charge-category"
            v-model="form.category"
            :select-class="{ 'is-invalid': $v.form.category.$dirty && $v.form.category.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.category.$dirty"
            class="recurring-charge-category-feedback"
          >
            <span v-if="!$v.form.category.required">Please enter a category.</span>
            <span v-if="!$v.form.category.maxLength">
              The category must be less than {{ $v.form.category.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.category.serverFailed">{{ serverErrors.category }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.description"
          label-for="recurring-charge-description"
        >
          <b-form-input
            name="recurring-charge-description"
            v-model="$v.form.description.$model"
            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.description.$dirty"
            class="recurring-charge-description-feedback"
          >
            <span v-if="!$v.form.description.required">Please enter a description.</span>
            <span v-if="!$v.form.description.maxLength">
              The description must be less than {{ $v.form.description.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.description.serverFailed">{{ serverErrors.description }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          v-if="newForm"
          :label="fieldLabels.start_date"
          label-for="recurring-charge-start_date"
        >
          <sfs-date-picker
            id="recurring-charge-start_date"
            v-model="$v.form.start_date.$model"
            :class="{ 'is-invalid': $v.form.start_date.$dirty && $v.form.start_date.$error }"
            :with-class="{ 'is-invalid': $v.form.start_date.$dirty && $v.form.start_date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.start_date.$dirty"
            :state="$v.form.start_date.$dirty ? !$v.form.start_date.$error : null"
            class="recurring-charge-start_date-feedback"
          >
            <span v-if="!$v.form.start_date.required">Please enter a start date.</span>
            <span v-if="!$v.form.start_date.serverFailed">
              {{ serverErrors.start_date }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        :lg="newForm ? 6 : 12"
      >
        <b-form-group
          :label="fieldLabels.end_date"
          label-for="recurring-charge-end_date"
        >
          <sfs-date-picker
            id="recurring-charge-end_date"
            v-model="$v.form.end_date.$model"
            :class="{ 'is-invalid': $v.form.end_date.$dirty && $v.form.end_date.$error }"
            :with-class="{ 'is-invalid': $v.form.end_date.$dirty && $v.form.end_date.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.end_date.$dirty"
            :state="$v.form.end_date.$dirty ? !$v.form.end_date.$error : null"
            class="recurring-charge-end_date-feedback"
          >
            <span v-if="!$v.form.end_date.serverFailed">
              {{ serverErrors.end_date }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="recurring-charge-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="recurring-charge-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength, decimal, requiredIf } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import ClientSelect from '@/components/admin/clients/Select.vue';
import ServiceSelect from '@/components/admin/services/Select.vue';
import FrequencySelect from '@/components/shared/FrequencySelect.vue';
import CategorySelect from '@/components/admin/workOrderCharges/CategorySelect.vue';

export default {
  name: 'RecurringChargesForm',
  components: {
    ClientSelect,
    FrequencySelect,
    ServiceSelect,
    CategorySelect,
  },
  mixins: [formMixin],
  props: {
    description: String,
    price: Object,
    frequency: String,
    category: String,
    client_id: Number,
    location_id: Number,
    service_id: Number,
    client: Object,
    service: Object,
    start_date: String,
    end_date: String,
    newForm: {
      type: Boolean,
      default: true,
    },
    frequencyOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        description: this.description,
        price: this.price && this.price.amount ? this.price.amount / 100 : null,
        price_cents: this.price && this.price.amount ? this.price.amount : null,
        frequency: this.frequency,
        category: this.category,
        client_id: this.client_id,
        service_id: this.service_id,
        client: this.client,
        service: this.service,
        start_date: this.start_date,
        end_date: this.end_date,
      },
      fieldLabels: {
        description: 'Description *',
        price: 'Price *',
        frequency: 'Frequency *',
        category: 'Category *',
        client_id: `${this.$store.getters.translate('client', 'Client')} *`,
        service_id: 'Service',
        start_date: 'Start Date *',
        end_date: 'End Date',
      },
    };
  },
  validations: {
    form: {
      description: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('description');
        },
      },
      price: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('price_cents');
        },
      },
      frequency: {
        required: requiredIf(function () {
          return this.newForm;
        }),
        serverFailed() {
          return !this.hasServerErrors('frequency');
        },
      },
      category: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('category');
        },
      },
      client_id: {
        required: requiredIf(function () {
          return this.newForm;
        }),
        serverFailed() {
          return !this.hasServerErrors('client_id');
        },
      },
      service_id: {
        serverFailed() {
          return !this.hasServerErrors('service_id');
        },
      },
      start_date: {
        required: requiredIf(function () {
          return this.newForm;
        }),
        serverFailed() {
          return !this.hasServerErrors('start_date');
        },
      },
      end_date: {
        serverFailed() {
          return !this.hasServerErrors('end_date');
        },
      },
    },
  },
  watch: {
    'form.price': function (newAmount) {
      this.form.price_cents = isNaN(newAmount) ? null : Math.round(newAmount * 100);
    },
  },
  methods: {
    onClientChange(client) {
      this.form.client_id = client ? client.id : null;
      this.form.client = client;
    },
    onServiceChange(service) {
      this.form.service_id = service ? service.id : '';
      this.form.service = service;
    },
  },
};
</script>
