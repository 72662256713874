<template>
  <div v-if="service">
    <base-page-heading
      :title="service.name"
      show-back-button
    >
      <template #secondary-title>
        <sfs-active-badge :active="!service.deleted_at" />
      </template>
      <template #extra>
        <b-button
          v-if="$can('update', 'Service')"
          variant="primary"
          v-b-modal.service-edit-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Edit the Service"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <net-suite-form-popover
          v-if="$can('read', 'IntegrationReference')"
          :reference-id="service.id"
          reference-type="Service"
          :current-integration-reference-id="service.netsuite_reference_id"
        />

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item
            v-if="$can('destroy', 'Service')"
            @click="toggleActive"
          >
            <i
              v-if="!service.deleted_at"
              class="fa fa-trash mr-1"
            />
            <i
              v-else
              class="fa fa-undo mr-1"
            />
            {{ toggleActiveTooltipText }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: service.id, model: 'Service' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content pt-5">
      <b-row>
        <b-col lg="6">
          <b-card class="mb-3">
            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Category
              </b-col>

              <b-col class="text-right">
                <b-badge variant="secondary">
                  {{ service.category | humanizedFormat | titlecaseFormat }}
                </b-badge>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Description
              </b-col>

              <b-col class="text-right">
                {{ service.description }}
              </b-col>
            </b-row>

            <b-row
              v-if="service.client"
              class="mb-3"
            >
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Client
              </b-col>
              <b-col class="text-right">
                <b-link :to="{ name: 'client', params: { id: service.client_id } }">
                  {{ service.client.name }}
                </b-link>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Minimum Days Between Services
              </b-col>
              <b-col class="text-right">
                {{ minimumDaysBetweenService }}
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Estimated Hours to Complete
              </b-col>
              <b-col class="text-right">
                {{ estimatedHours }}
              </b-col>
            </b-row>
          </b-card>

          <b-row class="mb-3">
            <b-col md="6">
              <sfs-dashboard-card
                title="Work Orders"
                icon-class="far fa-calendar-alt fa-2x"
                :content="totalWorkOrders.toString()"
                :href="{ name: 'work-orders', query: { service_id: service.id } }"
                href-content="View Work Orders"
              />
            </b-col>

            <b-col md="6">
              <sfs-dashboard-card
                title="Technician Services"
                icon-class="far fa-user fa-2x"
                :content="totalTechnicianServices.toString()"
                :href="{ name: 'locations', query: { service_id: service.id } }"
                href-content="View Locations"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col lg="6">
          <b-card>
            <h4 class="mb-3">
              Billing
            </h4>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Add on
              </b-col>
              <b-col class="text-right">
                <b-badge
                  v-if="service.add_on"
                  variant="warning"
                >
                  Yes
                </b-badge>
                <b-badge
                  v-else
                  variant="secondary"
                >
                  No
                </b-badge>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Charges Require a Technician
              </b-col>
              <b-col class="text-right">
                <b-badge
                  v-if="service.work_order_charges_require_user"
                  variant="success"
                >
                  Yes
                </b-badge>
                <b-badge
                  v-else
                  variant="secondary"
                >
                  No
                </b-badge>
              </b-col>
            </b-row>

            <h4 class="mb-3">
              Accounting
            </h4>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Tax is categorized through the Work Order instead of the Charge?
              </b-col>
              <b-col class="text-right">
                <b-badge
                  v-if="service.tax_categorized_via_work_order"
                  variant="warning"
                >
                  Yes
                </b-badge>
                <b-badge
                  v-else
                  variant="success"
                >
                  No
                </b-badge>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Technician Payment GL Code
              </b-col>
              <b-col class="text-right">
                <b-badge variant="secondary">
                  {{ technicianPaymentGLCodeName }}
                </b-badge>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Invoice GL Code
              </b-col>
              <b-col class="text-right">
                <b-badge variant="secondary">
                  {{ invoiceGLCodeName }}
                </b-badge>
              </b-col>
            </b-row>

            <b-row v-if="service.customer_identifier">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Customer Identifier
              </b-col>

              <b-col class="text-right">
                {{ service.customer_identifier }}
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <!-- Modals -->
    <edit-modal
      v-if="$can('update', 'Service')"
      v-model="service"
    />
  </div>
</template>

<script>
import pluralize from 'pluralize';
import EditModal from '@/components/admin/services/EditModal.vue';
import NetSuiteFormPopover from '@/components/admin/integrationReferences/NetSuiteFormPopover.vue';
import { DateTime } from 'luxon';

export default {
  name: 'ServicesShow',
  components: {
    EditModal,
    NetSuiteFormPopover,
  },
  data() {
    return {
      service: null,
      totalWorkOrders: 0,
      totalTechnicianServices: 0,
    };
  },
  mounted() {
    this.getService();
    this.getWorkOrders();
  },
  methods: {
    getService() {
      this.$servicesAPI
        .get(this.$route.params.id)
        .then((service) => {
          this.service = service;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    toggleActive() {
      this.$swal({
        text: this.toggleActiveConfirmText,
        preConfirm: () => this.$servicesAPI.update(this.$route.params.id, { deleted_at: !this.service.deleted_at ? DateTime.now().toISODate() : null }),
      }).then((result) => {
        if (result.value) {
          this.service = result.value;
        }
        result.timer;
      });
    },
    getWorkOrders() {
      this.$workOrdersAPI.getWorkOrders({ service_id: this.$route.params.id, per_page: 1 }).then((data) => {
        this.totalWorkOrders = this.$options.filters.numberWithDelimeter(data.total);
      });
    },
    getRecurringWorkOrders() {
      this.$technicianServicesAPI.getTechnicianServices({ service_id: this.$route.params.id, active: true, per_page: 1 }).then((data) => {
        this.totalRecurringWorkOrders = this.$options.filters.numberWithDelimeter(data.total);
      });
    },
  },
  computed: {
    toggleActiveTooltipText() {
      return !this.service.deleted_at ? 'Deactivate' : 'Reactivate';
    },
    toggleActiveConfirmText() {
      return !this.service.deleted_at
        ? 'Are you sure you want to deactivate the Service?'
        : 'Are you sure you want to reactivate the Service?';
    },
    minimumDaysBetweenService() {
      const daysBetweenServices = this.service.minimum_days_between_service;
      return `${daysBetweenServices} ${pluralize('Day', daysBetweenServices)}`;
    },
    estimatedHours() {
      const estimatedHours = this.$options.filters.numberWithDelimeter(this.service.estimated_hours) || 0;
      return `${estimatedHours} ${pluralize('Hour', estimatedHours)}`;
    },
    technicianPaymentGLCodeName() {
      return this.service.technician_payment_gl_code?.name || '-';
    },
    invoiceGLCodeName() {
      return this.service.invoice_gl_code?.name || '-';
    },
  }
};
</script>
