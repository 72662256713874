import { DateTime } from 'luxon';

export function mapTimeSeriesData(timeData, intervalSeries = undefined) {

  const toMillis = (isoDate) => (DateTime.fromISO(isoDate).toMillis());
  const keys = Object.keys(timeData);
  if (keys.length === 0) {
    return intervalSeries ? intervalSeries.map((isoDate) => ({ x: toMillis(isoDate), y: 0 })) : [];
  }

  if (intervalSeries) {
    return intervalSeries.map((isoDate) => {
      return { x: toMillis(isoDate), y: Number(timeData[isoDate]) || 0 };
    });
  }
  else {
    return keys.map((dateStr) => {
      const keyData = timeData[dateStr];
      return { x: toMillis(dateStr), y: Number(keyData) };
    });
  }

}

export function mapSeriesData(data) {
  const keys = Object.keys(data);
  return keys.map((key) => {
    const keyData = data[key];
    return { name: key, y: keyData };
  });
}
