<template>
  <b-form @submit.stop.prevent="onSubmit">
    <b-alert
      v-model="incorrectPassword"
      variant="danger"
      dismissible
    >
      Incorrect username or password.
    </b-alert>
    <b-alert
      :show="hasBaseServerErrors()"
      variant="danger"
      dismissible
    >
      <p
        class="mb-0"
        v-for="(error, index) in baseServerErrors"
        :key="index"
      >
        {{ error }}
      </p>
    </b-alert>
    <div>
      <b-form-group
        :label="fieldLabels.email"
        label-for="user-email"
      >
        <b-form-input
          id="user-email"
          v-model="$v.form.email.$model"
          :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
          type="text"
          placeholder="name@company.com"
        />
        <b-form-invalid-feedback
          v-if="$v.form.email.$dirty"
          id="user-email-feedback"
        >
          <span v-if="!$v.form.email.required">Please enter a email.</span>
          <span v-if="!$v.form.email.email">Please enter a valid email.</span>
          <span v-if="!$v.form.email.serverFailed">
            {{ serverErrors.email }}
          </span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        :label="fieldLabels.password"
        label-for="user-password"
      >
        <b-form-input
          id="user-password"
          v-model="$v.form.password.$model"
          :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
          type="password"
        />
        <b-form-invalid-feedback
          v-if="$v.form.password.$dirty"
          id="user-password-feedback"
        >
          <span v-if="!$v.form.password.required">Please enter a password.</span>
          <span v-if="!$v.form.password.serverFailed">
            {{ serverErrors.password }}
          </span>
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
    <b-row class="form-group">
      <b-col>
        <b-button
          type="submit"
          variant="primary"
          block
        >
          <b-spinner
            v-if="processing"
            small
            label="Logging In..."
            class="mr-1"
          />
          <i
            v-else
            class="fa fa-fw fa-sign-in-alt mr-1"
          />
          Login
        </b-button>
      </b-col>
    </b-row>

    <b-link :to="{ name: 'forgot-password' }">
      Forgot your password?
    </b-link>
  </b-form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';

export default {
  name: 'LoginForm',
  mixins: [formMixin],
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      fieldLabels: {
        email: 'Email',
        password: 'Password',
      },
      incorrectPassword: false,
      processing: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
        serverFailed() {
          return !this.hasServerErrors('email');
        },
      },
      password: {
        required,
        serverFailed() {
          return !this.hasServerErrors('password');
        },
      },
    },
  },
  methods: {
    onSubmit() {
      this.resetServerErrors();
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }
      this.processing = true;
      this.$store
        .dispatch('login', this.form)
        .then(() => {
          this.$emit('on-login');
        })
        .catch((error) => {
          this.processing = false;
          this.processServerErrors(error?.response?.data?.errors);
          if (this.mustRegisterMfa && !this.hasBaseServerErrors()) {
            this.$emit('on-registration-required', this.form);
          } else if (this.mustVerifyMfa) {
            this.$emit('on-verification-required', this.form);
          } else {
            this.incorrectPassword = !this.hasBaseServerErrors();
          }
        });
    },
  },
  computed: {
    mustRegisterMfa() {
      return this.hasServerErrors('otp_required_for_login');
    },
    mustVerifyMfa() {
      return this.hasServerErrors('otp_attempt');
    },
  },
};
</script>
