<template>
  <div v-if="vendor">
    <base-page-heading
      :title="vendor.name"
      show-back-button
    >
      <template #extra>
        <b-button
          v-if="$can('update', 'Vendor')"
          variant="primary"
          v-b-modal.vendor-edit-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Edit the Vendor"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <net-suite-form-popover
          v-if="$can('read', 'IntegrationReference')"
          :reference-id="vendor.id"
          reference-type="Vendor"
          :current-integration-reference-id="vendor.netsuite_reference_id"
        />

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: vendor.id, model: 'Vendor' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content pt-5">
      <b-row>
        <b-col md="6">
          <b-card>
            <b-row
              v-if="vendor.account_number"
              class="mb-2"
            >
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Account Number
              </b-col>

              <b-col class="text-right">
                {{ vendor.account_number }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Address
              </b-col>

              <b-col class="text-right">
                {{ vendor.address }}
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                City
              </b-col>

              <b-col class="text-right">
                {{ vendor.city }}
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                State
              </b-col>

              <b-col class="text-right">
                {{ vendor.state }}
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Zip Code
              </b-col>

              <b-col class="text-right">
                {{ vendor.zip_code }}
              </b-col>
            </b-row>

            <b-row
              v-if="vendor.attention_to"
              class="mb-2"
            >
              <b-col
                cols="auto"
                class="mr-auto"
              >
                PO Attention To
              </b-col>

              <b-col class="text-right">
                {{ vendor.attention_to }}
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Vendor is exempt from charging taxes
              </b-col>

              <b-col class="text-right">
                <div v-if="vendor.tax_exempt">
                  <i class="fa fa-fw fa-check text-success" />
                  Yes
                </div>
                <div v-else>
                  <i class="fa fa-fw fa-times text-danger" />
                  No
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="6">
          <contact-infos-list
            :list-func="$contactInfosAPI.getVendorsContactInfos"
            :create-func="$contactInfosAPI.createVendorsContactInfos"
            :reference-id="vendor.id"
            reference-type="Vendor"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-row class="pt-3">
            <b-col
              cols="auto"
              class="mr-auto"
            >
              <h3>
                Supplies
              </h3>
            </b-col>
            <b-col cols="auto">
              <b-button
                v-if="$can('create', 'Supply')"
                variant="outline-secondary"

                v-b-modal.supply-add-modal
                v-b-tooltip.hover
                title="Create a new Supply"
              >
                <i class="fa fa-plus" />
                Add Supplies
              </b-button>
            </b-col>
          </b-row>
          <b-row class="pt-3">
            <b-col>
              <supplies-list-filter
                :columns="suppliesColumns"
                :table-name="suppliesTableName"
                :default-columns="suppliesDefaultColumns"
                :initial-filters="suppliesFilters"
                @filters-applied="applySuppliesFilters"
              >
                <template #right-tray>
                  <sfs-download-button
                    v-b-tooltip.hover
                    class="ml-2"
                    :provider-params="{ ...suppliesFilters, vendor_id: vendor.id }"
                    filename="Supplies.csv"
                    :file-provider="$suppliesAPI.getSpreadsheet"
                    title="Download spreadsheet"
                  />
                </template>
              </supplies-list-filter>
            </b-col>
          </b-row>

          <supplies-table
            :filters="suppliesFilters"
            :columns="suppliesColumns"
            :append-to-query="false"
            :key="suppliesTableKey"
            :vendor-id="vendor.id"
          />
        </b-col>
      </b-row>
    </div>

    <!-- Modals -->
    <vendors-edit-modal
      v-if="$can('update', 'Vendor')"
      v-model="vendor"
    />
    <supplies-new-modal
      v-if="$can('create', 'Supply')"
      :vendor="vendor"
      :create-provider="$suppliesAPI.create"
      @on-save="refreshSupplies"
    />
  </div>
</template>

<script>
import VendorsEditModal from '@/components/admin/vendors/EditModal.vue';
import ContactInfosList from '@/components/admin/contactInfos/CardList.vue';
import SuppliesTable from '@/components/admin/supplies/Table.vue';
import SuppliesListFilter from '@/components/admin/supplies/ListFilter.vue';
import SuppliesNewModal from '@/components/admin/supplies/NewModal.vue';
import NetSuiteFormPopover from '@/components/admin/integrationReferences/NetSuiteFormPopover.vue';
import { VENDOR_SUPPLY_TABLE_NAME, VENDOR_SUPPLY_TABLE_DEFAULT_COLUMNS } from '@/constants/supplies';

export default {
  name: 'VendorsShow',
  components: {
    VendorsEditModal,
    ContactInfosList,
    SuppliesTable,
    SuppliesListFilter,
    SuppliesNewModal,
    NetSuiteFormPopover,
  },
  data() {
    return {
      vendor: null,
      suppliesTableKey: Date.now() + 1,
      suppliesTableName: VENDOR_SUPPLY_TABLE_NAME,
      suppliesDefaultColumns: VENDOR_SUPPLY_TABLE_DEFAULT_COLUMNS,
      suppliesFilters: {
        text: '',
        item_number: '',
        description: '',
        city: '',
        state: '',
        category: '',
        active: true,
      },
    };
  },
  mounted() {
    this.getVendor();
  },
  computed: {
    suppliesColumns() {
      return this.$store.getters.columnsForTable(this.suppliesTableName);
    },
  },
  methods: {
    getVendor() {
      this.$vendorsAPI
        .get(this.$route.params.id)
        .then((vendor) => {
          this.vendor = vendor;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    refreshContactInfos() {
      this.contactInfosTableKey++;
    },
    refreshSupplies() {
      this.suppliesTableKey++;
    },
    applySuppliesFilters({ filters }) {
      this.suppliesFilters = Object.assign({}, filters);
    },
  },
};
</script>
