import List from '@/pages/admin/walkthroughs/List.vue';
import Show from '@/pages/admin/walkthroughs/Show.vue';
import handleActivePaginationRouteProps from '@/utils/handleActivePaginationRouteProps.js';

export default [
  {
    path: 'walkthroughs',
    name: 'walkthroughs',
    component: List,
    props: handleActivePaginationRouteProps,
  },
  {
    path: 'walkthroughs/:id(\\d+)',
    name: 'walkthrough',
    component: Show,
  },
];
