<template>
  <sfs-form-modal
    :id="modalId"
    title="Add a Work Order Charge"
    @on-hide="() => workOrderCharge = null"
  >
    <b-form
      novalidate
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Search for a Work Order to Bill"
            label-for="invoice-item-add-work-order-work_order_charge_id"
          >
            <work-order-charges-select
              id="invoice-item-add-work-order-work_order_charge_id"
              :value="workOrderCharge"
              :select-class="{ 'is-invalid': $v.form.work_order_charge_id.$dirty && $v.form.work_order_charge_id.$error }"
              :filter-params="workOrderSelectParams"
              @input="onWorkOrderChargeChange"
            />
            <b-form-invalid-feedback
              v-if="$v.form.work_order_charge_id.$dirty"
              id="invoice-item-add-work-order-work_order_charge_id-feedback"
            >
              <span v-if="!$v.form.work_order_charge_id.required">Please enter a charge.</span>
              <span v-if="!$v.form.work_order_charge_id.serverFailed">{{ serverErrors.work_order_charge_id }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="invoice-item-add-work-order-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="invoice-item-add-work-order-submit-button"
            block
            type="submit"
            variant="primary"
            :disabled="processing"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import WorkOrderChargesSelect from '@/components/admin/workOrderCharges/Select';
import { WORK_ORDER_STATUS_COMPLETED, WORK_ORDER_STATUS_INVOICED } from '@/constants/workOrders';

export default {
  name: 'InvoicesAddWorkOrderChargeModal',
  mixins: [formMixin, formModalMixin],
  components: {
    WorkOrderChargesSelect,
  },
  props: {
    invoice_id: { type: Number, required: true },
    client_id: { type: Number, required: true },
    work_order_charge_id: Number,
  },
  data() {
    return {
      form: {
        work_order_charge_id: this.work_order_charge_id,
      },
      fieldLabels: {
        work_order_charge_id: 'Work Order Charge',
      },
      workOrderCharge: null,
      workOrderSelectParams: {
        invoiced: false,
        client_id: this.client_id,
        statuses: [WORK_ORDER_STATUS_COMPLETED, WORK_ORDER_STATUS_INVOICED],
      },
      modalId: 'invoice-item-add-work-order-charge-modal',
    };
  },
  validations: {
    form: {
      work_order_charge_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('work_order_charge_id');
        },
      },
    },
  },
  methods: {
    onWorkOrderChargeChange(workOrderCharge) {
      this.form.work_order_charge_id = workOrderCharge ? workOrderCharge.id : '';
      this.workOrderCharge = workOrderCharge;
    },
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.processing = true;
      this.$invoicesAPI
        .createWorkOrderChargeItem(this.invoice_id, this.form)
        .then(() => {
          this.resetServerErrors();
          this.$v.$reset();
          this.$emit('on-save');
          this.closeModal();
          this.processing = false;
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
          this.processing = false;
        });
    },
  },
};
</script>
