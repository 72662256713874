<template>
  <div>
    <base-page-heading title="Delayed Jobs" />
    <div class="content">
      <b-row>
        <b-col>
          <delayed-jobs-list-filter
            :table-name="tableName"
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>

      <delayed-jobs-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
  </div>
</template>

<script>
import DelayedJobsTable from '@/components/admin/delayedJobs/Table.vue';
import DelayedJobsListFilter from '@/components/admin/delayedJobs/ListFilter.vue';
import listMixin from '@/mixins/listMixin';
import { DELAYED_JOB_TABLE_NAME } from '@/constants/delayedJobs';

export default {
  name: 'DelayedJobsList',
  components: {
    DelayedJobsListFilter,
    DelayedJobsTable,
  },
  mixins: [listMixin],
  props: {
    status: String,
    queue: String,
    failed_from: String,
    failed_to: String,
    date_from: String,
    date_to: String,
    last_error: String,
    arguments: String,
  },
  data() {
    return {
      tableName: DELAYED_JOB_TABLE_NAME,
      filters: {
        status: this.status,
        queue: this.queue,
        failed_from: this.failed_from,
        failed_to: this.failed_to,
        date_from: this.date_from,
        date_to: this.date_to,
        last_error: this.last_error,
        arguments: this.arguments,
      },
    };
  },
};
</script>
