import List from '@/pages/admin/imports/List.vue';
import handlePaginationRouteProps from '@/utils/handlePaginationRouteProps.js';

export default [
  {
    path: 'imports',
    name: 'imports',
    component: List,
    props: handlePaginationRouteProps,
  },
];
