<template>
  <div v-if="technicianPayment">
    <base-page-heading
      :title="title"
      show-back-button
    />
    <div class="content">
      <b-row class="mb-3">
        <b-col lg="6">
          <b-card>
            <b-row v-if="technicianPayment.status">
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Status
              </b-col>
              <b-col class="text-right">
                <status-badge
                  :status="technicianPayment.status"
                  :status-variants="statusVariants"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto mb-3"
              >
                Payment Date
              </b-col>

              <b-col class="text-right">
                {{ technicianPayment.due_date | dateFormat }}
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto mb-3"
              >
                Work Period
              </b-col>

              <b-col class="text-right">
                {{ technicianPayment | dateRangeFormat("start_date", "end_date") }}
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="auto"
                class="mr-auto"
              >
                Amount
              </b-col>
              <b-col class="text-right">
                <money-format
                  :value="technicianPayment.balance.amount"
                  :currency-code="technicianPayment.balance.currency"
                  subunits-value
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-right">
                Last updated {{ technicianPayment.updated_at | dateTimeFormat }}
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">
                Created on {{ technicianPayment.created_at | dateTimeFormat }}
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col
          v-if="showCompletedBySummary"
          lg="5"
          offset-lg="1"
        >
          <b-card>
            <b-row class="mb-3">
              <b-col>
                <b>
                  Completed By Summary
                </b>
              </b-col>
            </b-row>

            <b-skeleton v-if="isLoadingData" />
            <b-row
              v-else
              v-for="(summary, index) in completedBySummary"
              :key="index"
            >
              <b-col
                cols="auto"
                class="mr-auto mb-3"
              >
                <b-link
                  :to="{ name: 'technician-user', params: { id: summary.done_by_id } }"
                >
                  {{ summary.done_by }}
                </b-link>
              </b-col>
              <b-col class="text-right">
                <money-format
                  :value="summary.amount_cents"
                  subunits-value
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-row class="pt-3">
            <b-col>
              <technician-payment-items-list-filter
                :technician-payment="technicianPayment"
                :initial-filters="filters"
                @filters-applied="applyFilters"
                @data-changed="refreshTable"
              />
            </b-col>
          </b-row>
          <technician-payment-items-table
            :technician-payment-id="$route.params.id.toString()"
            :append-to-query="false"
            :filters="filters"
            :key="tableKey"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TechnicianPaymentItemsTable from '@/components/technician/technicianPaymentItems/Table.vue';
import TechnicianPaymentItemsListFilter from '@/components/technician/technicianPaymentItems/ListFilter.vue';
import technicianPaymentsMixin from '@/mixins/technicianPaymentsMixin';
import StatusBadge from '@/components/shared/StatusBadge.vue';
import { STATUS_VARIANTS } from '@/constants/technicianPayments';

export default {
  name: 'TechnicianUserPaymentsShow',
  mixins: [technicianPaymentsMixin],

  components: {
    TechnicianPaymentItemsTable,
    TechnicianPaymentItemsListFilter,
    StatusBadge,
  },
  data() {
    return {
      isLoadingData: false,
      technicianPayment: null,
      completedBySummary: [],
      statusVariants: STATUS_VARIANTS,
      tableKey: Date.now(),
      filters: {
        text: this.text,
      },
    };
  },
  mounted() {
    this.getTechnicianPayment();
    this.getCompletedBySummary();
  },
  computed: {
    title() {
      return `Payment ${this.technicianPayment.number}`;
    },
    user() {
      return this.technicianPayment.user;
    },
    showCompletedBySummary() {
      return this.completedBySummary.length > 1;
    },
  },
  methods: {
    getTechnicianPayment() {
      this.$technicianUserPaymentsAPI
        .get(this.$route.params.id)
        .then((technicianPayment) => (this.technicianPayment = technicianPayment))
        .catch(() => {
          this.$router.push('/404');
        });
    },
    getCompletedBySummary() {
      this.isLoadingData = true;
      this.$technicianUserPaymentsAPI
        .getCompletedBySummary(this.$route.params.id)
        .then((response) => {
          this.completedBySummary = response.data;
        })
        .catch(() => {
          this.completedBySummary = [];
        })
        .finally(() => {
          this.isLoadingData = false;
        });
    },
    refreshTable() {
      this.tableKey = Date.now();
    },
    applyFilters({ filters }) {
      this.filters = Object.assign({}, filters);
    },
  },
};
</script>
