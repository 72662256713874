<template>
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :value="value"
    @input="(category) => $emit('input', category)"
    :reduce="(category) => category.value"
  />
</template>
<script>
export default {
  name: 'CategorySelect',
  props: {
    value: String,
    id: {
      type: String,
      default: 'category-select',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: {
      type: String,
      default: 'Select a Category',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
