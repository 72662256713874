<template>
  <sfs-base-list-filter
    title="Filter Supplies"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
  >
    <template #quick-filters>
      <b-form-group
        label="Search fields for Data"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Item Number, Vendor Name, City or State"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #advanced-filters>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.item_number"
            label-for="advanced-filter-item_number"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-item_number"
              v-model="filters.item_number"
              type="text"
              :placeholder="fieldLabels.item_number"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            label="Category"
            label-for="advanced-filter-category"
            label-sr-only
          >
            <category-select
              id="advanced-filter-category"
              v-model="filters.category"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.description"
            label-for="advanced-filter-description"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-description"
              v-model="filters.description"
              type="text"
              :placeholder="fieldLabels.description"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.city"
            label-for="advanced-filter-city"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-city"
              v-model="filters.city"
              type="text"
              :placeholder="fieldLabels.city"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="3"
        >
          <b-form-group
            :label="fieldLabels.state"
            label-for="advanced-filter-state"
            label-sr-only
          >
            <b-form-input
              id="advanced-filter-state"
              v-model="filters.state"
              type="text"
              :placeholder="fieldLabels.state"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Active or Inactive"
            label-for="advanced-filter-active"
            label-sr-only
          >
            <b-form-radio-group
              id="advanced-filter-active"
              v-model="filters.active"
              :options="activeOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template #right-tray>
      <slot name="right-tray" />
    </template>
  </sfs-base-list-filter>
</template>
<script>
import listFilterMixin from '@/mixins/listFilterMixin';
import CategorySelect from '@/components/admin/supplies/CategorySelect.vue';

import { SUPPLY_TABLE_COLUMN_OPTIONS, SUPPLIES_FORM_LABELS } from '@/constants/supplies';

export default {
  name: 'SuppliesListFilter',
  mixins: [listFilterMixin],
  components: {
    CategorySelect,
  },
  props: {
    tableName: {
      type: String,
      required: true,
    },
    defaultColumns: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      availableColumns: SUPPLY_TABLE_COLUMN_OPTIONS,
      fieldLabels: {
        ...SUPPLIES_FORM_LABELS,
        city: 'Vendor City',
        state: 'Vendor State',
      },
    };
  },
  mounted() {
    this.applyFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = this.defaultColumns;
      this.applyFilters();
    },
  },
};
</script>
