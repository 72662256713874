<template>
  <sfs-form-modal
    :id="modalId"
    title="Confirm The Supply Order"
    @on-hide="resetForm"
  >
    <b-form
      novalidate
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col>
          <b-form-group
            :label="fieldLabels.vendor_number"
            label-for="supply-order-confirm-vendor_number"
          >
            <b-form-input
              id="supply-order-confirm-vendor_number"
              v-model="$v.form.vendor_number.$model"
              autocomplete="new-password"
              :state="$v.form.vendor_number.$dirty ? !$v.form.vendor_number.$error : null"
              type="text"
            />
            <b-form-invalid-feedback
              v-if="$v.form.vendor_number.$dirty"
              id="supply-order-confirm-vendor_number-feedback"
            >
              <span v-if="!$v.form.vendor_number.serverFailed">{{ serverErrors.vendor_number }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="supply-order-confirm-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="supply-order-confirm-submit-button"
            block
            type="submit"
            variant="primary"
          >
            Confirm
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'SupplyOrderConfirmModal',
  mixins: [formMixin, formModalMixin],
  props: {
    value: Object,
  },
  data() {
    return {
      form: {
        vendor_number: this.value.vendor_number,
      },
      fieldLabels: {
        vendor_number: 'Enter the Vendor\'s Invoice Number',
      },
      modalId: 'supply-order-confirm-modal',
    };
  },
  validations: {
    form: {
      vendor_number: {
        serverFailed() {
          return !this.hasServerErrors('vendor_number');
        },
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.$supplyOrdersAPI
        .confirm(this.value.id, this.form)
        .then((supplyOrder) => {
          this.resetServerErrors();
          this.$v.$reset();
          this.$emit('input', supplyOrder);
          this.closeModal();
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
        });
    },
  },
};
</script>
