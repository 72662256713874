import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const INSURANCE_CERTIFICATE_TABLE_NAME = 'insuranceCertificates';

// The columns available to be shown in a insuranceCertificates table.
export const INSURANCE_CERTIFICATE_TABLE_COLUMNS = {
  policy_number: { label: 'Policy Number', key: 'policy_number', sortable: true },
  category: { label: 'Category', key: 'category' },
  insurance_contact: { label: 'Insurance Contact', key: 'insurance_contact' },
  phone: { label: 'Insurance Phone', key: 'phone' },
  state: { label: 'State', key: 'state' },
  status: { label: 'Status', key: 'status' },
  user_id: { label: 'User', key: 'user_id', sortable: true },
  end_date: { label: 'Coverage Expiries', key: 'end_date', sortable: true },
  end_date_from: { label: 'Coverage Expiring From', key: 'end_date_from', filterOnly: true },
  end_date_to: { label: 'Coverage Expiring To', key: 'end_date_to', filterOnly: true },
  manager_id: { label: 'Manager', key: 'manager_id' },
  verified: { label: 'Verified', key: 'verified' },
  deleted_at: { label: 'Active', key: 'active' },
};

// The available work order statuses.
export const INSURANCE_CERTIFICATE_STATUS_UP_TO_DATE = 'up_to_date';
export const INSURANCE_CERTIFICATE_STATUS_EXPIRES_SOON = 'expires_soon';
export const INSURANCE_CERTIFICATE_STATUS_EXPIRED = 'expired';
export const INSURANCE_CERTIFICATE_STATUS_INACTIVE = 'inactive';

const VARIANT_WARNING = 'warning';
const VARIANT_DANGER = 'danger';
const VARIANT_SUCCESS = 'success';

export const INSURANCE_CERTIFICATE_STATUS_VARIANTS = {
  [INSURANCE_CERTIFICATE_STATUS_UP_TO_DATE]: VARIANT_SUCCESS,
  [INSURANCE_CERTIFICATE_STATUS_EXPIRES_SOON]: VARIANT_WARNING,
  [INSURANCE_CERTIFICATE_STATUS_EXPIRED]: VARIANT_DANGER,
  [INSURANCE_CERTIFICATE_STATUS_INACTIVE]: VARIANT_DANGER,
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const INSURANCE_CERTIFICATE_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(INSURANCE_CERTIFICATE_TABLE_COLUMNS);

// The default columns to be shown when the insuranceCertificates table loads.
export const INSURANCE_CERTIFICATE_TABLE_DEFAULT_COLUMNS = [
  INSURANCE_CERTIFICATE_TABLE_COLUMNS.policy_number,
  INSURANCE_CERTIFICATE_TABLE_COLUMNS.status,
  INSURANCE_CERTIFICATE_TABLE_COLUMNS.user_id,
  INSURANCE_CERTIFICATE_TABLE_COLUMNS.state,
  INSURANCE_CERTIFICATE_TABLE_COLUMNS.insurance_contact,
  INSURANCE_CERTIFICATE_TABLE_COLUMNS.end_date,
];

export const INSURANCE_CERTIFICATE_TABLE_VERIFIED_FILTER_OPTIONS = [
  { text: 'Coverage Verified', value: 'true' },
  { text: 'Not Verified', value: 'false' },
  { text: 'Both', value: null },
];
