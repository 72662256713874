<template>
  <div>
    <base-page-heading title="Imports">
      <template #extra>
        <b-dropdown
          text="Import"
          class="ml-2"
          variant="primary"
          right
        >
          <template #button-content>
            <i class="fa fa-plus mr-1" />
            New Import
          </template>
          <b-row style="width: 700px;">
            <b-col sm="6">
              <b-dropdown-group header="Work Orders">
                <b-dropdown-item v-b-modal.work-orders-imports-add-modal>
                  Create Work Orders
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.update-work-orders-imports-add-modal>
                  Update Work Orders
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.cancel-work-orders-imports-add-modal>
                  Cancel
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.reopen-work-orders-imports-add-modal>
                  Reopen Cancelled
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.assign-work-orders-imports-add-modal>
                  Assign
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.check-out-work-orders-imports-add-modal>
                  Check Out
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="$can('destroy', 'InvoiceItem')"
                  v-b-modal.remove-work-orders-from-invoice-imports-add-modal
                >
                  Remove From Invoice
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="$can('create_item', 'TechnicianPayment')"
                  v-b-modal.remove-work-orders-from-technician-payment-imports-add-modal
                >
                  Remove From Technician Payments
                </b-dropdown-item>
              </b-dropdown-group>

              <b-dropdown-group header="Work Order Charges">
                <b-dropdown-item v-b-modal.work-order-charges-imports-add-modal>
                  Create Work Order Charges
                </b-dropdown-item>
                <b-dropdown-item v-b-modal.update-work-order-charges-imports-add-modal>
                  Update Work Order Charges
                </b-dropdown-item>
              </b-dropdown-group>

              <b-dropdown-group header="3rd Party Integrations">
                <b-dropdown-item v-b-modal.officetrax-work-orders-imports-add-modal>
                  Edit Officetrax Data
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.officetrax-work-orders-photos-push-imports-add-modal>
                  Push Photos to Officetrax
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.officetrax-work-orders-completions-push-imports-add-modal>
                  Push Completions to Officetrax
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.service-channel-work-orders-push-imports-add-modal>
                  Push Completions to ServiceChannel
                </b-dropdown-item>
              </b-dropdown-group>
            </b-col>

            <b-col sm="6">
              <b-dropdown-group :header="`${$store.getters.translate('client', 'Client')} Management`">
                <b-dropdown-item v-b-modal.clients-imports-add-modal>
                  Create / Update Clients
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.locations-imports-add-modal>
                  Create / Update Locations
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.recurring-work-orders-imports-add-modal>
                  New Recurring Work Orders
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.recurring-work-orders-imports-delete-modal>
                  Delete Recurring Work Orders
                </b-dropdown-item>
              </b-dropdown-group>

              <b-dropdown-group header="User Management">
                <b-dropdown-item v-b-modal.users-imports-add-modal>
                  Create / Update Users
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.technician-services-imports-add-modal>
                  New Technician Services
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.technician-services-imports-delete-modal>
                  Delete Technician Services
                </b-dropdown-item>
              </b-dropdown-group>

              <b-dropdown-group header="Accounting">
                <b-dropdown-item
                  v-if="$can('create_item', 'TechnicianPayment')"
                  v-b-modal.technician-payment-items-imports-add-modal
                >
                  New Technician Payment Items
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('create_item', 'TechnicianPayment')"
                  v-b-modal.technician-payment-items-from-work-order-imports-add-modal
                >
                  New Payment Items From Work Orders
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('create', 'InvoiceItem')"
                  v-b-modal.invoice-items-imports-add-modal
                >
                  New Invoice Items
                </b-dropdown-item>
                <b-dropdown-item v-b-modal.recurring-charges-imports-add-modal>
                  New Recurring Charges
                </b-dropdown-item>
                <b-dropdown-item v-b-modal.recurring-payments-imports-add-modal>
                  New Recurring Payments
                </b-dropdown-item>
              </b-dropdown-group>

              <b-dropdown-group header="Tax Rates">
                <b-dropdown-item v-b-modal.tax-rates-imports-add-modal>
                  Create Tax Rates
                </b-dropdown-item>
              </b-dropdown-group>
            </b-col>
          </b-row>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <imports-list-filter
            :columns="columns"
            :initial-filters="appliedFilters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            v-if="isLoaded"
            id="imports-table"
            :items="records"
            :fields="columns"
            :busy="isLoadingData"
            show-empty
            responsive
            table
          >
            <template #cell(user_id)="data">
              <b-link
                v-if="data.item.user_id"
                :to="{ name: 'user', params: { id: data.item.user.id } }"
              >
                {{ data.item.user.name }}
              </b-link>
            </template>
            <template #cell(category)="data">
              {{ data.item.category || "depreciated_import" | humanizedFormat | capitalizeFormat }}
            </template>
            <template #cell(status)="data">
              <b-badge :variant="statusBadge(data.item.status)">
                {{ data.item.status | humanizedFormat | capitalizeFormat }}
              </b-badge>
            </template>
            <template #cell(file)="data">
              <b-link :href="data.item.file.url">
                Download
              </b-link>
            </template>
            <template #cell(error_file)="data">
              <b-link
                v-if="data.item.error_file"
                :href="data.item.error_file.url"
              >
                Download
              </b-link>
            </template>
            <template #cell(completed_at)="data">
              <span v-if="data.item.completed_at">{{ data.item.completed_at | dateTimeFormat }}</span>
            </template>
            <template #cell(created_at)="data">
              <span v-if="data.item.created_at">{{ data.item.created_at | dateTimeFormat }}</span>
            </template>
            <template #cell(num_imported)="data">
              {{ data.item.num_imported | numberWithDelimeter }}
            </template>
            <template #cell(num_errors)="data">
              {{ data.item.num_errors | numberWithDelimeter }}
            </template>
          </b-table>
          <sfs-skeleton-table
            v-else
            :rows="pagination.perPage"
            :columns="columns.length"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="auto"
          :class="{ 'mr-auto': true, 'ml-auto': !showPerPage }"
        >
          <b-pagination
            v-if="showPagination"
            v-model="pagination.currentPage"
            :total-rows="totalRows"
            :per-page="pagination.perPage"
            last-class="d-none"
          />
        </b-col>
        <sfs-pagination-dropdown
          container-class="col-auto"
          :current-page="pagination.currentPage"
          :per-page="pagination.perPage"
          :total-rows="totalRows"
          @per-page-changed="onPerPageChanged"
        />
      </b-row>
    </div>
    <!-- Page Modals -->
    <work-orders-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <work-order-charges-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <update-work-order-charges-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <assign-work-orders-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <check-out-work-orders-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <cancel-work-orders-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <update-work-orders-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <reopen-work-orders-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <officetrax-work-orders-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <officetrax-work-orders-photos-push-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <officetrax-work-orders-completions-push-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <service-channel-work-orders-push-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <users-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <clients-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <locations-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <technician-services-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <technician-services-import-delete-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <technician-payment-items-import-new-modal
      v-if="$can('create_item', 'TechnicianPayment')"
      @on-save="getRecords"
    />
    <technician-payment-items-from-work-order-import-new-modal
      v-if="$can('create_item', 'TechnicianPayment')"
      @on-save="getRecords"
    />
    <invoice-items-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <recurring-charges-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <recurring-payments-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <recurring-work-orders-import-new-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <recurring-work-orders-import-delete-modal
      v-if="$can('create', 'Import')"
      @on-save="getRecords"
    />
    <remove-work-orders-from-invoice-import-new-modal
      v-if="$can('destroy', 'InvoiceItem')"
      @on-save="getRecords"
    />
    <remove-work-orders-from-technician-payment-import-new-modal
      v-if="$can('create_item', 'TechnicianPayment')"
      @on-save="getRecords"
    />
    <tax-rates-import-new-modal
      v-if="$can('create', 'TaxRate')"
      @on-save="getRecords"
    />
  </div>
</template>

<script>
import ImportsListFilter from '@/components/admin/imports/ListFilter.vue';
import WorkOrdersImportNewModal from '@/components/admin/imports/WorkOrdersNewModal.vue';
import TaxRatesImportNewModal from '@/components/admin/imports/TaxRatesNewModal.vue';
import WorkOrderChargesImportNewModal from '@/components/admin/imports/WorkOrderChargesNewModal.vue';
import AssignWorkOrdersImportNewModal from '@/components/admin/imports/AssignWorkOrdersNewModal.vue';
import CheckOutWorkOrdersImportNewModal from '@/components/admin/imports/CheckOutWorkOrdersNewModal.vue';
import CancelWorkOrdersImportNewModal from '@/components/admin/imports/CancelWorkOrdersNewModal.vue';
import UpdateWorkOrderChargesImportNewModal from '@/components/admin/imports/UpdateWorkOrderChargesNewModal.vue';
import UpdateWorkOrdersImportNewModal from '@/components/admin/imports/UpdateWorkOrdersNewModal.vue';
import ReopenWorkOrdersImportNewModal from '@/components/admin/imports/ReopenWorkOrdersNewModal.vue';
import OfficetraxWorkOrdersImportNewModal from '@/components/admin/imports/OfficetraxWorkOrdersNewModal.vue';
import OfficetraxWorkOrdersPhotosPushImportNewModal from '@/components/admin/imports/OfficetraxWorkOrdersPhotosPushNewModal.vue';
import OfficetraxWorkOrdersCompletionsPushImportNewModal from '@/components/admin/imports/OfficetraxWorkOrdersCompletionsPushNewModal.vue';
import ServiceChannelWorkOrdersPushImportNewModal from '@/components/admin/imports/ServiceChannelWorkOrdersPushNewModal.vue';
import TechnicianServicesImportNewModal from '@/components/admin/imports/TechnicianServicesNewModal.vue';
import TechnicianServicesImportDeleteModal from '@/components/admin/imports/TechnicianServicesDeleteModal.vue';
import TechnicianPaymentItemsImportNewModal from '@/components/admin/imports/TechnicianPaymentItemsNewModal.vue';
import TechnicianPaymentItemsFromWorkOrderImportNewModal from '@/components/admin/imports/TechnicianPaymentItemsFromWorkOrderNewModal.vue';
import InvoiceItemsImportNewModal from '@/components/admin/imports/InvoiceItemsNewModal.vue';
import RecurringChargesImportNewModal from '@/components/admin/imports/RecurringChargesNewModal.vue';
import RecurringPaymentsImportNewModal from '@/components/admin/imports/RecurringPaymentsNewModal.vue';
import RecurringWorkOrdersImportNewModal from '@/components/admin/imports/RecurringWorkOrdersNewModal.vue';
import RecurringWorkOrdersImportDeleteModal from '@/components/admin/imports/RecurringWorkOrdersDeleteModal.vue';
import UsersImportNewModal from '@/components/admin/imports/UsersNewModal.vue';
import ClientsImportNewModal from '@/components/admin/imports/ClientsNewModal.vue';
import LocationsImportNewModal from '@/components/admin/imports/LocationsNewModal.vue';
import RemoveWorkOrdersFromInvoiceImportNewModal from '@/components/admin/imports/RemoveWorkOrdersFromInvoiceNewModal.vue';
import RemoveWorkOrdersFromTechnicianPaymentImportNewModal from '@/components/admin/imports/RemoveWorkOrdersFromTechnicianPaymentNewModal.vue';
import urlMixin from '@/mixins/urlMixin';
import tableMixin from '@/mixins/tableMixin';
import { IMPORT_TABLE_DEFAULT_COLUMNS, SUCCEEDED_IMPORT_STATUS, FAILED_IMPORT_STATUS } from '@/constants/imports';

export default {
  name: 'ImportsList',
  components: {
    ImportsListFilter,
    TechnicianServicesImportNewModal,
    TechnicianServicesImportDeleteModal,
    TechnicianPaymentItemsImportNewModal,
    TechnicianPaymentItemsFromWorkOrderImportNewModal,
    InvoiceItemsImportNewModal,
    UsersImportNewModal,
    ClientsImportNewModal,
    WorkOrdersImportNewModal,
    WorkOrderChargesImportNewModal,
    AssignWorkOrdersImportNewModal,
    CheckOutWorkOrdersImportNewModal,
    CancelWorkOrdersImportNewModal,
    UpdateWorkOrderChargesImportNewModal,
    UpdateWorkOrdersImportNewModal,
    ReopenWorkOrdersImportNewModal,
    OfficetraxWorkOrdersImportNewModal,
    OfficetraxWorkOrdersPhotosPushImportNewModal,
    OfficetraxWorkOrdersCompletionsPushImportNewModal,
    ServiceChannelWorkOrdersPushImportNewModal,
    LocationsImportNewModal,
    RecurringChargesImportNewModal,
    RecurringPaymentsImportNewModal,
    RecurringWorkOrdersImportNewModal,
    RemoveWorkOrdersFromInvoiceImportNewModal,
    RemoveWorkOrdersFromTechnicianPaymentImportNewModal,
    TaxRatesImportNewModal,
    RecurringWorkOrdersImportDeleteModal
  },
  mixins: [urlMixin, tableMixin],
  props: {
    user_id: String,
    status: String,
    date_from: String,
    date_to: String,
    category: String,
    columns: {
      type: Array,
      default: () => IMPORT_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    const currentUser = this.$store.getters.user || {};
    return {
      appliedFilters: {
        user_id: this.user_id || currentUser.id,
        status: this.status,
        date_from: this.date_from,
        date_to: this.date_to,
        category: this.category,
      },
      loaded: false,
      totalRows: 0,
    };
  },
  methods: {
    getRecords() {
      const params = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        ...this.appliedFilters,
      };
      this.isLoadingData = true;
      this.$importsAPI
        .getImports(params)
        .then((response) => {
          const { total, imports } = response;
          this.totalRows = total;
          this.records = imports;
        })
        .catch(() => {
          this.records = [];
        })
        .finally(() => {
          this.loaded = true;
          this.isLoadingData = false;
        });
    },
    statusBadge(status) {
      switch (status) {
        case SUCCEEDED_IMPORT_STATUS:
          return 'success';
        case FAILED_IMPORT_STATUS:
          return 'warning';
        default:
          return 'secondary';
      }
    },
    applyFilters({ filters }) {
      this.appliedFilters = Object.assign({}, filters);
      this.appendObjectToQueryParams(this.appliedFilters);
      this.getRecords();
    },
  },
};
</script>
