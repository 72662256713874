<template>
  <div>
    <base-page-heading title="Revenue Report" />
    <div class="content">
      <revenue-list-filter
        :columns="columns"
        :initial-filters="filters"
        @filters-applied="applyFilters"
      />
      <b-row
        class="font-size-sm"
        align-h="end"
      >
        <b-col
          cols="auto"
        >
          <b-link @click="paramsUpdate('year')">
            By Year
          </b-link>
          <span class="pl-1 pr-1">|</span>
          <b-link @click="paramsUpdate('month')">
            By Month
          </b-link>
          <span class="pl-1 pr-1">|</span>
          <b-link @click="paramsUpdate('week')">
            By Week
          </b-link>
          <span class="pl-1 pr-1">|</span>
          <b-link @click="paramsUpdate('service')">
            By Service
          </b-link>
          <span class="pl-1 pr-1">|</span>
          <b-link @click="paramsUpdate('client')">
            By Client
          </b-link>
        </b-col>
      </b-row>
      <revenue-table
        class="mt-2"
        :filters="filters"
        :columns="columns"
        :revenue-table-params="params"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :key="tableKey"
      />
    </div>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin';
import RevenueListFilter from '@/components/admin/reports/invoices/RevenueListFilter.vue';
import RevenueTable from '@/components/admin/reports/invoices/RevenueTable.vue';
import { WORK_ORDER_REVENUE_REPORT_TABLE_NAME } from '@/constants/reports';

export default {
  name: 'WorkOrdersRevenueReport',
  components: {
    RevenueTable,
    RevenueListFilter,
  },
  mixins: [listMixin],
  props: {
    client_id: String,
    service_id: String,
    state: String,
    start_date: String,
    end_date: String,
  },
  data() {
    return {
      tableName: WORK_ORDER_REVENUE_REPORT_TABLE_NAME,
      filters: {
        client_id: this.client_id,
        service_id: this.service_id,
        state: this.state,
        start_date: this.start_date,
        end_date: this.end_date,
      },
      params: {},
      tableKey: Date.now(),
    };
  },
  methods: {
    paramsUpdate(param) {
      this.params.group_by = param;
      this.tableKey = Date.now();
    },
  },
};
</script>
