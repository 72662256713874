<template>
  <sfs-form-modal
    :id="modalId"
    title="End Recurring Charge"
  >
    <b-form
      novalidate
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="When should the billing end?"
            label-for="recurring-charge-cancel-end_date"
          >
            <sfs-date-picker
              name="recurring-charge-cancel-end_date"
              v-model="$v.form.end_date.$model"
              :class="{ 'is-invalid': $v.form.end_date.$dirty && $v.form.end_date.$error }"
              :with-class="{ 'is-invalid': $v.form.end_date.$dirty && $v.form.end_date.$error }"
            />
            <b-form-invalid-feedback
              v-if="$v.form.end_date.$dirty"
              :state="$v.form.end_date.$dirty ? !$v.form.end_date.$error : null"
              class="recurring-charge-cancel-end_date-feedback"
            >
              <span v-if="!$v.form.end_date.required">Please enter an end date.</span>
              <span v-if="!$v.form.end_date.serverFailed">
                {{ serverErrors.end_date }}
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            class="recurring-charge-cancel-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            block
            class="recurring-charge-cancel-submit-button"
            type="submit"
            variant="primary"
            :disabled="processing"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { DateTime } from 'luxon';
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'RecurringChargeCancelModal',
  mixins: [formMixin, formModalMixin],
  props: {
    value: Object,
    endDate: String,
  },
  data() {
    const initialEndDate = this.endDate ? this.endDate : DateTime.now().endOf('month').toISODate();
    return {
      form: {
        end_date: this.value.end_date ? this.value.end_date : initialEndDate,
      },
      modalId: `recurring-charge-cancel-${this.value.id}-modal`,
    };
  },
  validations: {
    form: {
      end_date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('end_date');
        },
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.processing = true;
      this.$recurringChargesAPI
        .update(this.value.id, this.form)
        .then((recurringCharge) => {
          this.resetServerErrors();
          this.$v.$reset();
          this.$emit('on-save', recurringCharge);
          this.closeModal();
          this.processing = false;
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
          this.processing = false;
        });
    },
  },
};
</script>
