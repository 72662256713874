<template>
  <!-- using autocomplete value "new-password" instead of "off" seems to disable autocomplete better in some browsers. -->
  <v-select
    :id="id"
    :options="options"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :value="value"
    @input="(vendor) => $emit('input', vendor)"
    autocomplete="new-password"
  >
    <template #option="vendor">
      <div>{{ vendor.name }}</div>
      <div class="font-size-sm text-secondary">
        <em>{{ vendor.city }}, {{ vendor.state }}</em>
      </div>
    </template>
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        No results found for
        <em>{{ search }}</em>
      </template>
      <em
        style="opacity: 0.7"
        v-else
      >Type to search for vendors</em>
    </template>
  </v-select>
</template>
<script>
import { isEqual as _isEqual, isUndefined as _isUndefined } from 'lodash';

export default {
  name: 'VendorsSelect',
  props: {
    value: Object,
    id: {
      type: String,
      default: 'vendor',
    },
    selectLabel: {
      type: String,
      default: 'name',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    filterParams: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: String,
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.getVendorOptions();
  },
  methods: {
    getVendorOptions() {
      const params = {
        ...this.filterParams,
        paginate: false,
      };
      this.$vendorsAPI.getVendors(params).then((response) => {
        this.options = response.vendors;
      });
    },
  },
  watch: {
    filterParams: {
      deep: true,
      handler(newFilterParams, oldFilterParams) {
        if (!_isEqual(oldFilterParams, newFilterParams) && !_isUndefined(newFilterParams)) {
          this.getVendorOptions();
        }
      },
    },
  },
};
</script>
