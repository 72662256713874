// Main files
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

// Custom libraries
import BootstrapVue from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import { abilitiesPlugin } from '@casl/vue';
import Appsignal from '@appsignal/javascript';
import { errorHandler } from '@appsignal/vue';
import VueApexCharts from 'vue-apexcharts';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueSidebarMenu from 'vue-sidebar-menu';

// Custom components from libraries
import vSelect from 'vue-select';
import VueFlatPickr from 'vue-flatpickr-component';
import MoneyFormat from 'vue-money-format';
import vue2Dropzone from 'vue2-dropzone';

// Custom components
import ActiveBadge from '@/components/shared/ActiveBadge.vue';
import BasePageHeading from '@/components/shared/BasePageHeading.vue';
import BaseTable from '@/components/shared/BaseTable.vue';
import BaseListFilter from '@/components/shared/BaseListFilter.vue';
import CopyButton from '@/components/shared/CopyButton.vue';
import DashboardCard from '@/components/shared/dashboard/Card.vue';
import DateTimePicker from '@/components/shared/inputs/DateTimePicker.vue';
import DateRangePicker from '@/components/shared/inputs/DateRangePicker.vue';
import DatePicker from '@/components/shared/inputs/DatePicker.vue';
import FormModal from '@/components/shared/FormModal.vue';
import DownloadButton from '@/components/shared/DownloadButton.vue';
import MetricCard from '@/components/shared/MetricCard.vue';
import PaginationDropdown from '@/components/shared/PaginationDropdown.vue';
import SkeletonTable from '@/components/shared/SkeletonTable.vue';

// Custom filters
import capitalizeFormatFilter from '@/filters/capitalizeFormatFilter';
import dateRangeFormatFilter from '@/filters/dateRangeFormatFilter';
import timeRangeFormatFilter from '@/filters/timeRangeFormatFilter';
import dateFormatFilter from '@/filters/dateFormatFilter';
import dateOrTimeFormatFilter from '@/filters/dateOrTimeFormatFilter';
import dateTimeFormatFilter from '@/filters/dateTimeFormatFilter';
import shortDateTimeFormatFilter from '@/filters/shortDateTimeFormatFilter';
import frequencyFormatFilter from '@/filters/frequencyFormatFilter';
import humanizedFormatFilter from '@/filters/humanizedFormatFilter';
import locationFormatFilter from '@/filters/locationFormatFilter';
import percentFormatFilter from '@/filters/percentFormatFilter';
import moneyFormatFilter from '@/filters/moneyFormatFilter';
import numberFormatFilter from '@/filters/numberFormatFilter';
import timeFormatFilter from '@/filters/timeFormatFilter';
import titlecaseFormatFilter from '@/filters/titlecaseFormatFilter';
import numberWithDelimeterFilter from '@/filters/numberWithDelimeterFilter';

// Constants
import { SET_OUT_OF_DATE_MUTATION } from '@/constants/mutations.js';

const app_version = require('../package.json').version;
// Custom appsignal error handling
const appsignal = new Appsignal({
  key: process.env.VUE_APP_APPSIGNAL_API_KEY,
});

Vue.config.errorHandler = errorHandler(appsignal, Vue);

// Custom axios configuration
axios.defaults.headers.common['Authorization'] = store.getters.token;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-App-Version'] = app_version;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

store.watch(
  (_, getters) => getters.token,
  (newToken) => {
    axios.defaults.headers.common['Authorization'] = newToken;
  }
);

axios.interceptors.response.use(undefined, (error) => {
  if (error && error.response && !error.response.config.url.startsWith('/v1/auth')) {
    if (error.response.status === 401) {
      return router.push({ name: 'logout' });
    }
  }
  if (error && error.response && error.response.status == 412) {
    store.commit(SET_OUT_OF_DATE_MUTATION, { upToDate: false });
  }
  return Promise.reject(error);
});

// Auth API
import authAPI from '@/api/auth';

// Admin APIs
import analyticsAPI from '@/api/admin/analytics';
import clientsAPI from '@/api/admin/clients';
import communicationsAPI from '@/api/admin/communications';
import contactInfosAPI from '@/api/admin/contactInfos';
import customFieldsAPI from '@/api/admin/customFields';
import documentsAPI from '@/api/admin/documents';
import locationsAPI from '@/api/admin/locations';
import notesAPI from '@/api/admin/notes';
import glCodesAPI from '@/api/admin/glCodes';
import importsAPI from '@/api/admin/imports';
import insuranceCertificatesAPI from '@/api/admin/insuranceCertificates';
import insuranceCertificateCoveragesAPI from '@/api/admin/insuranceCertificateCoverages';
import insuranceCertificateVerificationsAPI from '@/api/admin/insuranceCertificateVerifications';
import integrationReferencesAPI from '@/api/admin/integrationReferences';
import invoicesAPI from '@/api/admin/invoices';
import invoiceFormatsAPI from '@/api/admin/invoiceFormats';
import organizationsAPI from '@/api/admin/organizations';
import recurringChargesAPI from '@/api/admin/recurringCharges';
import recurringPaymentsAPI from '@/api/admin/recurringPayments';
import recurringWorkOrdersAPI from '@/api/admin/recurringWorkOrders';
import sectionsAPI from '@/api/admin/sections';
import suppliesAPI from '@/api/admin/supplies';
import supplyOrdersAPI from '@/api/admin/supplyOrders';
import servicesAPI from '@/api/admin/services';
import taxRatesAPI from '@/api/admin/taxRates';
import technicianPaymentsAPI from '@/api/admin/technicianPayments';
import technicianServicesAPI from '@/api/admin/technicianServices';
import userOwnershipsAPI from '@/api/admin/userOwnerships';
import usersAPI from '@/api/admin/users';
import vendorsAPI from '@/api/admin/vendors';
import versionsAPI from '@/api/admin/versions';
import walkthroughsAPI from '@/api/admin/walkthroughs';
import workOrderChargesAPI from '@/api/admin/workOrderCharges';
import additionalChargesAPI from '@/api/admin/additionalCharges';
import workOrdersAPI from '@/api/admin/workOrders';
import delayedJobsAPI from '@/api/admin/delayedJobs';

// Technician APIs
import technicianAnalyticsAPI from '@/api/technician/analytics';
import technicianUserPaymentsAPI from '@/api/technician/technicianPayments';
import technicianWorkOrdersAPI from '@/api/technician/workOrders';
import technicianClientsAPI from '@/api/technician/clients';
import technicianLocationsAPI from '@/api/technician/locations';
import technicianUsersAPI from '@/api/technician/users';

// Register global plugins
Vue.use(abilitiesPlugin, store.getters.ability);
Vue.use(BootstrapVue);
Vue.use(VueApexCharts);
Vue.use(Vuelidate);
Vue.use(VueFlatPickr);
Vue.use(VueSweetalert2, {
  buttonsStyling: false,
  showCancelButton: true,
  showCloseButton: true,
  reverseButtons: true,
  allowEscapeKey: true,
  position: 'top',
  icon: 'warning',
  title: 'Are you sure?',
  confirmButtonText: 'Confirm',
  cancelButtonText: 'Cancel',
  closeButtonHtml: '<i class="fa fa-close" />',
  customClass: {
    confirmButton: 'btn btn-primary ml-4',
    cancelButton: 'btn btn-link-dark mr-2',
    input: 'form-control',
  },
});
Vue.use(VueSidebarMenu);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places,drawing'
  }
});
// Register global components

// Custom components
Vue.component('SfsActiveBadge', ActiveBadge);
Vue.component('SfsCopyButton', CopyButton);
Vue.component('SfsBaseListFilter', BaseListFilter);
Vue.component('SfsBaseTable', BaseTable);
Vue.component(BasePageHeading.name, BasePageHeading);
Vue.component('SfsDashboardCard', DashboardCard);
Vue.component('SfsDateTimePicker', DateTimePicker);
Vue.component('SfsDateRangePicker', DateRangePicker);
Vue.component('SfsDatePicker', DatePicker);
Vue.component('SfsFormModal', FormModal);
Vue.component('SfsDownloadButton', DownloadButton);
Vue.component('SfsMetricCard', MetricCard);
Vue.component('SfsPaginationDropdown', PaginationDropdown);
Vue.component('SfsSkeletonTable', SkeletonTable);
Vue.component('GoogleMap', VueGoogleMaps.Map);
Vue.component('GoogleMarker', VueGoogleMaps.Marker);
Vue.component('GoogleInfoWindow', VueGoogleMaps.InfoWindow);


// Custom library components
Vue.component('VSelect', vSelect);
Vue.component('MoneyFormat', MoneyFormat);
Vue.component('VueDropzone', vue2Dropzone);
Vue.component('ApexChart', VueApexCharts);

// Register global instance variables

// Auth API
Vue.prototype.$authAPI = authAPI;

// Admin APIs
Vue.prototype.$analyticsAPI = analyticsAPI;
Vue.prototype.$clientsAPI = clientsAPI;
Vue.prototype.$communicationsAPI = communicationsAPI;
Vue.prototype.$contactInfosAPI = contactInfosAPI;
Vue.prototype.$customFieldsAPI = customFieldsAPI;
Vue.prototype.$documentsAPI = documentsAPI;
Vue.prototype.$locationsAPI = locationsAPI;
Vue.prototype.$notesAPI = notesAPI;
Vue.prototype.$glCodesAPI = glCodesAPI;
Vue.prototype.$importsAPI = importsAPI;
Vue.prototype.$insuranceCertificateCoveragesAPI = insuranceCertificateCoveragesAPI;
Vue.prototype.$insuranceCertificateVerificationsAPI = insuranceCertificateVerificationsAPI;
Vue.prototype.$insuranceCertificatesAPI = insuranceCertificatesAPI;
Vue.prototype.$invoicesAPI = invoicesAPI;
Vue.prototype.$integrationReferencesAPI = integrationReferencesAPI;
Vue.prototype.$invoiceFormatsAPI = invoiceFormatsAPI;
Vue.prototype.$organizationsAPI = organizationsAPI;
Vue.prototype.$recurringChargesAPI = recurringChargesAPI;
Vue.prototype.$recurringPaymentsAPI = recurringPaymentsAPI;
Vue.prototype.$recurringWorkOrdersAPI = recurringWorkOrdersAPI;
Vue.prototype.$sectionsAPI = sectionsAPI;
Vue.prototype.$servicesAPI = servicesAPI;
Vue.prototype.$suppliesAPI = suppliesAPI;
Vue.prototype.$supplyOrdersAPI = supplyOrdersAPI;
Vue.prototype.$taxRatesAPI = taxRatesAPI;
Vue.prototype.$technicianPaymentsAPI = technicianPaymentsAPI;
Vue.prototype.$technicianServicesAPI = technicianServicesAPI;
Vue.prototype.$userOwnershipsAPI = userOwnershipsAPI;
Vue.prototype.$usersAPI = usersAPI;
Vue.prototype.$vendorsAPI = vendorsAPI;
Vue.prototype.$versionsAPI = versionsAPI;
Vue.prototype.$walkthroughsAPI = walkthroughsAPI;
Vue.prototype.$workOrdersAPI = workOrdersAPI;
Vue.prototype.$workOrderChargesAPI = workOrderChargesAPI;
Vue.prototype.$delayedJobsAPI = delayedJobsAPI;
Vue.prototype.$additionalChargesAPI = additionalChargesAPI;
Vue.prototype.$appVersion = app_version;

// Technician APIs
Vue.prototype.$technicianAnalyticsAPI = technicianAnalyticsAPI;
Vue.prototype.$technicianUserPaymentsAPI = technicianUserPaymentsAPI;
Vue.prototype.$technicianWorkOrdersAPI = technicianWorkOrdersAPI;
Vue.prototype.$technicianClientsAPI = technicianClientsAPI;
Vue.prototype.$technicianLocationsAPI = technicianLocationsAPI;
Vue.prototype.$technicianUsersAPI = technicianUsersAPI;

// Register global filters
Vue.filter('capitalizeFormat', capitalizeFormatFilter);
Vue.filter('dateRangeFormat', dateRangeFormatFilter);
Vue.filter('timeRangeFormat', timeRangeFormatFilter);
Vue.filter('dateFormat', dateFormatFilter);
Vue.filter('dateTimeFormat', dateTimeFormatFilter);
Vue.filter('dateOrTimeFormat', dateOrTimeFormatFilter);
Vue.filter('shortDateTimeFormat', shortDateTimeFormatFilter);
Vue.filter('frequencyFormat', frequencyFormatFilter);
Vue.filter('humanizedFormat', humanizedFormatFilter);
Vue.filter('locationFormat', locationFormatFilter);
Vue.filter('percentFormatFilter', percentFormatFilter);
Vue.filter('moneyFormatFilter', moneyFormatFilter);
Vue.filter('numberFormatFilter', numberFormatFilter);
Vue.filter('timeFormat', timeFormatFilter);
Vue.filter('titlecaseFormat', titlecaseFormatFilter);
Vue.filter('numberWithDelimeter', numberWithDelimeterFilter);
// Disable tip shown in dev console when in development mode
Vue.config.productionTip = false;

// Craft new application
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
