<template>
  <b-form
    @submit.stop.prevent="handleSubmit"
    autocomplete="off"
  >
    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.first_name"
          label-for="user-first_name"
        >
          <b-form-input
            id="user-first_name"
            v-model="$v.form.first_name.$model"
            :state="$v.form.first_name.$dirty ? !$v.form.first_name.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.first_name.$dirty"
            id="user-first_name-feedback"
          >
            <span v-if="!$v.form.first_name.required">Please enter a first name.</span>
            <span v-if="!$v.form.first_name.maxLength">
              The first name must be less than {{ $v.form.first_name.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.first_name.serverFailed">{{ serverErrors.first_name }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.last_name"
          label-for="user-last_name"
        >
          <b-form-input
            id="user-last_name"
            v-model="$v.form.last_name.$model"
            :state="$v.form.last_name.$dirty ? !$v.form.last_name.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.last_name.$dirty"
            id="user-last_name-feedback"
          >
            <span v-if="!$v.form.last_name.required">Please enter a last name.</span>
            <span v-if="!$v.form.last_name.maxLength">
              The last name must be less than {{ $v.form.last_name.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.last_name.serverFailed">{{ serverErrors.last_name }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.email"
          label-for="user-email"
        >
          <b-form-input
            id="user-email"
            v-model="$v.form.email.$model"
            autocomplete="new-password"
            :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
            type="text"
            placeholder="example@email.com"
          />
          <b-form-invalid-feedback
            v-if="$v.form.email.$dirty"
            id="user-email-feedback"
          >
            <span v-if="!$v.form.email.required">Please enter a email.</span>
            <span v-if="!$v.form.email.email">Please enter a valid email.</span>
            <span v-if="!$v.form.email.maxLength">
              The email must be less than {{ $v.form.email.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.email.serverFailed">{{ serverErrors.email }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.phone"
          label-for="user-phone"
        >
          <b-form-input
            id="user-phone"
            v-model="$v.form.phone.$model"
            :state="$v.form.phone.$dirty ? !$v.form.phone.$error : null"
            type="text"
            placeholder="(201) 555-5555"
          />
          <b-form-invalid-feedback
            v-if="$v.form.phone.$dirty"
            id="user-phone-feedback"
          >
            <span v-if="!$v.form.phone.required">Please enter a phone.</span>
            <span v-if="!$v.form.phone.maxLength">
              The phone must be less than {{ $v.form.phone.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.phone.serverFailed">{{ serverErrors.phone }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      v-if="newForm"
    >
      <b-row>
        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.password"
            label-for="user-password"
          >
            <b-input-group>
              <b-form-input
                id="user-password"
                v-model="$v.form.password.$model"
                autocomplete="new-password"
                :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                :type="togglePassword1 ? 'text' : 'password'"
              />
              <b-input-group-append>
                <b-button
                  class="rounded-right"
                  variant="light"
                  @click="() => (togglePassword1 = !togglePassword1)"
                >
                  <i
                    v-if="togglePassword1"
                    class="fa-regular fa-eye"
                  />
                  <i
                    v-else
                    class="fa-regular fa-eye-slash"
                  />
                </b-button>
              </b-input-group-append>

              <b-form-invalid-feedback
                v-if="$v.form.password.$dirty"
                id="user-password-feedback"
              >
                <span v-if="!$v.form.password.required">Please enter a password.</span>
                <span v-if="!$v.form.password.minLength">
                  The password must be at least {{ $v.form.password.$params.minLength.min }} characters long.
                </span>
                <span v-if="!$v.form.password.serverFailed">{{ serverErrors.password }}</span>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.password_confirmation"
            label-for="user-password_confirmation"
          >
            <b-input-group>
              <b-form-input
                id="user-password_confirmation"
                autocomplete="new-password"
                v-model="$v.form.password_confirmation.$model"
                :state="$v.form.password_confirmation.$dirty ? !$v.form.password_confirmation.$error : null"
                :type="togglePassword2 ? 'text' : 'password'"
              />
              <b-input-group-append>
                <b-button
                  class="rounded-right"
                  variant="light"
                  @click="() => (togglePassword2 = !togglePassword2)"
                >
                  <i
                    v-if="togglePassword2"
                    class="fa-regular fa-eye"
                  />
                  <i
                    v-else
                    class="fa-regular fa-eye-slash"
                  />
                </b-button>
              </b-input-group-append>

              <b-form-invalid-feedback
                v-if="$v.form.password_confirmation.$dirty"
                id="user-password_confirmation-feedback"
              >
                <span v-if="!$v.form.password_confirmation.sameAs">Must match the password.</span>
                <span v-if="!$v.form.password_confirmation.serverFailed">
                  {{ serverErrors.password_confirmation }}
                </span>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.address"
          label-for="user-address"
        >
          <b-form-input
            id="user-address"
            v-model="$v.form.address.$model"
            :state="$v.form.address.$dirty ? !$v.form.address.$error : null"
            type="text"
            placeholder="123 Fake Street"
          />
          <b-form-invalid-feedback
            v-if="$v.form.address.$dirty"
            id="user-address-feedback"
          >
            <span v-if="!$v.form.address.maxLength">
              The address must be less than {{ $v.form.address.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.address.serverFailed">{{ serverErrors.address }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.city"
          label-for="user-city"
        >
          <b-form-input
            id="user-city"
            v-model="$v.form.city.$model"
            :state="$v.form.city.$dirty ? !$v.form.city.$error : null"
            type="text"
            placeholder="New York"
          />
          <b-form-invalid-feedback
            v-if="$v.form.city.$dirty"
            id="user-city-feedback"
          >
            <span v-if="!$v.form.city.maxLength">
              The city must be less than {{ $v.form.city.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.city.serverFailed">{{ serverErrors.city }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.state"
          label-for="user-state"
        >
          <b-form-input
            id="user-state"
            v-model="$v.form.state.$model"
            :state="$v.form.state.$dirty ? !$v.form.state.$error : null"
            type="text"
            placeholder="NY"
          />
          <b-form-invalid-feedback
            v-if="$v.form.state.$dirty"
            id="user-state-feedback"
          >
            <span v-if="!$v.form.state.maxLength">
              The state must be less than {{ $v.form.state.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.state.serverFailed">{{ serverErrors.state }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.zip_code"
          label-for="user-zip_code"
        >
          <b-form-input
            id="user-zip_code"
            v-model="$v.form.zip_code.$model"
            :state="$v.form.zip_code.$dirty ? !$v.form.zip_code.$error : null"
            type="text"
            placeholder="55555"
          />
          <b-form-invalid-feedback
            v-if="$v.form.zip_code.$dirty"
            id="user-zip_code-feedback"
          >
            <span v-if="!$v.form.zip_code.maxLength">
              The zip code must be less than {{ $v.form.zip_code.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.zip_code.serverFailed">{{ serverErrors.zip_code }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <h3 class="mb-2">
      Settings
    </h3>
    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.locale"
          label-for="user-locale"
        >
          <locale-select
            id="user-locale"
            v-model="$v.form.locale.$model"
            :select-class="{ 'is-invalid': $v.form.locale.$dirty && $v.form.locale.$error }"
            :state="$v.form.locale.$dirty ? !$v.form.locale.$error : null"
          />
          <b-form-invalid-feedback
            v-if="$v.form.locale.$dirty"
            id="user-locale-feedback"
          >
            <span v-if="!$v.form.locale.required">Please enter a locale.</span>
            <span v-if="!$v.form.locale.serverFailed">{{ serverErrors.locale }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.time_zone"
          label-for="user-time_zone"
        >
          <time-zone-select
            id="user-time_zone"
            v-model="$v.form.time_zone.$model"
            :select-class="{ 'is-invalid': $v.form.time_zone.$dirty && $v.form.time_zone.$error }"
            :state="$v.form.time_zone.$dirty ? !$v.form.time_zone.$error : null"
          />
          <b-form-invalid-feedback
            v-if="$v.form.time_zone.$dirty"
            id="user-time_zone-feedback"
          >
            <span v-if="!$v.form.time_zone.required">Please enter a time zone.</span>
            <span v-if="!$v.form.time_zone.serverFailed">{{ serverErrors.time_zone }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="8"
      >
        <b-form-group label-for="user-parent_work_order_access">
          <b-form-checkbox
            switch
            id="user-parent_work_order_access"
            v-model="$v.form.parent_work_order_access.$model"
          >
            Allow access to all of my Work Orders
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          id="user-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          id="user-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, requiredIf, maxLength, email, minLength, sameAs } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import LocaleSelect from '@/components/shared/users/LocaleSelect.vue';
import TimeZoneSelect from '@/components/shared/users/TimeZoneSelect.vue';

import { USER_FORM_LABELS } from '@/constants/users';

export default {
  name: 'TechnicianUsersForm',
  components: {
    LocaleSelect,
    TimeZoneSelect,
  },
  mixins: [formMixin],
  props: {
    newForm: Boolean,
    first_name: String,
    last_name: String,
    phone: String,
    email: String,
    address: String,
    city: String,
    state: String,
    zip_code: String,
    password: String,
    password_confirmation: String,
    manager_id: [String, Number],
    manager: Object,
    locale: String,
    time_zone: String,
    parent_work_order_access: Boolean,
  },
  data() {
    return {
      form: {
        newForm: this.newForm,
        first_name: this.first_name,
        last_name: this.last_name,
        phone: this.phone,
        email: this.email,
        address: this.address,
        city: this.city,
        state: this.state,
        zip_code: this.zip_code,
        password: this.password,
        password_confirmation: this.password_confirmation,
        manager_id: this.manager_id,
        manager: this.manager,
        locale: this.locale,
        time_zone: this.time_zone,
        parent_work_order_access: this.parent_work_order_access,
      },
      fieldLabels: {
        ...USER_FORM_LABELS,
      },
      togglePassword1: false,
      togglePassword2: false,
    };
  },
  validations: {
    form: {
      first_name: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('first_name');
        },
      },
      last_name: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('last_name');
        },
      },
      email: {
        required,
        email,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('email');
        },
      },
      phone: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('phone');
        },
      },
      address: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('address');
        },
      },
      city: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('city');
        },
      },
      state: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('state');
        },
      },
      zip_code: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('zip_code');
        },
      },
      locale: {
        serverFailed() {
          return !this.hasServerErrors('locale');
        },
      },
      time_zone: {
        serverFailed() {
          return !this.hasServerErrors('time_zone');
        },
      },
      password: {
        required: requiredIf(function () {
          return this.newForm;
        }),
        minLength: minLength(8),
        serverFailed() {
          return !this.hasServerErrors('password');
        },
      },
      password_confirmation: {
        sameAs: sameAs('password'),
        serverFailed() {
          return !this.hasServerErrors('password_confirmation');
        },
      },
      manager_id: {
        serverFailed() {
          return !this.hasServerErrors('manager_id');
        },
      },
      parent_work_order_access: {},
    },
  },
  methods: {
    onManagerChange(user) {
      this.form.manager_id = user ? user.id : '';
      this.form.manager = user;
    },
  },
};
</script>
