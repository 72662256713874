import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const USER_MODEL_NAME = 'users';
export const USER_TABLE_NAME = 'users';

// User form label constants.
export const USER_CATEGORY_LABEL = 'Role';
export const USER_ROLE_LABEL = 'Permissions';
export const USER_NAME_LABEL = 'Name';
export const USER_FIRST_NAME_LABEL = 'First Name';
export const USER_LAST_NAME_LABEL = 'Last Name';
export const USER_PHONE_LABEL = 'Phone';
export const USER_EMAIL_LABEL = 'Email';
export const USER_ADDRESS_LABEL = 'Address';
export const USER_CITY_LABEL = 'City';
export const USER_STATE_LABEL = 'State';
export const USER_ZIP_CODE_LABEL = 'Zip Code';
export const USER_CURRENT_PASSWORD_LABEL = 'Current Password';
export const USER_PASSWORD_LABEL = 'Password';
export const USER_CONFIRM_PASSWORD_LABEL = 'Confirm Password';
export const USER_ORGANIZATION_LABEL = 'Organization';
export const USER_MANAGER_LABEL = 'Manager';
export const USER_ACTIVE_LABEL = 'Active';
export const USER_QR_CODE_ACCESS_LABEL = 'QR Code Access';
export const USER_PARENT_WORK_ORDER_ACCESS_LABEL = 'Work Order Access';
export const USER_TECHNICIAN_PORTAL_ACCESS_LABEL = 'Technician Portal Access';
export const USER_LOCALE_LABEL = 'Language Preference';
export const USER_TIME_ZONE_LABEL = 'Time Zone';
export const USER_CLIENT_LABEL = 'Client';
export const USER_QUICK_BOOKS_VENDOR_ID = 'Quickbooks Vendor ID';
export const USER_ORGANIZATION_ID_LABEL = 'Organization';
export const USER_SERVICES_PROVIDED_LABEL = 'Services Provided';

export const USER_FORM_LABELS = {
  roles: USER_ROLE_LABEL,
  role_ids: USER_ROLE_LABEL,
  category: USER_CATEGORY_LABEL,
  first_name: USER_FIRST_NAME_LABEL,
  last_name: USER_LAST_NAME_LABEL,
  phone: USER_PHONE_LABEL,
  email: USER_EMAIL_LABEL,
  address: USER_ADDRESS_LABEL,
  city: USER_CITY_LABEL,
  state: USER_STATE_LABEL,
  zip_code: USER_ZIP_CODE_LABEL,
  current_password: USER_CURRENT_PASSWORD_LABEL,
  password: USER_PASSWORD_LABEL,
  password_confirmation: USER_CONFIRM_PASSWORD_LABEL,
  organization_id: USER_ORGANIZATION_LABEL,
  manager_id: USER_MANAGER_LABEL,
  locale: USER_LOCALE_LABEL,
  time_zone: USER_TIME_ZONE_LABEL,
  quick_books_vendor_id: USER_QUICK_BOOKS_VENDOR_ID,
  services_provided: USER_SERVICES_PROVIDED_LABEL,
};

// The columns available to be shown in a users table.
export const USER_TABLE_COLUMNS = {
  role: { label: 'Permissions', key: 'role', sortable: true },
  category: { label: USER_CATEGORY_LABEL, key: 'category', sortable: true },
  name: { label: USER_NAME_LABEL, key: 'name', sortable: true },
  phone: { label: USER_PHONE_LABEL, key: 'phone' },
  email: { label: USER_EMAIL_LABEL, key: 'email', sortable: true },
  address: { label: USER_ADDRESS_LABEL, key: 'address' },
  city: { label: USER_CITY_LABEL, key: 'city' },
  state: { label: USER_STATE_LABEL, key: 'state' },
  zip_code: { label: USER_ZIP_CODE_LABEL, key: 'zip_code' },
  manager_id: { label: USER_MANAGER_LABEL, key: 'manager_id' },
  deleted_at: { label: USER_ACTIVE_LABEL, key: 'active' },
  client_id: { label: USER_CLIENT_LABEL, key: 'client_id' },
  role_ids: { label: USER_ROLE_LABEL, key: 'role_ids', filterOnly: true },
  qr_code_access: { label: USER_QR_CODE_ACCESS_LABEL, key: 'qr_code_access' },
  technician_portal_access: { label: USER_TECHNICIAN_PORTAL_ACCESS_LABEL, key: 'technician_portal_access', filterOnly: true },
  parent_work_order_access: { label: USER_PARENT_WORK_ORDER_ACCESS_LABEL, key: 'parent_work_order_access' },
  services_provided: { label: USER_SERVICES_PROVIDED_LABEL, key: 'services_provided' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const USER_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(USER_TABLE_COLUMNS);

// The default columns to be shown when the users table loads.
export const USER_TABLE_DEFAULT_COLUMNS = [
  USER_TABLE_COLUMNS.name,
  USER_TABLE_COLUMNS.email,
  USER_TABLE_COLUMNS.phone,
  USER_TABLE_COLUMNS.category,
  USER_TABLE_COLUMNS.role,
];

export const USER_ADMIN_CATEGORY = 'admin';
export const USER_EMPLOYEE_CATEGORY = 'employee';
export const USER_TECHNICIAN_CATEGORY = 'technician';
export const USER_SUB_TECHNICIAN_CATEGORY = 'sub_technician';

export const USER_CATEGORIES = {
  [USER_ADMIN_CATEGORY]: 'A SCOPES employee with the highest permissions',
  [USER_EMPLOYEE_CATEGORY]: 'A SCOPES regular employee',
  [USER_TECHNICIAN_CATEGORY]: 'Provides services for SCOPES',
  [USER_SUB_TECHNICIAN_CATEGORY]: 'Works for a technician',
};

export const USER_TABLE_FILTER_OPTIONS = [
  { text: 'Yes', value: 'true' },
  { text: 'No', value: 'false' },
  { text: 'Both', value: null },
];

export const USER_TABLE_PORTAL_OPTIONS = [
  { text: 'Yes', value: 'true' },
  { text: 'No', value: 'false' },
  { text: 'Both', value: null },
];

// TECHNICIAN PROFILE CONSTANTS

export const TECHNICIAN_USER_TABLE_NAME = 'technicianUsers';

// The columns available to be shown in a users table.
export const TECHNICIAN_USER_TABLE_COLUMNS = {
  name: { label: USER_NAME_LABEL, key: 'name', sortable: true },
  phone: { label: USER_PHONE_LABEL, key: 'phone' },
  email: { label: USER_EMAIL_LABEL, key: 'email', sortable: true },
  address: { label: USER_ADDRESS_LABEL, key: 'address' },
  city: { label: USER_CITY_LABEL, key: 'city' },
  state: { label: USER_STATE_LABEL, key: 'state' },
  zip_code: { label: USER_ZIP_CODE_LABEL, key: 'zip_code' },
  parent_work_order_access: { label: 'All Work Order Access', key: 'parent_work_order_access' },
  deleted_at: { label: USER_ACTIVE_LABEL, key: 'active' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const TECHNICIAN_USER_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(TECHNICIAN_USER_TABLE_COLUMNS);

// The default columns to be shown when the technician users table loads.
export const TECHNICIAN_USER_TABLE_DEFAULT_COLUMNS = [
  TECHNICIAN_USER_TABLE_COLUMNS.name,
  TECHNICIAN_USER_TABLE_COLUMNS.email,
  TECHNICIAN_USER_TABLE_COLUMNS.phone,
  TECHNICIAN_USER_TABLE_COLUMNS.address,
  TECHNICIAN_USER_TABLE_COLUMNS.parent_work_order_access,
];
