<template>
  <div>
    <base-page-heading
      title="Audit Log"
      show-back-button
    >
      <template #extra>
        <sfs-download-button
          v-b-tooltip.hover
          :provider-params="filters"
          filename="Audit Log.csv"
          :file-provider="$versionsAPI.getSpreadsheet"
          title="Download spreadsheet"
          label="Download"
        />
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <versions-list-filter
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>

      <versions-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import VersionsTable from '@/components/admin/versions/Table.vue';
import VersionsListFilter from '@/components/admin/versions/ListFilter.vue';
import listMixin from '@/mixins/listMixin';
import { VERSION_TABLE_NAME } from '@/constants/versions';

export default {
  name: 'ClientsList',
  components: {
    VersionsListFilter,
    VersionsTable,
  },
  mixins: [listMixin],
  props: {
    model: String,
    model_id: String,
    event: String,
    user_id: String,
    start_date: String,
    end_date: String,
  },
  data() {
    const isSpecificRecord = this.model && this.model_id;
    const startDate = this.start_date ? this.start_date : (isSpecificRecord ? '' : this.$options.filters.dateFormat(DateTime.now().minus({ days: 1 }).toISODate()));
    const endDate = this.end_date ? this.end_date : (isSpecificRecord ? '' : this.$options.filters.dateFormat(DateTime.now().toISODate()));

    return {
      tableName: VERSION_TABLE_NAME,
      filters: {
        model: this.model,
        model_id: this.model_id,
        event: this.event,
        user_id: this.user_id,
        start_date: startDate,
        end_date: endDate,
      },
    };
  },
};
</script>
