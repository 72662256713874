import axios from 'axios';

export default function fileFormDataSubmission(data, path) {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  return axios.post(path, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
