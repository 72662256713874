<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$userOwnershipsAPI.getUserOwnerships"
    provider-key="userOwnerships"
    :columns="defaultColumns"
    :filters="{...filters, ...defaultFilters }"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(sub_technician_name)="data">
      <b-link :to="{ name: 'user', params: { id: data.item.sub_technician.id } }">
        {{ data.item.sub_technician.name }}
      </b-link>
    </template>
    <template #cell(sub_technician_phone)="data">
      {{ data.item.sub_technician.phone }}
    </template>
    <template #cell(sub_technician_email)="data">
      <b-link :href="`mailto:${data.item.sub_technician.email}`">
        {{ data.item.sub_technician.email }}
      </b-link>
    </template>
    <template #cell(sub_technician_active)="data">
      <b-badge
        v-if="!data.item.sub_technician.deleted_at"
        class="font-size-sm"
        variant="success"
      >
        Active
      </b-badge>
      <b-badge
        v-else
        class="font-size-sm"
        variant="danger"
      >
        Inactive
      </b-badge>
    </template>
    <template #cell(technician_name)="data">
      <b-link :to="{ name: 'user', params: { id: data.item.technician.id } }">
        {{ data.item.technician.name }}
      </b-link>
    </template>
    <template #cell(technician_phone)="data">
      {{ data.item.technician.phone }}
    </template>
    <template #cell(technician_email)="data">
      <b-link :href="`mailto:${data.item.technician.email}`">
        {{ data.item.technician.email }}
      </b-link>
    </template>
    <template #cell(technician_active)="data">
      <b-badge
        v-if="!data.item.technician.deleted_at"
        class="font-size-sm"
        variant="success"
      >
        Active
      </b-badge>
      <b-badge
        v-else
        class="font-size-sm"
        variant="danger"
      >
        Inactive
      </b-badge>
    </template>
    <template #cell(actions)="data">
      <b-button
        v-if="$can('destroy', 'UserOwnership')"
        size="sm"
        variant="link"
        @click="destroyUserOwnershipDialog(data.item.id)"
      >
        <i class="fa fa-fw fa-times" />
      </b-button>
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import {
  USER_OWNERSHIPS_TABLE_DEFAULT_SUB_TECHNICIAN_COLUMNS,
  USER_OWNERSHIPS_TABLE_DEFAULT_COLUMNS,
} from '@/constants/userOwnerships';
import { USER_SUB_TECHNICIAN_CATEGORY } from '@/constants/users';

export default {
  name: 'UserOwnershipsTable',
  mixins: [tablePropsMixin],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isSubTechnician() {
      return this.user.category == USER_SUB_TECHNICIAN_CATEGORY;
    },
    defaultColumns() {
      return this.isSubTechnician
        ? USER_OWNERSHIPS_TABLE_DEFAULT_SUB_TECHNICIAN_COLUMNS
        : USER_OWNERSHIPS_TABLE_DEFAULT_COLUMNS;
    },
    defaultFilters() {
      return this.isSubTechnician ? { sub_technician_id: this.user.id } : { technician_id: this.user.id };
    },
  },
  methods: {
    destroyUserOwnershipDialog(id) {
      this.$swal({
        text: 'This user will no longer be managed by the technician!',
        preConfirm: () => this.$userOwnershipsAPI.destroy(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
  },
};
</script>
