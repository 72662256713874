<template>
  <sfs-form-modal
    :id="itemModalId"
    title="Move Note"
    size="lg"
  >
    <b-form @submit.stop.prevent="handleSubmit">
      <b-form-radio-group
        v-model="selectedSectionId"
        stacked
        size="lg"
        name="move-walkthrough-item-options"
      >
        <b-row>
          <b-col>
            <b-form-radio
              v-for="section in sections"
              :key="`${itemModalId}-${section.id}`"
              :value="section.id"
              :class="`nav-depth-${section.depth}-lg mb-2`"
            >
              {{ section.name }}
            </b-form-radio>
          </b-col>
        </b-row>
      </b-form-radio-group>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            class="move-walkthrough-item-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            block
            class="move-walkthrough-item-submit-button"
            type="submit"
            variant="primary"
          >
            Move
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'MoveItemModal',
  mixins: [formModalMixin],
  props: {
    value: {
      type: Object,
      required: true,
    },
    sections: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      selectedSectionId: this.value.section_id || null,
    };
  },
  methods: {
    handleSubmit() {
        this.$walkthroughsAPI
        .updateItem(this.value.id, { section_id: this.selectedSectionId })
        .then((walkthroughItem) => {
          this.$emit('on-save', walkthroughItem);
          this.closeModal();
        });
    },
    closeModal() {
      this.$bvModal.hide(this.itemModalId);
    },
  },
  computed: {
    itemModalId() {
      return `move-walkthrough-item-${this.value.id}-modal`;
    },
  },
};
</script>
