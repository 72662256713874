<template>
  <b-link
    class="font-size-sm"
    :to="{ name: path, params: { id: reference.id } }"
  >
    {{ label }}
  </b-link>
</template>

<script>

export default {
  name: 'TimelineItemReference',
  props: {
    referenceType: {
      type: String,
      required: true,
    },
    reference: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      referenceOptions: {
        'WorkOrder': {
          name: '',
          path: 'work-order',
          labelKey: 'number',
        },
        'Location': {
          name: 'Location',
          path: 'location',
          labelKey: 'store_number',
        },
        'InsuranceCertificate': {
          name: 'Insurance Certificate',
          path: 'insurance-certificate',
          labelKey: 'policy_number',
        },
      },
    };
  },
  computed: {
    name() {
      return this.referenceOptions[this.referenceType]?.name;
    },
    label() {
      return this.reference[this.referenceOptions[this.referenceType]?.labelKey];
    },
    path() {
      return this.referenceOptions[this.referenceType]?.path;
    },
  },
};
</script>
