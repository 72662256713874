import Vue from 'vue';
import store from '@/store';
import { SET_OUT_OF_DATE_POPUP_RENDERED } from '@/constants/mutations.js';

export default function showAppOutOfDatePopup() {
  store.commit(SET_OUT_OF_DATE_POPUP_RENDERED, { upToDatePopupRendered: true });
  Vue.swal({
    title: 'App Has Been Updated',
    text: 'Please reload to get the latest app version',
    icon: 'info',
    allowOutsideClick: false,
    confirmButtonText: 'Reload',
    position: 'top',
  }).then((result) => {
    if (result.value) {
      window.history.go();
    }
    result.timer;
  });
}
