<template>
  <sfs-base-table
    :class="tableClasses"
    :data-provider="listProvider"
    :data-id="referenceId"
    provider-key="communications"
    :columns="columns"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
  >
    <template #cell(sent_at)="data">
      {{ data.item.sent_at | dateTimeFormat }}
    </template>
    <template #cell(category)="data">
      <b-badge variant="secondary">
        {{ data.item.category | humanizedFormat | capitalizeFormat }}
      </b-badge>
    </template>
    <template #cell(read_at)="data">
      <span v-if="data.item.read_at">{{ data.item.read_at | dateTimeFormat }}</span>
    </template>
    <template #cell(details)="data">
      <b-button
        href="#"
        tabindex="0"
        :id="`communications-${data.item.id}-popover`"
        size="sm"
        variant="link"
      >
        <i class="fa fa-eye" />
      </b-button>
      <communications-item-popover v-model="data.item" />
    </template>
  </sfs-base-table>
</template>

<script>
import tablePropsMixin from '@/mixins/tablePropsMixin';
import { COMMUNICATION_TABLE_DEFAULT_COLUMNS } from '@/constants/communications';
import CommunicationsItemPopover from '@/components/admin/communications/ItemPopover';

export default {
  name: 'CommunicationsTable',
  mixins: [tablePropsMixin],
  components: {
    CommunicationsItemPopover,
  },
  props: {
    referenceId: {
      type: Number,
      required: true,
    },
    listProvider: {
      type: Function,
      required: true,
    },
    columns: {
      type: Array,
      default: () => COMMUNICATION_TABLE_DEFAULT_COLUMNS,
    },
    tableClasses: {
      type: Object,
      default: () => new Object({ 'border-bottom': true }),
    },
  },
  methods: {
    formatRecipients(recipients) {
      return recipients.join(', ');
    },
  },
};
</script>
