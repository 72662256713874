<template>
  <b-form
    @submit.stop.prevent="handleSubmit"
    class="text-left"
  >
    <b-row>
      <b-col
        cols="12"
      >
        <b-form-group
          :label="fieldLabels.service"
          label-for="additional-charge-service"
        >
          <service-select
            id="additional-charge-service"
            :client-id="client_id"
            client-unassigned
            :value="form.service"
            @input="onServiceChange"
            :select-class="{ 'is-invalid': $v.form.service_id.$dirty && $v.form.service_id.$error, 'mb-1': true }"
            placeholder="Optional"
          />
          <b-form-invalid-feedback
            v-if="$v.form.service_id.$dirty"
            id="additional-charge-service-feedback"
          >
            <span v-if="!$v.form.service_id.serverFailed">
              {{ serverErrors.service_id }}
            </span>
          </b-form-invalid-feedback>
          <b-form-textarea
            id="additional-charge-description"
            v-model="$v.form.description.$model"
            placeholder="Enter a Description"
            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
            rows="4"
          />
          <b-form-invalid-feedback
            v-if="$v.form.description.$dirty"
            id="additional-charge-description-feedback"
          >
            <span v-if="!$v.form.description.required">Please enter a description.</span>
            <span v-if="!$v.form.description.maxLength">
              The description must be less than {{ $v.form.description.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.description.serverFailed">{{ serverErrors.description }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          :label="fieldLabels.category"
          label-for="additional-charge-category"
        >
          <category-select
            id="additional-charge-category"
            v-model="form.category"
            :select-class="{ 'is-invalid': $v.form.category.$dirty && $v.form.category.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.category.$dirty"
            id="additional-charge-category-feedback"
          >
            <span v-if="!$v.form.category.required">Please enter a category.</span>
            <span v-if="!$v.form.category.serverFailed">{{ serverErrors.category }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.cost"
          label-for="additional-charge-cost"
        >
          <b-input-group
            prepend="$"
            :class="{ 'is-invalid': $v.form.cost.$dirty && $v.form.cost.$error, 'has-warning': this.negativeProfitability }"
          >
            <b-form-input
              name="additional-charge-cost"
              v-model="$v.form.cost.$model"
              :state="$v.form.cost.$dirty ? !$v.form.cost.$error : null"
              type="text"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.cost.$dirty"
            :state="$v.form.cost.$dirty ? !$v.form.cost.$error : null"
            class="additional-charge-cost-feedback"
          >
            <span v-if="!$v.form.cost.required">Please enter a cost.</span>
            <span v-if="!$v.form.cost.decimal">Please enter a number.</span>
            <span v-if="!$v.form.cost.serverFailed">
              {{ serverErrors.cost_cents }}
            </span>
          </b-form-invalid-feedback>
          <div class="warning-feedback">
            <span v-if="negativeProfitability">Warning: cost is more than the price.</span>
          </div>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group
          :label="fieldLabels.price"
          label-for="additional-charge-price"
        >
          <b-input-group
            prepend="$"
            :class="{ 'is-invalid': $v.form.price.$dirty && $v.form.price.$error }"
          >
            <b-form-input
              name="additional-charge-price"
              v-model="$v.form.price.$model"
              :state="$v.form.price.$dirty ? !$v.form.price.$error : null"
              type="text"
            />
          </b-input-group>
          <b-form-invalid-feedback
            v-if="$v.form.price.$dirty"
            :state="$v.form.price.$dirty ? !$v.form.price.$error : null"
            class="additional-charge-price-feedback"
          >
            <span v-if="!$v.form.price.required">Please enter a price.</span>
            <span v-if="!$v.form.price.decimal">Please enter a number.</span>
            <span v-if="!$v.form.price.serverFailed">
              {{ serverErrors.price_cents }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          class="additional-charge-close-button"
          block
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          block
          class="additional-charge-submit-button"
          type="submit"
          variant="primary"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength, decimal } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import ServiceSelect from '@/components/admin/services/Select.vue';
import CategorySelect from '@/components/admin/workOrderCharges/CategoryButtonSelect.vue';

export default {
  name: 'AdditionalChargesForm',
  mixins: [formMixin],
  components: {
    CategorySelect,
    ServiceSelect
  },
  props: {
    description: String,
    category: String,
    cost: Object,
    price: Object,
    service: Object,
    service_id: Number,
    client_id: Number,
  },
  data() {
    return {
      form: {
        description: this.description,
        category: this.category,
        cost: this.cost && this.cost.amount ? this.cost.amount / 100 : 0,
        cost_cents: this.cost && this.cost.amount ? this.cost.amount : 0,
        price: this.price && this.price.amount ? this.price.amount / 100 : 0,
        price_cents: this.price && this.price.amount ? this.price.amount : 0,
        service: this.service,
        service_id: this.service_id,
      },
      fieldLabels: {
        description: 'Description',
        category: 'Category',
        cost: this.$store.getters.translate('work_orders.cost', 'Cost'),
        price: this.$store.getters.translate('work_orders.price', 'Price'),
        cost_cents: this.$store.getters.translate('work_orders.cost', 'Cost'),
        price_cents: this.$store.getters.translate('work_orders.price', 'Price'),
        service: 'Service',
        service_id: 'Service',
      },
    };
  },
  validations: {
    form: {
      description: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('description');
        },
      },
      category: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('category');
        },
      },
      cost: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('cost_cents');
        },
      },
      price: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('price_cents');
        },
      },
      service_id: {
        serverFailed() {
          return !this.hasServerErrors('service_id');
        },
      },
    },
  },
  computed: {
    negativeProfitability() {
      return this.form.cost_cents > this.form.price_cents;
    },
  },
  watch: {
    'form.cost': function (newCost) {
      this.form.cost_cents = isNaN(newCost) ? 0 : Math.round(newCost * 100);
    },
    'form.price': function (newPrice) {
      this.form.price_cents = isNaN(newPrice) ? 0 : Math.round(newPrice * 100);
    },
  },
  methods: {
    onServiceChange(service) {
      this.form.service_id = service ? service.id : '';
      this.form.service = service;
      if (service) {
        this.form.description = service.description;
      }
    },
  },
};
</script>
