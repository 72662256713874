<template>
  <google-map
    :center="center"
    :zoom="zoomLevel"
    style="width: 100%; height: 450px"
    :options="{
      controlSize: 24,
      streetViewControl: false
    }"
  >
    <google-marker
      v-for="(marker, index) in filteredMarkers"
      :key="index"
      :position="marker.coord"
      @click="openInfoWindow(index)"
    />

    <google-info-window
      :opened="infoWindowOpened"
      :position="infoWindowPosition"
      @closeclick="infoWindowOpened = false"
      :options="infoWindowOptions"
    >
      <div>{{ infoContent }}</div>
    </google-info-window>
  </google-map>
</template>

<script>
export default {
  name: 'MapWithMarkers',
  props: {
    markers: Array,
    markerDistance: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      infoContent: '',
      infoWindowPosition: null,
      infoWindowOpened: false,
      infoWindowOptions: {
        streetViewControl: false,
        pixelOffset: {
          width: 0,
          height: -35
        }
      }
    };
  },
  computed: {
    center() {
      const firstMarker = this.filteredMarkers[0];
      if(firstMarker){
        return this.filteredMarkers[0].coord;
      } else {
        return { lat: 33.753746, lng: -84.386330 };
      }
    },
    filteredMarkers() {
      return this.markers.filter(marker => (marker.coord.lat != null && marker.coord.lng != null));
    },
    zoomLevel() {
      let zoomLevel;

      if (this.markerDistance > 2) {
          zoomLevel = 13;
      } else if (this.markerDistance > 1) {
          zoomLevel = 14;
      } else if (this.markerDistance > 0.5) {
          zoomLevel = 15;
      } else {
          zoomLevel = 16;
      }

      return zoomLevel;
    },
  },
  methods: {
    openInfoWindow(index) {
      const marker = this.filteredMarkers[index];
      this.infoContent = marker.label;
      this.infoWindowPosition = { lat: marker.coord.lat, lng: marker.coord.lng };
      this.infoWindowOpened = true;
    }
  },
};
</script>
