<template>
  <div>
    <base-page-heading title="Tax Rates">
      <template #extra>
        <b-button
          v-if="$can('create', 'TaxRate')"
          variant="primary"
          class="mr-2"
          v-b-modal.tax-rates-add-modal
          v-b-tooltip.hover
          title="Create a new Tax Rate"
        >
          <i class="fa fa-plus mr-1" />
          New Tax Rate
        </b-button>
        <b-button
          v-if="$can('create', 'TaxRate')"
          variant="outline-secondary"
          class="mr-2"
          v-b-modal.tax-rates-imports-add-modal
          v-b-tooltip.hover
          title="Import Tax Rates"
        >
          <i class="fa fa-file-excel mr-1" />
          Import
        </b-button>

        <sfs-download-button
          v-b-tooltip.hover
          :provider-params="filters"
          filename="Tax Rates.csv"
          :file-provider="$taxRatesAPI.getSpreadsheet"
          title="Download spreadsheet"
          label="Download"
        />
      </template>
    </base-page-heading>
    <div class="content">
      <b-row>
        <b-col>
          <tax-rates-list-filter
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>
      <tax-rates-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :key="tableKey"
      />
    </div>

    <!-- Page Modals -->
    <tax-rates-new-modal
      v-if="$can('create', 'TaxRate')"
      @on-save="refreshTable"
    />
    <tax-rates-import-new-modal v-if="$can('create', 'TaxRate')" />
  </div>
</template>

<script>
import TaxRatesNewModal from '@/components/admin/taxRates/NewModal.vue';
import TaxRatesImportNewModal from '@/components/admin/imports/TaxRatesNewModal.vue';
import TaxRatesListFilter from '@/components/admin/taxRates/ListFilter.vue';
import TaxRatesTable from '@/components/admin/taxRates/Table.vue';
import listMixin from '@/mixins/listMixin';
import { TAX_RATE_TABLE_NAME } from '@/constants/taxRates';

export default {
  name: 'TaxRatesList',
  components: {
    TaxRatesNewModal,
    TaxRatesListFilter,
    TaxRatesTable,
    TaxRatesImportNewModal,
  },
  mixins: [listMixin],
  props: {
    text: String,
    name: String,
    city: String,
    county: String,
    state: String,
    charge_category: String,
    service_category: String,
    active: String,
  },
  data() {
    return {
      tableName: TAX_RATE_TABLE_NAME,
      filters: {
        text: this.text,
        name: this.name,
        city: this.city,
        county: this.county,
        state: this.state,
        charge_category: this.charge_category,
        service_category: this.service_category,
        active: this.active,
      },
      tableKey: Date.now(),
    };
  },
  methods: {
    refreshTable() {
      this.tableKey = Date.now();
    }
  },
};
</script>
