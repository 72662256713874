<template>
  <sfs-form-modal
    :id="modalId"
    :title="`Edit ${value.name}`"
    @on-hide="resetFormData"
    class="text-left"
    size="sm"
  >
    <tax-rates-form
      :new-form="false"
      v-bind="value"
      @on-submit="update"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
    />
  </sfs-form-modal>
</template>

<script>
import TaxRatesForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'TaxRatesEditModal',
  mixins: [formModalMixin],
  components: {
    TaxRatesForm,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: `tax-rates-${this.value.id}-edit-modal`,
    };
  },
  methods: {
    update(data, handleUpdate) {
      handleUpdate(this.$taxRatesAPI.update(this.value.id, data));
    },
    onSave(taxRate) {
      this.closeModal();
      this.resetFormData();
      this.$emit('on-save', taxRate);
    },
  },
};
</script>
