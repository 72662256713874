<template>
  <div>
    <skeleton-chart
      v-show="isLoading"
      :height="skeletonHeight"
      :width="skeletonWidth"
      :animation="skeletonAnimation"
    />
    <apex-chart
      v-show="!isLoading"
      :height="chartHeight"
      :width="chartWidth"
      :type="chartType"
      :series="series"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import apexchartsOptionsMixin from '@/mixins/apexchartsOptionsMixin';
import SkeletonChart from '@/components/shared/SkeletonChart.vue';

export default {
  name: 'BarChart',
  components: {
    SkeletonChart,
  },
  mixins: [apexchartsOptionsMixin],
  data() {
    return {
      chartType: 'bar',
    };
  },
};
</script>
