var render = function render(){var _vm=this,_c=_vm._self._c;return _c('bar-chart',{attrs:{"title":_vm.title,"stacked":true,"share-tooltip":true,"y-axis-title":"Total","chart-height":_vm.height,"skeleton-height":_vm.height,"series":_vm.chartData,"fill-opacity":1,"colors":_vm.chartColours,"y-axis-label-formatter":(val) => _vm.$options.filters.moneyFormatFilter(val, 'compact'),"data-function":_vm.loadChart,"data-parameters":{
    filters: {
      clientId: _vm.clientId,
      datePeriod: _vm.datePeriod,
    },
    groupPeriod: _vm.groupPeriod,
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }