<template>
  <b-modal
    :id="modalId"
    title="Changes"
    size="xl"
    no-close-on-backdrop
    hide-footer
    static
    lazy
  >
    <template #modal-header-close>
      <i class="fa fa-close" />
    </template>
    <b-row>
      <b-col>
        <b-table
          class="mb-0"
          :items="changes"
          :fields="columns"
          show-empty
          responsive
          table
        >
          <template #cell(field)="data">
            <code>{{ data.item.field }}</code>
          </template>
          <template #cell(before)="data">
            <code>{{ data.item.before }}</code>
          </template>
          <template #cell(after)="data">
            <code>{{ data.item.after }}</code>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        cols="3"
        offset="9"
      >
        <b-button
          variant="primary"

          block
          id="version-changes-close-button"
          @click="closeModal"
        >
          Close
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { map as _map } from 'lodash';

export default {
  name: 'VersionChangesModal',
  props: {
    value: Object,
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
  data() {
    return {
      columns: ['field', 'before', 'after'],
    };
  },
  computed: {
    modalId() {
      return `version-changes-${this.value.id}-modal`;
    },
    changes() {
      return _map(this.value.changes, (change, field) => {
        return { field: field, before: change[0], after: change[1] };
      });
    },
  },
};
</script>
