import paginationMixin from '@/mixins/paginationMixin';
import sortMixin from '@/mixins/sortMixin';

export default {
  mixins: [paginationMixin, sortMixin],
  props: {
    filters: {
      type: Object,
      default: () => new Object(),
    },
    columns: {
      type: Array,
      default: () => [],
    },
    showPerPage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableKey: Date.now(),
    };
  },
  methods: {
    refreshTable() {
      this.tableKey = Date.now();
      this.$emit('data-changed');
    },
  }
};
