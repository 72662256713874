import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const RECURRING_WORK_ORDER_MODEL_NAME = 'recurring_work_orders';
export const RECURRING_WORK_ORDER_TABLE_NAME = 'recurringWorkOrders';

// The columns available to be shown in a recurringWorkOrders table.
export const RECURRING_WORK_ORDER_TABLE_COLUMNS = {
  id: { label: 'ID', key: 'id' },
  description: { label: 'Description', key: 'description' },
  priority: { label: 'Priority', key: 'priority' },
  maintainance_type: { label: 'Maintainance Type', key: 'maintainance_type' },
  price: { label: 'Price (DNE)', key: 'price' },
  location_id: { label: 'Location', key: 'location_id' },
  client_id: { label: 'Client', key: 'client_id' },
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const RECURRING_WORK_ORDER_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(RECURRING_WORK_ORDER_TABLE_COLUMNS);

// The default columns to be shown when the recurringWorkOrders table loads.
export const RECURRING_WORK_ORDER_TABLE_DEFAULT_COLUMNS = [
  RECURRING_WORK_ORDER_TABLE_COLUMNS.description,
  RECURRING_WORK_ORDER_TABLE_COLUMNS.priority,
  RECURRING_WORK_ORDER_TABLE_COLUMNS.maintainance_type,
  RECURRING_WORK_ORDER_TABLE_COLUMNS.price,
  RECURRING_WORK_ORDER_TABLE_COLUMNS.client_id,
  RECURRING_WORK_ORDER_TABLE_COLUMNS.actions,
];
