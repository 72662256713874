<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-form-group
      :label="fieldLabels.category"
      label-for="contact-info-category"
    >
      <category-select
        name="contact-info-category"
        v-model="form.category"
        :select-class="{ 'is-invalid': $v.form.category.$dirty && $v.form.category.$error }"
      />
      <b-form-invalid-feedback
        v-if="$v.form.category.$dirty"
        class="contact-info-category-feedback"
      >
        <span v-if="!$v.form.category.required">Please enter a category.</span>
        <span v-if="!$v.form.category.maxLength">
          The category must be less than {{ $v.form.category.$params.maxLength.max + 1 }} characters.
        </span>
        <span v-if="!$v.form.category.serverFailed">{{ serverErrors.category }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      :label="fieldLabels.content"
      label-for="contact-info-content"
    >
      <b-form-input
        name="contact-info-content"
        v-model="$v.form.content.$model"
        :state="$v.form.content.$dirty ? !$v.form.content.$error : null"
        type="text"
      />
      <b-form-invalid-feedback
        v-if="$v.form.content.$dirty"
        class="contact-info-content-feedback"
      >
        <span v-if="!$v.form.content.required">Please enter a content.</span>
        <span v-if="!$v.form.content.maxLength">
          The content must be less than {{ $v.form.content.$params.maxLength.max + 1 }} characters.
        </span>
        <span v-if="!$v.form.content.serverFailed">{{ serverErrors.content }}</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="contact-info-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="contact-info-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import CategorySelect from '@/components/admin/contactInfos/CategorySelect.vue';

export default {
  name: 'ContactInfoForm',
  components: {
    CategorySelect,
  },
  mixins: [formMixin],
  props: {
    content: String,
    category: String,
  },
  data() {
    return {
      form: {
        content: this.content,
        category: this.category,
      },
      fieldLabels: {
        content: 'Content',
        category: 'Category *',
      },
    };
  },
  validations: {
    form: {
      content: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('content');
        },
      },
      category: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('category');
        },
      },
    },
  },
};
</script>
