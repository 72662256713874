<template>
  <div>
    <base-page-heading title="Technician Payments">
      <template #extra>
        <b-button
          v-if="$can('create', 'TechnicianPayment')"
          class="mr-2"
          v-b-modal.technician-payment-add-modal
          variant="primary"
          v-b-tooltip.hover
          title="Create a new Payment"
        >
          <i class="fa fa-plus mr-1" />
          New Payment
        </b-button>
        <b-button
          v-if="$can('create', 'TechnicianPayment')"
          class="mr-2"
          v-b-modal.technician-payment-finalize-modal
          variant="outline-secondary"
          v-b-tooltip.hover
          title="Finalize the Payments"
        >
          <i class="fa fa-check-double" />
          Finalize
        </b-button>

        <b-dropdown
          v-if="$can('create_item', 'TechnicianPayment')"
          text="Import"
          class="mr-2"
          variant="outline-secondary"
          v-b-tooltip.hover
          title="Manage Payments using imports"
        >
          <template #button-content>
            <i class="fa fa-file-excel mr-1" />
            Import
          </template>
          <b-dropdown-item
            v-if="$can('create_item', 'TechnicianPayment')"
            v-b-modal.technician-payment-items-imports-add-modal
          >
            New Technician Payment Items
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('create_item', 'TechnicianPayment')"
            v-b-modal.technician-payment-items-from-work-order-imports-add-modal
          >
            New Items from Work Order
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('create_item', 'TechnicianPayment')"
            v-b-modal.remove-work-orders-from-technician-payment-imports-add-modal
          >
            Remove Work Orders from Payments
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          v-if="$can('read', 'TechnicianPayment')"
          variant="outline-secondary"
          right
          v-b-tooltip.hover
          title="Download spreadsheets"
        >
          <template #button-content>
            <b-spinner
              v-if="downloading"
              small
              label="Downloading..."
            />
            <i
              v-else
              class="fa fa-download mr-1"
            />
            Downloads
          </template>
          <b-dropdown-item @click="downloadPaymentsSpreadsheet">
            Technician Payments Spreadsheet
          </b-dropdown-item>
          <b-dropdown-item @click="downloadItemsSpreadsheet">
            Items Spreadsheet
          </b-dropdown-item>
          <b-dropdown-item @click="downloadBillDotComSpreadsheet">
            Bill.com Spreadsheet
          </b-dropdown-item>
          <b-dropdown-item @click="downloadNewBillDotComSpreadsheet">
            NEW Bill.com Spreadsheet
          </b-dropdown-item>
          <b-dropdown-item @click="downloadProfitabilitySpreadsheet">
            Profitability
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>

    <div class="content">
      <b-row>
        <b-col>
          <technician-payments-list-filter
            :columns="columns"
            :initial-filters="filters"
            @filters-applied="applyFilters"
          />
        </b-col>
      </b-row>
      <technician-payments-table
        :filters="filters"
        :columns="columns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
      />
    </div>
    <!-- Page Modals -->
    <technician-payments-new-modal v-if="$can('create', 'TechnicianPayment')" />
    <technician-payments-finalize-modal
      v-if="$can('create', 'TechnicianPayment')"
      @on-save="applyFilters({ columns: columns, filters: { filters } })"
    />
    <technician-payment-items-import-new-modal v-if="$can('create_item', 'TechnicianPayment')" />
    <technician-payment-items-from-work-order-import-new-modal v-if="$can('create_item', 'TechnicianPayment')" />
    <remove-work-orders-from-technician-payment-import-new-modal v-if="$can('create_item', 'TechnicianPayment')" />
  </div>
</template>

<script>
import TechnicianPaymentsNewModal from '@/components/admin/technicianPayments/NewModal.vue';
import TechnicianPaymentsFinalizeModal from '@/components/admin/technicianPayments/FinalizeModal.vue';
import TechnicianPaymentsListFilter from '@/components/admin/technicianPayments/ListFilter.vue';
import TechnicianPaymentsTable from '@/components/admin/technicianPayments/Table.vue';
import TechnicianPaymentItemsImportNewModal from '@/components/admin/imports/TechnicianPaymentItemsNewModal.vue';
import TechnicianPaymentItemsFromWorkOrderImportNewModal from '@/components/admin/imports/TechnicianPaymentItemsFromWorkOrderNewModal.vue';
import RemoveWorkOrdersFromTechnicianPaymentImportNewModal from '@/components/admin/imports/RemoveWorkOrdersFromTechnicianPaymentNewModal.vue';
import listMixin from '@/mixins/listMixin';
import downloadMixin from '@/mixins/downloadMixin';

import { TECHNICIAN_PAYMENT_TABLE_NAME } from '@/constants/technicianPayments';

export default {
  name: 'TechnicianPaymentsList',
  components: {
    TechnicianPaymentsNewModal,
    TechnicianPaymentItemsImportNewModal,
    TechnicianPaymentItemsFromWorkOrderImportNewModal,
    RemoveWorkOrdersFromTechnicianPaymentImportNewModal,
    TechnicianPaymentsFinalizeModal,
    TechnicianPaymentsListFilter,
    TechnicianPaymentsTable,
  },
  mixins: [downloadMixin, listMixin],
  props: {
    text: String,
    user_id: String,
    manager_id: String,
    date_from: String,
    date_to: String,
    due_date: String,
    emailed: String,
    approved: String,
    finalized: String,
  },
  data() {
    return {
      tableName: TECHNICIAN_PAYMENT_TABLE_NAME,
      filters: {
        text: this.text,
        user_id: this.user_id,
        manager_id: this.manager_id,
        date_from: this.date_from,
        date_to: this.date_to,
        due_date: this.due_date,
        emailed: this.emailed,
        approved: this.approved,
        finalized: this.finalized,
      },
    };
  },
  methods: {
    downloadPaymentsSpreadsheet() {
      this.download(this.$technicianPaymentsAPI.getSpreadsheet, this.filters, 'Technician Payments.csv');
    },
    downloadItemsSpreadsheet() {
      this.download(
        this.$technicianPaymentsAPI.getTechnicianPaymentItemsSpreadsheet,
        this.filters,
        'Technician Payment Items.csv'
      );
    },
    downloadBillDotComSpreadsheet() {
      this.download(this.$technicianPaymentsAPI.getBillDotComSpreadsheet, this.filters, 'Bill.com Import.csv');
    },
    downloadNewBillDotComSpreadsheet() {
      this.download(this.$technicianPaymentsAPI.getNewBillDotComSpreadsheet, this.filters, 'New Bill.com Import.csv');
    },
    downloadProfitabilitySpreadsheet() {
      this.download(this.$technicianPaymentsAPI.getItemsProfitabilitySpreadsheet, this.filters, 'Technician Payment Items Profitability.csv');
    },
  },
};
</script>
