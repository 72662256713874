import List from '@/pages/admin/vendors/List.vue';
import Show from '@/pages/admin/vendors/Show.vue';
import handleActivePaginationRouteProps from '@/utils/handleActivePaginationRouteProps.js';

export default [
  {
    path: 'vendors',
    name: 'vendors',
    component: List,
    props: handleActivePaginationRouteProps,
  },
  {
    path: 'vendors/:id(\\d+)',
    name: 'vendor',
    component: Show,
  },
];
