import prepareTableColumnOptions from '@/utils/prepareTableColumnOptions';

export const SUPPLY_ORDER_ITEM_TABLE_NAME = 'supplyOrderItems';

// The columns available to be shown in a supplyOrderItems table.
export const SUPPLY_ORDER_ITEM_TABLE_COLUMNS = {
  id: { label: 'ID', key: 'id' },
  supply_order_id: { label: 'Supply Order', key: 'supply_order_id' },
  supply_id: { label: 'Supply', key: 'supply_id' },
  quantity: { label: 'Quantity', key: 'quantity' },
  cost: { label: 'Cost', key: 'cost' },
  price: { label: 'Price', key: 'price' },
  margin: { label: 'Margin', key: 'margin' },
  actions: { label: 'Actions', key: 'actions' },
};

// The columns available to be selected in a form that is compatible
// with vue-select and bootstrap-vue selects.
export const SUPPLY_ORDER_ITEM_TABLE_COLUMN_OPTIONS = prepareTableColumnOptions(SUPPLY_ORDER_ITEM_TABLE_COLUMNS);

// The default columns to be shown when the supplyOrderItems table loads.
export const SUPPLY_ORDER_ITEM_TABLE_DEFAULT_COLUMNS = [
  SUPPLY_ORDER_ITEM_TABLE_COLUMNS.supply_id,
  SUPPLY_ORDER_ITEM_TABLE_COLUMNS.quantity,
  SUPPLY_ORDER_ITEM_TABLE_COLUMNS.cost,
  SUPPLY_ORDER_ITEM_TABLE_COLUMNS.price,
  SUPPLY_ORDER_ITEM_TABLE_COLUMNS.margin,
  SUPPLY_ORDER_ITEM_TABLE_COLUMNS.actions,
];
